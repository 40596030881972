import React from 'react';

import { Box } from '@rbilabs/universal-components';
import { useIntl } from 'react-intl';

import { CHOOSE_LOCATION_COLOR } from 'components/top-service-mode/actions/constants';
import { theme } from 'styles/configure-theme';

export const StyledChooseLocation = Box.withConfig<{ reversedUI: boolean }>(p => ({
  marginLeft: { base: '$2', md: '$0' },
  _text: {
    fontSize: { base: 'sm', md: 'xs' },
    textDecorationLine: 'underline',
    color: p.reversedUI ? theme.token('text-reversed') : CHOOSE_LOCATION_COLOR,
  },
}));

interface ChooseLocationType {
  reversedUI: boolean;
}

const ChooseLocation = ({ reversedUI }: ChooseLocationType) => {
  const { formatMessage } = useIntl();
  const chooseYourLocation = formatMessage({ id: 'chooseYourLocation' });

  return (
    <StyledChooseLocation
      reversedUI={reversedUI}
      accessibilityLabel={chooseYourLocation}
      accessibilityHint={formatMessage({ id: 'clickToSelectLocation' })}
    >
      {chooseYourLocation}
    </StyledChooseLocation>
  );
};

export default ChooseLocation;
