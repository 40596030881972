import React from 'react';

import { useIntl } from 'react-intl';

import ActionButton, { ActionButtonVariants } from 'components/action-button';
import { ActionsheetContent, ActionsheetWrapper } from 'components/action-sheet';

import { ContentHeader, ContentText, ContentWrapper } from './styled';

interface IDialogActionSheetProps {
  isOpen?: boolean;
  onDismiss: () => void;
  onConfirm?: () => void;
  onPressDismissButton?: () => void;
  confirmButtonLabel?: string;
  dismissButtonLabel?: string;
  title: string | React.ReactNode;
  titleString?: string;
  body: string | React.ReactNode;
}

export const DialogActionSheet = ({
  isOpen = true,
  onDismiss,
  onConfirm,
  onPressDismissButton = () => {},
  confirmButtonLabel,
  dismissButtonLabel,
  title,
  titleString = '',
  body,
}: IDialogActionSheetProps) => {
  const { formatMessage } = useIntl();
  const shouldShowConfirm = !!onConfirm && !!confirmButtonLabel;

  return (
    <ActionsheetWrapper
      onOpenEventMessage={typeof title === 'string' ? title : titleString}
      isOpen={isOpen}
      onClose={onDismiss}
    >
      <ActionsheetContent>
        <ContentWrapper>
          {typeof title === 'string' ? <ContentHeader>{title}</ContentHeader> : title}
          <ContentText>{body}</ContentText>
          {shouldShowConfirm && (
            <ActionButton onPress={onConfirm} mb="$4">
              {confirmButtonLabel}
            </ActionButton>
          )}
          <ActionButton
            onPress={onPressDismissButton || onDismiss}
            variant={ActionButtonVariants.TEXT_ONLY}
          >
            {dismissButtonLabel || formatMessage({ id: 'cancel', description: 'cancel label' })}
          </ActionButton>
        </ContentWrapper>
      </ActionsheetContent>
    </ActionsheetWrapper>
  );
};
