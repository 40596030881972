import React, { FC } from 'react';

import { Skeleton, Text, TextProps } from '@rbilabs/universal-components';
import { centsToDollarString } from 'utils';

import { useUIContext } from 'state/ui';

export interface ICurrencyProps {
  isLoading?: boolean;
  amount?: number;
  prefix?: string;
  suffix?: string;
  hideCurrencySymbol?: boolean;
  id?: string;
  testID?: string;
  textProps?: TextProps;
  isBold?: boolean;
}

export const Currency: FC<React.PropsWithChildren<ICurrencyProps>> = ({
  amount = 0,
  prefix = '',
  suffix = '',
  hideCurrencySymbol = false,
  isLoading,
  testID,
  textProps,
  isBold = false,
}) => {
  const { formatCurrencyForLocale } = useUIContext();
  const formattedAmount = hideCurrencySymbol
    ? centsToDollarString(amount)
    : formatCurrencyForLocale(amount);

  if (isLoading) {
    return <Skeleton.Text w="10" />;
  }

  return (
    <Text
      testID={testID}
      accessibilityLabel={`${prefix}${formattedAmount}${suffix}`}
      variant="copyOne"
      {...textProps}
      fontWeight={isBold ? 'bold' : 'normal'}
    >
      {prefix}
      {formattedAmount}
      {suffix}
    </Text>
  );
};

export default Currency;
