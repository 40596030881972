import { Box, Header, Text, makeUclComponent } from '@rbilabs/universal-components';

import ActionButton from 'components/action-button';
import LoyaltyPointsIcon from 'components/icons/loyalty-points-icon';
import Picture from 'components/picture';
import LoyaltyRewardsLogo from 'pages/loyalty/loyalty-rewards-logo';

interface IAuthenticatedContainerProps {
  hasPromoMessage: boolean;
}

export const BannerContainer = Box.withConfig({
  paddingX: '$4',
  backgroundColor: Styles.color.contrastBackground,
});

export const UnauthenticatedContainer = Box.withConfig<{ center: boolean }>(p => ({
  paddingY: '$4',
  alignItems: p.center ? 'center' : 'flex-end',
  flexDirection: 'row',
}));

export const AuthenticatedContainer = Box.withConfig<IAuthenticatedContainerProps>(p => ({
  paddingTop: p.hasPromoMessage ? '$6' : '$8',
  flexDirection: 'column',
}));

export const StyledLoyaltyRewardsLogo = makeUclComponent(LoyaltyRewardsLogo).withConfig({
  width: '$48',
  marginTop: '$2',
});

export const TextContainer = Box.withConfig({
  flex: 1,
});

export const DetailsButton = makeUclComponent(ActionButton).withConfig({
  fontFamily: 'heading',
  borderColor: Styles.color.white,
  backgroundColor: Styles.color.contrastBackground,
});

export const LearnMoreButton = makeUclComponent(ActionButton).withConfig({
  paddingLeft: '$10',
  fontFamily: 'heading',
  maxHeight: '$8',
  maxWidth: '$32',
  textTransform: 'none',
  borderColor: Styles.color.white,
  backgroundColor: Styles.color.contrastBackground,
});

export const LearnMoreIcon = makeUclComponent(Picture).withConfig({
  position: 'absolute',
  overflow: 'visible',
  left: '$1.5',
  top: '$1',
});

export const WelcomeMessage = Text.withConfig({
  fontSize: 'xs',
  color: Styles.color.white,
  fontFamily: 'heading',
});

export const StyledLoyaltyPointsIcon = makeUclComponent(LoyaltyPointsIcon).withConfig({
  width: 50,
  height: 50,
});

export const PointsContainer = Box.withConfig({
  flexDirection: 'row',
  borderColor: 'blackOpacity.50',
  justifyContent: 'space-between',
});

export const AvailableCrownsCaption = Text.withConfig({
  fontFamily: 'body',
  fontSize: 'sm',
  marginLeft: '$1.5',
  color: Styles.color.alert.info.background,
});

export const UserPoints = Header.withConfig({
  lineHeight: 50,
  fontSize: '4xl',
  testID: 'authenticated-banner-points',
  color: 'white',
  variant: 'headerOne',
  marginBottom: '0',
});

export const UserCrownPointsContainer = Box.withConfig({
  flexDirection: 'row',
});

export const DashboardButton = makeUclComponent(ActionButton).withConfig({
  color: Styles.color.contrastBackground,
  borderColor: Styles.color.background,
  backgroundColor: Styles.color.background,
  alignSelf: 'center',
});

export const CurrentProgress = Box.withConfig<{ $progress: number }>(p => ({
  height: '$1',
  width: `${p.$progress}%`,
  bordeRadius: 50,
  backgroundColor: Styles.color.alert.info.background,
}));

export const PointsProgress = Box.withConfig({
  backgroundColor: Styles.color.grey.six,
  borderRadius: 50,
  marginBottom: '$12',
  marginTop: '$5',
  marginLeft: '$2',
  width: '88%',
});

export const ProgressBar = Box;

const INITIAL_TIER_LEFT_POSITION = -2;

export const TierDot = Box.withConfig<{ completed: boolean; left?: number }>(p => ({
  height: '$2.5',
  width: '$2.5',
  position: 'absolute',
  background: p.completed ? Styles.color.alert.info.background : Styles.color.grey.six,
  left: `${p.left ? p.left : INITIAL_TIER_LEFT_POSITION}%`,
  marginTop: '-0.5',
  borderRadius: '10',
}));

export const TierText = Text.withConfig<{ left: number }>(p => ({
  position: 'absolute',
  fontFamily: 'heading',
  left: `${p.left}%`,
  marginTop: '$2',
  color: Styles.color.white,
  textAlign: 'center',
  justifyContent: 'center',
}));
