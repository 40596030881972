import { View, makeUclComponent } from '@rbilabs/universal-components';

import { UIRefreshOffers } from 'components/offers/ui-refresh';
import BlockRenderer from 'components/sanity-block-renderer';
import { theme } from 'styles/configure-theme';

import { LoyaltyLoader } from '../loyalty-loader';

export const StyledUIRefreshOffers = makeUclComponent(UIRefreshOffers).withConfig({
  height: 'full',
  width: {
    md: 'full',
  },
  flexDirection: {
    md: 'row',
  },
});

export const StyledBlockRenderer = BlockRenderer.withConfig({
  margin: 0,
  fontSize: 'xs',
});

export const LoyaltyIncentivesContainer = View.withConfig({
  backgroundColor: theme.token('background-default'),
  width: 'full',
  flex: 1,
  flexDirection: 'column',
});

export const StyledLoyaltyLoader = LoyaltyLoader;
