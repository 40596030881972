import React from 'react';

import { Icon, PressableProps, Text } from '@rbilabs/universal-components';
import { useIntl } from 'react-intl';

import { primitive } from 'styles/constants/primitives';
import { hiddenAccessibilityPlatformProps } from 'utils/accessibility';

import { IconContainer, StyledPressable } from './loyalty-promo-code-cta.styled';

export const LoyaltyPromoCodeCTA = ({ onPress }: PressableProps) => {
  const { formatMessage } = useIntl();

  return (
    <StyledPressable onPress={onPress}>
      <IconContainer>
        <Icon variant="offer" color={primitive.$white} {...hiddenAccessibilityPlatformProps} />
      </IconContainer>
      <Text fontWeight="bold">{formatMessage({ id: 'promoCodeCTA' })}</Text>
    </StyledPressable>
  );
};
