import { isEmpty } from 'lodash-es';

import { IAuthRouteParams } from 'state/auth/types';
import { convertQueryStringToObject } from 'utils/navigation';
import { routes } from 'utils/routing';

import { navigatorsNameSanitizationMap } from './constants';
import { INavigationNestedParams, INavigationState, IRouteHook } from './types';

export function appendObjectToQueryString(path: string, object: object): string {
  if (!object || isEmpty(object)) {
    return path;
  }

  const appendedParams = Object.keys(object)
    .map(key => {
      const encodedKey = encodeURIComponent(key);
      const value = object[key];
      const processedValue = typeof value === 'object' ? JSON.stringify(value) : value;
      const encodedValue = encodeURIComponent(processedValue);
      return `${encodedKey}=${encodedValue}`;
    })
    .join('&');
  const concatChar = path.includes('?') ? '&' : '?';
  return `${path}${concatChar}${appendedParams}`;
}

/**
 * Iterate over navigation.state object to return the active route.
 *
 * This differs from useRoute hook, because it can iterate over nested navigators.
 *
 * @param navigationState as returned by navigation.getState
 */
export const getActiveRoute = <T extends object>({
  routes = [],
  index = 0,
}: INavigationState): IRouteHook<T> | null => {
  const activeRoute = routes[index];
  if (!activeRoute) {
    return null;
  }
  if (activeRoute.state) {
    return getActiveRoute(activeRoute.state);
  }

  if (isNavigationNestedParams(activeRoute.params)) {
    const { screen: nestedName, path: nestedPath, params: nestedParams } = activeRoute.params;

    // Path has the expected pathname when route was resolved using Linking. Name is ok for all other cases
    const { pathname, params } = processRouteAndParams(nestedPath || nestedName, nestedParams);

    return {
      pathname: sanitizeNavigatorsName(pathname, params),
      path: nestedPath,
      params: (params || {}) as T,
      key: activeRoute.key,
    };
  }

  return {
    // Path has the expected pathname when route was resolved using Linking. Name is ok for all other cases
    pathname: sanitizeNavigatorsName(
      activeRoute.path || activeRoute.name,
      activeRoute.params || {}
    ),
    path: activeRoute.path,
    params: (activeRoute.params || {}) as T,
    key: activeRoute.key,
  };
};

/**
 * Return backwards compatible screen names with react-router URLs
 */
export const sanitizeNavigatorsName = (
  name: string = '',
  params?: { [key: string]: any }
): string => {
  if (name === routes.staticPage || name === routes.modalStaticPage) {
    const resolvedParams = params?.params || params;
    const staticPagePath = resolvedParams?.staticPagePath;
    return isStringParam(staticPagePath) ? `/${staticPagePath}` : name;
  }

  if (name === routes.signUp) {
    const { activeRouteIsSignIn, showOtpForm } = (params || {}) as IAuthRouteParams;

    if (showOtpForm) {
      return routes.confirmOtp;
    }
    if (activeRouteIsSignIn) {
      return routes.signIn;
    }
    return routes.signUp;
  }

  const mappedName = navigatorsNameSanitizationMap[name] || name;
  return mappedName.split('?')[0];
};

const isStringParam = (property: any): property is string => {
  return typeof property === 'string';
};

const isNavigationNestedParams = (params: any): params is INavigationNestedParams => {
  return params?.screen && !params?.name;
};

export const processRouteAndParams = (route: string, defaultParams?: { [k: string]: any }) => {
  let params = defaultParams || {};
  const [routePart, queryString] = route.split('?');

  // @FIXME: GST-10538 - Localized routes are broken
  // const pathname = localizedRoutesMap[routePart] || routePart;

  if (queryString) {
    const queryStringObj = convertQueryStringToObject(queryString);
    params = { ...queryStringObj, ...params };
  }

  return { pathname: routePart, params };
};

/**
 * Processes a localized route and find its EN equivalent
 * @param route
 * @return The EN route equivalent with its query string
 */
export const processLocalizedRoute = (route: string) => {
  const { pathname, params } = processRouteAndParams(route);
  return appendObjectToQueryString(pathname, params);
};
