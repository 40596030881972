import React, { ReactNode, useState } from 'react';

import { Text } from '@rbilabs/universal-components';
import { useIntl } from 'react-intl';
import { GestureResponderEvent } from 'react-native';

import ActionButton, { ActionButtonSizes, ActionButtonVariants } from 'components/action-button';
import { VisuallyHidden } from 'components/ucl/visually-hidden';

import { SelectionsListItem, actionButtonStyles } from './product.styled';

interface SelectionsListProps {
  selections: { id: string; element: ReactNode }[];
  id: string;
  expandable: boolean;
}

// length of selections to show before truncating list
const MAX_SELECTIONS = 2;

const SelectionsList: React.FC<React.PropsWithChildren<SelectionsListProps>> = ({
  selections,
  expandable,
}) => {
  const { formatMessage } = useIntl();
  const [isExpanded, setIsExpanded] = useState(false);
  const shouldShowExpandToggle = expandable && selections.length > MAX_SELECTIONS;
  const selectionsListLength = isExpanded ? selections.length : MAX_SELECTIONS;

  const remainingSelections = selections.length - selectionsListLength;
  const remainingSelectionsText =
    !shouldShowExpandToggle &&
    remainingSelections > 0 &&
    formatMessage({ id: 'amountMore' }, { amountMore: remainingSelections });

  const handleClick = (event: GestureResponderEvent) => {
    if (expandable) {
      event.stopPropagation();
      setIsExpanded(!isExpanded);
    }
  };

  return (
    <>
      {selections.slice(0, selectionsListLength).map(({ id: selectionId, element }) => {
        return (
          <SelectionsListItem
            key={selectionId}
            testID={`modifiers-list-${selectionId}`}
            accessibilityLabel={formatMessage({ id: 'itemModifiers' })}
          >
            {element}
          </SelectionsListItem>
        );
      })}
      {remainingSelectionsText && <Text variant="copyTwo">{remainingSelectionsText}</Text>}
      {shouldShowExpandToggle && (
        <ActionButton
          {...actionButtonStyles}
          size={ActionButtonSizes.SMALL}
          variant={ActionButtonVariants.TEXT_ONLY}
          onPress={handleClick}
        >
          <VisuallyHidden accessibilityLabel={formatMessage({ id: 'itemModifiers' })} />
          {isExpanded ? formatMessage({ id: 'showLess' }) : formatMessage({ id: 'showMore' })}
        </ActionButton>
      )}
    </>
  );
};

export default SelectionsList;
