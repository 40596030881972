import { Auth } from '@aws-amplify/auth';
import { CognitoUserSession } from 'amazon-cognito-identity-js';

import AuthStorage from 'utils/cognito/storage';
import { StorageKeys } from 'utils/local-storage';

interface AuthError extends Error {
  code: string | 'NetworkError';
}

let pendingCurrentSessionCall: Promise<CognitoUserSession> | null = null;

export const getCurrentSessionWrapper = () => {
  // de-dupe calls to cognito as it does not dedupe internally for errors
  if (pendingCurrentSessionCall) {
    return pendingCurrentSessionCall;
  }

  const result = Auth.currentSession()
    // perform some retries at a delay to hopefully get a valid session
    // 2 calls in < 250ms seem to internally get debounced by cognito
    .catch((err: AuthError) => retryIfNetworkFailure(err, 250))
    .catch((err: AuthError) => retryIfNetworkFailure(err, 500))
    .catch((err: AuthError) => retryIfNetworkFailure(err, 1000))
    .catch((err: AuthError) => retryIfNetworkFailure(err, 2000))
    .finally(() => {
      pendingCurrentSessionCall = null;
    });

  pendingCurrentSessionCall = result;

  return result;
};

// cognito doesn't attempt to retry on network errors - network errors are a given in a mobile app
const retryIfNetworkFailure = (err: AuthError, delayMs: number): Promise<CognitoUserSession> => {
  if (err.code === 'NetworkError') {
    return new Promise((res, rej) => {
      setTimeout(() => Auth.currentSession().then(res).catch(rej), delayMs);
    });
  }
  // let all other errors pass though normally
  return Promise.reject(err);
};

// return the current user session
export const getCurrentSession = (): Promise<CognitoUserSession | null> =>
  getCurrentSessionWrapper().catch(() => {
    // if cognito failed to get a valid session
    // clear local storage to prevent a user with
    // no session from being "logged in" in app state
    // @ts-expect-error TS(2345) FIXME: Argument of type 'null' is not assignable to param... Remove this comment to see the full error message
    AuthStorage.setItem(StorageKeys.USER_AUTH_TOKEN, null);
    return null;
  });
