import { useRef } from 'react';

import { useRoute } from 'hooks/navigation/use-route';
import { useReactNavigationFlag } from 'hooks/use-react-navigation-flag';

import { ILoyaltyOffersParams } from './types';

interface ILoyaltyOffersFlow {
  offerEngineId: string;
  isConfigOffer?: boolean;
}

// This hook should be removed when we split OfferList from OfferDetails components
const useLoyaltyOffersFlow = (): ILoyaltyOffersFlow => {
  const { params } = useRoute<ILoyaltyOffersParams>();
  const offerEngineId = (params?.offerEngineId || '').trim();
  const isConfigOffer = params?.isConfigOffer || false;

  // Keeping offer engine ID ref across re renders because OffersBase is mounted twice:
  // on offers list view and on offer details view.
  const enableReactNativeNavigation = useReactNavigationFlag();
  const memoizedOfferEngineId = useRef(offerEngineId).current;

  return {
    offerEngineId: enableReactNativeNavigation ? memoizedOfferEngineId : offerEngineId,
    isConfigOffer,
  };
};

export default useLoyaltyOffersFlow;
