import { useEffect } from 'react';

import { datadogRum } from '@datadog/browser-rum';
import { Platform } from 'react-native';

import { useReactNavigationFlag } from 'hooks/use-react-navigation-flag';

export const useRumPageView = (key: string, name: string, context?: object) => {
  const enableReactNativeNavigation = useReactNavigationFlag();

  useEffect(() => {
    if (enableReactNativeNavigation || Platform.OS === 'web') {
      return;
    }

    void datadogRum.startView(name);
  }, [context, enableReactNativeNavigation, key, name]);
};
