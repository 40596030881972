import React, { useCallback, useState } from 'react';

import { LayoutChangeEvent } from 'react-native';

import { Container } from './read-more-accordion.styled';
import ReadMoreButton from './read-more-button';

const DEFAULT_COLLAPSED_HEIGHT = 25;

interface IReadMoreAccordionProps {
  collapsedHeight?: number;
  children: React.ReactElement;
}

const ReadMoreAccordion: React.FC<React.PropsWithChildren<IReadMoreAccordionProps>> = ({
  collapsedHeight = DEFAULT_COLLAPSED_HEIGHT,
  children,
}) => {
  const [isCollapsed, setIsCollapsed] = useState<boolean>(false);
  const [contentHeight, setContentHeight] = useState<number | null>(null);

  const toggleAccordion = useCallback(() => {
    setIsCollapsed(!isCollapsed);
  }, [isCollapsed]);

  const onLayout = useCallback(
    (e: LayoutChangeEvent) => {
      if (!contentHeight) {
        const childContentHeight = e.nativeEvent.layout.height;
        setContentHeight(childContentHeight);
        setIsCollapsed(childContentHeight > collapsedHeight);
      }
    },
    [contentHeight, collapsedHeight]
  );

  const showReadMoreButton = !!(contentHeight && contentHeight > collapsedHeight);

  return (
    <>
      <Container onLayout={onLayout} height={isCollapsed ? `${collapsedHeight}px` : undefined}>
        {React.cloneElement(children, { isCollapsed })}
      </Container>

      {showReadMoreButton && <ReadMoreButton collapsed={isCollapsed} onPress={toggleAccordion} />}
    </>
  );
};

export default ReadMoreAccordion;
