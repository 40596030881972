import { useCallback } from 'react';

import { useStoreClosedActionSheet } from 'components/store-closed-action-sheet';
import { IRestaurantNode } from 'generated/rbi-graphql';
import { useFocusEffect } from 'hooks/navigation/use-focus-effect-universal';
import { useNavigation } from 'hooks/navigation/use-navigation';
import { useStoreContext } from 'state/store';
import { routes } from 'utils/routing';

export const StoreClosedConfirmation = () => {
  const { navigate } = useNavigation();
  const { store } = useStoreContext();
  const {
    requestScheduleFutureOrderingConfirmation,
    storeClosedActionSheet,
  } = useStoreClosedActionSheet({
    restaurant: store as IRestaurantNode,
    shouldCheckSingletonDate: true,
  });
  useFocusEffect(
    useCallback(() => {
      requestScheduleFutureOrderingConfirmation().then(result => {
        if (!result) {
          /**
           * ActionSheet component blocks UI interaction if we don't wait until it is fully closed.
           */
          setTimeout(() => {
            navigate(routes.storeLocator);
          }, 250);
        }
      });
    }, [navigate, requestScheduleFutureOrderingConfirmation])
  );
  return <>{storeClosedActionSheet}</>;
};
