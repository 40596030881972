import React from 'react';
import type { ComponentProps, ComponentType } from 'react';

import { Box, Icon, InlineAlert, VStack } from '@rbilabs/universal-components';
import { useIntl } from 'react-intl';
import { StyleSheet, View } from 'react-native';

import ActionButton, { ActionButtonSizes, ActionButtonVariants } from 'components/action-button';
import Picture from 'components/picture';
import { useAlertQuery } from 'generated/sanity-graphql';
import { useFeatureSimplifiedLandingPage } from 'hooks/use-feature-simplified-landing-page';
import { useLocaleImage } from 'hooks/use-locale-image';
import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';

function Layer({ style, ...rest }: ComponentProps<typeof View>) {
  return <View style={[style, StyleSheet.absoluteFill]} {...rest} />;
}

type LandingPageComponent = ComponentType<{ id: string }>;

const LandingPageAlert: LandingPageComponent = ({ id }) => {
  const { data } = useAlertQuery({
    variables: { alertId: id },
    skip: !id,
  });

  const body = data?.Alert?.body?.locale;
  const heading = data?.Alert?.heading?.locale;
  const learnMoreText = data?.Alert?.learnMoreText?.locale ?? '';
  const learnMoreUrl = data?.Alert?.learnMoreUrl?.locale ?? '';

  return (
    <InlineAlert
      backgroundColor="#f5f5f5"
      title={heading}
      message={body}
      action={
        learnMoreText &&
        learnMoreUrl && (
          <ActionButton
            marginLeft="auto"
            size={ActionButtonSizes.SMALL}
            variant={ActionButtonVariants.TEXT_ONLY}
            to={learnMoreUrl}
          >
            {learnMoreText}
          </ActionButton>
        )
      }
      status="info"
    />
  );
};

const dynamicComponentMapByType = {
  Alert: LandingPageAlert,
} as const;

type SeparateOrderingCtasTreatmentProps = {
  componentsData: ReadonlyArray<{ _id: string; __typename: 'Alert' } | null>;
};

function SeparateOrderingCtasTreatment(props: SeparateOrderingCtasTreatmentProps) {
  const { formatMessage } = useIntl();

  const isCateringEnabled = useFlag(LaunchDarklyFlag.ENABLE_CATERING);
  const isDeliveryEnabled = useFlag(LaunchDarklyFlag.ENABLE_DELIVERY);

  return (
    <VStack flexGrow={1} space="$2" paddingBottom="$13.5">
      {props.componentsData.map(componentData => {
        if (!componentData) {
          return null;
        }

        const DynamicComponent = dynamicComponentMapByType[componentData.__typename];
        return <DynamicComponent key={componentData._id} id={componentData._id} />;
      })}
      <Box flexGrow={999} flexShrink={1}></Box>
      <VStack flexGrow={1} space="$2" paddingX="$4" flexBasis="140">
        <ActionButton
          leftIcon={<Icon variant="cart" size="4" />}
          variant={ActionButtonVariants.INVERSE}
          to={formatMessage({ id: 'routes.storeLocator' })}
        >
          {formatMessage({ id: 'pickUpOrder' })}
        </ActionButton>
        {isDeliveryEnabled && (
          <ActionButton
            leftIcon={<Icon variant="delivery" size="4" />}
            variant={ActionButtonVariants.INVERSE}
            to={formatMessage({ id: 'routes.storeLocator.address' })}
          >
            {formatMessage({ id: 'deliveryOrder' })}
          </ActionButton>
        )}
        {isCateringEnabled && (
          <ActionButton
            leftIcon={<Icon variant="catering" size="4" />}
            variant={ActionButtonVariants.INVERSE}
            to={formatMessage({ id: 'routes.storeLocator.serviceModeCatering' })}
          >
            {formatMessage({ id: 'cateringOrder' })}
          </ActionButton>
        )}
      </VStack>
    </VStack>
  );
}

export function LandingPage() {
  const { data } = useFeatureSimplifiedLandingPage();

  const localizedBgImage = useLocaleImage(data?.backgroundImage);

  return (
    <Box flexGrow={1} flexShrink={0} position="relative">
      <Layer>
        <Picture
          width="100%"
          height="100%"
          image={localizedBgImage}
          alt=""
          resolutionMultiplier={1.5}
        />
      </Layer>

      <Layer>
        <SeparateOrderingCtasTreatment componentsData={data?.components ?? []} />
      </Layer>
    </Box>
  );
}
