import React from 'react';

import { useIntl } from 'react-intl';

import Link from 'components/link';

export const CCPAButtonLink: React.FC = () => {
  const { formatMessage } = useIntl();

  return (
    <Link onPress={() => window.OneTrust.ToggleInfoDisplay()}>
      {formatMessage({ id: 'CCPADontSellButton' })}
    </Link>
  );
};
