import { REGIONS } from 'state/intl/types';
import { getConfigValue } from 'utils/environment/config';

export const PROD_SUPPORTED_REGIONS =
  {
    US: {
      US: REGIONS.US,
    },
    CA: {
      CA: REGIONS.CA,
    },
    // TODO: RN - locale switching -- should pull from specific region
  }[getConfigValue({ key: 'buildRegion' }).toUpperCase()] || {};

// TODO: RN - locale switching -- should pull from specific region
export const PROD_DEFAULT_REGION = getConfigValue({ key: 'buildRegion' }).toUpperCase();
