import React from 'react';

import { useIntl } from 'react-intl';

import { ActionButtonSizes, ActionButtonVariants } from 'components/action-button';
import { useNavigation } from 'hooks/navigation/use-navigation';
import { useFlag } from 'state/launchdarkly';
import { useIsLoyaltyEnabled } from 'state/loyalty/hooks/use-is-loyalty-enabled';
import { useLoyaltyUser } from 'state/loyalty/hooks/use-loyalty-user';
import { useReversedUIContext } from 'state/reversed-ui';
import { LaunchDarklyFlag } from 'utils/launchdarkly';

import { StyledLoyaltyButton, StyledLoyaltyPointsIcon } from './loyalty-points-button.styled';
import componentTheme from './theme';

const LoyaltyPointsButton = () => {
  const enableLoyaltyPointsInHeader = useFlag(LaunchDarklyFlag.ENABLE_LOYALTY_POINTS_IN_HEADER);
  const { navigate } = useNavigation();
  const { formatMessage } = useIntl();
  const { reversedUI } = useReversedUIContext();

  const loyaltyEnabled = useIsLoyaltyEnabled();
  const { loyaltyUser, loading: loyaltyLoading } = useLoyaltyUser();
  const showLoyaltyCta = Boolean(
    enableLoyaltyPointsInHeader && !loyaltyLoading && loyaltyEnabled && loyaltyUser
  );

  const handleOnPress = () => {
    navigate(formatMessage({ id: 'routes.loyaltyDashboard' }));
  };

  return showLoyaltyCta ? (
    <StyledLoyaltyButton
      mr={{ base: '$0', md: '$4' }}
      variant={ActionButtonVariants.OUTLINE}
      size={ActionButtonSizes.SMALL}
      testID="app-header-loyalty-cta"
      onPress={handleOnPress}
      reversed={reversedUI}
      startIcon={<StyledLoyaltyPointsIcon reversed={reversedUI} />}
      accessibilityLabel={`${String(loyaltyUser?.points)} ${formatMessage({
        id: componentTheme.points,
      })}`}
    >
      {String(loyaltyUser?.points)}
    </StyledLoyaltyButton>
  ) : null;
};
export default LoyaltyPointsButton;
