import { memoize } from 'lodash-es';

import logger from 'utils/logger';

const importMap = {
  en_CA: () => import('state/translations/en_CA.json'),
  en_US: () => import('state/translations/en.json'),
  en: () => import('state/translations/en.json'),
  fr_CA: () => import('state/translations/fr_CA.json'),
  fr: () => import('state/translations/fr.json'),
};

export const getMessagesForLanguage = memoize(async (language: string, region: string) => {
  let regionLocale;
  let baseLocale;

  try {
    regionLocale = await importMap[`${language}_${region}`]();
  } catch (e) {
    // @ts-expect-error TS(2345) FIXME: Argument of type 'unknown' is not assignable to pa... Remove this comment to see the full error message
    logger.warn(e);
  }

  try {
    baseLocale = await importMap[language]();
  } catch (e) {
    // @ts-expect-error TS(2345) FIXME: Argument of type 'unknown' is not assignable to pa... Remove this comment to see the full error message
    logger.warn(e);
  }

  return { ...(baseLocale || {}), ...(regionLocale || {}) };
});
