import { Dispatch, SetStateAction, useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { isEqual } from 'lodash-es';

import { IStore, IStoreAddress } from '@rbi-ctg/store';
import { usePrevious } from 'hooks/use-previous';
import { LaunchDarklyFlag, useFlag, useLDContext } from 'state/launchdarkly';
import { useServiceModeContext } from 'state/service-mode';
import LocalStorage, { StorageKeys } from 'utils/local-storage';
import { initStore } from 'utils/preload-store';
import { remapStore } from 'utils/restaurant';

import {
  IPreloadedStoreState,
  IStoreContext,
  IUseStore,
  SelectedStore,
  Store,
  StoreProxy,
  StoreState,
} from './types';
import { resetLastTimeStoreUpdated } from './use-store-utils';
import { initialStoreProxyState } from './utils';

const preloadedStoreInfo = (): IPreloadedStoreState =>
  LocalStorage.getItem(StorageKeys.STORE) || ({} as IPreloadedStoreState);

export const initialStoreState: StoreState = {
  hasSelection: false,
  selectedUnavailableStoreName: '',
  physicalAddress: {} as IStoreAddress,
  chaseMerchantId: '',
  selectedUnavailableStoreNumber: null,
};

export const createStoreProxyFromStore = (store: StoreState | Store) => {
  const storeHasPhysicalAddress =
    store && store.physicalAddress && !!Object.keys(store.physicalAddress).length;

  let storeProxy: StoreProxy = { ...initialStoreProxyState };

  if (store === null) {
    return storeProxy;
  }

  if ('hasSelection' in store) {
    const { hasSelection, ...storeDetails } = store;

    if (hasSelection) {
      storeProxy = { ...storeProxy, ...storeDetails };
    } else {
      // unavailable stores in store selection 1.0 only have 3 properties that we care about
      storeProxy.name = store.hasSelection
        ? // @ts-expect-error TS(2339) FIXME: Property 'name' does not exist on type 'never'.
          store.name
        : store.selectedUnavailableStoreName || null;
      storeProxy.number = store.hasSelection
        ? // @ts-expect-error TS(2339) FIXME: Property 'number' does not exist on type 'never'.
          store.number
        : store.selectedUnavailableStoreNumber ?? null;
      storeProxy.physicalAddress = storeHasPhysicalAddress ? store.physicalAddress : null;
    }
  } else {
    // does not have 'hasSelection' property
    storeProxy = store;
  }

  return storeProxy;
};

export const useStore = (): IUseStore => {
  const enableStoreSelection2_0 = useFlag(LaunchDarklyFlag.ENABLE_STORE_SELECTION_2_0);
  const { updateUserStore } = useLDContext();
  const { serviceMode } = useServiceModeContext();
  const preloaded = useMemo(() => preloadedStoreInfo(), []);
  const userSelection = useRef(false);

  /** Explicit state of provider */
  const [_storeState, _setStoreState] = useState<StoreState | Store>(
    initStore(preloaded, serviceMode)
  );
  const storeState = useMemo(() => remapStore(_storeState), [_storeState]);
  const setStoreState = useCallback<Dispatch<SetStateAction<StoreState | Store>>>(
    newStore =>
      _setStoreState(s => {
        const value = typeof newStore === 'function' ? newStore(s) : newStore;

        // Store restaurant in local storage
        LocalStorage.setItem(StorageKeys.STORE, {
          store: value,
        });

        if (!enableStoreSelection2_0 && !value) {
          return initialStoreState;
        }

        if (isEqual(value, s)) {
          return s;
        }

        return value;
      }),
    [enableStoreSelection2_0]
  );

  /** Change store back to initial state (excluding prefetch) */
  const resetStoreState = useCallback<IStoreContext['resetStore']>(() => {
    setStoreState(null);
  }, [setStoreState]);

  /**
   * Only used in store selection 1.0
   * Selects an unavailable store
   */
  const selectUnavailableStore = useCallback(
    ({
      name,
      number,
      physicalAddress,
    }: {
      name?: string | null;
      number?: string | null;
      physicalAddress?: IStoreAddress | null;
    }) => {
      if (!enableStoreSelection2_0) {
        setStoreState({
          ...initialStoreState,
          selectedUnavailableStoreName: name ?? initialStoreState.selectedUnavailableStoreName,
          physicalAddress: physicalAddress ?? initialStoreState.physicalAddress,
          selectedUnavailableStoreNumber:
            number ?? initialStoreState.selectedUnavailableStoreNumber,
        });
      }
    },
    [enableStoreSelection2_0, setStoreState]
  );

  const onConfirmStoreChange: IUseStore['onConfirmStoreChange'] = useCallback(
    async args => {
      if (!args) {
        return;
      }

      const { newStore, callback } = args;
      const store: SelectedStore | IStore = { ...newStore };
      if (!enableStoreSelection2_0) {
        (store as SelectedStore).hasSelection = true;
      }

      setStoreState(store);

      resetLastTimeStoreUpdated();
      if (typeof callback === 'function') {
        await callback();
      }
      userSelection.current = false;
    },
    [enableStoreSelection2_0, setStoreState]
  );

  const previousEnableStoreSelection2_0 = usePrevious(enableStoreSelection2_0);
  useEffect(() => {
    // Switching between store selection 1.0 and 2.0
    if (
      // Reset store when the LD flag changes to make sure we don't pass the wrong store type
      !!previousEnableStoreSelection2_0 !== !!enableStoreSelection2_0
    ) {
      setStoreState(initStore(preloadedStoreInfo(), serviceMode));
    }
  }, [enableStoreSelection2_0, previousEnableStoreSelection2_0, serviceMode, setStoreState]);

  useEffect(() => {
    if (!userSelection.current) {
      // Update the user store in LD
      updateUserStore(storeState as Store);
    }
  }, [storeState, updateUserStore]);

  const updateUserStoreWithCallback = useCallback(
    async (store: Store, callback: () => Promise<void>) => {
      userSelection.current = true;
      updateUserStore(store);
      await callback();
    },
    [updateUserStore]
  );

  /** An object similar to the {@link:IStore} type but all properties can be null */
  const storeProxy: StoreProxy = useMemo(() => createStoreProxyFromStore(storeState), [storeState]);

  return useMemo(
    () => ({
      resetStore: resetStoreState,
      setStore: setStoreState,
      store: storeState,
      selectUnavailableStore,
      onConfirmStoreChange,
      storeProxy,
      updateUserStoreWithCallback,
    }),
    [
      onConfirmStoreChange,
      resetStoreState,
      selectUnavailableStore,
      setStoreState,
      storeProxy,
      storeState,
      updateUserStoreWithCallback,
    ]
  );
};
