import * as React from 'react';

import { makeUclComponent } from '@rbilabs/universal-components';

import { useNavigation } from 'hooks/navigation/use-navigation';
import { routes } from 'utils/routing';

import {
  ActionContainer,
  Container,
  Details,
  DetailsContainer,
  Heading,
  IconContainer,
  NotificationWrapper,
} from './top-service-mode-notification.styled';
import { ITopServiceModeNotificationViewProps } from './types';

const TopServiceModeNotificationView: React.FC<React.PropsWithChildren<
  ITopServiceModeNotificationViewProps
>> = ({ heading, icon, orderId, details, cta, ...rest }) => {
  const { linkTo } = useNavigation();

  const navigateToOrder = () => {
    if (orderId) {
      linkTo(`${routes.orderConfirmation}/${orderId}`);
    }
  };

  return (
    <Container {...rest}>
      <NotificationWrapper>
        <IconContainer testID="service-notification-icon">{icon}</IconContainer>
        <DetailsContainer onPress={() => navigateToOrder()}>
          {heading && <Heading testID="service-notification-heading">{heading}</Heading>}
          {details && <Details testID="service-notification-details">{details}</Details>}
        </DetailsContainer>
        <ActionContainer>{cta}</ActionContainer>
      </NotificationWrapper>
    </Container>
  );
};

export default makeUclComponent(TopServiceModeNotificationView);
