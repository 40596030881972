import { useCallback, useMemo } from 'react';

import { useStaticMenuExperiment } from 'experiments/static-menu/use-static-menu-experiment';
import { useNavigation } from 'hooks/navigation/use-navigation';
import { useRoute } from 'hooks/navigation/use-route';
import useRoutes from 'hooks/use-routes';
import { useLocationContext } from 'state/location';
import { useStoreContext } from 'state/store';

import { shouldUseStaticMenuFlag } from './constants';

interface UseStoreRequiredResult {
  handleStoreLocatorRedirect: () => void;
  isStoreRequired: boolean;
}

export const useStoreRequired = (): UseStoreRequiredResult => {
  const { noStoreSelected } = useStoreContext();
  const { pathname } = useRoute();
  const { navigate } = useNavigation();
  const { setStoreLocatorCallbackUrl } = useLocationContext();
  const { getLocalizedRouteForPath } = useRoutes();

  const enableStaticMenu = useStaticMenuExperiment();

  const isStoreRequired = useMemo(() => {
    if (!shouldUseStaticMenuFlag) {
      return noStoreSelected;
    }
    return noStoreSelected && !enableStaticMenu;
  }, [enableStaticMenu, noStoreSelected]);

  const handleStoreLocatorRedirect = useCallback(() => {
    setStoreLocatorCallbackUrl(pathname); // TODO: React Navigation - Determine if this is still needed
    navigate(getLocalizedRouteForPath('store-locator'), { replace: true });
  }, [getLocalizedRouteForPath, navigate, pathname, setStoreLocatorCallbackUrl]);

  return {
    handleStoreLocatorRedirect,
    isStoreRequired,
  };
};
