// TODO: RN - Remove this and use it from components-library utils/internal'

type Falsy = false | 0 | '' | null | undefined;

/**
 * Assembles strings from potentially falsey parts for use in aria-describedby, className, etc.
 */
export const combineAttributeStrings = (...args: (string | Falsy)[]) => {
  const filtered = args.filter((a): a is string => !!a);

  return filtered.length !== 0 ? filtered.join(' ') : undefined;
};
/**
 * Assembles a string with an asterisks postfix when required is true.
 */
export const getRequiredLabel = (label: string, required?: boolean) => {
  if (label.endsWith('*')) {
    return label;
  }

  return combineAttributeStrings(label, required ? '*' : undefined);
};
