import { Box, Link, Text } from '@rbilabs/universal-components';

import TextButton from 'components/text-button';

export const Container = Box.withConfig({
  backgroundColor: Styles.color.validated,
  width: 'full',
  alignItems: 'center',
  paddingX: '$6',
  paddingY: '$4',
  justifyContent: 'center',
});

export const InfoText = Text.withConfig({
  color: Styles.color.white,
  marginBottom: '$4',
});

export const CloseButton = TextButton.withConfig({
  paddingRight: '$6',
  color: Styles.color.white,
  fontWeight: Styles.fontWeight.heavy,
});

export const CookieNoticeLink = Link.withConfig({
  color: Styles.color.white,
  fontWeight: Styles.fontWeight.heavy,
  isUnderlined: true,

  _web: {
    cursor: 'pointer',
  },
});

export const ButtonsContainer = Box.withConfig({
  justifyContent: 'center',
  alignItems: 'center',
  maxWidth: '100%',
});
