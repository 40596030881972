import { Box, HStack } from '@rbilabs/universal-components';

export const Container = HStack.withConfig({
  width: 'full',
  paddingY: '$4',
  paddingX: '$6',
  justifyContent: 'space-between',
});

export const ElementWrapper = Box.withConfig({
  alignItems: 'center',
  justifyContent: 'center',
});
