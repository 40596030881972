import { Box, Header, TextProps } from '@rbilabs/universal-components';

import Picture from 'components/picture';

const defaultTextAlign: TextProps = {
  textAlign: {
    base: 'center',
    md: 'left',
  },
};

export const BasicTileContainer = Box.withConfig({
  padding: {
    base: 0,
    md: '$6',
  },
  paddingTop: {
    base: '$2',
    md: '$6',
  },
  paddingBottom: {
    base: '$1',
    md: '$6',
  },
  flexDirection: {
    base: 'column',
    md: 'row',
  },
  alignItems: 'center',
  justifyContent: {
    base: 'center',
    md: 'flex-start',
  },
  borderRadius: 'lg',
  backgroundColor: 'transparent',
});

export const BasicTileTitle = Header.withConfig({
  variant: 'headerThree',
  margin: 0,
  marginBottom: '$2',
  color: Styles.color.white,
  fontSize: {
    base: 'sm',
    md: 'lg',
  },
  lineHeight: '2xs',
  textAlign: defaultTextAlign.textAlign,
});

export const BasicTileImage = Picture.withConfig({
  height: 'auto',
  minHeight: { base: '$2', md: '$8' },
  maxHeight: { base: '$10', md: '$20' },
  maxWidth: { base: '$10', md: '$20' },
  marginBottom: {
    base: '$1',
    md: 0,
  },
  marginLeft: {
    base: 0,
    md: '$6',
  },
});
