import React, { useEffect, useMemo } from 'react';
import type { FC, PropsWithChildren } from 'react';

import { ScrollView as UCLScrollView, View } from '@rbilabs/universal-components';

import Footer from 'components/footer';
import { useRoute } from 'hooks/navigation/use-route';
import { useScrollContext } from 'state/scroll';
import { WEB_APP_DESKTOP_LG_MAX_WIDTH, WEB_APP_MAX_WIDTH } from 'utils/constants';
import { isWeb } from 'utils/environment';
import { isCartRoute, isItemPickerOrComboRoute, isOrderConfirmationRoute } from 'utils/routing';

const ScrollWebWrapper = UCLScrollView.withConfig<{ delegateScrollToScreen: boolean }>(p => ({
  showsVerticalScrollIndicator: true,
  _web: {
    width: 'full',
    _contentContainerStyle: {
      flexShrink: {
        base: 1,
        md: p.delegateScrollToScreen ? 1 : 0,
      },
      flexGrow: 1,
    },
  },
}));

// only web needs a ScrollView component to show all the content with a scroll if necessary,
// on mobile each view should decide if use a FlatList or a ScrollView
const ScrollWrapper = isWeb ? ScrollWebWrapper : View;
const ScrollView = ScrollWrapper.withConfig({
  flex: 1,
  overflowX: 'hidden',
  nativeID: 'main',
});

export const ScrollContainer: FC<PropsWithChildren<{}>> = ({ children }) => {
  const { scrollRef, scrollTo } = useScrollContext();
  const { pathname } = useRoute();
  const delegateScrollToScreen = useMemo(() => {
    return (
      isWeb &&
      (isItemPickerOrComboRoute(pathname) ||
        isCartRoute(pathname) ||
        isOrderConfirmationRoute(pathname))
    );
  }, [pathname]);
  useEffect(() => {
    if (!delegateScrollToScreen) {
      scrollTo(0, 0);
    }
  }, [delegateScrollToScreen, pathname, scrollTo]);
  return (
    <ScrollView delegateScrollToScreen={delegateScrollToScreen} ref={scrollRef}>
      <View
        flexShrink={{
          base: 1,
          md: delegateScrollToScreen ? 1 : 0,
        }}
        flexGrow={1}
        overflowX={{
          base: 'hidden',
          md: 'auto',
        }}
        _web={{
          width: {
            base: 'full',
            md: delegateScrollToScreen ? undefined : WEB_APP_MAX_WIDTH,
            lg: delegateScrollToScreen ? undefined : WEB_APP_DESKTOP_LG_MAX_WIDTH,
          },
          mx: 'auto',
        }}
        nativeID="VIEW-WRAPPER"
      >
        {children}
      </View>
      <Footer />
    </ScrollView>
  );
};
