import { useMemo } from 'react';

import { useApolloClient } from '@apollo/client';

import { IOffersFragment, OffersFragmentDoc } from 'generated/graphql-gateway';
import { useFeatureSortedLoyaltyOffersQuery } from 'generated/sanity-graphql';

import { createLoyaltyOffer } from './create-loyalty-offer';
import { useGetLoyaltyOffersQueryWithContext } from './hooks/engine/use-engine-query-with-context';
import { useLoyaltyPersonalizedOffer } from './hooks/use-loyalty-personalized-offer-detail';
import { isPersonalizedEngineOffer } from './hooks/utils';

export const useLoyaltyOfferDetail = ({ offerEngineId }: { offerEngineId: string }) => {
  const client = useApolloClient();
  // try to get the offer from the cache first
  // not using apollo redirect to not affect other queries which need to evaluate the offer
  const loyaltyOfferFragment = client.readFragment<IOffersFragment>({
    id: `LoyaltyOffer:${offerEngineId}`,
    fragmentName: 'OffersFragment',
    fragment: OffersFragmentDoc,
  });

  const {
    data: engineOffersData,
    loading: loadingEngineOffers,
  } = useGetLoyaltyOffersQueryWithContext({
    variables: {
      ids: [offerEngineId],
    },
    skip: !offerEngineId || !!loyaltyOfferFragment,
  });
  const engineOffer = engineOffersData?.loyaltyOffersV2?.[0] || loyaltyOfferFragment;

  const {
    personalizedOffer,
    loading: loadingPersonalizedOffersData,
  } = useLoyaltyPersonalizedOffer({ engineOffer });

  const {
    data: sortedCmsOffers,
    loading: loadingSortedCmsOffers,
  } = useFeatureSortedLoyaltyOffersQuery({
    notifyOnNetworkStatusChange: true,
    variables: {
      id: 'feature-loyalty-offers-ui-singleton',
    },
    skip: !engineOffer || isPersonalizedEngineOffer(engineOffer),
  });
  const sortedSystemwideOffers = sortedCmsOffers?.LoyaltyOffersUI?.sortedSystemwideOffers ?? null;

  const offer = useMemo(() => {
    if (
      loadingEngineOffers ||
      loadingPersonalizedOffersData ||
      loadingSortedCmsOffers ||
      !engineOffer
    ) {
      return null;
    }

    const cmsOffer = isPersonalizedEngineOffer(engineOffer)
      ? personalizedOffer
      : sortedSystemwideOffers?.find(entry => entry?.loyaltyEngineId === engineOffer.id);

    if (!cmsOffer) {
      return null;
    }

    return createLoyaltyOffer(cmsOffer, engineOffer);
  }, [
    engineOffer,
    loadingEngineOffers,
    loadingPersonalizedOffersData,
    loadingSortedCmsOffers,
    personalizedOffer,
    sortedSystemwideOffers,
  ]);

  return {
    loading: loadingEngineOffers || loadingPersonalizedOffersData,
    offer,
  };
};
