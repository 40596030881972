import { Box, Text } from '@rbilabs/universal-components';

export const StyledPoints = Text.withConfig({
  fontSize: '6xl',
  lineHeight: '2xs',
  fontFamily: 'heading',
  fontWeight: 'bold',
  marginRight: '$2',
});

export const Div = Box.withConfig({
  paddingX: '$4',
  paddingTop: '$6',
});

export const Wrapper = Box.withConfig({
  height: '100%',
  marginTop: '$2',
  backgroundColor: Styles.color.white,
});

export const RewardsText = Text.withConfig({
  marginTop: '$1',
  width: '100%',
});

export const LoadingContainer = Box.withConfig({
  alignItems: 'center',
  justifyContent: 'center',
  minHeight: '32px',
  width: '100%',
});
