import { LANGUAGES, REGIONS } from 'state/intl/types';

export enum PROD_SUPPORTED_LANGUAGES {
  en = LANGUAGES.en,
  fr = LANGUAGES.fr,
}

export const PROD_DEFAULT_LANGUAGE = {
  [REGIONS.US]: PROD_SUPPORTED_LANGUAGES.en,
  [REGIONS.CA]: PROD_SUPPORTED_LANGUAGES.en,
};
