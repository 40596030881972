import { IAmplitudeUserAttributes } from 'state/amplitude/types';
import ReactAppboy from 'utils/appboy';
import logger from 'utils/logger';

export const setBrazeUserAttributes = (
  brazeAttributes: IAmplitudeUserAttributes & {
    name?: string;
    phoneNumber?: string;
  }
) => {
  const { setFirstName, setPhoneNumber, setCustomUserAttribute } = ReactAppboy;

  for (const key in brazeAttributes) {
    const value = brazeAttributes[key];
    try {
      switch (key) {
        case 'name':
          if (value) {
            setFirstName(value);
          }
          break;
        case 'phoneNumber':
          if (value) {
            setPhoneNumber(value);
          }
          break;
        default:
          setCustomUserAttribute(key, value);
          break;
      }
    } catch (error) {
      logger.warn({ error, message: 'Error setting Braze User Attributes' });
    }
  }
};
