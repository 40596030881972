import { Box, Header, Text } from '@rbilabs/universal-components';

import {
  OFFER_CONTAINER_PADDING,
  OFFER_HEIGHT_DESKTOP,
  OFFER_HEIGHT_MOBILE,
} from 'components/offer/ui-refresh/cell-layout';

export const Title = Header.withConfig({
  variant: 'headerTwo',
  accessibilityLevel: 2,
  margin: 0,
});

export const Body = Text.withConfig({
  variant: 'copyOne',
  margin: '0.5',
  fontSize: {
    sm: 'md',
  },
});

export const Wrapper = Box.withConfig({
  alignItems: 'center',
  background: Styles.color.white,
  padding: OFFER_CONTAINER_PADDING,
  height: OFFER_HEIGHT_MOBILE,
  minHeight: {
    md: OFFER_HEIGHT_DESKTOP,
  },
});
