import { useMemo } from 'react';

import { useLocation, useParams } from 'react-router-dom';

import { convertQueryStringToObject } from 'utils/navigation';

import { IRouteHook } from './types';

// TODO: GST The return type T is currently not guaranteed to match the params extracted from the query string / state
export const useRoute = <T extends object>(): IRouteHook<T> => {
  const reactRouterLocation = useLocation();
  const reactRouterParams = useParams();

  const params = useMemo<T>(() => {
    const stateParams =
      (typeof reactRouterLocation.state === 'object' ? reactRouterLocation.state : {}) ?? {};
    const queryString = reactRouterLocation.search;

    const queryStringParams = queryString ? convertQueryStringToObject<T>(queryString) : {};
    return { ...queryStringParams, ...reactRouterParams, ...stateParams } as T;
  }, [reactRouterLocation.search, reactRouterLocation.state, reactRouterParams]);

  return {
    params,
    path: undefined, // Path is not supported in web
    pathname: reactRouterLocation.pathname,
  };
};
