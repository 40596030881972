import React, { FC } from 'react';

import { Box, Stack } from '@rbilabs/universal-components';
import { useIntl } from 'react-intl';

import { useNavigation } from 'hooks/navigation/use-navigation';
import { useFeatureLoyaltyRewardListCategoriesOnly } from 'hooks/use-feature-loyalty-reward-list-categories-only';
import { useLoyaltyUser } from 'state/loyalty/hooks/use-loyalty-user';

import { PromoMessage } from './promo-message';
import {
  AuthenticatedContainer,
  AvailableCrownsCaption,
  CurrentProgress,
  DashboardButton,
  PointsContainer,
  PointsProgress,
  StyledLoyaltyPointsIcon,
  TierDot,
  TierText,
  UserCrownPointsContainer,
  UserPoints,
} from './styled';
import { BannerTier } from './types';
import { getProgressBarValue, mapCategoriesToTiers, sanitizeStringToNumber } from './utils';

const INITIAL_TIER_VALUE = 0;

const AuthenticatedBanner: FC<React.PropsWithChildren<unknown>> = () => {
  const {
    data: categories,
    loading: loadingCategories,
  } = useFeatureLoyaltyRewardListCategoriesOnly();

  const { loyaltyUser } = useLoyaltyUser();
  const { formatMessage } = useIntl();
  const { navigate } = useNavigation();

  const redeemClick = () => {
    navigate(formatMessage({ id: 'routes.loyaltyBannerAuthenticated' }));
  };

  if (loadingCategories || !categories?.length) {
    return null;
  }
  const bestPromoId = loyaltyUser?.bestPromotion?.sanityId;
  const userPoints = loyaltyUser?.points || 0;
  const tiers: BannerTier[] = mapCategoriesToTiers(categories);

  return (
    <AuthenticatedContainer testID="authenticated-banner" hasPromoMessage={!!bestPromoId}>
      {bestPromoId && <PromoMessage promoId={bestPromoId} />}
      <PointsContainer>
        <Stack>
          <UserCrownPointsContainer>
            <StyledLoyaltyPointsIcon />
            <UserPoints>{userPoints}</UserPoints>
          </UserCrownPointsContainer>
          <AvailableCrownsCaption>
            {formatMessage({ id: 'availableCrowns' })}
          </AvailableCrownsCaption>
        </Stack>
        <DashboardButton onPress={redeemClick}>
          {formatMessage({ id: 'loyaltyBannerDashboardButtonLabel' })}
        </DashboardButton>
      </PointsContainer>
      <PointsProgress>
        <Box>
          <TierDot completed={userPoints > INITIAL_TIER_VALUE} />
          <TierText left={-2}>{INITIAL_TIER_VALUE}</TierText>
        </Box>
        {tiers.map((tier: BannerTier, index) => {
          const tierText = tier.value;
          const isLast = tiers.length === index + 1;
          const completed = sanitizeStringToNumber(tierText) <= userPoints;
          const tierByPercentage = (100 / tiers.length) * (index + 1);
          const tierDotLeft = isLast ? tierByPercentage - 1 : tierByPercentage;
          const leftText =
            !isLast || tiers.length === 1
              ? tierDotLeft - tierText.length
              : tierDotLeft - tiers[index - 1].value.length;

          return (
            <Box key={tier.id}>
              <TierDot left={tierDotLeft} completed={completed} />
              <TierText left={leftText}>{tierText}</TierText>
            </Box>
          );
        })}
        <CurrentProgress $progress={getProgressBarValue(userPoints, tiers)} />
      </PointsProgress>
    </AuthenticatedContainer>
  );
};

export default AuthenticatedBanner;
