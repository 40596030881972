import { Helmet } from 'components/helmet';

const baseGlobalStyles = /*css*/ `
  /**
   * Styles to disable bound scrolling on iOS
   * https://stackoverflow.com/a/42055497
   */
  html {
    overflow: hidden;
  }
`;

export const GlobalStylesheet = () => (
  <Helmet>
    <style>{baseGlobalStyles}</style>
  </Helmet>
);
