import { Box } from '@rbilabs/universal-components';

export const MobileWebNavigationBorder = Box.withConfig({
  background: Styles.color.white,
  style: {
    // TODO: RN - I'm struggling to get these to not crash the app.
    // shadowColor: Styles.shadowColor,
    // shadowOffset: {
    //   width: Styles.shadowOffsetX,
    //   height: Styles.shadowOffsetY,
    // },
    // shadowRadius: Styles.shadowRadius,
  },
  width: 'full',
  zIndex: Styles.zIndex.overlay,
});
