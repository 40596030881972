import React from 'react';

import { Button, makeUclComponent } from '@rbilabs/universal-components';
import { useIntl } from 'react-intl';

import ActionButton, { ActionButtonVariants } from 'components/action-button';
import Dialog from 'components/dialog';

export interface ICurbsideConfirmationModalProps {
  onKeepOrder: () => void;
  onCancelOrder: () => void;
  onDismiss: () => void;
  showDialog: boolean;
}

const StyledButton = makeUclComponent(ActionButton).withConfig({
  marginBottom: '3',
});

const CurbsideConfirmationModal: React.FC<React.PropsWithChildren<
  ICurbsideConfirmationModalProps
>> = ({ showDialog, onKeepOrder, onCancelOrder, onDismiss }) => {
  const { formatMessage } = useIntl();
  return (
    <Dialog
      showDialog={showDialog}
      testID="curbside-confirmation-modal"
      onDismiss={onDismiss}
      modalAppearanceEventMessage="Curbside Confirmation Modal"
      heading={formatMessage({ id: 'cancelCurbsideOrderTitle' })}
      body={formatMessage({ id: 'cancelCurbsideOrderText' })}
      actions={
        <Button.Group>
          <StyledButton fullWidth onPress={onKeepOrder} testID="keep-curbside-order">
            {formatMessage({ id: 'keepCurbsideOrder' })}
          </StyledButton>
          <StyledButton
            variant={ActionButtonVariants.OUTLINE}
            fullWidth
            onPress={onCancelOrder}
            testID="cancel-curbside-order"
          >
            {formatMessage({ id: 'cancelCurbsideOrder' })}
          </StyledButton>
        </Button.Group>
      }
    />
  );
};

export default CurbsideConfirmationModal;
