import React, { useCallback, useState } from 'react';

import { get } from 'lodash-es';
import { useIntl } from 'react-intl';

import { DialogActionSheet } from 'components/confirmation-actionsheet';
import RestaurantRedemptionModal from 'components/offer-redemption-modal/redemption-modal';
import ReplaceRewardModal from 'components/replace-reward-modal';
import { useStaticMenuExperiment } from 'experiments/static-menu/use-static-menu-experiment';
import {
  LoyaltyPromotionType,
  useCreateOrderlessTransactionMutation,
} from 'generated/graphql-gateway';
import { OfferType } from 'generated/rbi-graphql';
import { useFeatureEarningCalculationQuery } from 'generated/sanity-graphql';
import { useNavigation } from 'hooks/navigation/use-navigation';
import { useRoute } from 'hooks/navigation/use-route';
import { appendObjectToQueryString } from 'hooks/navigation/utils';
import useDialogModal from 'hooks/use-dialog-modal';
import { useLoyaltyRewardsList } from 'hooks/use-loyalty-rewards-list';
import { useSwitchCartMethodDialog } from 'hooks/use-switch-cart-method-dialog';
import { useToast } from 'hooks/use-toast';
import { parseIncentiveData } from 'pages/loyalty/loyalty-incentives-components/incentive-card/parse-incentive-data';
import {
  getMenuItemDescriptorForIncentive,
  makeUrlForIncentive,
} from 'pages/loyalty/loyalty-incentives-components/incentive-details/utils';
import { ModalRewardItemUnavailable } from 'pages/loyalty/loyalty-incentives-components/modal-reward-item-unavailable-modal';
import { CustomEventNames, EventTypes, useCRMEventsContext } from 'state/crm-events';
import { actions, selectors, useAppDispatch, useAppSelector } from 'state/global-state';
import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';
import { useLocationContext } from 'state/location';
import { useLoyaltyUser } from 'state/loyalty/hooks/use-loyalty-user';
import { useInRestaurantRedemptionContext } from 'state/loyalty/in-restaurant-redemption';
import { ICreateCartEntryParams } from 'state/loyalty/in-restaurant-redemption/hooks/use-in-restaurant-redemption-cart';
import { canStackReward } from 'state/loyalty/in-restaurant-redemption/hooks/use-in-restaurant-redemption-cart/utils';
import {
  LoyaltyAppliedOffer,
  isLegacyOffer,
  isLoyaltyOffer,
  isReward,
  isSurpriseOffer,
} from 'state/loyalty/types';
import {
  getCanStackOfferCheck,
  isAppliedOfferSwap,
  isDiscountLoyaltyOffer,
} from 'state/loyalty/utils';
import { useMenuContext } from 'state/menu';
import { useOrderContext } from 'state/order';
import { useServiceModeContext } from 'state/service-mode';
import { useStoreContext } from 'state/store';
import { DIFFERENCE_TIME_UNITS, getDifferenceToNow } from 'utils/dateTime';
import logger from 'utils/logger';
import { routes } from 'utils/routing';
import { getFirstStringInLocaleBlockContent } from 'utils/sanity';

import { AddToCartArgs, Incentive, RedemptionMethod } from './types';

/**
 * Wraps the param Component in another component in order to add the function
 * addIncentiveToCart which takes an incentive and a redemption type and adds it to the loyaltyContext
 * It also handles and shows the related modals for each situation.
 * Eg: Shows "only one coupon allowed" if you already have an Offer in the cart.
 *
 * @param Component The component you want to wrap with this logic
 */
const withAddIncentiveToCart = (Component: React.FC<React.PropsWithChildren<any>>) => {
  return (props: any) => {
    // Utils
    const toast = useToast();
    const { formatMessage } = useIntl();
    const { setStoreLocatorCallbackUrl } = useLocationContext();
    const { navigate, linkTo } = useNavigation();
    const { pathname } = useRoute();
    const enableStaticMenu = useStaticMenuExperiment();
    const enableCooldownToast = useFlag(LaunchDarklyFlag.ENABLE_COOLDOWN_TOAST_INFO);
    const enableMultipleRewards = useFlag(LaunchDarklyFlag.ENABLE_MULTIPLE_REWARDS_PER_ORDER);
    const enableLoyaltyAlohaIntegration = useFlag(
      LaunchDarklyFlag.ENABLE_LOYALTY_ALOHA_INTEGRATION
    );

    // State
    const [incentiveName, setIncentiveName] = useState<string>('');
    const [selectedIncentive, setSelectedIncentive] = useState<Incentive>();
    const [showOfferRedemptionModal, setShowOfferRedemptionModal] = useState(false);
    const [openReplaceDialog, setOpenReplaceDialog] = useState(false);
    const [openReplaceRewardDialog, setOpenReplaceRewardDialog] = useState<boolean>(false);
    const [selectedArgs, setSelectedArgs] = useState<AddToCartArgs>();

    // Context
    const { engineRewardsMap } = useLoyaltyRewardsList();
    const { noStoreSelected } = useStoreContext();
    const { setSelectedStaticMenuItem } = useMenuContext();
    const appliedOffers = useAppSelector(selectors.loyalty.selectAppliedOffers);
    const dispatch = useAppDispatch();
    const { serviceMode } = useServiceModeContext();
    const { removeFromCart, cartEntries } = useOrderContext();
    const { logRBIEvent } = useCRMEventsContext();
    const appliedLoyaltyRewards = useAppSelector(selectors.loyalty.selectAppliedLoyaltyRewards);

    const {
      inRestaurantRedemptionCart,
      inRestaurantLoyaltyEnabledAtRestaurant,
      inRestaurantRedemptionEnabled,
      addInRestaurantRedemptionEntry,
      enableOfferRedemption,
      shouldForceRestaurantSelection,
      clearInRestaurantRedemptionAllRewards,
    } = useInRestaurantRedemptionContext();
    const { loyaltyUser } = useLoyaltyUser();
    // @ts-ignore
    const [InvalidIncentiveModal, openInvalidIncentiveModal] = useDialogModal({
      modalAppearanceEventMessage: 'Invalid In Restaurant Incentive Redemption',
    });
    const [createOrderlessTransaction] = useCreateOrderlessTransactionMutation({
      variables: {
        input: {
          loyaltyId: loyaltyUser?.id,
          channel: 'ORDERLESS',
          incentives: [
            { id: selectedIncentive?.loyaltyEngineId || '', type: LoyaltyPromotionType.OFFER },
          ],
        },
      },
      onCompleted: () => {
        setShowOfferRedemptionModal(true);
      },
      onError: () =>
        openInvalidIncentiveModal({ message: formatMessage({ id: 'unableToPlaceOrder' }) }),
    });

    const { data: earningCalculationData } = useFeatureEarningCalculationQuery({
      variables: { id: 'earningCalculation' },
    });
    const diffInMin = getDifferenceToNow(
      DIFFERENCE_TIME_UNITS.MINUTES,
      loyaltyUser?.offerRedemptionAvailability?.availableAfter || ''
    );

    const showOfferCooldownToast = useCallback(() => {
      //If the diffInMin is greater than 0 it means that the user already have an offer in course so we do nothing.
      if (!enableCooldownToast || diffInMin > 0 || !serviceMode) {
        return;
      }

      toast.show({
        text: formatMessage(
          { id: 'offersCooldownInfo' },
          {
            period: earningCalculationData?.EarningCalculation?.offerRedemptionCooldownPeriod,
          }
        ),
        variant: 'neutral',
      });
    }, [
      diffInMin,
      earningCalculationData?.EarningCalculation?.offerRedemptionCooldownPeriod,
      enableCooldownToast,
      formatMessage,
      serviceMode,
      toast,
    ]);

    // Dialogs
    const [ReplaceOfferDialog, openReplaceOfferDialog] = useDialogModal({
      modalAppearanceEventMessage: 'Confirm Replace Coupon',
      // @ts-expect-error TS(2322) FIXME: Type '(data: {    args: AddToCartArgs;}) => void' ... Remove this comment to see the full error message
      onConfirm: (data: { args: AddToCartArgs }) => redeemIncentive(data.args),
    });

    const [ItemUnavailableModal, openItemUnavailableModal] = useDialogModal<
      {},
      { rewardName: string }
    >({
      // @ts-expect-error TS(2322) FIXME: Type 'FC<IUseDialogComponentProps & { rewardName: ... Remove this comment to see the full error message
      Component: ModalRewardItemUnavailable,
    });
    const [ReadyToRedeemDialog, openReadyToRedeemDialog] = useDialogModal({
      modalAppearanceEventMessage: 'Confirm Ready to Redeem',
      onConfirm: () => {
        if (selectedIncentive && isLoyaltyOffer(selectedIncentive)) {
          const name = selectedIncentive.name;
          logRBIEvent({
            name: CustomEventNames.LEGACY_OFFER_ADDED_IN_STORE_ORDER,
            type: EventTypes.Other,
            attributes: {
              sanityId: selectedIncentive._id || '',
              // @ts-expect-error TS(2345) FIXME: Argument of type '{ readonly __typename?: "LocaleB... Remove this comment to see the full error message
              name: name ? getFirstStringInLocaleBlockContent(name) : '',
            },
          });
        }
        if (loyaltyUser?.id && selectedIncentive?.loyaltyEngineId) {
          createOrderlessTransaction();
        } else {
          setShowOfferRedemptionModal(true);
        }
        if (
          selectedIncentive &&
          (isLoyaltyOffer(selectedIncentive) || isLegacyOffer(selectedIncentive))
        ) {
          showOfferCooldownToast();
        }
      },
      showCancel: false,
    });
    const {
      SwitchCartMethodDialog,
      shouldShowSwitchToMobileDialog,
      shouldShowSwitchToStoreDialog,
      openSwitchCartMethod,
    } = useSwitchCartMethodDialog();

    // Handle add to cart flow
    const addIncentiveToCart = (args: AddToCartArgs) => {
      setSelectedIncentive(args.incentive);
      setSelectedArgs(args);
      if (isLoyaltyOffer(args.incentive)) {
        const canStackOfferToMobile = getCanStackOfferCheck((of: LoyaltyAppliedOffer) => of);
        const canStackOfferToInRestaurant = getCanStackOfferCheck(entry =>
          get(entry, 'details.offer')
        );

        const showReplaceModal =
          !canStackOfferToMobile(args.incentive, appliedOffers) ||
          !canStackOfferToInRestaurant(args.incentive, inRestaurantRedemptionCart);

        if (showReplaceModal) {
          return openReplaceOfferDialog({ args });
        }
      }

      redeemIncentive(args);
    };

    const redeemIncentive = (args: AddToCartArgs | undefined) => {
      if (args) {
        if (args.method === RedemptionMethod.MOBILE) {
          return handleMobileRedemption(args);
        }
        tryInRestaurantRedemption(args);
      }
    };

    const tryInRestaurantRedemption = (args: AddToCartArgs) => {
      if (shouldShowSwitchToStoreDialog) {
        openSwitchCartMethod({
          onConfirm: () => handleInRestaurantRedemption(args),
        });
      } else {
        handleInRestaurantRedemption(args);
      }
    };

    const handleMobileRedemption = (args: AddToCartArgs) => {
      if (shouldShowSwitchToMobileDialog) {
        openSwitchCartMethod({ onConfirm: () => mobileRedemption(args) });
      } else {
        if (!enableMultipleRewards) {
          const appliedRewards = cartEntries?.filter(
            (item: any) => appliedLoyaltyRewards?.[item.cartId]
          )?.length;
          if (appliedRewards > 0 && isReward(args.incentive)) {
            setOpenReplaceRewardDialog(true);
            return;
          }
        }
        mobileRedemption(args);
      }
    };

    const handleInRestaurantRedemption = (args: AddToCartArgs) => {
      const isInStoreLoyaltyRedemptionEnabled =
        inRestaurantRedemptionEnabled && inRestaurantLoyaltyEnabledAtRestaurant;
      const shouldRedeemInRestaurant =
        isInStoreLoyaltyRedemptionEnabled && (isReward(args.incentive) || enableOfferRedemption);

      if (shouldRedeemInRestaurant) {
        inRestaurantRedemption(args);
      } else {
        openReadyToRedeemDialog();
      }
    };

    const mobileRedemption = useCallback(
      ({ incentive }: AddToCartArgs) => {
        if (isLoyaltyOffer(incentive)) {
          dispatch(actions.loyalty.setSelectedOffer(incentive));

          if (isDiscountLoyaltyOffer(incentive)) {
            if (!incentive.isStackable) {
              appliedOffers.forEach(offer => {
                // removes offer from cart if the applied offer is not stackable or a swap offer
                if (!offer.isStackable && !isAppliedOfferSwap(offer)) {
                  removeFromCart({ cartId: offer.cartId });
                }
              });
            }

            // Discount offers should not show menu item details
            dispatch(
              actions.loyalty.applyOffer({
                id: incentive.loyaltyEngineId,
                type: OfferType.GLOBAL,
                isStackable: incentive.isStackable,
                isSurprise: isSurpriseOffer(incentive),
                cmsId: incentive._id,
                cartId: 'discount-offer',
              })
            );
            toast.show({
              text: formatMessage({ id: 'offerAddedToCart' }),
              variant: 'positive',
            });
            navigate(routes.menu, { popCurrent: true });
            return;
          }
        }

        const engineReward = engineRewardsMap[incentive?.loyaltyEngineId || ''];
        const menuItemDescriptor = getMenuItemDescriptorForIncentive(
          incentive,
          engineReward?.rewardBenefitId
        );
        const incentiveMenuPath = makeUrlForIncentive(incentive, menuItemDescriptor);
        if (!menuItemDescriptor || !incentiveMenuPath) {
          logger.warn(
            `Attempting to make a url for reward that is missing data: reward ${incentive._id}`
          );
          const incentiveData = parseIncentiveData(incentive);
          setIncentiveName(incentiveData.name);
          openItemUnavailableModal();
          return;
        }

        // Save incentive data before redirecting to StoreLocator
        if (noStoreSelected && !enableStaticMenu) {
          const searchIndex = incentiveMenuPath.indexOf('?');
          const search = searchIndex !== -1 ? incentiveMenuPath.slice(searchIndex) : '';
          setSelectedStaticMenuItem({
            itemId: `${menuItemDescriptor._id}-${menuItemDescriptor._type}`,
            itemName: incentiveName || '',
            search,
          });
        }

        const navigationState = { isFromSurprise: pathname === routes.cart };
        linkTo(appendObjectToQueryString(incentiveMenuPath, navigationState), {
          popCurrent: true,
        });

        if (isLoyaltyOffer(incentive) || isLegacyOffer(incentive)) {
          showOfferCooldownToast();
        }
      },
      [
        appliedOffers,
        dispatch,
        enableStaticMenu,
        engineRewardsMap,
        formatMessage,
        incentiveName,
        linkTo,
        navigate,
        noStoreSelected,
        openItemUnavailableModal,
        pathname,
        removeFromCart,
        setSelectedStaticMenuItem,
        showOfferCooldownToast,
        toast,
      ]
    );

    const createInRestaurantCartEntry = useCallback(
      (incentive: AddToCartArgs['incentive']) => {
        let inRestaurantCartEntry: ICreateCartEntryParams | undefined;
        if (!isReward(incentive)) {
          inRestaurantCartEntry = { offer: incentive };
        } else {
          const engineReward = engineRewardsMap[incentive?.loyaltyEngineId || ''];
          if (engineReward) {
            inRestaurantCartEntry = {
              reward: incentive,
              engineReward,
            };
          }
        }
        return inRestaurantCartEntry;
      },
      [engineRewardsMap]
    );

    const inRestaurantRedemption = ({ incentive }: AddToCartArgs) => {
      if (
        !enableLoyaltyAlohaIntegration &&
        !enableMultipleRewards &&
        inRestaurantRedemptionCart.length > 0 &&
        isReward(incentive)
      ) {
        setOpenReplaceRewardDialog(true);
        return;
      }
      const shouldSelectStore = shouldForceRestaurantSelection && noStoreSelected;
      if (shouldSelectStore) {
        setStoreLocatorCallbackUrl(pathname);
        navigate(routes.storeLocator);
        return;
      }

      const inRestaurantCartEntry = createInRestaurantCartEntry(incentive);
      if (inRestaurantCartEntry) {
        if (!canStackReward(inRestaurantRedemptionCart)) {
          return setOpenReplaceDialog(true);
        }
        addInRestaurantRedemptionEntry(inRestaurantCartEntry);
        navigate(routes.redeem, { popCurrent: true });
        if (isLoyaltyOffer(incentive) || isLegacyOffer(incentive)) {
          showOfferCooldownToast();
        }
      }
    };

    const onConfirmReplaceReward = useCallback(() => {
      if (selectedArgs?.method === RedemptionMethod.MOBILE) {
        // get the cartId of the applied reward to replace it with the new selected one
        const cartIdToRemove = cartEntries?.filter(
          (item: any) => appliedLoyaltyRewards?.[item.cartId]
        )[0]?.cartId;
        if (!cartIdToRemove) {
          return;
        }
        removeFromCart({
          cartId: cartIdToRemove,
        });
        mobileRedemption(selectedArgs);
        setOpenReplaceRewardDialog(false);
        return;
      }
      if (selectedIncentive) {
        const inRestaurantCartEntry = createInRestaurantCartEntry(selectedIncentive);
        if (inRestaurantCartEntry) {
          clearInRestaurantRedemptionAllRewards();
          addInRestaurantRedemptionEntry(inRestaurantCartEntry);
          setOpenReplaceDialog(false);
          navigate(routes.redeem, { replace: true, popCurrent: true });
        }
      }
    }, [
      selectedArgs,
      selectedIncentive,
      cartEntries,
      removeFromCart,
      mobileRedemption,
      appliedLoyaltyRewards,
      createInRestaurantCartEntry,
      clearInRestaurantRedemptionAllRewards,
      addInRestaurantRedemptionEntry,
      navigate,
    ]);

    const onDismissRestaurantRedemptionModal = () => {
      dispatch(actions.loyalty.setShouldRefetchOffers(true));
      setShowOfferRedemptionModal(false);
      navigate(routes.loyaltyOfferList, { popCurrent: true, replace: true });
    };

    const onDismissReplaceReward = () => {
      setOpenReplaceRewardDialog(false);
      navigate(routes.rewards, { popCurrent: true });
    };

    return (
      <>
        <ReplaceRewardModal
          isOpen={openReplaceRewardDialog}
          onDismiss={onDismissReplaceReward}
          onConfirm={onConfirmReplaceReward}
        />
        <ReplaceOfferDialog
          heading={formatMessage({ id: 'oneOfferPerOrder' })}
          body={formatMessage({ id: 'replaceOffer' })}
          buttonLabel={formatMessage({ id: 'replace' })}
          showCloseButton
        />
        <SwitchCartMethodDialog />
        <ItemUnavailableModal rewardName={incentiveName} />
        <DialogActionSheet
          isOpen={openReplaceDialog}
          onConfirm={onConfirmReplaceReward}
          confirmButtonLabel={formatMessage({ id: 'replaceItem' })}
          onDismiss={() => setOpenReplaceDialog(false)}
          title={formatMessage({ id: 'replaceReward' })}
          body={formatMessage({ id: 'replaceRewardText' })}
        />
        {selectedIncentive && isLoyaltyOffer(selectedIncentive) && (
          <>
            <InvalidIncentiveModal heading={formatMessage({ id: 'uhoh' })} showCloseButton />
            <ReadyToRedeemDialog
              testID="ready-to-redeem-dialog"
              heading={formatMessage({ id: 'couponWillExpire' })}
              body={formatMessage({ id: 'confirmDialogBody' })}
              buttonLabel={formatMessage({ id: 'redeem' })}
              showCloseButton
            />
            {showOfferRedemptionModal && (
              <RestaurantRedemptionModal
                selectedOffer={selectedIncentive}
                redemptionStartTime={Date.now()}
                onDismiss={onDismissRestaurantRedemptionModal}
              />
            )}
          </>
        )}
        <Component {...props} addIncentiveToCart={addIncentiveToCart} />
      </>
    );
  };
};

export default withAddIncentiveToCart;
