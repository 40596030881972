import React from 'react';

import { Box } from '@rbilabs/universal-components';

import { useIsMobileBp } from 'hooks/breakpoints';
import { useAreLoyaltyOffersInCooldown } from 'hooks/use-are-loyalty-offers-in-cooldown';
import { LoyaltyOffersCooldownDrawer } from 'pages/loyalty/loyalty-offers/loyalty-offers-cooldown-drawer';
import { Route, Routes } from 'state/location';
import { WEB_APP_DESKTOP_LG_MAX_WIDTH, WEB_APP_MAX_WIDTH } from 'utils/constants';

import { BrowsingPanel } from './browsing-panel';
import { DetailsPanel } from './details-panel';
import { DetailsPanelError } from './details-panel-error';
import { MobileBackButton } from './mobile-back-button';
import { useOffersUIContext } from './offers-ui-context';

export const offerIdSlug = ':offerId';

const BrowsingPanelContainer = Box.withConfig({
  flexGrow: 1,
  flexShrink: 1,
  flexBasis: 'auto',
  backgroundColor: Styles.color.background,
  width: {
    base: 'full',
    md: WEB_APP_MAX_WIDTH,
    lg: WEB_APP_DESKTOP_LG_MAX_WIDTH,
  },
});

const DetailsPanelContainer = Box.withConfig({
  flexGrow: {
    base: 1,
    md: 2,
  },
  flexShrink: {
    base: 1,
  },
  flexBasis: {
    base: 'auto',
  },
});

export const BrowsingRoute = () => {
  return (
    <BrowsingPanelContainer>
      <BrowsingPanel />
    </BrowsingPanelContainer>
  );
};

export const DetailsRoute = () => {
  const isMobile = useIsMobileBp();
  const {
    previewOfferId,
    previewOffer,
    showEmptyState,
    showMissingOffer,
    featureOffers,
  } = useOffersUIContext();
  const loyaltyOffersInCooldown = useAreLoyaltyOffersInCooldown();
  const { offersDetailsPanel = null, defaultBackgroundImage = null } = featureOffers || {};
  if (showEmptyState) {
    return (
      <DetailsPanelError
        backgroundImage={defaultBackgroundImage}
        image={offersDetailsPanel?.emptyStateImage ?? null}
        title={offersDetailsPanel?.emptyStateTitle ?? null}
        body={offersDetailsPanel?.emptyStateBody ?? null}
        action={offersDetailsPanel?.emptyStateAction ?? null}
        showAction
        titleTestId="details-panel-empty-state-title"
        bodyTestId="details-panel-empty-state-body"
        actionButtonTestId="details-panel-empty-state-action-button"
      />
    );
  }

  if (showMissingOffer) {
    return (
      <DetailsPanelError
        backgroundImage={defaultBackgroundImage}
        image={offersDetailsPanel?.missingOfferImage ?? null}
        title={offersDetailsPanel?.missingOfferTitle ?? null}
        body={offersDetailsPanel?.missingOfferBody ?? null}
        action={offersDetailsPanel?.missingOfferAction ?? null}
        showAction={isMobile}
        titleTestId="details-panel-missing-offer-title"
        bodyTestId="details-panel-missing-offer-body"
        actionButtonTestId="details-panel-missing-offer-action-button"
      />
    );
  }

  return (
    <DetailsPanelContainer>
      {loyaltyOffersInCooldown && <LoyaltyOffersCooldownDrawer />}
      {previewOffer && (
        <DetailsPanel
          key={previewOfferId ?? ''}
          offer={previewOffer}
          // props for DetailsPanelError
          backgroundImage={defaultBackgroundImage}
          image={offersDetailsPanel?.missingOfferImage ?? null}
          title={offersDetailsPanel?.missingOfferTitle ?? null}
          body={offersDetailsPanel?.missingOfferBody ?? null}
          action={offersDetailsPanel?.missingOfferAction ?? null}
          showAction={isMobile}
          offersInCooldown={loyaltyOffersInCooldown}
        />
      )}
    </DetailsPanelContainer>
  );
};

export const OffersRouter: React.FC<React.PropsWithChildren<{ showOfferDetails?: boolean }>> = ({
  showOfferDetails,
}) => {
  const { showMobileBackButton, offersUrl } = useOffersUIContext();

  return (
    <>
      {showMobileBackButton && <MobileBackButton to={offersUrl} />}
      <Routes>
        {showOfferDetails ? null : <Route path={''} element={<BrowsingRoute />} />}
        <Route path={'/*'} element={<DetailsRoute />} />
      </Routes>
    </>
  );
};
