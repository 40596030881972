import React from 'react';

import { HStack, Text as UCLText } from '@rbilabs/universal-components';

import Picture from 'components/picture';
import { IImageFragment } from 'generated/sanity-graphql';

import theme from './theme';

const Container = HStack.withConfig({
  alignItems: 'center',
  justifyContent: 'center',
  paddingTop: '3.5',
  paddingBottom: '3',
});

const Icon = Picture.withConfig({
  height: '7',
  width: '7',
  marginBottom: '1',
  marginRight: '2',
});

const Text = UCLText.withConfig({
  margin: 0,
  color: theme.textColor,
  fontSize: 'sm',
});

interface IEndOfOffersProps {
  image: IImageFragment | null;
  message: string;
}

export const EndOfOffers: React.FC<React.PropsWithChildren<IEndOfOffersProps>> = ({
  image,
  message,
}) => (
  <Container>
    {image && <Icon image={image} alt={message} objectFitContain />}
    <Text variant="copyOne">{message}</Text>
  </Container>
);
