import React, {
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

import { CognitoUserSession } from 'amazon-cognito-identity-js';
import { useIntl } from 'react-intl';

import { ICommunicationPreference, IFavoriteStore } from 'generated/rbi-graphql';
import { useNavigation } from 'hooks/navigation/use-navigation';
import useErrorModal from 'hooks/use-error-modal';
import { getCurrentSession } from 'remote/auth';
import { UpdateUserInfoOptions } from 'state/auth/types';
import { useLocale } from 'state/intl';
import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';
import { useNetworkContext } from 'state/network';
import { ISOsToRegions } from 'utils/form/constants';
import LocalStorage, { StorageKeys } from 'utils/local-storage';
import logger from 'utils/logger';
import { routes } from 'utils/routing';

import {
  ISignIn,
  ISignUpResult,
  useAccountAuthentication,
} from './hooks/use-account-authentication';
import { UserDetails, useCurrentUser } from './hooks/use-current-user';

export type UpdateUserInfo = {
  dob: string;
  name: string;
  phoneNumber: string;
  promotionalEmails: boolean;
  isoCountryCode: string;
  zipcode: string;
  defaultReloadAmt: number;
  defaultCheckoutPaymentMethodId?: string;
  defaultReloadPaymentMethodId?: string;
  autoReloadEnabled: boolean;
  autoReloadThreshold: number;
};

type SignupArgs = {
  email: string;
  name: string;
  phoneNumber: string;
  country: string;
  wantsPromotionalEmails: boolean;
};

export interface AuthInterface {
  // Computed values
  loading: boolean;
  originLocation: null | string;
  user: null | UserDetails;
  otpAuthError: null | string;
  // Functions
  getCurrentSession(): Promise<null | CognitoUserSession>;
  refreshCurrentUser(): Promise<void>;
  refreshCurrentUserWithNewSession(): Promise<void>;
  isAuthenticated: boolean;
  setOriginLocation(location: null | string): void;
  signIn(args: ISignIn): Promise<void>;
  signOut(): Promise<void>;
  signUp(
    args: SignupArgs,
    signInOverride?: (args: ISignIn) => Promise<void>
  ): Promise<ISignUpResult>;
  updateUserInfo(args: UpdateUserInfo, options?: UpdateUserInfoOptions): Promise<void>;
  updateUserCommPrefs(
    commPrefs: Array<ICommunicationPreference>,
    options?: {
      shouldThrowException?: boolean;
    }
  ): Promise<void>;
  updateUserFavStores(favStores: Array<IFavoriteStore>): Promise<void>;
  useUpdateMeMutationLoading: boolean;
  validateLogin(args: { jwt: string; username: string }): Promise<void>;
  validateLoginOtp(args: { otpCode: string }): Promise<void>;
  setOtpAuthError(error: null | string): void;
}

export const AuthContext = createContext<AuthInterface>(({} as any) as AuthInterface);
export const useAuthContext = () => useContext(AuthContext);

export const AuthProvider = ({ children }: { children: ReactNode }) => {
  const { hasNotAuthenticatedError, setHasNotAuthenticatedError } = useNetworkContext();
  const { navigate } = useNavigation();
  const signOutOnInvalidToken = useFlag(LaunchDarklyFlag.ENABLE_SIGNOUT_ON_INVALID_TOKEN);
  const { region } = useLocale();
  const { formatMessage } = useIntl();
  const enableAccountRegionVerification = useFlag(
    LaunchDarklyFlag.ENABLE_ACCOUNT_REGION_VERIFICATION
  );
  const [otpAuthError, setOtpAuthError] = useState(null);
  const onConfirmErrorDialog = useCallback(() => {
    navigate(routes.signUp, {
      state: { activeRouteIsSignIn: true },
      replace: true,
    });
  }, [navigate]);

  const [ErrorDialog, openErrorDialog] = useErrorModal({
    onConfirm: onConfirmErrorDialog,
    modalAppearanceEventMessage: 'Error: Auth Error',
  });

  const {
    refreshCurrentUser,
    refreshCurrentUserWithNewSession,
    setCurrentUser,
    updateUserCommPrefs,
    updateUserFavStores,
    updateUserInfo,
    user,
    userLoading,
    useUpdateMeMutationLoading,
    isAuthenticated,
  } = useCurrentUser({
    openErrorDialog,
  });
  const {
    authLoading,
    originLocation,
    setOriginLoc,
    signIn,
    signOut,
    signUp,
    validateLogin,
    validateLoginOtp,
  } = useAccountAuthentication({
    refreshCurrentUser,
    openErrorDialog,
    setCurrentUser,
  });

  const loading = authLoading || userLoading;

  // use cognito to determind if user is authed or not. Prevent showing un-auth view to auth user.
  const authContext = useMemo<AuthInterface>(
    () => ({
      // Computed values
      loading,
      useUpdateMeMutationLoading,
      originLocation,
      otpAuthError,
      // @ts-expect-error(2322) user
      user,
      // Functions
      getCurrentSession,
      refreshCurrentUser,
      refreshCurrentUserWithNewSession,
      isAuthenticated,
      signIn,
      signOut,
      signUp,
      updateUserInfo,
      updateUserCommPrefs,
      updateUserFavStores,
      validateLogin,
      validateLoginOtp,
      setOriginLocation: setOriginLoc as (args: null | string) => void,
      setOtpAuthError: setOtpAuthError as (args: null | string) => void,
    }),
    [
      loading,
      useUpdateMeMutationLoading,
      originLocation,
      otpAuthError,
      user,
      refreshCurrentUser,
      refreshCurrentUserWithNewSession,
      isAuthenticated,
      signIn,
      signOut,
      signUp,
      updateUserInfo,
      updateUserCommPrefs,
      updateUserFavStores,
      validateLogin,
      validateLoginOtp,
      setOriginLoc,
    ]
  );

  useEffect(() => {
    // save redirect url so user can get redirected correctly after login
    LocalStorage.setItem(StorageKeys.AUTH_REDIRECT, { callbackUrl: originLocation });
  }, [originLocation]);

  useEffect(() => {
    if (hasNotAuthenticatedError) {
      if (signOutOnInvalidToken) {
        signOut();
        logger.warn({
          message: 'User was signed out cause token was invalid',
        });
      }

      setHasNotAuthenticatedError(false);
    }
  }, [hasNotAuthenticatedError, setHasNotAuthenticatedError, signOut, signOutOnInvalidToken]);

  useEffect(() => {
    if (enableAccountRegionVerification && user?.details?.isoCountryCode) {
      const userRegion = ISOsToRegions[user.details.isoCountryCode];

      // Make sure we have values for the current region and user region
      if (region && userRegion && region !== userRegion) {
        // Force sign out
        signOut();

        // Show detailed error message
        openErrorDialog({
          title: formatMessage({ id: 'authRegionErrorTitle' }),
          message: formatMessage({ id: 'authRegionErrorDetails' }),
        });
      }
    }
  }, [enableAccountRegionVerification, formatMessage, openErrorDialog, region, signOut, user]);

  return (
    <AuthContext.Provider value={authContext}>
      {children}
      <ErrorDialog />
    </AuthContext.Provider>
  );
};

export default AuthContext.Consumer;
