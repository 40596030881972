// Burger King CSS

import { LayoutWidth, NAV_HEIGHT, ZIndex } from './enums';
// Burger King 2020 Legacy Visual Identity

export default {
  // Extra CSS Properties
  borderRadius: '10px',
  boxShadow: '0px 0px 4px 0px rgba(73, 73, 73, 0.2)',
  shadowColor: 'rgba(73, 73, 73, 0.2)',
  shadowOffsetX: 0,
  shadowOffsetY: 0,
  shadowRadius: 4,

  // Color Styles
  color: {
    accent: '#af0c2a',
    alert: {
      default: {
        background: '#502314',
        text: '#ffffff',
      },
      info: {
        background: '#FF8732',
        text: '#ffffff',
      },
      success: {
        background: '#1B8737',
        text: '#ffffff',
      },
      warning: {
        background: '#D62300',
        text: '#ffffff',
      },
      error: {
        background: '#D62300',
        text: '#ffffff',
      },
    },
    codeBlockBackground: '#F5EBDC',
    contrastBackground: '#502314',
    error: '#D62300',
    errorHover: '',
    pricing: '#502314',
    validated: '#1b8737',
    validatedHover: '',
    primary: '#D62300',
    primaryHover: '#a71b00',
    secondary: '#D62300',
    secondaryHover: '#a71b00',
    teal: '',
    tertiary: '#502314',
    background: '#F5EBDC',
    loyaltyHighlight: '#FFAA00',
    navLinkColor: {
      active: '#D62300',
      hover: '#a71b00',
    },
    mobileNavButtonColor: '#502314',
    black: '#502314',
    icon: '#D62300',
    grey: {
      one: '#2b0200',
      two: '#9f816f',
      three: '#693f34',
      four: '#bba391',
      five: '#d7c7b5',
      six: '#845f50',
      seven: '#340000',
      eight: '#4e201d',
      nine: '',
      ten: '',
    },
    headerContrast: '#D62300',
    highlight: '',
    white: '#F5EBDC',
    cardBackground: '#fff',
    disabledBorder: '#d4c7b7',
    disabledBackground: '',
    disabledColor: '#d4c7b7',
    success: '',
    red: '#d62300',
    orange: '#fe8731',
  },

  // Font Styles
  fontFamily: {
    base: 'heading',
    body: 'body',
    alternative: 'alternative',
    brand: 'heading',
  },

  // Font Weight Styles
  fontWeight: {
    black: 700,
    heavy: 700,
    normal: 400,
    light: 400,
  },

  // Font Transforms Styles
  textTransform: {
    headlines: 'none',
    body: 'none',
  },

  // Z Index Styles
  zIndex: {
    max: ZIndex.MAX,
    overlay: ZIndex.OVERLAY,
    top: ZIndex.TOP,
    normal: ZIndex.NORMAL,
    below: ZIndex.BELOW,
  },

  // Layout variables
  layout: {
    sectionMaxWidth: `${LayoutWidth.REGULAR}px`,
    smallerSectionMaxWidth: `${LayoutWidth.SMALL}px`,
    checkoutHeaderHeight: '57px',
    navHeight: {
      mobile: `${NAV_HEIGHT.MOBILE}px`,
      desktop: '78px',
    },
  },
};
