import * as React from 'react';

import { Measures, PerformanceMarks, logMeasurement, setMark } from 'utils/timing';

import { HomePageView } from './home-page.view';
import { useFeatureHomePage } from './use-feature-home-page';

const HomePage = () => {
  const { featureHomePageLoading, featureHomePage } = useFeatureHomePage();

  React.useEffect(() => {
    if (!featureHomePageLoading) {
      setMark(PerformanceMarks.MAIN_MENU_LOADING_FINISHED);
      logMeasurement(
        Measures.MENU_LOADING,
        PerformanceMarks.APP_RN_TSX_START,
        PerformanceMarks.MAIN_MENU_LOADING_FINISHED
      );
    }
  }, [featureHomePageLoading]);

  return <HomePageView isLoading={featureHomePageLoading} featureHomePage={featureHomePage} />;
};

export default HomePage;
