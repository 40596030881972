import { HStack, Header, VStack } from '@rbilabs/universal-components';

import Picture from 'components/picture';

export const ListContainer = VStack.withConfig(props => ({
  space: '$4',
  ...props,
}));

export const StyledTileContainer = HStack.withConfig({
  backgroundColor: 'token.background-pattern',
  alignItems: 'center',
  minHeight: '92px',
  padding: '$4',
  borderRadius: 8,
});

export const TileImage = Picture.withConfig({
  maxWidth: '60px',
  maxHeight: '60px',
});

export const TileTitle = Header.withConfig({
  variant: 'headerThree',
  marginBottom: '$1',
});
