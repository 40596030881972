import React, { useCallback } from 'react';

import { useFocusEffect } from '@react-navigation/native';
import { useIntl } from 'react-intl';

import { useNavigation } from 'hooks/navigation/use-navigation';
import { useReactNavigationFlag } from 'hooks/use-react-navigation-flag';
import { navigationRef } from 'navigation/navigation-container/navigation-container-ref';
import { ONLY_SHOW_REDEEM_REWARD_IN_RESTAURANT_CTA } from 'pages/loyalty/loyalty-incentives-components/incentive-details/constants';
import { LoyaltyRewards } from 'pages/loyalty/loyalty-rewards';
import { useFlag } from 'state/launchdarkly';
import { LaunchDarklyFlag } from 'utils/launchdarkly';
import { routes } from 'utils/routing';

const LoyaltyRewardsListWidget = () => {
  const { formatMessage } = useIntl();

  const { setParams } = useNavigation();
  const enableReactNativeNavigation = useReactNavigationFlag();
  const enableRewardPageStandAlone = useFlag(LaunchDarklyFlag.ENABLE_REWARD_PAGE_STAND_ALONE);

  useFocusEffect(
    useCallback(() => {
      return () => {
        if (!enableReactNativeNavigation) {
          return;
        }
        // onBlur
        const currentRoute = navigationRef.getCurrentRoute();
        const isALoyaltyRewardRoute = currentRoute?.name.startsWith(routes.rewards);
        if (!isALoyaltyRewardRoute) {
          setParams({ [ONLY_SHOW_REDEEM_REWARD_IN_RESTAURANT_CTA]: 'false' });
        }
      };
    }, [enableReactNativeNavigation, setParams])
  );

  return (
    <LoyaltyRewards
      isWidget={!enableRewardPageStandAlone}
      title={formatMessage({ id: 'redeemRewards' })}
    />
  );
};

export default LoyaltyRewardsListWidget;
