import { IPaymentErrors, IPaymentState } from 'utils/payment';

// For reference: https://docs.adyen.com/checkout/components-web/localization-components
// Using the language value to determine the proper Adyen locale value
export enum AdyenLocales {
  fr = 'fr-FR',
  en = 'en-US',
  de = 'de-DE',
  it = 'it-IT',
}

// NOTE: if we add more we need to expand the mapping to our FE CardTypes
// - adyenPaymentMethodCardTypes
export enum AdyenAcceptedCards {
  MASTERCARD = 'mc',
  VISA = 'visa',
  AMEX = 'amex',
  DISCOVER = 'discover',
  JCB = 'jcb',
  DINERS_CLUB = 'diners',
}

export type AdyenCardType = 'mc' | 'visa' | 'amex' | 'discover' | 'jcb' | 'diners';

enum AdyenCCFieldNames {
  ENCRYPTED_CARD_NUMBER = 'encryptedCardNumber',
  ENCRYPTED_EXPIRY_DATE = 'encryptedExpiryDate',
  ENCRYPTED_SECURITY_CODE = 'encryptedSecurityCode',
}

export const AdyenCCFieldFormNames = {
  [AdyenCCFieldNames.ENCRYPTED_CARD_NUMBER]: 'cardNumber',
  [AdyenCCFieldNames.ENCRYPTED_EXPIRY_DATE]: 'expiry',
  [AdyenCCFieldNames.ENCRYPTED_SECURITY_CODE]: 'cvv',
};

export interface IAdyenCustomCardInputs {
  onAdyenChange?: (state: IAdyenState) => void;
  onChange?: (field: string, value: string) => void;
  onError?: (errors: IAdyenErrors) => void;
  onAdyenInputsReady?: () => void;
  paymentValues?: IPaymentState;
  errors?: IPaymentErrors;
}

export interface IAdyenState {
  data: IAdyenStateData;
  isValid: boolean;
}

interface IAdyenStateData {
  paymentMethod: IAdyenPaymentMethodData;
  riskData?: IAdyenRiskData;
}

export interface IAdyenPaymentMethodData {
  encryptedCardNumber: string;
  encryptedExpiryMonth: string;
  encryptedExpiryYear: string;
  encryptedSecurityCode: string;
  holderName?: string;
  type?: string;
}

interface IAdyenRiskData {
  clientData?: string;
}

export interface IAdyenErrors {
  error?: string;
  errorI18n: string;
  errorText: string;
  fieldType: string;
  type?: string;
}
