import React, { memo, useEffect, useRef, useState } from 'react';

import { View, ViewProps } from '@rbilabs/universal-components';
import { isEqual, round } from 'lodash-es';
import { MeasureOnSuccessCallback } from 'react-native';

import { useOnboardingContext } from './onboarding-context';
import { IMeasures, IOnboardingStep } from './types';

const OnboardingStep: React.FC<React.PropsWithChildren<IOnboardingStep>> = props => {
  const { children, targetId, stepIndex, tooltipOffset, ...styles } = props;
  const {
    stepsData,
    setDoneMeasures,
    containerMetrics,
    componentsList,
    addComponentsToList,
    hasDoneOnboarding,
    updateComponentDetails,
  } = useOnboardingContext();

  const [componentMeasures, setComponentMeasures] = useState<IMeasures>();

  const componentRef = useRef<{
    measure: (cb: MeasureOnSuccessCallback) => void;
    measures: IMeasures;
    styles: ViewProps;
  }>();

  const getComponentMeasures = () => {
    if (componentRef.current?.measure) {
      componentRef.current?.measure((x, y, width, height, pageX, pageY) => {
        const measures = {
          x: round(x),
          y: round(y),
          width: round(width),
          height: round(height),
          pageX: round(pageX),
          pageY: round(pageY),
        };
        if (!isEqual(measures, componentMeasures)) {
          setDoneMeasures(false);
          setComponentMeasures(measures);
        }
      });
    }
  };

  // Add component initial metrics to context list
  useEffect(() => {
    if (componentMeasures && !(stepIndex in componentsList)) {
      const componentDetails = {
        [stepIndex]: {
          component: children,
          measures: componentMeasures,
          tooltipOffset,
          styles,
        },
      };
      addComponentsToList(componentDetails);
    }
  }, [
    children,
    componentMeasures,
    tooltipOffset,
    addComponentsToList,
    stepIndex,
    styles,
    componentsList,
  ]);

  // Re-calculate component measures if container metrics change
  useEffect(() => {
    if (
      componentMeasures &&
      componentsList[stepIndex] &&
      componentsList[stepIndex].measures !== componentMeasures
    ) {
      updateComponentDetails(stepIndex, {
        component: children,
        measures: componentMeasures,
        tooltipOffset,
        styles,
      });
    }
  }, [
    children,
    componentMeasures,
    tooltipOffset,
    stepIndex,
    styles,
    componentsList,
    updateComponentDetails,
  ]);

  if (!stepsData || Object.keys(stepsData).length === 0) {
    return <>{children}</>;
  }

  const doneOnboarding = hasDoneOnboarding[stepsData.id] ?? false;
  const targetIds = stepsData.steps.map(step => step.target_id);

  if (doneOnboarding || !containerMetrics || !targetIds.includes(targetId)) {
    return <>{children}</>;
  }

  return (
    <View ref={componentRef} onLayout={getComponentMeasures} flex="1">
      {children}
    </View>
  );
};

export default memo(OnboardingStep);
