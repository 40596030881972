import { Box } from '@rbilabs/universal-components';

import LoyaltyRewardsLogo from 'pages/loyalty/loyalty-rewards-logo';

export const LoyaltyLogoContainer = Box.withConfig({
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: Styles.color.background,
  marginTop: '$16',
  marginBottom: '$12',
});

export const StyledLogo = LoyaltyRewardsLogo.withConfig({
  width: '4/5',
});
