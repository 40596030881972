import { AllowedEvent } from '@rbilabs/mparticle-client';
import { IntlFormatters } from 'react-intl';

import { CustomEventNames, EventTypes } from 'state/crm-events/constants';

import { PromoCodeError, PromoCodeErrorReason } from './types';

export const createRbiApplyPromoCodeEvent = (
  promoCode: string,
  response: 'Successful' | 'Failed',
  failureReason?: PromoCodeErrorReason
): AllowedEvent => ({
  name: CustomEventNames.ACTION_SHEET_SUBMISSION,
  type: EventTypes.Other,
  attributes: {
    actionSheetHeader: 'Apply Promo Code',
    response,
    failureReason,
    submittedData: promoCode,
  },
});

export const buildErrorMessageFromPromoCodeError = (
  promoCodeError: PromoCodeError,
  formatMessage: IntlFormatters['formatMessage']
) => {
  switch (promoCodeError.message) {
    case PromoCodeErrorReason.expiredCode:
      return formatMessage({ id: 'promoCodeOfferExpiredCode' });
    case PromoCodeErrorReason.redeemedCode:
      return formatMessage({ id: 'promoCodeOfferRedeemedCode' });
    default:
      return formatMessage({ id: 'promoCodeOfferInvalidCode' });
  }
};
