import Picture from 'components/picture';

import {
  EmptyStateContainer as DefaultEmptyStateContainer,
  EmptyStateTitle as DefaultEmptyStateTitle,
} from './styled.base';

export * from './styled.base';

export const EmptyStateImage = Picture.withConfig({
  height: '167px',
  width: '200px',
});

export const EmptyStateTitle = DefaultEmptyStateTitle.withConfig({
  fontSize: 'xl',
});

export const EmptyStateContainer = DefaultEmptyStateContainer.withConfig({
  maxWidth: '290px',
});
