import React, { useCallback } from 'react';

import { Text } from '@rbilabs/universal-components';

import BonusPoints from 'components/bonus-points/bonus-points';
import { Calories } from 'components/price-and-calories';
import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';
import { useLoyaltyUserPromotions } from 'state/loyalty/hooks/use-loyalty-promotions';
import { EventName, emitEvent } from 'utils/event-hub';
import { routes } from 'utils/routing';

import {
  ItemPrice,
  PriceAndCaloriesListWrapper,
  Tile,
  TileDescription,
  TileHeader,
  TileHeaderWrapper,
  TileImage,
  TileImageWrapper,
} from './styled';
import { IMenuTileProps } from './types';

const StyledListItemCalories = Text.withConfig({
  marginBottom: 0,
  marginTop: 0,
  color: Styles.color.black,
  numberOfLines: 1,
});

const StyledItemPrice = ItemPrice.withConfig({
  color: Styles.color.black,
  numberOfLines: 1,
});

const SeparatorStyle = Text.withConfig({
  color: Styles.color.black,
  marginY: 0,
  marginX: 1,
  numberOfLines: 1,
});

const StyledPriceAndCaloriesListWrapper = PriceAndCaloriesListWrapper.withConfig<{
  $transparentBg?: boolean;
}>(p => ({
  marginTop: 1,
  textAlign: p.$transparentBg ? 'center' : 'left',
  justifyContent: p.$transparentBg ? 'center' : 'flex-start',
}));

const MenuTile = React.memo<IMenuTileProps>(
  ({
    showStaticMenu,
    pathname,
    image,
    imageDescription,
    name,
    type,
    price,
    calories,
    id,
    promotion,
  }) => {
    const enablePriceAndCaloriesInMenu = useFlag(
      LaunchDarklyFlag.ENABLE_PRICE_AND_CALORIES_IN_MENU
    );
    const enablePlkTransparentBackgroundMenuTile = useFlag(
      LaunchDarklyFlag.ENABLE_PLK_TRANSPARENT_BACKGROUND_MENU_TILE
    );
    const { availablePromotionsMap } = useLoyaltyUserPromotions();
    const shouldShowBonusPointsBadge =
      !!promotion?.loyaltyEngineId && !!availablePromotionsMap[promotion.loyaltyEngineId];

    const shouldRenderPriceAndCalories =
      enablePriceAndCaloriesInMenu &&
      type !== 'section' &&
      ((!showStaticMenu && price) || calories);

    const handleOnImageLoaded = useCallback(() => {
      emitEvent(EventName.MENU_TILE_IMAGE_LOADED, {
        sanityId: id,
        isOnMainMenu: pathname === routes.menu,
        attributes: [],
      });
    }, [id, pathname]);

    return (
      <Tile $transparentBg={enablePlkTransparentBackgroundMenuTile}>
        <TileImageWrapper>
          <TileImage
            image={image}
            alt={imageDescription ?? 'menu item image'}
            onImageLoaded={handleOnImageLoaded}
          />
        </TileImageWrapper>
        <TileDescription>
          <TileHeaderWrapper>
            <TileHeader
              variant="headerThree"
              testID={`tile-header-${name?.toLowerCase().replace(/\s/g, '')}`}
            >
              {name}
            </TileHeader>
            {shouldShowBonusPointsBadge && (
              <BonusPoints bonusPoints={promotion?.bonusPoints} hidePoints />
            )}
          </TileHeaderWrapper>
          {shouldRenderPriceAndCalories && (
            <StyledPriceAndCaloriesListWrapper
              $transparentBg={enablePlkTransparentBackgroundMenuTile}
            >
              {!showStaticMenu && (
                <>
                  <StyledItemPrice>{price}</StyledItemPrice>
                  <SeparatorStyle>•</SeparatorStyle>
                </>
              )}
              <Calories>
                <StyledListItemCalories>{calories}</StyledListItemCalories>
              </Calories>
            </StyledPriceAndCaloriesListWrapper>
          )}
        </TileDescription>
      </Tile>
    );
  }
);

export default MenuTile;
