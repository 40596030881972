import { Box } from '@rbilabs/universal-components';

import Picture from 'components/picture';

export const DoubleActionBtnWrapper = Box.withConfig({
  alignContent: 'flex-end',
  marginTop: {
    base: '-8',
    md: '12',
  },
});

export const WhiteBGWrapper = Box.withConfig({
  background: Styles.color.white,
  padding: '1',
  width: {
    base: 'full',
    md: 'unset',
  },
});

export const Icon = Picture.withConfig({
  width: '6',
  height: '5',
  borderRadius: 'unset',
});

export const LeftDesktopBtnWrapper = Box.withConfig({
  marginLeft: '5',
});

export const BottomHeroButtonWrapper = Box.withConfig({
  marginTop: '8',
  marginBottom: '4',
  justifyContent: 'center',
});
