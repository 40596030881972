import * as React from 'react';

import { HStack, Text } from '@rbilabs/universal-components';
import isNumber from 'lodash/isNumber';
import { useIntl } from 'react-intl';

import { useQuestContext } from 'components/quests/loyalty-quest';
import { isIncentiveRedeemed, isQuestCompleted } from 'components/quests/loyalty-quest/utils';

import { QuestPointsPicture } from './quest-points-picture';

export const QuestPointsIncentive = ({ description }: { description: string }) => {
  const { quest } = useQuestContext();
  const { formatMessage } = useIntl();

  return (
    <HStack space="$4" justifyContent="space-between">
      {isNumber(quest.bonusPoints) && <QuestPointsPicture bonusPoints={quest.bonusPoints} />}
      <Text flexShrink={1} color="white">
        {isQuestCompleted(quest.status) && isIncentiveRedeemed(quest.lastCompletion)
          ? formatMessage(
              { id: 'bonusPointsIncentiveDescription' },
              {
                points: quest.bonusPoints,
              }
            )
          : description}
      </Text>
    </HStack>
  );
};
