import React, {
  Reducer,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useReducer,
} from 'react';

import { isEmpty, round } from 'lodash-es';
import { Dimensions } from 'react-native';

import { useNavigation } from 'hooks/navigation/use-navigation';
import useEffectOnUpdates from 'hooks/use-effect-on-updates';
import useEffectOnce from 'hooks/use-effect-once';
import LocalStorage, { StorageKeys } from 'utils/local-storage';
import noop from 'utils/noop';

import {
  ACTIONS,
  ActionType,
  IComponentDetails,
  IInitialState,
  ILaunchOnboardingProps,
  ILocalStorageOnboardingProps,
  IOnboardingContextProps,
  IOnboardingProviderProps,
} from './types';

const initialState = {
  stepsData: null,
  containerMetrics: null,
  componentsList: {},
  addComponentsToList: noop,
  updateComponentDetails: noop,
  clearComponentsList: noop,
  currentStep: 0,
  setCurrentStep: noop,
  isLoading: false,
  setIsLoading: noop,
  doneMeasures: false,
  setDoneMeasures: noop,
  hasDoneOnboarding: {},
  setHasDoneOnboarding: noop,
  scrollToValueY: null,
  displayOnboarding: false,
  setDisplayOnboarding: noop,
  linkRedirect: '',
  setRedirectLink: noop,
  launchOnboarding: noop,
  isOnboardingAvailable: false,
  setIsOnboardingAvailable: noop,
};

const OnboardingContext = createContext<IOnboardingContextProps>(initialState);

export const useOnboardingContext = () => useContext(OnboardingContext);

const onboardingReducer: Reducer<IInitialState, ActionType> = (
  state: IInitialState,
  action: ActionType
) => {
  switch (action.type) {
    case ACTIONS.GO_TO_NEXT_STEP:
      return { ...state, currentStep: state.currentStep + 1 };
    case ACTIONS.ADD_COMPONENT_DETAILS_TO_LIST:
      return {
        ...state,
        componentsList: { ...state.componentsList, ...action.componentDetails },
      };
    case ACTIONS.UPDATE_COMPONENT_DETAILS:
      return {
        ...state,
        componentsList: {
          ...state.componentsList,
          [action.key]: { ...action.componentDetails },
        },
      };
    case ACTIONS.CLEAR_COMPONENTS_FROM_LIST:
      return {
        ...state,
        componentsList: {},
      };
    case ACTIONS.SET_LOADING:
      return {
        ...state,
        isLoading: action.loading,
      };
    case ACTIONS.SET_DONE_MEASURES:
      return {
        ...state,
        doneMeasures: action.done,
      };
    case ACTIONS.SET_HAS_DONE_ONBOARDING: {
      const udpatedValues = {
        ...state.hasDoneOnboarding,
        ...action.values,
      };
      LocalStorage.setItem(StorageKeys.HAS_DONE_ONBOARDING, udpatedValues);
      return {
        ...state,
        hasDoneOnboarding: { ...state.hasDoneOnboarding, ...action.values },
      };
    }
    case ACTIONS.SET_SCROLL_Y_VALUE:
      return {
        ...state,
        scrollToValueY: action.scrollY,
      };
    case ACTIONS.SET_DISPLAY_ONBOARDING:
      return {
        ...state,
        displayOnboarding: action.shouldDisplay,
      };
    case ACTIONS.SET_REDIRECT_LINK:
      return {
        ...state,
        linkRedirect: action.link,
      };
    case ACTIONS.SET_IS_ONBOARDING_AVAILABLE:
      return {
        ...state,
        isOnboardingAvailable: action.isAvailable,
      };
    default: {
      throw new Error('Unrecognized state transition');
    }
  }
};

export const OnboardingProvider: React.FC<React.PropsWithChildren<IOnboardingProviderProps>> = ({
  children,
  stepsData,
  containerMetrics,
  scrollViewRef,
  launchOnLoad: launchOnboardingOnLoad,
}) => {
  const { height: windowHeight } = Dimensions.get('window');
  const [state, dispatch] = useReducer(onboardingReducer, initialState);
  const { linkTo } = useNavigation();

  const componentsList = useMemo(() => state.componentsList, [state.componentsList]);
  const currentStep = useMemo(() => state.currentStep, [state.currentStep]);
  const isLoading = useMemo(() => state.isLoading, [state.isLoading]);
  const doneMeasures = useMemo(() => state.doneMeasures, [state.doneMeasures]);
  const hasDoneOnboarding = useMemo(() => state.hasDoneOnboarding, [state.hasDoneOnboarding]);
  const scrollToValueY = useMemo(() => state.scrollToValueY, [state.scrollToValueY]);
  const displayOnboarding = useMemo(() => state.displayOnboarding, [state.displayOnboarding]);
  const linkRedirect = useMemo(() => state.linkRedirect, [state.linkRedirect]);
  const isOnboardingAvailable = useMemo(() => state.isOnboardingAvailable, [
    state.isOnboardingAvailable,
  ]);

  const setCurrentStep = () => dispatch({ type: ACTIONS.GO_TO_NEXT_STEP });

  const setDoneMeasures = useCallback(
    (done: boolean) => dispatch({ type: ACTIONS.SET_DONE_MEASURES, done }),
    []
  );

  const setIsLoading = useCallback(
    (loading: boolean) => dispatch({ type: ACTIONS.SET_LOADING, loading }),
    []
  );

  const addComponentsToList = useCallback(
    (componentDetails: { [key: string]: IComponentDetails }) =>
      dispatch({ type: ACTIONS.ADD_COMPONENT_DETAILS_TO_LIST, componentDetails }),
    []
  );

  const updateComponentDetails = useCallback(
    (key: string, componentDetails: IComponentDetails) =>
      dispatch({ type: ACTIONS.UPDATE_COMPONENT_DETAILS, key, componentDetails }),
    []
  );

  const clearComponentsList = () => dispatch({ type: ACTIONS.CLEAR_COMPONENTS_FROM_LIST });

  const setHasDoneOnboarding = useCallback(
    (values: ILocalStorageOnboardingProps) =>
      dispatch({ type: ACTIONS.SET_HAS_DONE_ONBOARDING, values }),
    []
  );

  const setScrollValueY = useCallback(
    (scrollY: number) => dispatch({ type: ACTIONS.SET_SCROLL_Y_VALUE, scrollY }),
    []
  );

  const setDisplayOnboarding = useCallback(
    (shouldDisplay: boolean) => dispatch({ type: ACTIONS.SET_DISPLAY_ONBOARDING, shouldDisplay }),
    []
  );

  const setRedirectLink = useCallback(
    (link: string) => dispatch({ type: ACTIONS.SET_REDIRECT_LINK, link }),
    []
  );

  const setIsOnboardingAvailable = useCallback(
    (isAvailable: boolean) => dispatch({ type: ACTIONS.SET_IS_ONBOARDING_AVAILABLE, isAvailable }),
    []
  );

  const launchOnboarding = useCallback(
    ({ redirectLink }: ILaunchOnboardingProps) => {
      if (redirectLink) {
        // optional redirect to execute after onboarding is done
        setRedirectLink(redirectLink);
      }

      if (!displayOnboarding && !!stepsData) {
        setDisplayOnboarding(true);
      }
    },
    [displayOnboarding, setDisplayOnboarding, setRedirectLink, stepsData]
  );

  useEffectOnUpdates(() => {
    if (stepsData.id in hasDoneOnboarding && hasDoneOnboarding[stepsData.id] && linkRedirect) {
      linkTo(linkRedirect);
    }
  }, [hasDoneOnboarding]);

  useEffect(() => {
    if (containerMetrics && Object.keys(componentsList).length === stepsData.steps.length) {
      setDoneMeasures(true);
    }
  }, [containerMetrics, setDoneMeasures, stepsData.steps.length, componentsList]);

  useEffectOnce(() => {
    setHasDoneOnboarding(LocalStorage.getItem(StorageKeys.HAS_DONE_ONBOARDING) ?? {});

    if (!isEmpty(stepsData)) {
      setIsOnboardingAvailable(true);
    }

    if (launchOnboardingOnLoad) {
      setDisplayOnboarding(true);
    }
  });

  // Scroll to position if component is out of view
  useEffect(() => {
    if (!containerMetrics || !componentsList[currentStep] || hasDoneOnboarding[stepsData.id]) {
      return;
    }

    const currentStepComponent = componentsList[currentStep];
    const { pageY, height } = currentStepComponent.measures;
    const shouldScrollToView = pageY + height > round(windowHeight);

    if (
      shouldScrollToView &&
      scrollViewRef?.current &&
      scrollViewRef.current.scrollTo &&
      containerMetrics?.pageY
    ) {
      const scrollToYValue = pageY + height - round(windowHeight) + containerMetrics.pageY;
      setScrollValueY(scrollToYValue);
      scrollViewRef.current.scrollTo({ y: scrollToYValue, animated: true });
    }
  }, [
    componentsList,
    containerMetrics,
    containerMetrics?.pageY,
    currentStep,
    hasDoneOnboarding,
    scrollViewRef,
    setScrollValueY,
    stepsData.id,
    windowHeight,
  ]);

  const value = useMemo(
    () => ({
      stepsData,
      isLoading,
      setIsLoading,
      doneMeasures,
      setDoneMeasures,
      containerMetrics,
      currentStep,
      setCurrentStep,
      componentsList,
      addComponentsToList,
      clearComponentsList,
      updateComponentDetails,
      hasDoneOnboarding,
      setHasDoneOnboarding,
      scrollViewRef,
      scrollToValueY,
      displayOnboarding,
      setDisplayOnboarding,
      setRedirectLink,
      linkRedirect,
      launchOnboarding,
      isOnboardingAvailable,
      setIsOnboardingAvailable,
    }),
    [
      stepsData,
      isLoading,
      setIsLoading,
      doneMeasures,
      setDoneMeasures,
      containerMetrics,
      currentStep,
      componentsList,
      addComponentsToList,
      updateComponentDetails,
      hasDoneOnboarding,
      setHasDoneOnboarding,
      scrollViewRef,
      scrollToValueY,
      displayOnboarding,
      setDisplayOnboarding,
      setRedirectLink,
      linkRedirect,
      launchOnboarding,
      isOnboardingAvailable,
      setIsOnboardingAvailable,
    ]
  );

  if (!children) {
    return null;
  }

  const pageComponents = children[0];
  const pageComponentsWithOnboarding = children;

  if (stepsData.id in hasDoneOnboarding && hasDoneOnboarding[stepsData.id]) {
    return <>{pageComponents}</>;
  }

  return (
    <OnboardingContext.Provider value={value}>
      {displayOnboarding ? pageComponentsWithOnboarding : pageComponents}
    </OnboardingContext.Provider>
  );
};
