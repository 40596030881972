import { Box, HStack, Text } from '@rbilabs/universal-components';

import { IOptionContainer } from './types';

export const OptionContainer = Box.withConfig<IOptionContainer>(p => ({
  _text: {
    color: p.disabled ? Styles.color.grey.three : Styles.color.black,
    fontSize: 'md',
    fontFamily: Styles.fontFamily.base,
    lineHeight: 'xl',
  },
}));

export const SelectionContainer = HStack.withConfig({
  alignItems: 'center',
  paddingX: '$2',
  paddingY: '$2',
  justifyContent: 'space-between',
  width: 'full',
  borderBottomWidth: '1px',
  borderColor: Styles.color.grey.four,
});

export const LineItemDescription = Box.withConfig({
  justifyContent: 'center',
  flex: 1,
  marginRight: '$2',
});

export const LineItemDescriptionText = Text.withConfig({
  fontSize: 'sm',
  variant: 'copyOne',
  lineHeight: 'sm',
});
