import { SupportedRegions } from '@rbi-ctg/frontend';
// TODO: RN - fix dependency cycle causing app crash
//      when changing import to state/intl/constants
import {
  PROD_DEFAULT_REGION as DEFAULT_REGION,
  PROD_SUPPORTED_REGIONS as SUPPORTED_REGIONS,
} from 'state/intl/constants/supported-regions';

export function findSupportedRegion(requestedRegion: string): SupportedRegions {
  return SUPPORTED_REGIONS[requestedRegion] || DEFAULT_REGION;
}
