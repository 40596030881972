import React from 'react';

import { useAuthContext } from 'state/auth';
import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';
import { useStoreContext } from 'state/store';

import { FORCE_MENU_FEATURE_UPSELL_STRATEGY } from './constants';
import { IUpsellStrategyProps } from './types';
import { Upsell } from './upsell';
import { useChefUpsell } from './use-chef-upsell';
import { useDeepflameUpsell } from './use-deepflame-upsell';
import { useMenuFeatureUpsell } from './use-menu-feature-upsell';
import { useUpsell } from './use-upsell';

const MenuFeatureUpsell: React.FC<IUpsellStrategyProps> = props => {
  const upsellData = useMenuFeatureUpsell();
  return <Upsell {...upsellData} {...props} />;
};

const RecommendationEngineUpsell: React.FC<IUpsellStrategyProps & { storeId: string }> = ({
  isInCart,
  storeId,
}) => {
  const isChefDeprecated = useFlag(LaunchDarklyFlag.DEPRECATE_CHEF);
  const upsellItemCount = useFlag(LaunchDarklyFlag.CHEF_CART_UPSELL_ITEM_COUNT) ?? 2;

  const upsellData = useUpsell({
    isInCart,
    storeId,
    useUpsellQuery: isChefDeprecated ? useDeepflameUpsell : useChefUpsell,
    limit: isChefDeprecated ? null : upsellItemCount,
  });
  return <Upsell {...upsellData} isInCart={isInCart} />;
};

export const UpsellStrategy: React.FC<IUpsellStrategyProps> = props => {
  const { isAuthenticated } = useAuthContext();

  const { store } = useStoreContext();
  const storeId = store.number ?? '';

  const enableSignupAfterCart = useFlag(LaunchDarklyFlag.ENABLE_MOVE_SIGNUP_AFTER_CART);
  const chefRecommendationEngine2 = useFlag(LaunchDarklyFlag.CHEF_RECOMMENDATION_ENGINE_2);
  const isChefEngineSanity = chefRecommendationEngine2.toLowerCase() === 'sanity';
  const isChefEngineDeepflame = chefRecommendationEngine2.toLowerCase() === 'deep_flame';
  const isChefDeprecated = useFlag(LaunchDarklyFlag.DEPRECATE_CHEF);

  const shouldUseMenuFeatureUpsell =
    FORCE_MENU_FEATURE_UPSELL_STRATEGY ||
    isChefEngineSanity ||
    (!isChefDeprecated && !isAuthenticated && enableSignupAfterCart);

  if (shouldUseMenuFeatureUpsell) {
    return <MenuFeatureUpsell {...props} />;
  }

  const shouldRenderUpsell = isAuthenticated && isChefEngineDeepflame && !!storeId;
  return shouldRenderUpsell ? <RecommendationEngineUpsell {...props} storeId={storeId} /> : null;
};
