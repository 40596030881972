import { Box, Text } from '@rbilabs/universal-components';

import Trophy from 'components/icons/trophy';
import { theme } from 'styles/configure-theme';
import { primitive } from 'styles/constants/primitives';

export const QuestBadgeWrapper = Box.withConfig<{ reversed?: boolean }>(({ reversed }) => ({
  backgroundColor: reversed
    ? primitive.$white
    : theme.token('background-contrast-loyalty-reversed'),
}));

export const QuestBadgeIcon = Trophy.withConfig<{ reversed?: boolean }>(({ reversed }) => ({
  height: '$4',
  fill: reversed
    ? theme.token('background-contrast-loyalty-reversed')
    : theme.token('text-reversed'),
}));

export const QuestBadgeText = Text.withConfig<{ reversed?: boolean }>(({ reversed }) => ({
  color: reversed
    ? theme.token('background-contrast-loyalty-reversed')
    : theme.token('text-reversed'),
}));
