import { Box, Header, Text } from '@rbilabs/universal-components';

import Picture from 'components/picture';

export const EmptyStateContainer = Box.withConfig({
  justifyContent: 'center',
  alignItems: 'center',
  flex: 1,
  maxWidth: '240px',
  width: 'full',
  marginX: 'auto',
});

export const EmptyStateImage = Picture.withConfig({
  height: '107px',
  width: '95px',
});

export const EmptyStateTitle = Header.withConfig({
  accessibilityLevel: 3,
  marginTop: '$2',
  marginBottom: '$3',
  textTransform: 'uppercase',
  color: 'black',
  fontSize: '2xl',
  textAlign: 'center',
});

export const EmptyStateBody = Text.withConfig({
  textAlign: 'center',
  marginBottom: '$4',
});
