import React from 'react';

import { Box, Skeleton, Stack, VStack } from '@rbilabs/universal-components';

import { theme } from 'styles/configure-theme';

const SkeletonWrapper = Box.withConfig({
  backgroundColor: theme.token('background-hero-light'),
  flexDir: { base: 'column', md: 'row-reverse' },
  py: { base: '$4', md: '$0' },
  px: { base: '$0', md: '$4' },
});

export const HeroSkeleton = () => {
  return (
    <SkeletonWrapper>
      <Box width={{ base: '100%', md: '60%' }} p="$4">
        <Skeleton
          size={{ base: '$48', md: '$72' }}
          rounded={{ base: 'full', md: 'none' }}
          mx="auto"
        />
      </Box>
      <VStack width={{ base: '100%', md: '40%' }} justifyContent="center" p="$4">
        <Skeleton h={{ base: '$4', md: '$7' }} rounded="full" mb="$4" />
        <Skeleton.Text mb="$8" lines={2} />
        <Stack
          flexDir={{ md: 'row' }}
          alignItems="center"
          justifyContent={{ base: 'center', md: 'flex-start' }}
        >
          <Skeleton
            h="$10"
            width={{ base: 'full', md: '$24' }}
            rounded="full"
            startColor="token.background-skeleton-loader-cta-start"
            endColor="token.background-skeleton-loader-cta-end"
            mr={{ base: '$0', md: '$3' }}
            mb={{ base: '$6', md: '$0' }}
          />
          <Skeleton h="$4" rounded="full" width="$24" />
        </Stack>
      </VStack>
    </SkeletonWrapper>
  );
};
