import { Box, Header } from '@rbilabs/universal-components';

export const InformationTitle = Header.withConfig({
  variant: 'headerThree',
});

export const NutritionInformationWrapper = Box.withConfig({
  marginTop: 4,
  paddingX: 4,
});
