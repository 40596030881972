import { Box } from '@rbilabs/universal-components';

export const HeroContent = Box.withConfig<{ $windowHeight: number }>(p => ({
  paddingY: {
    base: '4',
    md: '16',
  },
  paddingX: {
    base: 0,
    md: '12',
  },
  minHeight: {
    base: p.$windowHeight * 0.65,
    md: 'none',
  },
  justifyContent: {
    md: 'center',
  },
}));
