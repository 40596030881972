import * as React from 'react';

import { Box, Button, HStack, Header, Icon, Text } from '@rbilabs/universal-components';
import { useIntl } from 'react-intl';

import { theme } from 'styles/configure-theme';

import { getQuestCheckConnectionText, getQuestLoadErrorText } from '../custom-text';

const ImageWrapper = Box.withConfig({
  height: '$48',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: theme.token('background-default'),
});

const ReloadButton = Button.withConfig({
  variant: 'outline',
  borderColor: theme.token('border-color-error'),
  _text: {
    variant: 'copyTwo',
    fontSize: 'sm',
    color: theme.token('text-error'),
  },
});

export const QuestCardError = ({ refetch }: { refetch: () => void }) => {
  const { formatMessage } = useIntl();

  return (
    <Box borderRadius="xl" backgroundColor={theme.token('background-pattern')} shadow="2">
      <ImageWrapper>
        <Icon variant="errorFill" size="12" color={theme.token('icon-disabled')} />
      </ImageWrapper>
      <Box padding="$4">
        <Header variant="headerTwo">{getQuestLoadErrorText(formatMessage)}</Header>
        <Text marginBottom="$4">{getQuestCheckConnectionText(formatMessage)}</Text>
        <HStack flexShrink={1}>
          <ReloadButton
            leftIcon={
              <Icon variant="refresh" size="sm" color={theme.token('border-color-error')} />
            }
            onPress={refetch}
          >
            {formatMessage({ id: 'tryAgain' })}
          </ReloadButton>
        </HStack>
      </Box>
    </Box>
  );
};
