import React from 'react';

import { OnboardingStep } from 'components/onboarding-multi-step';
import { ILoyaltyBasicCardWidgetFragment } from 'generated/sanity-graphql';
import { selectors, useAppSelector } from 'state/global-state';
import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';

import Container from './loyalty-basic-card-container';
import Description from './loyalty-basic-card-description';
import {
  StyledPicture,
  Title,
  TitleAndDescriptionContainer,
} from './loyalty-basic-card-widget.styled';

/**
 *
 * LoyaltyBasicCardWidget shows unlinked card or linked card UI
 * accordingly the physical card value
 */
const LoyaltyBasicCardWidget = ({ linkedCard, unlinkedCard }: ILoyaltyBasicCardWidgetFragment) => {
  const enableLinkPhysicalCard = useFlag(LaunchDarklyFlag.ENABLE_LINK_PHYSICAL_CARD);
  const cardData = useAppSelector(selectors.loyalty.select12DigitSwipeCard);

  if (!enableLinkPhysicalCard) {
    return null;
  }

  const visibleCard = cardData?.identifier ? linkedCard : unlinkedCard;
  if (!visibleCard) {
    return null;
  }
  const {
    marketingBasicTileTitle,
    marketingBasicTileDescription,
    marketingBasicTileImage,
    link,
  } = visibleCard;
  const titleText = marketingBasicTileTitle?.locale;
  const image = marketingBasicTileImage?.locale?.app;
  const imageAlt = marketingBasicTileImage?.locale?.imageDescription ?? '';
  const linkPath = link?.locale;

  return (
    <OnboardingStep
      targetId="basic_card_widget"
      stepIndex="2"
      backgroundColor="transparent"
      tooltipOffset={-4}
    >
      <Container link={linkPath}>
        <TitleAndDescriptionContainer>
          <Title>{titleText}</Title>
          <Description
            descriptionText={cardData?.identifier}
            descriptionBlockContent={marketingBasicTileDescription?.localeRaw}
          />
        </TitleAndDescriptionContainer>
        <StyledPicture image={image} alt={imageAlt} />
      </Container>
    </OnboardingStep>
  );
};

export default LoyaltyBasicCardWidget;
