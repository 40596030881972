import { Box, HStack, Header, Text } from '@rbilabs/universal-components';

export const Group = Box.withConfig({
  backgroundColor: Styles.color.cardBackground,
});

export const HeaderWrapper = HStack.withConfig({
  alignItems: 'center',
  justifyContent: 'space-between',
  paddingTop: 6,
  paddingBottom: 2,
  paddingX: 4,
});

export const HeaderText = Header.withConfig<{ $isNested?: boolean }>(({ $isNested }) => ({
  variant: $isNested ? 'headerFour' : 'headerThree',
  margin: 0,
  flex: 1,
  paddingRight: '$2',
}));

export const SubHeaderText = Text.withConfig({
  variant: 'copyTwo',
  paddingX: 4,
  paddingY: 1,
  marginTop: -2,
});

export const Footer = HStack.withConfig({
  borderBottomWidth: '1px',
  borderBottomColor: '#dcdcdc',
  alignItems: 'center',
  justifyContent: 'space-between',
  minHeight: 10,
  paddingX: 4,
  paddingY: 1,
});

export const ItemGroup = Box.withConfig({
  borderBottomWidth: '1px',
  borderBottomColor: '#dcdcdc',
});
