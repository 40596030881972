import React, { FC } from 'react';

import { useIntl } from 'react-intl';

import { useCarouselRewards } from 'components/features/components/rewards-section/use-carousel-rewards';
import { ResponsiveCarouselWrapper } from 'components/responsive-carousel';
import { RewardsCarousel } from 'components/rewards-carousel';
import { useLoyaltyFeaturedRewardsQuery } from 'generated/sanity-graphql';

const LoyaltyFeaturedRewards: FC<React.PropsWithChildren<unknown>> = () => {
  const { formatMessage } = useIntl();
  const { data, loading } = useLoyaltyFeaturedRewardsQuery();
  const carouselRewards = useCarouselRewards({
    rewards: data?.allRewardsSections?.[0]?.rewardsList || [],
  });

  if (loading || !carouselRewards?.length) {
    return null;
  }

  return (
    <ResponsiveCarouselWrapper
      background="transparent"
      header={formatMessage({ id: 'featured' })}
      mt="$4"
      pl="$4"
      pr="$4"
    >
      <RewardsCarousel rewards={carouselRewards} />
    </ResponsiveCarouselWrapper>
  );
};

export default LoyaltyFeaturedRewards;
