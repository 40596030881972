import { Box, NBStyledComponentProps, VStack } from '@rbilabs/universal-components';

import { IMarketingCardGroupFragment, IMarketingTileGroup } from 'generated/sanity-graphql';

export interface IMarketingTileGroupProps {
  item: IMarketingTileGroup | IMarketingCardGroupFragment;
  // TODO: RN - Expose component props interfaces from '@rbilabs/universal-components'
  props?: any;
  _marketingTileList?: NBStyledComponentProps<typeof VStack>;
  style?: NBStyledComponentProps<typeof Box>;
}

export enum MarketingTileGroupType {
  MARKETING_TILE = 'MarketingTile',
  MARKETING_TILE_BASIC = 'MarketingTileBasic',
  MARKETING_TILE_PAIR = 'MarketingTilePair',
  MARKETING_TILE_QUAD = 'MarketingTileQuad',
  MARKETING_TILE_LIST = 'MarketingTileList',
}
