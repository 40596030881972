import React, { FC, useCallback } from 'react';

import { Box, Icon, Pressable, Text } from '@rbilabs/universal-components';

import { ListItemGroup } from 'components/list-item-group';
import { theme } from 'styles/configure-theme';

import { IRadioCheckListProps } from './types';

const RadioCheckListItem = Pressable.withConfig({
  paddingY: 4,
  flexDirection: 'row',
  justifyContent: 'space-between',
});

const RadioCheckListSeparator = Box.withConfig({
  height: '1px',
  backgroundColor: Styles.color.grey.four,
});

const RadioCheckList: FC<React.PropsWithChildren<IRadioCheckListProps>> = ({
  options,
  selectedValue,
  onChange,
}) => {
  const handleClick = useCallback(
    (val: string) => {
      if (onChange) {
        onChange(val);
      }
    },
    [onChange]
  );
  return (
    <ListItemGroup
      testID="radio-check-list"
      ItemSeparator={<RadioCheckListSeparator />}
      items={options.map((opt, i) => (
        <RadioCheckListItem
          testID="radio-check-list-item"
          onPress={() => handleClick(opt.value)}
          key={i}
        >
          <Text>
            {opt.label}
            {opt.labelSuffix && <Text fontWeight="bold"> {opt.labelSuffix}</Text>}
          </Text>
          {opt.value === selectedValue && (
            <Icon
              testID="radio-check-list-icon"
              variant="check"
              title="check"
              color={theme.token('icon-default')}
              size={4}
            />
          )}
        </RadioCheckListItem>
      ))}
    />
  );
};

export default RadioCheckList;
