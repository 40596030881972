import React, { useCallback } from 'react';

import { useIntl } from 'react-intl';

import ActionButton from 'components/action-button';
import ItemUnavailableMessage from 'components/item-unavailable-message';
import { useIsTabletBp } from 'hooks/breakpoints';
import { useNavigation } from 'hooks/navigation/use-navigation';
import { useRoute } from 'hooks/navigation/use-route';
import { appendObjectToQueryString } from 'hooks/navigation/utils';
import { useMenuContext } from 'state/menu';
import { useProductWizardContext } from 'state/product-wizard';
import { routes } from 'utils/routing';

import { useProductAvailability } from '../use-product-availability';

import { PRODUCT_CTA_LABEL_ID } from './constants';
import { ProductCtaWrapper } from './product-cta-wrapper';

export const ProductStaticMenuCta: React.FC<React.PropsWithChildren<{
  showStaticPickers?: boolean;
}>> = ({ showStaticPickers }) => {
  const { params } = useRoute();
  const { navigate } = useNavigation();
  const { setSelectedStaticMenuItem } = useMenuContext();
  const { menuObject } = useProductWizardContext();

  const { formatMessage } = useIntl();
  const { dayParts, isItemAvailableForDaypart, itemDayParts } = useProductAvailability();
  const isTablet = useIsTabletBp();

  const handleFindRestaurantClick = useCallback(() => {
    const { _type, _id, name } = menuObject;
    const menuObjectId = `${_type}-${_id}`;
    setSelectedStaticMenuItem({
      itemId: menuObjectId,
      itemName: name?.locale,
      search: appendObjectToQueryString('', params),
    });
    navigate(routes.storeLocator, { replace: true });
  }, [menuObject, navigate, params, setSelectedStaticMenuItem]);

  return (
    <ProductCtaWrapper
      elements={[
        {
          element: isItemAvailableForDaypart ? (
            <ActionButton onPress={handleFindRestaurantClick} fullWidth>
              {formatMessage({ id: PRODUCT_CTA_LABEL_ID })}
            </ActionButton>
          ) : (
            <ItemUnavailableMessage
              dayParts={dayParts}
              itemDayParts={itemDayParts}
              marginAndBorders={isTablet && !showStaticPickers}
            />
          ),
        },
      ]}
    />
  );
};
