import { DependencyList, useMemo, useRef } from 'react';

import shallowequal from 'shallowequal';

const useShallowDeps = (latestDeps: DependencyList) => {
  const depsRef = useRef(latestDeps);
  if (!latestDeps.every((dep, i) => shallowequal(depsRef.current[i], dep))) {
    depsRef.current = latestDeps;
  }
  return depsRef.current;
};

const useShallowMemo = <T>(cb: () => T, deps: DependencyList) => {
  return useMemo<T>(cb, useShallowDeps(deps));
};

export default useShallowMemo;
