import { useCallback, useMemo } from 'react';

import type {
  ILoyaltyPersonalizeOffersQuery,
  ILoyaltyPersonalizeOffersQueryVariables,
} from 'generated/sanity-graphql';
import {
  LoyaltyPersonalizeOffersDocument,
  useLoyaltyPersonalizeOffersQuery,
  usePlaceholderFieldsQuery,
} from 'generated/sanity-graphql';
import { useFlag } from 'state/launchdarkly';
import { LaunchDarklyFlag } from 'utils/launchdarkly';

import type { ICmsConfigOffer, ICmsOffer, ICmsTemplateOffer, IEngineOffer } from '../types';

import type { PersonalizedEngineOffer } from './types';
import { useApplyOfferPlaceholders } from './use-apply-offer-placeholders';
import {
  createCmsPersonalizedMapByEngineId,
  createOfferPlaceHolderFieldsMap,
  getConfigAndTemplateIds,
  isPersonalizedEngineOffer,
} from './utils';

/**
 * Fetch personalize data and create the personalized offer objects applied the templates.
 */
export const useLoyaltyPersonalizedOfferList = ({
  engineOffers,
}: {
  engineOffers: readonly IEngineOffer[] | null;
}) => {
  const { applyOfferPlaceholders } = useApplyOfferPlaceholders();

  const personalizedOffersTemplateEnabled = Boolean(
    useFlag(LaunchDarklyFlag.ENABLE_PERSONALIZED_OFFERS_TEMPLATE)
  );

  const personalizedEngineOffers: PersonalizedEngineOffer[] = useMemo(
    () =>
      personalizedOffersTemplateEnabled && engineOffers
        ? engineOffers.filter(isPersonalizedEngineOffer)
        : [],
    [engineOffers, personalizedOffersTemplateEnabled]
  );

  // get all engine config and template ids
  const { configEngineIds, templateEngineIds } = getConfigAndTemplateIds(personalizedEngineOffers);

  const skipQueries = !configEngineIds.length && !templateEngineIds.length;
  const { data, loading, refetch, client } = useLoyaltyPersonalizeOffersQuery({
    variables: {
      configEngineIds,
      templateEngineIds,
    },
    skip: skipQueries,
  });

  const {
    data: placeholderFieldsData,
    loading: loadingPlaceholderFields,
  } = usePlaceholderFieldsQuery({
    skip: skipQueries,
  });

  const configOffers = data?.allConfigOffers;
  const offerTemplates = data?.allOfferTemplates;
  const placeholderFields = placeholderFieldsData?.allPlaceholderFields;

  const updateQuery = useCallback(
    ({
      configOffers,
      offerTemplates,
    }: {
      configOffers?: ICmsConfigOffer[];
      offerTemplates?: ICmsTemplateOffer[];
    }) => {
      // calculating the config and template ids because those values are not memoized in the outer scope
      const { configEngineIds, templateEngineIds } = getConfigAndTemplateIds(
        personalizedEngineOffers
      );

      // updating the cache with a new query and update the variables with the new IDs
      client.cache.updateQuery<
        ILoyaltyPersonalizeOffersQuery,
        ILoyaltyPersonalizeOffersQueryVariables
      >(
        {
          query: LoyaltyPersonalizeOffersDocument,
          variables: {
            configEngineIds: Array.from(
              new Set(configEngineIds.concat(configOffers?.map(({ _id }) => _id) || []))
            ),
            templateEngineIds: Array.from(
              new Set(templateEngineIds.concat(offerTemplates?.map(({ _id }) => _id) || []))
            ),
          },
          // this is a dependant query, shouldn't be necessary communicate this change to query listeners
          broadcast: false,
        },
        data => ({
          ...(data || {}),
          allConfigOffers: (data?.allConfigOffers || []).concat(configOffers || []),
          allOfferTemplates: (data?.allOfferTemplates || []).concat(offerTemplates || []),
        })
      );
    },
    [client.cache, personalizedEngineOffers]
  );

  const personalizedOffers: ICmsOffer[] = useMemo(() => {
    // if the are not config offers means the user doesn't have personalized offers or the query is still on flight
    if (!configOffers?.length) {
      return [];
    }

    // create maps to access to the entities by key
    // not using `keyBy` to avoid the Dictionary type which trying to access to a property by square brackets
    // always returns a type different than `undefined`
    const configOffersMapByEngineId = createCmsPersonalizedMapByEngineId(configOffers);
    const offerTemplatesMapByEngineId = createCmsPersonalizedMapByEngineId(offerTemplates);

    const placeholderFieldsMap = createOfferPlaceHolderFieldsMap(placeholderFields || []);

    return personalizedEngineOffers.reduce<ICmsOffer[]>((acc, engineOffer) => {
      const { templateId, configId } = engineOffer;
      const configOffer = configOffersMapByEngineId?.[configId];

      if (!configOffer) {
        return acc;
      }

      const offerTemplate = offerTemplatesMapByEngineId?.[templateId || ''];
      const personalizedOffer = applyOfferPlaceholders({
        configOffer,
        engineOffer,
        offerTemplate,
        placeholderFieldsMap,
      });

      if (personalizedOffer) {
        acc.push(personalizedOffer);
      }

      return acc;
    }, []);
  }, [
    applyOfferPlaceholders,
    configOffers,
    offerTemplates,
    personalizedEngineOffers,
    placeholderFields,
  ]);

  return { personalizedOffers, refetch, loading: loading || loadingPlaceholderFields, updateQuery };
};
