import React from 'react';

import { Box, HStack, Text } from '@rbilabs/universal-components';
import { useIntl } from 'react-intl';

import { IHistoricalOrder } from '@rbi-ctg/menu';
import ActionButton, { ActionButtonSizes } from 'components/action-button';
import { FavoriteOrderButton } from 'components/favorite-order-button';
import { RecentOrderCard } from 'components/recent-order-card-list-item';
import useOrderNumber from 'hooks/use-order-number';
import { useCRMEventsContext } from 'state/crm-events';
import { useFlag } from 'state/launchdarkly';
import { useReorder } from 'state/order/hooks/use-reorder';
import { useStoreContext } from 'state/store';
import { useCreateOrderAriaLabel } from 'utils/accessibility/aria';
import { LaunchDarklyFlag } from 'utils/launchdarkly';
import { logAddRecentItemToCartEvent } from 'utils/mParticle';

const Header = HStack.withConfig({
  paddingTop: '$1',
  paddingBottom: '$4',
  paddingX: '$3',
  // TODO: RN this breaks
  //backgroundColor: theme.recentOrderHeaderBackgroundColor,
  alignItems: 'center',
  justifyContent: 'space-between',
});

interface RecentOrderCardGroupProps {
  order: IHistoricalOrder;
}

const RecentOrderCardGroup: React.FC<React.PropsWithChildren<RecentOrderCardGroupProps>> = ({
  order,
}) => {
  const { formatMessage } = useIntl();
  const { logRBIEvent } = useCRMEventsContext();
  const { store } = useStoreContext();
  const enableFavorites = useFlag(LaunchDarklyFlag.ENABLE_FAVORITES);
  const createAriaLabel = useCreateOrderAriaLabel();
  const { handleReorderClick, disabled } = useReorder(order);
  const reorderCartEntries = order.cart.reorderCartEntries;
  const orderNumber = useOrderNumber(order);
  const formattedDate = new Date(order.createdAt).toLocaleString(
    formatMessage({ id: 'localeDateTime' }),
    {
      month: 'long',
      day: 'numeric',
    }
  );

  const handleReorder = () => {
    for (const entry of order.cart.cartEntries) {
      logAddRecentItemToCartEvent(entry?.name ?? '', store, logRBIEvent);
    }
    handleReorderClick();
  };

  if (!reorderCartEntries?.length) {
    return null;
  }

  return (
    <Box testID="recent-order-card-group" paddingBottom="$3">
      <Header>
        <HStack alignItems="center">
          <Text fontSize="sm" fontWeight="bold">
            {formattedDate}
          </Text>
          {enableFavorites && <FavoriteOrderButton order={order} />}
          {orderNumber && (
            <Text fontSize="sm" marginLeft="$2">
              {formatMessage({ id: 'order' })} #{orderNumber}
            </Text>
          )}
        </HStack>
        <ActionButton
          testID="reorder-button"
          onPress={handleReorder}
          disabled={disabled}
          size={ActionButtonSizes.SMALL}
          aria-label={createAriaLabel({
            buttonType: 'reorder',
            order,
          })}
        >
          {formatMessage({ id: 'reorder' })}
        </ActionButton>
      </Header>
      {reorderCartEntries.map(cartEntry => {
        if (cartEntry.isDonation) {
          return null;
        }

        return <RecentOrderCard key={cartEntry.lineId} cartEntry={cartEntry} />;
      })}
    </Box>
  );
};

export default RecentOrderCardGroup;
