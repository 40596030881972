import { HStack } from '@rbilabs/universal-components';

import Link from 'components/link';
import { MarketingBasicTile } from 'components/marketing-basic-tile';

export const Section = HStack.withConfig({
  width: 'full',
  maxWidth: Styles.layout.sectionMaxWidth,
  justifyContent: 'center',
  flexGrow: 1,
  flexShrink: 1,
  flexBasis: 'auto',
  marginX: { base: 'auto', md: '$6' },
  marginTop: {
    base: '$4',
    md: 0,
  },
  marginBottom: '$4',
  paddingX: {
    base: '$3',
    md: 0,
  },
});

export const LeftContainer = HStack.withConfig({
  flex: { md: 1 },
  paddingRight: { md: '$2' },
});

export const RightContainer = HStack.withConfig({
  flex: { md: 1 },
  paddingLeft: {
    md: '$2',
  },
});

export const QuadTileContainer = HStack.withConfig({
  // TODO: RN - Replace with another component to compose a grid layout.
  // display: grid;
  // grid-gap: 0.5rem;
  // grid-template-columns: 1fr 1fr;
  // ${Styles.desktop`
  //   grid-gap: 1rem;
  // `}

  maxWidth: { md: '800px' },
  justifyContent: 'center',
});

export const StyledLink = Link.withConfig({
  color: Styles.color.black,
});

export const StyledBasicTile = MarketingBasicTile.withConfig<{ newUI?: boolean }>(p => ({
  background: p.newUI ? null : Styles.color.white,
  flexDirection: 'row',
  padding: '$4',
  margin: '$4',
  borderRadius: 'md',
  shadow: p.newUI ? '0px' : '2px',
  borderWidth: p.newUI ? '1' : null,
  borderColor: p.newUI ? Styles.color.grey.five : null,
  _basicTileTitle: {
    color: Styles.color.alert.info.background,
    fontSize: 'sm',
    lineHeight: '2xs',
    textAlign: 'left',
  },
  _typographyBlock: {
    color: Styles.color.black,
    fontFamily: 'heading',
    fontSize: 'sm',
    lineHeight: '2xs',
  },
  _basicTileImage: {
    height: 'full',
    marginTop: 'auto',
    marginBottom: 'auto',
    marginLeft: '$0',
    marginRight: '$1',
    maxHeight: '50px',
    maxWidth: '50px',
  },
}));
