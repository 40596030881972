import React, { useMemo } from 'react';

import { Box, FlatListProps } from '@rbilabs/universal-components';
import { FlatList } from 'react-native';

import { useReactNavigationFlag } from 'hooks/use-react-navigation-flag';

import { useCmsSortedQuestCardListQuery } from '../cms-hooks/use-cms-sorted-quest-card-list';
import { IQuest } from '../loyalty-quest/types';
import { useLoyaltyQuestQuery } from '../loyalty-quest/use-loyalty-quest-query';
import { QuestCardWrapper } from '../quest-card-wrapper';
import { createCardListData } from '../utils';

import { QuestCardListSkeleton } from './quest-card-list-skeleton';
import { QuestLibraryHeader } from './quest-library-header';

export const StepSeparator = Box.withConfig({
  height: '$4',
});

const listHeaderStyles: FlatListProps<IQuest>['ListHeaderComponentStyle'] = {
  alignItems: 'center',
  justifyContent: 'center',
};

const QuestCardList = ({ loyaltyId }: { loyaltyId: string }) => {
  const enableReactNativeNavigation = useReactNavigationFlag();
  const { loadingQuests, quests } = useLoyaltyQuestQuery({ loyaltyId });
  const { loading: loadingCmsQuests, cmsQuestCardUI, cmsQuests } = useCmsSortedQuestCardListQuery();
  const questList = useMemo(
    () => (quests && cmsQuests ? createCardListData(quests, cmsQuests) : null),
    [quests, cmsQuests]
  );
  const noQuests = questList?.length === 0;

  if (loadingQuests || loadingCmsQuests || !cmsQuests || !cmsQuestCardUI) {
    return <QuestCardListSkeleton showHeaderLoader={!enableReactNativeNavigation} />;
  }

  if (noQuests) {
    return null;
  }

  return (
    <FlatList
      // For ReactNavigation the header will be displayed at the top of the page
      ListHeaderComponent={enableReactNativeNavigation ? null : QuestLibraryHeader}
      ListHeaderComponentStyle={listHeaderStyles}
      data={questList}
      keyExtractor={({ loyaltyQuest }) => loyaltyQuest.id}
      ItemSeparatorComponent={StepSeparator}
      renderItem={({ item }) => {
        const { loyaltyQuest, cmsQuestCard } = item;

        return (
          <QuestCardWrapper
            quest={loyaltyQuest}
            cmsQuestCard={cmsQuestCard}
            cmsQuestCardUI={cmsQuestCardUI}
          />
        );
      }}
    />
  );
};

export default QuestCardList;
