import { IMarketingTileFragment, IOffersPageMarketingTileFragment } from 'generated/sanity-graphql';

import {
  ITilesAndOffers,
  IUseOffersAndMarketingTiles,
  IUseOffersAndMarketingTilesProps,
} from './types';

export const useOffersPageMarketingTiles = ({
  offers,
  offersPageMarketingTiles,
  selectedOffer,
}: IUseOffersAndMarketingTilesProps): IUseOffersAndMarketingTiles => {
  const sortedTilesAndOffers: ITilesAndOffers = [...offers];

  if (!offersPageMarketingTiles) {
    return { sortedTilesAndOffers, marketingTileTop: null, marketingTileBottom: null };
  }
  const marketingTileTop: IMarketingTileFragment | null =
    offersPageMarketingTiles?.find(
      (tile: IOffersPageMarketingTileFragment) => tile?.displayPosition === 'top'
    )?.marketingTileReference ?? null;

  const marketingTileBottom: IMarketingTileFragment | null =
    offersPageMarketingTiles?.find(
      (tile: IOffersPageMarketingTileFragment) => tile?.displayPosition === 'bottom'
    )?.marketingTileReference ?? null;

  offersPageMarketingTiles.forEach((tile: IOffersPageMarketingTileFragment) => {
    const { displayPosition, customPosition, marketingTileReference } = tile;
    if (displayPosition !== 'custom' || !customPosition || !marketingTileReference) {
      return;
    }
    /*
     * If selected offer is present or a marketingTile on top, shift the tile higher in the index.
     * This way customPosition: 2 will always render in the second position.
     * Only exception is if there is both a selected Tile and a top marketing tile
     */
    const isSelectedOfferOrTopMarketingTile = selectedOffer || marketingTileTop;

    sortedTilesAndOffers.splice(
      isSelectedOfferOrTopMarketingTile ? customPosition - 2 : customPosition - 1,
      0,
      marketingTileReference
    );
  });
  return { sortedTilesAndOffers, marketingTileTop, marketingTileBottom };
};
