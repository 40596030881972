import { useCallback } from 'react';

import { useReactNavigationFlag } from 'hooks/use-react-navigation-flag';

import { useFocusEffect as useFocusEffectRn } from './use-focus-effect-rn';
import { useFocusEffect as useFocusEffectRnLegacy } from './use-focus-effect-rn-legacy';

/**
 * TODO: This useFocusEffect should be the one used across all the app, since there are components
 * that are using the one in ```use-focus-effect.ts``` and this file has its platform file ```.web.ts```
 * we should do a regression across all the flows in web (because it is already working in native with
 * useFocusEffect React Navigation version).
 * Once we removed all references to ```use-focus-effect.ts```, we can rename this file with that name
 * and remove ```use-focus-effect.web.ts```
 */
export const useFocusEffect: typeof useFocusEffectRn = effectCb => {
  const enableReactNativeNavigation = useReactNavigationFlag();

  useFocusEffectRn(
    useCallback(() => {
      if (enableReactNativeNavigation) {
        return effectCb();
      }
    }, [effectCb, enableReactNativeNavigation])
  );

  useFocusEffectRnLegacy(
    useCallback(() => {
      if (!enableReactNativeNavigation) {
        return effectCb();
      }
    }, [effectCb, enableReactNativeNavigation])
  );
};
