import React from 'react';

import { RedemptionButton } from './redemption-button';
import { RestaurantRedemptionButtonUiOption } from './types';

type IRestaurantRedemptionButton = {
  onPressRestaurantRedemption: () => void;
  restaurantRedemptionButtonUiType: RestaurantRedemptionButtonUiOption;
  restaurantRedemptionButtonTitle: string;
  disabled?: boolean;
};

export const RestaurantRedemptionButton: React.FC<React.PropsWithChildren<
  IRestaurantRedemptionButton
>> = ({
  onPressRestaurantRedemption,
  restaurantRedemptionButtonTitle,
  restaurantRedemptionButtonUiType,
  disabled,
}) => (
  <RedemptionButton
    onPressRestaurantRedemption={onPressRestaurantRedemption}
    restaurantRedemptionButtonTitle={restaurantRedemptionButtonTitle}
    restaurantRedemptionButtonUiType={restaurantRedemptionButtonUiType}
    disabled={disabled}
  />
);
