import React, { useCallback, useEffect, useMemo, useRef } from 'react';

import { Box, Checkbox, Header } from '@rbilabs/universal-components';
import { useIntl } from 'react-intl';

import ActionButton from 'components/action-button';
import Modal, { ModalSize } from 'components/modal';
import ModalContent from 'components/modal/modal-content';
import UnstyledButton from 'components/unstyled-button';

import { FilterMap, INutritionFilterModalProps } from './types';

const SectionHeader = Header.withConfig({
  variant: 'headerTwo',
  fontSize: 'xl',
  alignSelf: 'flex-start',
});

const CheckboxContainer = Box.withConfig({
  marginBottom: '$8',
  marginTop: '$3.5',
  width: 'full',
});

const StyledCheckbox = Checkbox.withConfig({
  flexBasis: {
    base: 'auto',
    md: '25%',
  },
});

const ResetButton = UnstyledButton.withConfig({
  position: 'absolute',
  top: '$16',
  right: '$5',
  _text: {
    fontSize: 'xl',
    fontFamily: Styles.fontFamily.base,
    fontWeight: Styles.fontWeight.normal,
    letterSpacing: 'md',
    textTransform: 'uppercase',
    color: Styles.color.primary,
  },
});

const ResultsCount = Box.withConfig({
  marginTop: '$10',
  marginBottom: '$8',
  _text: {
    fontSize: 'md',
    fontWeight: Styles.fontWeight.heavy,
  },
});

interface IFilterCheckbox {
  id: string;
  dataTestId: string;
  description: string;
  isChecked: boolean;
  selectFilter: (id: string, newValue: boolean) => void;
}
const FilterCheckbox: React.FC<React.PropsWithChildren<IFilterCheckbox>> = ({
  id,
  dataTestId,
  description,
  isChecked,
  selectFilter,
}) => {
  return (
    <StyledCheckbox
      testID={dataTestId}
      key={id}
      name={id}
      value=""
      isChecked={isChecked}
      onChange={() => selectFilter(id, !isChecked)}
      accessibilityLabel={description}
    >
      {description}
    </StyledCheckbox>
  );
};

export const NutritionFilterModal: React.FC<React.PropsWithChildren<
  INutritionFilterModalProps
>> = ({ resultsLeft, isModalOpen, filterGroups, activeFilters, onDismiss, applyFilters }) => {
  const { formatMessage } = useIntl();

  const savedActiveFilters = useRef(activeFilters);
  const prevIsModalOpen = useRef(isModalOpen);
  useEffect(() => {
    /**
     * when the modal goes from closed to open, capture the most recent
     * set of filters in case we want to restore the filters to
     * previous state
     * */
    if (!prevIsModalOpen.current && isModalOpen) {
      savedActiveFilters.current = activeFilters;
    }
    prevIsModalOpen.current = isModalOpen;
  }, [isModalOpen, activeFilters]);

  const onDismissModal = useCallback(() => {
    // if the user dismissed the modal, reset filters to prev value
    applyFilters(savedActiveFilters.current || []);
    onDismiss();
  }, [applyFilters, onDismiss]);

  const onClickApply = useCallback(() => {
    // filters are actively applied, so no need to apply them again
    onDismiss();
  }, [onDismiss]);

  const onReset = useCallback(() => {
    applyFilters([]);
  }, [applyFilters]);

  const allFilterMaps = useMemo(() => {
    const allFMs: FilterMap[] = [];

    filterGroups.forEach(fg => {
      const filterMap = fg.filters.reduce(
        (partialMap, filter) => ({ ...partialMap, [filter._id]: filter }),
        {}
      );
      allFMs.push(filterMap);
    });

    return allFMs;
  }, [filterGroups]);

  const selectFilter = useCallback(
    (filterId: string, newValue: boolean) => {
      const matchingFilterMap = allFilterMaps.find(fm => fm[filterId]);
      const matchingFilter = matchingFilterMap?.[filterId] ?? null;

      if (!matchingFilter) {
        return;
      }

      if (newValue) {
        applyFilters(oldFilters => oldFilters.concat([matchingFilter]));
      } else {
        applyFilters(oldFilters => oldFilters.filter(f => f !== matchingFilter));
      }
    },
    [allFilterMaps, applyFilters]
  );

  return !isModalOpen ? null : (
    <Modal
      size={ModalSize.REGULAR}
      onDismiss={onDismissModal}
      mParticleEventData={{
        modalAppearanceEventMessage: 'Nutrition Filter',
      }}
    >
      <ModalContent
        overScrollerContent={
          <ResetButton onPress={onReset}>{formatMessage({ id: 'reset' })}</ResetButton>
        }
      >
        {filterGroups.map(fg => {
          return (
            <React.Fragment key={fg._id}>
              <SectionHeader testID={`section-header-fg-${fg._id}`}>
                {fg.description.locale}
              </SectionHeader>
              <CheckboxContainer>
                {fg.filters.map(filter => {
                  const isChecked = activeFilters.some(
                    activeFilter => activeFilter._id === filter._id
                  );
                  return (
                    <FilterCheckbox
                      key={filter._id}
                      id={filter._id}
                      dataTestId={`filter-checkbox-${filter._id}`}
                      description={filter.description.locale}
                      isChecked={isChecked}
                      selectFilter={selectFilter}
                    />
                  );
                })}
              </CheckboxContainer>
            </React.Fragment>
          );
        })}
        <ResultsCount>
          {formatMessage(
            { id: 'resultsLeft' },
            {
              count: resultsLeft,
            }
          )}
        </ResultsCount>
        <Box mb="$6">
          <ActionButton onPress={onClickApply}>{formatMessage({ id: 'apply' })}</ActionButton>
        </Box>
      </ModalContent>
    </Modal>
  );
};
