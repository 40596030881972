import { DesignTokens } from 'styles/constants/design-tokens';

export enum HeaderLeftIconType {
  Back = 'back',
  Cancel = 'cancel',
  Menu = 'menu',
}

export interface IHeaderStylesHook {
  iconColor: string;
  backgroundColor: string;
  height: number;
}

export interface IHeaderStyles {
  headerBackground: keyof DesignTokens;
  headerBackgroundReversed: keyof DesignTokens;
  headerIconColor: keyof DesignTokens;
  headerIconColorReversed: keyof DesignTokens;
  logoWidth: string | undefined;
  logoHeight: string | undefined;
  logoLabel: string | undefined;
}
