import React, { useCallback, useMemo } from 'react';

import { Box, Header } from '@rbilabs/universal-components';
import delve from 'dlv';

import Link from 'components/link';
import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';
import { isWeb } from 'utils/environment';
import { isExternalLink } from 'utils/is-external-link';
import { routes } from 'utils/routing';

import { CCPAButtonLink } from './ccpa-button-link';
import { CCPA_COMPLIANCE } from './ccpa-button-link/constants';

interface SingleNode {
  title: string;
  path: string;
}
interface ArrayNode {
  name: { locale: string };
  pages: [SingleNode];
}
interface IListProps {
  title: string;
  entries: [SingleNode | ArrayNode];
  borderColor?: string;
  sectionHeadings?: boolean;
  onDismiss?: () => void;
  testID?: string;
}

const addPathSlash = (path: string): string =>
  isExternalLink(path) || path.startsWith('/') ? path : `/${path}`;

const getPaths = (nodes: [SingleNode | ArrayNode]): any => {
  return nodes.map((node: SingleNode | ArrayNode) => {
    const title = delve(node, 'localeTitle.locale') || delve(node, 'title') || '';
    const path = delve(node, 'localePath.locale.current') || delve(node, 'path.current') || '';
    if ((node as SingleNode).path) {
      return {
        title,
        // Fix relative paths not having a leading slash
        path: addPathSlash(delve(node, 'redirectUrl.locale') || path),
      };
    }
    return {
      title: (node as ArrayNode).name?.locale,
      pages: getPaths((node as ArrayNode).pages),
    };
  });
};

const Section = Box.withConfig<{ borderColor?: string; hasBorder: boolean }>(p => ({
  borderTopWidth: p.hasBorder ? '1' : '0',
  borderTopColor: p.borderColor ?? Styles.color.grey.five,
}));

const SectionHeader = Header.withConfig({
  variant: 'headerThree',
  textTransform: Styles.textTransform.headlines,
  fontSize: 'md',
  marginTop: '4',
  marginBottom: '0',
});

const SectionGroup = Box.withConfig<{ hasHeader?: boolean }>(p => ({
  paddingTop: p.hasHeader ? '0' : '4',
  paddingBottom: '4',
}));

const SectionItem = Box.withConfig({
  marginTop: '1',
  marginBottom: 1,
});

export default function ListOfLinks({
  onDismiss,
  entries,
  sectionHeadings,
  borderColor,
}: IListProps) {
  const enableNutritionExplorerLink = useFlag(LaunchDarklyFlag.ENABLE_NUTRITION_EXPLORER);
  const ENABLE_CCPA_DO_NOT_SELL_BUTTON = useFlag(LaunchDarklyFlag.ENABLE_CCPA_DO_NOT_SELL_BUTTON);

  const filterMenuPaths = useCallback(
    (entries: any) => {
      return entries.filter((entry: SingleNode | ArrayNode) => {
        if ('pages' in entry) {
          return filterMenuPaths(entry.pages);
        }
        return entry.path?.includes(routes.nutritionExplorer.slice(1))
          ? enableNutritionExplorerLink
          : true;
      });
    },
    [enableNutritionExplorerLink]
  );

  const paths = useMemo(() => filterMenuPaths(getPaths(entries)), [entries, filterMenuPaths]);

  const onPress = useCallback(() => {
    onDismiss?.();
  }, [onDismiss]);

  return (
    <Box>
      {paths.map((entry: any, i: number) => {
        const isSection = Array.isArray(entry.pages);
        const pages = isSection ? entry.pages : [entry];
        const showHeader = isSection && sectionHeadings;
        return (
          <Section hasBorder={i > 0} borderColor={borderColor} key={`path-group-${i}`}>
            {showHeader && <SectionHeader>{entry.title}</SectionHeader>}

            <SectionGroup hasHeader={showHeader}>
              {pages.map((node: SingleNode) => {
                const isCCPALink: boolean = !!node.path?.includes(CCPA_COMPLIANCE);
                const showCCPAButtonLink = isCCPALink && ENABLE_CCPA_DO_NOT_SELL_BUTTON && isWeb;
                return (
                  <SectionItem marginTop="2" key={`${node.title}: ${node.path}`}>
                    {showCCPAButtonLink ? (
                      <CCPAButtonLink />
                    ) : (
                      <Link
                        to={node.path}
                        onPress={() => onPress()}
                        isExternal={isExternalLink(node.path)}
                      >
                        {node.title}
                      </Link>
                    )}
                  </SectionItem>
                );
              })}
            </SectionGroup>
          </Section>
        );
      })}
    </Box>
  );
}
