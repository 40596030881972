import logger from 'utils/logger';

import { BranchEventNames } from '../branch-event-names';

export const logBranchEvent = (
  eventName: typeof BranchEventNames[keyof typeof BranchEventNames],
  _customAttributes?: Record<string, any>
) => {
  try {
    window.branch?.logEvent(eventName);
  } catch (error) {
    logger.error({ error, message: `Error logging ${eventName} Event to Branch` });
  }
};
