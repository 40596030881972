import * as React from 'react';

import { VStack } from '@rbilabs/universal-components';
import { useWindowDimensions } from 'react-native';

import { BackArrowHistory } from 'components/navigation/back-arrow';
import { PageWrapper } from 'components/page-wrapper';
import AuthRequired from 'pages/authentication/auth-required';
import { useLoyaltyUser } from 'state/loyalty/hooks/use-loyalty-user';

import QuestList from './quest-card-list';

const Wrapper = VStack.withConfig<{
  $windowHeight: number;
}>(({ $windowHeight }) => ({
  height: '100%',
  maxHeight: $windowHeight,
  paddingY: '$2',
  paddingX: '$4',
}));

const QuestLibrary = () => {
  const { height: $windowHeight } = useWindowDimensions();
  const { loyaltyUser } = useLoyaltyUser();
  const loyaltyId = loyaltyUser?.id;

  return (
    <PageWrapper>
      <AuthRequired>
        <BackArrowHistory />
        <Wrapper $windowHeight={$windowHeight}>
          {loyaltyId && <QuestList loyaltyId={loyaltyId} />}
        </Wrapper>
      </AuthRequired>
    </PageWrapper>
  );
};

export default QuestLibrary;
