import { Pressable } from '@rbilabs/universal-components';

import Link from 'components/link';

export const NavLinkStyles = {
  justifyContent: 'flex-end',
  alignItems: 'center',
  paddingY: '8px',
};

const StyledLink = Link.withConfig(NavLinkStyles);

export const NavButton = Pressable.withConfig(NavLinkStyles);

export default StyledLink;
