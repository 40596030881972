import React, { VFC, useEffect } from 'react';

import { Text } from '@rbilabs/universal-components';

import { Calories } from 'components/price-and-calories';
import { useMenuCalories } from 'hooks/menu/use-menu-calories';
import { useProductPrice } from 'hooks/menu/use-product-price';
import { computeProductCalories } from 'hooks/menu/utils';
import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';
import { useMenuContext } from 'state/menu';
import { useProductWizardContext } from 'state/product-wizard';
import { EventName, emitEvent } from 'utils/event-hub';

import { PriceAndCaloriesText } from './price-and-calories.styled';

export const PriceAndCalories: VFC = () => {
  const { showStaticMenu } = useMenuContext();
  const { formattedProductPrice } = useProductPrice();

  const { selectedProduct } = useProductWizardContext();
  const { formatCalories } = useMenuCalories();
  const isCaloriesCalculatorEnabled = useFlag(LaunchDarklyFlag.ENABLE_CALORIES_CALCULATOR);

  const shouldComputeCalories =
    isCaloriesCalculatorEnabled && !!selectedProduct && !selectedProduct.hideCalories;
  const productCalories = shouldComputeCalories ? computeProductCalories(selectedProduct) : 0;
  const formattedProductCalories = formatCalories(productCalories);

  useEffect(() => {
    if (!formattedProductCalories) {
      return;
    }
    // Emit event for when calories are loaded since menu performance tracking uses this
    emitEvent(EventName.MENU_ITEM_CALORIES_LOADED);
  }, [formattedProductCalories]);

  return (
    <PriceAndCaloriesText>
      {!showStaticMenu && (
        <Text variant="copyOne" testID="product-total-price">
          {formattedProductPrice}
        </Text>
      )}
      {isCaloriesCalculatorEnabled && (
        <Calories hideCalories={!!selectedProduct?.hideCalories}>
          <Text variant="copyOne" testID="product-total-calories">
            {!showStaticMenu && <Text variant="copyOne"> • </Text>}
            {formattedProductCalories}
          </Text>
        </Calories>
      )}
    </PriceAndCaloriesText>
  );
};

export default PriceAndCalories;
