import React from 'react';

import { MenuObjectContent } from 'pages/menu-content/menu-object-content';
import { MenuSectionContent } from 'pages/menu-content/menu-section-content';

interface IMenuSectionParams {
  id: string;
  sectionId?: string;
  subSectionId?: string;
}

interface IMenuSectionScreenProps {
  navigation: {
    setParams: (params: object) => void;
  };
  route: {
    name: string;
    params: IMenuSectionParams;
  };
}

// Multiple instances of this component will be held on memory, representing the navigated menu tabs.
// The contents of the selected menu tab might be replaced with a subsection based on the nav params.
export const MenuSectionScreen = ({ route }: IMenuSectionScreenProps) => {
  const { name: pathname, params } = route;
  const { id, sectionId, subSectionId } = params;

  const isCurrentMenuTab = id === sectionId;
  const renderedSectionId = isCurrentMenuTab && !!subSectionId ? subSectionId : id;

  if (!id.startsWith('section')) {
    return (
      <MenuObjectContent id={renderedSectionId} pathname={pathname} shouldBeTreatedAsSection />
    );
  }
  return <MenuSectionContent id={renderedSectionId} pathname={pathname} />;
};
