import { Box, HStack, Header, Text, VStack, View } from '@rbilabs/universal-components';

import Picture from 'components/picture';
import { theme } from 'styles/configure-theme';

export const ProductImageContainer = Box.withConfig({});

export const ProductImageWrapper = Box.withConfig({
  alignItems: 'center',
  justifyContent: {
    base: 'flex-start',
    md: 'center',
  },
  flex: 1,
});

export const TitleWrapper = HStack.withConfig({
  paddingX: 3,
  alignItems: 'center',
  minHeight: 16,
});

export const Title = Header.withConfig({
  variant: 'headerOne',
  textAlign: 'center',
  numberOfLines: 2,
  ellipsizeMode: 'tail',
});

export const ImageDisclaimerText = Text.withConfig({
  variant: 'copyOne',
  textAlign: 'center',
});

export const ProductInformationContainer = VStack.withConfig({
  width: '100%',
  paddingX: 4,
  alignItems: 'center',
});

export const BackgroundImageColor = View.withConfig({
  background: theme.token('background-menu-customization-image'),
  position: 'absolute',
  width: '100%',
  height: '100%',
});

export const StyledProductPicture = Picture.withConfig({
  marginTop: '20px',
  width: '100%',
  height: '245px',
  alignSelf: 'center',
  objectFitContain: true,
});
