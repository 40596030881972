import React from 'react';

import { makeUclComponent } from '@rbilabs/universal-components';

import LoadingAnimation from 'components/loading-animation';
import { useIsTabletBp } from 'hooks/breakpoints';

import { LoyaltyLoaderContainer } from './loyalty-loader.styled';

const LoyaltyLoader = ({ ...props }) => {
  const isTablet = useIsTabletBp();

  return (
    <LoyaltyLoaderContainer {...props} width={isTablet ? 180 : 140}>
      <LoadingAnimation />
    </LoyaltyLoaderContainer>
  );
};

export default makeUclComponent(LoyaltyLoader);
