import { useMemo } from 'react';

import { useCarouselRewards } from 'components/features/components/rewards-section/use-carousel-rewards';
import { useFeatureHomePage } from 'components/features/home-page/use-feature-home-page';
import { IRewardsSectionFragment } from 'generated/sanity-graphql';

const useHomeRewards = () => {
  // TODO: query only the loyalty section
  const { featureHomePageLoading, featureHomePage } = useFeatureHomePage();
  const homeRewards = useMemo(
    () =>
      featureHomePage?.components?.find<IRewardsSectionFragment>(
        (cmp): cmp is IRewardsSectionFragment => cmp?.__typename === 'RewardsSection'
      )?.rewardsList ?? [],
    [featureHomePage]
  );
  const rewards = useCarouselRewards({ rewards: homeRewards });

  return {
    loadingRewards: featureHomePageLoading,
    rewards,
  };
};

export default useHomeRewards;
