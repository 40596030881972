import { Icon } from '@rbilabs/universal-components';

import { AccentBadge } from './accent-badge';
import { IFilledAccentBadgeProps, IStyledIconProps } from './types';

export const FilledAccentBadge = AccentBadge.withConfig<IFilledAccentBadgeProps>(p => ({
  flexDirection: 'row',
  alignItems: 'center',
  backgroundColor: p.$fillColor || Styles.color.grey.five,
}));

export const StyledIcon = Icon.withConfig<IStyledIconProps>(p => ({
  marginRight: '3px',
  color: p.$iconColor || Styles.color.white,
}));
