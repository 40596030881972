export enum HorizontalPosition {
  LEFT = 'left',
  CENTER = 'center',
  RIGHT = 'right',
}

export enum TileSize {
  HALF = 'half',
  FULL = 'full',
}

export enum Direction {
  DEFAULT = 'default',
  REVERSE = 'reverse',
}

export interface ITileSizeable {
  tileSize: TileSize;
}

export interface ILockedAspectRatio {
  lockedAspectRatio: boolean;
}

export interface ITileStyleable {
  hasBoxShadow: boolean;
  hasBorder: boolean;
  additionalPadding: string;
}

export interface IHorizontalPositionable {
  horizontalPosition: HorizontalPosition;
}

export interface IButtonContainer {
  isShowingTwoButtons: boolean;
  buttonPosition: HorizontalPosition;
  $direction: Direction;
}
