import * as React from 'react';
import { FC } from 'react';

import { Box } from '@rbilabs/universal-components';
import { Animated } from 'react-native';

import { useIsTabletBp } from 'hooks/breakpoints';

import { METER_STROKE_DESKTOP_WIDTH, METER_STROKE_WIDTH } from './constants';
import { PointsIcon } from './points-icon';
import { IMeterIcon } from './types';

const START_ANGLE = 90;
const FULL_ANGLE = 270;
const ICON_OFFSET = 5;

const computeIconRotation = (progress: number) => {
  const wrapperRotation =
    progress === 0
      ? START_ANGLE + ICON_OFFSET
      : Math.min(
          FULL_ANGLE * (progress / 100) + START_ANGLE + ICON_OFFSET,
          FULL_ANGLE + START_ANGLE
        );
  const iconRotation = 360 - 45 - wrapperRotation;
  return `${iconRotation}deg`;
};

export const MeterIcon: FC<React.PropsWithChildren<IMeterIcon>> = ({
  width,
  height,
  progressAnimation,
}) => {
  const isTablet = useIsTabletBp();
  const strokeWidth = isTablet ? METER_STROKE_DESKTOP_WIDTH : METER_STROKE_WIDTH;
  const diameter = width - strokeWidth - 20;
  const radius = diameter * 0.5;

  const halfWidth = width / 2;

  const iconRotationAnimation = progressAnimation.interpolate({
    inputRange: [0, 100],
    outputRange: [computeIconRotation(0), computeIconRotation(100)],
  });

  const rotateAnimation = progressAnimation.interpolate({
    inputRange: [0, 100],
    outputRange: [`${START_ANGLE + ICON_OFFSET}deg`, `${FULL_ANGLE + START_ANGLE}deg`],
  });

  return (
    <Box
      width={width}
      height={height}
      position="relative"
      left={halfWidth}
      style={[
        {
          transform: [
            {
              translateX: halfWidth * -1,
            },
            {
              rotate: '45deg',
            },
          ],
        },
      ]}
    >
      <Animated.View
        style={{
          width: 50,
          height: 50,
          position: 'absolute',
          left: halfWidth,
          top: halfWidth,
          marginTop: -25,
          marginLeft: -25,
          alignItems: 'center',
          justifyContent: 'center',
          transform: [
            {
              rotate: rotateAnimation,
            },
            {
              translateX: radius,
            },
          ],
        }}
      >
        <Animated.View
          style={[
            {
              transform: [
                {
                  rotate: iconRotationAnimation,
                },
                {
                  scale: 1.4,
                },
              ],
            },
          ]}
        >
          <PointsIcon />
        </Animated.View>
      </Animated.View>
    </Box>
  );
};
