/** Points meter progress animation delay in ms */
export const POINTS_METER_ANIMATION_DELAY = 500;

export const METER_STROKE_DESKTOP_WIDTH = 15;
export const METER_OUTER_STROKE_DESKTOP_WIDTH = 24;

export const METER_WIDTH = 200;
export const METER_DESKTOP_WIDTH = 300;

export const METER_HEIGHT = 200;
export const METER_DESKTOP_HEIGHT = 500;

export const METER_DIAMETER_WIDTH_ADJUSTMENT = 20;

export const METER_BG_COLOR = 'rgba(255, 255, 255, 0.20)';
export const METER_STROKE_COLOR = 'white';

export const METER_STROKE_WIDTH = 12;
export const METER_OUTER_STROKE_WIDTH = 20;

/**
 * Minimum amount of progress (out of 100) to show when the user has
 * points but no progress towards next goal.
 */
export const MIN_PROGRESS_WHEN_REDEEMABLE = 1;

export const ENABLE_NEW_TIER_CELEBRATION_ANIMATION = false;
