import { ComponentType, ReactNode } from 'react';

import { PressableProps, TextProps } from '@rbilabs/universal-components';

export interface IAccordionItemProps {
  contentWrapper?: ComponentType<React.PropsWithChildren<unknown>>;
  /** Gets rendered at the title content. */
  title: ReactNode;
  /** callback that triggers when the accordion is expanded or collapsed */
  onInteract?: (isExpanded: boolean, isFirstInteraction: boolean) => void;
  /** Allows for a colored background when the item is expanded */
  activeHighlight?: boolean;
  /** Select the icon type that you want to display */
  iconType?: IconType; // replace it with an enum
  /** Override AccordionActivatorButton styles*/
  _accordionActivatorButton?: PressableProps;
  /** Override ListItemTitle styles*/
  _listItemTitle?: TextProps;
  iconFromDeg?: number;
  autoExpand?: boolean;
}

export interface IUseAccordionItemProps {
  onInteract?: (isExpanded: boolean, isFirstInteraction: boolean) => void;
  defaultValue?: boolean;
}

export enum IconType {
  ARROW = 'arrow',
}
