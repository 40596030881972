import React from 'react';

import { useIntl } from 'react-intl';
import { Pressable } from 'react-native';

import Logo from 'components/logo';
import { routes } from 'utils/routing';

import { headerStyles } from './constants';

interface IHeaderLogoProps {
  navigation: any;
}

export const HeaderLogo: React.VFC<IHeaderLogoProps> = ({ navigation }) => {
  const { formatMessage } = useIntl();
  return (
    <Pressable
      onPress={() => navigation.navigate(routes.base)}
      accessibilityLabel={`${formatMessage({ id: headerStyles.logoLabel })} ${formatMessage({
        id: 'home',
      })}`}
      accessibilityRole="link"
      style={{
        // Don't set `width` - popeyes logo is wider than 48px
        minWidth: 48,
        height: 48,
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Logo width={headerStyles.logoWidth} height={headerStyles.logoHeight} />
    </Pressable>
  );
};
