import React, { useEffect } from 'react';

import LottieView from 'components/lottie-animations/lottie-view';
import { HapticsNotificationType, hapticNotification } from 'utils/haptic';

import animation from './confetti-burst.json';

const SuccessAnimation = ({ onAnimationFinish }: { onAnimationFinish?: () => void }) => {
  useEffect(() => {
    hapticNotification({ type: HapticsNotificationType.SUCCESS });
  }, []);

  return (
    <LottieView source={animation} autoPlay loop={false} onAnimationFinish={onAnimationFinish} />
  );
};

export default SuccessAnimation;
