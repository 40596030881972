export enum ServiceMode {
  CATERING_PICKUP = 'CATERING_PICKUP',
  CATERING_DELIVERY = 'CATERING_DELIVERY',
  CURBSIDE = 'CURBSIDE',
  DELIVERY = 'DELIVERY',
  DRIVE_THRU = 'DRIVE_THRU',
  EAT_IN = 'EAT_IN',
  TAKEOUT = 'TAKEOUT',
}

export type ServiceModeStatus = {
  [key in ServiceMode]: {
    /**
     * store is available in MDM
     */
    capable: boolean;
    /**
     * store is available if store hours are within service mode availability
     * and the store supports this service mode type
     */
    available: boolean;
    /**
     * state is disabled based on flag from LD, separate from store's availability
     */
    disabled: boolean;
    /**
     * store hours are open within service mode availability
     */
    open?: boolean;
  };
};
