import { useCallback, useEffect, useRef, useState } from 'react';

import { QueryHookOptions, useApolloClient } from '@apollo/client';

import { ICartEntry } from '@rbi-ctg/menu';
import {
  ILoyaltyBenefitSwap,
  ILoyaltyOffersQuery,
  ILoyaltyUserOffersQuery,
  IOffersFragment,
  LoyaltyOfferRedemptionType,
  LoyaltyOfferType,
  LoyaltyServiceMode,
  LoyaltyUserOffersDocument,
  OfferRedemptionType,
  PaymentMethod,
  useLoyaltyOffersLazyQuery,
  useLoyaltyUserOffersQuery,
} from 'generated/graphql-gateway';
import {
  useFeatureSortedLoyaltyOffersQuery,
  useLoyaltySystemwideOffersByIdsQuery,
} from 'generated/sanity-graphql';
import { useLocaleSmartBlockContent } from 'hooks/use-locale-smart-block-content/use-locale-smart-block-content';
import { actions, selectors, useAppDispatch, useAppSelector } from 'state/global-state';
import { IEntriesIdsMap } from 'state/global-state/models/loyalty/offers/offers.types';
import {
  flattenEntriesToMap,
  parseEntry,
  parseOffers,
} from 'state/global-state/models/loyalty/offers/offers.utils';
import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';
import { useServiceModeContext } from 'state/service-mode';
import { useStoreContext } from 'state/store';
import logger from 'utils/logger';

import { LoyaltyAppliedOffer, LoyaltyOffer } from '../types';

import { ENABLE_LOYALTY_STANDARD_OFFERS } from './constants';
import { IAppliedRewards, IQueryLoyaltyUserOffersOptions, isSwap } from './types';
import { useLoyaltyOffersEvaluation } from './use-loyalty-offers-evaluation';
import { usePersonalizedOffers } from './use-personalized-offers';
import { IPersonalizedData, mergePersonalizedData } from './utils/personalized-offers';

export const useLoyaltyOffers = () => {
  // This flag is parent to the next ones
  const loyaltyOffersEnabled = Boolean(useFlag(LaunchDarklyFlag.ENABLE_LOYALTY_OFFERS));
  // This flag replaces Offers 3.0
  const loyaltyStandardOffersEnabled = ENABLE_LOYALTY_STANDARD_OFFERS;
  // This flag enables surprise offers including swaps
  const loyaltySurpriseOffersEnabled = Boolean(
    useFlag(LaunchDarklyFlag.ENABLE_LOYALTY_SURPRISE_OFFERS)
  );

  const client = useApolloClient();
  const [evaluating, setEvaluating] = useState(false);
  const dispatch = useAppDispatch();
  const loyaltyUserData = useAppSelector(selectors.loyalty.selectUser);
  const appliedOffers = useAppSelector(selectors.loyalty.selectAppliedOffers);
  const userOffers = useAppSelector(selectors.loyalty.selectUserOffers);
  const { serviceMode } = useServiceModeContext();
  const { store } = useStoreContext();
  const sortedOffersRef = useRef<LoyaltyOffer[]>([]);
  const cartEntriesIdsMap = useRef<IEntriesIdsMap>({});

  const [
    fetchPersonalizedOfferData,
    { loading: personalizedDataLoading },
  ] = usePersonalizedOffers();
  const storeId = store?.number;

  const { transformSmartBlockContent } = useLocaleSmartBlockContent();
  const {
    loading: cmsOffersLoading,
    refetch: refetchCmsOffers,
  } = useFeatureSortedLoyaltyOffersQuery({
    skip: !loyaltyStandardOffersEnabled,
    variables: {
      id: 'feature-loyalty-offers-ui-singleton',
    },
    onCompleted(data) {
      if (data?.LoyaltyOffersUI?.sortedSystemwideOffers) {
        sortedOffersRef.current = sortedOffersRef.current.concat(
          data.LoyaltyOffersUI.sortedSystemwideOffers as LoyaltyOffer[]
        );
      }
    },
  });
  const getValidOfferWithSwap = useCallback(
    (offer: IOffersFragment) => {
      const isValidSwapOffer = (singleBenefit: ILoyaltyBenefitSwap) => {
        // Checking swap from value is in cartEntries
        const isValidSwap = cartEntriesIdsMap.current[singleBenefit.value.from];

        if (isValidSwap) {
          dispatch(actions.loyalty.setUpsizeAvailable(true));
        }
        return isValidSwap;
      };
      const isSwapApplied = appliedOffers.some(entry => !!entry.swap && entry.id === offer.id);

      // Filtering valid benefits from offer
      const benefits = offer?.benefits?.filter(benefit => {
        // filter out all the benefits if the swap was applied
        if (isSwap(benefit) && !isSwapApplied) {
          return isValidSwapOffer(benefit);
        }
        // Offer is not a swap so don't filter it
        return true;
      });

      return { ...offer, ...(benefits && { benefits }) };
    },
    [appliedOffers, dispatch]
  );

  const getPersonalizedOffer = useCallback(
    (
      offer: IOffersFragment,
      personalizedOfferData: IPersonalizedData
    ): LoyaltyOffer | undefined => {
      let personalizedOffer;

      try {
        personalizedOffer = mergePersonalizedData({
          engineOffer: offer,
          transformSmartBlockContent,
          ...personalizedOfferData,
        });
      } catch (error) {
        // @ts-expect-error TS(2571) FIXME: Object is of type 'unknown'.
        const message = error?.message ? `Error: ${error.message}` : '';
        logger.error(`Failed processing personalized offer: ${offer.id} - ${message}`);
      }

      return personalizedOffer;
    },
    [transformSmartBlockContent]
  );

  const onUserOffersQueryComplete = useCallback(
    async (data: ILoyaltyUserOffersQuery) => {
      const loyaltyUser = data?.loyaltyUserV2;
      const offerRedemptionAvailableAfter =
        loyaltyUser?.offerRedemptionAvailability?.availableAfter;

      if (offerRedemptionAvailableAfter) {
        dispatch(actions.loyalty.setOfferRedemptionAvailableAfter(offerRedemptionAvailableAfter));
      }

      const loyaltyUserOffers = loyaltyUser?.offers;
      if (!loyaltyUserOffers?.length) {
        return;
      }

      // Fetch data needed to build personalized offer
      const personalizedOfferData = await fetchPersonalizedOfferData(loyaltyUserOffers);

      const personalizedUserOffers: LoyaltyOffer[] = [];
      const systemWideOffers: IOffersFragment[] = [];

      loyaltyUserOffers.forEach(offer => {
        let validOffer = offer;

        // Do not validate surprise offers if feature is not enabled
        if (loyaltySurpriseOffersEnabled) {
          validOffer = getValidOfferWithSwap(offer);
          dispatch(actions.loyalty.setSurpriseOfferIfAvailable(offer));
        }

        if (validOffer.type === LoyaltyOfferType.PERSONALIZED && personalizedOfferData) {
          const personalizedOffer = getPersonalizedOffer(validOffer, personalizedOfferData);
          if (personalizedOffer) {
            personalizedUserOffers.push(personalizedOffer);
          }
        }

        if (validOffer.type === LoyaltyOfferType.GLOBAL) {
          systemWideOffers.push(validOffer);
        }
      });

      sortedOffersRef.current = sortedOffersRef.current.concat(personalizedUserOffers);

      dispatch(actions.loyalty.setCmsOffers(sortedOffersRef.current));
      dispatch(actions.loyalty.setUserOffers(systemWideOffers));
      dispatch(actions.loyalty.setPersonalizedOffers(personalizedUserOffers));
    },
    [
      dispatch,
      fetchPersonalizedOfferData,
      getPersonalizedOffer,
      getValidOfferWithSwap,
      loyaltySurpriseOffersEnabled,
    ]
  );

  const [queryEngineOffers, { loading: engineOffersLoading }] = useLoyaltyOffersLazyQuery({
    fetchPolicy: 'network-only',
    variables: {
      serviceMode: LoyaltyServiceMode[serviceMode || ''] || undefined,
      storeId,
      omitInvalids: false,
    },
    onCompleted: (data: ILoyaltyOffersQuery) => {
      if (data?.loyaltyOffersV2?.length) {
        dispatch(actions.loyalty.setCmsOffers(sortedOffersRef.current));
        dispatch(actions.loyalty.setOffers(data.loyaltyOffersV2 as IOffersFragment[]));
      }
    },
  });

  // Find all surprise offers ids for the current user
  const surpriseOffersEngineIds = userOffers.reduce((acc: string[], offer) => {
    if (offer.redemptionType === LoyaltyOfferRedemptionType.SURPRISE && offer.sanityId) {
      acc.push(offer.sanityId);
    }
    return acc;
  }, []);

  // Attach applied offers ids to get the full collection of offers from CMS
  const appliedLoyaltyOffersIds = surpriseOffersEngineIds.concat(
    appliedOffers.map(({ cmsId }) => cmsId || '')
  );

  const loyaltyUserId = loyaltyUserData?.id || '';
  const { loading: queryUserAppliedOffersLoading } = useLoyaltyUserOffersQuery({
    fetchPolicy: 'no-cache',
    skip: !loyaltyUserId || !appliedLoyaltyOffersIds.length || userOffers.length > 0,
    variables: {
      loyaltyId: loyaltyUserId,
      where: {
        ids: appliedOffers.map(({ id }) => id || ''),
      },
    },
    onCompleted: onUserOffersQueryComplete,
  });

  const { loading: swoffersLoading } = useLoyaltySystemwideOffersByIdsQuery({
    variables: {
      ids: appliedLoyaltyOffersIds,
    },
    skip: !appliedLoyaltyOffersIds.length,
    onCompleted(data) {
      if (data?.allSystemwideOffers) {
        sortedOffersRef.current = sortedOffersRef.current.concat(
          data.allSystemwideOffers as LoyaltyOffer[]
        );

        dispatch(actions.loyalty.setCmsOffers(sortedOffersRef.current));

        const upsizeAndDiscountIds = appliedOffers
          .filter(({ swap, cartId }) => !!swap || cartId === 'discount-offer')
          .map(({ id }) => id || '');

        if (upsizeAndDiscountIds.length) {
          queryUserOffers({
            variables: {
              loyaltyId: loyaltyUserId,
              where: {
                omitInvalids: false,
                ids: upsizeAndDiscountIds,
              },
            },
          });
        }
      }
    },
  });

  const queryUserOffers = useCallback(
    async (options: QueryHookOptions) => {
      dispatch(actions.loyalty.setOffersLoading(true));

      try {
        const { data } = await client.query<ILoyaltyUserOffersQuery>({
          ...options,
          fetchPolicy: 'no-cache',
          query: LoyaltyUserOffersDocument,
        });

        if (data) {
          onUserOffersQueryComplete(data);
        }
      } catch (e) {
        logger.error(`Error fetching user offers: ${e}`);
      } finally {
        dispatch(actions.loyalty.setOffersLoading(false));
      }
    },
    [client, dispatch, onUserOffersQueryComplete]
  );

  const queryLoyaltyUserOffers = useCallback(
    ({
      redemptionTypes,
      omitInvalids = false,
    }: {
      redemptionTypes: OfferRedemptionType[];
      omitInvalids?: boolean;
    }) => ({
      loyaltyId,
      cartEntries,
      appliedRewards,
      subtotalAmount,
      appliedLoyaltyOffers = [],
    }: IQueryLoyaltyUserOffersOptions) => {
      const parsedEntries = cartEntries?.reduce(parseEntry(appliedRewards), []);

      cartEntriesIdsMap.current = (parsedEntries || []).reduce(flattenEntriesToMap, {});

      queryUserOffers({
        variables: {
          loyaltyId,
          where: {
            appliedIncentives: parseOffers(appliedLoyaltyOffers),
            cartEntries: parsedEntries,
            serviceMode: serviceMode || undefined,
            redemptionTypes,
            storeId,
            subtotalAmount,
            omitInvalids,
          },
        },
      });
    },
    [queryUserOffers, serviceMode, storeId]
  );

  // Offers without loyaltyId should always be of redemption type STANDARD
  const queryLoyaltyOffers = useCallback(
    ({ subtotalAmount = 0 }: any) => {
      queryEngineOffers({
        variables: {
          serviceMode: LoyaltyServiceMode[serviceMode || ''],
          storeId,
          subtotalAmount,
          redemptionTypes: [LoyaltyOfferRedemptionType.STANDARD],
        },
      });
    },
    [queryEngineOffers, serviceMode, storeId]
  );

  const queryLoyaltyUserStandardOffers = useCallback(
    queryLoyaltyUserOffers({ redemptionTypes: [OfferRedemptionType.STANDARD] }),
    [queryLoyaltyUserOffers]
  );

  const queryLoyaltyUserSurpriseOffers = useCallback(
    queryLoyaltyUserOffers({
      redemptionTypes: [OfferRedemptionType.SURPRISE, OfferRedemptionType.SWAP],
      omitInvalids: true,
    }),
    [queryLoyaltyUserOffers]
  );

  const { evaluateLoyaltyOffers } = useLoyaltyOffersEvaluation();

  const evaluateLoyaltyUserIncentives = useCallback(
    async (
      loyaltyId: string,
      appliedLoyaltyOffers: LoyaltyAppliedOffer[],
      cartEntries?: ICartEntry[],
      appliedLoyaltyRewards?: IAppliedRewards | null,
      subtotalAmount?: number,
      paymentMethod?: PaymentMethod | null
    ) => {
      const parsedEntries = cartEntries?.reduce(parseEntry(appliedLoyaltyRewards), []);

      cartEntriesIdsMap.current = (parsedEntries || []).reduce(flattenEntriesToMap, {});

      setEvaluating(true);

      let evaluationResult = null;
      try {
        evaluationResult = await evaluateLoyaltyOffers({
          loyaltyId,
          appliedOffers: appliedLoyaltyOffers,
          cartEntries: parsedEntries,
          subtotalAmount,
          paymentMethod,
          serviceMode,
          storeId,
        });

        if (evaluationResult) {
          dispatch(actions.loyalty.setOffersFeedbackMap(evaluationResult));
        }
      } finally {
        setEvaluating(false);
      }

      return evaluationResult;
    },
    [dispatch, evaluateLoyaltyOffers, serviceMode, storeId]
  );

  useEffect(() => {
    dispatch(
      actions.loyalty.setOffersLoading(
        engineOffersLoading ||
          personalizedDataLoading ||
          cmsOffersLoading ||
          swoffersLoading ||
          evaluating ||
          queryUserAppliedOffersLoading
      )
    );
  }, [
    dispatch,
    engineOffersLoading,
    personalizedDataLoading,
    cmsOffersLoading,
    swoffersLoading,
    evaluating,
    queryUserAppliedOffersLoading,
  ]);

  return {
    evaluateLoyaltyUserIncentives,
    loyaltyOffersEnabled,
    loyaltyStandardOffersEnabled,
    loyaltySurpriseOffersEnabled,
    queryLoyaltyOffers,
    queryLoyaltyUserStandardOffers,
    queryLoyaltyUserSurpriseOffers,
    refetchCmsOffers,
    cmsOffersLoading,
  };
};
