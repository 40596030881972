import * as React from 'react';
import { FC } from 'react';

import { LoyaltyCtaBarViewBase } from './loyalty-cta-bar.view.base';
import { ILoyaltyCtaBarViewProps } from './types';

export const LoyaltyCtaBarView: FC<React.PropsWithChildren<ILoyaltyCtaBarViewProps>> = ({
  loading = true,
  ...rest
}) => <LoyaltyCtaBarViewBase {...rest} loading={loading} />;
