import React from 'react';

import { Box, Header, Pressable } from '@rbilabs/universal-components';

import { IBaseProps } from '@rbi-ctg/frontend';
import { ISanityImage } from '@rbi-ctg/menu';
import Picture from 'components/picture';
import { stylesForMaxLinesOfText } from 'utils/style/max-lines-text';

import theme from './theme';

const Container = Pressable.withConfig({
  backgroundColor: '__legacyToken.background-pattern',
  flex: 1,
  shadow: '1',
  borderTopLeftRadius: 8,
  borderTopRightRadius: 8,
  borderBottomRightRadius: 8,
  borderBottomLeftRadius: 8,
  padding: '$2',
  paddingBottom: '$3',
  _web: {
    cursor: 'pointer',
  },
  _hover: {
    shadow: '3',
  },
});

const ImageContainer = Box.withConfig<{ imageHeightWidthRatio: number }>(p => ({
  // react-native-web was stripping aspectRatio even though it's a real css property
  // this is why we are putting it in a style object directly.
  style: {
    aspectRatio: `1 / ${p.imageHeightWidthRatio}`,
  },

  borderTopLeftRadius: 4,
  borderTopRightRadius: 4,
  borderBottomRightRadius: 4,
  borderBottomLeftRadius: 4,
}));

const Content = Box.withConfig({
  flex: 1,
  flexBasis: 'auto',
  width: '100%',
});

interface ICard extends IBaseProps {
  backgroundImage?: ISanityImage;
  image: ISanityImage;
  imageAlt?: string;
  imageHeightWidthRatio?: number;
  onPress?: () => void;
}

export const Card: React.FC<React.PropsWithChildren<ICard>> = ({
  image,
  backgroundImage,
  imageAlt,
  children,
  imageHeightWidthRatio = 0.75,
  onPress,
}) => {
  return (
    <Container onPress={onPress} flex={1}>
      <ImageContainer imageHeightWidthRatio={imageHeightWidthRatio}>
        {backgroundImage && <Picture alt="" image={backgroundImage} w={'100%'} />}
        <Picture height="full" alt={imageAlt || ''} objectFitContain image={image} w={'100%'} />
      </ImageContainer>
      <Content>{children}</Content>
    </Container>
  );
};

interface ITitle {
  useFullHeight?: boolean;
}

export const Title = Header.withConfig<ITitle>(p => ({
  margin: 0,
  textTransform: Styles.textTransform.headlines,
  variant: 'headerTwo',
  letterSpacing: '1px',
  accessibilityLevel: 4,
  ...stylesForMaxLinesOfText({
    maxLines: 3,
    lineHeight: theme.titleLineHeight,
    fontSizeInRem: 1.1,
    useFullHeight: p.useFullHeight,
  }),
}));
