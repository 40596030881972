import * as React from 'react';
import { FC } from 'react';

import { ItemWidth, ResponsiveCarousel } from 'components/responsive-carousel';
import { useIsMobileBp } from 'hooks/breakpoints';

import { RewardsCarouselCard } from './rewards-carousel-card';
import { RewardsCarouselContainer } from './rewards-carousel.styled';
import { CarouselReward, IRewardsCarouselProps } from './types';

const rewardsBenefitIdKeyExtractor = (item: CarouselReward) => item.rewardBenefitId;

const rewardsItemRenderer = ({ item }: { item: CarouselReward }) => (
  <RewardsCarouselCard reward={item} />
);

/**
 * A carousel for loyalty rewards
 */

const RewardsCarousel: FC<React.PropsWithChildren<IRewardsCarouselProps>> = ({ rewards }) => {
  const isMobile = useIsMobileBp();
  const itemWidth = isMobile
    ? ItemWidth.VERTICAL_CAROUSEL_ITEM_MOBILE
    : ItemWidth.VERTICAL_CAROUSEL_ITEM_WEB;
  return (
    <RewardsCarouselContainer>
      <ResponsiveCarousel
        centerCards={false}
        bgColor="transparent"
        data={rewards.filter(reward => reward.isAvailable)}
        itemWidth={itemWidth}
        keyExtractor={rewardsBenefitIdKeyExtractor}
        renderItem={rewardsItemRenderer}
        loop={false}
        inactiveSlideOpacity={1}
        inactiveSlideScale={0.9}
      />
    </RewardsCarouselContainer>
  );
};

export default RewardsCarousel;
