import { Box, Header, Text } from '@rbilabs/universal-components';

// TODO: RN - There is no accessibility role for section
export const Section = Box.withConfig({
  background: Styles.color.white,

  width: {
    base: 'full',
    md: 'auto',
  },
  marginX: {
    base: '0',
    md: 'auto',
  },
  paddingX: {
    base: '0',
    md: '3rem',
  },
  maxWidth: {
    base: 'full',
    md: Styles.layout.sectionMaxWidth,
  },
});

export const HomepageAlertContainer = Box.withConfig({
  flexDirection: 'column',
  background: Styles.color.black,
  padding: '8',
  marginBottom: '8',

  borderRadius: {
    base: '0px',
    md: '10px',
  },
  marginTop: {
    base: '0',
    md: '8',
  },
});

export const AlertHeader = Header.withConfig({
  variant: 'headerTwo',
  marginTop: '0',
  fontFamily: 'heading',
  fontWeight: 'normal',
  color: Styles.color.white,
  textTransform: Styles.textTransform.headlines,
  marginBottom: '4',
});

export const AlertBody = Text.withConfig({
  variant: 'copyOne',
  margin: '0',
  color: Styles.color.white,
});
