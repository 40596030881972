import React, { FC, ReactNode, useCallback, useRef, useState } from 'react';

import delve from 'dlv';
import { useIntl } from 'react-intl';

import { Nullable } from '@rbi-ctg/frontend';
import ActionButton, { ActionButtonVariants } from 'components/action-button';
import ConfirmDialog, { IConfirmProps } from 'components/confirm-dialog';
import Dialog from 'components/dialog';
import { IDialogProps } from 'components/dialog/types';
import noop from 'utils/noop';

export type DialogCb<T> = (data?: Nullable<T>) => void;

interface IUseDialogBaseArgs<T> {
  onConfirm?: DialogCb<T>;
  onCancel?: DialogCb<T>;
  onDismiss?: DialogCb<T>;
  onOpen?: DialogCb<T>;
  type?: string;
  showCancel?: boolean;
  init?: boolean;
  allowDismiss?: boolean;
  allowClose?: boolean;
  showCloseButton?: boolean;
}

type UseDialogComponentRequired<P> = {
  Component: React.ComponentType<React.PropsWithChildren<Props<P>>>;
  modalAppearanceEventMessage?: string;
};
type UseDialogMessageRequired<P> = {
  Component?: React.ComponentType<React.PropsWithChildren<Props<P>>>;
  modalAppearanceEventMessage: string;
};

type UseDialogArgs<T, P = {}> = IUseDialogBaseArgs<T> &
  (UseDialogComponentRequired<P> | UseDialogMessageRequired<P>);

export interface IUseDialogProps {
  heading?: string;
  body?: ReactNode;
  bodyComponent?: ReactNode;
  buttonLabel?: string;
  image?: ReactNode;
}

export interface IUseDialogComponentProps {
  onDismiss: () => void;
  onConfirm: () => void;
}

export type Props<P> = IUseDialogProps &
  Partial<IDialogProps> &
  Partial<IConfirmProps> &
  Omit<P, keyof IUseDialogComponentProps>;

export type UseDialogHook<T, P = {}> = [
  FC<React.PropsWithChildren<Props<P>>>,
  DialogCb<T>,
  Nullable<T>,
  () => void,
  () => Promise<boolean>
];

export default function useDialogModal<T extends object, P = {}>({
  Component,
  onConfirm = noop,
  onCancel = noop,
  onDismiss = onCancel,
  onOpen = noop,
  showCancel = false,
  init = false,
  allowDismiss = true,
  allowClose = true,
  /**
   * A small string sent to mParticle describing the purpose of the modal.
   */
  modalAppearanceEventMessage,
}: UseDialogArgs<T, P>): UseDialogHook<T, P> {
  const { formatMessage } = useIntl();
  const [open, setOpen] = useState(init);
  const [pendingData, setPending] = useState<Nullable<T>>(null);
  const openDialog = useCallback(
    (data?: Nullable<T>) => {
      onOpen(data);
      setPending(data ?? null);
      setOpen(true);
    },
    [onOpen]
  );
  const cancelDialog = useCallback(() => {
    onCancel(pendingData);
    setPending(null);
    if (allowClose) {
      setOpen(false);
    }
    resolveConfirmRef.current?.(false);
  }, [allowClose, onCancel, pendingData]);
  const dismissDialog = useCallback(() => {
    onDismiss(pendingData);
    setPending(null);
    if (allowDismiss && allowClose) {
      setOpen(false);
    }
    resolveConfirmRef.current?.(false);
  }, [allowClose, allowDismiss, onDismiss, pendingData]);
  const confirmDialog = useCallback(() => {
    onConfirm(pendingData);
    setPending(null);
    if (allowClose) {
      setOpen(false);
    }
    resolveConfirmRef.current?.(true);
  }, [allowClose, onConfirm, pendingData]);

  const resolveConfirmRef = useRef<(confirm: boolean) => void>();
  const requestConfirm = useCallback(() => {
    openDialog();
    return new Promise<boolean>(resolve => (resolveConfirmRef.current = resolve));
  }, [openDialog]);

  const DialogComponent: FC<React.PropsWithChildren<Props<P>>> = useCallback(
    ({
      body = formatMessage({ id: 'errorProcessingRequest' }),
      buttonLabel = formatMessage({ id: 'okay' }),
      heading = formatMessage({ id: 'somethingWrong' }),
      image,
      showCloseButton,
      /**
       * The DialogComponent can be rendered using different props
       * than the `useDialogComponent` hook is called with.
       */
      modalAppearanceEventMessage: modalAppearanceEventMessageOverride,
      ...rest
    }) => {
      if (!open) {
        return null;
      }
      // dlv returns the provided default if first arg is null,
      // so cast to T to shut the compiler up about the TypeError
      const message = delve(pendingData as T, 'message', null);
      const title = delve(pendingData as T, 'title', null);

      if (Component) {
        return (
          <Component
            onDismiss={dismissDialog}
            onConfirm={confirmDialog}
            modalAppearanceEventMessage={modalAppearanceEventMessageOverride}
            heading={title! || heading}
            {...(rest as P)}
          />
        );
      }

      return showCancel ? (
        // @ts-ignore TS(2590) FIXME: Expression produces a union type that is too complex to represent.
        <ConfirmDialog
          showDialog={open}
          heading={title! || heading}
          body={message || body}
          image={image}
          confirmLabel={buttonLabel}
          onConfirm={confirmDialog}
          onCancel={cancelDialog}
          onDismiss={dismissDialog}
          modalAppearanceEventMessage={
            modalAppearanceEventMessageOverride || modalAppearanceEventMessage!
          }
          {...rest}
        />
      ) : (
        <Dialog
          showDialog={open}
          heading={title! || heading}
          body={message || body}
          image={image}
          onDismiss={dismissDialog}
          showCloseButton={showCloseButton}
          actions={
            <ActionButton
              fullWidth={showCloseButton}
              onPress={confirmDialog}
              testID="dialog-confirm-btn"
              variant={
                showCloseButton ? ActionButtonVariants.PRIMARY : ActionButtonVariants.TEXT_ONLY
              }
            >
              {buttonLabel}
            </ActionButton>
          }
          modalAppearanceEventMessage={
            modalAppearanceEventMessageOverride || modalAppearanceEventMessage!
          }
          {...rest}
        />
      );
    },
    // @ts-ignore TODO: represents a union that is too complex to express
    [
      formatMessage,
      open,
      pendingData,
      Component,
      showCancel,
      confirmDialog,
      cancelDialog,
      dismissDialog,
      modalAppearanceEventMessage,
    ]
  );

  return [DialogComponent, openDialog, pendingData, dismissDialog, requestConfirm];
}
