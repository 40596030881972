import React, { VFC } from 'react';

import { addWithConfig } from '@rbilabs/universal-components';
import { useIntl } from 'react-intl';

import ActionButton, { ActionButtonSizes, ActionButtonVariants } from 'components/action-button';
import { useResetConfirmDialog } from 'hooks/menu/use-reset-confirm-dialog';

import { IProductCustomizationResetProps } from './types';

const StyledButton = addWithConfig(ActionButton).withConfig({
  variant: ActionButtonVariants.OUTLINE,
  size: ActionButtonSizes.SMALL,
});

export const ProductCustomizationReset: VFC<IProductCustomizationResetProps> = ({
  onReset,
  showButton,
}) => {
  const { formatMessage } = useIntl();

  const {
    openConfirmationDialog,
    ResetConfirmDialog,
  } = useResetConfirmDialog('Confirmation: reset customizations to default', () => onReset());

  return (
    <>
      {showButton && (
        <StyledButton onPress={openConfirmationDialog}>
          {formatMessage({ id: 'reset' })}
        </StyledButton>
      )}
      {ResetConfirmDialog}
    </>
  );
};

export default ProductCustomizationReset;
