import React, { useMemo } from 'react';

import { Box, Text } from '@rbilabs/universal-components';
import { useIntl } from 'react-intl';

import { ISanityImage } from '@rbi-ctg/menu';
import NutritionModal from 'components/nutrition';
import { Allergens } from 'components/nutrition/allergens';
import { AllergenGroup, NutritionFacts } from 'components/nutrition/types';
import { getAllergenGroups } from 'components/nutrition/utils';
import { useFormatCalories } from 'hooks/use-format-calories';
import { useFormatJoules } from 'hooks/use-format-joules';
import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';

import { Card, Title } from './card';
import { IProduct } from './types';

const Body = Box.withConfig({
  marginTop: {
    base: '$2',
    md: '$1',
  },
});

const CaloriesContainer = Text.withConfig({
  margin: 0,
  variant: 'copyTwo',
});

const Content = Box.withConfig({
  flex: 1,
  paddingTop: {
    base: '$2',
    md: '$3',
  },
  paddingRight: {
    base: '$3',
    md: '$4',
  },
  paddingBottom: {
    base: 0,
    md: 0,
  },
  paddingLeft: {
    base: '$3',
    md: '$4',
  },
});

interface IProductCard {
  product: IProduct;
  backgroundImage?: ISanityImage;
}

export const ProductCard: React.FC<React.PropsWithChildren<IProductCard>> = ({
  product,
  backgroundImage,
}) => {
  const { formatMessage } = useIntl();
  const enableAllergens = useFlag(LaunchDarklyFlag.ENABLE_NUTRITION_EXPLORER_ALLERGENS);
  const enableEUNutritionDisplay = useFlag(LaunchDarklyFlag.ENABLE_EU_NUTRITION_DISPLAY);
  const formatCalories = useFormatCalories();
  const formatJoules = useFormatJoules();

  const nutritionText = useMemo(() => {
    const formattedCalories = formatCalories(product.nutrition.calories);
    const formattedJoules = formatJoules(product.nutrition.calories);

    if (!enableEUNutritionDisplay) {
      return formattedCalories;
    }

    return formatMessage(
      { id: 'euNutritionValues' },
      { calories: formattedCalories, joules: formattedJoules }
    );
  }, [
    formatMessage,
    formatCalories,
    formatJoules,
    product.nutrition.calories,
    enableEUNutritionDisplay,
  ]);

  let allergenGroups: AllergenGroup[] | undefined;
  if (enableAllergens && product.allergens) {
    allergenGroups = getAllergenGroups(product.allergens, formatMessage);
  }

  const nutritionFacts: NutritionFacts[] = [
    {
      itemName: product.name,
      itemImage: product.image,
      facts: product.nutrition || {},
      allergenGroups,
      additionalItemInformation: product.additionalItemInformation || null,
    },
  ];

  const nutritionInfoCard = (
    <Card key={product._id} image={product.image} imageAlt="" backgroundImage={backgroundImage}>
      <Content>
        <Title useFullHeight>{product.name}</Title>
        <Body>
          {nutritionText && <CaloriesContainer>{nutritionText}</CaloriesContainer>}
          {allergenGroups && <Allergens allergenGroups={allergenGroups} />}
        </Body>
      </Content>
    </Card>
  );

  return <NutritionModal nutritionFacts={nutritionFacts} activatingElement={nutritionInfoCard} />;
};
