import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { View } from '@rbilabs/universal-components';
import { isEmpty } from 'lodash-es';
import { useIntl } from 'react-intl';
import { RefreshControl } from 'react-native';

import { AnimatedFlatList } from 'components/animated-flat-list';
import { MobileBackButton } from 'components/offers/ui-refresh/mobile-back-button';
import { OffersEmptyState } from 'components/offers/ui-refresh/offers-empty-state';
import { useIsMobileBp } from 'hooks/breakpoints';
import { useFocusEffect } from 'hooks/navigation/use-focus-effect';
import { useNavigation } from 'hooks/navigation/use-navigation';
import { useRumPageView } from 'hooks/rum/use-rum-page-view';
import useEffectOnce from 'hooks/use-effect-once';
import { useToast } from 'hooks/use-toast';
import { CustomEventNames, EventTypes, useCRMEventsContext } from 'state/crm-events';
import { actions, selectors, useAppDispatch, useAppSelector } from 'state/global-state';
import { useFlag } from 'state/launchdarkly';
import { useLoyaltyContext } from 'state/loyalty';
import { IncentiveEvaluationMap } from 'state/loyalty/hooks/types';
import { useLoyaltyUser } from 'state/loyalty/hooks/use-loyalty-user';
import {
  ICartEntryType,
  useInRestaurantRedemptionContext,
} from 'state/loyalty/in-restaurant-redemption';
import { IncentiveSource, LoyaltyOffer } from 'state/loyalty/types';
import { isDiscountLoyaltyOffer } from 'state/loyalty/utils';
import { useOrderContext } from 'state/order';
import { isNative } from 'utils/environment';
import { EventName, emitEvent } from 'utils/event-hub';
import { LaunchDarklyFlag } from 'utils/launchdarkly';
import { routes } from 'utils/routing';

import { AnimatedOfferItem } from '../loyalty-incentives-components/animated-offer-item';
import { parseIncentiveData } from '../loyalty-incentives-components/incentive-card/parse-incentive-data';
import { IncentiveDetails } from '../loyalty-incentives-components/incentive-details';
import { LoyaltyOffersItem } from '../loyalty-incentives-components/loyalty-offers-item';
import { useLoyaltyIncentivesAvailability } from '../loyalty-incentives-components/use-loyalty-incentives-availability';

import EndOfOffers from './end-of-offers/loyalty-end-of-offers';
import { LoyaltyOffersCooldown } from './loyalty-offers-cooldown';
import { LoyaltyOffersFilterButtons } from './loyalty-offers-filters';
import {
  LoyaltyIncentivesContainer,
  StyledLoyaltyLoader,
  StyledUIRefreshOffers,
} from './loyalty-offers.styled';
import { LoyaltyPromoCode } from './loyalty-promo-code';
import { OffersInCart } from './offers-in-cart/offers-in-cart';
import { useLoyaltyOffersFilters } from './use-loyalty-offers-filters';
import useLoyaltyOffersFlow from './use-loyalty-offers-flow';
import { getSelectedIncentive, reduceOffers } from './utils';

export const LoyaltyOffersBase = () => {
  const { formatMessage } = useIntl();
  const { loyaltyUser } = useLoyaltyUser();
  const {
    queryLoyaltyOffers,
    queryLoyaltyUserStandardOffers,
    loyaltyStandardOffersEnabled,
    refetchCmsOffers,
  } = useLoyaltyContext();
  const loyaltyCmsOffers = useAppSelector(selectors.loyalty.selectCmsOffers);
  const appliedOffers = useAppSelector(selectors.loyalty.selectAppliedOffers);
  const offersLoading = useAppSelector(selectors.loyalty.selectOffersLoading);
  const userOffers = useAppSelector(selectors.loyalty.selectUserOffers);
  const unauthenticatedOffers = useAppSelector(selectors.loyalty.selectOffers);
  const personalizedOffers = useAppSelector(selectors.loyalty.selectPersonalizedOffers);
  const shouldRefetchOffers = useAppSelector(selectors.loyalty.selectShouldRefetchOffers);
  const dispatch = useAppDispatch();
  const {
    inRestaurantRedemptionCart,
    removeInRestaurantRedemptionEntry,
  } = useInRestaurantRedemptionContext();
  const enableSortOffersForServiceMode = useFlag(
    LaunchDarklyFlag.ENABLE_SORT_OFFERS_FOR_SERVICE_MODE
  );
  const enableLoyaltyPromoCodes = useFlag(LaunchDarklyFlag.ENABLE_LOYALTY_PROMO_CODES);
  const enableLoyaltyOffersFilters = useFlag(LaunchDarklyFlag.ENABLE_LOYALTY_OFFERS_FILTERS);
  const { removeFromCart, ...order } = useOrderContext();

  const { checkLoyaltyOfferAvailability } = useLoyaltyIncentivesAvailability();
  const toast = useToast();
  const isMobile = useIsMobileBp();

  const { navigate, linkTo } = useNavigation();
  const { offerEngineId: loyaltyEngineId, isConfigOffer } = useLoyaltyOffersFlow();
  const { appliedFilters, toggleFilter } = useLoyaltyOffersFilters();
  const skipAnimation = useRef(true);
  const isBaseOffersRoute = !loyaltyEngineId;

  const { logRBIEvent } = useCRMEventsContext();
  // TODO: extract this method to a reusable place
  const removeLoyaltyOffer = useCallback(
    (offerToRemove: LoyaltyOffer) => {
      const appliedOfferToRemove = appliedOffers.find(
        offer => offer.id === offerToRemove.loyaltyEngineId
      );
      if (appliedOfferToRemove?.cartId) {
        removeFromCart(appliedOfferToRemove);
        return true;
      }

      return false;
    },
    [appliedOffers, removeFromCart]
  );

  const handleRemove = useCallback(
    (offerToRemove: LoyaltyOffer) => {
      const redemptionOfferToRemove = inRestaurantRedemptionCart.find(
        cartEntry =>
          cartEntry.type === ICartEntryType.OFFER &&
          cartEntry.details.offer._id === offerToRemove._id
      );

      let showNotification = false;

      if (redemptionOfferToRemove) {
        removeInRestaurantRedemptionEntry(redemptionOfferToRemove);
        showNotification = true;
      } else if (isDiscountLoyaltyOffer(offerToRemove)) {
        dispatch(actions.loyalty.setSelectedOffer(null));
        dispatch(actions.loyalty.resetAppliedOffers());
        showNotification = true;
      } else {
        showNotification = removeLoyaltyOffer(offerToRemove);
      }

      if (showNotification) {
        toast.show({
          text: formatMessage({ id: 'offerAppliedSuccess' }),
          variant: 'positive',
        });
      }
    },
    [
      inRestaurantRedemptionCart,
      removeInRestaurantRedemptionEntry,
      dispatch,
      removeLoyaltyOffer,
      toast,
      formatMessage,
    ]
  );

  const cartTotal: number = useMemo(() => order?.calculateCartTotal && order.calculateCartTotal(), [
    order,
  ]);

  const loyaltyId = loyaltyUser?.id;

  const fetchOffers = useCallback(() => {
    // Reset shouldRefetchOffers flag
    dispatch(actions.loyalty.setShouldRefetchOffers(false));

    if (loyaltyStandardOffersEnabled) {
      if (loyaltyId) {
        queryLoyaltyUserStandardOffers({ loyaltyId, subtotalAmount: cartTotal });
      } else {
        queryLoyaltyOffers({ subtotalAmount: cartTotal });
      }
    }
  }, [
    cartTotal,
    loyaltyId,
    loyaltyStandardOffersEnabled,
    queryLoyaltyOffers,
    queryLoyaltyUserStandardOffers,
    dispatch,
  ]);

  useFocusEffect(
    React.useCallback(() => {
      emitEvent(EventName.SCREEN_RENDER, {
        screenId: 'loyaltyOffers',
      });
    }, [])
  );

  const [offers, setOffers] = useState<LoyaltyOffer[]>([]);
  const [offersInCart, setOffersInCart] = useState<LoyaltyOffer[]>([]);

  useEffect(() => {
    const [_offers, _offersInCart] = !loyaltyCmsOffers
      ? []
      : reduceOffers({
          cmsOffers: loyaltyCmsOffers,
          personalizedOffers,
          baseOffers: loyaltyUser?.id ? userOffers : unauthenticatedOffers,
          appliedOffers,
          inRestaurantRedemptionCart,
          checkLoyaltyOfferAvailability,
          enableSortOffersForServiceMode,
          // @ts-expect-error TS(2322) FIXME: Type '(offerFeedbackMap: IncentiveEvaluationMap) =... Remove this comment to see the full error message
          setOffersFeedbackMap: (offerFeedbackMap: IncentiveEvaluationMap) =>
            dispatch(actions.loyalty.setOffersFeedbackMap(offerFeedbackMap)),
          appliedFilters,
        });

    setOffers(_offers);
    setOffersInCart(_offersInCart);
  }, [
    loyaltyCmsOffers,
    loyaltyUser,
    userOffers,
    unauthenticatedOffers,
    appliedOffers,
    inRestaurantRedemptionCart,
    checkLoyaltyOfferAvailability,
    enableSortOffersForServiceMode,
    dispatch,
    personalizedOffers,
    appliedFilters,
  ]);

  // If we're showing the screen and we don't have any offers, try to refetch
  useEffectOnce(() => {
    if (!offersLoading && isBaseOffersRoute && !offers.length) {
      dispatch(actions.loyalty.setShouldRefetchOffers(true));
    }
  });

  // Refetch offers if shouldRefetchOffers is true or an offer is added to cart
  useEffect(() => {
    if (shouldRefetchOffers || (offersInCart?.length && !offers.length)) {
      fetchOffers();
    }
  }, [fetchOffers, shouldRefetchOffers, offersInCart, offers]);

  const selectedIncentive = getSelectedIncentive(loyaltyEngineId, offers, offersInCart, true);

  const handlePress = useCallback(
    (incentiveId: string, incentiveName: string, incentiveSanityId: string) => {
      if (incentiveSanityId !== selectedIncentive?._id) {
        logRBIEvent({
          name: CustomEventNames.OFFER_SELECTED,
          type: EventTypes.Other,
          attributes: {
            engineId: incentiveId,
            sanityId: incentiveSanityId,
            name: incentiveName,
          },
        });
        linkTo(`${routes.loyaltyOfferList}/${incentiveId}`);
      }
    },
    [linkTo, logRBIEvent, selectedIncentive]
  );

  useRumPageView(
    `loyalty-offers${selectedIncentive ? '-detail' : '-list'}`,
    `Loyalty Offers${selectedIncentive ? ' Detail' : ' List'}`
  );

  useEffect(() => {
    if (
      loyaltyEngineId &&
      !selectedIncentive &&
      loyaltyStandardOffersEnabled &&
      !offersLoading &&
      !isEmpty(offers)
    ) {
      // TODO: ReactNavigation review this error case
      toast.show({
        text: formatMessage(
          { id: 'incentiveFeedbackInvalidStore' },
          { incentiveType: formatMessage({ id: 'offer' }) }
        ),
        variant: 'negative',
      });
      navigate(isConfigOffer ? routes.menu : routes.loyaltyOfferList, {
        popCurrent: true,
        replace: true,
      });
    }
  }, [
    formatMessage,
    isConfigOffer,
    loyaltyEngineId,
    loyaltyStandardOffersEnabled,
    navigate,
    offers,
    offersLoading,
    selectedIncentive,
    toast,
  ]);

  const renderItem = useCallback(
    ({ item: offer }: { item: LoyaltyOffer }) => {
      const incentiveData = parseIncentiveData(offer);
      const isPromoCode = (incentiveData.metadata || []).some(
        data => data?.value === IncentiveSource.PROMOTION_CODE
      );

      const renderOfferItem = () => {
        return <LoyaltyOffersItem handlePress={handlePress} incentiveData={incentiveData} />;
      };
      // Animate only if skipAnimation is disabled and offer source is Promo Code campaing
      return !skipAnimation.current && isPromoCode ? (
        <AnimatedOfferItem setSkipAnimation={(skip: boolean) => (skipAnimation.current = skip)}>
          {renderOfferItem()}
        </AnimatedOfferItem>
      ) : (
        renderOfferItem()
      );
    },
    [handlePress]
  );

  if (!loyaltyStandardOffersEnabled) {
    // Use CBA Offers UI
    return (
      <LoyaltyIncentivesContainer>
        <StyledUIRefreshOffers
          currentUrl={formatMessage({ id: 'routes.rewards' })}
          suffixUrl={routes.offers}
        />
      </LoyaltyIncentivesContainer>
    );
  }

  const isSelectedIncentiveLoading = !isBaseOffersRoute && !selectedIncentive;

  if ((offersLoading && offers.length === 0) || isSelectedIncentiveLoading) {
    return <StyledLoyaltyLoader />;
  }

  const onRefresh = () => {
    // When a user manually refreshes we have to fetch the CMS offers
    // and the engine offers. These two offers have been implemented in different
    // ways and signal different loading mechanisms (one is through redux, the other a hook)
    // To standardize the way this component responds to loading, I've opted to
    // manually set the offers loading (which is currently what happens when you call fetchOffers)
    // I'm doing this eagerly so the refresh control doesn't close. FlatList will close the RefreshControl
    // if the refreshing state doesn't get signalled in an appropriate time.
    //
    // In the future, this could be refactored and simplified if the `refetchCmsOffers` worked through redux
    // in the same discipline.
    dispatch(actions.loyalty.setOffersLoading(true));

    refetchCmsOffers()
      .then(fetchOffers)
      .then(() => dispatch(actions.loyalty.setOffersLoading(false)));
  };

  const onPromoCodeRedeemSuccess = ({ configOffer }: { configOffer: LoyaltyOffer }) => {
    skipAnimation.current = false;
    toast.show({
      text: formatMessage({ id: 'promoCodeAddedToOffers' }),
      variant: 'positive',
    });
    dispatch(actions.loyalty.setCmsOffers([configOffer]));
    dispatch(actions.loyalty.unshiftPersonalizedOffer(configOffer));
  };

  const canShowPromoCode = enableLoyaltyPromoCodes && !selectedIncentive;

  return (
    <LoyaltyIncentivesContainer
      accessible
      accessibilityRole="header"
      accessibilityLabel={formatMessage({ id: 'offers' })}
    >
      {isMobile && selectedIncentive && (
        <MobileBackButton
          to={
            selectedIncentive.__typename === 'ConfigOffer' ? routes.menu : routes.loyaltyOfferList
          }
        />
      )}
      {canShowPromoCode && <LoyaltyPromoCode onPromoCodeRedeemSuccess={onPromoCodeRedeemSuccess} />}
      {enableLoyaltyOffersFilters && !selectedIncentive && (
        <LoyaltyOffersFilterButtons appliedFilters={appliedFilters} toggleFilter={toggleFilter} />
      )}
      <View flex={1} mx={selectedIncentive ? 0 : '$3'} marginTop={canShowPromoCode ? '$0' : '$4'}>
        {selectedIncentive ? (
          <IncentiveDetails engineIncentivesMap={{}} selectedIncentive={selectedIncentive} />
        ) : !offers.length ? (
          <OffersEmptyState />
        ) : (
          <AnimatedFlatList
            ListHeaderComponent={
              <>
                <LoyaltyOffersCooldown />
                {
                  /* If it's a widget, don't allow to pull on reload */
                  offersInCart?.length ? (
                    <OffersInCart
                      offers={offersInCart}
                      handlePress={handlePress}
                      handleRemove={handleRemove}
                    />
                  ) : null
                }
              </>
            }
            ListFooterComponent={offers?.length ? <EndOfOffers /> : null}
            {...(isNative
              ? {
                  refreshControl: (
                    <RefreshControl refreshing={offersLoading} onRefresh={onRefresh} />
                  ),
                }
              : {})}
            data={offers}
            initialNumToRender={5}
            keyExtractor={(item, index) => item.loyaltyEngineId || `${index}`}
            renderItem={renderItem}
          />
        )}
      </View>
    </LoyaltyIncentivesContainer>
  );
};
