import React, { useCallback } from 'react';

import { Box, Header, Text } from '@rbilabs/universal-components';
import { useIntl } from 'react-intl';

import { ResponsiveCarousel } from 'components/responsive-carousel';
import { ILocaleImageFragment, ILocaleStringFragment } from 'generated/sanity-graphql';

import { useStaticContentLanguage } from '../static-content-language';

import { styles } from './constants';
import { Image, Wrapper } from './styled';

export type IReward = {
  image: ILocaleImageFragment;
  name: ILocaleStringFragment;
};

export type RewardCarouselCategory = {
  label: ILocaleStringFragment;
  rewards: Array<IReward>;
};

export type IRewardsCarouselProps = {
  rewardsCarouselTitle: {
    en: string;
    fr: string;
  };
  rewardsCarouselDescription: {
    en: string;
    fr: string;
  };
  rewardCarouselCategories: Array<RewardCarouselCategory>;
};

const RewardsCarouselWidget: React.FC<React.PropsWithChildren<IRewardsCarouselProps>> = ({
  rewardsCarouselTitle,
  rewardsCarouselDescription,
  rewardCarouselCategories,
}) => {
  const { formatMessage } = useIntl();
  const { staticContentLanguage: language } = useStaticContentLanguage();

  const RewardsCategories = ({
    rewardCategory,
    categoryIndex,
  }: {
    rewardCategory: RewardCarouselCategory;
    categoryIndex: number;
  }) => (
    <Box alignItems={'center'} margin={'$0'} marginTop={'$10'}>
      <Header marginBottom={'$0'}>
        {rewardCategory.label[language]} {formatMessage({ id: 'crowns' })}
      </Header>
      <ResponsiveCarousel
        alignWithParent
        data={rewardCategory.rewards}
        itemWidth={260}
        centerCards
        bgColor={Styles.color.teal}
        keyExtractor={useCallback(
          ({ name }: IReward, index: number) => name?.[language] || index.toString(),
          []
        )}
        renderItem={useCallback(
          ({ item }: { item: IReward }) => {
            const styleIndex = categoryIndex > 5 ? 0 : categoryIndex;
            const itemNameColor = styles[styleIndex].color ?? 'black';
            const carouselBackgroundColor = styles[styleIndex].background ?? 'transparent';

            return (
              <Box
                backgroundColor={carouselBackgroundColor}
                justifyContent={'center'}
                alignItems="center"
                paddingX={'$2'}
                paddingY={'$2'}
                margin={'$0'}
              >
                <Image
                  lockedAspectRatio
                  objectFitContain
                  alt={item.name[language] ?? ''}
                  image={item.image[language]}
                />
                <Text color={itemNameColor}>{item.name[language]}</Text>
              </Box>
            );
          },
          [categoryIndex]
        )}
      />
    </Box>
  );

  return (
    <Wrapper>
      <Header>{rewardsCarouselTitle[language]}</Header>
      <Text>{rewardsCarouselDescription[language]}</Text>
      {rewardCarouselCategories.map((rewardCategory, i) => (
        <RewardsCategories key={i} categoryIndex={i} rewardCategory={rewardCategory} />
      ))}
    </Wrapper>
  );
};

export default RewardsCarouselWidget;
