import { LinkingOptions } from '@react-navigation/native';
import * as Linking from 'expo-linking';

import { relativeRoutes, routes } from 'utils/routing';

import { getStateFromPathMiddleware } from './get-state-from-path-middleware';
import { DeepLinks } from './routes';

const appPrefix = Linking.createURL('/');

// TODO: ReactNavigation Register all deep linked screens we need to support
export const config = {
  screens: {
    [routes.drawer]: {
      screens: {
        [routes.tabs]: {
          screens: {
            [routes.menu]: {
              screens: {
                [routes.menuMain]: {
                  screens: {
                    [routes.menu]: routes.menu,
                  },
                },
                [routes.menuContent]: `${routes.menu}/:id`,
              },
            },
            [routes.loyaltyDashboard]: {
              screens: {
                [routes.loyaltyRewardsDashboard]: DeepLinks.loyaltyRewardsDashboard,
                [routes.loyaltyRewardsList]: `${routes.loyaltyRewardsList}/:rewardId`,
              },
            },
            [routes.loyaltyOfferList]: {
              screens: {
                [routes.offersList]: routes.loyaltyOfferList,
                [`${routes.loyaltyOfferList}/`]: `${routes.loyaltyOfferList}/:offerEngineId`,
              },
            },
            // Linking for /offers redirection to /rewards/offer
            [routes.offers]: `${routes.offers}/:offerId`,
            [routes.support]: {
              screens: {
                [routes.supportCategory]: `${routes.supportCategories}/:categoryId`,
                [routes.supportForm]: routes.supportForm,
                [routes.supportOrders]: routes.supportOrders,
                [routes.missingItems]: `${routes.missingItems}/:orderId`,
                [routes.missingOrder]: `${routes.missingOrder}/:orderId`,
              },
            },
          },
        },
      },
    },
    [routes.storeLocator]: 'store-locator',
    [routes.address]: routes.address,
    [routes.store]: `${routes.store}/:id`,
    [routes.ordersDetails]: `${routes.orders}/:rbiOrderId`,
    [routes.cart]: {
      screens: {
        [routes.orderConfirmation]: `${routes.orderConfirmation}/:rbiOrderId`,
        [routes.cartAddExtras]: routes.cartAddExtras,
      },
    },
    [routes.favoriteEditor]: `/menu/${relativeRoutes.favorites}/:favoriteId/edit`,
    [routes.signUp]: routes.signUp,
    [routes.signIn]: routes.signIn,
    [routes.linkCard]: routes.linkCard,
    [routes.modalStaticPage]: {
      screens: {
        [routes.staticPage]: `/:staticPagePath`,
      },
    },
    [routes.rewardsOnboarding]: `${routes.rewardsOnboarding}/:stepIndex`,
    [routes.sendGift]: routes.sendGift,
    [routes.confirmJwt]: routes.confirmJwt,
  },
};

// TODO: ReactNavigation Need to add universal links per brand. Ex: https://www.bk.com
export const linking: LinkingOptions<ReactNavigation.RootParamList> = {
  prefixes: [appPrefix],
  config,
  getStateFromPath: getStateFromPathMiddleware,
};
