import React, { FC } from 'react';

import { useIntl } from 'react-intl';

import { ActionButtonVariants } from 'components/action-button';
import { useNavigation } from 'hooks/navigation/use-navigation';
import { useFeatureLoyaltyUi } from 'hooks/use-feature-loyalty-ui';
import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';

import {
  DetailsButton,
  LearnMoreButton,
  LearnMoreIcon,
  StyledLoyaltyRewardsLogo,
  TextContainer,
  UnauthenticatedContainer,
  WelcomeMessage,
} from './styled';

const UnauthenticatedBanner: FC<React.PropsWithChildren<unknown>> = () => {
  const { formatMessage } = useIntl();
  const { navigate } = useNavigation();
  const { featureLoyaltyUiLoading, featureLoyaltyUi } = useFeatureLoyaltyUi();
  const hasIcon = !featureLoyaltyUiLoading && featureLoyaltyUi?.rewardsLearnMoreIcon?.locale;
  const ButtonComponent = hasIcon ? LearnMoreButton : DetailsButton;
  const hideLoyaltyBannerWelcomeMessage = useFlag(
    LaunchDarklyFlag.ENABLE_HIDE_LOYALTY_BANNER_WELCOME_MESSAGE
  );

  const handlePress = () => {
    navigate(formatMessage({ id: 'routes.loyaltyBannerUnauthenticated' }));
  };

  return (
    <UnauthenticatedContainer
      center={hideLoyaltyBannerWelcomeMessage}
      testID="unauthenticated-banner"
    >
      <TextContainer>
        <StyledLoyaltyRewardsLogo />
        {!hideLoyaltyBannerWelcomeMessage && (
          <WelcomeMessage>{formatMessage({ id: 'loyaltyBannerLoggedOutMessage' })}</WelcomeMessage>
        )}
      </TextContainer>
      <ButtonComponent
        testID="unauthenticated-banner-learn-more"
        variant={ActionButtonVariants.OUTLINE}
        onPress={handlePress}
      >
        {!!hasIcon && (
          <LearnMoreIcon
            image={featureLoyaltyUi?.rewardsLearnMoreIcon?.locale}
            alt=""
            objectFitContain
          />
        )}
        {formatMessage({ id: 'loyaltyBannerDetailsButtonLabel' })}
      </ButtonComponent>
    </UnauthenticatedContainer>
  );
};

export default UnauthenticatedBanner;
