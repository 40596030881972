import React, { useCallback, useEffect } from 'react';

import { useDisclose } from '@rbilabs/universal-components';

import { useNavigation } from 'hooks/navigation/use-navigation';
import { useRoute } from 'hooks/navigation/use-route';
import { CustomEventNames, EventTypes, useCRMEventsContext } from 'state/crm-events';
import { ClickEventComponentNames } from 'state/crm-events/constants';

import { LoyaltyPromoCodeCTA } from './loyalty-promo-code-cta';
import { LoyaltyPromoCodeDrawer } from './loyalty-promo-code-drawer';
import { IPromoCodeProps } from './types';

export const LoyaltyPromoCode = ({ onPromoCodeRedeemSuccess }: IPromoCodeProps) => {
  const { params } = useRoute<{ promoCode?: string }>();
  const { isOpen, onOpen, onClose } = useDisclose(!!params.promoCode);
  const { logRBIEvent } = useCRMEventsContext();
  const { setParams } = useNavigation();
  const onDismiss = useCallback(() => {
    // React router 6 return undefined as string.
    setParams({ ...params, promoCode: '' });
    onClose();
  }, [onClose, setParams, params]);

  const onOpenHandler = () => {
    logRBIEvent({
      name: CustomEventNames.CLICK_EVENT,
      type: EventTypes.Other,
      attributes: {
        component: ClickEventComponentNames.PROMO_CODE_TILE,
        text: 'Have a Promo Code?',
      },
    });
    onOpen();
  };

  useEffect(() => {
    if (!isOpen && params.promoCode) {
      // This delay is necessary in order to allow the component to be ready to show the Actionsheet.
      setTimeout(onOpen, 500);
    }
  }, [isOpen, onOpen, params.promoCode]);

  return (
    <>
      <LoyaltyPromoCodeCTA onPress={onOpenHandler} />
      {/* Hack to be able to use React Navigation navigate method from the Actionsheet component */}
      {/* Navigate won't trigger unless Actionsheet is unmounted */}
      {isOpen ? (
        <LoyaltyPromoCodeDrawer
          isOpen={isOpen}
          onDismiss={onDismiss}
          promoCode={params.promoCode?.toString()}
          onPromoCodeRedeemSuccess={onPromoCodeRedeemSuccess}
          onNavigate={onClose}
        />
      ) : null}
    </>
  );
};
