import React, { useCallback, useEffect, useState } from 'react';

import { Box, FlatList, Pressable, Text } from '@rbilabs/universal-components';
import { useIntl } from 'react-intl';

import { ISanityImage } from '@rbi-ctg/menu';
import Picture from 'components/picture';
import { MenuObjectTypes } from 'enums/menu';
import { NutritionExplorerMenuObject } from 'remote/queries/fragments/nutrition-explorer-menu';
import { theme } from 'styles/configure-theme';

import { DropdownArrow, defaultOrientation, defaultSize } from './dropdown-arrow';
import { ProductCard } from './product-card';
import { IProduct } from './types';

const Container = Box.withConfig<{ hasBorderTop: boolean }>(p => ({
  width: 'full',
  borderTopWidth: p.hasBorderTop ? '1' : null,
  borderTopColor: theme.token('border-color-default'),
}));

const Header = Pressable.withConfig({
  flexDirection: 'row',
  alignItems: 'center',
  margin: 0,
  paddingY: '$3',
  width: 'full',
});

const TextContainer = Box.withConfig({
  _text: {
    textAlign: 'left',
  },

  paddingLeft: {
    base: '$2',
    md: '$3',
  },
});

const DisabledText = Text.withConfig<{ disabled: boolean }>(p => ({
  color: p.disabled ? theme.token('text-disabled') : null,
}));

const TitleContainer = Box.withConfig({
  flexDirection: 'row',
  alignItems: 'center',
  flex: 1,
});

const TitleText = DisabledText.withConfig({
  variant: 'headerTwo',
  margin: 0,
});

const CountText = DisabledText.withConfig({
  variant: 'headerTwo',
  marginLeft: '$2',
  flexShrink: 1,
  margin: 0,
});

const Title: React.FC<React.PropsWithChildren<{
  title: string;
  count: number;
  disabled: boolean;
}>> = ({ title, count, disabled }) => (
  <TitleContainer>
    <TitleText disabled={disabled}>{title}</TitleText>
    <CountText disabled={disabled}>{`(${count})`}</CountText>
  </TitleContainer>
);

const Caption = DisabledText.withConfig({
  margin: 0,
  variant: 'copyTwo',
});

const PreviewPicture = Picture.withConfig<{
  disabled: boolean;
}>(p => ({
  flexShrink: 0,
  opacity: p.disabled ? '0.7' : '1',

  width: {
    base: '$3',
    md: '$16',
  },

  height: {
    base: '$2.5',
    md: '$16',
  },

  marginLeft: {
    base: '$1',
    md: '$3',
  },
}));

const ProductCardSection = FlatList.withConfig({
  width: '100%',
  alignSelf: 'center',
  numColumns: 2,
  paddingY: '4',
});

interface IAccordion {
  parentMenuObject: NutritionExplorerMenuObject;
  products: IProduct[];
  startsOpen: boolean;
  productBackgroundImage?: ISanityImage;
  hasBorderTop: boolean;
}

export const Accordion: React.FC<React.PropsWithChildren<IAccordion>> = ({
  parentMenuObject,
  products,
  startsOpen,
  productBackgroundImage,
  hasBorderTop = true,
}) => {
  const [open, setOpen] = useState(startsOpen);
  const { formatMessage } = useIntl();

  const disabled = products.length === 0;

  const onPressHeader = useCallback(() => {
    if (!disabled) {
      setOpen(isOpen => !isOpen);
    }
  }, [disabled]);

  useEffect(() => {
    if (disabled) {
      setOpen(false);
    }
  }, [disabled]);

  const image =
    parentMenuObject._type === MenuObjectTypes.SECTION
      ? parentMenuObject.carouselImage
      : parentMenuObject.image;

  return (
    <Container hasBorderTop={hasBorderTop}>
      <Header onPress={onPressHeader} aria-expanded={open} disabled={disabled}>
        <DropdownArrow
          open={open}
          disabled={disabled}
          size={defaultSize}
          orientation={defaultOrientation}
        />
        <PreviewPicture image={image} alt="" objectFitContain disabled={disabled} />
        <TextContainer>
          <Title
            disabled={disabled}
            title={parentMenuObject.name?.locale}
            count={products.length}
          />
          {disabled && (
            <Caption disabled={disabled}>{formatMessage({ id: 'noItemsFitCriteria' })}</Caption>
          )}
        </TextContainer>
      </Header>
      {open ? (
        <ProductCardSection
          // @ts-expect-error TS(2322) FIXME: Type '{ data: IProduct[]; keyExtractor: (product: ... Remove this comment to see the full error message
          data={products}
          // @ts-expect-error TS(7006) FIXME: Parameter 'product' implicitly has an 'any' type.
          keyExtractor={product => product._id}
          // @ts-expect-error TS(7031) FIXME: Binding element 'item' implicitly has an 'any' typ... Remove this comment to see the full error message
          renderItem={({ item }) => (
            <Box paddingX="$1" marginY="$1" width="50%">
              <ProductCard product={item} backgroundImage={productBackgroundImage} />
            </Box>
          )}
        />
      ) : null}
    </Container>
  );
};
