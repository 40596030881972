import { datadogRum } from '@datadog/browser-rum';

import { useConfigValue } from 'hooks/configs/use-config-value';
import useEffectOnce from 'hooks/use-effect-once';
import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';
import { brand, env, getCurrentCommitHash, isLocalDev, platform } from 'utils/environment';

const FALLBACK_SAMPLING_RATE = isLocalDev ? 100 : 10;

export const useDatadogRum = () => {
  const sampleRate = useFlag(LaunchDarklyFlag.DATADOG_RUM_SAMPLE_RATE);
  const dataDogConfig = useConfigValue({ key: 'datadog', defaultValue: { rum: {} } });
  const awsConfig = useConfigValue({ key: 'aws', defaultValue: {}, isRegionalized: false });

  useEffectOnce(() => {
    const applicationId = dataDogConfig.rum.applicationId;
    const clientToken = dataDogConfig.rum.clientToken;
    const datacenter = dataDogConfig.rum.dataCenter || 'us';
    const allowedTracingOrigins = [awsConfig.gqlApiUrl, awsConfig.gqlGatewayApiUrl];
    const currentEnv = env();

    // We do not log errors to datadog in local dev
    // If you need to test datadog integration, remove the `&& !isLocalDev`
    if (isLocalDev) {
      return;
    }

    datadogRum.init({
      applicationId,
      clientToken,
      datacenter,
      service: `${brand()}_${platform()}`,
      env: currentEnv,
      version: getCurrentCommitHash(),
      sampleRate: sampleRate ?? FALLBACK_SAMPLING_RATE,
      trackInteractions: true,
      defaultPrivacyLevel: 'mask-user-input',
      allowedTracingOrigins,
    });
  });
};
