import React, { useEffect, useState } from 'react';

import { useIntl } from 'react-intl';

import Dialog from 'components/dialog';
import { useNetworkContext } from 'state/network';

function ErrorNetwork() {
  const { formatMessage } = useIntl();
  const { hasNetworkError } = useNetworkContext();
  const [browsingOffline, setBrowsingOffline] = useState(false);
  const [hasOpened, setHasOpened] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (!hasOpened && hasNetworkError && !browsingOffline) {
      setHasOpened(true);
      setIsOpen(true);
    }
  }, [browsingOffline, hasNetworkError, hasOpened]);

  return (
    <Dialog
      showDialog={isOpen}
      modalAppearanceEventMessage="Network Error"
      testID="error-network-dialog"
      heading={formatMessage({ id: 'lostNetworkConnectivity' })}
      body={formatMessage({ id: 'checkNetworkSettings' })}
      accessibilityLabel={formatMessage({ id: 'lostNetworkConnectivity' })}
      showCloseButton
      onDismiss={() => {
        setBrowsingOffline(true);
        setIsOpen(false);
      }}
    />
  );
}

export default ErrorNetwork;
