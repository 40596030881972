import * as React from 'react';

import { Box, BoxProps } from '@rbilabs/universal-components';

import { useIsDesktopBp } from 'hooks/breakpoints';
import { useRoute } from 'hooks/navigation/use-route';
import { useLocalizedTabs } from 'navigation/tab-navigator/tabs';
import useAuthFlow from 'state/auth/hooks/use-auth-flow';
import { isWeb } from 'utils/environment';
import { isItemPickerOrComboRoute } from 'utils/routing';

import { BottomNavMobileTab } from './bottom-nav-mobile-tab';
import { MobileWebNavigationBorder } from './mobile-web-navigation-border';

const mobileMediaQuery = { mediaquery: 'mobile' };

// The types for react native do not support `dataSet` yet, but
// it is the required way to pass data-* attributes to the node.
// And we are using this mechanism to ensure these nodes are rendered
// during the pre-render, but only visible for the correct screen size.
const onlyRenderInMobile = () =>
  ({
    dataSet: mobileMediaQuery,
  } as any);

const boxViewProps: BoxProps = {
  flexDirection: 'row',
  justifyContent: {
    base: 'space-evenly',
    lg: 'space-between',
  },
  _web: {
    width: 'full',
    maxWidth: Styles.layout.sectionMaxWidth,
    marginX: 'auto',
  },
  backgroundColor: '__legacyToken.bottom-nav-bar-background-color',
};

const MobileWebNavigationBar: React.FC<React.PropsWithChildren<unknown>> = () => {
  const { pathname } = useRoute();
  const hideNavLinks = isWeb && isItemPickerOrComboRoute(pathname);

  const { isInAuthFlow } = useAuthFlow();

  const localizedTabs = useLocalizedTabs();

  const isDesktop = useIsDesktopBp();
  if (isDesktop) {
    return null;
  }

  // not showing mobile navigation bar on sign up flow
  if (isInAuthFlow) {
    return null;
  }

  return (
    <MobileWebNavigationBorder {...onlyRenderInMobile()}>
      {!hideNavLinks && (
        <Box safeAreaBottom {...boxViewProps}>
          {localizedTabs.map(tab => (
            <BottomNavMobileTab key={tab.link} componentKey={tab.link} tab={tab} />
          ))}
        </Box>
      )}
    </MobileWebNavigationBorder>
  );
};

export default MobileWebNavigationBar;
