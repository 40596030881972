import React, { FC, useCallback } from 'react';

import { Button } from '@rbilabs/universal-components';
import { useIntl } from 'react-intl';

import ActionButton, { ActionButtonVariants } from 'components/action-button';
import Dialog from 'components/dialog';
import { useToast } from 'hooks/use-toast';
import useUserVerification from 'hooks/use-user-verification';

interface IUserVerificationModalProps {
  onDismiss: () => void;
  showDialog: boolean;
}

const UserVerificationModal: FC<React.PropsWithChildren<IUserVerificationModalProps>> = ({
  showDialog,
  onDismiss,
}) => {
  const toast = useToast();
  const { formatMessage } = useIntl();
  const { sendVerificationEmail, loading } = useUserVerification();
  const onVerify = useCallback(
    async (event: any) => {
      event.preventDefault();
      await sendVerificationEmail();
      toast.show({
        text: formatMessage({ id: 'emailVerificationSent' }),
        variant: 'positive',
      });
      onDismiss();
    },
    [toast, formatMessage, onDismiss, sendVerificationEmail]
  );
  return (
    <Dialog
      showDialog={showDialog}
      onDismiss={onDismiss}
      modalAppearanceEventMessage="User Verification Modal"
      testID="user-verification-modal"
      heading={formatMessage({
        id: 'verifyYourEmail',
      })}
      body={formatMessage({
        id: 'verifyYourEmailInstructions',
      })}
      actions={
        <Button.Group>
          <ActionButton
            isLoading={loading}
            fullWidth
            onPress={onVerify}
            testID="user-verification-modal-verify-button"
            mb={2}
          >
            {formatMessage({
              id: 'verifyYourEmail',
            })}
          </ActionButton>
          <ActionButton
            disabled={loading}
            variant={ActionButtonVariants.OUTLINE}
            fullWidth
            onPress={onDismiss}
            testID="user-verification-modal-cancel-button"
          >
            {formatMessage({
              id: 'cancel',
            })}
          </ActionButton>
        </Button.Group>
      }
    />
  );
};

export default UserVerificationModal;
