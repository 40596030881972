import * as React from 'react';
import { FC, useEffect } from 'react';

import { useNavigation } from 'hooks/navigation/use-navigation';
import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';
import { useOrderContext } from 'state/order';
import { useStoreContext } from 'state/store';
import { routes } from 'utils/routing';

import { useTopServiceModeDetails } from './hooks/use-top-service-details';
import { TopServiceModeAppHeaderView } from './top-service-mode-app-header.view';
import { ITopServiceModeProps } from './types';

/**
 *
 * TopServiceModeAppHeader purpose
 *
 */
const TopServiceModeAppHeader: FC<React.PropsWithChildren<ITopServiceModeProps>> = () => {
  const enableOrdering = useFlag(LaunchDarklyFlag.ENABLE_ORDERING);
  const { resetStore, store } = useStoreContext();
  const topServiceModeDetails = useTopServiceModeDetails();
  const {
    CurbsideConfirmationModal,
    curbsideConfirmationModalOpen,
    setCurbsideConfirmationModalOpen,
  } = topServiceModeDetails;

  const {
    emptyCart,
    setCurbsidePickupOrderId,
    setCurbsidePickupOrderTimePlaced,
  } = useOrderContext();

  const { navigate } = useNavigation();

  useEffect(() => {
    if (!enableOrdering) {
      resetStore();
    }
  }, [enableOrdering, store]);

  const modalDismiss = () => setCurbsideConfirmationModalOpen(false);
  const cancelCurbsideOrder = () => {
    emptyCart();
    setCurbsidePickupOrderId('');
    setCurbsidePickupOrderTimePlaced('');
    modalDismiss();
    navigate(routes.storeLocator);
  };

  return (
    <>
      <TopServiceModeAppHeaderView {...topServiceModeDetails} />

      {curbsideConfirmationModalOpen && (
        <CurbsideConfirmationModal
          showDialog={curbsideConfirmationModalOpen}
          onKeepOrder={modalDismiss}
          onCancelOrder={cancelCurbsideOrder}
          onDismiss={modalDismiss}
        />
      )}
    </>
  );
};

export default TopServiceModeAppHeader;
