import React from 'react';

import { Pressable, Text } from '@rbilabs/universal-components';
import { useIntl } from 'react-intl';

import { useReversedUIContext } from 'state/reversed-ui';
import { theme } from 'styles/configure-theme';

export const StyledCtaButton = Pressable.withConfig({
  _web: {
    cursor: 'pointer',
  },
});

export const StyledCtaText = Text.withConfig({
  fontSize: 'xs',
});

interface CtaButtonProps {
  text: string;
  cta: () => void;
}

const CtaButton = ({ text, cta }: CtaButtonProps) => {
  const { formatMessage } = useIntl();
  const { reversedUI } = useReversedUIContext();

  return (
    <StyledCtaButton onPress={cta} accessibilityRole="button">
      <StyledCtaText
        color={reversedUI ? theme.token('text-reversed') : theme.token('text-default')}
        textDecorationLine="underline"
      >
        {formatMessage({ id: text })}
      </StyledCtaText>
    </StyledCtaButton>
  );
};

export default CtaButton;
