import React from 'react';

import { Icon } from '@rbilabs/universal-components';

import { ButtonContent, EditButton, Label } from './clickable-text-with-pencil.styled';
import { IClickableTextWithPencilProps } from './types';

const ClickableTextWithPencil: React.VFC<IClickableTextWithPencilProps> = ({
  onPress,
  label,
  accessibilityLabel,
}) => {
  return (
    <EditButton
      testID="clickable-text-with-pencil"
      onPress={onPress}
      accessibilityLabel={accessibilityLabel}
    >
      <ButtonContent>
        <Label>{label}</Label>
        <Icon title={label} variant="edit" size="13px" />
      </ButtonContent>
    </EditButton>
  );
};

export default ClickableTextWithPencil;
