import { IOffer } from '@rbi-ctg/menu';

interface PromoCodeViewMessages {
  buttonText: string;
  inputLabel: string;
  noPromoCodeText: string;
  errorMessage: string;
}

export interface IPromoCodeOfferProps {
  cognitoId: string;
  offer: IOffer;
  togglePromoCodeOfferRedeemable: (offerId: string) => void;
}

export interface IPromoCodeOfferViewProps {
  offer: IOffer;
  isLoading: boolean;
  errorMessageId: PromoCodeErrorMessageIds | null;
  messages: PromoCodeViewMessages;
  code: string;
  onSubmit: () => void;
  onChangeInput: (e: React.ChangeEvent) => void;
}

export enum PromoCodeErrorMessageIds {
  default = 'promoCodeOfferUnknownException',
  expiredCode = 'promoCodeOfferExpiredCode',
  invalidCode = 'promoCodeOfferInvalidCode',
  redeemedCode = 'promoCodeOfferRedeemedCode',
  invalidOfferId = 'promoCodeOfferInvalidOfferId',
  unknownException = 'promoCodeOfferUnknownException',
  required = 'promoCodeOfferRequired',
}
