import React, { useEffect, useRef, useState } from 'react';

import { Box } from '@rbilabs/universal-components';
import { useIntl } from 'react-intl';

import { IOffer } from '@rbi-ctg/menu';
import ConfirmDialog from 'components/confirm-dialog';
import RestaurantRedemptionModal from 'components/offer-redemption-modal/redemption-modal';
import { useOffer } from 'components/offer/use-offer';
import { useNavigation } from 'hooks/navigation/use-navigation';
import { useRoute } from 'hooks/navigation/use-route';
import useDialogModal from 'hooks/use-dialog-modal';
import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';
import { useLocationContext } from 'state/location';
import { useServiceModeContext } from 'state/service-mode';
import { useStoreContext } from 'state/store';
import { routes } from 'utils/routing';

import { DeliveryMessageView } from '../delivery-message-view';

import { IDetailsPanelErrorProps } from './details-panel-error';
import { DetailsPanelView } from './details-panel-view';
import OfferUpsellModal from './offer-upsell-modal';
import { useOffersUIContext } from './offers-ui-context';
import { IAttemptOfferRedemptionArgs } from './types';
import { useOfferDetails } from './use-offer-details';
import { useOfferRedemptionFlow } from './use-offer-redemption-flow';

const MessageContainer = Box.withConfig({
  flex: 1,
  width: 'full',
  alignSelf: 'stretch',
  alignItems: 'center',
  justifyContent: 'center',
});

export interface IDetailsPanelProps extends IDetailsPanelErrorProps {
  offer: IOffer;
  offersInCooldown: boolean;
}
export const DetailsPanel: React.FC<React.PropsWithChildren<IDetailsPanelProps>> = ({
  offer,
  offersInCooldown,
}) => {
  const {
    authenticated,
    showDeliveryMessage,
    previewOfferFeedback: offerFeedback,
    offers,
    togglePromoCodeOfferRedeemable,
  } = useOffersUIContext();
  const offerIndex = offers.findIndex(o => o._id === offer._id);

  const { noStoreSelected } = useStoreContext();
  const { isDelivery, serviceMode } = useServiceModeContext();

  const { setStoreLocatorCallbackUrl } = useLocationContext();
  const { navigate } = useNavigation();
  const { pathname } = useRoute();

  const enabledForceRestaurantSelection = Boolean(
    useFlag(LaunchDarklyFlag.FORCE_RESTAURANT_SELECTION_FOR_REWARDS)
  );

  const shouldRedirectToStoreLocator =
    enabledForceRestaurantSelection && (noStoreSelected || !serviceMode);
  useEffect(() => {
    if (shouldRedirectToStoreLocator) {
      setStoreLocatorCallbackUrl(pathname);
      navigate(routes.storeLocator, { replace: true });
    }
  }, [navigate, pathname, setStoreLocatorCallbackUrl, shouldRedirectToStoreLocator]);

  const { formatMessage } = useIntl();

  const {
    loginRequiredForRedemption,
    mobileRedemptionAvailable,
    disableInRestaurantOfferRedemptions,
    disableMobileOfferRedemptions,
    offerAvailableRightNow,
    redemptionErrorMessage,
    restaurantRedemptionAvailable,
    showRestaurantRedemptionButton,
    showMobileRedemptionButton,
    restaurantRedemptionButtonUiType,
    showSignUpToRedeem,
    noPossibleRedemption,
  } = useOfferDetails({
    authenticated,
    offer,
    offerIndex,
  });

  const {
    attemptOfferRedemption,
    onConfirmSwitchOffers,
    onConfirmSwitchServiceMode,
    onCancelSwitchOffers,
    onCancelSwitchServiceMode,
    onConfirmReadyToRedeem,
    onCancelReadyToRedeem,
    onDismissRestaurantRedemption,
    showConfirmSwitchOffersDialog,
    showReadyToRedeemDialog,
    showRestaurantRedemptionModal,
    showSwitchServiceModeDialog,
    restaurantRedemptionStartTime,
    loadingOfferConfigs,
  } = useOfferRedemptionFlow({
    offer,
    authenticated,
    offerAvailableRightNow,
    mobileRedemptionAvailable,
    restaurantRedemptionAvailable,
    loginRequiredForRedemption,
  });
  const [showUpsellOffer, setShowUpsellOffer] = useState(false);
  const wasUpsellOfferDiscarded = useRef(false);

  const [ReadyToRedeemDialog, openReadyToRedeemDialog] = useDialogModal({
    modalAppearanceEventMessage: 'Confirm Ready to Redeem',
    onConfirm: onConfirmReadyToRedeem as any,
    onDismiss: onCancelReadyToRedeem as any,
    showCancel: false,
  });

  useEffect(() => {
    if (showReadyToRedeemDialog) {
      openReadyToRedeemDialog();
    }
  }, [openReadyToRedeemDialog, showReadyToRedeemDialog]);

  const { endingIn, platforms } = useOffer({ offer, offerFeedback });

  const enableOfferBadges = useFlag(LaunchDarklyFlag.ENABLE_OFFER_BADGES);

  const handleOfferRedeem = React.useCallback(
    ({ redemptionMethod, bypassCheckForAnotherOffer = false }: IAttemptOfferRedemptionArgs) => {
      if (!wasUpsellOfferDiscarded.current && offer.upsellOptions?.length) {
        setShowUpsellOffer(true);
        return;
      }
      attemptOfferRedemption({ redemptionMethod, bypassCheckForAnotherOffer });
    },
    [attemptOfferRedemption, offer.upsellOptions, wasUpsellOfferDiscarded]
  );
  const handleUpsellOfferDiscard = React.useCallback(() => {
    setShowUpsellOffer(false);
    wasUpsellOfferDiscarded.current = true;
  }, [setShowUpsellOffer, wasUpsellOfferDiscarded]);

  if (showDeliveryMessage) {
    return (
      <MessageContainer>
        <DeliveryMessageView />
      </MessageContainer>
    );
  }
  return (
    <>
      <DetailsPanelView
        togglePromoCodeOfferRedeemable={togglePromoCodeOfferRedeemable}
        offer={offer}
        onRedeemOffer={handleOfferRedeem}
        showRestaurantRedemptionButton={showRestaurantRedemptionButton}
        disableInRestaurantOfferRedemptions={
          offersInCooldown || disableInRestaurantOfferRedemptions || loadingOfferConfigs
        }
        disableMobileOfferRedemptions={offersInCooldown || disableMobileOfferRedemptions}
        showMobileRedemptionButton={showMobileRedemptionButton}
        redemptionErrorMessage={redemptionErrorMessage}
        enableOfferBadges={enableOfferBadges}
        endingIn={endingIn}
        platforms={platforms}
        showSignUpToRedeem={showSignUpToRedeem}
        restaurantRedemptionButtonUiType={restaurantRedemptionButtonUiType}
        noPossibleRedemption={noPossibleRedemption}
      />
      {showConfirmSwitchOffersDialog && (
        <ConfirmDialog
          showDialog={showConfirmSwitchOffersDialog}
          heading={formatMessage({ id: 'oneOfferPerOrder' })}
          body={formatMessage({ id: 'replaceOffer' })}
          confirmLabel={formatMessage({ id: 'yes' })}
          cancelLabel={formatMessage({ id: 'no' })}
          onConfirm={onConfirmSwitchOffers}
          onCancel={onCancelSwitchOffers}
          onDismiss={onCancelSwitchOffers}
          modalAppearanceEventMessage="Confirm Replace Coupon"
        />
      )}
      {showSwitchServiceModeDialog && (
        <ConfirmDialog
          showDialog={showSwitchServiceModeDialog}
          heading={formatMessage({
            id: isDelivery
              ? 'switchServiceModeToPickupHeader'
              : 'switchServiceModeToDeliveryHeader',
          })}
          body={formatMessage({
            id: isDelivery ? 'switchServiceModeToPickupBody' : 'switchServiceModeToDeliveryBody',
          })}
          confirmLabel={formatMessage({
            id: isDelivery
              ? 'switchServiceModeToPickupButton'
              : 'switchServiceModeToDeliveryButton',
          })}
          cancelLabel={formatMessage({ id: 'cancel' })}
          onConfirm={onConfirmSwitchServiceMode}
          onCancel={onCancelSwitchServiceMode}
          onDismiss={onCancelSwitchServiceMode}
          modalAppearanceEventMessage="Confirm Change Service Mode"
        />
      )}

      <ReadyToRedeemDialog
        testID="ready-to-redeem-dialog"
        heading={formatMessage({ id: 'couponWillExpire' })}
        body={formatMessage({ id: 'confirmDialogBody' })}
        buttonLabel={formatMessage({ id: 'redeem' })}
        showCloseButton
      />
      {showUpsellOffer && offer.upsellOptions?.length ? (
        <OfferUpsellModal
          parentId={offer._id}
          upsellOptions={offer.upsellOptions}
          onDismiss={handleUpsellOfferDiscard}
        />
      ) : null}
      {showRestaurantRedemptionModal && (
        <RestaurantRedemptionModal
          selectedOffer={offer}
          redemptionStartTime={restaurantRedemptionStartTime}
          onDismiss={onDismissRestaurantRedemption}
        />
      )}
    </>
  );
};
