import React, { PropsWithChildren, createContext, useContext } from 'react';

import { PermissionStatus } from 'expo-modules-core';
import {
  IosAuthorizationStatus,
  NotificationPermissionsStatus,
  usePermissions,
} from 'expo-notifications';

export interface IPushPermissionContext {
  isPushPermissionKnown: boolean;
  requestPermission: () => Promise<NotificationPermissionsStatus>;
  settings: NotificationPermissionsStatus | null;
  isLoadingPushPermission: boolean;
}

export const PushPermissionContext = createContext<IPushPermissionContext>(
  {} as IPushPermissionContext
);

export const usePushPermission = () => useContext<IPushPermissionContext>(PushPermissionContext);

export const PushPermissionProvider = ({ children }: PropsWithChildren<{}>) => {
  const [settings, requestPermission] = usePermissions();

  const value = {
    isPushPermissionKnown:
      !!settings &&
      settings?.status !== PermissionStatus.UNDETERMINED &&
      settings?.ios?.status !== IosAuthorizationStatus.NOT_DETERMINED,
    requestPermission,
    settings,
    isLoadingPushPermission: !settings,
  };

  return <PushPermissionContext.Provider value={value}>{children}</PushPermissionContext.Provider>;
};
