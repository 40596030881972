import React from 'react';

import { HStack } from '@rbilabs/universal-components';

import { IHorizontalLinkListWidgetFragment, ILinkWidgetFragment } from 'generated/sanity-graphql';

import { Links } from './links';

type IHorizontalLinkListProps = IHorizontalLinkListWidgetFragment;

export const HorizontalLinkList = (props: IHorizontalLinkListProps) => (
  <HStack mb="$6">
    <Links links={props.linkWidgets as ILinkWidgetFragment[]} isHorizontalLink />
  </HStack>
);
