import { Box, VStack, makeUclComponent } from '@rbilabs/universal-components';

import LoadingAnimation from 'components/loading-animation';

export const Container = VStack.withConfig({
  width: 'full',
  height: 'full',
  maxWidth: { md: Styles.layout.smallerSectionMaxWidth },
  marginTop: { md: Styles.layout.navHeight.desktop },
  marginBottom: { base: '$8', md: Styles.layout.navHeight.desktop },
  marginX: 'auto',
  _text: {
    textAlign: 'center',
  },
  _android: {
    marginBottom: { base: '$0' },
  },
});

export const Loading = makeUclComponent(LoadingAnimation).withConfig<{ $windowHeight?: number }>(
  ({ $windowHeight }) => ({
    ...($windowHeight && { minHeight: $windowHeight - 107 }),
  })
);

export const SpinnerContainer = Box.withConfig({
  justifyContent: 'center',
  height: '100%',
});
