import React, { useMemo } from 'react';

import { Box, Pressable, Text } from '@rbilabs/universal-components';
import { useIntl } from 'react-intl';

import { ActionButtonVariants } from 'components/action-button';

import { StyledRedemptionBox, StyledRedemptionButton } from './redemption-button.styled';
import theme from './theme';
import { IRedemptionButton, RestaurantRedemptionButtonUiOption } from './types';

const RestaurantOfferLink = Box.withConfig({
  flexDirection: 'row',
  minWidth: '234px',
  marginTop: {
    base: '4',
    md: '8',
  },
});

const RestaurantOfferLinkText = Text.withConfig({
  variant: 'copyOne',
  margin: 0,
  color: theme.pCssColor,
  textAlign: 'center',
  fontSize: 'xs',
});

export const RedemptionButton: React.FC<React.PropsWithChildren<IRedemptionButton>> = ({
  onPressRestaurantRedemption,
  restaurantRedemptionButtonTitle,
  restaurantRedemptionButtonUiType,
  disabled,
}) => {
  const { formatMessage } = useIntl();
  const clickHereText = formatMessage({ id: 'restaurantRedemptionClickHere' });
  const restaurantRedemptionText = useMemo(
    () => (
      <>
        <RestaurantOfferLinkText>
          {formatMessage(
            { id: 'restaurantRedemption' },
            {
              clickHere: (
                <Pressable
                  accessibilityRole="button"
                  onPress={onPressRestaurantRedemption}
                  testID="restaurant-redemption-button"
                >
                  <RestaurantOfferLinkText textDecorationLine="underline">
                    {clickHereText}
                  </RestaurantOfferLinkText>
                </Pressable>
              ),
            }
          )}
        </RestaurantOfferLinkText>
      </>
    ),
    [clickHereText, formatMessage, onPressRestaurantRedemption]
  );

  return restaurantRedemptionButtonUiType === RestaurantRedemptionButtonUiOption.BUTTON ? (
    <StyledRedemptionBox>
      <StyledRedemptionButton
        testID="restaurant-redemption-button"
        onPress={onPressRestaurantRedemption}
        disabled={disabled}
        variant={ActionButtonVariants.OUTLINE}
      >
        {restaurantRedemptionButtonTitle}
      </StyledRedemptionButton>
    </StyledRedemptionBox>
  ) : (
    <RestaurantOfferLink>{restaurantRedemptionText}</RestaurantOfferLink>
  );
};
