import { gql } from '@apollo/client';

import ItemFragment from './fragments/item';

export default gql`
  query allItems($where: ItemFilter) {
    allItems(where: $where) {
      ... on Item {
        ...ItemFragment
        description {
          localeRaw: _LANG_Raw
        }
      }
    }
  }
  ${ItemFragment}
`;
