import React, { FC } from 'react';

import { useIntl } from 'react-intl';

import { RecentItemsCarousel } from 'components/recent-items-carousel';
import { ResponsiveCarouselWrapper } from 'components/responsive-carousel';
import { useIsMobileBp } from 'hooks/breakpoints';
import { useRecentItemsAvailable } from 'hooks/use-recent-items-available';
import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';
import { relativeRoutes, routes } from 'utils/routing';

export const RecentItemsSection: FC<React.PropsWithChildren<unknown>> = () => {
  const showRecentItems = useFlag(LaunchDarklyFlag.ENABLE_HOME_PAGE_RECENT_ITEMS);
  const { formatMessage } = useIntl();
  const isMobile = useIsMobileBp();
  const enableTabbedMenuBarRecents = useFlag(LaunchDarklyFlag.ENABLE_TABBED_MENU_BAR_RECENTS);

  const shouldShow = useRecentItemsAvailable();
  const locationName = 'Home page';
  const shouldDisplayRecentItems = showRecentItems && shouldShow;

  const viewAllRedirect =
    isMobile && enableTabbedMenuBarRecents
      ? `${routes.menu}/${relativeRoutes.recentItems}`
      : routes.orders;

  const header = formatMessage({ id: 'recentItems' });
  const recentItemsText = formatMessage({ id: 'viewAll' });
  const recentItemsAccessibilityLabel = formatMessage({ id: 'viewAllRecentItems' });

  if (shouldDisplayRecentItems) {
    return (
      <ResponsiveCarouselWrapper
        testID="recentItemsContainer"
        header={header}
        linkText={recentItemsText}
        linkPath={viewAllRedirect}
        linkAccessibilityLabel={recentItemsAccessibilityLabel}
        mt="$4"
        px="$4"
      >
        <RecentItemsCarousel containerHasTopPadding={false} locationName={locationName} />
      </ResponsiveCarouselWrapper>
    );
  }
  return null;
};
