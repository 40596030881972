import { ApolloLink, Observable } from '@apollo/client';
import { launchArguments } from 'expo-launch-arguments';

export const withDetoxOverrides = (): ApolloLink =>
  new ApolloLink((operation, forward) => {
    try {
      const queryOverrides = JSON.parse(launchArguments.graphql);

      if (queryOverrides[operation.operationName]) {
        return new Observable(observer => {
          observer.next(JSON.parse(queryOverrides[operation.operationName]));
          observer.complete();
        });
      }

      return forward(operation);
    } catch (e) {
      return forward(operation);
    }
  });
