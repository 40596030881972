import React from 'react';

import { Dimensions } from 'react-native';

import { BackArrowHistory } from 'components/navigation/back-arrow';
import { useIsTabletBp } from 'hooks/breakpoints';
import { useNavigation } from 'hooks/navigation/use-navigation';
import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';
import { useMenuContext } from 'state/menu';
import { useProductWizardFlow } from 'state/product-wizard/hooks/use-product-wizard-flow';
import { ProductWizardStep } from 'state/product-wizard/types';
import { MENU_PRODUCT_PAGE } from 'utils/test-ids';

import { ProductLayout } from './product-layout/product-layout';
import { LayoutContainer } from './styled';

// TODO: RN - Add back button on navigation
const ProductDetail: React.FC<React.PropsWithChildren<unknown>> = () => {
  const { goBack } = useNavigation();
  const isTablet = useIsTabletBp();
  const { showStaticMenu } = useMenuContext();
  const { currentStep } = useProductWizardFlow();
  const useEnableShowPickerAspectsInStaticMenu = useFlag(
    LaunchDarklyFlag.ENABLE_SHOW_PICKER_ASPECTS_IN_STATIC_MENU
  );

  const handleBackButtonClick = () => {
    goBack();
  };

  const windowHeight = Dimensions.get('window').height;

  return (
    <LayoutContainer
      // @ts-expect-error TS(2322) FIXME: Type '{ children: (false | Element)[]; $isStatic: ... Remove this comment to see the full error message
      $isStatic={showStaticMenu && !useEnableShowPickerAspectsInStaticMenu}
      $windowHeight={windowHeight}
      testID={MENU_PRODUCT_PAGE}
    >
      {!isTablet && currentStep !== ProductWizardStep.ProductCustomization && (
        <BackArrowHistory onPress={handleBackButtonClick} />
      )}
      <ProductLayout />
    </LayoutContainer>
  );
};

export default ProductDetail;
