import { launchArguments } from 'expo-launch-arguments';

import LocalStorage, { StorageKeys } from 'utils/local-storage';

export function injectLaunchArguments() {
  if (!launchArguments.DETOX) {
    return;
  }

  LocalStorage.setCurrentVersion();

  LocalStorage.setItem(StorageKeys.USER_AUTH_TOKEN, null);

  if (launchArguments.store) {
    LocalStorage.setItem(StorageKeys.STORE, JSON.parse(launchArguments.store));
    LocalStorage.setItem(StorageKeys.SERVICE_MODE, { serviceMode: 'TAKEOUT' });
    LocalStorage.setItem(StorageKeys.LAST_TIME_STORE_UPDATED, Date.now());
  }

  if (launchArguments.order) {
    LocalStorage.setItem(StorageKeys.ORDER, JSON.parse(launchArguments.order));
  }
}
