import * as React from 'react';
import { FC } from 'react';

import { ISanityTextNode } from '@rbi-ctg/menu';
import LoadingAnimation from 'components/loading-animation';
import {
  ItemWidth,
  ResponsiveCarousel,
  ResponsiveCarouselWrapper,
} from 'components/responsive-carousel';
import { useIsMobileBp } from 'hooks/breakpoints';
import { useLoyaltyContext } from 'state/loyalty';
import { NotificationDetails } from 'state/loyalty/hooks/types';
import { useLoyaltyNotifications } from 'state/loyalty/hooks/use-loyalty-notifications';
import { useLoyaltyUser } from 'state/loyalty/hooks/use-loyalty-user';

import LoyaltyNotificationCard from './loyalty-notification-card';
import { LoadingContainer } from './loyalty-notifications-widget.styled';

const loyaltyItemTitleLocaleExtractor = (item: NotificationDetails, index: number) =>
  item?.title?.locale || index.toString();

const loyaltyCardRenderer = ({ item }: { item: NotificationDetails }) => (
  <LoyaltyNotificationCard notification={item} />
);

/**
 *
 * LoyaltyNotificationsWidget shows a section with loyalty notifications
 *
 */
const LoyaltyNotificationsWidget: FC<React.PropsWithChildren<{
  title: ISanityTextNode;
}>> = ({ title }) => {
  const { loyaltyUser } = useLoyaltyUser();
  const { loyaltyOffersEnabled } = useLoyaltyContext();
  const { notifications, loadingNotifications } = useLoyaltyNotifications(
    loyaltyUser,
    loyaltyOffersEnabled
  );
  const isMobile = useIsMobileBp();
  const itemWidth = isMobile
    ? ItemWidth.MARKETING_CAROUSEL_ITEM_MOBILE
    : ItemWidth.MARKETING_CAROUSEL_ITEM_WEB;

  if (loadingNotifications) {
    return (
      <LoadingContainer testID="widget-loader">
        <LoadingAnimation />
      </LoadingContainer>
    );
  }

  if (!notifications.length) {
    return null;
  }

  return (
    <ResponsiveCarouselWrapper header={title.locale} background="transparent">
      <ResponsiveCarousel
        centerCards
        bgColor="transparent"
        data={notifications}
        itemWidth={itemWidth}
        keyExtractor={loyaltyItemTitleLocaleExtractor}
        renderItem={loyaltyCardRenderer}
        loop={false}
      />
    </ResponsiveCarouselWrapper>
  );
};

export default LoyaltyNotificationsWidget;
