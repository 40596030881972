import React from 'react';

import { useIntl } from 'react-intl';

import { ICartEntry } from '@rbi-ctg/menu';
import { VisuallyHidden } from 'components/ucl/visually-hidden';
import useAddToCartRecentItem from 'hooks/use-add-to-cart-recent-item';

import { AddIcon, IconButton } from './styled';

interface AddToCartProps {
  disabled: boolean;
  cartEntry: ICartEntry;
}

const AddToCart: React.FC<React.PropsWithChildren<AddToCartProps>> = ({ disabled, cartEntry }) => {
  const { formatMessage } = useIntl();
  const { addToCart, SwitchCartMethodDialog } = useAddToCartRecentItem({
    cartEntry,
  });

  return (
    <IconButton disabled={disabled} testID="add-to-cart" onPress={addToCart}>
      <VisuallyHidden accessibilityLabel={formatMessage({ id: 'addToCart' })} />
      <AddIcon isDisabled={disabled} />
      <SwitchCartMethodDialog />
    </IconButton>
  );
};

export default AddToCart;
