import { Box, Pressable } from '@rbilabs/universal-components';

export const StyledPressable = Pressable.withConfig({
  margin: '$4',
  padding: '$4',
  backgroundColor: Styles.color.cardBackground,
  borderRadius: 8,
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
});

export const IconContainer = Box.withConfig({
  padding: '$1.5',
  marginRight: '$3',
  backgroundColor: '__legacyToken.icon-active',
  borderRadius: 'full',
});
