import React from 'react';

import { Box, Icon, ScrollView, Text, makeUclComponent } from '@rbilabs/universal-components';
import { isNil } from 'lodash-es';
import { useIntl } from 'react-intl';
import { isFalse } from 'utils';

import { ArrowLink } from 'components/arrow-link';
import { Helmet } from 'components/helmet';
import LanguageSelector from 'components/language-selector';
import AccountLayout from 'components/layout/account';
import Link from 'components/link';
import { BackArrowHistory } from 'components/navigation/back-arrow';
import { VerifyEmailMessage } from 'components/verify-email-message';
import useAuth from 'hooks/auth/use-auth';
import useAuthRedirects from 'hooks/auth/use-auth-redirects';
import { useIsDesktopBp, useIsMobileBp } from 'hooks/breakpoints';
import { useRumPageView } from 'hooks/rum/use-rum-page-view';
import { useFeatureNavigation } from 'hooks/use-feature-navigation';
import LoyaltyCardTile from 'pages/loyalty/loyalty-card-tile/loyalty-card-tile';
import { useAuthContext } from 'state/auth';
import { useLocale } from 'state/intl';
import { PROD_SUPPORTED_REGIONS, regionSupportedLanguages } from 'state/intl/constants';
import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';
import { MyAccountSections, defaultMyAccountSections } from 'state/launchdarkly/variations';
import { theme } from 'styles/configure-theme';
import { routes } from 'utils/routing';
import { DRAWER_ACCOUNT_INFO_BUTTON } from 'utils/test-ids';

import {
  ACCOUNT_DISPLAY_SIGN_UP_HEADLINE,
  ACCOUNT_LINKS_BORDER_COLOR,
  ACCOUNT_LINKS_DISPLAY_SECTION_HEADINGS,
  isAccountActionsEnabled,
} from './constants';
import CreateAccount from './create-account';
import Disclaimer from './disclaimer';
import ListOfLinks from './list-of-links';
import RewardsLink from './rewards-link';
import SubmitFeedbackLink from './submit-feedback-link';

const AccountAction = Link.withConfig({
  flexDirection: 'row',
  textDecoration: 'none',
  marginY: '2',
  alignItems: 'center',
});

const StyledIcon = Box.withConfig({});

const StyledIconClose = Icon.withConfig({
  variant: 'cancel',
  color: theme.token('icon-default'),
});

const StyledBackArrowHistory = makeUclComponent(BackArrowHistory).withConfig({
  margin: '2',
});

const AccountLanguageSelectorContainer = Box.withConfig({
  borderBottomWidth: '1px',
  borderBottomColor: theme.token('border-color-default'),
  paddingBottom: '4',
});

const LanguageSelectIntro = Text.withConfig({
  textTransform: 'none',
});

const ActionsWrapper = Box.withConfig({
  borderBottomWidth: '1px',
  borderBottomColor: ACCOUNT_LINKS_BORDER_COLOR,
  paddingBottom: '4',
});

const AccountLinksWrapper = Box.withConfig({
  marginBottom: '7',
});

const Account = () => {
  const { formatMessage } = useIntl();
  const { user } = useAuthContext();
  const enableAutoSignInOnSignUp = useFlag(LaunchDarklyFlag.ENABLE_AUTO_SIGN_IN_ON_SIGN_UP);
  const enableRequestMyInformation = useFlag(LaunchDarklyFlag.ENABLE_REQUEST_MY_INFORMATION);
  const enableOrderingFlag = useFlag(LaunchDarklyFlag.ENABLE_ORDERING);
  const enableOrdering = isNil(enableOrderingFlag) || enableOrderingFlag;
  const enableAccountDeletePage = useFlag(LaunchDarklyFlag.ENABLE_ACCOUNT_DELETE_PAGE);
  const enableCommPrefFlag = useFlag(LaunchDarklyFlag.ENABLE_COMMUNICATION_PREFERENCES);
  const isLocalizationDisabled = useFlag(LaunchDarklyFlag.DISABLE_LOCALIZATION);
  const enableAppIssueCollector = useFlag(LaunchDarklyFlag.ENABLE_APP_ISSUE_COLLECTOR);
  const enableDiagnosticTools = useFlag(LaunchDarklyFlag.ENABLE_DIAGNOSTIC_TOOLS);
  const enableNativeMobileAccount = useFlag(LaunchDarklyFlag.ENABLE_NATIVE_MOBILE_ACCOUNT);
  const enableFindRestaurantLink = useFlag(LaunchDarklyFlag.ENABLE_FIND_RESTAURANT_LINK);
  const isMobile = useIsMobileBp();
  const enableCommunicationPreferences = !isFalse(enableCommPrefFlag);
  const { isAuthenticated } = useAuth();

  useRumPageView('account', 'Account');

  const showAboutMenuLinks = enableNativeMobileAccount;
  useAuthRedirects({
    skip: showAboutMenuLinks,
  });

  const { featureAboutMenuEntriesMobile: aboutMenuEntries }: any = useFeatureNavigation();

  const { region } = useLocale();

  const hasMultipleRegions = Object.keys(PROD_SUPPORTED_REGIONS).length > 1;
  const hasMultipleLanguagesForRegion = region && regionSupportedLanguages[region]?.size > 1;
  const showLanguageSelector =
    !isLocalizationDisabled && (hasMultipleRegions || hasMultipleLanguagesForRegion);

  const enableSignUpFlag = useFlag(LaunchDarklyFlag.ENABLE_SIGN_UP);
  const enableSignUp = isNil(enableSignUpFlag) || enableSignUpFlag;
  const enabledAccountSections =
    useFlag<MyAccountSections>(LaunchDarklyFlag.ENABLE_ACCOUNT_SECTIONS_VARIATIONS) ||
    defaultMyAccountSections;

  const isMobileAccountSignUp = isMobile && ACCOUNT_DISPLAY_SIGN_UP_HEADLINE;
  const showCreateAccount = isMobileAccountSignUp && !isAuthenticated;
  const showSignUpLink = !isMobileAccountSignUp && !isAuthenticated;
  const showEarnPointsLink = isMobile && enabledAccountSections.earnPoints;
  const isHamburgerMenuEnabled = useFlag(LaunchDarklyFlag.ENABLE_HAMBURGER_MENU_ON_MOBILE);
  const isDesktop = useIsDesktopBp();

  if (!showAboutMenuLinks && !isAuthenticated) {
    return <Box />;
  }

  return (
    <ScrollView showsVerticalScrollIndicator={false} flex={1}>
      <Helmet title={formatMessage({ id: 'myAccount' })} />
      <AccountLayout>
        {isHamburgerMenuEnabled && <StyledBackArrowHistory icon={<StyledIconClose />} />}
        <ScrollView>
          {isAuthenticated && (
            <AccountLinksWrapper>
              {enableAutoSignInOnSignUp && user?.details.emailVerified === false && (
                <VerifyEmailMessage />
              )}
              <RewardsLink />
              {enabledAccountSections.accountInfo && (
                <ArrowLink to={routes.info} testID={DRAWER_ACCOUNT_INFO_BUTTON}>
                  {formatMessage({ id: 'accountInfo' })}
                </ArrowLink>
              )}
              {showEarnPointsLink && (
                <ArrowLink to={routes.redeem} testID="earn-points">
                  {formatMessage({ id: 'earnPoints' })}
                </ArrowLink>
              )}
              {enabledAccountSections.paymentMethods && enableOrdering && (
                <ArrowLink to={routes.payment} testID="payment-methods">
                  {formatMessage({ id: 'paymentMethods' })}
                </ArrowLink>
              )}
              {enabledAccountSections.recentOrders && enableOrdering && (
                <ArrowLink to={routes.orders} testID="recent-orders">
                  {formatMessage({ id: 'recentOrders' })}
                </ArrowLink>
              )}
              {enableCommunicationPreferences && (
                <ArrowLink testID="communication-preferences" to={routes.communication}>
                  {formatMessage({ id: 'communicationPreferences' })}
                </ArrowLink>
              )}
              {enabledAccountSections.submitFeedback && <SubmitFeedbackLink />}
              {enableRequestMyInformation && (
                <ArrowLink to={formatMessage({ id: 'routes.accountRequestInfo' })}>
                  {formatMessage({ id: 'requestMyInformation' })}
                </ArrowLink>
              )}
              {enableAccountDeletePage && (
                <ArrowLink to={formatMessage({ id: 'routes.accountDelete' })}>
                  {formatMessage({ id: 'deleteAccount' })}
                </ArrowLink>
              )}
              <LoyaltyCardTile />
            </AccountLinksWrapper>
          )}

          {showAboutMenuLinks && !isDesktop && (
            <>
              {showLanguageSelector && (
                <AccountLanguageSelectorContainer>
                  <LanguageSelectIntro mb="$2">
                    {formatMessage({ id: 'languageAndRegion' })}
                  </LanguageSelectIntro>
                  <LanguageSelector color={theme.token('text-default')} />
                </AccountLanguageSelectorContainer>
              )}

              {isAccountActionsEnabled(enableSignUp) && (
                <ActionsWrapper>
                  {showCreateAccount && <CreateAccount />}
                  {showSignUpLink && (
                    <AccountAction testID="signup-link" to={routes.signUp}>
                      <>
                        <StyledIcon>
                          <Icon
                            variant="account"
                            color={theme.token('icon-default')}
                            width="24px"
                            aria-hidden
                          />
                        </StyledIcon>
                        <Text marginLeft="1">{formatMessage({ id: 'signInOrCreateAccount' })}</Text>
                      </>
                    </AccountAction>
                  )}
                  {enableFindRestaurantLink && (
                    <AccountAction to={routes.storeLocator}>
                      <>
                        <StyledIcon>
                          <Icon
                            variant="location"
                            color={theme.token('icon-default')}
                            width="24px"
                            aria-hidden
                          />
                        </StyledIcon>
                        <Text bold>{formatMessage({ id: 'findARestaurant' })}</Text>
                      </>
                    </AccountAction>
                  )}
                </ActionsWrapper>
              )}

              {aboutMenuEntries && (
                <ListOfLinks
                  testID="links-list"
                  title={aboutMenuEntries.name?.locale || ''}
                  entries={aboutMenuEntries.pages || []}
                  borderColor={ACCOUNT_LINKS_BORDER_COLOR}
                  sectionHeadings={ACCOUNT_LINKS_DISPLAY_SECTION_HEADINGS}
                />
              )}
              <Disclaimer />
              {enableDiagnosticTools && (
                <Link to={routes.diagnostics}>
                  <Text>{formatMessage({ id: 'diagnostics' })}</Text>
                </Link>
              )}
              {enableAppIssueCollector && (
                <Link to={routes.issueCollector} marginTop="2">
                  <Text>Issue Collector</Text>
                </Link>
              )}
            </>
          )}
        </ScrollView>
      </AccountLayout>
    </ScrollView>
  );
};

export default Account;
