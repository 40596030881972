import * as React from 'react';
import { FC } from 'react';

import { secondsToMinutes } from 'date-fns';

import ActionButton from 'components/action-button';
import Dialog from 'components/dialog';
import { useLoyaltyOffersUiCooldownContentQuery } from 'generated/sanity-graphql';
import { useNavigation } from 'hooks/navigation/use-navigation';
import { useRoute } from 'hooks/navigation/use-route';
import { areLoyaltyOffersInCooldown } from 'hooks/use-are-loyalty-offers-in-cooldown';
import { useLoyaltyOffersCooldownCountdown } from 'hooks/use-loyalty-offers-cooldown-countdown';
import { selectors, useAppSelector } from 'state/global-state';
import { maybeLocale, maybeLocaleRaw } from 'utils/graphql';
import { routes } from 'utils/routing';

import { interpolateSanityKey } from '../utils';

const LoyaltyOffersCooldownDrawer: FC<React.PropsWithChildren<unknown>> = () => {
  const { goBack } = useNavigation();
  const { pathname } = useRoute();
  const cooldownCountdown = useLoyaltyOffersCooldownCountdown();
  const loyaltyOffersInCooldown = areLoyaltyOffersInCooldown(cooldownCountdown);
  const { data: cooldownContentData } = useLoyaltyOffersUiCooldownContentQuery({
    variables: {
      id: 'feature-loyalty-offers-ui-singleton',
    },
    skip: !loyaltyOffersInCooldown,
  });

  const offerCooldownContent = cooldownContentData?.LoyaltyOffersUI?.globalOfferCooldownContent;

  const cooldownContent = {
    image: maybeLocale(offerCooldownContent?.image),
    title: maybeLocale(offerCooldownContent?.title) || '',
    description: maybeLocaleRaw(offerCooldownContent?.description) || '',
    buttonText: maybeLocale(offerCooldownContent?.actionButton?.buttonText) || '',
    buttonUrl: maybeLocale(offerCooldownContent?.actionButton?.buttonPath) || '',
    termsText: maybeLocaleRaw(offerCooldownContent?.terms),
  };

  const isOffersPage = pathname.includes(routes.offers);
  const isAvailableAfterTimestamp = useAppSelector(
    selectors.loyalty.selectOfferRedemptionAvailableAfter
  );
  const showCooldown = Boolean(
    loyaltyOffersInCooldown && isOffersPage && isAvailableAfterTimestamp
  );

  return (
    <Dialog
      showDialog={showCooldown}
      heading={cooldownContent.title}
      body={interpolateSanityKey(
        cooldownContent.description,
        'REMAINING_MINUTES',
        secondsToMinutes(cooldownCountdown)
      )}
      actions={
        <ActionButton fullWidth onPress={() => goBack()}>
          {cooldownContent.buttonText}
        </ActionButton>
      }
      modalAppearanceEventMessage="Cooldown reminder Modal"
    />
  );
};

export default LoyaltyOffersCooldownDrawer;
