import React from 'react';

import { Header as BaseHeader, Box, Text } from '@rbilabs/universal-components';

import ActionLink from 'components/action-button';
import BrandIcon from 'components/brand-icon';

export const Container = Box.withConfig({
  alignItems: 'center',
  flexDirection: 'column',
  width: '5/6',
  maxWidth: {
    md: Styles.layout.smallerSectionMaxWidth,
  },
});

const Header = BaseHeader.withConfig({
  textAlign: 'center',
  color: Styles.color.grey.one,
  fontFamily: 'heading',
  fontSize: '3xl',
  lineHeight: '2xs',
  marginBottom: '$0',
  textTransform: 'uppercase',
});

const Subheader = Text.withConfig({
  textAlign: 'center',
  marginTop: '$4',
  marginX: '$0',
  marginBottom: '$8',
});

interface IEmptyStateProps {
  header: string;
  subheader: string;
  linkText: string;
  linkTo: string;
  linkFullWidth?: boolean;
}

const EmptyState = ({
  header,
  subheader,
  linkText,
  linkTo,
  linkFullWidth = false,
}: IEmptyStateProps) => {
  return (
    <Container>
      <BrandIcon color={Styles.color.primary} width="$24" />
      <Header>{header}</Header>
      <Subheader>{subheader}</Subheader>
      <ActionLink to={linkTo} fullWidth={linkFullWidth}>
        {linkText}
      </ActionLink>
    </Container>
  );
};

export default EmptyState;
