import { Box, HStack, Pressable, Text } from '@rbilabs/universal-components';

import { theme } from 'styles/configure-theme';

export const Container = HStack.withConfig<{ reversedUI: boolean }>(p => ({
  alignItems: 'center',
  background: p.reversedUI
    ? theme.token('background-hero-reversed')
    : theme.token('background-hero-light'),
}));

export const ClickableWrapper = Pressable.withConfig({
  flexDirection: 'row',
  alignItems: 'center',
  height: '$9',
  flexGrow: 1,
  flexShrink: 1,
  flexBasis: 'auto',

  _web: {
    cursor: 'pointer',
  },
});

export const IconContainer = Box.withConfig({
  marginLeft: '$5',
  marginRight: '$2',
});

export const DetailsContainer = HStack.withConfig({
  alignItems: 'center',
});

export const Heading = Text.withConfig<{ reversedUI: boolean }>(p => ({
  fontFamily: 'heading',
  numberOfLines: 1,
  fontSize: 'md',
  color: p.reversedUI ? theme.token('text-reversed') : theme.token('text-default'),
}));

export const ButtonWrapper = Box.withConfig({
  justifyContent: 'flex-end',
  marginRight: '$4',
});

export const Details = Text.withConfig<{ reversedUI: boolean }>(p => ({
  flex: 1,
  overflow: 'hidden',
  paddingX: '$2',
  fontSize: 'xs',
  backgroundColor: 'red',
  color: p.reversedUI ? theme.token('text-reversed') : theme.token('text-default'),
}));
