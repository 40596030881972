import { Pressable, Text } from '@rbilabs/universal-components';

import { primitive } from 'styles/constants/primitives';

import { ContainerProps } from './types';

export const TextWithArrowButtonRoot = Pressable.withConfig<ContainerProps>(p => ({
  borderBottomWidth: p.withBorder ? '1px' : 0,
  borderColor: primitive.$blackOpacity10,
  alignItems: 'center',
  flexDirection: 'row',
  justifyContent: 'space-between',
  paddingY: '$4',
}));

export const Label = Text.withConfig({
  variant: 'copyOne',
  fontSize: 'md',
});
