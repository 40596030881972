import React, { FC, useCallback, useMemo } from 'react';

import {
  Checkpoint,
  CheckpointDetail,
  Circle,
  CircleDot,
  Label,
  Segment,
  Stepper,
} from './locked-offers-card.styled';
import { IOfferStepperProps } from './types';

export const OfferStepper: FC<React.PropsWithChildren<IOfferStepperProps>> = ({
  steps = [],
  stepsCompleted,
}) => {
  const calcSegmentPercentage = useCallback(
    (index: number) => {
      // None completed all segments are empty
      if (stepsCompleted === 0) {
        return 0;
      }
      // Case when challenge completed or segment must display full progress to next marker
      if (stepsCompleted === steps.length || index < stepsCompleted - 1) {
        return 100;
      }
      // When more challenges remain and display leading progress to potential next marker
      if (index === stepsCompleted - 1) {
        return 50;
      }
      // Empty remaining challenges
      return 0;
    },
    [steps.length, stepsCompleted]
  );

  const stepperDisplay = useMemo(
    () =>
      steps.map((step, index, { length: totalSteps }) => (
        <React.Fragment key={`${index}${step.isComplete}`}>
          <Checkpoint>
            <CheckpointDetail>
              <Label>{index + 1}</Label>
              {step.isComplete ? <Circle /> : <CircleDot testID="circle-dot-marker" />}
            </CheckpointDetail>
          </Checkpoint>
          {totalSteps - 1 !== index && (
            <Segment percentComplete={calcSegmentPercentage(index)} totalSteps={totalSteps} />
          )}
        </React.Fragment>
      )),
    [calcSegmentPercentage, steps]
  );

  return <Stepper>{stepperDisplay}</Stepper>;
};
