import { useCallback } from 'react';

import { useNavigation } from 'hooks/navigation/use-navigation';
import { useRoute } from 'hooks/navigation/use-route';
import { appendObjectToQueryString } from 'hooks/navigation/utils';
import {
  IProductWizardFlow,
  IProductWizardParams,
  ProductWizardStep,
} from 'state/product-wizard/types';

export const useProductWizardFlow = (): IProductWizardFlow => {
  const { linkTo } = useNavigation();
  const { pathname, params } = useRoute<IProductWizardParams>();

  const {
    id = '',
    isEdit = false,
    isFavorite = false,
    isFromCart = false,
    isFromSurprise = false,
    rewardBenefitId,
    rewardId,
    currentStep = ProductWizardStep.ProductHome,
    slotKeyToCustomize = '',
  } = params || {};

  const navigateToStep = useCallback(
    (step: ProductWizardStep, slotKey?: string) => {
      const currentRouteWithoutQS = pathname.split('?')[0];
      const updatedParams = {
        ...params,
        currentStep: step,
        slotKeyToCustomize: slotKey,
      };
      const targetRoute = appendObjectToQueryString(currentRouteWithoutQS, updatedParams);
      linkTo(targetRoute, { replace: true });
    },
    [linkTo, params, pathname]
  );

  const goToHome = useCallback(() => {
    navigateToStep(ProductWizardStep.ProductHome);
  }, [navigateToStep]);

  const goToCustomizeProduct = useCallback(
    (slotKey: string) => {
      navigateToStep(ProductWizardStep.ProductCustomization, slotKey);
    },
    [navigateToStep]
  );

  return {
    id,
    isEdit,
    isFavorite,
    isFromCart,
    isFromSurprise,
    rewardBenefitId,
    rewardId,
    currentStep: Number(currentStep),
    slotKeyToCustomize,
    goToHome,
    goToCustomizeProduct,
  };
};

export default useProductWizardFlow;
