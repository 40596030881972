import React, { FC } from 'react';

import { Badge, Box } from '@rbilabs/universal-components';
import { useIntl } from 'react-intl';

import BonusPoints from 'components/bonus-points/bonus-points';
import Picture from 'components/picture';
import { productImageProps } from 'components/product-image/constants';
import { useLoyaltyUserPromotions } from 'state/loyalty/hooks/use-loyalty-promotions';
import { parseStringifiedJSON } from 'utils/parse-string';

import { PriceAndCalories } from './price-and-calories';
import { ProductSkeleton } from './product.skeleton';
import {
  DetailsContainer,
  ImageContainer as ImageContainerBase,
  Layout,
  ProductDescription,
  ProductName,
  Wrapper,
} from './product.styled';
import SelectionsList from './selections-list';
import { ProductProps } from './types';

/**
 *
 * Product is used to display a compact product to guests
 * Anywhere we need to show a menu item outside the menu we should use this component
 *
 */
const Product: FC<React.PropsWithChildren<ProductProps>> = ({
  image,
  fallbackImage,
  name,
  price,
  calories,
  note,
  selections,
  expandable = true,
  quantity,
  loading = false,
  displayMode = 'list',
  imageSize,
  isProductNameBold = false,
  description,
  promotion,
}) => {
  const { formatMessage } = useIntl();
  const { availablePromotionsMap } = useLoyaltyUserPromotions();
  const shouldShowBonusPointsBadge =
    !!promotion?.loyaltyEngineId && !!availablePromotionsMap[promotion.loyaltyEngineId];

  // Should not render the product if the name is empty and is not loading
  if (!name && !loading) {
    return null;
  }

  const isInlineLayout = displayMode === 'list' || !!selections?.length;

  return (
    <Wrapper testID="product">
      {loading ? (
        <ProductSkeleton />
      ) : (
        <Layout $inline={isInlineLayout}>
          {(image || fallbackImage) && (
            <ImageContainerBase key={name} $size={imageSize} testID="product-image">
              {image && (
                <Picture
                  image={parseStringifiedJSON({ value: image })}
                  alt={`${formatMessage({ id: 'product' })} ${name}`}
                  {...productImageProps}
                />
              )}
              {!image &&
                fallbackImage &&
                fallbackImage({
                  width: 55,
                  height: 55,
                })}
              {quantity && (
                <Badge
                  position="absolute"
                  top="-3px"
                  left="-10px"
                  variant="number-solid"
                  accessibilityLabel={formatMessage({ id: 'xItems' }, { xItems: quantity })}
                >
                  {`${quantity}x`}
                </Badge>
              )}
            </ImageContainerBase>
          )}
          <DetailsContainer>
            {note}
            <ProductName testID="product-name" $isProductNameBold={isProductNameBold}>
              {name}
            </ProductName>
            {description && <ProductDescription>{description}</ProductDescription>}
            <PriceAndCalories price={price} calories={calories} />
            {selections && (
              <SelectionsList selections={selections} id={name || ''} expandable={expandable} />
            )}

            {shouldShowBonusPointsBadge && (
              <Box marginTop="2">
                <BonusPoints bonusPoints={promotion?.bonusPoints} />
              </Box>
            )}
          </DetailsContainer>
        </Layout>
      )}
    </Wrapper>
  );
};

export default Product;
