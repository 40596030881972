import React from 'react';

import { useIsDesktopBp } from 'hooks/breakpoints';
import { useRoute } from 'hooks/navigation/use-route';
import { isItemPickerOrComboRoute, routes } from 'utils/routing';

import FooterContent from './footer';

const Footer = () => {
  const isDesktop = useIsDesktopBp();
  const { pathname } = useRoute();
  const isCart = pathname.startsWith(routes.cart);
  const isMenuItem = isItemPickerOrComboRoute(pathname);
  const orderConfirmation = pathname.startsWith(routes.orderConfirmation);

  // hiding footer on Mobile and Desktop small
  // on product or cart page
  if (!isDesktop || isMenuItem || isCart || orderConfirmation) {
    return null;
  }

  return <FooterContent />;
};

export default Footer;
