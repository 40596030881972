import { isNil } from 'lodash-es';

import { sumModifierNutrition } from 'components/nutrition/utils';
import { MenuObjectTypes } from 'enums/menu';
import { NutritionExplorerMenuObject } from 'remote/queries/fragments/nutrition-explorer-menu';
import {
  ComparisonOperator,
  ConditionType,
  ISanityFilter,
  ISanityFilterGroup,
} from 'remote/queries/fragments/nutrition-explorer-widget';
import { filterMap } from 'utils/array';
import logger from 'utils/logger';
import { computeSelectedOption, defaultPickerAspect } from 'utils/menu';

import {
  IFilter,
  IFilterGroup,
  IGetParsedMenuDataProps,
  IGetProductsForComboProps,
  IGetProductsForItemProps,
  IGetProductsForMenuObjectProps,
  IGetProductsForPickerProp,
  IGetProductsForSectionProps,
  IProduct,
  NutritionExplorerMenuObjectProductsTuple,
} from './types';

enum SanityTypename {
  Nutrition = 'Nutrition',
  Allergen = 'OpAllergen',
}

export const getFilter = (sanityFilter: ISanityFilter): IFilter => ({
  ...sanityFilter,
  _id: `${sanityFilter._type}-${sanityFilter.description?.locale}`,
});

export const getFilterGroup = (sanityFilterGroup: ISanityFilterGroup): IFilterGroup => ({
  ...sanityFilterGroup,
  _id: `${sanityFilterGroup._type}-${sanityFilterGroup.description?.locale}`,
  filters: sanityFilterGroup.filters.map(getFilter),
});

const isValidAllergenItem = (allergen: any) =>
  allergen != null &&
  // need to ignore the `__typename` otherwise this would always will be `true`
  allergen !== SanityTypename.Allergen;

const isValidNutritionItem = (nutritionItem: any) =>
  Boolean(nutritionItem) &&
  // need to ignore the `__typename` otherwise this would always will be `true`
  nutritionItem !== SanityTypename.Nutrition;

const getProductsForSection = ({
  data,
  enableNutritionExplorerAllergens,
  displayNutritionWithModifiersFromSanity,
}: IGetProductsForSectionProps): IProduct[] => {
  return data.options.reduce<IProduct[]>(
    (products, option) =>
      products.concat(
        getProductsForMenuObject({
          data: option,
          enableNutritionExplorerAllergens,
          displayNutritionWithModifiersFromSanity,
        })
      ),
    []
  );
};

const getProductsForPicker = ({
  data,
  enableNutritionExplorerAllergens,
  displayNutritionWithModifiersFromSanity,
}: IGetProductsForPickerProp): IProduct[] => {
  // @ts-ignore FIXME Property 'uiPattern' is missing in type 'INutritionExplorerPicker' but required in type 'IPicker'.
  const defaultPickerSelections = defaultPickerAspect(data);
  // @ts-ignore FIXME Type 'INutritionExplorerPicker' is missing the following properties from type 'IPicker': _createdAt, _rev, _updatedAtts(2345)
  const resolvedPickerSelection = computeSelectedOption(defaultPickerSelections, data);
  if (!resolvedPickerSelection) {
    logger.warn(`Unable to resolve default picker selection for Picker ${data._id}`);
    return [];
  }
  return getProductsForMenuObject({
    data: (resolvedPickerSelection as unknown) as NutritionExplorerMenuObject,
    enableNutritionExplorerAllergens,
    displayNutritionWithModifiersFromSanity,
  });
};

const getProductsForItem = ({
  data,
  enableNutritionExplorerAllergens,
  displayNutritionWithModifiersFromSanity,
}: IGetProductsForItemProps): IProduct[] => {
  // @TODO: investigate how we can consolidate `INutritionExplorerItem` with `IItem`, which is used
  // by `sumModifierNutrition`. The properties that function expects to have access to all exist on
  // `INutritionExplorerItem`, so this cast is fine

  const nutrition = sumModifierNutrition(data as any, displayNutritionWithModifiersFromSanity);

  const isNutritionDefined = data.nutrition
    ? Object.values(nutrition).some(isValidNutritionItem)
    : false;

  if (!isNutritionDefined) {
    logger.warn(`No nutrition info available for Item ${data._id}`);
    return [];
  }

  if (enableNutritionExplorerAllergens) {
    const areAllergensDefined = data.allergens
      ? Object.values(data.allergens).some(isValidAllergenItem)
      : false;

    if (!areAllergensDefined) {
      logger.warn(`No allergen info available for Item ${data._id}`);
      return [];
    }
  }

  return [
    {
      _id: data._id,
      name: data.name?.locale,
      image: data.image,
      nutrition,
      allergens: data.allergens,
      additionalItemInformation: data.additionalItemInformation,
    },
  ];
};

const getProductsForCombo = ({
  data,
  enableNutritionExplorerAllergens,
  displayNutritionWithModifiersFromSanity,
}: IGetProductsForComboProps): IProduct[] => {
  if (!data.mainItem) {
    logger.warn(`Did not have main item on Combo ${data._id}`);
    return [];
  }

  return getProductsForItem({
    data: data.mainItem,
    enableNutritionExplorerAllergens,
    displayNutritionWithModifiersFromSanity,
  });
};

export const getProductsForMenuObject = ({
  data,
  enableNutritionExplorerAllergens,
  displayNutritionWithModifiersFromSanity,
}: IGetProductsForMenuObjectProps): IProduct[] => {
  switch (data._type) {
    case MenuObjectTypes.SECTION:
      return getProductsForSection({
        data,
        enableNutritionExplorerAllergens,
        displayNutritionWithModifiersFromSanity,
      });
    case MenuObjectTypes.PICKER:
      return getProductsForPicker({
        data,
        enableNutritionExplorerAllergens,
        displayNutritionWithModifiersFromSanity,
      });
    case MenuObjectTypes.COMBO:
      return getProductsForCombo({
        data,
        enableNutritionExplorerAllergens,
        displayNutritionWithModifiersFromSanity,
      });
    case MenuObjectTypes.ITEM:
      return getProductsForItem({
        data,
        enableNutritionExplorerAllergens,
        displayNutritionWithModifiersFromSanity,
      });
    default:
      return [];
  }
};

export const getParsedMenuData = ({
  menu,
  categoryWhitelist,
  enableNutritionExplorerAllergens,
  displayNutritionWithModifiersFromSanity,
}: IGetParsedMenuDataProps): NutritionExplorerMenuObjectProductsTuple[] => {
  const options = menu.options || [];

  // if the whitelist isn't specified or is an empty array, return all parsed categories
  if (!categoryWhitelist || categoryWhitelist.length === 0) {
    return options.map(option => [
      option,
      getProductsForMenuObject({
        data: option,
        enableNutritionExplorerAllergens,
        displayNutritionWithModifiersFromSanity,
      }),
    ]);
  }

  const categoryWhitelistMap = categoryWhitelist.reduce(
    (partialMap, whitelistItem) => ({ ...partialMap, [whitelistItem._id]: whitelistItem }),
    {}
  );
  // only return categories that are in the whitelist
  return filterMap<NutritionExplorerMenuObject, NutritionExplorerMenuObjectProductsTuple>(
    option => !!categoryWhitelistMap[option._id],
    option => [
      option,
      getProductsForMenuObject({
        data: option,
        enableNutritionExplorerAllergens,
        displayNutritionWithModifiersFromSanity,
      }),
    ]
  )(options);
};

const evaluateComparison = (operator: string, leftValue: number, rightValue: number) => {
  switch (operator) {
    case ComparisonOperator.LessThan:
      return leftValue < rightValue;
    case ComparisonOperator.GreaterThan:
      return leftValue > rightValue;
    case ComparisonOperator.EqualTo:
      return leftValue === rightValue;
    default:
      return false;
  }
};

export const evaluateFilter = (
  filter: IFilter,
  product: IProduct,
  parentMenuObject: NutritionExplorerMenuObject
): boolean =>
  filter.conditions.every(condition => {
    switch (condition._type) {
      case ConditionType.ParentCategory:
        return parentMenuObject._id === condition.parentCategory?._id;
      case ConditionType.Nutrition: {
        const nutritionValue = product.nutrition[condition.nutritionIdentifier ?? ''];
        if (
          !condition.comparisonOperator ||
          isNil(condition.comparisonValue) ||
          isNil(nutritionValue)
        ) {
          return false;
        }
        return evaluateComparison(
          condition.comparisonOperator,
          nutritionValue,
          condition.comparisonValue
        );
      }
      case ConditionType.Allergen: {
        const allergenValue = product.allergens?.[condition.allergenIdentifier ?? ''];
        if (
          !condition.comparisonOperator ||
          isNil(condition.comparisonValue) ||
          isNil(allergenValue)
        ) {
          return false;
        }
        return evaluateComparison(
          condition.comparisonOperator,
          allergenValue,
          condition.comparisonValue
        );
      }
      case ConditionType.ItemOneOf:
        return condition.items?.some(i => i?._id === product._id);
      default:
        return false;
    }
  });

export const filterData = (
  data: NutritionExplorerMenuObjectProductsTuple[],
  activeFilters: IFilter[]
): NutritionExplorerMenuObjectProductsTuple[] =>
  data.map(([parentMenuObject, products]) => {
    // for each tuple, apply active filters
    const filteredProducts = products.filter(product =>
      activeFilters.every(activeFilter => evaluateFilter(activeFilter, product, parentMenuObject))
    );

    return [parentMenuObject, filteredProducts];
  });
