import React from 'react';

import { useIntl } from 'react-intl';

import { Helmet } from 'components/helmet';
import { useLocale } from 'state/intl';
import { fullBrandName } from 'utils/environment';

import { DOCUMENT_TITLE } from './constants';

const titleTemplate = `%s - ${fullBrandName()}`;

export function HelmetHTML() {
  const { language } = useLocale();
  const { formatMessage } = useIntl();

  const defaultTitle = formatMessage(
    { id: DOCUMENT_TITLE, description: '' },
    { brandName: fullBrandName() }
  );

  return (
    <Helmet titleTemplate={titleTemplate} defaultTitle={defaultTitle}>
      <html lang={language} />
    </Helmet>
  );
}
