import { Box, HStack, Header, Icon, Pressable, Text, VStack } from '@rbilabs/universal-components';

import LoyaltyPointsIcon from 'components/icons/loyalty-points-icon';
import Picture from 'components/picture';
import { ProgressMeter } from 'components/progress-meter';
import SanityBlockRenderer from 'components/sanity-block-renderer';

import { HIGHLIGHT_COLOR } from '../constants';

export const REWARD_CARD_HEIGHT = 112;

export const BackgroundImage = Picture.withConfig({
  width: '100%',
  height: '100%',
  position: 'absolute',
});

export const StyledProgressMeter = ProgressMeter.withConfig({
  width: '160px',
  borderWidth: 0,
  background: 'blackOpacity.4',
  _progress: {
    background: HIGHLIGHT_COLOR,
  },
});

export const StyledRewardPoints = Text.withConfig({
  fontSize: 'md',
  textTransform: 'lowercase',
});

export const CardContainer = Box.withConfig({
  height: `${REWARD_CARD_HEIGHT}px`,
  backgroundColor: 'token.background-pattern',
});

export const StyledClickableContainer = Pressable.withConfig({
  flexDirection: 'row',
  height: '100%',
  textAlign: 'left',
  backgroundColor: 'token.background-pattern',
  borderBottomWidth: 1,
  borderBottomColor: 'blackOpacity.10',
  paddingY: '$2',
  paddingX: '$4',
});

export const InfoContainer = VStack.withConfig({
  marginLeft: '$0.5',
  justifyContent: 'center',
  paddingRight: { base: '$2', md: '$4' },
  paddingLeft: '$2',
  flex: 1,
});

export const PointsNeededContainer = HStack.withConfig({
  marginTop: '$1.5',
  space: 1,
  alignItems: 'center',
});

export const PointsNeededText = Text.withConfig({
  fontSize: 'xs',
  lineHeight: '16px',
});

export const PointsNeededTextDetail = Text.withConfig({
  fontWeight: 'bold',
});

// TODO: RN - Migrate
export const StyledLockIcon = Box.withConfig({});
// export const StyledLockIcon = styled(RewardLockIcon)`
//   & > div {
//     top: 3px;
//     transform: scale(0.4);
//   }
// `;

export const PointTextContainer = HStack.withConfig({
  space: 1,
  marginBottom: '$1.5',
  alignItems: 'center',
});

export const StyledUnLockedRewardPoints = Box.withConfig({
  display: 'none',
});

export const StyledRewardLock = Icon.withConfig({
  variant: 'locked',
  size: 4,
});

// export const PointsNeededLockIcon = styled(StyledRewardLock)`
//   transform: scale(0.4);
//   position: relative;
//   width: 1.2rem;
//   height: 1.2rem;
//   margin: 0 0.25rem 0 0;
//   display: none;
//   > div {
//     background-color: ${HIGHLIGHT_COLOR};
//     ::before,
//     ::after {
//       border-color: ${HIGHLIGHT_COLOR};
//     }
//     ::after {
//       background-color: inherit;
//     }
//   }
// `;

export const IncentiveCardDescription = SanityBlockRenderer.withConfig({
  width: 'full',
  overflow: 'hidden',
  mb: '$1.5',
  textProps: {
    numberOfLines: 2,
    fontSize: 12,
    lineHeight: 16,
    ellipsizeMode: 'tail', // TODO: RN - SanityBlockRenderer not applying text variants correctly
  },
});

export const ImageWrapper = Box.withConfig({
  width: '120px',
  paddingY: '$2',
  paddingX: '$4',
  flexShrink: 0,
  justifyContent: 'center',
  alignItems: 'center',
  marginRight: '$2',
});

export const RewardText = Header.withConfig<{ $hasMessage: boolean }>(p => ({
  variant: 'headerFour',
  marginBottom: '$1.5',
  ellipsizeMode: 'tail',
  numberOfLines: p.$hasMessage ? 1 : 2,
  fontSize: 'xs',
}));

export const StyledPointsIcon = LoyaltyPointsIcon.withConfig({
  width: '14px',
  height: '14px',
  mr: '$1.5',
});

export const StyledPointsText = Text.withConfig({
  fontSize: 'xs',
  lineHeight: 'md',
  fontFamily: 'alternative',
});
