import { Box } from '@rbilabs/universal-components';

const LoadingContainer = Box.withConfig({
  width: 'full',
  height: '100%',
  justifyContent: 'center',
  alignItems: 'center',
});

export default LoadingContainer;
