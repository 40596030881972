import { MenuNavigator } from 'navigation/menu-navigator';
import { routes } from 'utils/routing';

import type { RawTab } from '../../types';

export const id: RawTab['id'] = 'menu';

export const screen: RawTab['screen'] = {
  Component: MenuNavigator,
  options: {
    getShouldRenderEagerly: ({ isStoreRequired }) => !isStoreRequired,
  },
};

export const link: RawTab['link'] = {
  en: routes.menu,
  fr: routes.menu,
};
