import React, { FC } from 'react';

import { Box } from '@rbilabs/universal-components';
import { useIntl } from 'react-intl';

import ActionButton from 'components/action-button';
import { useOffersContext } from 'state/offers';
import { UIPattern } from 'state/offers/types';

import { DetailButton } from './detail-button';
import { LockBadge } from './locked-badge';
import {
  CardContainer,
  CardFooter,
  Countdown,
  ErrorControlsContainer,
  ErrorText,
  Header,
  Subheader,
  SubheaderContainer,
} from './locked-offers-card.styled';
import { OfferList } from './offer-list';
import { OfferStepper } from './offer-stepper';
import { ILockedCardProps } from './types';

export const LockedCard: FC<React.PropsWithChildren<ILockedCardProps>> = ({
  header,
  subheader,
  progressData,
  stepsCompleted,
  offerState,
  expiresText,
  mode,
  showButton = true,
  showBadge = true,
  onPress,
}) => {
  const { formatMessage } = useIntl();
  const { refreshOffers } = useOffersContext();

  if (mode === UIPattern.ERROR) {
    return (
      <CardContainer centerVertical>
        <ErrorText>{formatMessage({ id: 'retryMessage' })}</ErrorText>
        <ErrorControlsContainer>
          <Box>
            <ActionButton onPress={refreshOffers}>{formatMessage({ id: 'retry' })}</ActionButton>
          </Box>
        </ErrorControlsContainer>
      </CardContainer>
    );
  }

  return (
    <CardContainer onPress={onPress}>
      {showBadge && <LockBadge />}
      <Header content={header} />
      <SubheaderContainer mode={mode}>
        <Subheader content={subheader} />
      </SubheaderContainer>
      {mode === UIPattern.STEPPER && (
        <OfferStepper stepsCompleted={stepsCompleted} steps={progressData} />
      )}
      {mode === UIPattern.LIST && <OfferList list={progressData} />}
      <CardFooter>
        {showButton && (
          <Box width="1/2">
            <DetailButton offerState={offerState} progressData={progressData} onPress={onPress} />
          </Box>
        )}
        <Box width="1/2">
          <Countdown>{expiresText}</Countdown>
        </Box>
      </CardFooter>
    </CardContainer>
  );
};
