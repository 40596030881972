import { Box, Header as UCLHeader } from '@rbilabs/universal-components';

import Link from 'components/link';
import LoyaltyClaimPointsLink from 'pages/loyalty/loyalty-claim-points/loyalty-claim-points-link';

import { DASHBOARD_PADDING_SIZE } from '../../loyalty-dashboard/constants';

export const Container = Box.withConfig({
  width: 'full',
  backgroundColor: Styles.color.white,
  marginTop: '$4',
  paddingX: DASHBOARD_PADDING_SIZE,
  paddingBottom: '$12',
  borderTopRadius: {
    md: Styles.borderRadius,
  },
});

export const Header = UCLHeader.withConfig({
  accessibilityLevel: 1,
  margin: 0,
  fontSize: '4xl',
  lineHeight: 'lg',
});

export const SubText = Link.withConfig({
  marginBottom: '$4',
  _text: {
    underline: true,
    fontSize: 'md',
    lineHeight: 'lg',
  },
});

export const Content = Box.withConfig({
  alignItems: 'center',
});

export const TableWrap = Box.withConfig({
  width: 'full',
  maxWidth: 620,
});

export const StyledLoyaltyClaimPointsLink = LoyaltyClaimPointsLink.withConfig({
  marginTop: '$4',
  _textProps: {
    underline: true,
  },
});
