import React from 'react';

import { Box } from '@rbilabs/universal-components';

import { BackArrowLink } from 'components/navigation/back-arrow';

export const Container = Box.withConfig({
  position: 'absolute',
  top: '3px',
  left: '3px',
  zIndex: Styles.zIndex.overlay,
  margin: '4',
  justifyContent: 'flex-start',
});

interface IMobileBackButton {
  onPress?: () => void;
  to: string;
}
export const MobileBackButton: React.FC<React.PropsWithChildren<IMobileBackButton>> = ({ to }) => {
  return (
    <Container>
      <BackArrowLink to={to} />
    </Container>
  );
};
