import React, { FC } from 'react';

import { LockedOffersCardView } from './locked-offers-card.view';
import { ILockedOffersCardProps } from './types';

/**
 *
 * LockedOffersCard:
 * Informative card display to understand their progress to unlocking a special offer
 */
const LockedOffersCard: FC<React.PropsWithChildren<ILockedOffersCardProps>> = props => {
  return <LockedOffersCardView {...props} />;
};

export default LockedOffersCard;
