import React, { FC } from 'react';

import { Box } from '@rbilabs/universal-components';

import { ISanityTextNode, ISanityTypographyBlock } from '@rbi-ctg/menu';
import { IMarketingTileGroupProps } from 'components/features/components/marketing-tile-group/types';
import { useInRestaurantRedemptionContext } from 'state/loyalty/in-restaurant-redemption';

import {
  DisclaimerMessage,
  MarketingTileGroup,
  RewardsTitle,
} from './loyalty-marketing-tile-widget.styled';

/**
 *
 * LoyaltyMarketingTileGroupWidget shows a group of tiles in a given widget slot
 *
 */
const LoyaltyMarketingTileGroupWidget: FC<React.PropsWithChildren<{
  disclaimer: { localeRaw: ISanityTypographyBlock[] } | null;
  marketingTileGroup: IMarketingTileGroupProps['item'];
  title?: ISanityTextNode;
}>> = ({ marketingTileGroup, title, disclaimer }) => {
  const { inRestaurantRedemptionCart } = useInRestaurantRedemptionContext();

  return (
    <Box testID="loyalty-marketing-tile-widget">
      {title && <RewardsTitle>{title?.locale}</RewardsTitle>}
      <MarketingTileGroup
        $checkRedemptionCart={!!inRestaurantRedemptionCart.length}
        item={marketingTileGroup}
      />
      {disclaimer && <DisclaimerMessage content={disclaimer?.localeRaw} />}
    </Box>
  );
};

export default LoyaltyMarketingTileGroupWidget;
