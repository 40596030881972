import React, { useCallback, useEffect, useState } from 'react';

import { useIntl } from 'react-intl';

import { SupportedLanguages, SupportedRegions } from '@rbi-ctg/frontend';
import Modal from 'components/modal';
import { useAmplitudeContext } from 'state/amplitude';
import { setBrazeUserAttributes } from 'state/braze/set-braze-user-attributes';
import { useLocale } from 'state/intl';
import { useFlag } from 'state/launchdarkly';
import { theme } from 'styles/configure-theme';
import { inferSupportedLocale } from 'utils/intl/locale';
import { LaunchDarklyFlag } from 'utils/launchdarkly';

import LanguageSelectorModalContents from './language-selector-modal-contents';
import { ModalAdditionalContentWrapper } from './language-selector-modal-contents/styled';
import {
  ContentContainer,
  PrimaryText,
  SecondaryText,
  StyledModalHeading,
} from './language-selector-modal.styled';
import { ILanguageModalProps } from './types';
import { useChangeLocale } from './use-change-locale.hook';

const LanguageSelectorModal: React.FC<React.PropsWithChildren<ILanguageModalProps>> = ({
  onModalDismiss,
  heading,
  primaryText,
  secondaryText,
  disclaimer,
  regionFilter = (regionString: string) => !!regionString,
  redirectToCurrentLocation = false,
}) => {
  const { formatMessage } = useIntl();
  const { updateAmplitudeUserAttributes } = useAmplitudeContext();
  const { region, locale, language } = useLocale();
  const [{ langKey, regionKey }, setSelectedLocale] = useState({
    langKey: language,
    regionKey: region,
  });
  const { changeLocale } = useChangeLocale({ redirectToCurrentLocation, langKey, regionKey });

  const [isOneStepSelection, setIsOneStepSelection] = useState(false);

  const singleRegionLanguageSelector = useFlag(LaunchDarklyFlag.SINGLE_REGION_LANGUAGE_SELECTOR);

  const handleSelection = (
    selectedLanguage: string,
    mappedRegion: string,
    isOneStep: boolean = false
  ) => {
    setIsOneStepSelection(isOneStep);
    setSelectedLocale({
      langKey: selectedLanguage as SupportedLanguages,
      regionKey: mappedRegion as SupportedRegions,
    });
  };

  const isNewLocaleSelectionSameAsCurrent = useCallback(() => {
    const inferredLocaleFromArgs = inferSupportedLocale(
      langKey as SupportedLanguages,
      regionKey as SupportedRegions
    );

    return inferredLocaleFromArgs === locale;
  }, [langKey, regionKey, locale]);

  const setLanguage = useCallback(() => {
    if (isNewLocaleSelectionSameAsCurrent()) {
      onModalDismiss();
      return;
    }
    changeLocale();
    onModalDismiss();
  }, [changeLocale, isNewLocaleSelectionSameAsCurrent, onModalDismiss]);

  useEffect(() => {
    updateAmplitudeUserAttributes({ language });
    setBrazeUserAttributes({ language });
    if (!isOneStepSelection) {
      return;
    }
    if (isNewLocaleSelectionSameAsCurrent()) {
      onModalDismiss();
    } else {
      setLanguage();
    }
  }, [
    langKey,
    regionKey,
    isNewLocaleSelectionSameAsCurrent,
    setLanguage,
    isOneStepSelection,
    onModalDismiss,
    updateAmplitudeUserAttributes,
    language,
  ]);

  const applyButtonText = formatMessage({ id: 'apply' });
  const modalHeading =
    heading ||
    (singleRegionLanguageSelector
      ? formatMessage({ id: 'selectLanguage' })
      : formatMessage({ id: 'changeLanguage' }));

  return (
    <Modal
      testID="select-language-modal"
      onDismiss={onModalDismiss}
      mParticleEventData={{
        modalAppearanceEventMessage: 'Language and region selector',
      }}
    >
      <ContentContainer paddingTop="$13.5" background={theme.token('background-dialog')}>
        <StyledModalHeading nativeID="language-select-modal-heading">
          {modalHeading}
        </StyledModalHeading>
        {(primaryText || secondaryText) && (
          <ModalAdditionalContentWrapper>
            {primaryText && <PrimaryText variant="copyOne">{primaryText}</PrimaryText>}
            {secondaryText && <SecondaryText variant="copyOne">{secondaryText}</SecondaryText>}
          </ModalAdditionalContentWrapper>
        )}
        <LanguageSelectorModalContents
          ariaRadioGroupLabeledBy="language-select-modal-heading"
          regionFilter={regionFilter}
          regionKey={regionKey}
          langKey={langKey}
          disclaimer={disclaimer}
          applyButtonText={applyButtonText}
          setLanguage={setLanguage}
          handleSelection={handleSelection}
        />
      </ContentContainer>
    </Modal>
  );
};

export default LanguageSelectorModal;
