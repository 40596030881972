import React, { useCallback, useRef, useState } from 'react';

import { FormControl } from '@rbilabs/universal-components';
import { useIntl } from 'react-intl';

import ActionButton, { ActionButtonVariants } from 'components/action-button';
import { ActionsheetContent, ActionsheetWrapper } from 'components/action-sheet';
import { useIsDesktopBp } from 'hooks/breakpoints';
import { useNavigation } from 'hooks/navigation/use-navigation';
import { useRoute } from 'hooks/navigation/use-route';
import { appendObjectToQueryString } from 'hooks/navigation/utils';
import { useKeyboardState } from 'hooks/use-keyboard-state';
import { useAuthContext } from 'state/auth';
import { useCRMEventsContext } from 'state/crm-events';
import { useLoyaltyUser } from 'state/loyalty/hooks/use-loyalty-user';
import { useRedeemPromoCode } from 'state/loyalty/hooks/use-redeem-promo-code';
import { routes } from 'utils/routing';

import { LoyaltyPromoCodeDialog } from './loyalty-promo-code-dialog';
import {
  CloseButton,
  ContentHeader,
  StyledFormControl,
  StyledInput,
} from './loyalty-promo-code-drawer.styled';
import { ILoyaltyPromoCodeDrawerProps, PromoCodeError } from './types';
import { buildErrorMessageFromPromoCodeError, createRbiApplyPromoCodeEvent } from './utils';

export const LoyaltyPromoCodeDrawer = ({
  isOpen,
  onDismiss,
  promoCode,
  onPromoCodeRedeemSuccess,
  onNavigate,
}: ILoyaltyPromoCodeDrawerProps) => {
  const { formatMessage } = useIntl();
  const { loyaltyUser } = useLoyaltyUser();
  const { keyboardHeight } = useKeyboardState();
  const { logRBIEvent } = useCRMEventsContext();
  const [promoCodeValue, setPromoCodeValue] = useState(promoCode ?? '');
  const [redeemMutation, { loading, error }] = useRedeemPromoCode();
  const { isAuthenticated, setOriginLocation } = useAuthContext();
  const { navigate, setParams } = useNavigation();
  const { pathname, params } = useRoute();
  const isDesktop = useIsDesktopBp();
  // Using ref to autofocus input due to Android not supporting autoFocus prop
  const inputRef = useRef<HTMLInputElement>(null);
  const isDisabled = !promoCodeValue || loading;
  const heading = formatMessage({ id: 'applyPromoCode' });

  const handleOnApplyPromoCode = useCallback(
    async (promoCode: string) => {
      if (!isAuthenticated || !loyaltyUser?.id) {
        setParams({ ...params, promoCode });
        const currentPathWithPromoCodeParam = appendObjectToQueryString(pathname, {
          ...params,
          promoCode,
        });
        setOriginLocation(currentPathWithPromoCodeParam);
        onNavigate();
        return navigate(routes.signUp, { replace: true, popCurrent: true });
      }
      let event = createRbiApplyPromoCodeEvent(promoCodeValue, 'Successful');
      const redeemMutationResult = await redeemMutation(loyaltyUser.id, promoCode).catch(
        (e: PromoCodeError) => {
          event = createRbiApplyPromoCodeEvent(promoCode, 'Failed', e.message);
        }
      );
      logRBIEvent(event);

      if (redeemMutationResult) {
        onPromoCodeRedeemSuccess?.(redeemMutationResult);
        return onDismiss();
      }
    },
    [
      isAuthenticated,
      loyaltyUser?.id,
      promoCodeValue,
      redeemMutation,
      logRBIEvent,
      pathname,
      params,
      setOriginLocation,
      onNavigate,
      navigate,
      onPromoCodeRedeemSuccess,
      onDismiss,
      setParams,
    ]
  );

  if (isDesktop) {
    return (
      <LoyaltyPromoCodeDialog
        heading={heading}
        onDismiss={onDismiss}
        isOpen={isOpen}
        onConfirm={() => {
          handleOnApplyPromoCode(promoCodeValue);
        }}
        ctaIsDisabled={isDisabled}
        setPromoCodeValue={setPromoCodeValue}
        promoCodeValue={promoCodeValue}
        error={error}
      />
    );
  }

  return (
    <ActionsheetWrapper
      isOpen={isOpen}
      onClose={onDismiss}
      onOpenEventMessage="Apply Promo Code"
      //This nasty hack enables keyboard opening on Android on input focus
      onLayout={() => setTimeout(() => inputRef.current?.focus(), 1)}
    >
      <ActionsheetContent _ios={{ bottom: keyboardHeight }} px="$4">
        <CloseButton onPress={onDismiss} />
        <ContentHeader>{heading}</ContentHeader>
        <StyledFormControl isInvalid={!!error}>
          <StyledInput
            ref={inputRef}
            value={promoCodeValue}
            onChangeText={setPromoCodeValue}
            _focus={{
              borderColor: error ? 'token.border-color-error' : undefined,
            }}
            // Super hacky way of getting border color to update on Native Base 3.3.4
            InputRightElement={<></>}
          />

          <ActionButton
            disabled={isDisabled}
            variant={ActionButtonVariants.OUTLINE}
            onPress={() => {
              handleOnApplyPromoCode(promoCodeValue);
            }}
          >
            {formatMessage({ id: 'apply' })}
          </ActionButton>

          {!!error && (
            <FormControl.ErrorMessage width="100%">
              {buildErrorMessageFromPromoCodeError(error, formatMessage)}
            </FormControl.ErrorMessage>
          )}
        </StyledFormControl>
      </ActionsheetContent>
    </ActionsheetWrapper>
  );
};
