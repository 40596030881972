import React, { FC } from 'react';

import Modal from 'components/modal';
import { MobileBackButton } from 'components/offers/ui-refresh/mobile-back-button';
import { PullToReload } from 'components/pull-to-reload';
import { useIsMobileBp } from 'hooks/breakpoints';

import { LoyaltyOffersCooldown } from '../loyalty-offers/loyalty-offers-cooldown';

import { INCENTIVE_MODAL_BG_COLOR } from './constants';
import { IncentiveDetails } from './incentive-details';
import { IncentiveList, IncentivesGrid } from './loyalty-incentives.styled';

const LoyaltyIncentivesGrid: FC<React.PropsWithChildren<any>> = ({
  selectedIncentive,
  engineIncentivesMap,
  goBack,
  children,
  refetch,
  setScrollContainerRef,
  offersInCooldown = false,
  isWidget,
  setSelectedRewardIdFromWidget,
  isRefreshing,
}) => {
  const isIncentiveSelected = Boolean(selectedIncentive);

  const isMobile = useIsMobileBp();

  return (
    <>
      {isMobile && isIncentiveSelected && <MobileBackButton to={goBack} />}
      <IncentivesGrid
        incentiveSelected={isIncentiveSelected}
        isWidget={isWidget}
        backgroundColor={Styles.color.background}
      >
        {(!isIncentiveSelected || isWidget) && (
          <IncentiveList
            testID="incentives-list"
            ref={el => {
              if (setScrollContainerRef) {
                setScrollContainerRef(el);
              }
            }}
          >
            {offersInCooldown && <LoyaltyOffersCooldown />}
            {/* If it's a widget, don't allow to pull on reload */}
            {isWidget ? (
              children
            ) : (
              <PullToReload onRefresh={refetch} isRefreshing={isRefreshing}>
                {children}
              </PullToReload>
            )}
          </IncentiveList>
        )}
        {selectedIncentive &&
          (isWidget ? (
            <Modal
              onDismiss={() => setSelectedRewardIdFromWidget('')}
              mParticleEventData={{
                modalAppearanceEventMessage: 'Show reward modal',
              }}
              backgroundColor={INCENTIVE_MODAL_BG_COLOR}
            >
              <IncentiveDetails
                engineIncentivesMap={engineIncentivesMap}
                selectedIncentive={selectedIncentive}
              />
            </Modal>
          ) : (
            <IncentiveDetails
              engineIncentivesMap={engineIncentivesMap}
              selectedIncentive={selectedIncentive}
            />
          ))}
      </IncentivesGrid>
    </>
  );
};

export default LoyaltyIncentivesGrid;
