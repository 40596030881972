import React, { FC } from 'react';

import { NavigationProp } from '@react-navigation/native';
import { NativeStackHeaderProps, createNativeStackNavigator } from '@react-navigation/native-stack';

import { showHamburgerMenu } from 'navigation/drawer-navigator/constants';
import { HeaderLeft } from 'navigation/header/header-left';
import { TabNavigatorHeader } from 'navigation/header/tab-navigator-header';
import { HeaderLeftIconType } from 'navigation/header/types';
import { INativeStackNavigatorWithBackProps } from 'navigation/native-stack-navigator-with-back/types';

const Stack = createNativeStackNavigator();

const Header: FC<React.PropsWithChildren<NativeStackHeaderProps>> = ({ options, navigation }) => (
  // @ts-expect-error TS(2322) FIXME: Type 'NativeStackNavigationOptions' is not assigna... Remove this comment to see the full error message
  <TabNavigatorHeader navigation={navigation} headerOptions={options} />
);

export const NativeStackNavigatorWithBack: React.VFC<INativeStackNavigatorWithBackProps> = ({
  screens = [],
  backButtonOverride,
}) => {
  const [firstScreen, ...otherScreens] = screens;

  if (!firstScreen) {
    return null;
  }

  return (
    <Stack.Navigator
      screenOptions={{
        header: Header,
        fullScreenGestureEnabled: true,
      }}
      initialRouteName={firstScreen.name}
    >
      <Stack.Screen
        name={firstScreen.name}
        component={firstScreen.component}
        options={({ navigation }) => ({
          headerLeft: () =>
            showHamburgerMenu ? <HeaderLeft onPress={navigation.toggleDrawer} /> : undefined,
          ...firstScreen.options,
        })}
      />
      {otherScreens.map(screen => (
        <Stack.Screen
          key={screen.name}
          name={screen.name}
          component={screen.component}
          options={({ navigation }: { navigation: NavigationProp<any> }) => ({
            headerLeft: () => {
              let onPress = navigation.goBack;
              /**
               * This allows for the sometimes funky UX that we have that we've tried to ask for different UX on but they want it this way (for now at least).
               * Presently menus can go many levels deep on a single stack for PLK, providing alternative back button functionality allows for a user to get out of these nested menus.
               */
              if (backButtonOverride) {
                try {
                  const overrideOnPress = backButtonOverride(navigation);
                  if (overrideOnPress) {
                    onPress = overrideOnPress;
                  }
                  // eat for app stability
                } catch (_) {}
              }

              return (
                <HeaderLeft
                  onPress={onPress}
                  iconProps={{
                    variant: HeaderLeftIconType.Back,
                  }}
                />
              );
            },
            // Screen Options must override above options.
            ...screen.options,
          })}
        />
      ))}
    </Stack.Navigator>
  );
};
