// NAVIGATION
export const HEADER_SIGNUP_BUTTON = 'app-header-signup-button';
export const HEADER_HAMBURGER_BUTTON = 'app-header-hamburger-button';
export const HEADER_CHOOSE_LOCATION = 'app-header-tsm-choose-location';
export const HEADER_VIEW_CART_BUTTON = 'app-header-view-cart-button';

// TOASTS
export const TOAST_NEW_ACCOUNT_CREATED = 'toast-new-account-created';
export const TOAST_ITEM_ADDED_TO_CART = 'toast-item-added-to-cart';

// DRAWER
export const DRAWER_ACCOUNT_INFO_BUTTON = 'drawer-account-info-button';

// SIGNUP PAGE
export const SIGNUP_FORM_SCROLL_CONTAINER = 'signup-scroll-container';
export const SIGNUP_FORM_NAME_INPUT = 'signup-name-input';
export const SIGNUP_FORM_EMAIL_INPUT = 'signup-email-input';
export const SIGNUP_FORM_PHONE_INPUT = 'signup-phone-input';
export const SIGNUP_FORM_TERMS_AGREEMENT = 'signup-terms-agreement-checkbox';
export const SIGNUP_SUBMIT_BUTTON = 'signup-submit-button';

// SIGNIN PAGE
export const SIGNIN_FORM_EMAIL_INPUT = 'signin-form-email-input';
export const SIGNIN_SUBMIT_BUTTON = 'signin-submit-button';

// OTP PAGE
export const SIGNIN_OTP_INPUT = 'signin-otp-input';
export const SIGNIN_OTP_PAGE = 'signin-otp-page';

// ACCOUNT INFO PAGE
export const ACCOUNT_INFO_SCROLL_CONTAINER = 'account-info-scroll-container';
export const ACCOUNT_INFO_SIGNOUT_BUTTON = 'account-info-signout-button';
export const ACCOUNT_INFO_SIGNOUT_DIALOG_CONTAINER = 'account-info-signout-dialog-container';

// GENERIC DIALOG
export const GENERIC_DIALOG_CONFIRM_BUTTON = 'generic-dialog-confirm-button';
export const GENERIC_DIALOG_CANCEL_BUTTON = 'generic-dialog-cancel-button';

// MENU
export const MAIN_MENU_PAGE_CONTAINER = 'main-menu-page-container';
export const MENU_TILE_ITEM_AT = (index: number) => `menu-tile-item-at-${index}`;
export const MENU_PRODUCT_PAGE_PRIMARY_ACTION = 'menu-product-page-primary-action';
export const MENU_PRODUCT_PAGE = 'menu-product-page';

// STORE LOCATOR
export const STORE_LOCATOR_CONTAINER = 'store-locator-container';
export const STORE_LOCATOR_SEARCH_INPUT = 'store-locator-search-input';
export const STORE_LOCATOR_SUGGESTION_AT = (index: number) => `store-locator-suggestion-${index}`;
export const STORE_LOCATOR_STORE_LIST_ITEM_AT = (index: number) =>
  `store-locator-store-list-item-${index}`;
export const STORE_LOCATOR_STORE_LIST_ORDER_BUTTON = `store-locator-store-list-item-order`;

// CART
export const CART_PAGE = 'cart-page';
export const CART_PAGE_CONTINUE_BUTTON = 'cart-page-continue-button';
export const CART_PAYMENT_PAGE = 'cart-payment-page';
export const CREDIT_CARD_FORM_NAME_INPUT = 'credit-card-form-name-input';
export const CART_PAYMENT_PAGE_PAYMENT_METHODS_TOGGLE_BUTTON =
  'cart-payment-page-payment-methods-toggle-button';
export const ADD_NEW_CARD_BUTTON = 'add-new-card-button';
export const CART_PLACE_ORDER_BUTTON = 'cart-place-order-button';
export const CART_STORE_CONFIRMATION_PAGE = 'cart-store-confirmation-page';

// ORDER CONFIRMATION
export const ORDER_CONFIRMATION_PAGE = 'order-confirmation-page';
