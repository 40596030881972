import 'expo-dev-client';
import registerRootComponent from 'expo/build/launch/registerRootComponent';
import { StatusBar } from 'expo-status-bar';
import App from 'frontend';
import { sanityAssets } from './assets';

const Root = () => (
  <>
    <StatusBar style="auto" />
    <App sanityAssets={sanityAssets} />
  </>
);

registerRootComponent(Root);
