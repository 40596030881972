import React from 'react';

import { Icon } from '@rbilabs/universal-components';
import { useIntl } from 'react-intl';

import { useNavigation } from 'hooks/navigation/use-navigation';
import { theme } from 'styles/configure-theme';

import {
  BackArrowContainer,
  SignUpHeader,
  SignUpHeaderContainer,
} from './signup-mobile-header.styled';

// The types for react native do not support `dataSet` yet, but
// it is the required way to pass data-* attributes to the node.
// And we are using this mechanism to ensure these nodes are rendered
// during the pre-render, but only visible for the correct screen size.
const onlyRenderInMobile = () =>
  ({
    dataSet: { mediaquery: 'mobile' },
  } as any);

const SignupMobileHeader = () => {
  const { formatMessage } = useIntl();
  const { goBack } = useNavigation();

  return (
    <SignUpHeaderContainer {...onlyRenderInMobile()} testID="signup-mobile-header">
      <SignUpHeader>
        <BackArrowContainer
          testID="mobile-nav-go-back"
          onPress={goBack}
          accessibilityLabel={formatMessage({ id: 'back' })}
          accessibilityRole="button"
        >
          <Icon
            variant="back"
            color={theme.token('icon-default')}
            width="24px"
            title={formatMessage({ id: 'back' })}
          />
        </BackArrowContainer>
      </SignUpHeader>
    </SignUpHeaderContainer>
  );
};

export default SignupMobileHeader;
