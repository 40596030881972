import { Text as UCLText, View } from '@rbilabs/universal-components';

export const Wrapper = View.withConfig(p => ({
  position: 'absolute',
  left: 0,
  top: 0,
  zIndex: Styles.zIndex.max,
  backgroundColor: Styles.color.alert.info.background,
  height: '$6',
  padding: '$1.5',
  borderTopLeftRadius: 0,
  borderTopRightRadius: 0,
  borderBottomRightRadius: 5,
  borderBottomLeftRadius: 0,
}));

export const Text = UCLText.withConfig({
  color: Styles.color.contrastBackground,
  fontFamily: Styles.fontFamily.brand,
  fontSize: 'xs',
  fontWeight: Styles.fontWeight.normal,
  lineHeight: '2xs',
});
