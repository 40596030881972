import React, { FC } from 'react';

import { ISanityImage } from '@rbi-ctg/menu';
import ActionButton, { ActionButtonVariants } from 'components/action-button';
import { IImageFragment } from 'generated/sanity-graphql';
import { useNavigation } from 'hooks/navigation/use-navigation';

import {
  StyledButtonGroup,
  StyledDescription,
  StyledDialog,
  StyledPicture,
  StyledTitle,
} from './loyalty-incentive-not-available-modal.styled';

interface IActionButton {
  buttonText: string;
  buttonPath: string;
  inverted?: boolean;
}

interface ILoyaltyIncentiveNotAvailableModal {
  image: ISanityImage | IImageFragment;
  title: string;
  description: string;
  actionButtons: IActionButton[];
}

const LoyaltyIncentiveNotAvailableModalView: FC<React.PropsWithChildren<
  ILoyaltyIncentiveNotAvailableModal
>> = ({ image, title, description, actionButtons }) => {
  const { navigate } = useNavigation();
  const handleActionButtonClick = (path: string) => {
    navigate(path);
  };

  const Header = () => <StyledTitle>{title}</StyledTitle>;
  const Body = () => <StyledDescription>{description}</StyledDescription>;
  const Actions = () => (
    <StyledButtonGroup>
      {actionButtons.map(
        ({ buttonText, buttonPath, inverted }) =>
          buttonText && (
            <ActionButton
              key={buttonText}
              onPress={() => handleActionButtonClick(buttonPath)}
              variant={inverted ? ActionButtonVariants.OUTLINE : ActionButtonVariants.PRIMARY}
              width="full"
              marginTop="$2"
            >
              {buttonText}
            </ActionButton>
          )
      )}
    </StyledButtonGroup>
  );

  return (
    <StyledDialog
      headingComponent={<Header />}
      image={<StyledPicture image={image} alt="incentive-not-available" />}
      bodyComponent={<Body />}
      actions={<Actions />}
      modalAppearanceEventMessage="Loyalty Incentive Not Available Modal"
      showDialog
    />
  );
};

export default LoyaltyIncentiveNotAvailableModalView;
