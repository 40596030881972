import { DOMAINS, LANGUAGES, REGIONS } from 'state/intl/types';
import { StorageKeys } from 'utils/local-storage';

export const LOCALE_SELECTED_QUERY_PARAM = 'locale-selected';

export const LOCALE_SEPARATOR = '-';

export const READABLE_LANGUAGES = {
  [LANGUAGES.en]: {
    [LANGUAGES.en]: 'English',
    [LANGUAGES.fr]: 'Français',
  },
  [LANGUAGES.fr]: {
    [LANGUAGES.en]: 'English',
    [LANGUAGES.fr]: 'Français',
  },
};

/* @TODO: let's just use formatMessage here instead of redefining for every language */
export const READABLE_REGIONS = {
  [LANGUAGES.en]: {
    [REGIONS.CA]: 'Canada',
    [REGIONS.US]: 'USA',
  },
  [LANGUAGES.fr]: {
    [REGIONS.CA]: 'Canada',
    [REGIONS.US]: 'États Unis',
  },
};

export const REGION_DOMAIN_VALUES = {
  CA: DOMAINS.ca,
  US: DOMAINS.com,
};

export const regionSpecificLocalStorageKeys = [
  StorageKeys.STORE,
  StorageKeys.AUTH_REDIRECT,
  StorageKeys.ORDER,
  StorageKeys.SERVICE_MODE,
];

export const supportedLanguagesByRegion = {
  ca: {
    locales: ['en', 'fr'],
    defaultLanguage: 'en',
  },
  us: {
    locales: ['en'],
    defaultLanguage: 'en',
  },
};
