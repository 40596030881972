import { Box, ScrollView, Text } from '@rbilabs/universal-components';

export const DashboardContainer = ScrollView.withConfig({
  backgroundColor: Styles.color.background,
  width: 'full',
  maxWidth: {
    md: '960px',
  },
  flex: 1,
  marginX: {
    md: 'auto',
  },
});

export const Placeholder = Text.withConfig({
  color: Styles.color.white,
  height: '$2.5',
});

export const WidgetElementWrapper = Box.withConfig({
  height: 'auto',
});
