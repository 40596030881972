import React, { useCallback, useState } from 'react';

import { Icon } from '@rbilabs/universal-components';
import { useIntl } from 'react-intl';

import { PRE_CURBSIDE_CONFIRM_ARRIVAL_TIMEOUT_IN_MINUTES } from 'components/bottom-service-mode/hooks/use-bottom-service-details';
import { RbiOrderStatus, useGetUserOrdersQuery } from 'generated/rbi-graphql';
import { useNavigation } from 'hooks/navigation/use-navigation';
import { serviceModeToIntlId } from 'pages/store-locator/new-ui/store-card/hooks/use-service-mode-text';
import { useAuthContext } from 'state/auth';
import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';
import { ServiceMode, useOrderContext } from 'state/order';
import { useReversedUIContext } from 'state/reversed-ui';
import { useStoreContext } from 'state/store';
import { theme } from 'styles/configure-theme';
import { hiddenAccessibilityPlatformProps } from 'utils/accessibility';
import { routes } from 'utils/routing';

import { CurbsideConfirmationModal } from '../../top-service-mode/curbside-confirmation-modal';
import { ITopServiceModeDetail, TopServiceVariants } from '../types';
import { postOrderTimeout } from '../utils';

export const StyledIcon = Icon.withConfig({
  size: '$6',
  ...hiddenAccessibilityPlatformProps,
});

export const useTopServiceModeDetails = (): ITopServiceModeDetail => {
  const {
    serviceMode,
    deliveryAddress,
    curbsidePickupOrderId,
    curbsidePickupOrderTimePlaced,
  } = useOrderContext();
  const { store } = useStoreContext();
  const enableOrdering = useFlag(LaunchDarklyFlag.ENABLE_ORDERING);
  const [curbsideConfirmationModalOpen, setCurbsideConfirmationModalOpen] = useState<boolean>(
    false
  );
  const { formatMessage } = useIntl();
  const { reversedUI } = useReversedUIContext();
  const { isAuthenticated } = useAuthContext();

  const iconColor = reversedUI ? theme.token('icon-reversed') : theme.token('icon-default');
  const { navigate } = useNavigation();

  const { refetch } = useGetUserOrdersQuery({
    variables: {
      limit: 1,
      orderStatuses: [
        RbiOrderStatus.INSERT_SUCCESSFUL,
        RbiOrderStatus.UPDATE_SUCCESSFUL,
        RbiOrderStatus.REFUND_SUCCESSFUL,
      ],
    },
    skip: !isAuthenticated,
  });

  // @TODO: Currently we have duplicated logic for recentOrders between FAB Button, Top service Mode and Service Notification
  // We must find a way to avoid this.
  const isPreConfirmCurbside = useCallback(() => {
    return (
      curbsidePickupOrderId &&
      postOrderTimeout(
        curbsidePickupOrderTimePlaced,
        PRE_CURBSIDE_CONFIRM_ARRIVAL_TIMEOUT_IN_MINUTES
      )
    );
  }, [curbsidePickupOrderId, curbsidePickupOrderTimePlaced]);

  const handleServiceModeChange = useCallback(() => {
    if (isPreConfirmCurbside() && isAuthenticated) {
      return setCurbsideConfirmationModalOpen(true);
    }
    navigate(routes.serviceMode);
  }, [isAuthenticated, isPreConfirmCurbside, navigate]);

  const resultBase: ITopServiceModeDetail = {
    heading: formatMessage({ id: 'forItemAvailability' }),
    details: null,
    icon: <StyledIcon variant="location" color={iconColor} />,
    onTopServiceClick: handleServiceModeChange,
    variant: TopServiceVariants.DEFAULT,
    CurbsideConfirmationModal,
    curbsideConfirmationModalOpen,
    setCurbsideConfirmationModalOpen,
    refetchUserOrder: refetch,
  };

  if (store._id) {
    if ((!store.hasMobileOrdering || !enableOrdering) && store.physicalAddress?.address1) {
      return {
        ...resultBase,
        heading: formatMessage({ id: 'yourSelectedStore' }),
        details: store.physicalAddress.address1,
        icon: <StyledIcon variant="restaurant" color={iconColor} />,
      };
    }
    const pickUpBase: ITopServiceModeDetail = {
      ...resultBase,
      details: store.physicalAddress?.address1,
      icon: <StyledIcon variant="restaurant" color={iconColor} />,
    };
    switch (serviceMode) {
      case ServiceMode.DELIVERY:
        if (deliveryAddress) {
          return {
            ...resultBase,
            heading: formatMessage({ id: 'delivery' }),
            details: deliveryAddress.addressLine1,
            icon: <StyledIcon variant="delivery" color={iconColor} />,
          };
        }
        break;
      case ServiceMode.CURBSIDE:
        if (pickUpBase.details) {
          return {
            ...pickUpBase,
            heading: formatMessage({ id: 'curbside' }),
          };
        }
        break;
      case ServiceMode.TAKEOUT:
        if (pickUpBase.details) {
          return {
            ...pickUpBase,
            heading: formatMessage({ id: 'pickUp' }),
          };
        }
        break;
      case ServiceMode.DRIVE_THRU:
      case ServiceMode.EAT_IN:
        if (pickUpBase.details) {
          return {
            ...pickUpBase,
            heading: formatMessage({ id: serviceModeToIntlId[serviceMode] }),
          };
        }
        break;
      case ServiceMode.CATERING_DELIVERY:
      case ServiceMode.CATERING_PICKUP:
        if (pickUpBase.details) {
          return {
            ...pickUpBase,
            heading: formatMessage({ id: 'catering' }),
            icon: <StyledIcon variant="catering" color={iconColor} />,
          };
        }
        break;
      default:
    }
  }
  return {
    ...resultBase,
    variant: TopServiceVariants.NO_STORE,
  };
};
