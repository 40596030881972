import React, { ReactNode } from 'react';

import { BoxProps } from '@rbilabs/universal-components';

import { StyledLinkExternal } from 'components/app-header/styled';
import { useReversedUIContext } from 'state/reversed-ui';

interface INavLinkExternal extends BoxProps {
  children?: ReactNode;
  isSelected: boolean;
  noBorder: boolean;
  to: string;
}

export const NavLinkExternal: React.FC<React.PropsWithChildren<INavLinkExternal>> = ({
  children,
  isSelected,
  noBorder,
  to,
  ...props
}) => {
  const { reversedUI } = useReversedUIContext();

  return (
    // TODO: RN - Remove this after Link component improvements.
    // @ts-expect-error TS(2322) FIXME: Type '{ _text?: ITextProps | undefined; bg?: Respo... Remove this comment to see the full error message
    <StyledLinkExternal
      children={children}
      to={to}
      selected={isSelected}
      borderStyle={noBorder ? 'none' : 'solid'}
      reversed={reversedUI}
      {...props}
    />
  );
};

export default NavLinkExternal;
