import { useMemo } from 'react';

import { useLocale } from 'state/intl';
import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';

import { tabList as defaultTabList, tabListWithoutEarn } from './tab-list';
import type { LocalizedTab, RawTab } from './types';

type LanguageType = keyof RawTab['link'] & keyof RawTab['text'];

export function localizeTab(rawTab: RawTab, language: LanguageType): LocalizedTab {
  return {
    ...rawTab,
    link: rawTab.link[language],
    text: rawTab.text[language],
  };
}

export function useLocalizedTabs(tabList: Array<RawTab> = defaultTabList): Array<LocalizedTab> {
  const { language } = useLocale();
  const enableInRestaurantLoyalty = useFlag(LaunchDarklyFlag.ENABLE_IN_RESTAURANT_LOYALTY);
  const tabs = enableInRestaurantLoyalty ? defaultTabList : tabListWithoutEarn;
  return useMemo(() => tabs.map(tab => localizeTab(tab, language)), [tabs, language]);
}
