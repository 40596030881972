import React from 'react';

import { Header } from '@rbilabs/universal-components';

import { theme } from 'styles/configure-theme';

type ITopTabLabelProps = { label: string; focused: boolean };

export const TopTabLabel: React.VFC<ITopTabLabelProps> = ({ label, focused }) => {
  return (
    <Header
      variant="headerThree"
      numberOfLines={1}
      textAlign="center"
      color={theme.token(focused ? 'text-active' : 'text-default')}
    >
      {label}
    </Header>
  );
};
