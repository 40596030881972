import { Box, makeUclComponent } from '@rbilabs/universal-components';

import ActionButton from 'components/action-button';

export const StyledButton = makeUclComponent(ActionButton).withConfig({
  fontSize: 'sm',
  lineHeight: 'sm',
  letterSpacing: '0.5px',
});

export const StyledWrapper = Box.withConfig({
  alignItems: 'center',
});
