import React, { VFC } from 'react';

import { Icon } from '@rbilabs/universal-components';
import { useIntl } from 'react-intl';

import ActionButton, { ActionButtonVariants } from 'components/action-button';
import { BackArrowHistory } from 'components/navigation/back-arrow';
import { useIsTabletBp } from 'hooks/breakpoints';

import { SHOW_DESKTOP_CUSTOMIZATION_BACK_ON_NAVBAR } from './constants';
import { BackButtonContainer } from './product-customization-back-button.styled';
import { IProductCustomizationBackButton } from './types';

export const ProductCustomizationBackButton: VFC<IProductCustomizationBackButton> = ({
  onBackButtonClick,
}) => {
  const { formatMessage } = useIntl();
  const isTablet = useIsTabletBp();

  return (
    <>
      {isTablet && !SHOW_DESKTOP_CUSTOMIZATION_BACK_ON_NAVBAR ? (
        <BackButtonContainer>
          <ActionButton
            startIcon={<Icon variant="back" />}
            color="icon-header-contrast"
            variant={ActionButtonVariants.TEXT_ONLY}
            // @ts-expect-error TS(2322) FIXME: Type '{ children: string; startIcon: Element; colo... Remove this comment to see the full error message
            onClick={onBackButtonClick}
          >
            {formatMessage({ id: 'backToSummary' })}
          </ActionButton>
        </BackButtonContainer>
      ) : (
        <BackArrowHistory onPress={onBackButtonClick} />
      )}
    </>
  );
};
