import React from 'react';

import { BrandLogo, useBreakpointValue } from '@rbilabs/universal-components';

import { useBrandLogoVariant } from 'hooks/use-brand-logo-variant';
import { useReversedUIContext } from 'state/reversed-ui';

import { theme, themeLgDesktop } from './theme';
import { ILogoProps, ILogoTheme } from './types';

const Logo: React.FC<ILogoProps> = ({ reversed, ...props }) => {
  const { reversedUI } = useReversedUIContext();
  const { brandPrimaryLogoVariant, brandLogoWordmarkVariant } = useBrandLogoVariant();

  const responsiveTheme: ILogoTheme = useBreakpointValue({
    base: theme,
    lg: themeLgDesktop ?? theme,
  });

  const variant = useBreakpointValue({
    base: brandPrimaryLogoVariant,
    lg: brandLogoWordmarkVariant,
  });

  const isReversed = reversed ?? (reversedUI || responsiveTheme.reversedColor);

  return <BrandLogo reversed={isReversed} variant={variant} {...responsiveTheme} {...props} />;
};

export default Logo;
