import keyBy from 'lodash/keyBy';

import type { IQuest } from 'components/quests/loyalty-quest/types';

import type { ICmsQuestCardList } from './cms-hooks/types';
import type { CardListItem } from './types';

export const getProductRequirementCmsIds = (quest: IQuest): string[] => {
  if (!quest || !quest?.steps) {
    return [];
  }

  // @ts-expect-error TS(2769) FIXME: No overload matches this call.
  const uniqueCmsIds = quest.steps.reduce((stepIdSet, step) => {
    return step.cartRequirements?.reduce((cartReqIdSet, cartReq) => {
      return cartReq?.reduce((prodReqIdSet, prodReq) => {
        return prodReqIdSet.add(prodReq?.id);
      }, cartReqIdSet);
    }, stepIdSet);
  }, new Set<string>());

  // @ts-expect-error TS(2769) FIXME: No overload matches this call.
  return Array.from(uniqueCmsIds ?? []);
};

export const createCardListData = (
  loyaltyQuests: IQuest[],
  cmsQuests: ICmsQuestCardList
): CardListItem[] => {
  // create the list item comparing the lyl quest `cmsId` and cms quest `_id`
  const loyaltyQuestMapByCmsId = keyBy(loyaltyQuests, 'cmsId');

  const cardListData = cmsQuests.reduce<CardListItem[]>((accum, cmsQuest) => {
    const loyaltyQuest = cmsQuest?._id ? loyaltyQuestMapByCmsId[cmsQuest._id] : null;

    if (cmsQuest && loyaltyQuest) {
      accum.push({
        cmsQuestCard: cmsQuest,
        loyaltyQuest,
      });
    }
    return accum;
  }, []);

  return cardListData;
};
