import React, { useCallback, useEffect, useMemo, useState } from 'react';

import './polyfill';

import { PortalRenderer } from '@rbilabs/universal-components';
import * as SplashScreen from 'expo-splash-screen';
import { Platform, View } from 'react-native';

import { AppContainer } from 'components/app-container';
import ErrorBoundary from 'components/error-boundary-static';
import ReduceProviders from 'components/reduce-providers';
import { initStatuses, useAppBlockerInit } from 'hooks/use-app-init';
import { theme as uclTheme } from 'styles/theme';
import 'utils/accessibility/text-size';
import 'utils/keyboard/keyboard-config';
import { brand, isLocalDev, isTest, platform } from 'utils/environment';
import { EventName, emitEvent } from 'utils/event-hub';
import LocalStorage from 'utils/local-storage';
import { initializePerformanceMonitors } from 'utils/performance';
import { Measures, PerformanceMarks, logMeasurement, setMark } from 'utils/timing';

import NavigationContainer from './navigation/navigation-container';
import { Router } from './router';
import { IAppProps } from './types';

// call this immediately per docs
// @see https://docs.expo.dev/versions/latest/sdk/splash-screen/#splashscreenpreventautohideasync
SplashScreen.preventAutoHideAsync();

// Added this to prevent crash on Android
// See https://github.com/andyearnshaw/Intl.js#regexp-cache--restore
// and https://github.com/expo/expo/issues/6536
if (Platform.OS === 'android') {
  if (typeof (Intl as any).__disableRegExpRestore === 'function') {
    (Intl as any).__disableRegExpRestore();
  }
}

// Create start time before trying to render the app for true performance numbers
const LOADING_START_TIME = performance.now?.() ?? 0;

// Log an event to Google Analytics
// TODO: RN - @react-native-firebase/analytics causes iOS to crash
// Fix this before continuing analytics work
// analytics().logEvent('app_loaded', {
//   isReactNative: true,
// });

let FlipperAsyncStorage: any;
const isFlipperAsyncStorageEnabled = __DEV__ && Platform.OS !== 'web';
if (isFlipperAsyncStorageEnabled) {
  // Setup performance flipper reporter only for dev
  // https://docs.expo.dev/workflow/development-mode/#production-mode
  require('react-native-performance-flipper-reporter').setupDefaultFlipperReporter();
  FlipperAsyncStorage = require('rn-flipper-async-storage-advanced').default;
}

const App: React.VFC<IAppProps> = ({ sanityAssets, onErrorBoundaryRequestReload }) => {
  const [isSplashScreenRendered, setIsSplashScreenRendered] = useState<boolean>(true);
  const blockingStatus = useAppBlockerInit({
    cachedFonts: uclTheme.cachedFonts,
    sanityAssets,
  });

  const appIsReady = useMemo(() => blockingStatus !== initStatuses.LOADING, [blockingStatus]);

  // TODO: RN - properly initialize GTM in RN.
  useEffect(() => {
    if (!isLocalDev) {
      const renderSuccessTime = performance.now?.();
      const durationTillRenderSuccess = renderSuccessTime - LOADING_START_TIME;
      (window.dataLayer || []).push({
        event: 'APP_REACT_RENDER_SUCCESS',
        brand: brand(),
        timestamp: renderSuccessTime,
        duration: durationTillRenderSuccess,
        platform: platform(),
      });
    }
  }, []);

  useEffect(() => {
    if (blockingStatus === initStatuses.INITIALIZED) {
      // NOTE: cypress-v2 test suite requirement
      // Avoid localStorage clearing. Cypress does this automatically between tests.
      const shouldClear = !LocalStorage.isCurrentVersion();

      if (shouldClear && !isTest) {
        LocalStorage.clearAll();
        LocalStorage.setCurrentVersion();
      }
    }
  }, [blockingStatus]);

  // @see https://docs.expo.dev/versions/latest/sdk/splash-screen
  const onLayoutRootView = useCallback(async () => {
    if (appIsReady) {
      await SplashScreen.hideAsync();
      setIsSplashScreenRendered(false);
    }
  }, [appIsReady]);

  let AppContent = null;

  switch (blockingStatus) {
    default:
    case initStatuses.LOADING:
      setMark(PerformanceMarks.APP_LOADING_START);
      break;
    // TODO: Create a new error screen for when the app fails to load
    case initStatuses.ERROR:
      AppContent = (
        <NavigationContainer>
          <Router>
            <ReduceProviders
              uclTheme={uclTheme}
              sanityAssets={sanityAssets}
              onErrorBoundaryRequestReload={onErrorBoundaryRequestReload}
            >
              <ErrorBoundary onRequestReload={onErrorBoundaryRequestReload} />
              <PortalRenderer />
            </ReduceProviders>
          </Router>
        </NavigationContainer>
      );
      break;
    case initStatuses.INITIALIZED:
      logMeasurement(Measures.APP_LOADING, PerformanceMarks.APP_LOADING_START);
      AppContent = (
        // TODO: ReactNavigation - Investigate how to render these Routers conditionally based on the FF
        <NavigationContainer>
          <Router>
            {isFlipperAsyncStorageEnabled && <FlipperAsyncStorage />}
            <ReduceProviders
              uclTheme={uclTheme}
              sanityAssets={sanityAssets}
              onErrorBoundaryRequestReload={onErrorBoundaryRequestReload}
            >
              <AppContainer />
              <PortalRenderer />
            </ReduceProviders>
          </Router>
        </NavigationContainer>
      );
      break;
  }

  if (!appIsReady) {
    return null;
  }

  return (
    <View style={{ flex: 1 }} onLayout={onLayoutRootView}>
      {!isSplashScreenRendered && AppContent}
    </View>
  );
};

setMark(PerformanceMarks.APP_RN_TSX_START);

// We need to initialize the performance event handlers before any event is fired.
initializePerformanceMonitors();
emitEvent(EventName.JAVASCRIPT_START);

export default App;
