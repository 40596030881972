import { HandlerType, StatusType, datadogLogs } from '@datadog/browser-logs';
import { datadogRum } from '@datadog/browser-rum';

import { Loggable } from '@rbi-ctg/frontend';
import AuthStorage from 'utils/cognito/storage';
import { loadRegion } from 'utils/intl/region';
import { StorageKeys } from 'utils/local-storage';

import {
  RBIEnv,
  brand,
  checkRbiEnv,
  env,
  getConfigValue,
  getCurrentCommitHash,
  isLocalDev,
  platform,
} from '../environment';

import { makeBeforeSendCallback } from './make-before-send-callback';

export { StatusType } from '@datadog/browser-logs';

interface Context {
  [x: string]: Loggable | Loggable[];
}

interface DataDogLogger {
  context?: Context;
  message: string | Error;
  status: StatusType;
}

export function init(configuredSampleRate?: number): void {
  let handler: HandlerType = HandlerType.http;

  if (checkRbiEnv(RBIEnv.TEST)) {
    handler = HandlerType.silent;
  }

  const defaultRate = isLocalDev ? 100 : 1;
  const sampleRate = typeof configuredSampleRate === 'number' ? configuredSampleRate : defaultRate;
  const dataDogConfig = getConfigValue({
    key: 'datadog',
    region: loadRegion(),
    defaultValue: { logs: {} },
  });
  const datacenter = dataDogConfig.logs.dataCenter ?? 'us';
  const clientToken = dataDogConfig.logs.clientToken;
  const currentEnv = env();

  datadogLogs.init({
    clientToken,
    datacenter,
    forwardErrorsToLogs: true,
    env: currentEnv,
    service: `${brand()}_${platform()}`,
    version: getCurrentCommitHash(),
    beforeSend: makeBeforeSendCallback(sampleRate),
  });

  datadogLogs.logger.setHandler(handler);
}

const UNKNOWN_ERROR_MESSAGE: string = 'Unknown application error. Unable to parse error message.';

export function dataDogLogger({ message, context = {}, status }: DataDogLogger): void {
  const currentEnv = env();
  const ctx = {
    appVersionCode: getCurrentCommitHash(),
    brand: brand(),
    platform: platform(),
    stage: currentEnv,
    userId: AuthStorage?.getItem(StorageKeys.USER_AUTH_TOKEN) ?? undefined,
    ...context,
  };

  const strMessage: string =
    typeof message === 'string' ? message : message?.message || UNKNOWN_ERROR_MESSAGE;

  datadogLogs.logger.log(strMessage, ctx, status);
}

export function addContext(key: string, value: string) {
  datadogLogs.logger.addContext(key, value);
}

export function setUser(user: { id: string }) {
  if (user.id) {
    datadogRum.setUser(user);
  } else {
    datadogRum.removeUser();
  }
}
