import { useMemo } from 'react';

import { IHomePageConfigurationFragment } from 'generated/sanity-graphql';

import {
  FeatureHomePageComponentTypes,
  FeatureHomePageComponents,
  IRawFeatureHomePageComponent,
} from '../types';

import { useAlert } from './use-alert';
import { useHero } from './use-hero';
import { useMainHero } from './use-main-hero';
import { useMarketingCardGroups } from './use-marketing-card-groups';
import { useMarketingTileGroups } from './use-marketing-tile-groups';
import { useRewardsSection } from './use-rewards-section';

const ID_ONLY_COMPONENTS = [
  FeatureHomePageComponentTypes.LockedOffers,
  FeatureHomePageComponentTypes.OffersSection,
  FeatureHomePageComponentTypes.RecentItems,
  FeatureHomePageComponentTypes.QuestsSection,
];

const MULTIPLE_INSTANCE_COMPONENTS = [
  FeatureHomePageComponentTypes.MarketingCardGroup,
  FeatureHomePageComponentTypes.MarketingTileGroup,
];

/**
 * @name formatIdOnlyComponents
 * @description
 *  At this point there are some components we only care about their _id
 *    and we don't need to query for them because we already have the _id
 *    from the original homepage query.
 *  So, lets just format them to mirror the queried components
 */
const formatIdOnlyComponents = (featureHomePageComponentMap: any) => {
  return ID_ONLY_COMPONENTS.reduce((acc, compType) => {
    if (featureHomePageComponentMap[compType]) {
      acc[compType] = {
        _id: featureHomePageComponentMap[compType],
        __typename: compType,
      };
    }
    return acc;
  }, {});
};

const orderPopulatedFeatureHomePageComponents = (
  allPopulatedComponents: FeatureHomePageComponents,
  featureHomePage?: IHomePageConfigurationFragment
) => {
  const components = (featureHomePage?.components ?? []).reduce(
    // @ts-expect-error TS(2769) FIXME: No overload matches this call.
    (acc: FeatureHomePageComponents[], rawComp: IRawFeatureHomePageComponent) => {
      if (rawComp?.__typename && allPopulatedComponents[rawComp?.__typename]) {
        const allowedMultipleInstances = MULTIPLE_INSTANCE_COMPONENTS.includes(rawComp.__typename);
        // These types are allowed multiple instances so we need to find the right one
        if (allowedMultipleInstances) {
          const specificItem = allPopulatedComponents[rawComp.__typename].find(
            (popComp: any) => popComp._id === rawComp._id
          );
          if (specificItem) {
            acc.push(specificItem);
          }
        }
        // all others are limited to one instance so just push the one
        else {
          acc.push(allPopulatedComponents[rawComp?.__typename]);
        }
      }
      return acc;
    },
    [] as FeatureHomePageComponents[]
  );
  return components;
};

// We only want the home page to act in a "loading" behavior for the first component.
// While we have to fetch each component individually, the first component is the one that should
// be blocking. The rest can pop in after
function loadingStateBasedOnFirstComponent(
  typename: FeatureHomePageComponentTypes,
  loadingMap: Record<FeatureHomePageComponentTypes, boolean>
) {
  return loadingMap[typename];
}

export const useFeatureHomePageComponents = (featureHomePage?: IHomePageConfigurationFragment) => {
  /**
   * @name featureHomePageComponentMap
   * @description
   *  maps raw feature home page components to { type: id } structure
   *  to simplify querying for the different component types
   */
  // @ts-expect-error TS(2322) FIXME: Type '{ readonly __typename: "Alert"; readonly _id... Remove this comment to see the full error message
  const featureHomePageComponentMap: FeatureHomePageComponents = (featureHomePage?.components ?? [])
    // @ts-expect-error TS(2769) FIXME: No overload matches this call.
    .reduce((acc: any, comp: IRawFeatureHomePageComponent) => {
      const allowedMultipleInstances = MULTIPLE_INSTANCE_COMPONENTS.includes(comp.__typename);
      // These types are allowed multiple instances
      // setup the component type as an array of ids
      if (allowedMultipleInstances) {
        if (!acc[comp.__typename]) {
          acc[comp.__typename] = [];
        }
        acc[comp.__typename].push(comp._id);
      }
      // all others are limited to one instance so just one id
      else {
        acc[comp.__typename] = comp._id;
      }
      return acc;
    }, {});

  /**
   * ==================================
   *    INDIVIDUAL COMPONENT QUERIES
   * ==================================
   */

  const { mainHero, mainHeroLoading } = useMainHero(featureHomePageComponentMap.MainHero);

  const { hero, heroLoading } = useHero(featureHomePageComponentMap.Hero);

  const { alert, alertLoading } = useAlert(featureHomePageComponentMap.Alert);

  const { marketingCardGroups, marketingCardGroupsLoading } = useMarketingCardGroups(
    featureHomePageComponentMap.MarketingCardGroup
  );

  const { marketingTileGroups, marketingTileGroupsLoading } = useMarketingTileGroups(
    featureHomePageComponentMap.MarketingTileGroup
  );

  const { rewardsSection, rewardsSectionLoading } = useRewardsSection(
    featureHomePageComponentMap.RewardsSection
  );

  /**
   * ==================================
   *   MERGE POPULATED COMPONENT DATA
   * ==================================
   */
  const allPopulatedComponents: FeatureHomePageComponents = {
    [FeatureHomePageComponentTypes.Alert]: alert,
    [FeatureHomePageComponentTypes.Hero]: hero,
    [FeatureHomePageComponentTypes.MainHero]: mainHero,
    [FeatureHomePageComponentTypes.MarketingTileGroup]: marketingTileGroups,
    [FeatureHomePageComponentTypes.MarketingCardGroup]: marketingCardGroups,
    [FeatureHomePageComponentTypes.RewardsSection]: rewardsSection,
    ...formatIdOnlyComponents(featureHomePageComponentMap),
  };

  /**
   * ==================================
   *   ORDER POPULATED COMPONENT DATA
   * ==================================
   */
  const orderedPopulatedFeatureHomePageComponents = useMemo(
    () => orderPopulatedFeatureHomePageComponents(allPopulatedComponents, featureHomePage),
    [allPopulatedComponents, featureHomePage]
  );

  /**
   * ==================================
   *    DETERMINE LOADING STATE
   * ==================================
   */
  const firstComponentType = featureHomePage?.components?.[0]
    ?.__typename as FeatureHomePageComponentTypes;

  const featureHomePageComponentsLoading = loadingStateBasedOnFirstComponent(firstComponentType!, {
    MainHero: mainHeroLoading,
    Hero: heroLoading,
    Alert: alertLoading,
    MarketingCardGroup: marketingCardGroupsLoading,
    MarketingTileGroup: marketingTileGroupsLoading,
    RewardsSection: rewardsSectionLoading,

    // These components are required in the type, but not loaded on the home page.
    // So forcing to false to make typescript happy
    OffersSection: false,
    LockedOffers: false,
    RecentItems: false,
    QuestsSection: false,
  });

  /**
   * ==================================
   *            RETURN DATA
   * ==================================
   */
  return {
    featureHomePageComponentsLoading,
    featureHomePageComponents: orderedPopulatedFeatureHomePageComponents,
  };
};
