import { useCallback } from 'react';

import { useApolloClient } from '@apollo/client';
import has from 'lodash/has';

import { IStore } from '@rbi-ctg/store';
import {
  GetRestaurantLoyaltyEnabledDocument,
  IGetRestaurantLoyaltyEnabledQuery,
} from 'generated/sanity-graphql';
import { ServiceMode } from 'state/service-mode';
import { useStoreContext } from 'state/store';
import { useSanityGqlEndpoint } from 'utils/network';

export const useEvaluateLoyaltyAtRestaurant = () => {
  const { getStoreStatusFlags } = useStoreContext();
  const client = useApolloClient();
  const uri = useSanityGqlEndpoint();

  const evaluateLoyaltyAtRestaurant = useCallback(
    async (storeToCheck: IStore, serviceModeToCheck?: ServiceMode | null): Promise<boolean> => {
      const { isStoreOpenAndAvailable } = getStoreStatusFlags(storeToCheck, serviceModeToCheck);
      if (!isStoreOpenAndAvailable) {
        return false;
      }

      // the restaurant is stored in the local storage and this property may not exist
      if (has(storeToCheck, 'hasLoyalty')) {
        return Boolean(storeToCheck.hasLoyalty);
      }

      try {
        const response = await client.query<IGetRestaurantLoyaltyEnabledQuery>({
          query: GetRestaurantLoyaltyEnabledDocument,
          context: { uri },
          variables: {
            storeId: storeToCheck._id,
          },
        });

        return Boolean(response?.data?.Restaurant?.hasLoyalty);
      } catch (e) {
        // TODO: do something
        return false;
      }
    },
    [client, getStoreStatusFlags, uri]
  );

  return { evaluateLoyaltyAtRestaurant };
};
