import * as React from 'react';

import { HStack } from '@rbilabs/universal-components';
import { useIntl } from 'react-intl';

import ActionButton, { ActionButtonSizes, ActionButtonVariants } from 'components/action-button';
import { LoyaltyPointsButton } from 'components/loyalty-points-button';
import { DisabledLoyaltyPointsButton_SimplifiedLandingPage__Experiment } from 'experiments/simplified-landing-page';
import { useAuthContext } from 'state/auth';
import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';
import { routes } from 'utils/routing';
import { HEADER_SIGNUP_BUTTON } from 'utils/test-ids';

import { AppHeaderCartButton } from './app-header-cart-button';

const StyledHStack = HStack.withConfig({
  mr: { base: '$0', md: '$4' },
  space: '$2',
});

const MobileHeaderCta = () => {
  const { formatMessage } = useIntl();

  const enableSignUp = useFlag(LaunchDarklyFlag.ENABLE_SIGN_UP);
  const { isAuthenticated } = useAuthContext();

  if (isAuthenticated) {
    return (
      <StyledHStack>
        <DisabledLoyaltyPointsButton_SimplifiedLandingPage__Experiment>
          <LoyaltyPointsButton />
        </DisabledLoyaltyPointsButton_SimplifiedLandingPage__Experiment>
        <AppHeaderCartButton />
      </StyledHStack>
    );
  }

  if (!enableSignUp) {
    return (
      <StyledHStack>
        <AppHeaderCartButton />
      </StyledHStack>
    );
  }

  return (
    <StyledHStack>
      <ActionButton
        variant={ActionButtonVariants.OUTLINE}
        size={ActionButtonSizes.SMALL}
        to={routes.signUp}
        testID={HEADER_SIGNUP_BUTTON}
      >
        {formatMessage({ id: 'signUp' })}
      </ActionButton>
      <AppHeaderCartButton />
    </StyledHStack>
  );
};

export default MobileHeaderCta;
