import { Box, HStack, Pressable, Text, VStack } from '@rbilabs/universal-components';

import { theme } from 'styles/configure-theme';

export const Container = HStack.withConfig({
  flex: 1,
  justifyContent: 'flex-end',
  alignItems: 'center',
});

export const ClickableWrapper = Pressable.withConfig({
  flexDirection: 'row',
  alignItems: 'center',
  marginRight: '$6',
});

export const IconContainer = Box.withConfig({
  marginLeft: '$5',
  marginRight: '$2',
});

export const DetailsContainer = VStack.withConfig({
  alignItems: 'flex-start',
  borderRightColor: 'token.border-color-default',
  borderRightWidth: 1,
  paddingRight: '$6',
});

export const Heading = Text.withConfig<{ reversedUI: boolean }>(p => ({
  fontFamily: 'heading',
  numberOfLines: 1,
  fontSize: 'xs',
  lineHeight: '14px',
  marginBottom: '$1',
  color: p.reversedUI ? theme.token('text-reversed') : theme.token('text-default'),
}));

export const Details = Text.withConfig<{ reversedUI: boolean }>(p => ({
  flex: 1,
  overflow: 'hidden',
  fontSize: 'xs',
  lineHeight: '14px',
  backgroundColor: 'red',
  underline: true,
  color: p.reversedUI ? theme.token('text-reversed') : theme.token('text-default'),
}));
