import React, { useCallback, useEffect } from 'react';

import { Box } from '@rbilabs/universal-components';

import { IMainMenuViewOption } from '@rbi-ctg/menu';
import CateringConfirmationDataSheet from 'components/catering-confirmation-datasheet';
import LoadingAnimation from 'components/loading-animation';
import LoadingContainer from 'components/loading-animation/loading-container';
import MenuTileGrid from 'components/menu-tile-grid';
import StoreSelected from 'components/store-selected';
import { TabbedHeader } from 'components/tabbed-header';
import { useFocusEffect } from 'hooks/navigation/use-focus-effect';
import { useNavigation } from 'hooks/navigation/use-navigation';
import { useRumPageView } from 'hooks/rum/use-rum-page-view';
import { useFeatureMenu } from 'hooks/use-feature-menu';
import useRoutes from 'hooks/use-routes';
import { useRenderTabbedMenuHeader } from 'hooks/use-tabbed-menu-header';
import { useDayPartContext } from 'state/day-part';
import { useLocale } from 'state/intl';
import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';
import { useMainMenuContext } from 'state/main-menu';
import { useMenuContext } from 'state/menu';
import { useServiceModeContext } from 'state/service-mode';
import { useStoreContext } from 'state/store';
import { isWeb } from 'utils/environment';
import { EventName, emitEvent } from 'utils/event-hub';
import { MAIN_MENU_PAGE_CONTAINER } from 'utils/test-ids';

import { StoreClosedConfirmation } from './store-closed-confirmation';
import { excludeDisabledMenuSections } from './utils';

const MainContent = Box.withConfig({ flex: 1 });

const MainMenu = () => {
  useRumPageView('main-menu', 'Main Menu');
  const { region } = useLocale();
  const { showStaticMenu, showBurgersForBreakfast } = useMenuContext();
  const { menuId } = useFeatureMenu();
  const { getMainMenu, data, loading: menuDataLoading, storeMenuLoading } = useMainMenuContext();
  const { serviceMode, isCatering } = useServiceModeContext();
  const { isStoreClosed, store } = useStoreContext();
  const storeId = store.number || '';
  const enableFutureOrdering = useFlag(LaunchDarklyFlag.ENABLE_FUTURE_ORDERING);
  const enableCateringDialog = useFlag(LaunchDarklyFlag.ENABLE_CATERING_CONFIRMATION_DIALOG);

  const shouldRenderTabbedHeader = useRenderTabbedMenuHeader();
  const { navigate } = useNavigation();
  const { getLocalizedRouteForPath } = useRoutes();

  useEffect(() => {
    getMainMenu(menuId, region, storeId);
  }, [getMainMenu, menuId, region, storeId]);

  useEffect(() => {
    if (!enableFutureOrdering && isStoreClosed) {
      navigate(getLocalizedRouteForPath('store-locator'));
    }
  });

  useFocusEffect(
    useCallback(() => {
      emitEvent(EventName.SCREEN_RENDER, {
        screenId: 'menu',
      });
    }, [])
  );

  const { activeDayParts } = useDayPartContext();
  const dataOptions = excludeDisabledMenuSections({
    menuData: data?.Menu?.options as IMainMenuViewOption[] | undefined,
    activeDayParts,
    serviceMode,
    showStaticMenu,
    showBurgersForBreakfast,
  });

  const loading = menuDataLoading || storeMenuLoading;

  return (
    <StoreSelected>
      <MainContent testID={MAIN_MENU_PAGE_CONTAINER}>
        {shouldRenderTabbedHeader && (
          <TabbedHeader menuData={data?.Menu} menuDataLoading={loading} />
        )}
        {loading ? (
          <LoadingContainer>
            <LoadingAnimation />
          </LoadingContainer>
        ) : (
          dataOptions && <MenuTileGrid data={dataOptions} />
        )}
      </MainContent>
      {isCatering && enableCateringDialog && <CateringConfirmationDataSheet />}
      {isWeb && <StoreClosedConfirmation />}
    </StoreSelected>
  );
};

export default MainMenu;
