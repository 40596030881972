import { AllowedEvent } from '@rbilabs/mparticle-client';
import { compact, isNil } from 'lodash-es';

import { NonNullableObject } from '@rbi-ctg/frontend';
import { IBackendCartEntries, ICartEntry, IServerOrder } from '@rbi-ctg/menu';
import { IAmplitudeProduct, IAmplitudeSublevelItem, ProductItemType } from 'state/amplitude/types';
import { CartPaymentType } from 'state/order/types';
import { ServiceMode } from 'state/service-mode/types';
import { CartEntryType } from 'utils/cart/types';
import { centsToDollars } from 'utils/index';
import logger from 'utils/logger';

import { FALSE, FALSE_VALS, TRUE, TRUE_VALS } from './constants';
import { ICRMEventsAttributes } from './types';

// Removes null, undefined and empty string values
export function sanitizeValues<M extends object = ICRMEventsAttributes>(
  attributes: M
): NonNullableObject<Partial<M>> {
  return Object.entries(attributes).reduce((memo, [key, value]) => {
    const attrIsEmptyString = typeof value === 'string' && value.length === 0;
    if (!isNil(value) && !attrIsEmptyString) {
      memo[key] = value;
    }
    return memo;
  }, {} as NonNullableObject<Partial<M>>) as NonNullableObject<Partial<M>>;
}

export const booleanToString = (bool: boolean): typeof TRUE | typeof FALSE => (bool ? TRUE : FALSE);

const normalizeStringBoolean = (str: string): string => {
  if (TRUE_VALS.includes(str)) {
    return TRUE;
  }
  if (FALSE_VALS.includes(str)) {
    return FALSE;
  }
  return str;
};

/**
 * Slices string values that are too large for Amplitude (starting from the 0 index).
 * Amplitude max string length is 1024
 * https://help.amplitude.com/hc/en-us/articles/115002923888#h_84aa2818-b187-4a80-b838-4ebd11b36904
 */
export const toAttributesWithValidLengthValues = (attributes: Record<string, any>) => {
  const attributesCopy = { ...attributes };

  const STRING_VALUE_LENGTH_LIMIT = 1024;
  const invalidStringKeys: string[] = [];
  const reduceFn = (acc: object, [key, value]: [string, any]) => {
    const isString = value === 'string';
    if (isString && value.length > STRING_VALUE_LENGTH_LIMIT) {
      invalidStringKeys.push(key);
    }
    const validValue =
      typeof value === 'string' ? value.slice(0, STRING_VALUE_LENGTH_LIMIT) : value;
    return { ...acc, [key]: validValue };
  };
  if (invalidStringKeys.length) {
    logger.warn({
      message: `Error attribute keys were sliced when logging to Amplitude because they were too large: ${invalidStringKeys.join(
        ', '
      )}`,
    });
  }
  return Object.entries(attributesCopy).reduce(reduceFn, {});
};

/**
 * Converts any boolean values to strings
 * eg true => "True"
 */
export function normalizeBooleans<M extends object = ICRMEventsAttributes>(
  attributes: M
): NonNullableObject<Partial<M>> {
  const copy: Partial<M> = {};
  Object.keys(attributes).forEach(key => {
    const value = attributes[key];
    if (typeof value === 'boolean') {
      copy[key] = booleanToString(value);
    } else if (typeof value === 'string') {
      copy[key] = normalizeStringBoolean(value);
    } else {
      copy[key] = value;
    }
  });
  return copy as NonNullableObject<Partial<M>>;
}

export function flattenCartEntryItems(cartEntry: ICartEntry): ICartEntry[] {
  const cartEntryCopy = { ...cartEntry };
  const children = cartEntryCopy.children.reduce((accum: ICartEntry[], current) => {
    return [...accum, ...flattenCartEntryItems(current)];
  }, []);
  return [cartEntryCopy, ...children];
}

const createSublevelItems = (cartEntry: ICartEntry): IAmplitudeSublevelItem[] => {
  const subItems = flattenCartEntryItems(cartEntry).filter(
    item => item._id !== cartEntry._id && item.type === CartEntryType.item
  );
  // Merge sublevel items by item id
  const mergedItemsById = subItems.reduce<{
    [id: string]: IAmplitudeSublevelItem | undefined;
  }>((acc, item) => {
    const curItem = acc[item._id];
    if (curItem) {
      curItem.quantity += item.quantity;
    } else {
      acc[item._id] = { id: item._id, quantity: item.quantity };
    }
    return acc;
  }, {});
  return compact(Object.values(mergedItemsById));
};

const createSublevelProducts = (cartEntry: ICartEntry): IAmplitudeProduct[] => {
  const subProducts: IAmplitudeProduct[] = [];
  // Get cart entry sublevel items
  const subItems = flattenCartEntryItems(cartEntry);

  // Merge sublevel items by item id
  for (const subItem of subItems) {
    if (
      subItem._id !== cartEntry._id &&
      subItem.type === CartEntryType.item &&
      subItem.productHierarchy
    ) {
      const product = createProduct(subItem);
      if (product) {
        product.custom_attributes.comboChild = booleanToString(true);
        product.comboChild = booleanToString(true);
        subProducts.push(product);
      }
    }
  }
  return subProducts;
};

export const reformatAttributesForSingularProduct = (product: IAmplitudeProduct) => {
  const newAttributes: Record<string, any> = { ...product };

  // remap product attributes
  newAttributes.Name = product.name ?? '';
  newAttributes.Id = product.id ?? '';
  newAttributes.Price = String(product.price ?? '');
  newAttributes.Quantity = String(product.quantity ?? '');
  newAttributes['Total Product Amount'] = String(product.total_product_amount ?? '');

  // remove old attributes
  delete newAttributes.id;
  delete newAttributes.name;
  delete newAttributes.quantity;
  delete newAttributes.price;
  delete newAttributes.total_product_amount;

  return newAttributes;
};

export const createProduct = (
  cartEntry: ICartEntry | IBackendCartEntries
): IAmplitudeProduct | null => {
  const _id = '_id' in cartEntry ? cartEntry._id : cartEntry.sanityId;
  if (!_id) {
    return null;
  }
  const cartId = 'lineId' in cartEntry ? cartEntry.lineId : cartEntry.cartId;
  const { name = '', price, quantity, isDonation = false, isExtra = false } = cartEntry;
  const basePrice = price ? centsToDollars(price / quantity) : 0;
  const productSublevelItems = createSublevelItems(cartEntry as ICartEntry);
  const itemLevel =
    productSublevelItems.length === 0 ? ProductItemType.Child : ProductItemType.Parent;

  const productAttrs = {
    cartId: cartId || _id,
    comboChild: booleanToString(false),
    isDonation: booleanToString(isDonation),
    isExtra: booleanToString(isExtra),
    'Item Level': itemLevel,
    sublevelItems: JSON.stringify(productSublevelItems),
  };
  const hierarchyData = {
    L1: cartEntry.productHierarchy?.L1 || '',
    L2: cartEntry.productHierarchy?.L2 || '',
    L3: cartEntry.productHierarchy?.L3 || '',
    L4: cartEntry.productHierarchy?.L4 || '',
    L5: cartEntry.productHierarchy?.L5 || '',
  };
  const product: IAmplitudeProduct = {
    name,
    id: _id,
    quantity,
    price: basePrice,
    total_product_amount: basePrice * quantity,
    custom_attributes: {
      ...productAttrs,
      ...(itemLevel === ProductItemType.Child && { ...hierarchyData }),
      sublevelItems: JSON.stringify(productSublevelItems),
    },
    ...(itemLevel === ProductItemType.Child && { ...hierarchyData }),
    ...productAttrs,
  };

  return product;
};

/**
 * @param serverOrder IServerOrder
 * @param cartEntries ICartEntry[]
 * @returns {IAmplitudeProduct[]} IAmplitudeProduct[] CRM products array
 */
export function createCRMProducts({
  serverOrder,
  cartEntries,
}: {
  serverOrder: IServerOrder;
  cartEntries: ICartEntry[];
}) {
  const products: IAmplitudeProduct[] = cartEntries.reduce(
    (accumulator: IAmplitudeProduct[], cartEntry) => {
      const eCommerceProduct = createProduct(cartEntry);
      if (!eCommerceProduct) {
        return accumulator;
      }

      const sublevelProducts = createSublevelProducts(cartEntry);
      const rewardApplied = serverOrder.cart.rewardsApplied?.find(
        reward => reward?.cartId === eCommerceProduct.custom_attributes?.cartId
      );

      eCommerceProduct.rewardItem = booleanToString(!!rewardApplied);
      eCommerceProduct.custom_attributes = {
        ...eCommerceProduct.custom_attributes,
        rewardItem: booleanToString(!!rewardApplied),
      };

      return [...accumulator, eCommerceProduct, ...sublevelProducts];
    },
    []
  );
  return products;
}

interface FlattenedObject {
  [key: string]: any;
}

function flattenObject(obj: object, prefix = 'products'): FlattenedObject {
  const result: object = {};
  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      const propName = prefix ? `${prefix}.${key}` : key;
      const value = obj[key];
      if (typeof value === 'object' && value !== null) {
        Object.assign(result, flattenObject(value, propName));
      } else {
        result[propName] = value;
      }
    }
  }
  return result;
}

/**
 * In order to query event properties on eCommerce events,
 * we must flatten nested product information.
 * For example: if we have a cart with two products: `product: { name: "chicken" }` and `product: { name: "pie" }`
 * We must transform this to:
 * `product.name: ["chicken", "pie"]`
 * This allows digital teams to write queries like "get me all purchases that included product.name = 'chicken'""
 */
export function expandProductAttributes(products: IAmplitudeProduct[]) {
  const expandedObjects: Record<string, any[]> = {};
  for (const obj of products) {
    const flattenedObj = flattenObject(obj, 'products');
    const expandedObj: object = {};

    for (const key in flattenedObj) {
      if (flattenedObj.hasOwnProperty(key)) {
        const existingValue = expandedObj[key];
        if (existingValue) {
          expandedObj[key] = [...existingValue, flattenedObj[key]];
        }
        expandedObj[`${key}`] = flattenedObj[key];
      }
    }

    for (const key in expandedObj) {
      const existingValue = expandedObjects[key] ?? null;
      if (existingValue) {
        expandedObjects[key] = [...existingValue, expandedObj[key]];
      } else {
        expandedObjects[key] = [expandedObj[key]];
      }
    }
  }
  return expandedObjects;
}

export function serializePaymentType(paymentType: CartPaymentType | null) {
  switch (paymentType) {
    case CartPaymentType.APPLE_PAY:
      return 'APPLE_PAY';
    case CartPaymentType.GOOGLE_PAY:
      return 'GOOGLE_PAY';
    case CartPaymentType.CREDIT_ANONYMOUS:
      return 'CREDIT_ANONYMOUS';
    default:
      return 'VAULTED_ACCOUNT';
  }
}

export function serializeServiceMode(serviceMode: ServiceMode | null) {
  switch (serviceMode) {
    case ServiceMode.DELIVERY:
    case ServiceMode.CATERING_DELIVERY:
      return 'Delivery';
    case ServiceMode.DRIVE_THRU:
    case ServiceMode.EAT_IN:
    case ServiceMode.TAKEOUT:
    case ServiceMode.CURBSIDE:
    case ServiceMode.CATERING_PICKUP:
      return 'Pickup';
    default:
      return 'None';
  }
}

export function serializePickupMode(serviceMode: ServiceMode | null) {
  switch (serviceMode) {
    case ServiceMode.DELIVERY:
      return 'Delivery';
    case ServiceMode.DRIVE_THRU:
      return 'Drive Thru';
    case ServiceMode.EAT_IN:
      return 'Eat In';
    case ServiceMode.TAKEOUT:
      return 'Take Out';
    case ServiceMode.CURBSIDE:
      return 'Curbside';
    case ServiceMode.CATERING_PICKUP:
      return 'Catering Pickup';
    default:
      return 'None';
  }
}

export function serializeNumberOfDriveThruWindows(driveThruLaneType: string | null) {
  switch (driveThruLaneType) {
    case 'single':
      return 1;
    case 'dual':
      return 2;
    default:
      return 0;
  }
}

export const getCartDataItems = (cartEntries: ICartEntry[]): string => {
  const cartData = JSON.stringify({
    items: cartEntries.map(entry => ({
      items: createProduct(entry),
    })),
  });
  const cartDataItemsRegex = /item_\d{3,}/gi;
  return cartData?.match(cartDataItemsRegex)?.join() || '';
};

export function isCommerceEvent(eventName: string) {
  return eventName.includes('eCommerce');
}

export function isExpandableEvent(eventName: AllowedEvent['name']) {
  const eventsWeWantToExpand: AllowedEvent['name'][] = [
    'eCommerce - Purchase',
    'eCommerce - Checkout',
    'eCommerce - ViewDetail',
  ];
  return eventsWeWantToExpand.includes(eventName);
}

export function convertToSingleCommerceProduct(product: Partial<IAmplitudeProduct>) {
  const { id, quantity, name, price, total_product_amount, ...rest } = product;

  const newProduct: Record<string, any> = { ...rest };
  newProduct.Quantity = String(product.quantity);
  newProduct.Id = String(product.id);
  newProduct.Name = String(product.name);
  newProduct['Total Product Amount'] = String(product.total_product_amount);
  newProduct.Price = String(product.price);

  return newProduct;
}
