import { ApolloClient, from } from '@apollo/client';

import { getConfiguredCache } from 'state/graphql/cache';
import { httpLink } from 'state/graphql/links/http-link';
import { stripTypenameLink } from 'state/graphql/links/strip-typename';
import { withErrorLogger } from 'state/graphql/links/with-error-logger';
import { withHeaders } from 'state/graphql/links/with-headers';
import { isTest } from 'utils/environment';

import { headers } from './headers';
import { withDetoxOverrides } from './links/with-detox-overrides';

/**
 * Apollo Client instance
 *
 * Client should only be instantiated once per app
 * @see https://github.com/apollographql/apollo-client-devtools/issues/822
 */
export const client = new ApolloClient({
  cache: getConfiguredCache(),
  link: from(
    [
      isTest ? withDetoxOverrides() : null,
      withHeaders(headers),
      withErrorLogger,
      stripTypenameLink,
      httpLink,
    ].filter(Boolean) as any[]
  ),
  headers,
});
