import { useCallback, useMemo } from 'react';

import { useIntl } from 'react-intl';

import { ISanityVendorConfigs } from '@rbi-ctg/menu';
import { ItemAvailabilityStatus } from 'enums/menu';
import { Maybe } from 'generated/sanity-graphql';
import usePosVendor from 'hooks/menu/use-pos-vendor';
import { useDayPartContext } from 'state/day-part';
import { selectors, useAppSelector } from 'state/global-state';
import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';
import { LoyaltyOffer, LoyaltyReward, isLoyaltyOffer } from 'state/loyalty/types';
import { isDiscountOffer } from 'state/loyalty/utils';
import { useMenuContext } from 'state/menu';
import { useStoreContext } from 'state/store';
import { itemIsAvailable } from 'utils/availability';
import { PosVendors } from 'utils/vendor-config';

import {
  checkIncentiveAvailableForDayPart,
  makeIncentiveUnavailableMessage,
} from './incentive-details/utils';

const VENDORS_TO_IGNORE_AVAILABILITY_CHECK = [
  PosVendors.NCR,
  PosVendors.NCR_DELIVERY,
  PosVendors.QDI,
  PosVendors.QDI_DELIVERY,
];

const shouldSkipAvailabilityCheck = ({
  vendor,
  cmsOffer,
}: {
  vendor: PosVendors;
  cmsOffer: LoyaltyOffer;
}) => Boolean(VENDORS_TO_IGNORE_AVAILABILITY_CHECK.includes(vendor) && isDiscountOffer(cmsOffer));

export const useLoyaltyIncentivesAvailability = (
  selectedIncentive?: LoyaltyReward | LoyaltyOffer,
  pointCost?: number,
  locked?: Maybe<boolean>
) => {
  const {
    checkItemAvailability,
    showBurgersForBreakfast: burgersForBreakfastEnabled,
  } = useMenuContext();
  const { activeDayParts, dayParts } = useDayPartContext();
  const { store, isStoreOpenAndAvailable, prices } = useStoreContext();
  const { vendor } = usePosVendor();
  const { formatMessage, formatTime } = useIntl();
  const stagedCartPoints = useAppSelector(selectors.loyalty.selectStagedCartPoints);
  const hideOffersForServiceMode = useFlag(LaunchDarklyFlag.ENABLE_HIDE_OFFERS_FOR_SERVICE_MODE);

  const checkIncentiveAvailability = useCallback(
    async (itemId: string) => {
      // Check store availability for incentive
      if (isStoreOpenAndAvailable && itemId) {
        const { availabilityStatus } = await checkItemAvailability({
          vendor,
          restaurantPosDataId: store.restaurantPosData?._id ?? '',
          storeNumber: store.number ?? '',
          itemId,
        });

        return availabilityStatus === ItemAvailabilityStatus.AVAILABLE;
      }
      return false;
    },
    [isStoreOpenAndAvailable, checkItemAvailability, vendor, store.number, store.restaurantPosData]
  );

  const checkLoyaltyIncentiveAvailability = useCallback(
    (incentive: LoyaltyReward | LoyaltyOffer) => {
      let checkResult = true;

      if (!!incentive?.vendorConfigs && !!vendor && !!prices) {
        checkResult = itemIsAvailable(
          {
            ...incentive,
            name: undefined,
            vendorConfigs: incentive.vendorConfigs as ISanityVendorConfigs,
          },
          vendor,
          prices,
          undefined,
          hideOffersForServiceMode
        );
      }

      return checkResult;
    },
    [vendor, prices]
  );

  const checkLoyaltyOfferAvailability = useCallback(
    (offer: LoyaltyOffer) => {
      return !vendor || shouldSkipAvailabilityCheck({ vendor, cmsOffer: offer })
        ? true
        : checkLoyaltyIncentiveAvailability(offer);
    },
    [vendor, checkLoyaltyIncentiveAvailability]
  );

  const incentiveUnavailableMessage = useMemo(
    () =>
      selectedIncentive &&
      makeIncentiveUnavailableMessage({
        isIncentiveAvailable: isLoyaltyOffer(selectedIncentive)
          ? checkLoyaltyOfferAvailability(selectedIncentive)
          : checkLoyaltyIncentiveAvailability(selectedIncentive),
        dayParts,
        isAvailableForDayPart: checkIncentiveAvailableForDayPart({
          incentive: selectedIncentive,
          activeDayParts,
          burgersForBreakfastEnabled,
        }),
        formatMessage,
        formatTime,
        locked,
        pointCost,
        incentive: selectedIncentive,
        stagedCartPoints,
        burgersForBreakfastEnabled,
      }),
    [
      activeDayParts,
      burgersForBreakfastEnabled,
      checkLoyaltyIncentiveAvailability,
      checkLoyaltyOfferAvailability,
      dayParts,
      formatMessage,
      formatTime,
      locked,
      pointCost,
      selectedIncentive,
      stagedCartPoints,
    ]
  );

  return {
    checkIncentiveAvailability,
    checkLoyaltyOfferAvailability,
    incentiveUnavailableMessage,
  };
};
