import { Box } from '@rbilabs/universal-components';

export const LoadingContainer = Box.withConfig({
  height: '$6',
  width: '$6',
  justifyContent: 'center',
  alignItems: 'center',
});

export const LoadingSizer = Box.withConfig({
  height: '$9',
  width: '$9',
});
