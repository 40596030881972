import React from 'react';

import { useNavigation } from 'hooks/navigation/use-navigation';
import { HeaderLeft } from 'navigation/header/header-left';
import { HeaderLeftIconType } from 'navigation/header/types';
import { NativeStackNavigatorWithBack } from 'navigation/native-stack-navigator-with-back';
import { INativeStackNavigatorScreens } from 'navigation/native-stack-navigator-with-back/types';
import LoyaltyOffers from 'pages/loyalty/loyalty-offers';
import { LoyaltyOfferDetail } from 'pages/loyalty/loyalty-offers/loyalty-offer-detail';
import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';
import { routes } from 'utils/routing';

const OffersHeaderLeft = () => {
  const { navigate } = useNavigation();
  return (
    <HeaderLeft
      onPress={() => {
        navigate(routes.offersList, { state: { offerEngineId: '' } });
      }}
      iconProps={{
        variant: HeaderLeftIconType.Back,
      }}
    />
  );
};

const screens: INativeStackNavigatorScreens[] = [
  {
    name: routes.offersList,
    component: LoyaltyOffers,
  },
  {
    name: `${routes.loyaltyOfferList}/`,
    // This is a temporary solution. The Offer Detail route should have its own component and the custom header shouldn't be needed when that is done
    // TODO: React Navigation - change this component to a specific Offer Detail and remove headerLeft option
    component: LoyaltyOffers,
    options: {
      headerLeft: OffersHeaderLeft,
    },
  },
];

const screensV2: INativeStackNavigatorScreens[] = [
  {
    name: routes.offersList,
    component: LoyaltyOffers,
  },
  {
    name: `${routes.loyaltyOfferList}/`,
    component: LoyaltyOfferDetail,
  },
];

export const OffersNavigator = () => {
  const isOffersV2Enabled = useFlag(LaunchDarklyFlag.ENABLE_LOYALTY_OFFERS_V2_FE);

  return <NativeStackNavigatorWithBack screens={isOffersV2Enabled ? screensV2 : screens} />;
};
