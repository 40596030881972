import * as React from 'react';
import { FC } from 'react';

import { HStack } from '@rbilabs/universal-components';
import { isUndefined } from 'lodash-es';
import { useIntl } from 'react-intl';

import {
  Balance,
  GreetingWrapper,
  JoinNowCTA,
  JoinNowContainer,
  StyledLoyaltyRewardsLogo as LoyaltyRewardsLogo,
  P,
  PointBalancePointsIcon,
  PointText,
  PointsWrapper,
  Separator,
  StyledLoadingAnimation,
  Wrapper,
  pointsTextID,
} from './loyalty-cta-bar.styled';
import { IJoinNow, ILoyaltyCtaBarViewProps, IPointsContent } from './types';

const PointsContent: FC<React.PropsWithChildren<IPointsContent>> = ({ points, loading }) => {
  const { formatMessage } = useIntl();
  return (
    <PointsWrapper>
      {!loading && (
        <Balance>
          <PointBalancePointsIcon />
          <PointText accessibilityLabel={`${points} ${formatMessage({ id: pointsTextID })}`}>
            {points}
          </PointText>
        </Balance>
      )}
    </PointsWrapper>
  );
};

const JoinNow: FC<React.PropsWithChildren<IJoinNow>> = ({ formatMessage, loading, onPress }) => (
  <JoinNowContainer>
    {loading ? (
      <StyledLoadingAnimation />
    ) : (
      <>
        <PointBalancePointsIcon />
        <P testID="earn-points">{formatMessage({ id: 'earnPoints' })}</P>
        <Separator>-</Separator>
        <JoinNowCTA testID="join-now" onPress={onPress}>
          {formatMessage({ id: 'joinNow' })}
        </JoinNowCTA>
      </>
    )}
  </JoinNowContainer>
);

export const LoyaltyCtaBarViewBase: FC<React.PropsWithChildren<ILoyaltyCtaBarViewProps>> = ({
  handlePress,
  points,
  loading = true,
  hideLoyaltyLogo = false,
}) => {
  const { formatMessage } = useIntl();
  const showJoinNow = isUndefined(points);

  return (
    <Wrapper testID="loyalty-cta-bar" onPress={handlePress} accessibilityRole="button">
      {!hideLoyaltyLogo && <LoyaltyRewardsLogo />}
      {showJoinNow ? (
        <JoinNow formatMessage={formatMessage} loading={loading} onPress={handlePress} />
      ) : (
        <HStack flex={1}>
          <PointsContent points={points} loading={loading} />
          {loading ? <StyledLoadingAnimation /> : <GreetingWrapper dayPeriodEnabled={false} />}
        </HStack>
      )}
    </Wrapper>
  );
};
