import * as React from 'react';
import { FC, memo, useCallback } from 'react';

import { Box } from '@rbilabs/universal-components';

import LoadingAnimation from 'components/loading-animation';
import { withStoreSelected } from 'components/store-selected';
import { useNavigation } from 'hooks/navigation/use-navigation';
import useIsFirstLocation from 'hooks/use-is-first-location';
import { LoyaltyNotEnrolled } from 'pages/loyalty/loyalty-not-enrolled';
import { useAuthContext } from 'state/auth';
import { routes } from 'utils/routing';

import { Widget, useWidgetComponents } from '../loyalty-widget-components';
import {
  LoyaltyDoubleButtonWidget,
  LoyaltyFeaturedRewardsCarouselWidget,
  LoyaltyGreetingWidget,
  LoyaltyHistoryWidget,
  LoyaltyLinksWidget,
  LoyaltyLogoWidget,
  LoyaltyMarketingTileGroupWidget,
  LoyaltyNotificationsWidget,
  LoyaltyPointsAndRewardsWidget,
  LoyaltyPointsMeterWidget,
  LoyaltyRewardsListWidget,
  LoyaltyTiersWidget,
} from '../loyalty-widgets';
import { LoyaltyQuestsTileWidget } from '../loyalty-widgets/loyalty-quests-tile-widget';

import { LoyaltyDashboardView } from './loyalty-dashboard.view';
import { LoyaltyDashboardWidgets } from './types';
import { useFeaturesLoyaltyDashboard } from './use-feature-loyalty-dashboard';

/**
 *
 * LoyaltyDashboard is a configurable component that displays an overview of the loyalty program.
 * Content is displayed and ordered via sanity controlled loyalty widgets.
 *
 */

export const componentWidgetMap: Record<LoyaltyDashboardWidgets, Widget> = {
  // @ts-expect-error TS(2418) FIXME: Type of computed property's value is 'FC<ILoyaltyD... Remove this comment to see the full error message
  [LoyaltyDashboardWidgets.DoubleButton]: LoyaltyDoubleButtonWidget,
  [LoyaltyDashboardWidgets.Greeting]: LoyaltyGreetingWidget,
  [LoyaltyDashboardWidgets.Reward]: LoyaltyPointsAndRewardsWidget,
  // @ts-expect-error TS(2418) FIXME: Type of computed property's value is 'FC<{ disclai... Remove this comment to see the full error message
  [LoyaltyDashboardWidgets.MarketingTiles]: LoyaltyMarketingTileGroupWidget,
  [LoyaltyDashboardWidgets.History]: LoyaltyHistoryWidget,
  [LoyaltyDashboardWidgets.Logo]: LoyaltyLogoWidget,
  // @ts-expect-error TS(2418) FIXME: Type of computed property's value is '({ links }: ... Remove this comment to see the full error message
  [LoyaltyDashboardWidgets.Links]: LoyaltyLinksWidget,
  [LoyaltyDashboardWidgets.Tiers]: LoyaltyTiersWidget,
  [LoyaltyDashboardWidgets.PointsMeter]: LoyaltyPointsMeterWidget,
  // @ts-expect-error TS(2418) FIXME: Type of computed property's value is 'FC<{ title: ... Remove this comment to see the full error message
  [LoyaltyDashboardWidgets.Notifications]: LoyaltyNotificationsWidget,
  [LoyaltyDashboardWidgets.QuestsTile]: LoyaltyQuestsTileWidget,
  [LoyaltyDashboardWidgets.RewardsListWidget]: LoyaltyRewardsListWidget,
  [LoyaltyDashboardWidgets.FeatureRewardsCarouselWidget]: LoyaltyFeaturedRewardsCarouselWidget,
};

const LoyaltyDashboard: FC<React.PropsWithChildren<unknown>> = () => {
  const { user } = useAuthContext();
  const { data, loading } = useFeaturesLoyaltyDashboard();
  const isFirstLocation = useIsFirstLocation();
  const { navigate, goBack } = useNavigation();
  const widgetKeys = data?.widgets;
  const widgetComponents = useWidgetComponents<LoyaltyDashboardWidgets>(
    componentWidgetMap,
    widgetKeys
  );

  const handleDismiss = useCallback(() => {
    if (isFirstLocation) {
      navigate(routes.rewards);
    } else {
      goBack();
    }
  }, [goBack, isFirstLocation, navigate]);

  if (loading) {
    return (
      <Box flex="1" justifyContent="center">
        <LoadingAnimation />
      </Box>
    );
  }

  if (!user?.loyaltyId) {
    return <LoyaltyNotEnrolled />;
  }

  return widgetComponents?.length ? (
    <LoyaltyDashboardView widgets={widgetComponents} handleDismiss={handleDismiss} />
  ) : null;
};

export default memo(withStoreSelected(LoyaltyDashboard));
