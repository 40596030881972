import * as React from 'react';
import { FC } from 'react';

import { useIntl } from 'react-intl';

import { ActionButtonVariants } from 'components/action-button';

import { Quest } from '../use-quests';

import {
  QuestCardContainer,
  QuestCardPicture,
  QuestContent,
  QuestDescription,
  QuestExpirationText,
  QuestTitle,
  RewardLockContainer,
  StyledButton,
  StyledRewardLock,
} from './loyalty-quest-card.styled';

interface IQuestCardProps {
  quest: Quest;
  className?: string;
}

export const QuestCard: FC<React.PropsWithChildren<IQuestCardProps>> = ({ quest, className }) => {
  const { formatMessage } = useIntl();

  return (
    // @ts-expect-error TS(2769) FIXME: No overload matches this call.
    <QuestCardContainer className={className} testID="quest-card">
      <QuestCardPicture image={quest.image.locale} alt={quest.title.locale} />

      <RewardLockContainer>
        {/* @ts-expect-error TS(2769) FIXME: No overload matches this call. */}
        <StyledRewardLock testID="quest-lock" />
      </RewardLockContainer>

      <QuestContent>
        <QuestTitle>{quest.title.locale}</QuestTitle>
        <QuestDescription>{quest.description.locale}</QuestDescription>
        <QuestExpirationText>{quest.expiration.locale}</QuestExpirationText>

        {/* @ts-expect-error TS(2769) FIXME: No overload matches this call. */}
        <StyledButton variant={ActionButtonVariants.OUTLINE} maintainWidth>
          {formatMessage({ id: 'details' })}
        </StyledButton>
      </QuestContent>
    </QuestCardContainer>
  );
};
