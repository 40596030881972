import {
  DEFAULT_PAYMENT_COUNTRY,
  DEFAULT_PAYMENT_CURRENCY,
} from './default-codes/default-payment-codes';

export enum ISOs {
  USA = 'USA',
  CAN = 'CAN',
  CHE = 'CHE',
  DEU = 'DEU',
  GBR = 'GBR',
  ZAF = 'ZAF',
  US = 'USA',
  CA = 'CAN',
  CH = 'CHE',
  DE = 'DEU',
  GB = 'GBR',
  ZA = 'ZAF',
}

export { ISOs as IsoCountryCode };

export enum RegionToISOs {
  US = ISOs.USA,
  CA = ISOs.CAN,
  CH = ISOs.CHE,
  DE = ISOs.DE,
  GB = ISOs.GBR,
  ZA = ISOs.ZAF,
}

export enum ISOsToRegions {
  USA = 'US',
  CAN = 'CA',
  CHE = 'CH',
  DEU = 'DE',
  GBR = 'GB',
  ZAF = 'ZA',
}

export enum PaymentISOs {
  US = 'US',
  CA = 'CA',
  CH = 'CH',
  DE = 'DE',
  GB = 'GB',
  ZA = 'ZA',
}

export enum Currencies {
  USD = 'USD',
  CAD = 'CAD',
  GBP = 'GBP',
  CHF = 'CHF',
  ZAF = 'ZAR',
  EUR = 'EUR',
}

export {
  DEFAULT_PAYMENT_COUNTRY,
  DEFAULT_PAYMENT_CURRENCY,
} from './default-codes/default-payment-codes';

export const getCountryAndCurrencyCodes = (
  billingCountry: ISOs
): { countryCode: PaymentISOs; currencyCode: Currencies } => {
  const defaultCodes = {
    countryCode: DEFAULT_PAYMENT_COUNTRY,
    currencyCode: DEFAULT_PAYMENT_CURRENCY,
  };
  return (
    {
      [ISOs.USA]: { countryCode: PaymentISOs.US, currencyCode: Currencies.USD },
      [ISOs.CAN]: { countryCode: PaymentISOs.CA, currencyCode: Currencies.CAD },
      [ISOs.CHE]: { countryCode: PaymentISOs.CH, currencyCode: Currencies.CHF },
      [ISOs.DEU]: { countryCode: PaymentISOs.DE, currencyCode: Currencies.EUR },
      [ISOs.GBR]: { countryCode: PaymentISOs.GB, currencyCode: Currencies.GBP },
      [ISOs.ZAF]: { countryCode: PaymentISOs.ZA, currencyCode: Currencies.ZAF },
    }[billingCountry] || defaultCodes
  );
};

export const postalCodeStringLocaleKey = {
  [ISOs.USA]: 'zipCode',
  [ISOs.CAN]: 'postalCode',
  [ISOs.GBR]: 'postalCode',
  [ISOs.CHE]: 'postalCode',
  [ISOs.DEU]: 'postalCode',
};

export const postalCodeInputProperties = {
  [ISOs.USA]: { maxLength: 5, pattern: '[0-9]*' },
  [ISOs.CAN]: { maxLength: 7 },
  [ISOs.GBR]: { maxLength: 10 },
  [ISOs.CHE]: { maxLength: 4, pattern: '[0-9]*' },
  [ISOs.DEU]: { maxLength: 5, pattern: '[0-9]*' },
};

export const regionStringLocaleKey = {
  [ISOs.USA]: 'state',
  [ISOs.CAN]: 'province',
  [ISOs.GBR]: 'province',
  [ISOs.CHE]: 'canton',
  [ISOs.DEU]: 'province',
};

export const regionInputProperties = {
  [ISOs.USA]: { maxLength: 2 },
  [ISOs.CAN]: { maxLength: 2 },
  [ISOs.GBR]: { maxLength: 2 },
  [ISOs.CHE]: { maxLength: 2 },
  [ISOs.DEU]: { maxLength: 2 },
};
