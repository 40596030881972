import { useCallback, useMemo } from 'react';

import keyBy from 'lodash/keyBy';

import {
  IGetCmsQuestDetailByIdQuery,
  useGetCmsQuestDetailByIdLazyQuery,
  useGetLoyaltyQuestUiDetailLazyQuery,
} from 'generated/sanity-graphql';

import type {
  ICmsQuestDetail,
  IQuestRequirementComboOrItem,
  IUseCmsQuestDetailLazyQuery,
} from './types';

const createCmsQuestDetail = (
  questDetailData: IGetCmsQuestDetailByIdQuery | undefined
): ICmsQuestDetail | null => {
  let questDetail: ICmsQuestDetail | null = null;
  const cmsQuest = questDetailData?.SystemwideQuest;

  if (cmsQuest) {
    const { allItems = [], allCombos = [] } = questDetailData;
    const allDocuments: IQuestRequirementComboOrItem[] = [...allCombos, ...allItems];
    const requirementMap = keyBy(allDocuments, '_id');

    questDetail = {
      ...cmsQuest,
      requirementByCmsId: requirementMap,
    };
  }

  return questDetail;
};

export const useCmsQuestDetailLazyQuery: IUseCmsQuestDetailLazyQuery = ({
  cmsId,
  questRequirementCmsIds,
}) => {
  const [
    cmsQuestDetailQuery,
    {
      loading: questDetailLoading,
      data: questDetailData,
      error: questDetailError,
      refetch: refetchQuestDetail,
    },
  ] = useGetCmsQuestDetailByIdLazyQuery({
    variables: {
      swQuestId: cmsId,
      requirementIds: questRequirementCmsIds,
    },
  });

  const [
    cmsQuestUIDetailQuery,
    {
      loading: cmsQuestDetailUIDataLoading,
      data: cmsQuestDetailUIData,
      error: cmsLoyaltyQuestError,
      refetch: refetchLoyaltyQuest,
    },
  ] = useGetLoyaltyQuestUiDetailLazyQuery({
    variables: {
      featureLoyaltyQuestsUIId: 'feature-loyalty-quests-ui-singleton',
    },
  });

  const fetchCmsQuestDetail = useCallback(() => {
    cmsQuestDetailQuery();
    cmsQuestUIDetailQuery();
  }, [cmsQuestDetailQuery, cmsQuestUIDetailQuery]);

  const cmsQuestDetail: ICmsQuestDetail | null = useMemo(
    () => createCmsQuestDetail(questDetailData),
    [questDetailData]
  );

  const refetchCmsQuestDetail = useCallback(() => {
    refetchQuestDetail();
    refetchLoyaltyQuest();
  }, [refetchLoyaltyQuest, refetchQuestDetail]);

  const error = questDetailError || cmsLoyaltyQuestError;
  return [
    fetchCmsQuestDetail,
    {
      loadingCmsQuestDetail: questDetailLoading || cmsQuestDetailUIDataLoading,
      cmsQuestDetail,
      cmsQuestDetailUI: cmsQuestDetailUIData?.LoyaltyQuestsUI ?? null,
      refetch: refetchCmsQuestDetail,
      error,
    },
  ];
};
