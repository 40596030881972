import React, { memo, useCallback, useState } from 'react';

import { AlertDialog } from '@rbilabs/universal-components';

import { parseErrorModalData, parseInitModalData, parseLoadingModalData } from './data-parser';
import { useCreateLoyaltyUser } from './hooks/use-create-loyalty-user';
import { useFeaturesLoyaltyOptInModal } from './hooks/use-features-loyalty-opt-in-modal';
import { OptInErrorContent } from './opt-in-error-content';
import { OptInInitContent } from './opt-in-init-content';
import { OptInLoadingContent } from './opt-in-loading-content';
import { OptInDialogContent } from './styled';
import type { ILoyaltyOptInModalProps } from './types';
import { isModalDataValid } from './utils';

/**
 * Display the modal and handle the transition of the states.
 */
export const LoyaltyOptInModal = memo(
  ({ cognitoId, onLoyaltyUserCreated }: ILoyaltyOptInModalProps) => {
    const { data: modalData, loading: loadingData } = useFeaturesLoyaltyOptInModal();

    const [isDismissed, setIsDismissed] = useState(false);
    const [
      createLoyaltyUser,
      { loading: loadingUserCreation, called: mutationCalled },
    ] = useCreateLoyaltyUser({
      cognitoId,
      onCompleted: () => {
        onLoyaltyUserCreated?.();
      },
    });

    const onDismiss = useCallback(() => setIsDismissed(true), []);

    const leastDestructiveRef = React.useRef(null);

    // no content, dismissed or invalid cms data display nothing
    if (
      loadingData ||
      !modalData ||
      !modalData.optInModal ||
      isDismissed ||
      !isModalDataValid(modalData.optInModal)
    ) {
      return null;
    }

    // parse data to send MP event log
    const initModalData = parseInitModalData(modalData.optInModal);

    const ModalContent = () => {
      if (loadingUserCreation && modalData?.optInModalLoading) {
        const optInModalLoading = parseLoadingModalData(modalData?.optInModalLoading);

        return <OptInLoadingContent {...optInModalLoading} />;
        // mutation called and still on the flow means that the request has an error
      } else if (mutationCalled && modalData?.optInModalError) {
        const errorModalData = parseErrorModalData(modalData?.optInModalError);

        return (
          <OptInErrorContent
            {...errorModalData}
            onActionPress={createLoyaltyUser}
            onDismiss={onDismiss}
            allowDismiss
          />
        );
      }

      return <OptInInitContent {...initModalData} onActionPress={createLoyaltyUser} />;
    };

    return (
      <AlertDialog leastDestructiveRef={leastDestructiveRef} isOpen>
        <OptInDialogContent testID="loyalty-opt-in-modal">
          <ModalContent />
        </OptInDialogContent>
      </AlertDialog>
    );
  }
);
