import React, { FC, useCallback, useState } from 'react';

import { Box } from '@rbilabs/universal-components';
import { FlatList } from 'react-native';

import Alert from 'components/alert';
import { HeroContainer } from 'components/features/components/hero';
import { LockedOffersSection } from 'components/features/components/locked-offers-section';
import { MainHeroContainer } from 'components/features/components/main-hero/main-hero-container';
import { MarketingTileGroup } from 'components/features/components/marketing-tile-group';
import { RecentItemsSection } from 'components/features/components/recent-items';
import { RewardsSection } from 'components/features/components/rewards-section';
import LoadingAnimation from 'components/loading-animation';
import { QuestSection } from 'components/quests';
import { SimpleHomePageHero__Experiment } from 'experiments/simple-home-page';
import { IMarketingCardGroupFragment, IMarketingTileGroup } from 'generated/sanity-graphql';

import { Section, Spacer } from './home-page/styles';
import { Component, Components } from './home-page/types';

const itemIdExtractor = (item: Component | null, index: number) => item?._id || index + '_item';

const Spinner = () => (
  <Box padding="$2">
    <LoadingAnimation />
  </Box>
);

export const Features: FC<React.PropsWithChildren<{
  components: Components;
  headerComponent?: React.ReactElement | null;
  numberOfItemsToRender?: number;
}>> = React.memo(({ headerComponent, components, numberOfItemsToRender }) => {
  const componentLength = components?.length || 0;

  const renderItem = useCallback(
    ({ item, index }: { item: Component | null; index: number }) => {
      if (!item) {
        return null;
      }

      const first = index === 0;
      const last = index + 1 === componentLength;

      switch (item.__typename) {
        case 'Spacer': {
          return <Spacer $isSmall={item.isSmall} />;
        }
        case 'MainHero': {
          return <MainHeroContainer item={item} />;
        }

        case 'Hero': {
          return (
            <SimpleHomePageHero__Experiment hero={item}>
              <HeroContainer hero={item} />
            </SimpleHomePageHero__Experiment>
          );
        }

        case 'Alert': {
          return (
            <Alert
              headline={item.heading?.locale || ''}
              body={item.body?.locale || ''}
              learnMore={item.learnMoreText?.locale || ''}
              learnMoreUrl={item.learnMoreUrl?.locale || ''}
            />
          );
        }

        case 'MarketingCardGroup':
          return (
            <Section first={first} last={last} shouldRenderDefaultBackground={true}>
              <MarketingTileGroup item={(item as unknown) as IMarketingCardGroupFragment} />
            </Section>
          );

        case 'MarketingTileGroup':
          return (
            <Section first={first} last={last}>
              <MarketingTileGroup item={item as IMarketingTileGroup} />
            </Section>
          );

        case 'RecentItems': {
          return <RecentItemsSection />;
        }

        case 'LockedOffers': {
          return <LockedOffersSection />;
        }

        case 'RewardsSection': {
          return <RewardsSection item={item} />;
        }

        case 'QuestsSection': {
          return <QuestSection />;
        }

        default: {
          return null;
        }
      }
    },
    [componentLength]
  );

  const [isLoadingMore, setIsLoadingMore] = useState(true);
  const setIsLoadingMoreFalse = useCallback(() => setIsLoadingMore(false), []);

  if (!components) {
    return null;
  }

  return (
    <FlatList
      ListHeaderComponent={headerComponent}
      ListFooterComponent={isLoadingMore ? <Spinner /> : null}
      keyExtractor={itemIdExtractor}
      data={components}
      initialNumToRender={numberOfItemsToRender}
      renderItem={renderItem}
      windowSize={1}
      onEndReached={setIsLoadingMoreFalse}
      // Changed from 0 to 0.01 because with 0 onEndReached was not being called on web
      // 0.01 is a small value, close to the original, that works on both web and mobile
      onEndReachedThreshold={0.01}
    />
  );
});
