import { useLocale } from 'state/intl';
import { REGIONS } from 'state/intl/types';

import { prirmaryVariant, wordmarkVariant, wordmarkVariantCA } from './constants';
import { BrandLogoVariantsType } from './types';

export const useBrandLogoVariant = (): BrandLogoVariantsType => {
  const { region } = useLocale();

  const brandPrimaryLogoVariant = prirmaryVariant;
  const brandLogoWordmarkVariant = region === REGIONS.CA ? wordmarkVariantCA : wordmarkVariant;

  return {
    brandPrimaryLogoVariant,
    brandLogoWordmarkVariant,
  };
};
