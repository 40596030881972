import * as React from 'react';
import { FC } from 'react';

import { Icon, InlineAlert } from '@rbilabs/universal-components';
import { isUndefined } from 'lodash-es';
import { isNumber } from 'lodash/fp';
import { useIntl } from 'react-intl';

import { ActionButtonVariants } from 'components/action-button';
import { BackArrowHistory } from 'components/navigation/back-arrow';
import { CustomDisclaimer } from 'components/offers/ui-refresh/offer-disclaimer';
import { StyledSignUpToRedeemLink } from 'components/offers/ui-refresh/redemption-button.styled';
import { DisableAddToMobileOrder_SimplifiedLandingPage__Experiment } from 'experiments/simplified-landing-page';
import { useNavigation } from 'hooks/navigation/use-navigation';
import { useRoute } from 'hooks/navigation/use-route';
import useRoutes from 'hooks/use-routes';
import { LoyaltyOffersCooldownDrawer } from 'pages/loyalty/loyalty-offers/loyalty-offers-cooldown-drawer';
import { useAuthContext } from 'state/auth';
import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';
import { FixableEvaluationErrors, IncentiveEvaluationErrorCodes } from 'state/loyalty/hooks/types';
import { hiddenAccessibilityPlatformProps } from 'utils/accessibility';
import { isWeb } from 'utils/environment';
import { routes } from 'utils/routing';

import { DISABLE_IN_RESTAURANT_FOR_INVALID_SERVICE_MODE } from '../constants';
import { PointsNeededText, PointsNeededTextDetail } from '../incentive-card/incentive-card.styled';

import { CAN_REPLACE_REWARD, SHOULD_DISPLAY_ICON } from './constants';
import {
  BackgroundImage,
  ButtonContainer,
  ButtonsWrapper,
  DisclaimerText,
  ImageWrapper,
  RewardDescription,
  RewardDetailsContainer,
  RewardName,
  StyledActionButton,
  StyledDisclaimerContainer,
  StyledIncentiveEvaluationMessages,
  StyledProgressMeter,
  StyledRewardImage,
  Wrapper,
} from './incentive-details.styled';
import { RewardPoints } from './reward-points';
import { IIncentiveDetailsViewProps } from './types';

export const IncentiveDetailsView: FC<React.PropsWithChildren<IIncentiveDetailsViewProps>> = ({
  name,
  image,
  description,
  moreInfo,
  isOffer,
  pointCost,
  locked,
  remainingPointsNeededForRedemption,
  redemptionPercentage,
  handleRedeemRewardPress,
  handleInRestaurantRedemptionPress,
  errorMessage,
  checkingAvailability,
  backgroundImage,
  canAddToInRestaurantOrder = false,
  canAddToMobileOrder = false,
  showRemoveRewardButton = false,
  handleRemoveRewardsPress,
  incentiveEvaluationResults,
  offersInCooldown = false,
  limitPerOrderMet = false,
}) => {
  const { formatMessage } = useIntl();
  const { goBack, navigate } = useNavigation();

  const meterEnabled = Boolean(useFlag(LaunchDarklyFlag.ENABLE_METER_ON_REWARDS));

  const addRewardButtonLabel =
    limitPerOrderMet && CAN_REPLACE_REWARD
      ? `${formatMessage({ id: 'change' })} ${formatMessage({ id: 'reward' })}`
      : formatMessage({ id: 'addMobileOrder' });

  const inRestaurantRedemptionText = formatMessage({ id: 'redeemInRestaurant' });

  const hasServiceModeError = (
    evaluationResults: IIncentiveDetailsViewProps['incentiveEvaluationResults']
  ) =>
    !!evaluationResults?.some(
      evaluationResult =>
        evaluationResult.code === IncentiveEvaluationErrorCodes.INVALID_SERVICE_MODE
    );

  const hasBlockingErrors =
    !!errorMessage ||
    (offersInCooldown && isOffer) ||
    // Disable buttons if Feedback is not included in fixable incentive errors
    incentiveEvaluationResults?.some(
      evaluationResult =>
        !FixableEvaluationErrors.has(evaluationResult.code as IncentiveEvaluationErrorCodes)
    );

  const disableMobileOrderButton =
    hasBlockingErrors || hasServiceModeError(incentiveEvaluationResults);

  const disableInRestaurantOrderButton =
    hasBlockingErrors ||
    (DISABLE_IN_RESTAURANT_FOR_INVALID_SERVICE_MODE &&
      hasServiceModeError(incentiveEvaluationResults));

  const { isAuthenticated, setOriginLocation } = useAuthContext();
  const { pathname } = useRoute();
  const { getLocalizedRouteForPath } = useRoutes();
  const routeToSignUp = (event: any) => {
    event.preventDefault();
    setOriginLocation(pathname);
    navigate(getLocalizedRouteForPath(routes.signUp));
  };

  return (
    <>
      {isWeb && <BackArrowHistory onPress={goBack} />}
      <Wrapper>
        <ImageWrapper>
          {backgroundImage && <BackgroundImage alt={name} image={backgroundImage} />}
          {image && <StyledRewardImage alt={name} image={image} objectFitContain />}
        </ImageWrapper>
        <RewardDetailsContainer>
          {name && <RewardName>{name}</RewardName>}
          {description && <RewardDescription content={description} />}
          {!isUndefined(pointCost) && (
            <RewardPoints pointCost={pointCost} locked={Boolean(locked)} />
          )}
          {locked && (
            <>
              {meterEnabled && isNumber(redemptionPercentage) && (
                <StyledProgressMeter percentage={redemptionPercentage} />
              )}
              {isNumber(remainingPointsNeededForRedemption) && (
                <PointsNeededText>
                  {formatMessage(
                    { id: 'yourXPointsAway' },
                    {
                      points: (
                        <PointsNeededTextDetail>
                          {remainingPointsNeededForRedemption}
                        </PointsNeededTextDetail>
                      ),
                    }
                  )}
                </PointsNeededText>
              )}
            </>
          )}
          {!isOffer && moreInfo && <DisclaimerText content={moreInfo} />}
        </RewardDetailsContainer>

        {!!errorMessage && (
          <InlineAlert
            marginTop="$4"
            status="info"
            maxWidth={{ base: 'full', md: '400px' }}
            message={errorMessage}
            alignSelf="center"
          />
        )}

        <ButtonsWrapper $errorMessage={!!errorMessage}>
          {!locked && (
            <ButtonContainer>
              {!isAuthenticated ? (
                <StyledSignUpToRedeemLink
                  testID="sign-up-to-redeem"
                  to={routes.signUp}
                  onPress={routeToSignUp}
                >
                  {formatMessage({ id: 'signUpToRedeem' })}
                </StyledSignUpToRedeemLink>
              ) : (
                <>
                  <DisableAddToMobileOrder_SimplifiedLandingPage__Experiment>
                    {canAddToMobileOrder && (
                      <StyledActionButton
                        disabled={disableMobileOrderButton}
                        onPress={handleRedeemRewardPress}
                        isLoading={checkingAvailability}
                        leftIcon={
                          SHOULD_DISPLAY_ICON ? (
                            <Icon
                              variant="mobile"
                              width="24px"
                              {...hiddenAccessibilityPlatformProps}
                            />
                          ) : undefined
                        }
                      >
                        {addRewardButtonLabel}
                      </StyledActionButton>
                    )}
                  </DisableAddToMobileOrder_SimplifiedLandingPage__Experiment>
                  {canAddToInRestaurantOrder && (
                    <StyledActionButton
                      disabled={disableInRestaurantOrderButton}
                      onPress={handleInRestaurantRedemptionPress}
                      isLoading={checkingAvailability}
                      marginTop="$3"
                      leftIcon={
                        SHOULD_DISPLAY_ICON ? (
                          <Icon
                            variant="store"
                            width="24px"
                            {...hiddenAccessibilityPlatformProps}
                          />
                        ) : undefined
                      }
                      variant={ActionButtonVariants.OUTLINE}
                    >
                      {inRestaurantRedemptionText}
                    </StyledActionButton>
                  )}
                </>
              )}
            </ButtonContainer>
          )}
          {showRemoveRewardButton && (
            <ButtonContainer>
              <StyledActionButton
                onPress={handleRemoveRewardsPress}
                variant={ActionButtonVariants.TEXT_ONLY}
                marginTop="$4"
              >
                {formatMessage({ id: 'removeRewardsFromCart' })}
              </StyledActionButton>
            </ButtonContainer>
          )}
        </ButtonsWrapper>

        {incentiveEvaluationResults && (
          <StyledIncentiveEvaluationMessages
            incentiveEvaluationResults={incentiveEvaluationResults}
            hideIcon
          />
        )}
        {isOffer && moreInfo && (
          <StyledDisclaimerContainer>
            <CustomDisclaimer content={moreInfo} />
          </StyledDisclaimerContainer>
        )}
      </Wrapper>
      {offersInCooldown && <LoyaltyOffersCooldownDrawer />}
    </>
  );
};
