import React from 'react';

import { Box } from '@rbilabs/universal-components';
import { useIntl } from 'react-intl';

import ActionButton, { ActionButtonVariants } from 'components/action-button';
import Dialog from 'components/dialog';

import { IReplaceRewardModal } from './types';

const ReplaceRewardModal = ({ onDismiss, onConfirm, isOpen }: IReplaceRewardModal) => {
  const { formatMessage } = useIntl();

  return (
    <Dialog
      showDialog={isOpen}
      modalAppearanceEventMessage={formatMessage({ id: 'oneRewardPerOrder' })}
      testID="one-reward-per-order-dialog"
      heading={formatMessage({ id: 'oneRewardPerOrder' })}
      body={formatMessage({ id: 'replaceAppliedReward' })}
      accessibilityLabel={formatMessage({ id: 'replaceAppliedReward' })}
      onDismiss={onDismiss}
      actions={
        <Box width="full" marginTop="$4">
          <ActionButton width="full" variant={ActionButtonVariants.PRIMARY} onPress={onConfirm}>
            {formatMessage({ id: 'yes' })}
          </ActionButton>
          <ActionButton
            marginTop="$2"
            width="full"
            variant={ActionButtonVariants.TEXT_ONLY}
            onPress={onDismiss}
          >
            {formatMessage({ id: 'no' })}
          </ActionButton>
        </Box>
      }
    />
  );
};

export default ReplaceRewardModal;
