import { Box, HStack, ScrollView } from '@rbilabs/universal-components';

export const Container = HStack.withConfig<{ $containerHasTopPadding: boolean }>(props => ({
  paddingTop: props.$containerHasTopPadding ? '5' : '0',
}));

export const StyledRecentItem = Box.withConfig({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  height: '100%',
  minHeight: '12',
  width: '64',
  marginX: '1.5',
  marginY: '0',
  padding: '4',
  background: Styles.color.cardBackground,
  borderRadius: '20',
  overflow: 'hidden',
});

export const ScrollViewStyled = ScrollView.withConfig({
  paddingBottom: '6',
});

export const StyledRecentItemProduct = Box.withConfig({
  height: '100%',
  display: 'flex',
  alignItems: 'center',
});

export const StyledRecentItemButton = Box.withConfig({
  marginTop: '4',
  marginY: 'auto',
  marginBottom: '2',
});
