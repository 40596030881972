import React from 'react';

import { HStack, Icon } from '@rbilabs/universal-components';
import { useIntl } from 'react-intl';

import Link from 'components/link';
import { VisuallyHidden } from 'components/ucl/visually-hidden';

import { socialIds, socialVariants } from './constants';
import { ISocialCompProps, ISocialIconsProps } from './types';

export const SocialComp: React.FC<ISocialCompProps> = props => {
  const { formatMessage } = useIntl();
  const socialVariant = socialVariants[props.item];
  const SocialId = socialIds[props.item];
  return (
    <Link to={props.href ?? ''} pl="$9">
      <VisuallyHidden accessibilityLabel={formatMessage({ id: SocialId, description: '' })} />
      <Icon variant={socialVariant} color="token.icon-reversed" size="sm" aria-hidden />
    </Link>
  );
};
export const SocialIcons = (props: ISocialIconsProps) => (
  <HStack testID="social-icons">
    {Object.keys(props).map((item, i) => {
      if (!props[item] || props[item] === '') {
        return null;
      }
      return <SocialComp key={`social_${i}`} item={item} href={props[item]} />;
    })}
  </HStack>
);
