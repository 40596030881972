import React from 'react';

import { Box } from '@rbilabs/universal-components';
import { useWindowDimensions } from 'react-native';

import Picture from 'components/picture';

import { IHeroPicture } from '../types';

const Hero: React.FC<React.PropsWithChildren<IHeroPicture>> = props => {
  const { height: screenHeight } = useWindowDimensions();

  const IMAGE_RATIO = 2 / 6;
  const imageHeight = screenHeight * IMAGE_RATIO;

  return (
    <Box height={imageHeight}>
      <Box alignItems={'center'}>
        <Picture image={props.image} alt={props.alt} height="full" objectFitContain />
      </Box>
    </Box>
  );
};

export default Hero;
