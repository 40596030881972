import React from 'react';

import { useRoute } from 'hooks/navigation/use-route';
import { routes } from 'utils/routing';

import { routesWithoutMobileHeader } from './constants';
import MobileHeader from './mobile-header';

export const routesWithoutHeader = [routes.cart];

const isPathInRouteList = (routeList: string[], pathname: string) =>
  routeList.some(route => pathname.startsWith(route));

const AppHeader: React.FC<React.PropsWithChildren<unknown>> = () => {
  const { pathname } = useRoute();

  if (isPathInRouteList(routesWithoutMobileHeader, pathname)) {
    return null;
  }

  return <MobileHeader />;
};

export default AppHeader;
