import { IntlShape } from 'react-intl';

import { MenuObjectTypes } from 'enums/menu';
import { CartEntryType } from 'generated/rbi-graphql';
import { IDayPartBoundary, IValidDayPart } from 'state/day-part/hooks/use-active-day-parts';
import {
  LoyaltyOffer,
  LoyaltyReward,
  isLoyaltyOffer,
  isReward,
  isValidOfferIncentive,
} from 'state/loyalty/types';
import { DAY_PART_SELECTIONS } from 'state/main-menu/types';
import {
  IMenuItem,
  getMenuItemDayParts,
  isAvailableForActiveDayParts,
  timeToString,
} from 'utils/availability';
import { findBurgersForBreakfastDayPart } from 'utils/daypart';
import { routes } from 'utils/routing';

import { IMenuItemDescriptor } from './types';

const REWARD_ID_QUERY_PARAM = 'rewardId';

export const makeUrlForIncentive = (
  incentive: LoyaltyReward | LoyaltyOffer,
  menuItemDescriptor?: IMenuItemDescriptor
) => {
  const descriptor = menuItemDescriptor || getMenuItemDescriptorForIncentive(incentive);
  if (!descriptor) {
    return undefined;
  }

  const queryParam = isLoyaltyOffer(incentive)
    ? ``
    : `?rewardBenefitId=${descriptor._id}&${REWARD_ID_QUERY_PARAM}=${incentive._id}`;

  return `/menu/${descriptor._type}-${descriptor._id}${queryParam}`;
};

export const getRewardIdFromSearch = (search: string) => {
  const rewardId = new URLSearchParams(search).get(REWARD_ID_QUERY_PARAM);
  return rewardId;
};

export interface IMakeIncentiveUnavailableMessageArgs {
  isIncentiveAvailable: boolean;
  incentive: LoyaltyReward | LoyaltyOffer;
  dayParts: ReadonlyArray<IValidDayPart>;
  formatMessage: IntlShape['formatMessage'];
  formatTime: IntlShape['formatTime'];
  isAvailableForDayPart: boolean;
  stagedCartPoints: number;
  pointCost?: number;
  locked?: boolean | null;
  burgersForBreakfastEnabled?: boolean | null;
}

interface ICheckIncentiveAvailableForDayPart {
  incentive: LoyaltyReward | LoyaltyOffer;
  activeDayParts: IDayPartBoundary[];
  burgersForBreakfastEnabled?: boolean;
}

export const checkIncentiveAvailableForDayPart = ({
  incentive,
  activeDayParts,
  burgersForBreakfastEnabled,
}: ICheckIncentiveAvailableForDayPart) => {
  const burgersForBreakfastDayPart = findBurgersForBreakfastDayPart(activeDayParts);

  if (isLoyaltyOffer(incentive) && incentive.daypart?.length) {
    const dayPartsSet = new Set(incentive.daypart);

    // Burgers For Breakfast
    if (burgersForBreakfastDayPart && dayPartsSet.has(burgersForBreakfastDayPart.key)) {
      return !!burgersForBreakfastEnabled;
    }

    return activeDayParts.some(dayPart => dayPartsSet.has(dayPart.key));
  }

  if (!incentive?.incentives?.length) {
    return true;
  }
  // For now get first incentive, future implementation TBD
  const [incentiveItem] = incentive.incentives;

  if (incentiveItem?._type === CartEntryType.OFFERDISCOUNT) {
    return true;
  }

  // Burgers For Breakfast
  if (
    burgersForBreakfastDayPart &&
    isAvailableForActiveDayParts({
      activeDayParts: [burgersForBreakfastDayPart],
      menuData: incentiveItem as IMenuItem,
    })
  ) {
    return !!burgersForBreakfastEnabled;
  }

  const result = isAvailableForActiveDayParts({
    activeDayParts,
    menuData: incentiveItem as IMenuItem,
  });

  return result;
};

export const makeIncentiveUnavailableMessage = ({
  isIncentiveAvailable,
  dayParts,
  isAvailableForDayPart,
  formatMessage,
  formatTime,
  locked,
  pointCost = 0,
  incentive,
  stagedCartPoints,
  burgersForBreakfastEnabled,
}: IMakeIncentiveUnavailableMessageArgs) => {
  if (locked) {
    return;
  }

  let message = undefined;

  if (!isIncentiveAvailable) {
    message = formatMessage({ id: 'nonAvailableItemInStore' });
  } else if (!isAvailableForDayPart) {
    const getNextAvailableDayPart = (
      incentiveDayParts: LoyaltyOffer['daypart']
    ): IValidDayPart | undefined => {
      const incentiveDayPartsSet = new Set(incentiveDayParts?.map(part => part?.toLowerCase()));
      // Burgers for Breakfast
      if (
        incentiveDayPartsSet.has(DAY_PART_SELECTIONS.BURGERS_FOR_BREAKFAST.toLowerCase()) &&
        !burgersForBreakfastEnabled
      ) {
        return;
      }
      return dayParts.find(
        dayPart => dayPart.key && incentiveDayPartsSet.has(dayPart.key.toLowerCase())
      );
    };

    if (isReward(incentive) && incentive.incentives?.length) {
      const nextAvailableDayPart = getNextAvailableDayPart(
        getMenuItemDayParts(incentive.incentives[0] as IMenuItem)
      );
      message = nextAvailableDayPart
        ? formatMessage(
            { id: 'nonAvailableRewardUntilX' },
            {
              time: timeToString(nextAvailableDayPart.startTime, formatTime),
            }
          )
        : formatMessage({ id: 'nonAvailableReward' });
    } else if (isLoyaltyOffer(incentive) && incentive.daypart?.length) {
      const nextAvailableDayPart = getNextAvailableDayPart(incentive.daypart);
      message = nextAvailableDayPart
        ? formatMessage(
            { id: 'nonAvailableOfferUntil' },
            {
              time: timeToString(nextAvailableDayPart.startTime, formatTime),
            }
          )
        : formatMessage({ id: 'offerNotAvailable' });
    }
  }

  if (!message && stagedCartPoints < pointCost) {
    message = formatMessage({ id: 'exceedsRewardsBalance' });
  }

  return message;
};

const findBenefitAvailableInMenu = <IncentiveObject extends readonly ({ _type?: string } | null)[]>(
  incentives: IncentiveObject | null | undefined,
  benefitId?: string
) => {
  return incentives?.find(benefit => {
    // Discounts are not shown in menu
    if (
      !benefit ||
      benefit._type === MenuObjectTypes.OFFER_DISCOUNT ||
      !isValidOfferIncentive(benefit)
    ) {
      return false;
    }
    return benefitId ? benefit?._id === benefitId : true;
  });
};

/**
 * Find first incentive benefit that is available as a menu item.
 * If benefitId is passed, try to find a match.
 */
export const getMenuItemDescriptorForIncentive = (
  incentive: LoyaltyReward | LoyaltyOffer,
  benefitId?: string
): IMenuItemDescriptor | undefined => {
  const benefitAvailableInMenu = findBenefitAvailableInMenu(incentive?.incentives, benefitId);

  const { _id, _type }: { _id?: string; _type?: string } = benefitAvailableInMenu || {};
  return _id && _type ? { _id, _type } : undefined;
};

export const getMenuIdForIncentive = (
  incentive: LoyaltyReward | LoyaltyOffer,
  benefitId?: string
): string | undefined => {
  const menuDescriptor = getMenuItemDescriptorForIncentive(incentive, benefitId);

  return menuDescriptor ? `${menuDescriptor._type}-${menuDescriptor._id}` : undefined;
};

export const makeLoyaltyRewardDetailsPath = ({
  rewardId,
  search = '',
}: {
  rewardId: string;
  search?: string;
}) => {
  const rewardIdPath = rewardId ? `/${rewardId}` : '';
  return `${routes.loyaltyRewardsList}${rewardIdPath}${search}`;
};
