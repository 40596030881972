import { BoxProps } from '@rbilabs/universal-components';
import { FlatListProps } from 'react-native';

type requiredNonNullable<T> = {
  [K in keyof T]-?: NonNullable<T[K]>;
};

type CarouselDataProps<T> = Pick<FlatListProps<T>, 'keyExtractor' | 'renderItem' | 'data'>;
interface IResponsiveCarouselIsLoadedDataProps<T>
  extends requiredNonNullable<CarouselDataProps<T>> {
  isLoading?: false | undefined;
}

interface IResponsiveCarouselIsLoadingDataProps<T> extends Partial<CarouselDataProps<T>> {
  isLoading: true;
}

type IResponsiveCarouselDataProps<T> =
  | IResponsiveCarouselIsLoadedDataProps<T>
  | IResponsiveCarouselIsLoadingDataProps<T>;

export type IResponsiveCarouselProps<T> = {
  itemWidth?: number;
  bgColor?: string;
  centerCards?: boolean;
  alignWithParent?: boolean;
  loop?: boolean;
  inactiveSlideOpacity?: number;
  inactiveSlideScale?: number;
  activeItemIndex?: number;
  snapOnItem?: (activeIndex: number) => void;
} & IResponsiveCarouselDataProps<T>;

export enum Sizes {
  SPACER = 8,
  CARDS_PER_SLIDE_TABLET = 3,
  CARDS_PER_SLIDE_DESKTOP = 4,
}

export enum ItemWidth {
  MARKETING_CAROUSEL_ITEM_MOBILE = 253,
  MARKETING_CAROUSEL_ITEM_WEB = 276,
  VERTICAL_CAROUSEL_ITEM_MOBILE = 150,
  VERTICAL_CAROUSEL_ITEM_WEB = 182,
}

export type IResponsiveCarouselWrapperProps = BoxProps & {
  header: string;
  linkText?: string;
  linkPath?: string;
  linkAccessibilityLabel?: string;
};
