import { Box, HStack, Pressable } from '@rbilabs/universal-components';

import Link from 'components/link';
import { theme } from 'styles/configure-theme';

// The absolute position pops it out of the flex flow
// and this allows our centered logo to be truly centered and not offset by the menu item
export const MenuButtonContainer = HStack.withConfig({
  position: 'absolute',
  zIndex: 1,
  left: '4',
  flexShrink: 1,
  alignItems: 'center',
});

export const MobileHeaderContainer = Box.withConfig<{
  reversed?: boolean;
}>(p => ({
  safeAreaTop: true,
  paddingBottom: '0px',
  width: 'full',
  background: p.reversed
    ? theme.token('background-hero-reversed')
    : theme.token('background-appbar-navbar'),
  zIndex: Styles.zIndex.overlay,
  _web: {
    borderWidth: 1,
    borderColor: 'blackOpacity.10',
  },
}));

export const Header = HStack.withConfig({
  alignItems: 'center',
  color: theme.token('text-reversed'),
  minHeight: Styles.layout.navHeight.mobile,
  justifyContent: 'space-between',
  paddingX: '$4',
  paddingY: '$1.5',
  textAlign: 'center',
  width: 'full',
});

const headerIconPressedBg = theme.token('background-icon-header-contrast');

const headerIconContainerStyles = {
  zIndex: Styles.zIndex.overlay,
  _pressed: {
    backgroundColor: headerIconPressedBg,
  },
  _focus: {
    backgroundColor: headerIconPressedBg,
  },
  _hover: {
    backgroundColor: headerIconPressedBg,
    color: 'inherit',
  },
};

export const LinkNoDecoration = Link.withConfig({
  ...headerIconContainerStyles,
});

export const ActionButton = Pressable.withConfig({
  ...headerIconContainerStyles,
});

export const HeaderIconContainer = Pressable.withConfig({
  ...headerIconContainerStyles,
  padding: 1,
  borderRadius: 'full',
});

export const LeftNavWrapper = HStack;
