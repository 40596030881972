import * as React from 'react';
import { FC } from 'react';

import { useIntl } from 'react-intl';

import { useNavigation } from 'hooks/navigation/use-navigation';
import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';
import { useIsLoyaltyEnabled } from 'state/loyalty/hooks/use-is-loyalty-enabled';
import { useLoyaltyUser } from 'state/loyalty/hooks/use-loyalty-user';

import { LoyaltyCtaBarView } from './loyalty-cta-bar.view';
import { PromoMessageRibbon } from './loyalty-ribbon';

/**
 *
 * <LoyaltyCtaBar /> displays below app header on homepage
 * If user is authenticated, displays number of rewards
 * otherwise prompts user to create account.
 *
 */
const LoyaltyCtaBar: FC<React.PropsWithChildren<unknown>> = () => {
  const loyaltyEnabled = useIsLoyaltyEnabled();
  const { loyaltyUser, loading: loyaltyLoading } = useLoyaltyUser();
  const showLoyaltyBannerV1 = useFlag(LaunchDarklyFlag.SHOW_LOYALTY_BANNER_V1);

  const { navigate } = useNavigation();
  const { formatMessage } = useIntl();
  const handlePress = () => {
    const navigationRoute =
      loyaltyUser == null
        ? formatMessage({ id: 'routes.signUp' })
        : formatMessage({ id: 'routes.loyaltyDashboard' });

    navigate(navigationRoute);
  };

  if (!loyaltyEnabled || !showLoyaltyBannerV1) {
    return null;
  }
  const loading = !loyaltyUser && loyaltyLoading;
  const bestPromoId = loyaltyUser?.bestPromotion?.sanityId;

  return (
    <>
      <LoyaltyCtaBarView loading={loading} handlePress={handlePress} points={loyaltyUser?.points} />
      {bestPromoId && <PromoMessageRibbon promoId={bestPromoId} />}
    </>
  );
};

export default LoyaltyCtaBar;
