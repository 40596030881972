import * as React from 'react';
import { FC } from 'react';

import { InlineAlert } from '@rbilabs/universal-components';
import { secondsToMinutes } from 'date-fns';
import { useIntl } from 'react-intl';

import { useRoute } from 'hooks/navigation/use-route';
import { areLoyaltyOffersInCooldown } from 'hooks/use-are-loyalty-offers-in-cooldown';
import { useLoyaltyOffersCooldownCountdown } from 'hooks/use-loyalty-offers-cooldown-countdown';
import { selectors, useAppSelector } from 'state/global-state';
import { routes } from 'utils/routing';

const LoyaltyOffersCooldown: FC<React.PropsWithChildren<unknown>> = () => {
  const { formatMessage } = useIntl();
  const { pathname } = useRoute();
  const cooldownCountdown = useLoyaltyOffersCooldownCountdown();
  const isOffersPage = pathname.includes(routes.offers);
  const isAvailableAfterTimestamp = useAppSelector(
    selectors.loyalty.selectOfferRedemptionAvailableAfter
  );

  if (
    !areLoyaltyOffersInCooldown(cooldownCountdown) ||
    !isOffersPage ||
    !isAvailableAfterTimestamp
  ) {
    return null;
  }

  return (
    <InlineAlert
      message={formatMessage(
        { id: 'offersCooldownDescription' },
        {
          minutes: secondsToMinutes(cooldownCountdown),
        }
      )}
      status="info"
      mb="$2"
    />
  );
};

export default LoyaltyOffersCooldown;
