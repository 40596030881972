import React, { FC, useEffect } from 'react';

import { useIntl } from 'react-intl';

import { LockedOffers } from 'components/rewards/locked-offers';
import { useIsMobileBp } from 'hooks/breakpoints';
import { Section } from 'pages/home/styled';
import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';
import { useOffersContext } from 'state/offers';

import { FULL_WIDTH } from './constants';
import { SectionHeader } from './section-header';

export const LockedOffersSection: FC<React.PropsWithChildren<unknown>> = () => {
  const { lockedOffers, refetchOffersList } = useOffersContext();
  const showLockedOffers = useFlag(LaunchDarklyFlag.ENABLE_LOCKED_OFFERS);
  const { formatMessage } = useIntl();
  const isMobile = useIsMobileBp();
  const hasLockedOffers = showLockedOffers && lockedOffers?.length;

  useEffect(() => {
    refetchOffersList();
  }, [refetchOffersList]);

  if (!hasLockedOffers) {
    return null;
  }

  return (
    <Section $fullWidth={FULL_WIDTH} testID="locked-offer-section">
      <SectionHeader isMobile={isMobile}>{formatMessage({ id: 'myChallenges' })}</SectionHeader>
      <LockedOffers />
    </Section>
  );
};
