import { HStack, Icon, Text } from '@rbilabs/universal-components';

import { theme } from 'styles/configure-theme';

const ChipContainer = HStack.withConfig<{ $roundedCorners?: boolean }>(p => ({
  backgroundColor: theme.token('background-chip'),
  borderRadius: p.$roundedCorners ? '12px' : theme.token('border-radius-chip'),
  height: theme.token('height-chip'),
  minWidth: '$6',
  paddingY: '$0',
  paddingX: '$2',
  alignItems: 'center',
  alignSelf: 'flex-start',
}));

const ChipText = Text.withConfig<{ $hasIcon?: boolean }>(p => ({
  variant: 'copyTwo',
  color: theme.token('text-reversed'),
  paddingLeft: p.$hasIcon ? '$1' : '$0',
}));

const ChipIcon = Icon.withConfig({
  color: theme.token('icon-reversed'),
  size: theme.token('width-icon-small'),
});

export { ChipContainer, ChipIcon, ChipText };
