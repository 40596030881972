import React from 'react';

import { Svg, SvgProps, SvgRect } from '@rbilabs/universal-components';

export const BkProductBgWebSvg = (props: SvgProps) => (
  <Svg {...props} viewBox="0 0 768 267" preserveAspectRatio="xMidYMid slice">
    <SvgRect y="197" width="768" height="70" fill="rgb(245, 235, 220)" />
  </Svg>
);

export const BkProductBgMobileSvg = (props: SvgProps) => (
  <Svg {...props} viewBox="0 0 375 267" preserveAspectRatio="xMidYMid slice">
    <SvgRect y="197" width="375" height="70" fill="rgb(245, 235, 220)" />
  </Svg>
);
