import React, { useState } from 'react';

import { Link } from '@rbilabs/universal-components';
import { useIntl } from 'react-intl';

import { COLOR } from './constants';

const SkipToContentContainer = Link.withConfig({
  position: 'fixed',
  _text: {
    color: COLOR,
  },
  isUnderlined: false,
});

const makeLinkStyle = (focused: boolean) => ({
  top: focused ? '0px' : '-9999px',
  left: focused ? '0px' : '-9999px',
  opacity: focused ? 100 : 0,
});

const SkipToContent = () => {
  const { formatMessage } = useIntl();

  const [focused, setFocused] = useState(false);
  const linkStyle = makeLinkStyle(focused);

  return (
    <SkipToContentContainer
      href="#main"
      // @ts-ignore
      onFocus={() => {
        setFocused(true);
      }}
      onBlur={() => {
        setFocused(false);
      }}
      style={linkStyle}
    >
      {formatMessage({ id: 'skipToContent' })}
    </SkipToContentContainer>
  );
};

export default SkipToContent;
