import React, { useEffect, useMemo } from 'react';

import { Box } from '@rbilabs/universal-components';

import { BlankScreen } from 'components/blank-screen';
import { Helmet } from 'components/helmet';
import LoadingAnimation from 'components/loading-animation';
import LoadingContainer from 'components/loading-animation/loading-container';
import { BackArrowHistory } from 'components/navigation/back-arrow';
import { useConfigValue } from 'hooks/configs/use-config-value';
import { useNavigation } from 'hooks/navigation/use-navigation';
import { useRoute } from 'hooks/navigation/use-route';
import useEffectOnce from 'hooks/use-effect-once';
import NotFound from 'pages/not-found';
import { RenderCurrentStaticPage } from 'pages/static/render-current-static-page';
import { useLocale } from 'state/intl';
import { usePageManagerContext } from 'state/static-page-manager';
import { useGetStaticPageByRoute } from 'state/static-page-manager/hooks/use-get-static-page-by-route';
import { routes as appRoutes } from 'utils/routing';
import { isRedirectedToSimplexUrl } from 'utils/simplex';

import { StaticContentLanguageProvider } from './static-content-language';
import { StaticPageSkeleton } from './static-page.skeleton';

const Container = Box.withConfig({
  flex: 1,
  paddingTop: {
    sm: '0px',
  },
  marginTop: {
    sm: '0px',
  },
});

const StaticPageContainer = () => {
  const { routesLoading, loadRoutes, loadRoutesHasBeenCalled } = usePageManagerContext();
  useEffectOnce(() => {
    // If the loadRoutes query has not been called yet, load the static routes list
    if (!loadRoutesHasBeenCalled) {
      loadRoutes();
    }
  });
  if (!loadRoutesHasBeenCalled || routesLoading) {
    return (
      <LoadingContainer>
        <LoadingAnimation />
      </LoadingContainer>
    );
  }
  return <StaticPage />;
};

export const StaticPage = () => {
  const { currentPage, retrieveStaticPageById, routes } = usePageManagerContext();
  const { navigate } = useNavigation();
  const url = useConfigValue({ key: 'urls', defaultValue: {} })?.webBaseUrl ?? null;

  const { language: appUILanguage } = useLocale();
  const { params } = useRoute<{ staticPagePath: string }>();
  const { staticPagePath } = params || {};

  // Remove first and last slash to check the "staticPath"
  const staticPath = (staticPagePath || '').replace(/^\/|\/$/g, '');

  const { route: targetPageRoute, defaultLanguage: targetPageDefaultLanguage } = useMemo(() => {
    for (const route of routes || []) {
      if (route.localePath?.en?.current === staticPagePath) {
        return { route, defaultLanguage: 'en' } as const;
      }

      if (route.localePath?.fr?.current === staticPagePath) {
        return { route, defaultLanguage: 'fr' } as const;
      }

      if (route.path.current === staticPagePath) {
        return { route, defaultLanguage: appUILanguage } as const;
      }
    }

    return {};
  }, [routes, staticPagePath, appUILanguage]);

  useEffect(() => {
    if (!targetPageRoute) {
      return;
    }

    retrieveStaticPageById(
      targetPageRoute._id,
      targetPageRoute.path.current === appRoutes.nutritionExplorer.slice(1)
    );
  }, [targetPageRoute, retrieveStaticPageById]);

  const is404 = !targetPageRoute;

  if (is404) {
    // If it is a SEO simplex URL navigate home.
    const fullPath = `/${staticPath}`;
    const wasRedirectedToSimplex = isRedirectedToSimplexUrl(fullPath, url);

    if (wasRedirectedToSimplex) {
      navigate(appRoutes.base);
      return (
        <BlankScreen
          context={{
            reason: 'Redirect to simple from static pages',
          }}
        />
      );
    }

    return <NotFound />;
  }

  return currentPage ? (
    <StaticContentLanguageProvider defaultLanguage={targetPageDefaultLanguage}>
      <Box testID="static-page" flex={1}>
        <Helmet title={currentPage.title} />
        <BackArrowHistory />
        <Container>
          <RenderCurrentStaticPage currentPage={currentPage} />
        </Container>
      </Box>
    </StaticContentLanguageProvider>
  ) : (
    <LoadingContainer>
      <StaticPageSkeleton />
    </LoadingContainer>
  );
};

/**
 * This function takes a pathname as param and render a Static Page component that can be embedded
 * inside a page / component so we're not limited to using routes for static content.
 */
export const StaticPageEmbedded = ({
  pathname,
  currentPageOverrideCss,
}: {
  pathname: string;
  currentPageOverrideCss?: string;
}) => {
  const { currentPage, getStaticPageByRouteId, is404 } = useGetStaticPageByRoute();

  useEffect(() => {
    getStaticPageByRouteId(pathname);
  }, [getStaticPageByRouteId, pathname]);

  if (is404) {
    return <NotFound />;
  }

  return (
    <Box testID="static-page-embeded" flex={1}>
      {currentPage ? (
        <RenderCurrentStaticPage
          currentPage={currentPage}
          currentPageOverrideCss={currentPageOverrideCss}
        />
      ) : (
        <LoadingContainer>
          <StaticPageSkeleton />
        </LoadingContainer>
      )}
    </Box>
  );
};

export default StaticPageContainer;
