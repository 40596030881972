import { LOCALES } from 'state/intl/types';
import logger from 'utils/logger';

const localeImport = {
  en_ca: () => import(`@formatjs/intl-listformat/locale-data/en-CA`),
  en_ch: () => import(`@formatjs/intl-listformat/locale-data/en-CH`),
  en_de: () => import(`@formatjs/intl-listformat/locale-data/en-DE`),
  en_gb: () => import(`@formatjs/intl-listformat/locale-data/en-GB`),
  en_nz: () => import(`@formatjs/intl-listformat/locale-data/en-NZ`),
  en_us: () => import(`@formatjs/intl-listformat/locale-data/en-US`),
  en_za: () => import(`@formatjs/intl-listformat/locale-data/en-ZA`),
  de_ch: () => import(`@formatjs/intl-listformat/locale-data/de-CH`),
  de_de: () => import(`@formatjs/intl-listformat/locale-data/de`),
  fr_ca: () => import(`@formatjs/intl-listformat/locale-data/fr-CA`),
  fr_ch: () => import(`@formatjs/intl-listformat/locale-data/fr-CH`),
  it_ch: () => import(`@formatjs/intl-listformat/locale-data/it-CH`),
};

export const polyfillListFormatLocale = async (locale: LOCALES) => {
  try {
    if (locale in LOCALES) {
      await localeImport[locale]();
    } else {
      await import('@formatjs/intl-listformat/locale-data/en-US');
    }
  } catch (e) {
    // @ts-expect-error TS(2345) FIXME: Argument of type 'unknown' is not assignable to pa... Remove this comment to see the full error message
    logger.warn(e);
  }
};
