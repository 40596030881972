import { useEffect } from 'react';

import { useIntl } from 'react-intl';

interface SetModalCtaOptions {
  children: any[];
  onClick: () => void;
}

interface UseModalCtaOptions {
  addToOrder: () => void;
  addToOrderText: string;
  currentPrice: string;
  incrementStep: () => void;
  isFinalStep: boolean;
  setModalCta: (options: SetModalCtaOptions) => void;
}

const useModalCta = ({
  addToOrder,
  addToOrderText,
  currentPrice,
  incrementStep,
  isFinalStep,
  setModalCta,
}: UseModalCtaOptions) => {
  const { formatMessage } = useIntl();

  useEffect(() => {
    if (isFinalStep) {
      setModalCta({
        children: [addToOrderText, currentPrice],
        onClick: addToOrder,
      });
    } else {
      setModalCta({
        children: [formatMessage({ id: 'continue' }), currentPrice],
        onClick: incrementStep,
      });
    }
  }, [
    addToOrder,
    addToOrderText,
    currentPrice,
    formatMessage,
    incrementStep,
    isFinalStep,
    setModalCta,
  ]);
};

export default useModalCta;
