import React, { useState } from 'react';

import { Box } from '@rbilabs/universal-components';
import { WebView } from 'react-native-webview';

import { theme } from 'styles/configure-theme';

export interface IDontationWidget {
  id: string;
  css: string;
}

const DonationWidgetContainer = Box.withConfig({
  width: '90%',
  maxWidth: 920,
  marginY: '$8',
  marginX: 'auto',
});

// The script that renders the donation form is now hosted on s3.
const DONATION_FORM_URI = 'https://ctg-plk-hosted.s3.amazonaws.com/donation-form.html';
const INITIAL_WEBVIEW_HEIGHT = 900;

const convertCSSToString = (style: string) => {
  const globalStyle = `#mongo-form > * { font: ${theme.token(
    'text-style-form-options'
  )}; font-size: 1rem; } [data-style="Narrow_Buttons"] .BBFormRadioLabelGivingLevelSelected { background: #be5a07 !important; border: none !important; }`;

  const webViewStyle = globalStyle + style;
  const withoutLineBreaks = webViewStyle.replace(/[\n]/g, ' ');
  const replaceSingleWithDoubleQuotes = withoutLineBreaks.replace(/'/g, '"');

  return `'${replaceSingleWithDoubleQuotes.trim()}'`;
};

// Styling from Sanity does not have any effect when loading a webview from URI
// Manually inject css to Webview through Javascript
const injectCSS = (css: string) => `
      const head = document.head || document.getElementsByTagName('head')[0];
      const style = document.createElement('style');

      style.type = 'text/css';
      style.appendChild(document.createTextNode(${css}));
      head.appendChild(style);
`;

const webViewScript = (css: string) => `
  const intervalId = setInterval(checkDonationForm, 200)

  function postMessageData() {
    // Gets the web view height dynamically after the donation form is rendered.
    window.ReactNativeWebView.postMessage(document.documentElement.scrollHeight + 100);
  }

  function checkDonationForm() {
    const donationForm = document.getElementById('mongo-form');
    
    if (!!donationForm) {
      postMessageData();
      clearInterval(intervalId);
      document.getElementById('bboxdonation_btnSubmit').addEventListener('click', () => postMessageData());
    }
  }

  true; // note: this is required, or you'll sometimes get silent failures
  ${injectCSS(css)}
`;

const DonationWidget: React.FC<React.PropsWithChildren<IDontationWidget>> = ({
  id = '',
  css = '',
}) => {
  const [webViewHeight, setWebViewHeight] = useState(INITIAL_WEBVIEW_HEIGHT);

  const formattedUri = `${DONATION_FORM_URI}?id=${id}`;

  const donationWidgetStyle = convertCSSToString(css);
  const injectJavaScript = webViewScript(donationWidgetStyle);

  return (
    <DonationWidgetContainer>
      <WebView
        style={{ height: webViewHeight }}
        source={{ uri: formattedUri }}
        injectedJavaScript={injectJavaScript}
        scrollEnabled={false}
        onMessage={event => {
          setWebViewHeight(parseInt(event.nativeEvent.data));
        }}
      />
    </DonationWidgetContainer>
  );
};

export default DonationWidget;
