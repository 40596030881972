import { HStack, Header, Text, VStack } from '@rbilabs/universal-components';

import Link from 'components/link';
import Picture from 'components/picture';
import SanityBlockRenderer from 'components/sanity-block-renderer';

export const ContainerBox = HStack.withConfig({
  alignItems: 'center',
  padding: '$4',
  flex: 1,
  height: '$32',
  backgroundColor: Styles.color.white,
  borderRadius: '10px',
  marginX: '$4',
  marginBottom: '$4',
  marginTop: '$4',
});

export const ContainerLink = Link.withConfig({
  textDecoration: 'none',
});

export const TitleAndDescriptionContainer = VStack.withConfig({
  flex: 1,
  space: '$1',
});

export const Title = Header.withConfig({
  variant: 'headerTwo',
  margin: '0',
});

export const DescriptionText = Text.withConfig({
  variant: 'copyOne',
  margin: '0',
});

export const DescriptionBlock = SanityBlockRenderer.withConfig({
  textProps: {
    fontFamily: 'body',
  },
});

export const StyledPicture = Picture.withConfig({
  width: '100px',
  height: '100px',
});
