import { Header } from '@rbilabs/universal-components';

export const Heading = Header.withConfig({
  marginTop: {
    base: 0,
    md: 20,
  },
  marginBottom: {
    base: 0,
    md: 1,
  },
  marginLeft: {
    base: 1,
    md: 0,
  },
  textAlign: 'center',
});
