import React, { FC, createContext, useCallback, useContext, useState } from 'react';

import { IBaseProps, SetState } from '@rbi-ctg/frontend';
import LoadingIndicatorBar from 'components/loading-indicator-bar';
import { useIsTabletBp } from 'hooks/breakpoints';
import { useRoute } from 'hooks/navigation/use-route';
import useEffectOnUnmount from 'hooks/use-effect-on-unmount';
import { brand } from 'utils/environment';
import noop from 'utils/noop';
import { routes } from 'utils/routing';

export interface ILoadingContext {
  loading: boolean;
  LoadingIndicator: FC<React.PropsWithChildren<IBaseProps>>;
  setLoading: SetState<boolean>;
}

export const LoadingContext = createContext<ILoadingContext>({
  loading: false,
  setLoading: noop,
  // @ts-expect-error TS(2322) FIXME: Type '({ fill, show, className, }: ILoadingIndicat... Remove this comment to see the full error message
  LoadingIndicator: LoadingIndicatorBar,
});

const useShouldShow = () => {
  const { pathname } = useRoute();
  const match = useIsTabletBp();
  if (brand() === 'th' || !match) {
    return true;
  }
  return pathname !== routes.base;
};

export const LoadingProvider = ({ children }: IBaseProps) => {
  const [loading, setLoading] = useState(false);
  const show = useShouldShow();

  const LoadingIndicator = useCallback(() => <LoadingIndicatorBar show={show && loading} />, [
    loading,
    show,
  ]);

  return (
    <LoadingContext.Provider value={{ loading, setLoading, LoadingIndicator }}>
      {children}
    </LoadingContext.Provider>
  );
};

export const useLoadingContext = () => useContext(LoadingContext);

export const useLoadingIndicator = () => useLoadingContext().LoadingIndicator;

export const useLoadingState = () => {
  const { loading, setLoading } = useLoadingContext();
  useEffectOnUnmount(() => {
    setLoading(false);
  });
  return { loading, setLoading };
};
