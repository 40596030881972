import React, { FC, useEffect } from 'react';

import { useFocusEffect } from 'hooks/navigation/use-focus-effect';
import { useStoreRequired } from 'state/store/hooks/use-store-required';

// Ensures that a store is selected before rendering children
const StoreSelected: FC<React.PropsWithChildren<unknown>> = ({ children }) => {
  const { handleStoreLocatorRedirect, isStoreRequired } = useStoreRequired();

  useEffect(() => {
    if (isStoreRequired) {
      handleStoreLocatorRedirect();
    }
  }, [handleStoreLocatorRedirect, isStoreRequired]);

  return <>{children}</>;
};

// @ts-ignore
export const withStoreSelected = Component => props => {
  const { handleStoreLocatorRedirect, isStoreRequired } = useStoreRequired();
  useFocusEffect(
    React.useCallback(() => {
      if (isStoreRequired) {
        handleStoreLocatorRedirect();
      }
    }, [handleStoreLocatorRedirect, isStoreRequired])
  );
  return <Component {...props} />;
};

export default StoreSelected;
