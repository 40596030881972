import * as React from 'react';
import { FC, memo } from 'react';

import { useIntl } from 'react-intl';

import type { ILoyaltyOffer } from 'state/loyalty/offers/types';
import { isOfferDeliveryOnly, isPromoCodeOffer } from 'state/loyalty/offers/utils';

import { OfferListItemBase } from './loyalty-offer-list-item-base';

export interface ILoyaltyIOfferListItemProps {
  onPress: (offer: ILoyaltyOffer) => void;
  offer: ILoyaltyOffer;
}

export const LoyaltyOfferListItem: FC<ILoyaltyIOfferListItemProps> = memo(({ onPress, offer }) => {
  const { formatMessage } = useIntl();
  const { image, imageDescription, name: incentiveName, description, serviceMode } = offer;

  const showPromoCodeLabel = isPromoCodeOffer(offer);
  const promoCodeLabel = showPromoCodeLabel
    ? formatMessage({ id: 'promoCodeBadgeLabel' })
    : undefined;

  return (
    <OfferListItemBase
      incentiveName={incentiveName}
      image={image}
      imageDescription={imageDescription}
      description={description}
      serviceMode={serviceMode}
      serviceModeStyle={isOfferDeliveryOnly(offer) ? 'default-outline' : 'default-solid'}
      promoCodeLabel={promoCodeLabel}
      onPress={() => onPress(offer)}
    />
  );
});
