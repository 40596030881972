import { Box } from '@rbilabs/universal-components';

import { ISectionContainerProps } from './types';

export const Section = Box.withConfig<ISectionContainerProps>(p => ({
  marginBottom: '0',
  marginTop: '4',
  marginX: 'auto',
  width: '100%',
  paddingX: p.$fullWidth ? '0' : '4',
  maxWidth: {
    md: '1050px',
  },
}));
