import { Icon, VStack } from '@rbilabs/universal-components';
import { useIntl } from 'react-intl';

import ConfirmDialog from 'components/confirm-dialog';
import { DialogActionSheet } from 'components/confirmation-actionsheet';
import { useIsDesktopBp } from 'hooks/breakpoints';
import { theme } from 'styles/configure-theme';
import noop from 'utils/noop';

import { BodyText, HeaderText, Wrapper } from './styled';
import { IStoreClosedActionSheetProps } from './types';
import { useNextOpenDay } from './use-next-open-day';

export const StoreClosedActionSheet = ({
  isOpen,
  onChangeRestaurant,
  onScheduleFutureOrder,
  restaurant,
}: IStoreClosedActionSheetProps) => {
  const { formatMessage } = useIntl();
  const isDesktop = useIsDesktopBp();
  const opensAtText = useNextOpenDay({ restaurant, isModalOpen: isOpen });

  const heading = (
    <Wrapper>
      <Icon variant="store" color={theme.token('icon-active')} alignSelf="center" size="$16" />
      <HeaderText mt="$4">{formatMessage({ id: 'selectedRestaurantIsClosed' })}</HeaderText>
    </Wrapper>
  );

  const body = (
    <VStack alignItems="center">
      <BodyText textAlign="center">{opensAtText}</BodyText>
      <BodyText textAlign="center" mt="$4">
        {formatMessage({ id: 'futureOrderingWorksForYou' })}
      </BodyText>
    </VStack>
  );

  const scheduleFutureOrderText = formatMessage({ id: 'scheduleFutureOrder' });

  const changeRestaurantText = formatMessage({ id: 'changeRestaurant' });

  const modalTextForLogging = formatMessage({ id: 'selectedRestaurantIsClosed' });

  return (
    <>
      {isDesktop ? (
        <>
          <ConfirmDialog
            showDialog={isOpen}
            onConfirm={onScheduleFutureOrder}
            onDismiss={noop}
            onCancel={onChangeRestaurant}
            confirmLabel={scheduleFutureOrderText}
            cancelLabel={changeRestaurantText}
            modalAppearanceEventMessage={modalTextForLogging}
            headingComponent={heading}
            bodyComponent={body}
          />
        </>
      ) : (
        <DialogActionSheet
          isOpen={isOpen}
          onConfirm={onScheduleFutureOrder}
          onDismiss={noop}
          onPressDismissButton={onChangeRestaurant}
          confirmButtonLabel={scheduleFutureOrderText}
          dismissButtonLabel={changeRestaurantText}
          titleString={modalTextForLogging}
          title={heading}
          body={body}
        />
      )}
    </>
  );
};
