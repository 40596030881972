import { useIntl } from 'react-intl';

import { IMainMenuViewOption } from '@rbi-ctg/menu';
import { useNavigation } from 'hooks/navigation/use-navigation';
import { useRoute } from 'hooks/navigation/use-route';
import { useDayPartContext } from 'state/day-part';
import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';
import { useMenuContext } from 'state/menu';
import { useServiceModeContext } from 'state/service-mode';
import { getTabbedMenuData, optionToUrl } from 'utils/menu';
import { useIsBrowseMenu } from 'utils/menu/hooks/use-is-browse-menu';
import {
  isFavoritesRoute,
  isItemPickerOrComboRoute,
  isRecentItemsRoute,
  relativeRoutes,
  routes,
} from 'utils/routing';
import { isCateringPickup } from 'utils/service-mode';

import { IUseTabbedHeaderProps } from './types';

type ITabbedItem = {
  tabItemId: string;
  label: string;
  to: string;
};

const getActiveTab = (data: ITabbedItem[], pathname: string) => {
  return data.findIndex(item => item?.to === pathname);
};

/**
 * This hook is specific for legacy navigation
 * React navigation uses useMenuSectionTabs hook
 * TODO - React Navigation: Deprecate hook once fully migrated
 */
export default function useTabbedMenuHeader({
  menuData,
  menuDataLoading,
  dayPartMenu,
  activeDayParts,
}: IUseTabbedHeaderProps) {
  const { formatMessage } = useIntl();
  const { navigate } = useNavigation();
  const { pathname } = useRoute();
  const { isBrowseMenu } = useIsBrowseMenu(pathname);
  const enableTabbedMenuBarRecents = useFlag(LaunchDarklyFlag.ENABLE_TABBED_MENU_BAR_RECENTS);
  const enableTabbedMenuBarFavorites = useFlag(LaunchDarklyFlag.ENABLE_TABBED_MENU_BAR_FAVORITES);
  const enableCatering = useFlag(LaunchDarklyFlag.ENABLE_CATERING);

  const { serviceMode } = useServiceModeContext();
  const { activeDayParts: currentActiveDayParts } = useDayPartContext();
  const {
    getPricingAndAvailability,
    getDayPart,
    showStaticMenu,
    showBurgersForBreakfast,
  } = useMenuContext();

  const mainMenuFilteredItems: IMainMenuViewOption[] =
    (!showStaticMenu
      ? // @ts-expect-error TS(2349) FIXME: This expression is not callable.
        menuData?.options?.filter(item => {
          if (item?.__typename === 'Combo') {
            return false;
          }

          return getPricingAndAvailability(item?._id)?.isAvailable;
        })
      : menuData?.options) || [];

  const { currentDayPart } = getDayPart({ showStaticMenu });

  const menuItems = getTabbedMenuData({
    mainMenuItems: mainMenuFilteredItems,
    dayPartMenu: dayPartMenu ?? currentDayPart,
    activeDayParts: activeDayParts ?? currentActiveDayParts,
    showBurgersForBreakfast,
    serviceMode,
    showStaticMenu,
  });

  const sectionMenuItems = menuItems.map(item => ({
    tabItemId: item._id,
    label: item.name?.locale,
    to: optionToUrl(item, isBrowseMenu),
  }));

  const fullMenuLabelKey = 'fullMenu';
  const recentsLabelKey = 'recents';
  const favoriteLabelKey = 'favorite';
  const cateringLabelKey = 'cateringMenu';

  const fullMenu = {
    tabItemId: fullMenuLabelKey,
    label: formatMessage({ id: fullMenuLabelKey }),
    to: routes.menu,
  };
  const recentItems = enableTabbedMenuBarRecents
    ? {
        tabItemId: recentsLabelKey,
        label: formatMessage({ id: recentsLabelKey }),
        to: `${routes.menu}/${relativeRoutes.recentItems}`,
      }
    : null;

  const favorites = enableTabbedMenuBarFavorites
    ? {
        tabItemId: favoriteLabelKey,
        label: formatMessage({ id: favoriteLabelKey }),
        to: routes.favorites,
      }
    : null;

  const catering =
    enableCatering && isCateringPickup(serviceMode)
      ? {
          tabItemId: cateringLabelKey,
          label: formatMessage({ id: cateringLabelKey }),
          to: routes.menu,
        }
      : null;

  const isBaseMenu =
    pathname === routes.menu || isRecentItemsRoute(pathname) || isFavoritesRoute(pathname);

  // check if the current path is recentItems or favorites.
  // If it is and its disabled then redirect to the root menu
  if (
    (!recentItems && isRecentItemsRoute(pathname)) ||
    (!favorites && isFavoritesRoute(pathname))
  ) {
    navigate(routes.menu);
  }

  const baseMenuItems = catering
    ? [catering]
    : ([fullMenu, recentItems, favorites].filter(item => item !== null) as ITabbedItem[]);

  const activeMenuItemTab = getActiveTab(sectionMenuItems, pathname);
  const activeBaseItemTab = getActiveTab(baseMenuItems, pathname);
  const isRouteOnItemPickerAndOutsideTabbedItems =
    isItemPickerOrComboRoute(pathname) && (activeBaseItemTab === -1 || activeMenuItemTab === -1);

  return {
    activeTab: isBaseMenu ? activeBaseItemTab : activeMenuItemTab,
    isRouteOnItemPickerAndOutsideTabbedItems,
    isTabbedMenuLoading: menuDataLoading,
    tabbedHeaderItems: isBaseMenu ? baseMenuItems : sectionMenuItems,
  };
}
