import React from 'react';

import MenuSectionHeading from 'components/menu-section-heading';

import { HeaderTitle } from './header-title';

export const SectionHeader: React.FC<React.PropsWithChildren<{ isMobile: boolean }>> = ({
  children,
  isMobile,
}) =>
  isMobile ? (
    <HeaderTitle>{children}</HeaderTitle>
  ) : (
    <MenuSectionHeading variant="headerOne">{children}</MenuSectionHeading>
  );
