import React from 'react';

import { Header, VStack } from '@rbilabs/universal-components';

import {
  ILinkWidgetFragment,
  IVerticalLinkListWithHeaderWidgetFragment,
} from 'generated/sanity-graphql';

import { Links } from './links';

type IVerticalLinkListWithHeaderProps = IVerticalLinkListWithHeaderWidgetFragment;

export const VerticalLinkListWithHeader: React.FC<IVerticalLinkListWithHeaderProps> = props => {
  return (
    <VStack mb="$6">
      <Header variant="headerTwo" color="token.text-reversed">
        {props.header?.locale}
      </Header>
      <Links links={props.linkWidgets as ILinkWidgetFragment[]} />
    </VStack>
  );
};
