import React from 'react';

import { Box, HStack, Text } from '@rbilabs/universal-components';
import { useIntl } from 'react-intl';

import { VisuallyHidden } from 'components/ucl/visually-hidden';
import { DeliveryStatus } from 'state/order/types';

import ProgressBar from './delivery-progress-bar';
import ProgressCheckpoint from './delivery-progress-checkpoint';
import theme from './theme';

const StatusMessage = Text.withConfig({
  variant: 'copyTwo',
  fontSize: 'sm',
  color: theme.statusMessageColor,
});

const ProgressList = HStack.withConfig({
  justifyContent: 'space-between',
  marginTop: '$5',
  marginBottom: '$4',
  width: 'full',
});

const ProgressListItem = Box.withConfig({
  alignItems: 'center',
  _text: {
    color: Styles.color.grey.two,
    fontSize: 'xs',
    lineHeight: 'xs',
    textAlign: 'center',
  },
});

interface IDeliveryProgressProps {
  deliveryStatus: DeliveryStatus;
}

export const STATUS_PERCENTS = {
  [DeliveryStatus.ORDER_CREATED]: 33,
  [DeliveryStatus.DRIVER_ASSIGNED]: 33,
  [DeliveryStatus.DRIVER_UNASSIGNED]: 33,
  [DeliveryStatus.DRIVER_STARTING]: 33,
  [DeliveryStatus.DRIVER_AT_STORE]: 50,
  [DeliveryStatus.ORDER_PICKED_UP]: 66.7,
  [DeliveryStatus.DRIVER_AT_CUSTOMER]: 83.3,
  [DeliveryStatus.ORDER_DROPPED_OFF]: 100,
  [DeliveryStatus.ORDER_ABANDONED]: 100,
};

export const STATUS_MESSAGES = {
  [DeliveryStatus.QUOTE_SUCCESSFUL]: 'deliveryStatusQuoteSuccessful',
  [DeliveryStatus.QUOTE_ERROR]: 'deliveryStatusQuoteError',
  [DeliveryStatus.QUOTE_REQUESTED]: 'deliveryStatusQuoteRequested',
  [DeliveryStatus.QUOTE_UNAVAILABLE]: 'deliveryStatusQuoteUnavailable',
  [DeliveryStatus.ORDER_CREATED]: 'deliveryStatusOrderCreated',
  [DeliveryStatus.DRIVER_ASSIGNED]: 'deliveryStatusDriverAssigned',
  [DeliveryStatus.DRIVER_UNASSIGNED]: 'deliveryStatusDriverUnassigned',
  [DeliveryStatus.DRIVER_STARTING]: 'deliveryStatusDriverStarting',
  [DeliveryStatus.DRIVER_AT_STORE]: 'deliveryStatusDriverAtStore',
  [DeliveryStatus.ORDER_PICKED_UP]: 'deliveryStatusOrderPickedUp',
  [DeliveryStatus.DRIVER_AT_CUSTOMER]: 'deliveryStatusDriverAtCustomer',
  [DeliveryStatus.ORDER_DROPPED_OFF]: 'deliveryStatusOrderDroppedOff',
  [DeliveryStatus.ORDER_ABANDONED]: 'deliveryStatusOrderAbandoned',
  [DeliveryStatus.ORDER_CANCELLED]: 'deliveryStatusOrderCancelled',
  [DeliveryStatus.ORDER_ERROR]: 'deliveryStatusOrderError',
  [DeliveryStatus.ORDER_TIMEOUT]: 'deliveryStatusOrderTimeout',
};

const DeliveryProgress: React.FC<React.PropsWithChildren<IDeliveryProgressProps>> = ({
  deliveryStatus,
}) => {
  const { formatMessage } = useIntl();
  const percentComplete = STATUS_PERCENTS[deliveryStatus];
  const isOrderPlaced = percentComplete >= STATUS_PERCENTS[DeliveryStatus.ORDER_CREATED];
  const isBeingPrepared = percentComplete >= STATUS_PERCENTS[DeliveryStatus.DRIVER_ASSIGNED];
  const isDriverEnroute = percentComplete >= STATUS_PERCENTS[DeliveryStatus.ORDER_PICKED_UP];
  const isDelivered = percentComplete >= STATUS_PERCENTS[DeliveryStatus.ORDER_DROPPED_OFF];
  const statusMessageId = STATUS_MESSAGES[deliveryStatus];
  const ariaLabel = () => {
    const [complete, incomplete] = [
      formatMessage({ id: 'complete' }),
      formatMessage({ id: 'incomplete' }),
    ];
    return `
      ${formatMessage({ id: 'orderPlaced' })} ${isOrderPlaced ? complete : incomplete},
      ${formatMessage({ id: 'deliveryOrderBeingPrepared' })} ${
      isBeingPrepared ? complete : incomplete
    },
      ${formatMessage({ id: 'deliveryDriverEnroute' })} ${isDriverEnroute ? complete : incomplete},
      ${formatMessage({ id: 'deliveryDelivered' })} ${isDelivered ? complete : incomplete},
    `;
  };

  return (
    <Box>
      {/* @ts-expect-error TS(2769) FIXME: No overload matches this call. */}
      <StatusMessage testID="progress-status-message" tabIndex={0}>
        {!!statusMessageId && formatMessage({ id: statusMessageId })}
      </StatusMessage>
      <VisuallyHidden role="alert" accessibilityLabel={ariaLabel()} />
      <ProgressBar percentComplete={percentComplete} />
      <ProgressList>
        <ProgressListItem>
          <ProgressCheckpoint isActive={isOrderPlaced} />
          {formatMessage({ id: 'orderPlaced' })}
        </ProgressListItem>
        <ProgressListItem>
          <ProgressCheckpoint isActive={isBeingPrepared} />
          {formatMessage({ id: 'deliveryOrderBeingPrepared' })}
        </ProgressListItem>
        <ProgressListItem>
          <ProgressCheckpoint isActive={isDriverEnroute} />
          {formatMessage({ id: 'deliveryDriverEnroute' })}
        </ProgressListItem>
        <ProgressListItem>
          <ProgressCheckpoint isActive={isDelivered} />
          {formatMessage({ id: 'deliveryDelivered' })}
        </ProgressListItem>
      </ProgressList>
    </Box>
  );
};

export default DeliveryProgress;
