import React, { FC, useCallback } from 'react';

import { HStack } from '@rbilabs/universal-components';
import { GestureResponderEvent } from 'react-native';

import { ListItemButton, ListItemContent } from './product-list-item.styled';
import { IProductListItemProps } from './types';

const Container: FC<React.PropsWithChildren<Partial<IProductListItemProps>>> = ({
  children,
  isClickable,
  onProductClick,
}) => {
  const wrappedOnProductClick = useCallback(
    (e: GestureResponderEvent) => {
      // Generally not needed for React Native, but does not break anything.
      // On web, this stops the event capture phase here so the parent handles the click event.
      e.preventDefault();
      onProductClick?.();
    },
    [onProductClick]
  );

  if (isClickable) {
    return (
      <HStack>
        <ListItemButton testID="product-list-item-button" onPress={wrappedOnProductClick}>
          {children}
        </ListItemButton>
      </HStack>
    );
  }
  return <ListItemContent testID="product-list-item-content">{children}</ListItemContent>;
};

export default Container;
