import { Box, ScrollView, Text } from '@rbilabs/universal-components';

import theme from './theme';

/**
 * A wrapper for `ListItem` components.
 *
 * A list is built from its building blocks. See Storybook for example.
 */
export const List = ScrollView.withConfig(props => ({
  marginBottom: '4',
  ...props,
}));

/**
 * Displays a styled list item with borders and padding.
 */
export const ListItem = Box.withConfig<{ activeHighlight?: boolean; isFirstChild?: boolean }>(
  ({ activeHighlight, isFirstChild }) => ({
    borderBottomWidth: '1px',
    borderColor: 'token.background-default',
    ...(activeHighlight && { backgroundColor: Styles.color.background }),

    ...(isFirstChild && {
      borderTopWidth: '1px',
    }),
  })
);

/**
 * Content spacing for a List item.
 *
 * This component exists if the entire contend needs to
 * be replaced for customization.
 */
export const ListItemContent = Box.withConfig({
  paddingY: '16px',
  paddingX: '96px',
});

/**
 * Styled title for ListItem content
 */
export const ListItemTitle = Text.withConfig({
  variant: 'headerFour',
  color: theme.listItemTitleColor,
  width: 'full',
});

/**
 * Styled subtext for a ListItem's content
 */
export const ListItemSubtext = Text.withConfig({
  variant: 'copyTwo',
  color: theme.listItemSubtextColor,
});
