import React, { FC } from 'react';

import { Icon } from '@rbilabs/universal-components';
import { useIntl } from 'react-intl';

import { useOffersUIContext } from 'components/offers/ui-refresh/offers-ui-context';
import { useFeatureEarningCalculationQuery } from 'generated/sanity-graphql';
import { useNavigation } from 'hooks/navigation/use-navigation';
import { useRoute } from 'hooks/navigation/use-route';
import useDialogModal from 'hooks/use-dialog-modal';
import useRoutes from 'hooks/use-routes';
import { useSwitchCartMethodDialog } from 'hooks/use-switch-cart-method-dialog';
import { useToast } from 'hooks/use-toast';
import { SHOULD_DISPLAY_ICON } from 'pages/loyalty/loyalty-incentives-components/incentive-details/constants';
import { useAuthContext } from 'state/auth';
import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';
import { useLoyaltyUser } from 'state/loyalty/hooks/use-loyalty-user';
import {
  ICartEntryType,
  useInRestaurantRedemptionContext,
} from 'state/loyalty/in-restaurant-redemption';
import { RedemptionMethod } from 'state/offers';
import { useServiceModeContext } from 'state/service-mode';
import { theme } from 'styles/configure-theme';
import { hiddenAccessibilityPlatformProps } from 'utils/accessibility';
import { DIFFERENCE_TIME_UNITS, getDifferenceToNow } from 'utils/dateTime';
import { routes } from 'utils/routing';

import { ErrorMessage } from './details-panel-components';
import {
  StyledRedemptionBox,
  StyledRedemptionButton,
  StyledSignUpToRedeemLink,
} from './redemption-button.styled';
import { RestaurantRedemptionButton } from './restaurant-redemption-button';
import { IRedemptionButtonsProps } from './types';

export const RedemptionButtons: FC<React.PropsWithChildren<IRedemptionButtonsProps>> = ({
  onRedeemOffer,
  disableMobileOfferRedemptions,
  disableInRestaurantOfferRedemptions,
  redemptionErrorMessage,
  showSignUpToRedeem,
  showMobileRedemptionButton,
  showRestaurantRedemptionButton,
  restaurantRedemptionButtonUiType,
  noPossibleRedemption,
}) => {
  const { formatMessage } = useIntl();
  const { navigate } = useNavigation();
  const { pathname } = useRoute();
  const { getLocalizedRouteForPath } = useRoutes();
  const { setOriginLocation } = useAuthContext();
  const enableMyCodeNewUI = useFlag(LaunchDarklyFlag.ENABLE_MY_CODE_NEW_UI);
  const enableCooldownToast = useFlag(LaunchDarklyFlag.ENABLE_COOLDOWN_TOAST_INFO);
  const toast = useToast();
  const { serviceMode } = useServiceModeContext();
  const {
    SwitchCartMethodDialog,
    shouldShowSwitchToStoreDialog,
    shouldShowSwitchToMobileDialog,
    openSwitchCartMethod,
  } = useSwitchCartMethodDialog();
  const { existEntryTypeInRestaurantRedemptionCart } = useInRestaurantRedemptionContext();

  const { loading: offersLoading } = useOffersUIContext();

  const { data: earningCalculationData } = useFeatureEarningCalculationQuery({
    variables: { id: 'earningCalculation' },
  });
  const { loyaltyUser } = useLoyaltyUser();
  const diffInMin = getDifferenceToNow(
    DIFFERENCE_TIME_UNITS.MINUTES,
    loyaltyUser?.offerRedemptionAvailability?.availableAfter || ''
  );

  const showOfferCooldownToast = () => {
    //If the diffInMin is greater than 0 it means that the user already have an offer in course so we do nothing.
    if (!enableCooldownToast || diffInMin > 0 || !serviceMode) {
      return;
    }

    toast.show({
      variant: 'neutral',
      text: formatMessage(
        { id: 'offersCooldownInfo' },
        {
          period: earningCalculationData?.EarningCalculation?.offerRedemptionCooldownPeriod,
        }
      ),
    });
  };

  const setMobileRedemption = () => onRedeemOffer({ redemptionMethod: RedemptionMethod.mobile });
  const handleOnPressMobileRedemption = () => {
    if (shouldShowSwitchToMobileDialog) {
      openSwitchCartMethod({ onConfirm: setMobileRedemption });
    } else {
      setMobileRedemption();
    }
    showOfferCooldownToast();
  };

  const setRestaurantRedemption = () =>
    onRedeemOffer({ redemptionMethod: RedemptionMethod.restaurant });

  const [ConfirmAddOfferDialog, openConfirmAddOfferDialog] = useDialogModal({
    onConfirm: setRestaurantRedemption,
    showCancel: true,
    modalAppearanceEventMessage: 'Confirmation: Select new offer for in-restaurant redemption cart',
  });

  const handleOnPressRestaurantRedemption = () => {
    if (shouldShowSwitchToStoreDialog) {
      openSwitchCartMethod({ onConfirm: setRestaurantRedemption });
    } else if (existEntryTypeInRestaurantRedemptionCart(ICartEntryType.LEGACY_OFFER)) {
      openConfirmAddOfferDialog();
    } else {
      setRestaurantRedemption();
    }
    showOfferCooldownToast();
  };

  const routeToSignUp = (event: any) => {
    event.preventDefault();
    setOriginLocation(pathname);
    navigate(getLocalizedRouteForPath(routes.signUp));
  };

  if (noPossibleRedemption) {
    return (
      <ErrorMessage variant="copyOne" testID="redemption-error">
        {redemptionErrorMessage}
      </ErrorMessage>
    );
  }

  if (showSignUpToRedeem) {
    return (
      <StyledRedemptionBox>
        <StyledSignUpToRedeemLink
          testID="sign-up-to-redeem"
          to={routes.signUp}
          onPress={routeToSignUp}
        >
          {formatMessage({ id: 'signUpToRedeem' })}
        </StyledSignUpToRedeemLink>
      </StyledRedemptionBox>
    );
  }

  return (
    <>
      {redemptionErrorMessage && (
        <ErrorMessage variant="copyOne">{redemptionErrorMessage}</ErrorMessage>
      )}

      {showMobileRedemptionButton && (
        <StyledRedemptionBox>
          <StyledRedemptionButton
            testID="mobile-redemption-button"
            onPress={handleOnPressMobileRedemption}
            disabled={disableMobileOfferRedemptions || offersLoading}
            accessibilityLabel={formatMessage({ id: 'addMobileOrder' })}
            isLoading={offersLoading}
            leftIcon={
              SHOULD_DISPLAY_ICON ? (
                <Icon
                  variant="mobile"
                  color={theme.token('icon-reversed')}
                  width="20px"
                  {...hiddenAccessibilityPlatformProps}
                />
              ) : undefined
            }
          >
            {formatMessage({ id: 'addMobileOrder' })}
          </StyledRedemptionButton>
        </StyledRedemptionBox>
      )}

      {showRestaurantRedemptionButton && (
        <RestaurantRedemptionButton
          onPressRestaurantRedemption={handleOnPressRestaurantRedemption}
          restaurantRedemptionButtonTitle={formatMessage({
            id: enableMyCodeNewUI ? 'redeemAtRestaurant' : 'redeemInRestaurant',
          })}
          restaurantRedemptionButtonUiType={restaurantRedemptionButtonUiType}
          disabled={disableInRestaurantOfferRedemptions}
        />
      )}

      <SwitchCartMethodDialog />
      <ConfirmAddOfferDialog
        heading={formatMessage({ id: 'oneOfferPerOrder' })}
        body={formatMessage({ id: 'replaceOffer' })}
        confirmLabel={formatMessage({ id: 'yes' })}
        cancelLabel={formatMessage({ id: 'no' })}
      />
    </>
  );
};
