import React from 'react';

import LottieView from '../../lottie-animations/lottie-view';

import {
  PULLING_ANIMATION_HEIGHT,
  PULLING_ANIMATION_WIDTH,
  REFRESHING_ANIMATION_HEIGHT,
  REFRESHING_ANIMATION_WIDTH,
  RELEASING_ANIMATION_HEIGHT,
  RELEASING_ANIMATION_WIDTH,
} from './constants';
import { Actions, IPullToRefreshContent } from './types';

export default function AnimationLoader({ animationData, action }: IPullToRefreshContent) {
  const getAnimationHeight = (action: Actions): string => {
    const animationHeight: Record<Actions, string> = {
      releasing: RELEASING_ANIMATION_HEIGHT,
      pullingDown: PULLING_ANIMATION_HEIGHT,
      refreshing: REFRESHING_ANIMATION_HEIGHT,
    };
    return animationHeight[action];
  };

  const getAnimationWidth = (action: Actions): string => {
    const animationHeight: Record<Actions, string> = {
      releasing: RELEASING_ANIMATION_WIDTH,
      pullingDown: PULLING_ANIMATION_WIDTH,
      refreshing: REFRESHING_ANIMATION_WIDTH,
    };
    return animationHeight[action];
  };

  const getAnimationMarginLeft = (action: Actions): string => {
    const animationHeight: Record<Actions, string> = {
      releasing: '4%',
      pullingDown: '4%',
      refreshing: '1%',
    };
    return animationHeight[action];
  };

  return (
    <LottieView
      source={animationData}
      autoPlay
      loop
      style={{
        height: getAnimationHeight(action),
        width: getAnimationWidth(action),
        marginLeft: getAnimationMarginLeft(action),
        paddingBottom: 10,
      }}
    />
  );
}
