import { HStack, Header, Text, VStack } from '@rbilabs/universal-components';

export const SelectionOptionsHeader = HStack.withConfig({
  justifyContent: 'space-between',
  alignItems: 'baseline',
  width: 'full',
  padding: '$2',
  borderColor: Styles.color.grey.four,
  borderBottomWidth: '1px',
  _web: {
    cursor: 'pointer',
  },
});

export const RightContainer = HStack.withConfig({
  justifyContent: 'flex-end',
  alignItems: 'center',
  width: '1/2',
  height: 'full',
});

export const SelectionOptionsTitle = Header.withConfig({
  variant: 'headerThree',
  width: '1/2',
});

export const SelectedOption = Text.withConfig({
  fontSize: 'md',
  lineHeight: 'lg',
  paddingRight: '$2',
  ellipsizeMode: 'tail',
  numberOfLines: 1,
  marginLeft: '$6',
});

export const SelectionOptionsDescription = Text.withConfig({
  variant: 'copyTwo',
  color: Styles.color.black,
  paddingLeft: '$4',
});

export const SelectionOptions = VStack.withConfig({
  marginTop: '$4',
  justifyContent: 'space-between',
  width: 'full',
});
