import * as React from 'react';
import { FC } from 'react';

import { Box } from '@rbilabs/universal-components';

import ActionButton, { ActionButtonSizes } from 'components/action-button';

import { Body, Title, Wrapper } from './select-restaurant-cta.styled';
import { ISelectRestaurantCtaViewProps } from './types';

export const SelectRestaurantCtaView: FC<React.PropsWithChildren<
  ISelectRestaurantCtaViewProps
>> = ({ title, body, ctaButtonText, onPress }) => {
  return (
    <Wrapper testID="select-restaurant-cta">
      <Title>{title}</Title>
      <Body>{body}</Body>
      <Box minHeight="auto" margin={{ md: 'auto auto 14px', base: 'auto' }}>
        <ActionButton size={ActionButtonSizes.SMALL} onPress={onPress}>
          {ctaButtonText}
        </ActionButton>
      </Box>
    </Wrapper>
  );
};
