import { SupportedLanguages } from '@rbi-ctg/frontend';
import {
  PROD_DEFAULT_LANGUAGE,
  PROD_SUPPORTED_LANGUAGES as SUPPORTED_LANGUAGES,
} from 'state/intl/constants';
import { loadRegion } from 'utils/intl/region';

export function findSupportedLanguage(requestedLanguage: string): SupportedLanguages {
  return SUPPORTED_LANGUAGES[requestedLanguage] || PROD_DEFAULT_LANGUAGE[loadRegion()];
}
