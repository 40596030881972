import React, { FC } from 'react';

import { Icon } from '@rbilabs/universal-components';
import { useIntl } from 'react-intl';

import { Label, TextWithArrowButtonRoot } from './text-with-arrow-button.styled';
import { TextWithArrowButtonProps } from './types';

const TextWithArrowButton: FC<React.PropsWithChildren<TextWithArrowButtonProps>> = ({
  onPress,
  messageId,
  withBorder = false,
}) => {
  const { formatMessage } = useIntl();

  return (
    <TextWithArrowButtonRoot
      onPress={onPress}
      accessibilityLabel={formatMessage({ id: messageId })}
      accessibilityRole="button"
      withBorder={withBorder}
    >
      <Label>{formatMessage({ id: messageId })}</Label>
      <Icon variant="next" height="$3.5" width="$4" color="dark.200" />
    </TextWithArrowButtonRoot>
  );
};

export default TextWithArrowButton;
