import { useFeatureMenuAdaptableCardsQuery } from 'generated/sanity-graphql';

export const useAdaptableMenuCardsQuery = (featureMenuId: string) => {
  const response = useFeatureMenuAdaptableCardsQuery({
    variables: { featureMenuId },
    skip: !featureMenuId,
  });

  return response;
};
