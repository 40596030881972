import { IntlShape } from 'react-intl';

import { LoyaltyQuestIncentiveType, LoyaltyQuestStatus } from 'generated/graphql-gateway';

import type { ICmsQuestCardUI } from '../cms-hooks/types';

import { formatQuestDates } from './date-formatter';

export const getButtonText = ({
  questStatus,
  incentiveRedeemed,
  cmsQuestCardUI,
}: {
  questStatus: LoyaltyQuestStatus;
  incentiveRedeemed: boolean;
  cmsQuestCardUI: ICmsQuestCardUI;
}): string => {
  let buttonText: string = '';

  switch (questStatus) {
    case LoyaltyQuestStatus.NOT_ENROLLED: {
      buttonText = cmsQuestCardUI.notEnrolledButton?.locale ?? '';
      break;
    }
    case LoyaltyQuestStatus.ACTIVATED: {
      buttonText = cmsQuestCardUI.enrolledButton?.locale ?? '';
      break;
    }
    case LoyaltyQuestStatus.COMPLETED: {
      if (!incentiveRedeemed) {
        buttonText = cmsQuestCardUI.completedButton?.locale ?? '';
      }
      break;
    }
    default: {
      break;
    }
  }

  return buttonText;
};

export const getRangeDateDescription = ({
  startDate,
  endDate,
  formatMessage,
}: {
  startDate: string;
  endDate: string;
  formatMessage: IntlShape['formatMessage'];
}): string => {
  const [formattedStartDate, formattedEndDate] = formatQuestDates(startDate, endDate);
  const description = `${formatMessage({ id: 'from' })} ${formattedStartDate} ${formatMessage({
    id: 'to',
  })} ${formattedEndDate}`;

  return description;
};

export const getRedeemedDescription = ({
  incentiveType,
  cmsQuestCardUI,
}: {
  incentiveType: LoyaltyQuestIncentiveType;
  cmsQuestCardUI: ICmsQuestCardUI;
}): string => {
  return (
    (incentiveType === LoyaltyQuestIncentiveType.BONUS_POINTS
      ? cmsQuestCardUI.completedAndRedeemedBonusPointsDescription?.locale
      : cmsQuestCardUI.completedAndRedeemedDescription?.locale) || ''
  );
};
