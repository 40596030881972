import React, { FC } from 'react';

import { useFlipper } from '@react-navigation/devtools';
import { NavigationContainer as NativeNavigationContainer } from '@react-navigation/native';
import { Platform } from 'react-native';

import { linking } from '../linking';
import { navigationTheme } from '../theme';

import { handleUnhandledAction } from './handle-unhandled-action';
import { navigationRef } from './navigation-container-ref';
import { onReady } from './on-ready';

/** Flipper is only available on iOS and Android. Stub out hook for web. */
const useFlipperHook = Platform.OS !== 'web' ? useFlipper : () => {};

/**
 * This container works as a root for all the native navigation.
 * Anytime a Navigator is rendered, it's registered in this container and all its routes
 * are made accessible for navigation to all children of this component.
 * Necessarily is on top of all global providers, to allow them to navigate to any
 * registered route.
 *
 * TODO: because the LD provider is a child of this component we couldn't find a way to
 * use the FF to hide it conditionally in case the flag is off.
 *
 */
const NavigationContainer: FC<React.PropsWithChildren<unknown>> = ({ children }) => {
  useFlipperHook(navigationRef);

  return (
    <NativeNavigationContainer
      linking={linking}
      theme={navigationTheme}
      ref={navigationRef}
      onReady={onReady}
      onUnhandledAction={handleUnhandledAction}
      documentTitle={{ enabled: false }}
    >
      {children}
    </NativeNavigationContainer>
  );
};

export default NavigationContainer;
