import {
  gql,
  gql as gqlFunc /* do not change this `as` as it makes it so the babel-plugin-graphql-tag transform works properly */,
} from '@apollo/client';

import { ISanityTextNode } from '@rbi-ctg/menu';

import ImageFragment from './fragments/image';
import NutritionExplorerWidgetFragment from './fragments/nutrition-explorer-widget';
import VideoFileFragment from './fragments/video-file';

export interface IStaticPageRoute {
  _id: string;
  path: {
    current: string | null;
  };
  title: string | null;
  localePath: {
    [language: string]: {
      current: string | null;
    } | null;
  };
  localeTitle: ISanityTextNode | null;
  displayIndex: number | null;
  parent: {
    _id: string;
    _key: string;
  } | null;
  redirectUrl: ISanityTextNode | null;
}

export interface IGetStaticPageRoutesQuery {
  allStaticPage: IStaticPageRoute[];
}

// need to do this at runtime not at build time babel transform
export const GetStaticPageRoutesQuery = gqlFunc(`
  query GetStaticPageRoutes($staticPageWhere: StaticPageFilter) {
    allStaticPage(where: $staticPageWhere) {
      _id
      path {
        current
      }
      title
      localePath {
        en { current }
        fr { current }
      }
      localeTitle {
        en
        fr
      }
      displayIndex
      parent {
        _id
        _key
      }
      redirectUrl {
        en
        fr
      }
    }
  }
`);

// @todo
export interface IStaticPage {
  _id: string;
  [key: string]: any;
}

export interface IGetStaticPageQuery {
  StaticPage: IStaticPage;
}

export interface IGetStaticPageVariables {
  id: string;
}

export const GetStaticPageQuery = gql`
  query GetStaticPage($id: ID!) {
    StaticPage(id: $id) {
      _id
      path {
        _key
        _type
        current
      }
      pageVariablesRaw
      widgets {
        ... on YoutubeWidget {
          _key
          _type
          videoId
          videoAutoplay
        }
        ... on HeaderWidget {
          _key
          _type
          headingContent {
            en
            fr
          }
          taglineContent {
            en
            fr
          }
          headerImage {
            en {
              ...ImageFragment
            }
            fr {
              ...ImageFragment
            }
          }
        }
        ... on VideoWidget {
          _key
          _type
          video {
            en {
              ...VideoFileFragment
            }
            fr {
              ...VideoFileFragment
            }
          }
          caption {
            en
            fr
          }
          attributionLink
        }
        ... on AccordionWidget {
          _key
          _type
          accordionTitle {
            en
            fr
          }
          accordionContent {
            collapsedContent {
              en
              fr
            }
            expandedContent {
              en
              fr
            }
          }
        }
        ... on LocaleBlockTextWidget {
          _key
          _type
          blockTextInversion
          localeBlockTextContent {
            enRaw
            frRaw
          }
        }
        ... on PreviewWidget {
          _key
          _type
          previewContent {
            previewImage {
              en {
                ...ImageFragment
              }
              fr {
                ...ImageFragment
              }
            }
            titleText {
              en
              fr
            }
            bodyText {
              en
              fr
            }
            linkText {
              en
              fr
            }
            linkURL
            localizedLinkURL {
              en
              fr
            }
          }
        }
        ... on ImageWidget {
          _key
          _type
          caption {
            en
            fr
          }
          attributionLink
          image {
            en {
              ...ImageFragment
            }
            fr {
              ...ImageFragment
            }
          }
        }
        ... on CallToActionWidget {
          _key
          _type
          callToActionContent {
            heading {
              en
              fr
            }
            subheading {
              en
              fr
            }
            body {
              en
              fr
            }
            buttonText {
              en
              fr
            }
            buttonLink
            link {
              en
              fr
            }
            image {
              en {
                ...ImageFragment
              }
              fr {
                ...ImageFragment
              }
            }
          }
        }
        ... on AnchorWidget {
          _key
          _type
          anchorName {
            en
            fr
          }
        }
        ... on RewardsCarouselWidget {
          _key
          _type
          rewardsCarouselTitle {
            en
            fr
          }
          rewardsCarouselDescription {
            en
            fr
          }
          rewardCarouselCategories {
            _id
            label {
              en
              fr
            }
            rewards {
              _id
              name {
                en
                fr
              }
              image {
                en {
                  ...ImageFragment
                }
                fr {
                  ...ImageFragment
                }
              }
            }
          }
        }
        ... on AnchorLinksWidget {
          _key
          _type
          title {
            en
            fr
          }
        }
        ... on WebViewWidget {
          _key
          _type
          name
          url {
            en
            fr
          }
        }
        ... on MultiWidget {
          _key
          _type
          widget
        }
        ... on LanguageSelectorWidget {
          _key
          _type
        }
      }
      title

      pageCSS {
        code
      }
      pageHtml {
        code
      }
    }
  }
  ${ImageFragment}
  ${VideoFileFragment}
`;

export const GetNutritionPageQuery = gql`
  query GetStaticPage($id: ID!) {
    StaticPage(id: $id) {
      _id
      title
      path {
        _key
        _type
        current
      }
      widgets {
        ...NutritionExplorerWidgetFragment
      }
    }
  }
  ${NutritionExplorerWidgetFragment}
`;
