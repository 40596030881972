import React, { useCallback, useMemo } from 'react';

import { Box, Button, HStack, Header, Icon } from '@rbilabs/universal-components';
import { useIntl } from 'react-intl';
import { FlatList } from 'react-native';

import TrophyIcon from 'components/icons/trophy';
import { useNavigation } from 'hooks/navigation/use-navigation';
import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';
import { useLoyaltyUser } from 'state/loyalty/hooks/use-loyalty-user';
import { routes } from 'utils/routing';

import { useCmsSortedQuestCardListQuery } from './cms-hooks/use-cms-sorted-quest-card-list';
import { getQuestText } from './custom-text';
import { useLoyaltyQuestQuery } from './loyalty-quest/use-loyalty-quest-query';
import { QuestCardError, QuestCardSkeleton } from './quest-card';
import { QuestCardWrapper } from './quest-card-wrapper';
import type { CardListItem } from './types';
import { createCardListData } from './utils';

const CardListHeader = () => {
  const { formatMessage } = useIntl();
  const { navigate } = useNavigation();
  const onPress = () => navigate(routes.questLibrary);

  return (
    <HStack space="$2.5" alignItems="center" marginBottom="$6" paddingY="$2">
      <TrophyIcon />
      <Header margin={0}>{getQuestText(formatMessage)}</Header>
      <Button
        variant="ghost"
        size="md"
        marginLeft="auto"
        onPress={onPress}
        rightIcon={<Icon variant="forward" size="4" />}
      >
        {formatMessage({ id: 'viewAll' })}
      </Button>
    </HStack>
  );
};

const QuestContainer = ({ loyaltyId }: { loyaltyId: string }) => {
  const {
    loadingQuests,
    quests: loyaltyQuests,
    error: loyaltyQuestError,
    refetchQuests,
  } = useLoyaltyQuestQuery({
    loyaltyId,
  });
  const {
    loading: loadingQuestCardList,
    refetch: refetchCmsQuestCardList,
    cmsQuestCardUI,
    cmsQuests,
    error: cmsQuestQueryError,
  } = useCmsSortedQuestCardListQuery();

  const cardListData = useMemo(() => {
    if (!loyaltyQuests || !cmsQuests) {
      return null;
    }

    return createCardListData(loyaltyQuests, cmsQuests);
  }, [cmsQuests, loyaltyQuests]);

  // display nothing if there is no cms quest matches with a loyalty quest
  const noQuests = cardListData?.length === 0;
  const error = loyaltyQuestError || cmsQuestQueryError;

  const isLoading =
    loadingQuests || loadingQuestCardList || !cmsQuests || !cmsQuestCardUI || !loyaltyQuests;

  const refetchQuestSection = useCallback(() => {
    refetchQuests();
    refetchCmsQuestCardList();
  }, [refetchCmsQuestCardList, refetchQuests]);

  if (noQuests) {
    return null;
  }

  if (isLoading) {
    return <QuestCardSkeleton />;
  }

  if (error) {
    return <QuestCardError refetch={refetchQuestSection} />;
  }

  return (
    <FlatList<CardListItem>
      ListHeaderComponent={CardListHeader}
      data={cardListData}
      keyExtractor={({ loyaltyQuest }) => loyaltyQuest.id}
      renderItem={({ item }) => {
        const { loyaltyQuest, cmsQuestCard } = item;

        return (
          <QuestCardWrapper
            quest={loyaltyQuest}
            cmsQuestCard={cmsQuestCard}
            cmsQuestCardUI={cmsQuestCardUI}
          />
        );
      }}
    />
  );
};

export const QuestSection = () => {
  const { loyaltyUser } = useLoyaltyUser();
  const isQuestTilesEnable = useFlag(LaunchDarklyFlag.ENABLE_QUEST_TILES);
  const loyaltyId = loyaltyUser?.id;

  if (!isQuestTilesEnable || !loyaltyId) {
    return null;
  }

  return (
    <Box margin="$4">
      <QuestContainer loyaltyId={loyaltyId} />
    </Box>
  );
};
