import React from 'react';

import { ApolloProvider } from '@apollo/client';
import { Platform } from 'react-native';

import { IBaseProps } from '@rbi-ctg/frontend';

import { client } from './client';
import { useHeadersSync } from './hooks/use-headers-sync';

export const GraphQLProvider = ({ children }: IBaseProps) => {
  useHeadersSync();

  return <ApolloProvider client={client}>{children}</ApolloProvider>;
};

const isNativeApp = ['ios', 'android'].includes(Platform.OS);
const isNotTest = process.env.NODE_ENV !== 'test';
if (__DEV__ && isNativeApp && isNotTest) {
  import('react-native-apollo-devtools-client').then(({ apolloDevToolsInit }) => {
    apolloDevToolsInit(client);
  });
}

export { useLazySanityQuery, useSanityQuery } from './hooks/use-sanity-query';
