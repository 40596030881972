import { Reducer, useCallback, useReducer } from 'react';

import {
  IOffersFragment,
  useGetLoyaltyOffersLazyQuery,
  useLoyaltyRedeemPromoCodeMutation,
} from 'generated/graphql-gateway';
import { useLoyaltyOfferConfigsLazyQuery } from 'generated/sanity-graphql';
import {
  PromoCodeError,
  PromoCodeErrorReason,
} from 'pages/loyalty/loyalty-offers/loyalty-promo-code/types';

import { LoyaltyOffer } from '../types';

// internal implementation types
type OfferData = { configOffer: LoyaltyOffer; engineOffer: IOffersFragment };
type State = {
  offerData: OfferData | null;
  error: PromoCodeError | null;
  loading: boolean;
};

enum ActionType {
  SUCCESS,
  ERROR,
  FETCHING,
  RESET,
}
type FetchingAction = { type: ActionType.FETCHING };
type ErrorAction = { type: ActionType.ERROR; payload: PromoCodeError | null };
type SuccessAction = {
  type: ActionType.SUCCESS;
  payload: OfferData | null;
};
type ReducerAction = FetchingAction | ErrorAction | SuccessAction;

const initialState: State = {
  offerData: null,
  error: null,
  loading: false,
};

const reducer: Reducer<State, ReducerAction> = (state: State, action: ReducerAction) => {
  switch (action.type) {
    case ActionType.FETCHING: {
      return { error: null, offerData: null, loading: true };
    }
    case ActionType.SUCCESS: {
      return { error: null, offerData: action.payload, loading: false };
    }
    case ActionType.ERROR: {
      return { error: action.payload, offerData: null, loading: false };
    }
    default:
      return initialState;
  }
};

export const useRedeemPromoCode = (): [
  (loyaltyId: string, promoCode: string) => Promise<OfferData>,
  State
] => {
  const [state, dispatch] = useReducer<Reducer<State, ReducerAction>>(reducer, initialState);
  const [queryConfigOffer] = useLoyaltyOfferConfigsLazyQuery();
  const [queryLoyaltyOffer] = useGetLoyaltyOffersLazyQuery();
  const [redeemMutation] = useLoyaltyRedeemPromoCodeMutation();

  const redeemPromoCode = useCallback(
    async (loyaltyId: string, promoCode: string): Promise<OfferData> => {
      dispatch({ type: ActionType.FETCHING });

      try {
        const { data } = await redeemMutation({
          variables: {
            input: {
              loyaltyId,
              code: promoCode,
              shouldRedeem: true,
            },
          },
        });

        const configId = data?.loyaltyValidatePromoCode?.configId;
        const personalizedOfferId = data?.loyaltyValidatePromoCode?.personalizedOfferId;

        if (!configId || !personalizedOfferId) {
          const reason = data?.loyaltyValidatePromoCode?.reason;
          const error =
            (reason && PromoCodeErrorReason[reason]) || PromoCodeErrorReason.invalidCode;

          throw new PromoCodeError(error);
        }

        const [{ data: loyaltyOfferData }, { data: configOfferData }] = await Promise.all([
          queryLoyaltyOffer({
            variables: {
              loyaltyId,
              where: {
                ids: [personalizedOfferId],
              },
            },
          }),
          queryConfigOffer({
            variables: { ids: [configId] },
          }),
        ]);

        const configOffer = configOfferData?.allConfigOffers?.[0];
        const engineOffer = loyaltyOfferData?.loyaltyOffersV2?.[0];

        if (!configOffer || !engineOffer) {
          throw new PromoCodeError(PromoCodeErrorReason.invalidCode);
        }

        dispatch({
          type: ActionType.SUCCESS,
          payload: { engineOffer, configOffer },
        });

        return { engineOffer, configOffer };
      } catch (err) {
        const error =
          err instanceof PromoCodeError
            ? err
            : new PromoCodeError(PromoCodeErrorReason.invalidCode);

        dispatch({ type: ActionType.ERROR, payload: error });

        throw error;
      }
    },
    [redeemMutation, queryLoyaltyOffer, queryConfigOffer]
  );

  return [redeemPromoCode, state];
};
