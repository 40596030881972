import * as React from 'react';
import { FC, useCallback, useEffect, useState } from 'react';

import { useIntl } from 'react-intl';

import { ISanityBlockContent, ISanityImage } from '@rbi-ctg/menu';
import Picture from 'components/picture';
import SanityBlockRenderer from 'components/sanity-block-renderer';
import { Hero } from 'components/ucl/hero';
import { useNavigation } from 'hooks/navigation/use-navigation';
import useDialogModal from 'hooks/use-dialog-modal';
import { useReversedUIContext } from 'state/reversed-ui';
import { EventName, emitEvent } from 'utils/event-hub';

import linkSerializers from '../terms/terms-link';
import useTerms from '../terms/terms.hook';

import { HeroSection } from './styles';
import { IHeroProps } from './types';

export type ModalPayloadType = { heading: string; content: ISanityBlockContent[] };

/**
 *
 * HeroContainer is the Sanity Data component that wraps the Component-library Hero component
 *
 */
const HeroContainer: FC<React.PropsWithChildren<IHeroProps>> = ({ hero }) => {
  const { formatMessage } = useIntl();
  const { setReversedUI } = useReversedUIContext();
  const { linkTo } = useNavigation();
  const [pendingData, setPendingData] = useState<ModalPayloadType>({ content: [], heading: '' });
  const [TermsModal, openTermsModal] = useDialogModal({
    modalAppearanceEventMessage: 'Terms and Conditions',
  });

  const onShowTermsModal = useCallback(
    (data: any) => {
      openTermsModal(data);
      setPendingData(data);
    },
    [openTermsModal]
  );

  // Hero content
  const header = hero?.header?.locale ?? '';
  const subheader = hero?.subheader?.locale ?? '';
  const sanityImage = hero.image?.locale as ISanityImage;
  const imageAlt = (hero.altImageText?.locale ?? '') as string;
  // The height value comes from
  // src/components/ucl/hero/hero.styled.ts
  const image = sanityImage && (
    <Picture
      priority="high"
      height="240px"
      width="320px"
      image={sanityImage}
      alt={imageAlt}
      objectFitContain
      onImageLoaded={() => {
        emitEvent(EventName.MAIN_HERO_IMAGE_LOADED);
      }}
    />
  );

  // Hero can set colors/darker background colors if reversed is set to true
  const reversed = hero?.reversed ?? false;
  useEffect(() => {
    setReversedUI(reversed);
  }, [setReversedUI, reversed]);

  // Hero CTAs
  const primaryCtaText = (hero?.primaryCta?.actionText?.locale ?? '') as string;
  const primaryCtaHref = (hero?.primaryCta?.actionUrl?.locale ?? '') as string;
  const complementaryCtaText = (hero?.complementaryCta?.actionText?.locale ?? '') as string;
  const complementaryCtaHref = (hero?.complementaryCta?.actionUrl?.locale ?? '') as string;
  const onPressPrimaryCta = () => {
    linkTo(primaryCtaHref);
  };
  const onPressComplementaryCta = () => {
    linkTo(complementaryCtaHref);
  };

  // Terms
  const hasTerms = hero.hasTerms;
  // Fallback to "terms and conditions" localized
  const termsButtonText = (hero.termsButton?.actionText?.locale ??
    formatMessage({ id: 'termsConditions' })) as string;
  const termsButtonUrl = (hero.termsButton?.actionUrl?.locale ?? '') as string;
  const termsLinkHref = hasTerms ? termsButtonUrl : undefined;
  const termsLinkText = hasTerms ? termsButtonText : undefined;
  const termsText = hero.termsText?.localeRaw;

  const onPressTermsLink = useTerms({
    onShowTermsModal,
    termsButtonText,
    termsButtonUrl,
    termsText,
  });

  return (
    <HeroSection $reversed={reversed}>
      <Hero
        header={header}
        subheader={subheader}
        image={image}
        primaryCtaText={primaryCtaText}
        primaryCtaHref={primaryCtaHref}
        onPressPrimaryCta={onPressPrimaryCta}
        complementaryCtaText={complementaryCtaText}
        complementaryCtaHref={complementaryCtaHref}
        onPressComplementaryCta={onPressComplementaryCta}
        reversed={reversed}
        // Terms
        onPressTermsLink={() => onPressTermsLink()}
        termsLinkHref={termsLinkHref}
        termsLinkText={termsLinkText}
      />

      <TermsModal
        heading={pendingData.heading}
        // @ts-expect-error TS(2322) FIXME: Type 'Element' is not assignable to type '((boolea... Remove this comment to see the full error message
        body={<SanityBlockRenderer content={pendingData?.content} serializers={linkSerializers} />}
      />
    </HeroSection>
  );
};

export default HeroContainer;
