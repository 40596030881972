import { useCreateLoyaltyUserMutation } from 'generated/rbi-graphql';
import logger from 'utils/logger';

import type { IUseCreateLoyaltyUserParams } from './types';

/**
 * Create the loyalty user and log the proper events depending on the request result
 */
export const useCreateLoyaltyUser = ({
  onCompleted,
  onError,
  cognitoId,
}: IUseCreateLoyaltyUserParams) => {
  return useCreateLoyaltyUserMutation({
    variables: {
      cognitoId,
    },
    onCompleted: ({ createLoyaltyUser: { loyaltyId } }) => {
      if (!loyaltyId) {
        logger.error('Success response without loyalty id');
        // if the query was completed but no loyalty id returned back from the BE
        // means that the loyalty user was not created correctly
        return;
      }

      onCompleted?.();
    },
    onError: error => {
      logger.error(`Error enrolling user: ${error.message}`);

      onError?.(error);
    },
  });
};
