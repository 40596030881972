import React from 'react';

import { useIntl } from 'react-intl';

import { routes } from 'utils/routing';

import { LoggedOutEmptyStateImage } from './images';
import {
  EmptyStateWrapper,
  HeaderText,
  ImageWrapper,
  StyledActionButtonLink,
  SubText,
} from './styled';

const LoggedOutEmptyState = () => {
  const { formatMessage } = useIntl();

  return (
    <EmptyStateWrapper>
      <ImageWrapper>
        <LoggedOutEmptyStateImage />
      </ImageWrapper>
      <HeaderText>{formatMessage({ id: 'unauthenticatedFavoritesHeader' })}</HeaderText>
      <SubText>{formatMessage({ id: 'unauthenticatedFavoritesSubtext' })}</SubText>
      <StyledActionButtonLink to={routes.signIn}>
        {formatMessage({ id: 'logIn' })}
      </StyledActionButtonLink>
    </EmptyStateWrapper>
  );
};

export default LoggedOutEmptyState;
