import React from 'react';

import { Box, Header } from '@rbilabs/universal-components';

import { theme } from 'styles/configure-theme';

const Wrapper = Box.withConfig({
  marginTop: '$6',
  marginBottom: '$4',
  borderBottomWidth: 1,
  borderColor: theme.token('border-color-default'),
});

export const TableHeader = ({ title, testID }: { title: string; testID?: string }) => {
  return (
    <Wrapper testID={testID}>
      <Header variant="headerThree" marginBottom={1}>
        {title}
      </Header>
    </Wrapper>
  );
};
