import React, { ReactNode } from 'react';

import { Box, BoxProps, VStack } from '@rbilabs/universal-components';

import { IBaseProps } from '@rbi-ctg/frontend';

import theme from '../theme';

import { Scroller } from './scroller';

interface IModalContentProps extends IBaseProps, BoxProps {
  bgColor?: string;
  disableFooter?: boolean;
  expand?: boolean;
  overScrollerContent?: ReactNode;
}

interface IInner extends IBaseProps {
  expand?: boolean;
}

const ModalWrapper = Box.withConfig({
  height: 'full',
  width: 'full',
});

const Inner = VStack.withConfig<IInner>(p => ({
  alignItems: 'center',
  height: p.expand ? '95%' : 'auto',
  margin: 'auto',
  maxWidth: '710px',
  width: 'full',
}));

const ModalContent = ({
  children,
  bgColor = theme.modalWrapperBackground,
  expand = false,
  overScrollerContent,
  ...rest
}: IModalContentProps) => {
  return (
    <ModalWrapper accessibilityViewIsModal backgroundColor={bgColor} {...rest}>
      {overScrollerContent && <Box zIndex={2}>{overScrollerContent}</Box>}
      <Scroller>
        <Box height="full" p="$8">
          <Inner expand={expand}>{children}</Inner>
        </Box>
      </Scroller>
    </ModalWrapper>
  );
};

export default ModalContent;
