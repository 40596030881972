import { Box } from '@rbilabs/universal-components';

import { primitive } from 'styles/constants/primitives';

import {
  Direction,
  HorizontalPosition,
  IButtonContainer,
  ILockedAspectRatio,
  ITileSizeable,
  ITileStyleable,
  TileSize,
} from './types';

function getWrapperHeight({ tileSize, lockedAspectRatio }: ITileSizeable & ILockedAspectRatio) {
  if (lockedAspectRatio) {
    return 'auto';
  }

  const titleSizeMap = {
    [TileSize.FULL]: '266px',
    [TileSize.HALF]: '300px',
  };
  return titleSizeMap[tileSize] ?? 'auto';
}

export const Wrapper = Box.withConfig<ITileSizeable & ITileStyleable & ILockedAspectRatio>(
  ({ additionalPadding, hasBorder, hasBoxShadow, tileSize, lockedAspectRatio, ...rest }) => ({
    width: 'full',
    height: getWrapperHeight({ tileSize, lockedAspectRatio }),
    margin: 0,
    padding: additionalPadding || 0,
    backgroundColor: Styles.color.white,
    borderStyle: 'solid',
    borderWidth: hasBorder ? '2' : 0,
    borderColor: primitive.$white,
    shadow: hasBoxShadow ? 2 : 'none',
    ...rest,
  })
);

export const Content = Box.withConfig<ITileSizeable>(({ tileSize }) => ({
  height: 'full',
  justifyContent: 'space-between',
  padding: tileSize === TileSize.HALF ? '$3' : '$4',
}));

export const ButtonContainer = Box.withConfig<IButtonContainer>(
  ({ $direction, buttonPosition, isShowingTwoButtons }) => {
    const horizontalPositionToFlexMap = {
      [HorizontalPosition.LEFT]: 'flex-start',
      [HorizontalPosition.CENTER]: 'center',
      [HorizontalPosition.RIGHT]: 'flex-end',
    };
    return {
      flexDirection: $direction === Direction.REVERSE ? 'row-reverse' : 'row',
      justifyContent: isShowingTwoButtons
        ? 'space-between'
        : horizontalPositionToFlexMap[buttonPosition],
    };
  }
);
