import { Header, makeUclComponent } from '@rbilabs/universal-components';

import { MarketingTileGroup as DefaultMarketingTileGroup } from 'components/features/components/marketing-tile-group';
import SanityBlockRenderer from 'components/sanity-block-renderer';

export const RewardsTitle = Header.withConfig({
  color: Styles.color.primary,
  fontFamily: Styles.fontFamily.brand,
  textAlign: 'center',
  accessibilityLevel: 1,
  fontSize: {
    base: 'lg',
    md: '4xl',
  },
});

export const DisclaimerMessage = makeUclComponent(SanityBlockRenderer).withConfig({
  marginTop: {
    base: '$3',
    md: '$6',
  },
  marginRight: 0,
  marginBottom: {
    base: '$3',
    md: '$12.5',
  },
  marginLeft: 0,
  textProps: {
    color: Styles.color.white,
    textAlign: 'center',
    fontSize: {
      base: 'sm',
      md: 'md',
    },
  },
});

export const MarketingTileGroup = makeUclComponent(DefaultMarketingTileGroup).withConfig<{
  $checkRedemptionCart?: boolean;
}>(p => ({
  width: 'full',
  marginY: 0,
  borderRadius: Styles.borderRadius,
  paddingTop: 0,
  marginTop: p.$checkRedemptionCart ? '0' : '$1',

  _marketingTileList: {
    space: '$6',
  },
}));
