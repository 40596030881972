import { ServiceMode, useListRecommendationItemsQuery } from 'generated/rbi-graphql';

import { IUseUpsellQueryHook } from './types';

export const useDeepflameUpsell: IUseUpsellQueryHook = ({
  products,
  recommendationTypeLogic,
  serviceMode,
  skip,
  storeId,
}) => {
  const { loading, data } = useListRecommendationItemsQuery({
    variables: {
      productDetails: products,
      serviceMode: ServiceMode[serviceMode],
      storeId,
      type: recommendationTypeLogic,
    },
    skip,
  });

  return {
    loading,
    recommendationToken: data?.listRecommendationItems?.recommendationToken,
    recommender: data?.listRecommendationItems?.recommender,
    upsellData: data?.listRecommendationItems?.results,
  };
};
