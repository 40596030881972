import { Box, Link } from '@rbilabs/universal-components';

export const Container = Box.withConfig({
  alignItems: 'center',
  justifyContent: 'center',
  width: 'full',
});
export const Description = Box.withConfig({
  _text: {
    fontSize: 'xs',
    fontWeight: Styles.fontWeight.heavy,
  },
});
export const Label = Box.withConfig({
  _text: {
    fontSize: 'xs',
    fontWeight: Styles.fontWeight.heavy,
  },
});
export const PromoLink = Link.withConfig({
  fontSize: 'xs',
  fontWeight: Styles.fontWeight.heavy,
  color: Styles.color.primary,
});
export const IconDescription = Box.withConfig({
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'baseline',
});

export const ErrorMessage = Box.withConfig({
  _text: {
    color: Styles.color.error,
    fontSize: 'sm',
    lineHeight: '2xs',
  },

  marginBottom: '$4',
});
