import { Button, Header, Text } from '@rbilabs/universal-components';

import Dialog from 'components/dialog';
import Picture from 'components/picture';

export const StyledHeader = Header.withConfig({
  alignSelf: 'center',
  fontFamily: Styles.fontFamily.alternative,
  fontSize: '2xl',
  fontWeight: Styles.fontWeight.heavy,
  accessibilityLevel: 1,
  variant: 'headerOne',
});

export const StyledBody = Text.withConfig({
  alignSelf: 'center',
  fontFamily: Styles.fontFamily.body,
  fontSize: 'md',
  fontWeight: Styles.fontWeight.light,
  width: '98%',
});

export const StyledDialog = Dialog.withConfig({
  paddingTop: '$6',
  textAlign: 'center',
});

export const StyledPicture = Picture.withConfig({
  maxWidth: '85%',
  alignSelf: 'center',
});

export const StyledTextOnlyButton = Button.withConfig({
  variant: 'ghost',
  paddingX: '$12',
  paddingY: '$3',
  marginTop: '$1',
});

export const StyledButton = Button.withConfig({});

export const StyledOutlineButton = Button.withConfig({
  variant: 'outline',
});
