import React, { ComponentProps, PropsWithChildren } from 'react';

import { Box, Header, Icon, Text, VStack } from '@rbilabs/universal-components';
import { useWindowDimensions } from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';

import { ISanityImage } from '@rbi-ctg/menu';
import ActionButton, { ActionButtonVariants } from 'components/action-button';
import { BSM_HEIGHT } from 'components/bottom-service-mode/styled';
import Picture from 'components/picture';
import { IHeroFragment } from 'generated/sanity-graphql';
import { useNavigation } from 'hooks/navigation/use-navigation';
import { TAB_BAR_HEIGHT } from 'navigation/tab-navigator/constants';
import { RunningExperiments, useExperiments } from 'state/experiments';
import { useOrderContext } from 'state/order';
import { ServiceMode, useServiceModeContext } from 'state/service-mode';
import { theme } from 'styles/configure-theme';
import { NAV_HEIGHT } from 'styles/constants/enums';
import { EventName, emitEvent } from 'utils/event-hub';

type HeroProps = PropsWithChildren<{ hero: { readonly __typename?: 'Hero' } & IHeroFragment }>;
type Props = {
  navigate: ReturnType<typeof useNavigation>['navigate'];
  numberOfItemsInCart: number;
  height: number;
  serviceMode: ReturnType<typeof useServiceModeContext>['serviceMode'];
} & HeroProps;

const pickupModes = [
  ServiceMode.CURBSIDE,
  ServiceMode.DRIVE_THRU,
  ServiceMode.EAT_IN,
  ServiceMode.TAKEOUT,
];
const SimpleHomePageHero = React.memo(function SimpleHomePageHero(props: Props) {
  const isPickup = pickupModes.some(s => s === props.serviceMode);
  const isDelivery = ServiceMode.DELIVERY === props.serviceMode;

  const header = props.hero?.header?.locale ?? '';
  const subheader = props.hero?.subheader?.locale ?? '';
  const sanityImage = props.hero.image?.locale as ISanityImage;
  const imageAlt = (props.hero.altImageText?.locale ?? '') as string;
  const image = sanityImage && (
    <Picture
      priority="high"
      height="300px"
      width="320px"
      image={sanityImage}
      alt={imageAlt}
      objectFitContain
      marginX="auto"
      onImageLoaded={() => {
        emitEvent(EventName.MAIN_HERO_IMAGE_LOADED);
      }}
    />
  );

  return (
    <Box
      height={props.height}
      width="full"
      justifyContent="space-between"
      paddingX="$6"
      paddingY="$2"
    >
      {image}

      <Box>
        <Header color={theme.token('text-hero-light')} fontSize="3xl" lineHeight="xs">
          {header}
        </Header>
        <Text variant="headerThree" color={theme.token('text-hero-light')}>
          {subheader}
        </Text>
      </Box>

      <VStack space="$5" marginBottom="$4">
        <CTAButton
          primary
          defaultLinkTo="/store-locator"
          iconVariant="cart"
          isMatchingServiceMode={isPickup}
          numberOfItemsInCart={props.numberOfItemsInCart}
          navigate={props.navigate}
        >
          Order Pick Up
        </CTAButton>

        <CTAButton
          defaultLinkTo="/store-locator/address"
          iconVariant="delivery"
          isMatchingServiceMode={isDelivery}
          numberOfItemsInCart={props.numberOfItemsInCart}
          navigate={props.navigate}
        >
          Order Delivery
        </CTAButton>
      </VStack>
    </Box>
  );
});

// This is a dynamic component to handle both of the CTA Buttons on this hero card.
// this is sort of overkill, but it played the fine line between lots of redundant similar logic
// and too much complexity to be DRY'd up. Ultimately I landed on this, to make the primary component
// above simpler to reason about. This holds most of the complex business logic associated with this test.
const CTAButton = (props: {
  primary?: boolean;
  children: string;
  defaultLinkTo: '/store-locator/address' | '/store-locator';
  isMatchingServiceMode: boolean;
  iconVariant: ComponentProps<typeof Icon>['variant'];
  numberOfItemsInCart: number;
  navigate: ReturnType<typeof useNavigation>['navigate'];
}) => {
  const shouldGoToCart = props.isMatchingServiceMode && props.numberOfItemsInCart > 0;

  const handleDelivery = () => {
    let path: string = props.defaultLinkTo;
    path = props.isMatchingServiceMode ? '/menu' : path;
    path = shouldGoToCart ? '/cart' : path;
    props.navigate(path);
  };

  return (
    <ActionButton
      variant={props.primary ? ActionButtonVariants.PRIMARY : ActionButtonVariants.OUTLINE}
      paddingTop="$3.5"
      paddingBottom="$3.5"
      _text={{
        fontSize: 'lg',
        lineHeight: 'lg',
      }}
      eventAttributes={{ Name: shouldGoToCart ? 'Continue to Cart' : props.children }}
      leftIcon={<Icon variant={props.iconVariant} />}
      onPress={handleDelivery}
    >
      {shouldGoToCart ? `Continue to Cart (${props.numberOfItemsInCart})` : props.children}
    </ActionButton>
  );
};

function usePageContentHeight() {
  const windowDimensions = useWindowDimensions();
  const { bottom: bottomInset, top: topInset } = useSafeAreaInsets();
  const { serviceMode } = useServiceModeContext();

  const headerHeight = NAV_HEIGHT.MOBILE + topInset;
  const bottomTabBarHeight = TAB_BAR_HEIGHT + bottomInset;
  const bottomServiceModeHeight = serviceMode ? BSM_HEIGHT : 0;

  // TODO: BK should include top service mode height.
  const nonPageContentElementsHeight = headerHeight + bottomTabBarHeight + bottomServiceModeHeight;

  return windowDimensions.height - nonPageContentElementsHeight;
}

export function SimpleHomePageHeroContainer(props: HeroProps) {
  const { navigate } = useNavigation();
  const { numCartPreviewEntries } = useOrderContext();
  const { serviceMode } = useServiceModeContext();
  const pageContentHeight = usePageContentHeight();

  return (
    <SimpleHomePageHero
      {...props}
      height={pageContentHeight}
      navigate={navigate}
      numberOfItemsInCart={numCartPreviewEntries}
      serviceMode={serviceMode}
    />
  );
}

// This test is wrapping the home page hero component and optionally
// overriding it when our home page test variant is running.
export function SimpleHomePageHero__Experiment(props: HeroProps) {
  const { variant } = useExperiments();

  if (variant(RunningExperiments.SimpleHomePage, 'treatment')) {
    return <SimpleHomePageHeroContainer hero={props.hero} />;
  }

  return <>{props.children}</>;
}
