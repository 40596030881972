import React from 'react';

import { WebView } from 'react-native-webview';

import {
  ILocaleStringFragment,
  ILocaleVideoFileFragment,
  IVideoWidget,
} from 'generated/sanity-graphql';
import { theme } from 'styles/configure-theme';

import { useStaticContentLanguage } from './static-content-language';

export type IVideoWidgetProps = Pick<IVideoWidget, 'attributionLink'> & {
  caption?: ILocaleStringFragment;
  format?: string;
  video: ILocaleVideoFileFragment;
};

const VideoWidget: React.FC<React.PropsWithChildren<IVideoWidgetProps>> = ({
  attributionLink,
  caption,
  video,
  format = 'video/mp4',
}) => {
  const { staticContentLanguage: language } = useStaticContentLanguage();
  const source = video?.[language]?.asset?.url ?? null;

  const styles = `
    <style type="text/css">
      .container {
        display: block;
        width: 90%;
        max-width: 920px;
        overflow: hidden;
        margin: 1.5rem auto;
      }
      .video {
        width: 100%;
        display: block;
        border-radius: 4px;
        background: #000;
      }
      .video-caption {
        padding-top: 1rem;
        font-size: 0.875rem;
        color: ${theme.token('text-default')};
        & > a {
          color: ${theme.token('text-active')};
          margin-right: 0.33rem;
        }
      }
    </style>
  `;

  const body = `
    <body>
      <div class="container">
        <video class="video" controls>
          <source src="${source}" type=${format} />
        </video>
        <figcaption class="video-caption">
          ${attributionLink ? <a href={attributionLink}>Video Source</a> : ''}
          ${caption?.[language]}
        </figcaption>
      </div>
    </body>
  `;

  const html = `
    <html>
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    ${styles}
    ${body}
    </html>
`;

  return <WebView style={{ height: 400 }} source={{ html }} />;
};

export default VideoWidget;
