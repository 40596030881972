import * as React from 'react';
import { useCallback } from 'react';

import { Box, FormControl, Input, makeUclComponent } from '@rbilabs/universal-components';
import type { InputProps } from '@rbilabs/universal-components';

import ActionButton from 'components/action-button';
import { FormValidationState } from 'utils/form';

export interface IInputWithButtonProps extends Omit<InputProps, 'onChange'> {
  label: string;
  value: string;
  buttonLabel: string;
  disabled?: boolean;
  name?: string;
  mask?: (value: string) => any;
  guide?: boolean;
  placeholderChar?: string;
  onChange: (event: React.ChangeEvent) => void;
  validation?: FormValidationState;
  inputReadOnly?: boolean;
  onSubmit: (inputValue: string) => void;
  isLoading?: boolean;
  autoFocus?: boolean;
  buttonTabIndex?: number;
}

const StyledForm = FormControl.withConfig({
  padding: 0,
  marginY: '$4',
  marginX: 0,
  flexDirection: 'row',
});

const FloatingInput = Input.withConfig({
  flexGrow: 2,
  margin: 0,
  borderRadius: Styles.borderRadius,
  paddingX: '$4',
  paddingY: 0,
  borderColor: Styles.color.grey.six,
  autoCapitalize: 'none',
});

const SubmitBtnWrapper = Box.withConfig({
  zIndex: Styles.zIndex.top,
  alignItems: 'center',
  justifyContent: 'center',
});

const StyledActionButton = makeUclComponent(ActionButton).withConfig({
  borderRadius: 0,
  shadow: '5',
  _text: {
    fontSize: 'lg',
    textAlign: 'center',
  },
});

const InputWithButton: React.FC<React.PropsWithChildren<IInputWithButtonProps>> = ({
  buttonLabel,
  label,
  value,
  disabled,
  onChange,
  validation,
  inputReadOnly,
  onSubmit,
  isLoading,
  autoFocus,
  buttonTabIndex = 0,
  ...props
}) => {
  const handlePress = useCallback(() => {
    onSubmit(value);
  }, [onSubmit, value]);

  const InnerInputButton = (
    <FloatingInput
      placeholder={label}
      value={value}
      isReadOnly={inputReadOnly}
      onChangeText={(text: string) => onChange({ target: { value: text } } as any)}
      testID="input-with-submit-field"
      accessibilityLabel={label}
      autoFocus={autoFocus}
      InputRightElement={
        <SubmitBtnWrapper>
          <StyledActionButton
            isLoading={isLoading}
            testID="input-with-submit-button"
            disabled={disabled || isLoading}
            focusable={buttonTabIndex !== -1}
            onPress={handlePress}
          >
            {buttonLabel}
          </StyledActionButton>
        </SubmitBtnWrapper>
      }
      {...props}
    />
  );

  return (
    <StyledForm
      isDisabled={disabled}
      isInvalid={validation === FormValidationState.INVALID}
      testID="input-with-button"
    >
      {InnerInputButton}
    </StyledForm>
  );
};

export default InputWithButton;
