import React, { ComponentProps } from 'react';

import { Box, Header, makeUclComponent } from '@rbilabs/universal-components';

import { ISanityImage, ISanityTypographyBlock } from '@rbi-ctg/menu';
import ActionButton, { ActionButtonVariants } from 'components/action-button';
import { TypographyBlock } from 'components/features/components/typography-block';

import { BasicTileContainer, BasicTileImage, BasicTileTitle } from './styled';

interface IMktBasicTileProps {
  description: ISanityTypographyBlock[];
  image?: ISanityImage;
  imageDescription?: string;
  title: string;
  link?: string;
  linkLabel?: string;
  onPress?: () => void;
  _basicTileImage?: Omit<ComponentProps<typeof BasicTileImage>, 'alt'>;
  _basicTileTitle?: ComponentProps<typeof Header>;
  _typographyBlock?: Omit<ComponentProps<typeof TypographyBlock>, 'content'>;
}

const MarketingBasicTile: React.FC<React.PropsWithChildren<IMktBasicTileProps>> = ({
  description,
  image,
  imageDescription = '',
  title,
  link,
  linkLabel,
  onPress,
  _basicTileImage,
  _basicTileTitle,
  _typographyBlock,
  ...props
}) => {
  return (
    <BasicTileContainer {...props}>
      {image && (
        <BasicTileImage
          image={image}
          alt={title}
          {..._basicTileImage}
          accessibilityLabel={imageDescription}
        />
      )}
      <Box testID="basic-tile-text" flex="1">
        <BasicTileTitle testID="basic-tile-title" {..._basicTileTitle}>
          {title}
        </BasicTileTitle>
        <TypographyBlock color={Styles.color.white} content={description} {..._typographyBlock} />
        {!!linkLabel && (!!link || onPress) && (
          <ActionButton linkPath={link} onPress={onPress} variant={ActionButtonVariants.OUTLINE}>
            {linkLabel}
          </ActionButton>
        )}
      </Box>
    </BasicTileContainer>
  );
};

export default makeUclComponent(MarketingBasicTile);
