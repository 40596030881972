import React, { FC, PropsWithChildren } from 'react';

import { Box } from '@rbilabs/universal-components';
import { useWindowDimensions } from 'react-native';

import { APP_MINIMUM_HEIGHT } from 'utils/constants';

export const Wrapper: FC<PropsWithChildren> = ({ children }) => {
  const dimension = useWindowDimensions();
  const height = Math.max(dimension.height, APP_MINIMUM_HEIGHT);

  return (
    <Box backgroundColor={Styles.color.background} height={height}>
      {children}
    </Box>
  );
};
