import { useCallback } from 'react';

import { StackActions } from '@react-navigation/native';

import { IStore } from '@rbi-ctg/store';
import { IRestaurantNode } from 'generated/rbi-graphql';
import { useNavigation } from 'hooks/navigation/use-navigation';
import { useRoute } from 'hooks/navigation/use-route';
import { useReactNavigationFlag } from 'hooks/use-react-navigation-flag';
import { navigationRef } from 'navigation/navigation-container/navigation-container-ref';
import {
  getRewardIdFromSearch,
  makeLoyaltyRewardDetailsPath,
} from 'pages/loyalty/loyalty-incentives-components/incentive-details/utils';
import { useInRestaurantRedemptionContext } from 'state/loyalty/in-restaurant-redemption';
import { ServiceMode } from 'state/order';
import { StoreProxy } from 'state/store';
import { routes } from 'utils/routing';

import { useStaticMenuContext } from './static-menu';

type IRedirectCallbackArgs = {
  restaurant: StoreProxy | IRestaurantNode;
  serviceMode?: ServiceMode | null;
};

export const useStaticMenuRedirect = () => {
  const { navigate } = useNavigation();
  const { isInRestaurantRedemptionEnabledAtStore } = useInRestaurantRedemptionContext();
  const { pathname } = useRoute();

  const {
    resetState,
    selectedStaticMenuItemId,
    selectedStaticMenuItemSearch: search,
  } = useStaticMenuContext();

  const enableReactNativeNavigation = useReactNavigationFlag();

  return useCallback(
    async ({ restaurant, serviceMode }: IRedirectCallbackArgs) => {
      const rewardId = getRewardIdFromSearch(search);
      const shouldNavigateToRewardDetails =
        rewardId &&
        (await isInRestaurantRedemptionEnabledAtStore(restaurant as IStore, serviceMode));

      if (enableReactNativeNavigation) {
        // For ReactNative Store locator is better to close the modal/stack instead of redirect to the previous screen
        const isStoreInfoModal = pathname.startsWith(routes.store);
        navigationRef.dispatch(StackActions.pop(isStoreInfoModal ? 2 : 1));
      } else if (rewardId && shouldNavigateToRewardDetails) {
        navigate(makeLoyaltyRewardDetailsPath({ rewardId, search }), { replace: true });
      } else {
        navigate(`${routes.menu}/${selectedStaticMenuItemId}${search}`, { replace: true });
      }
      resetState();
    },
    [
      enableReactNativeNavigation,
      isInRestaurantRedemptionEnabledAtStore,
      navigate,
      pathname,
      resetState,
      search,
      selectedStaticMenuItemId,
    ]
  );
};
