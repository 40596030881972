import * as React from 'react';
import { useMemo } from 'react';

import { HStack, Icon } from '@rbilabs/universal-components';
import { useIntl } from 'react-intl';

import ActionButton, { ActionButtonSizes } from 'components/action-button';
import LoadingAnimation from 'components/loading-animation';
import { RewardsCarousel } from 'components/rewards-carousel';
import { useLoyaltyUser } from 'state/loyalty/hooks/use-loyalty-user';

import {
  Div,
  LoadingContainer,
  RewardsText,
  StyledPoints,
  Wrapper,
} from './loyalty-points-rewards-widget.styled';
import useHomeRewards from './use-home-rewards';

const LoyaltyPointsAndRewardsWidget = () => {
  const { formatMessage } = useIntl();
  const { loyaltyUser, loading: loyaltyLoading } = useLoyaltyUser();
  const { rewards, loadingRewards } = useHomeRewards();
  const userPoints = loyaltyUser?.points ?? 0;

  const rewardsMessage = useMemo(() => {
    if (!rewards?.length) {
      return '';
    }

    let messageId = 'loyaltyUseYourPoints';
    let points: number | undefined;

    const nextReward = rewards.find(({ locked, pointCost }) => locked && pointCost > userPoints);

    if (nextReward) {
      messageId = 'loyaltyEarnMorePointsForReward';
      points = nextReward.pointCost - userPoints;
    }

    return formatMessage({ id: messageId }, { points });
  }, [formatMessage, rewards, userPoints]);

  const loading = (loyaltyLoading || loadingRewards) && !!rewardsMessage;

  return (
    <Wrapper testID="reward-widget">
      {loading ? (
        <LoadingContainer testID="widget-loader">
          <LoadingAnimation />
        </LoadingContainer>
      ) : (
        <>
          <Div>
            <HStack justifyContent="space-between" flexWrap="wrap">
              <HStack>
                <StyledPoints testID="user-points">{userPoints}</StyledPoints>
                <Icon variant="bkCrown" color="#FF8732" size="lg" />
              </HStack>

              <ActionButton
                size={ActionButtonSizes.LARGE}
                to={formatMessage({ id: 'routes.loyaltyRewardList' })}
                paddingTop="0"
                paddingBottom="0"
                height="50%"
                maxHeight="$10"
              >
                {formatMessage({ id: 'loyaltyUseMyCrowns' })}
              </ActionButton>
            </HStack>
            <RewardsText testID="rewards-message">{rewardsMessage}</RewardsText>
          </Div>
          {rewards && <RewardsCarousel rewards={rewards} />}
        </>
      )}
    </Wrapper>
  );
};

export default LoyaltyPointsAndRewardsWidget;
