import React from 'react';

import { Box, Header, Text, VStack } from '@rbilabs/universal-components';
import { closestTo, format, parse } from 'date-fns';
import { capitalize } from 'lodash-es';
import { useIntl } from 'react-intl';

import Picture from 'components/picture';
import { IValidDayPart } from 'state/day-part/hooks/use-active-day-parts';
import { DAYPART_FORMAT, TWELVE_HOUR_TIME_PARSE_FORMAT } from 'utils/restaurant/constants';

interface IProps {
  dayParts: ReadonlyArray<IValidDayPart>;
  itemDayParts: string[];
  marginAndBorders: boolean;
}

type WrapperProps = Pick<IProps, 'marginAndBorders'>;

// TODO - RN is this still needed?
// const marginAndBordersCSS = css`
//   margin: 2rem 0 1rem;
//   padding: 1rem 0;
//   border-top: 1px solid ${Styles.color.grey.five};
//   border-bottom: 1px solid ${Styles.color.grey.five};
// `;

const ContentWrapper = Box.withConfig<WrapperProps>({
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  paddingLeft: '$8',
  // ${({ marginAndBorders }) => marginAndBorders && marginAndBordersCSS}
});

const TextWrapper = VStack.withConfig({
  justifyContent: 'center',
});

const ItemUnavailableMessage = ({ dayParts, itemDayParts, marginAndBorders }: IProps) => {
  const { formatMessage } = useIntl();

  const now = new Date();
  const itemDayPartObjects = dayParts.filter(part => itemDayParts.includes(part.key));
  const itemDayPartBoundaryDateObjects = itemDayPartObjects.flatMap(part => [
    parse(part.startTime, DAYPART_FORMAT, now),
    parse(part.endTime, DAYPART_FORMAT, now),
  ]);
  const closestDayPartBoundaryDateObj = closestTo(now, itemDayPartBoundaryDateObjects);
  // @ts-expect-error TS(2345) FIXME: Argument of type 'Date | undefined' is not assigna... Remove this comment to see the full error message
  const closestDayPartBoundaryString = format(closestDayPartBoundaryDateObj, DAYPART_FORMAT);
  const closestItemDayPartObject = itemDayPartObjects.find(part =>
    Object.values(part).some(time => time === closestDayPartBoundaryString)
  );

  if (!closestItemDayPartObject) {
    return null;
  }

  const { startTime, endTime, key, displayName, icon } = closestItemDayPartObject;
  const dayPartName = displayName?.locale || key;

  let dayPartMessage = '';
  let timeBoundaryMessage = '';
  const nowInDayPartFormat = format(now, DAYPART_FORMAT);

  if (nowInDayPartFormat < startTime) {
    dayPartMessage = formatMessage(
      { id: 'daypartBeforeStartTime' },
      { dayPart: dayPartName.toLowerCase() }
    );
    timeBoundaryMessage = formatMessage(
      { id: 'daypartStartsAt' },
      {
        dayPart: capitalize(dayPartName),
        startTime: format(parse(startTime, DAYPART_FORMAT, now), TWELVE_HOUR_TIME_PARSE_FORMAT),
      }
    );
  } else {
    dayPartMessage = formatMessage(
      { id: 'daypartAfterEndTime' },
      { dayPart: dayPartName.toLowerCase() }
    );
    timeBoundaryMessage = formatMessage(
      { id: 'daypartEndsAt' },
      {
        dayPart: capitalize(dayPartName),
        endTime: format(parse(endTime, DAYPART_FORMAT, now), TWELVE_HOUR_TIME_PARSE_FORMAT),
      }
    );
  }

  return (
    <ContentWrapper marginAndBorders={!!marginAndBorders}>
      <Box flex={0.3}>
        <Picture image={icon} alt={dayPartName} width={'40px'} />
      </Box>
      <Box flex={0.7}>
        <TextWrapper>
          {dayPartMessage && (
            <Header variant="headerThree" margin="0">
              {dayPartMessage}
            </Header>
          )}
          {timeBoundaryMessage && <Text variant="copyTwo">{timeBoundaryMessage}</Text>}
        </TextWrapper>
      </Box>
    </ContentWrapper>
  );
};

export default ItemUnavailableMessage;
