import React from 'react';

import { AlertDialog, Box, Header, makeUclComponent } from '@rbilabs/universal-components';
import { useIntl } from 'react-intl';

import ActionButton, { ActionButtonVariants } from 'components/action-button';

import type { IDialogProps } from './types';

const CloseButtonContainer = Box.withConfig({
  mt: '$2',
  width: 'full',
});

export const CloseButton = makeUclComponent(ActionButton).withConfig({
  fullWidth: true,
  variant: ActionButtonVariants.TEXT_ONLY,
});

const Dialog: React.FC<React.PropsWithChildren<IDialogProps>> = ({
  children,
  heading,
  headingComponent,
  body,
  bodyComponent,
  onDismiss,
  actions,
  image,
  initialFocusRef,
  /**
   * A small string sent to mParticle describing the purpose of the modal.
   */
  modalAppearanceEventMessage,
  /**
   * Additional Error details to be sent to mParticle.
   */
  errorEventMessage,
  testID,
  showCloseButton = false,
  showDialog,
  ...props
}) => {
  const { formatMessage } = useIntl();

  if (!actions && !showCloseButton && __DEV__) {
    // eslint-disable-next-line no-console
    console.error('Dialog: Please add actions or show the close button (showCloseButton)');
  }
  const cancelRef = React.useRef(null);

  return (
    <AlertDialog
      leastDestructiveRef={initialFocusRef || cancelRef}
      isOpen={showDialog}
      onClose={onDismiss}
      {...props}
    >
      <AlertDialog.Content testID={testID}>
        {image}
        {headingComponent ? (
          headingComponent
        ) : heading ? (
          <AlertDialog.Header>
            <Header variant="headerTwo">{heading}</Header>
          </AlertDialog.Header>
        ) : null}
        {children ? (
          children
        ) : bodyComponent ? (
          bodyComponent
        ) : body ? (
          <AlertDialog.Body>{body}</AlertDialog.Body>
        ) : null}
        {(actions || showCloseButton) && (
          <AlertDialog.Footer>
            {actions}
            {showCloseButton && (
              <CloseButtonContainer>
                <CloseButton onPress={onDismiss}>{formatMessage({ id: 'close' })}</CloseButton>
              </CloseButtonContainer>
            )}
          </AlertDialog.Footer>
        )}
      </AlertDialog.Content>
    </AlertDialog>
  );
};

export default makeUclComponent(Dialog);
