import { useCallback, useEffect, useMemo, useState } from 'react';

import { CognitoUserSession } from 'amazon-cognito-identity-js';
import { omit, pick } from 'lodash-es';
import { useIntl } from 'react-intl';

import { IUserInfoSubmitData } from 'components/user-info-form/types';
import {
  GetMeDocument,
  ICommunicationPreference,
  IDeliveryAddress,
  IFavoriteStore,
  IUserDetailsFragment,
  useGetMeQuery,
  useUpdateMeMutation,
} from 'generated/rbi-graphql';
import useEffectOnUpdates from 'hooks/use-effect-on-updates';
import useEffectOnce from 'hooks/use-effect-once';
import { ModalCb } from 'hooks/use-error-modal';
import { usePrevious } from 'hooks/use-previous';
import { useToast } from 'hooks/use-toast';
import { checkForUnexpectedSignOut, getCurrentSession } from 'remote/auth';
import { updateUserAttributes as cognitoUpdateUserAttributes } from 'remote/auth/cognito';
import { useAmplitudeContext } from 'state/amplitude';
import { transformUserDetailsToAmplitudeUserAttributes } from 'state/amplitude/utils';
import { UpdateUserInfoOptions } from 'state/auth/types';
import { setBrazeUserAttributes } from 'state/braze/set-braze-user-attributes';
import { LaunchDarklyFlag, useFlag, useLDContext } from 'state/launchdarkly';
import { useNetworkContext } from 'state/network';
import AuthStorage from 'utils/cognito/storage';
import * as Datadog from 'utils/datadog';
import { StorageKeys } from 'utils/local-storage';
import logger, { addLoggingContext } from 'utils/logger';

import { CommunicationPreferences, FavoriteStores, UserDetails } from './types';
import { useThirdPartyAuthentication } from './use-third-party-authentication';

export * from './types';

export const NUM_RECENT_PURCHASED_ITEMS = 10;

export interface IUseCurrentUser {
  openErrorDialog: ModalCb;
}

const configUserDetails = (details: IUserDetailsFragment) => ({
  dob: details.dob || '',
  email: details.email || '',
  emailVerified: details.emailVerified as boolean,
  name: details.name || '',
  phoneNumber: details.phoneNumber || '',
  phoneVerified: details.phoneVerified as boolean,
  promotionalEmails: details.promotionalEmails as boolean,
  isoCountryCode: details.isoCountryCode || '',
  zipcode: details.zipcode || '',
  defaultReloadAmt: details.defaultReloadAmt as number,
  defaultAccountIdentifier: details.defaultAccountIdentifier || '',
  defaultFdAccountId: details.defaultFdAccountId || '',
  defaultPaymentAccountId: details.defaultPaymentAccountId || '',
  defaultScanAndPayAccountIdentifier: details.defaultScanAndPayAccountIdentifier || '',
  autoReloadEnabled: details.autoReloadEnabled as boolean,
  autoReloadThreshold: details.autoReloadThreshold as number,
  loyaltyTier: details.loyaltyTier,
  communicationPreferences: (details.communicationPreferences as CommunicationPreferences) || null,
  favoriteStores: (details.favoriteStores as FavoriteStores) || null,
  deliveryAddresses: (details.deliveryAddresses as Array<IDeliveryAddress>) || null,
});

export const useCurrentUser = ({ openErrorDialog }: IUseCurrentUser) => {
  const toast = useToast();
  const { formatMessage } = useIntl();
  const { updateUserAttributes: launchDarklyUpdateUserAttributes } = useLDContext();
  const { updateAmplitudeUserAttributes } = useAmplitudeContext();
  const { hasNetworkError, setHasNotAuthenticatedError } = useNetworkContext();
  const { logUserInToThirdPartyServices } = useThirdPartyAuthentication();
  const [currentUserSession, setCurrentUserSession] = useState<CognitoUserSession | null>(null);
  const shouldUniqueByModifiers = useFlag(LaunchDarklyFlag.ENABLE_RECENT_ITEMS_WITH_MODIFIERS);
  const getMeVariables = {
    numUniquePurchasedItems: NUM_RECENT_PURCHASED_ITEMS,
    customInput: { shouldUniqueByModifiers },
  };
  const { data: userData, loading, refetch, called } = useGetMeQuery({
    skip: !AuthStorage.getItem(StorageKeys.USER_AUTH_TOKEN),
    variables: getMeVariables,
    // Apollo client loading state gets stuck: https://github.com/apollographql/react-apollo/issues/3425
    // Temporary fix while we wait for a stable 3.0.0 version
    fetchPolicy: 'cache-and-network',
  });

  // NOTE: The updateMeMutation will attempt to refetch and update userData from useGetMeQuery
  // The refetchQueries query needs to match the useGetMeQuery exactly, including the variables
  const [updateMeMutation, { loading: useUpdateMeMutationLoading }] = useUpdateMeMutation({
    refetchQueries: [{ query: GetMeDocument, variables: getMeVariables }],
    awaitRefetchQueries: true,
  });

  useEffect(() => {
    getCurrentSession().then(user => {
      AuthStorage.setItem(StorageKeys.USER_AUTH_TOKEN, user?.getAccessToken().payload.username);
      setCurrentUserSession(user);
    });
  }, []);

  const prevUserData = usePrevious(userData);

  const user = currentUserSession ? userData?.me : null;
  const cognitoId = user?.cognitoId ?? AuthStorage.getItem(StorageKeys.USER_AUTH_TOKEN) ?? null;

  const setCurrentUser = useCallback(
    (session: null | CognitoUserSession, numUniquePurchasedItems?: number) => {
      if (session && called) {
        const refetchVariables = numUniquePurchasedItems
          ? { numUniquePurchasedItems, customInput: { shouldUniqueByModifiers } }
          : undefined;
        refetch(refetchVariables);
      }
      setCurrentUserSession(session);
    },
    [called, shouldUniqueByModifiers, refetch]
  );

  const refreshCurrentUser = useCallback(
    async (numUniquePurchasedItems?: number) => {
      try {
        AuthStorage.setItem(
          StorageKeys.USER_AUTH_TOKEN,
          currentUserSession?.getAccessToken().payload.username
        );
        setCurrentUser(currentUserSession, numUniquePurchasedItems);
      } catch (error) {
        logger.error({ error, message: 'An error occurred while refreshing the current user.' });
        openErrorDialog({
          // @ts-expect-error TS(2322) FIXME: Type 'unknown' is not assignable to type 'Error | ... Remove this comment to see the full error message
          error,
          message: formatMessage({ id: 'authRetryError' }),
          modalAppearanceEventMessage: 'Error: Setting Current User Error',
        });
      }
    },
    [currentUserSession, formatMessage, openErrorDialog, setCurrentUser]
  );

  const refreshCurrentUserWithNewSession = useCallback(
    async (numUniquePurchasedItems?: number) => {
      try {
        const session = await getCurrentSession();
        AuthStorage.setItem(
          StorageKeys.USER_AUTH_TOKEN,
          session?.getAccessToken().payload.username
        );
        setCurrentUserSession(session);
        setCurrentUser(currentUserSession, numUniquePurchasedItems);
      } catch (error) {
        logger.error({
          error,
          message: 'An error occurred while refreshing the current user with new session.',
        });
        openErrorDialog({
          // @ts-expect-error TS(2322) FIXME: Type 'unknown' is not assignable to type 'Error | ... Remove this comment to see the full error message
          error,
          message: formatMessage({ id: 'authRetryError' }),
          modalAppearanceEventMessage: 'Error: Setting Current User Error With New Session',
        });
      }
    },
    [currentUserSession, formatMessage, openErrorDialog, setCurrentUser]
  );

  const updateCRMAttributes = useCallback(
    (updatedAttributes: IUserInfoSubmitData | UserDetails['details']) => {
      if (!user) {
        return;
      }

      const userAttributes: (IUserInfoSubmitData | UserDetails['details']) & {
        customerid: string;
        rbiCognitoId: string;
      } = {
        customerid: user.thLegacyCognitoId ? `us-east-1:${user.thLegacyCognitoId}` : user.cognitoId,
        rbiCognitoId: user.cognitoId,
        ...user.details,
        ...updatedAttributes,
      };

      const transformedAttributes = transformUserDetailsToAmplitudeUserAttributes(userAttributes);
      updateAmplitudeUserAttributes(transformedAttributes);
      setBrazeUserAttributes({
        name: userAttributes.name,
        phoneNumber: userAttributes.phoneNumber,
        ...transformedAttributes,
      });
    },
    [updateAmplitudeUserAttributes, user]
  );

  const updateUserInfo = useCallback(
    async (form: IUserInfoSubmitData, options?: UpdateUserInfoOptions) => {
      try {
        // QUESTION - why do we await the current session here if we don't do anything with it?
        await getCurrentSession();
        cognitoUpdateUserAttributes(pick(form, 'phoneNumber', 'dob', 'name'));
        updateCRMAttributes(form);
        launchDarklyUpdateUserAttributes({
          key: user?.cognitoId,
          ...form,
        });

        const updateMeParams = omit(
          form,
          'agreesToTermsOfService',
          'defaultCheckoutPaymentMethodId',
          'defaultReloadPaymentMethodId',
          'email',
          'emailVerified',
          'phoneVerified',
          'deliveryAddresses'
        );

        const input = {
          ...updateMeParams,
          defaultAccountIdentifier: form.defaultCheckoutPaymentMethodId,
          defaultPaymentAccountId: form.defaultReloadPaymentMethodId,
        };

        await updateMeMutation({ variables: { input } });
      } catch (error) {
        if (!options) {
          return;
        }

        const errorMessage = 'Error: Update User Info Failure';

        if (!options.shouldMuteUserInfoErrors) {
          logger.error({ error, message: errorMessage });
          toast.show({
            text: formatMessage({ id: 'updateInfoError' }),
            variant: 'negative',
          });
        } else {
          logger.error({
            error,
            message: `${errorMessage} - Muted`,
          });
        }
        if (options.shouldThrowException) {
          throw new Error(errorMessage);
        }
      }
    },
    [
      updateCRMAttributes,
      launchDarklyUpdateUserAttributes,
      user,
      updateMeMutation,

      toast,
      formatMessage,
    ]
  );

  const updateUserCommPrefs = useCallback(
    async (
      communicationPreferences: Array<ICommunicationPreference>,
      options?: { shouldThrowException?: boolean }
    ) => {
      const shouldThrowException = options?.shouldThrowException ?? false;
      try {
        const promotionalEmailsInput =
          (communicationPreferences.length && {
            promotionalEmails: communicationPreferences.some(({ value }) => value === 'true'),
          }) ||
          {};
        const input = {
          communicationPreferences,
          ...promotionalEmailsInput,
        };
        const { data } = await updateMeMutation({ variables: { input } });

        if (!data) {
          return logger.error({ message: 'An error occurred updating communication preference' });
        }

        const details = configUserDetails(data.updateMe.details);
        updateCRMAttributes(details);
      } catch (error) {
        const errorMessage = 'Error: Update User Communication Preferences Failure';
        logger.error({ error, message: errorMessage });
        toast.show({
          text: formatMessage({ id: 'updateInfoError' }),
          variant: 'negative',
        });
        if (shouldThrowException) {
          throw new Error(errorMessage);
        }
      }
    },
    [updateMeMutation, updateCRMAttributes, toast, formatMessage]
  );

  const updateUserFavStores = useCallback(
    async (favoriteStores: Array<IFavoriteStore>) => {
      try {
        const input = { favoriteStores };
        const { data } = await updateMeMutation({ variables: { input } });
        if (!data) {
          logger.error({ message: 'An error occurred updating favorite store' });
          toast.show({
            text: formatMessage({ id: 'updateInfoError' }),
            variant: 'negative',
          });
        }
      } catch (error) {
        logger.error({ message: `An error occurred updating favorite store: ${error}` });
        toast.show({
          text: formatMessage({ id: 'updateInfoError' }),
          variant: 'negative',
        });
      }
    },
    [toast, formatMessage, updateMeMutation]
  );

  const checkIfUnexpectedSignOut = useCallback(async () => {
    const errorUnexpectedSignOut = await checkForUnexpectedSignOut();
    if (errorUnexpectedSignOut) {
      logger.error({
        message: 'Unexpected Sign out',
        error: errorUnexpectedSignOut,
      });
    }
  }, []);

  useEffect(() => {
    // decorate logger with cognito id
    addLoggingContext({ userId: cognitoId });
    Datadog.setUser({ id: cognitoId });
  }, [cognitoId]);

  useEffect(() => {
    if (!user) {
      checkIfUnexpectedSignOut();
    }
    // No longer needed... Use USER_AUTH_TOKEN for performance reasons
    // TODO: RN - Cleanup once we are no longer supporting capacitor
    AuthStorage.removeItem(StorageKeys.USER);
  }, [checkIfUnexpectedSignOut, prevUserData, user]);

  useEffectOnce(() => {
    const refreshUserIfHasSignedIn = async () => {
      if (!user) {
        setHasNotAuthenticatedError(true);
      }
    };
    refreshUserIfHasSignedIn();
  });

  useEffectOnUpdates(() => {
    // any update fetch + set current user
    if (!hasNetworkError) {
      refreshCurrentUser();
    }
  }, [hasNetworkError]);

  // when user data populates for the first time, it means the user got signed in, therefore we should sign them into all third party services as well
  useEffect(() => {
    if (userData && !prevUserData) {
      logUserInToThirdPartyServices((userData.me as unknown) as UserDetails);
    }
  }, [userData, prevUserData, logUserInToThirdPartyServices]);

  return useMemo(
    () => ({
      refreshCurrentUser,
      refreshCurrentUserWithNewSession,
      setCurrentUser,
      updateUserCommPrefs,
      updateUserFavStores,
      updateUserInfo,
      user,
      isAuthenticated: !!currentUserSession,
      currentUserSession,
      setCurrentUserSession,
      userLoading: loading,
      useUpdateMeMutationLoading,
    }),
    [
      refreshCurrentUser,
      refreshCurrentUserWithNewSession,
      setCurrentUser,
      updateUserCommPrefs,
      updateUserFavStores,
      updateUserInfo,
      user,
      currentUserSession,
      setCurrentUserSession,
      loading,
      useUpdateMeMutationLoading,
    ]
  );
};
