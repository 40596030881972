import React, { useState } from 'react';

import { Header, ScrollView } from '@rbilabs/universal-components';
import { useIntl } from 'react-intl';

import Modal, { ModalContent, ModalSize } from 'components/modal';
import { useFeatureDisclaimers } from 'hooks/use-feature-disclaimers';
import { useFlag } from 'state/launchdarkly';
import { WEB_APP_MODAL_CONTENT_MAX_WIDTH } from 'utils/constants';
import { LaunchDarklyFlag } from 'utils/launchdarkly';

import { ItemNutrition } from './item-nutrition';
import { NutritionModalDisclaimer } from './nutrition-modal-disclaimer';
import { INutritionProps } from './types';

const Nutrition: React.FC<React.PropsWithChildren<INutritionProps>> = ({
  nutritionFacts,
  activatingElement,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { formatMessage } = useIntl();
  const { featureDisclaimers } = useFeatureDisclaimers();
  const { calorie } = featureDisclaimers || {};

  const enableFeatureNutritionIntegration = useFlag(
    LaunchDarklyFlag.ENABLE_FEATURE_NUTRITION_INTEGRATION
  );
  const enableStaticMenuAllergens = useFlag(LaunchDarklyFlag.ENABLE_STATIC_MENU_ALLERGENS);

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  const modalHeading = formatMessage({ id: 'nutritionalInformation' });

  return (
    <>
      {React.cloneElement(activatingElement, { onPress: openModal })}
      {isModalOpen && (
        <Modal
          size={ModalSize.FULLSCREEN}
          onDismiss={closeModal}
          mParticleEventData={{
            modalAppearanceEventMessage: 'Nutritional Information',
          }}
          testID="nutrition-modal"
          backgroundColor={Styles.color.background}
        >
          <ModalContent alignItems="center">
            <ScrollView showsVerticalScrollIndicator={false} maxW={WEB_APP_MODAL_CONTENT_MAX_WIDTH}>
              <Header variant="headerTwo" alignSelf="center" fontSize="xl" marginTop="$12">
                {modalHeading}
              </Header>
              {nutritionFacts
                .filter(
                  elem =>
                    elem.itemName?.toLowerCase() !== 'combo item' &&
                    elem.itemName?.toLowerCase() !== 'mix n match'
                )
                .map(
                  ({ itemName, itemImage, facts, allergenGroups, additionalItemInformation }) => (
                    <ItemNutrition
                      key={itemName}
                      allergenGroups={allergenGroups}
                      itemImage={itemImage}
                      itemName={itemName}
                      nutritionInfo={facts}
                      enableFeatureNutritionIntegration={enableFeatureNutritionIntegration}
                      enableStaticMenuAllergens={enableStaticMenuAllergens}
                      additionalItemInformation={additionalItemInformation}
                    />
                  )
                )}
              <NutritionModalDisclaimer content={calorie?.localeRaw} />
            </ScrollView>
          </ModalContent>
        </Modal>
      )}
    </>
  );
};

export default Nutrition;
