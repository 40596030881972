import * as React from 'react';
import { useCallback, useMemo, useState } from 'react';

import { useCmsQuestDetailLazyQuery } from './cms-hooks/use-cms-quest-detail';
import { OPEN_DETAILS_ON_PRESS_IMAGE } from './constants';
import { QuestProvider } from './loyalty-quest';
import { useActivateQuest } from './loyalty-quest/use-activate-quest';
import { canActivateQuest } from './loyalty-quest/utils';
import { QuestCard } from './quest-card';
import { QuestDetailModal } from './quest-detail-modal';
import { QuestDrawerContent } from './quest-drawer-content';
import { QuestDetailError } from './quest-drawer-content/quest-detail-error';
import { QuestDrawerSkeleton } from './quest-drawer-content/quest-detail-skeleton';
import { QuestTerms } from './quest-drawer-content/quest-terms';
import type { IQuestCardWrapperProps } from './types';
import { useParseQuestCardData } from './use-parse-quest-card-data';
import { getProductRequirementCmsIds } from './utils';

export const QuestCardWrapper = ({
  cmsQuestCard,
  quest,
  cmsQuestCardUI,
}: IQuestCardWrapperProps) => {
  const {
    image,
    imageDescription,
    title,
    description,
    buttonText,
    linkText,
    badgeText,
    reversedBadge,
  } = useParseQuestCardData(quest, cmsQuestCard, cmsQuestCardUI);
  const [showQuestTerms, setShowQuestTerms] = useState<boolean>(false);
  const [openQuestDetailDrawer, setOpenQuestDetailDrawer] = useState<boolean>(false);
  const [
    activateQuest,
    { loading: loadingActivateQuestMutation, error: activateQuestError },
  ] = useActivateQuest({
    questId: quest.id,
  });
  const questRequirementCmsIds = useMemo(() => getProductRequirementCmsIds(quest), [quest]);
  const [
    cmsQuestDetailQuery,
    {
      loadingCmsQuestDetail,
      cmsQuestDetail,
      cmsQuestDetailUI,
      refetch: refetchCmsQuestDetail,
      error: cmsQuestDetailError,
    },
  ] = useCmsQuestDetailLazyQuery({ cmsId: quest.cmsId, questRequirementCmsIds });
  const questDetailError = activateQuestError || cmsQuestDetailError;

  const callToActionCard = useCallback(() => {
    setOpenQuestDetailDrawer(true);

    if (canActivateQuest(quest.status)) {
      activateQuest();
    }
    cmsQuestDetailQuery();
  }, [activateQuest, cmsQuestDetailQuery, quest.status]);

  const onPressLinkAndImageHandler = useCallback(() => {
    setOpenQuestDetailDrawer(true);

    cmsQuestDetailQuery();
  }, [cmsQuestDetailQuery]);

  const onPressLinkHandler = useCallback(() => {
    setOpenQuestDetailDrawer(true);

    setShowQuestTerms(true);
  }, []);

  const onCloseDrawer = useCallback(() => {
    // restart state
    setOpenQuestDetailDrawer(false);
    setShowQuestTerms(false);
  }, []);

  const onRefetch = useCallback(() => {
    if (canActivateQuest(quest.status)) {
      activateQuest();
    }

    refetchCmsQuestDetail();
  }, [activateQuest, quest.status, refetchCmsQuestDetail]);

  const loadingQuestDetail = loadingCmsQuestDetail || loadingActivateQuestMutation;

  return (
    <>
      <QuestCard
        image={image}
        altText={imageDescription}
        title={title}
        description={description}
        callToAction={callToActionCard}
        callToActionText={buttonText}
        linkText={linkText}
        badgeText={badgeText}
        onPressImage={OPEN_DETAILS_ON_PRESS_IMAGE ? onPressLinkAndImageHandler : undefined}
        onPressLink={onPressLinkHandler}
        reversedBadge={reversedBadge}
      />

      {openQuestDetailDrawer && (
        <QuestDetailModal
          title={title}
          onDismiss={onCloseDrawer}
          hideTitle={showQuestTerms || !!questDetailError}
        >
          {showQuestTerms ? (
            <QuestTerms cmsQuestTerms={cmsQuestCard.moreInfo} />
          ) : (
            <>
              {questDetailError ? (
                <QuestDetailError refetch={onRefetch} />
              ) : (
                <QuestProvider quest={quest}>
                  {loadingQuestDetail && <QuestDrawerSkeleton />}
                  {!loadingQuestDetail && cmsQuestDetail && cmsQuestDetailUI && (
                    <QuestDrawerContent
                      cmsQuestDetail={cmsQuestDetail}
                      cmsQuestDetailUI={cmsQuestDetailUI}
                      onCloseDrawer={onCloseDrawer} // TODO - Remove this after replacing modal with RN Drawer/Page
                    />
                  )}
                </QuestProvider>
              )}
            </>
          )}
        </QuestDetailModal>
      )}
    </>
  );
};
