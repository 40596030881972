import React, { FC } from 'react';

import { Box } from '@rbilabs/universal-components';
import { useIntl } from 'react-intl';

import defaultConfig from './constants';
import { StyledActionButton } from './locked-offers-card.styled';
import { IButtonLabelProps } from './types';

export const ButtonLabel: FC<React.PropsWithChildren<IButtonLabelProps>> = ({
  progressData = [],
}) => {
  const { formatMessage } = useIntl();
  const isRedeemable = progressData.filter(step => !step.isComplete);

  const message = isRedeemable.length ? 'details' : 'redeemNow';
  return (
    <Box width={defaultConfig.buttonWidth}>
      <StyledActionButton>{formatMessage({ id: message })}</StyledActionButton>
    </Box>
  );
};
