import { getActionFromState, getStateFromPath } from '@react-navigation/native';
import { NavigationAction } from '@react-navigation/routers';

import { config } from 'navigation/linking';

import { navigationRef } from './navigation-container-ref';

// If navigate fails (not found route), navigate directly to static page
export const handleUnhandledAction = (action: NavigationAction) => {
  const payload = action.payload as Record<string, string>;

  if (action.type === 'NAVIGATE' && payload?.name && navigationRef.current) {
    // @ts-expect-error TS(2345) FIXME: Argument of type '{ screens: { [x: string]: string... Remove this comment to see the full error message
    const stateFromPath = getStateFromPath(payload.name, config);
    const actionFromState = stateFromPath ? getActionFromState(stateFromPath) : null;
    if (actionFromState) {
      navigationRef.current.dispatch(actionFromState);
    }
  }
};
