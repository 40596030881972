import React from 'react';

import { RunningExperiments, useExperiments } from 'state/experiments';

import { Props, ServiceModeExperimentVariants } from './types';

export function DisableTopServiceMode__Experiment({ children }: Props) {
  const { variant } = useExperiments();

  if (!variant(RunningExperiments.ServiceMode, ServiceModeExperimentVariants.SHOW_TSM)) {
    return null;
  }

  return children ? <>{children}</> : null;
}
