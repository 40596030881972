import React, { useMemo } from 'react';

import { Box } from '@rbilabs/universal-components';
import { FlatList, RefreshControl } from 'react-native';

import { IOffer } from '@rbi-ctg/menu';
import { Tile } from 'components/features/components/marketing-tile-group/marketing-tile-group';
import LoadingAnimation from 'components/loading-animation';
import { HorizontalPosition, TileSize } from 'components/marketing-tile';
import { OfferCell } from 'components/offer/ui-refresh/cell';
import { useIsMobileBp } from 'hooks/breakpoints';
import { useOffersPageMarketingTiles } from 'hooks/use-combine-offers-and-marketing-tiles';
import { ITilesAndOffers } from 'hooks/use-combine-offers-and-marketing-tiles/types';
import { LoyaltyOffersCooldown } from 'pages/loyalty/loyalty-offers/loyalty-offers-cooldown';
import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';
import { useOffersContext } from 'state/offers';
import { useOrderContext } from 'state/order';
import { useStoreContext } from 'state/store';
import { getUniqIdForOffer, sortOffersForSelectedServiceMode } from 'utils/offers';

import { EndOfOffers } from './end-of-offers';
import { OfferSelected } from './offer-selected';
import { OffersEmptyState } from './offers-empty-state';
import { useOffersUIContext } from './offers-ui-context';
import { SelectRestaurantCta } from './select-restaurant-cta';

const LoadingAnimationContainer = Box.withConfig({
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%',
  flex: 1,
  padding: '12',
});

const Wrapper = Box.withConfig({
  flex: 1,
  paddingY: '$4',
  paddingX: { base: '$4', lg: '$0' },
});

export const BrowsingPanel: React.FC<React.PropsWithChildren<unknown>> = () => {
  const enableSortOffersForServiceMode = useFlag(
    LaunchDarklyFlag.ENABLE_SORT_OFFERS_FOR_SERVICE_MODE
  );
  const hideOffersForServiceMode = useFlag(LaunchDarklyFlag.ENABLE_HIDE_OFFERS_FOR_SERVICE_MODE);
  const { noStoreSelected } = useStoreContext();
  const { selectedOffer, clearSelectedOffer } = useOffersContext();
  const {
    featureOffers,
    loading,
    showEmptyState,
    offers,
    onSelectOffer,
    previewOfferId,
    refreshOffers,
    offersLoading,
  } = useOffersUIContext();
  const { serviceMode } = useOrderContext();
  const isMobile = useIsMobileBp();
  const enableSelectRestaurantCta = useFlag(LaunchDarklyFlag.ENABLE_OFFERS_SELECT_RESTAURANT_CTA);
  const { offersBrowsingPanel = null, offersPageMarketingTiles = [] } = featureOffers || {};

  const filterSelectedOffer = (offer: IOffer) => getUniqIdForOffer(offer) !== selectedOfferUniqueId;

  const minOffers = offersBrowsingPanel?.offersSelectRestaurantCta?.minimumNumberOfOffers ?? null;
  const endOfOffersImage = offersBrowsingPanel?.endOfOffersImage ?? null;
  const endOfOffersMessage = offersBrowsingPanel?.endOfOffersMessage ?? null;

  const selectedOfferUniqueId = selectedOffer && getUniqIdForOffer(selectedOffer);

  // sort offers by SM based on LD flag & if user has selected a SM
  const shouldSortOffersForServiceMode =
    (enableSortOffersForServiceMode || hideOffersForServiceMode) && !!serviceMode;
  const offersToDisplay = shouldSortOffersForServiceMode
    ? sortOffersForSelectedServiceMode(offers, serviceMode, hideOffersForServiceMode)
    : offers;

  // True when offers with no restaurant selection is less than min offers specified in sanity.
  // will hide offers and show CTA card prompting restaurant selection to unlock restaurant restricted offers.
  const availableOffersLessThanMinimum =
    enableSelectRestaurantCta && noStoreSelected && minOffers && offers.length < minOffers;

  const {
    sortedTilesAndOffers,
    marketingTileTop,
    marketingTileBottom,
  } = useOffersPageMarketingTiles({
    offers: offersToDisplay,
    offersPageMarketingTiles,
    selectedOffer,
  });

  // @ts-expect-error TS(2769) FIXME: No overload matches this call.
  const offersAndTiles = sortedTilesAndOffers.filter(filterSelectedOffer);
  const listData: ITilesAndOffers = useMemo(() => {
    let offersData: ITilesAndOffers = [];

    // Add top marketing tile (if any) in beginning
    if (marketingTileTop) {
      offersData.push(marketingTileTop);
    }
    // Add selected offer (if any) next
    if (selectedOffer) {
      offersData.push(selectedOffer);
    }
    if (!availableOffersLessThanMinimum && !!offersAndTiles.length) {
      offersData = offersData.concat(offersAndTiles);
    }
    // Add bottom marketing tile (if any) to the end
    if (marketingTileBottom) {
      offersData.push(marketingTileBottom);
    }

    return offersData;
  }, [
    marketingTileTop,
    selectedOffer,
    availableOffersLessThanMinimum,
    offersAndTiles,
    marketingTileBottom,
  ]);
  const flastListData = useMemo(
    () => [null /* space in array to render LoyaltyOffersCooldown */, ...listData],
    [listData]
  );

  const renderCooldownOffersAndTiles = ({ item, index }: { item: any; index: number }) => {
    if (index === 0) {
      return <LoyaltyOffersCooldown />;
    }
    return renderOffersAndTiles({ item, index: index - 1 });
  };
  const renderOffersAndTiles = ({ item, index }: { item: any; index: number }) => {
    const isOffer = item?._type === 'offer';
    const isTopMarketingTile = index === 0 && marketingTileTop;
    const isBottomMarketingTile = index === listData.length && marketingTileBottom;
    const selectedOfferIndex = marketingTileTop && selectedOffer ? 1 : 0;
    const isSelectedOfferItem = index === selectedOfferIndex && selectedOffer;
    const testID = isTopMarketingTile
      ? 'top-marketing-tile'
      : isBottomMarketingTile
      ? 'bottom-marketing-tile'
      : undefined;

    if (isOffer) {
      return (
        <OfferCell
          offer={item}
          onPress={onSelectOffer}
          isSelected={selectedOffer?._id === item._id}
          onRemove={() => clearSelectedOffer({ doLog: true, popToast: true })}
        />
      );
    }

    if (isSelectedOfferItem) {
      return (
        <OfferSelected
          offer={item}
          onRemoveSelectedOffer={() => {
            clearSelectedOffer({ doLog: true, popToast: true });
          }}
          onPress={onSelectOffer}
          isSelected={!isMobile && previewOfferId === item._id}
        />
      );
    }

    return (
      <Tile
        // @ts-expect-error TS(2322) FIXME: Type '{ testID: string | undefined; tile: any; siz... Remove this comment to see the full error message
        testID={testID}
        tile={item}
        size={TileSize.HALF}
        buttonPosition={HorizontalPosition.RIGHT}
        hasBorder={false}
        additionalPadding={`8px`}
      />
    );
  };

  if (loading && !offers.length) {
    return (
      <LoadingAnimationContainer testID="browsing-panel-loading-animation">
        <LoadingAnimation />
      </LoadingAnimationContainer>
    );
  }

  if (showEmptyState) {
    return <OffersEmptyState />;
  }

  //TODO: RN - enable pull to reload on web
  return (
    <Wrapper testID="browsing-panel">
      <FlatList
        data={flastListData}
        renderItem={renderCooldownOffersAndTiles}
        refreshControl={<RefreshControl refreshing={offersLoading} onRefresh={refreshOffers} />}
        keyExtractor={(item: any, index: number) => `${item?._id}-${index}`}
        onRefresh={refreshOffers}
        refreshing={loading}
        ListFooterComponent={
          <>
            {endOfOffersMessage && (
              <EndOfOffers message={endOfOffersMessage} image={endOfOffersImage} />
            )}
            {enableSelectRestaurantCta && <SelectRestaurantCta />}
          </>
        }
      />
    </Wrapper>
  );
};
