import React from 'react';

import { Box, Skeleton } from '@rbilabs/universal-components';

export const StaticPageSkeleton = () => {
  return (
    <Box p="$6" width="full">
      <Skeleton h="$48" mb="$3" rounded="12" mx="auto" />
      <Skeleton h="$24" mb="$3" rounded="12" mx="auto" />
      <Skeleton h="$24" mb="$3" rounded="12" mx="auto" />
      <Skeleton h="$24" mb="$3" rounded="12" mx="auto" />
      <Skeleton.Text mb="$4" />
      <Skeleton.Text mb="$4" />
      <Skeleton.Text mb="$4" />
      <Skeleton.Text mb="$4" />
      <Skeleton.Text mb="$4" />
      <Skeleton.Text mb="$4" />
    </Box>
  );
};
