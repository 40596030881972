import React, { useLayoutEffect, useMemo } from 'react';

import { IItem } from '@rbi-ctg/menu';
import { ScreenScrollView } from 'components/layout/screen-scroll-view';
import { ProductOverview } from 'components/product-detail/product-overview';
import { useIsTabletBp } from 'hooks/breakpoints';
import { useScrollRestoration } from 'hooks/use-scroll-restoration';
import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';
import { useMenuContext } from 'state/menu';
import { useProductWizardContext } from 'state/product-wizard';
import { useProductWizardFlow } from 'state/product-wizard/hooks/use-product-wizard-flow';
import { ProductWizardStep } from 'state/product-wizard/types';
import { useScrollContext } from 'state/scroll';
import { isWeb } from 'utils/environment';

import { NutritionInformation } from '../nutrition-information';
import { ProductHomeCta, ProductStaticMenuCta } from '../product-cta';
import { ProductCustomization } from '../product-customization';
import { ProductHome } from '../product-home';

import { ProductCustomizationContainer } from './product-customization-container';

export interface IProductDetailLayoutProps {
  currentStep: ProductWizardStep;
  showStaticMenu: boolean;
}

export const ProductLayout: React.FC<React.PropsWithChildren<unknown>> = () => {
  const { showStaticMenu } = useMenuContext();
  const { currentStep, slotKeyToCustomize } = useProductWizardFlow();
  const { userSelections } = useProductWizardContext();
  const { scrollRef, scrollTo } = useScrollContext();
  const useEnableShowPickerAspectsInStaticMenu = useFlag(
    LaunchDarklyFlag.ENABLE_SHOW_PICKER_ASPECTS_IN_STATIC_MENU
  );

  const scrollProps = useScrollRestoration(String(currentStep));

  const showStaticPickers = showStaticMenu && useEnableShowPickerAspectsInStaticMenu;

  const itemToCustomize = useMemo<IItem | null>(
    () => userSelections.comboSlot?.get(slotKeyToCustomize)?.selectedItem || null,
    [slotKeyToCustomize, userSelections.comboSlot]
  );

  const isTablet = useIsTabletBp();
  const showCustomization =
    !!itemToCustomize && currentStep === ProductWizardStep.ProductCustomization;
  const showHome = !showCustomization || isTablet;

  useLayoutEffect(() => {
    if (isWeb && scrollProps.contentOffset) {
      scrollTo({
        y: scrollProps.contentOffset?.y,
        animated: false,
      });
    }
  }, [scrollProps.contentOffset, scrollTo]);

  return (
    <>
      {showCustomization ? (
        <ProductCustomizationContainer>
          <ProductCustomization itemToCustomize={itemToCustomize} />
        </ProductCustomizationContainer>
      ) : null}
      {showHome ? (
        <>
          <ScreenScrollView ref={scrollRef} {...scrollProps}>
            <ProductOverview />
            {(!showStaticMenu || showStaticPickers) && (
              <ProductHome showStaticPickers={showStaticPickers} />
            )}
            <NutritionInformation />
          </ScreenScrollView>
          {showStaticMenu || showStaticPickers ? (
            <ProductStaticMenuCta showStaticPickers={showStaticPickers} />
          ) : (
            <ProductHomeCta />
          )}
        </>
      ) : null}
    </>
  );
};
