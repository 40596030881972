import React, { FC } from 'react';

import { IItem, IItemOption, IPickerItem, ISanityItemOption } from '@rbi-ctg/menu';
import { MenuObjectTypes } from 'enums/menu';
import { getItemCalories } from 'utils/menu';

import { Modifiers } from '../modifiers';
import { QuickConfigs } from '../quick-configs';
import { SimpleWizardItem } from '../simple-wizard-item';

import { IMenuItemCustomizationProps } from './types';

const MenuItemCustomization: FC<React.PropsWithChildren<IMenuItemCustomizationProps>> = ({
  menuItem,
  excludesForPickerAspects = [],
  modifierSelections = [],
  handleModifierSelection,
  handleModifierQuantityUpdate,
  quickConfigs = [],
  showModifiers = true,
}) => {
  let item: IItem | null = null;
  let showMainItem = false;
  let mainItemCalories = 0;
  let modifiersUnderMainItem = false;
  let modifiers: (ISanityItemOption | IItemOption)[] = [];
  let radioGroupParentId = '';

  if (menuItem) {
    if (menuItem._type === MenuObjectTypes.ITEM) {
      item = menuItem;
      modifiers = menuItem.options || [];
      radioGroupParentId = menuItem._id;
    } else {
      item = menuItem.mainItem;
      showMainItem = !menuItem.hideMainItemDescription;
      mainItemCalories = item ? getItemCalories(item) : 0;
      modifiersUnderMainItem = !menuItem.forceModifiersToBottom;
      modifiers = item ? item.options : [];
      radioGroupParentId = item ? item._id : '';
    }
  }

  const modifiersElem = showModifiers && (
    <Modifiers
      item={item}
      quickConfigs={quickConfigs}
      modifiers={modifiers}
      excludesForPickerAspects={excludesForPickerAspects}
      radioGroupParentId={radioGroupParentId}
      modifierSelections={modifierSelections}
      handleModifierSelection={handleModifierSelection}
      handleModifierQuantityUpdate={handleModifierQuantityUpdate}
    />
  );

  return (
    <>
      {showMainItem && item && (
        <SimpleWizardItem title={item.name?.locale} calories={mainItemCalories} />
      )}

      <QuickConfigs
        handleModifierSelection={handleModifierSelection}
        modifierSelections={modifierSelections}
        pickerSelection={(menuItem as unknown) as IPickerItem}
        quickConfigs={quickConfigs}
      />

      {modifiersUnderMainItem && modifiersElem}

      {!modifiersUnderMainItem && modifiersElem}
    </>
  );
};

export default MenuItemCustomization;
