import { ReactNode } from 'react';

import addHours from 'date-fns/fp/addHours';
import addSeconds from 'date-fns/fp/addSeconds';
import { isNull } from 'lodash-es';
import { compose } from 'utils';

import { SupportedLanguages } from '@rbi-ctg/frontend';
import { IEvaluationFeedback } from '@rbi-ctg/offers';
import { format, formatRelative } from 'utils/language/format-date';

import { RuleType } from './types';

const ruleTypeStaticKeyMapping = {
  [RuleType.BetweenDates]: 'CannotRedeemNow',
  [RuleType.BetweenTimes]: 'CannotRedeemNow',
  [RuleType.CartProperty]: 'CartPropertyViolation',
  [RuleType.CoolDown]: 'CannotRedeemNow',
  [RuleType.DayOfWeekBand]: 'DayOfWeekBandViolation',
  [RuleType.FirstOrderOnly]: 'FirstOrderOnlyViolation',
  [RuleType.Limit]: 'CannotRedeemAgain',
  [RuleType.Purchase]: 'PurchaseViolation',
  [RuleType.RequiresAssignment]: 'RequiresAssignmentViolation',
};

const redeemableAgainInKey = 'offerRedeemableAgainIn';
const redeemableInKey = 'offerRedeemableIn';
const redeemableOnKey = 'offerRedeemableOn';

function timezoneOffsetToHours(timezoneOffset: number): number {
  return timezoneOffset / 60;
}

const addTimezoneOffset = compose<[number], number, (now: Date) => Date>(
  addHours,
  timezoneOffsetToHours
);

const getLocalRedeemableTime = (now: Date, redeemableIn: number) =>
  compose(addSeconds(redeemableIn), addTimezoneOffset(now.getTimezoneOffset()))(now);

function formatRedeemableIn(
  feedbackEntry: IEvaluationFeedback,
  formatMessage: Function,
  locale: SupportedLanguages
): ReactNode {
  const { redeemableInSeconds, ruleSetType } = feedbackEntry;

  const redeemableAgain =
    (!isNull(redeemableInSeconds) && ruleSetType === RuleType.Limit) ||
    ruleSetType === RuleType.CoolDown;

  if (!isNull(redeemableInSeconds)) {
    const now = new Date();
    const localRedeemableTime = getLocalRedeemableTime(now, redeemableInSeconds);

    // display the next day of the week this coupon
    // can be redeemed on
    if (ruleSetType === RuleType.DayOfWeekBand) {
      return formatMessage(
        {
          id: redeemableOnKey,
        },
        {
          redeemableOn: format(localRedeemableTime, 'EEEE', locale),
        }
      );
    }

    return formatMessage(
      {
        id: redeemableAgain ? redeemableAgainInKey : redeemableInKey,
      },
      {
        redeemableIn: formatRelative(localRedeemableTime, now, locale),
      }
    );
  }

  return '';
}

function messageForFeedbackEntry(
  feedbackEntry: IEvaluationFeedback,
  formatMessage: Function,
  locale: SupportedLanguages = 'en'
) {
  const redeemableInString = formatRedeemableIn(feedbackEntry, formatMessage, locale);

  const messageKey = ruleTypeStaticKeyMapping[feedbackEntry.ruleSetType];

  const localizedMessage = messageKey
    ? formatMessage(
        {
          id: `ruleSets${messageKey}`,
        },
        {
          redeemableInString,
        }
      )
    : '';

  return localizedMessage;
}

export function reduceFeedbackToErrorMessages(
  evaluationFeedback: IEvaluationFeedback[],
  formatMessage: Function,
  language: SupportedLanguages
) {
  const { errors: feedbackErrors } = evaluationFeedback.reduce<{
    errors: ReactNode[];
    violationCodes: string[];
  }>(
    ({ errors, violationCodes }, feedbackEntry) => {
      const { code, condition } = feedbackEntry;

      // condition true means the rule was satisfied
      // or otherwise would not prevent redemption
      // check code to ensure we do not show
      // duplicate messages
      if (condition || violationCodes.includes(code)) {
        return { errors, violationCodes };
      }

      return {
        errors: errors.concat(messageForFeedbackEntry(feedbackEntry, formatMessage, language)),
        violationCodes: violationCodes.concat(code),
      };
    },
    { errors: [], violationCodes: [] }
  );

  return feedbackErrors;
}

export default reduceFeedbackToErrorMessages;
