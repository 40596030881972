import { RewardsNavigator } from 'navigation/rewards-navigator';
import { routes } from 'utils/routing';

import type { RawTab } from '../../types';

export const id: RawTab['id'] = 'rewards';

export const screen: RawTab['screen'] = {
  Component: RewardsNavigator,
};

export const text: RawTab['text'] = {
  en: 'Rewards',
  fr: 'Récompenses',
};

export const link: RawTab['link'] = {
  en: routes.loyaltyDashboard,
  fr: routes.loyaltyDashboard,
};
