import { Header } from '@rbilabs/universal-components';

const HeroHeading = Header.withConfig({
  variant: 'headerOne',
  color: Styles.color.white,
  fontFamily: 'heading',
  fontSize: {
    base: '6xl',
    md: '8xl',
  },
  letterSpacing: '-2px',
  lineHeight: 60,
  margin: 0,
  marginBottom: {
    base: 0,
    md: '40px',
  },
  textAlign: {
    base: 'center',
    md: 'left',
  },
  textTransform: 'none',
  width: '100%',
});

export default HeroHeading;
