import React, { createContext, useCallback, useContext, useEffect, useState } from 'react';

import { useIntl } from 'react-intl';
import { Alert } from 'react-native';

import { IBaseProps, Nullable, SetState } from '@rbi-ctg/frontend';
import { useRoute } from 'hooks/navigation/use-route';
// TODO: This is the original DeliveryFeeSurchargeDialog, we need to use this once the useDialogModal
// renders ok within a modal screen (after GST-5015 is done)
// import useDialogModal from 'hooks/use-dialog-modal';
import { useLocalStorageState } from 'hooks/use-local-storage-state';
import { useCRMEventsContext } from 'state/crm-events';
import { serializePickupMode, serializeServiceMode } from 'state/crm-events/utils';
import { LaunchDarklyFlag, useFlag, useLDContext } from 'state/launchdarkly';
import { useUIContext } from 'state/ui';
import LocalStorage, { StorageKeys } from 'utils/local-storage';
import noop from 'utils/noop';
import {
  isCateringDelivery as checkIfItsCateringDelivery,
  isCateringPickup as checkIfItsCateringPickup,
} from 'utils/service-mode';

import { ServiceMode } from './types';

export { ServiceMode };

const getServiceModeFromLS = (): ServiceMode | null => {
  const preloaded = LocalStorage.getItem(StorageKeys.SERVICE_MODE);
  return preloaded ? preloaded.serviceMode : null;
};

const persistServiceMode = (serviceMode: Nullable<ServiceMode>) => {
  LocalStorage.setItem(StorageKeys.SERVICE_MODE, { serviceMode });
};

export interface IServiceModeCtx {
  serviceMode: Nullable<ServiceMode>;
  setServiceMode: SetState<ServiceMode | null>;
  setDeliverySurchargeFee: SetState<number | null>;
  isDelivery: boolean;
  isTakeout: boolean;
  isEatIn: boolean;
  isCurbside: boolean;
  isCatering: boolean;
}

export const ServiceModeContext = createContext<IServiceModeCtx>({
  serviceMode: null,
  setServiceMode: noop,
  setDeliverySurchargeFee: noop,
  isDelivery: false,
  isTakeout: false,
  isEatIn: false,
  isCurbside: false,
  isCatering: false,
});

export const useServiceModeContext = () => useContext(ServiceModeContext);

export const ServiceModeProvider = ({ children }: IBaseProps) => {
  const { formatMessage } = useIntl();
  const { formatCurrencyForLocale: currencyFormatter } = useUIContext();
  const { params } = useRoute<{ 'service-mode': string }>();
  const serviceModeInRoute = ServiceMode[params['service-mode']] ?? '';

  const [serviceMode, setServiceMode] = useState<ServiceMode | null>(
    serviceModeInRoute || getServiceModeFromLS()
  );
  const enableDeliveryOnly = useFlag(LaunchDarklyFlag.ENABLE_DELIVERY_ONLY);
  const enableDeliveryFeeSurchargeModal = Boolean(
    useFlag(LaunchDarklyFlag.SHOW_INCREASED_DELIVERY_FEES_MODAL)
  );

  const isDelivery = serviceMode === ServiceMode.DELIVERY;
  const isTakeout = serviceMode === ServiceMode.TAKEOUT;
  const isEatIn = serviceMode === ServiceMode.EAT_IN;
  const isCurbside = serviceMode === ServiceMode.CURBSIDE;
  const isCateringDelivery = checkIfItsCateringDelivery(serviceMode);
  const isCateringPickup = checkIfItsCateringPickup(serviceMode);
  const isCatering = isCateringDelivery || isCateringPickup;
  const { updateServiceMode } = useLDContext();
  const { updateUniversalAttributes } = useCRMEventsContext();

  const [
    deliverySurchargeFee,
    setDeliverySurchargeFee,
    clearDeliverySurchargeFee,
  ] = useLocalStorageState<number | null>({
    key: StorageKeys.DELIVERY_SURCHARGE_FEE,
    defaultReturnValue: null,
  });

  // TODO: This is the original DeliveryFeeSurchargeDialog, we need to use this once the useDialogModal
  // renders ok within a modal screen (after GST-5015 is done)
  //
  // const [DeliveryFeeSurchargeDialog, openDeliveryFeeSurcharge] = useDialogModal({
  //   onConfirm: acceptDeliverySurchargeFee,
  //   allowDismiss: false,
  //   modalAppearanceEventMessage: 'Delivery Surcharge',
  // });

  const showDeliveryFeeSurchargeModal = enableDeliveryFeeSurchargeModal && !!deliverySurchargeFee;

  const acceptDeliverySurchargeFee = useCallback(() => {
    clearDeliverySurchargeFee();
  }, [clearDeliverySurchargeFee]);

  const openDeliveryFeeSurcharge = useCallback(() => {
    // TODO: Change this native dialog with DeliveryFeeSurchargeDialog once GST-5015 is done
    Alert.alert(
      formatMessage({ id: 'deliverySurcharge' }),
      formatMessage(
        { id: 'deliverySurchargeMessage' },
        {
          feeSurcharge: currencyFormatter(deliverySurchargeFee || 0),
        }
      ),
      [
        {
          text: formatMessage({ id: 'okay' }),
          onPress: () => acceptDeliverySurchargeFee(),
        },
      ]
    );
  }, [formatMessage, currencyFormatter, deliverySurchargeFee, acceptDeliverySurchargeFee]);

  useEffect(() => {
    if (showDeliveryFeeSurchargeModal) {
      openDeliveryFeeSurcharge();
    }
  }, [openDeliveryFeeSurcharge, showDeliveryFeeSurchargeModal]);

  useEffect(() => {
    persistServiceMode(serviceMode);
    // Updates service/pickup modes for all mParticle events/page views
    updateUniversalAttributes({
      'Service Mode': serializeServiceMode(serviceMode),
      'Pickup Mode': serializePickupMode(serviceMode),
    });
    // update LaunchDarkly
    updateServiceMode(serviceMode);
  }, [serviceMode, updateServiceMode, updateUniversalAttributes]);

  useEffect(() => {
    // If the service mode isn't delivery and the only service mode available is delivery, wipe out the current service mode
    if (enableDeliveryOnly && !isDelivery) {
      setServiceMode(null);
    }
  }, [enableDeliveryOnly, isDelivery, serviceMode]); // Needed to be checked every time serviceMode is being changed

  useEffect(() => {
    if (serviceModeInRoute && serviceModeInRoute !== serviceMode) {
      setServiceMode(serviceModeInRoute);
    }
  }, [serviceMode, serviceModeInRoute]);
  return (
    <ServiceModeContext.Provider
      value={{
        serviceMode,
        setServiceMode,
        setDeliverySurchargeFee,
        isDelivery,
        isTakeout,
        isEatIn,
        isCurbside,
        isCatering,
      }}
    >
      {/* 
      TODO: This is the original DeliveryFeeSurchargeDialog, we need to use this once the useDialogModal 
      renders ok within a modal screen (after GST-5015 is done)
      
      {enableDeliveryFeeSurchargeModal && deliverySurchargeFee && (
        <DeliveryFeeSurchargeDialog
          heading={formatMessage({ id: 'deliverySurcharge' })}
          body={formatMessage(
            { id: 'deliverySurchargeMessage' },
            {
              feeSurcharge: currencyFormatter(deliverySurchargeFee),
            }
          )}
        />
      )} */}

      {children}
    </ServiceModeContext.Provider>
  );
};
