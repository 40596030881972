import React, { useCallback } from 'react';

import { addWithConfig } from '@rbilabs/universal-components';
import { View } from 'react-native';

import {
  ClickEventComponentNames,
  CustomEventNames,
  EventTypes,
  useCRMEventsContext,
} from 'state/crm-events';
import { useMatch } from 'state/location';

import StyledLink from './styled-link';

type INavLinkProps = React.ComponentProps<typeof StyledLink> & {
  to: string;
  hidden?: boolean;
  requiresExactMatch?: boolean;
  onClick?: (to: string, isActive: boolean) => void;
  component?(isActive: boolean): React.ReactNode;
  navBarText?: string;
  componentKey?: string;
};

const MemoizedNavLink = React.memo(function MemoizedNavLink({
  to,
  isActive,
  component,
  children,
  testID: dataQaId,
  onClick,
  hidden,
  componentKey,
  navBarText = '',
  ...rest
}: INavLinkProps & {
  isActive: boolean;
}) {
  const { logRBIEvent } = useCRMEventsContext();

  const onClickAndLogToMParticle = useCallback(() => {
    if (navBarText !== '') {
      logRBIEvent({
        name: CustomEventNames.CLICK_EVENT,
        type: EventTypes.Other,
        attributes: {
          component: ClickEventComponentNames.NAV_BAR,
          text: navBarText,
          componentId: componentKey,
        },
      });
    }

    if (onClick) {
      onClick(to, isActive);
    }
  }, [componentKey, navBarText, logRBIEvent, to, onClick, isActive]);

  return (
    <StyledLink to={to} onPress={onClickAndLogToMParticle} testID={dataQaId} {...rest}>
      <View focusable={hidden ? false : undefined}>
        {component ? component(isActive) : children}
      </View>
    </StyledLink>
  );
});

export const NavLink = addWithConfig(({ requiresExactMatch, to, ...restProps }: INavLinkProps) => {
  const pathToMatch = requiresExactMatch ? to : `${to}/*`;
  const isActive = !!useMatch(pathToMatch);
  return <MemoizedNavLink isActive={isActive} to={to} {...restProps} />;
});

export const iconSize = 25;
