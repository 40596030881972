import React from 'react';

import ActionButton, { ActionButtonVariants } from 'components/action-button';

import { AlertBody, AlertHeader, HomepageAlertContainer, Section } from './styled/styled';

interface IAlert {
  headline: string;
  body: string;
  learnMore: string;
  learnMoreUrl: string;
}

const Alert: React.FC<React.PropsWithChildren<IAlert>> = ({
  headline,
  body,
  learnMore,
  learnMoreUrl,
}) => (
  <Section>
    <HomepageAlertContainer>
      <AlertHeader>{headline}</AlertHeader>
      <AlertBody>{body}</AlertBody>
      {learnMoreUrl && learnMore && (
        <ActionButton to={learnMoreUrl} variant={ActionButtonVariants.PRIMARY} marginTop="$3">
          {learnMore}
        </ActionButton>
      )}
    </HomepageAlertContainer>
  </Section>
);

export default Alert;
