import { useCallback, useEffect, useMemo, useState } from 'react';

import { ApolloQueryResult, useApolloClient } from '@apollo/client';

import { IPlusDataQuery, PlusDataDocument, PosDataServiceMode } from 'generated/graphql-gateway';
import { IPluDataQuery } from 'generated/rbi-graphql';
import { IPosDataQuery, PosDataDocument } from 'generated/sanity-graphql';
import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';
import { useServiceModeContext } from 'state/service-mode';
import { transformPlus } from 'utils/menu';
import { useSanityGqlEndpoint } from 'utils/network';
import { parseStringifiedJSON } from 'utils/parse-string';

import getRestaurantPosDataPlus from './get-restaurant-pos-data-plus';

type PosDataResults = {
  posData: Record<string, number> | null;
};

type PosDataIds = (ids?: {
  restaurantPosDataId: string | null;
  storeNumber: string | null;
}) => Promise<PosDataResults | null>;

interface IUsePosData {
  data: PosDataResults;
  refetch: PosDataIds;
}

const transformResponse = ({
  queryResponse,
  isDelivery,
}: {
  queryResponse: ApolloQueryResult<IPluDataQuery & IPosDataQuery>;
  isDelivery: boolean;
}): PosDataResults => {
  let rawPlus = null;

  rawPlus = getRestaurantPosDataPlus(queryResponse?.data, isDelivery);

  if (!rawPlus) {
    return { posData: null };
  }

  return {
    posData: transformPlus(parseStringifiedJSON({ defaultValue: {}, value: rawPlus })),
  };
};

// create PLU object to keep same logic
const transformToPluObject = (plus: IPlusDataQuery['plus']): PosDataResults => {
  return {
    posData: (plus || []).reduce((acc, plu) => {
      acc[plu.plu] = Number(plu.price);
      return acc;
    }, {}),
  };
};

export const usePosDataQuery = ({
  restaurantPosDataId,
  storeNumber,
  lazy = false,
}: {
  restaurantPosDataId: string | null;
  storeNumber: string | null;
  lazy?: boolean;
}): IUsePosData => {
  const enableMenuServicePlus = useFlag(LaunchDarklyFlag.ENABLE_MENU_SERVICE_PLUS);
  const { isDelivery } = useServiceModeContext();
  const sanityUri = useSanityGqlEndpoint();
  const client = useApolloClient();

  const [data, setData] = useState<PosDataResults>({
    posData: null,
  });

  const fetchPosData: PosDataIds = useCallback(
    async ids => {
      const id = ids?.restaurantPosDataId ?? restaurantPosDataId;
      const storeId = ids?.storeNumber ?? storeNumber;

      if (!id) {
        return Promise.resolve({ posData: null });
      }

      let transformedResponse: PosDataResults = { posData: null };

      if (enableMenuServicePlus) {
        const { data } = await client.query({
          query: PlusDataDocument,
          variables: {
            storeId,
            serviceMode: isDelivery ? PosDataServiceMode.DELIVERY : PosDataServiceMode.PICKUP,
          },
        });
        transformedResponse = transformToPluObject(data?.plus);
      } else {
        const queryResponse = await client.query({
          context: { uri: sanityUri },
          query: PosDataDocument,
          variables: {
            id,
          },
        });

        transformedResponse = transformResponse({
          queryResponse,
          isDelivery,
        });
      }

      setData(transformedResponse);
      return transformedResponse;
    },
    [client, enableMenuServicePlus, restaurantPosDataId, sanityUri, isDelivery, storeNumber]
  );

  useEffect(() => {
    if (!lazy) {
      fetchPosData({
        restaurantPosDataId,
        storeNumber,
      });
    }
  }, [fetchPosData, lazy, restaurantPosDataId, storeNumber]);

  return useMemo(
    () => ({
      data,
      refetch: fetchPosData,
    }),
    [data, fetchPosData]
  );
};
