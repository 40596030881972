import { useMemo } from 'react';

import LottieAnimatedView, { AnimatedLottieViewProps } from 'lottie-react-native';

import { colorizeLottie } from 'utils/lottie';

export default function LottieView({
  colorFilters,
  source: originalSource,
  ...props
}: AnimatedLottieViewProps) {
  const derivedSource = useMemo(() => {
    let source = originalSource;
    if (colorFilters) {
      source = colorizeLottie(source, colorFilters);
    }
    return source;
  }, [colorFilters, originalSource]);
  return <LottieAnimatedView source={derivedSource} {...props} />;
}
