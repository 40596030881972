import {
  IFeatureLoyaltyRewardListCategoriesOnlyQuery,
  useFeatureLoyaltyRewardListCategoriesOnlyQuery,
} from 'generated/sanity-graphql';
import { useFeaturesContext } from 'state/features';

type RewardList = NonNullable<IFeatureLoyaltyRewardListCategoriesOnlyQuery['RewardList']>;
type RewardCategories = NonNullable<RewardList['rewardCategories']>;

export type RewardCategory = NonNullable<RewardCategories[0]>;

interface RewardsCategoryResponse {
  data: RewardCategory[] | undefined;
  loading: boolean;
}

export const useFeatureLoyaltyRewardListCategoriesOnly = (): RewardsCategoryResponse => {
  const { featureLoyaltyRewardListCategoriesId = '', featureIdsLoading } = useFeaturesContext();
  const { data, loading } = useFeatureLoyaltyRewardListCategoriesOnlyQuery({
    variables: { featureLoyaltyRewardListCategoriesId },
    skip: !featureLoyaltyRewardListCategoriesId,
  });

  return {
    data: data?.RewardList?.rewardCategories as RewardCategory[],
    loading: featureIdsLoading || loading,
  };
};
