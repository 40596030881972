import { useCallback, useEffect, useMemo, useState } from 'react';

import { IOffer } from '@rbi-ctg/menu';
import { ItemAvailabilityStatus } from 'enums/menu';
import { useGetOfferByIdQuery } from 'generated/sanity-graphql';
import usePosVendor from 'hooks/menu/use-pos-vendor';
import { useNavigation } from 'hooks/navigation/use-navigation';
import { useRoute } from 'hooks/navigation/use-route';
import useRoutes from 'hooks/use-routes';
import { useAuthContext } from 'state/auth';
import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';
import { useLocationContext } from 'state/location';
import { useInRestaurantRedemptionContext } from 'state/loyalty/in-restaurant-redemption';
import { useMenuContext } from 'state/menu';
import { RedemptionMethod, useOffersContext } from 'state/offers';
import { ServiceMode, useServiceModeContext } from 'state/service-mode';
import { useStoreContext } from 'state/store';
import { HapticsNotificationType, hapticNotification } from 'utils/haptic';
import logger from 'utils/logger';
import {
  checkOfferIsAvailableForServiceModeCategory,
  getUniqIdForOffer,
  makeUrlForOffer,
  offerIsDeliveryOnly,
  offerIsPickupOnly,
  offerMatchesUniqID,
} from 'utils/offers';
import { routes } from 'utils/routing';
import { determineServiceModeCategory } from 'utils/service-mode';

import { useOffersUIContext } from './offers-ui-context';
import { IAttemptOfferRedemptionArgs } from './types';

type IsDialogOpen = boolean;
interface IUseOfferRedemptionFlowArgs {
  offer: IOffer;
  authenticated: boolean;
  offerAvailableRightNow: boolean;
  mobileRedemptionAvailable: boolean;
  restaurantRedemptionAvailable: boolean;
  loginRequiredForRedemption: boolean;
}
export const useOfferRedemptionFlow = ({
  offer,
  authenticated,
  offerAvailableRightNow,
  mobileRedemptionAvailable,
  restaurantRedemptionAvailable,
  loginRequiredForRedemption,
}: IUseOfferRedemptionFlowArgs) => {
  const {
    inRestaurantLoyaltyEnabledAtRestaurant,
    inRestaurantRedemptionEnabled,
    addInRestaurantRedemptionEntry,
    enableOfferRedemption,
  } = useInRestaurantRedemptionContext();
  const {
    selectedOffer,
    selectOfferById,
    clearSelectedOffer,
    onSelectMobileRedemption,
    onSelectRestaurantRedemption,
    onCancelRedemption,
    onConfirmRedemption,
    setRedeemedOffer,
    redemptionInProgress,
    setRedemptionInProgress,
  } = useOffersContext();

  const { offersUrl } = useOffersUIContext();
  const { serviceMode, setServiceMode } = useServiceModeContext();
  const { noStoreSelected } = useStoreContext();
  const { setStoreLocatorCallbackUrl } = useLocationContext();
  const { getLocalizedRouteForPath } = useRoutes();
  const { setOriginLocation } = useAuthContext();
  const { navigate, linkTo } = useNavigation();
  const { pathname } = useRoute();

  const { checkItemAvailability } = useMenuContext();
  const { store } = useStoreContext();
  const { vendor } = usePosVendor();

  const [showConfirmSwitchOffersDialog, setShowConfirmSwitchOffersDialog] = useState(false);
  const [showSwitchServiceModeDialog, setShowSwitchServiceModeDialog] = useState(false);
  const [showReadyToRedeemDialog, setShowReadyToRedeemDialog] = useState(false);
  const [showRestaurantRedemptionModal, setShowRestaurantRedemptionModal] = useState(false);
  const [
    tentativeRedemptionMethod,
    setTentativeRedemptionMethod,
  ] = useState<RedemptionMethod | null>(null);
  const [restaurantRedemptionStartTime, setRestaurantRedemptionStartTime] = useState(0);
  const uniqOfferId = getUniqIdForOffer(offer);
  const { data: offerData, loading: loadingOfferConfigs } = useGetOfferByIdQuery({
    variables: { id: offer._id },
  });
  const shouldForceRestaurantSelection = useFlag(
    LaunchDarklyFlag.FORCE_RESTAURANT_SELECTION_FOR_REWARDS
  );
  const checkForAnotherSelectedOffer = useCallback((): IsDialogOpen => {
    // If an existing offer has already been selected, warn user
    if (selectedOffer && !offerMatchesUniqID(uniqOfferId, selectedOffer)) {
      setShowConfirmSwitchOffersDialog(true);
      return true;
    }

    selectOfferById(uniqOfferId);
    return false;
  }, [selectOfferById, selectedOffer, uniqOfferId]);

  const redeemMobileOffer = useCallback(
    async (bypassCheckForAnotherOffer: boolean) => {
      if (!authenticated) {
        setOriginLocation(pathname);
        linkTo(getLocalizedRouteForPath(routes.signUp));

        return;
      }

      if (!mobileRedemptionAvailable) {
        logger.warn(`Attempting to redeem mobile offer that is not available: offer ${offer._id}`);
        return;
      }

      setTentativeRedemptionMethod(RedemptionMethod.mobile);

      const { availabilityStatus } = await checkItemAvailability({
        vendor,
        restaurantPosDataId: store.restaurantPosData?._id ?? '',
        storeNumber: store.number ?? '',
        itemId: `${offer.option._type}-${offer.option._id}`,
      });

      if (!bypassCheckForAnotherOffer && availabilityStatus === ItemAvailabilityStatus.AVAILABLE) {
        const isDialogOpen = checkForAnotherSelectedOffer();
        if (isDialogOpen) {
          return;
        }
      }

      const nextUrl = makeUrlForOffer(offer) || routes.menu;
      if (!nextUrl) {
        logger.warn(
          `Attempting to make a url for mobile offer that is missing critical data: offer ${offer._id}`
        );
        return;
      }
      onSelectMobileRedemption();
      setTentativeRedemptionMethod(null);

      // If there is no store selected, send user to store selection
      if (noStoreSelected) {
        setStoreLocatorCallbackUrl(nextUrl);
        let path = 'store-locator';
        const deliveryOnly = offerIsDeliveryOnly(offer);
        const pickupOnly = offerIsPickupOnly(offer);
        // If the offer is for only pickup or only delivery
        // skip service mode selection
        if (deliveryOnly || pickupOnly) {
          path = deliveryOnly ? routes.address : routes.locationService;
        }
        linkTo(getLocalizedRouteForPath(path));
        return;
      }
      linkTo(nextUrl);
    },
    [
      authenticated,
      checkForAnotherSelectedOffer,
      checkItemAvailability,
      getLocalizedRouteForPath,
      mobileRedemptionAvailable,
      linkTo,
      noStoreSelected,
      offer,
      onSelectMobileRedemption,
      pathname,
      setOriginLocation,
      setStoreLocatorCallbackUrl,
      store.number,
      store.restaurantPosData?._id,
      vendor,
    ]
  );

  const redeemRestaurantOffer = useCallback(
    (bypassCheckForAnotherOffer: boolean) => {
      if (!restaurantRedemptionAvailable) {
        logger.warn(
          `Attempting to redeem restaurant offer that is not available: offer ${offer._id}`
        );
        return;
      }

      if (loginRequiredForRedemption && !authenticated) {
        setOriginLocation(pathname);
        navigate(getLocalizedRouteForPath(routes.signIn));

        return;
      }

      if (shouldForceRestaurantSelection && noStoreSelected) {
        setStoreLocatorCallbackUrl(pathname);
        let path = 'store-locator';
        const deliveryOnly = offerIsDeliveryOnly(offer);
        const pickupOnly = offerIsPickupOnly(offer);
        // Skip service mode selection if offer is for pickup or delivery only
        if (deliveryOnly || pickupOnly) {
          path = deliveryOnly ? routes.address : routes.locationService;
        }

        setRedemptionInProgress(true);
        navigate(getLocalizedRouteForPath(path));
        return;
      }

      setTentativeRedemptionMethod(RedemptionMethod.restaurant);

      if (!bypassCheckForAnotherOffer) {
        if (
          inRestaurantLoyaltyEnabledAtRestaurant &&
          inRestaurantRedemptionEnabled &&
          enableOfferRedemption
        ) {
          const offerToAdd = (offerData?.Offer as IOffer) || offer;
          addInRestaurantRedemptionEntry({ offer: offerToAdd });
          navigate(routes.redeem);
          return;
        }
        const isDialogOpen = checkForAnotherSelectedOffer();
        if (isDialogOpen) {
          return;
        }
      }

      setTentativeRedemptionMethod(null);

      onSelectRestaurantRedemption();
      setShowReadyToRedeemDialog(true);
    },
    [
      restaurantRedemptionAvailable,
      loginRequiredForRedemption,
      authenticated,
      shouldForceRestaurantSelection,
      noStoreSelected,
      onSelectRestaurantRedemption,
      offer,
      setOriginLocation,
      pathname,
      navigate,
      getLocalizedRouteForPath,
      setStoreLocatorCallbackUrl,
      setRedemptionInProgress,
      inRestaurantLoyaltyEnabledAtRestaurant,
      inRestaurantRedemptionEnabled,
      enableOfferRedemption,
      offerData,
      addInRestaurantRedemptionEntry,
      checkForAnotherSelectedOffer,
    ]
  );

  const attemptOfferRedemption = useCallback(
    ({ redemptionMethod, bypassCheckForAnotherOffer = false }: IAttemptOfferRedemptionArgs) => {
      if (!offerAvailableRightNow) {
        logger.warn(`Attempting to redeem offer that is not available: offer ${offer._id}`);
      }

      // verify offer is available for service mode, if selected
      if (serviceMode) {
        const serviceModeCategory = determineServiceModeCategory(serviceMode);

        const isAvailableForServiceMode = checkOfferIsAvailableForServiceModeCategory(
          offer,
          serviceModeCategory
        );
        if (!isAvailableForServiceMode) {
          setShowSwitchServiceModeDialog(true);
          return;
        }
      }
      if (redemptionMethod === RedemptionMethod.mobile) {
        redeemMobileOffer(bypassCheckForAnotherOffer);
      } else {
        redeemRestaurantOffer(bypassCheckForAnotherOffer);
      }
    },
    [offer, offerAvailableRightNow, redeemMobileOffer, redeemRestaurantOffer, serviceMode]
  );

  const onConfirmSwitchOffers = useCallback(() => {
    setShowConfirmSwitchOffersDialog(false);
    if (tentativeRedemptionMethod) {
      clearSelectedOffer();
      selectOfferById(uniqOfferId);
      attemptOfferRedemption({
        redemptionMethod: tentativeRedemptionMethod,
        bypassCheckForAnotherOffer: true,
      });
    }
  }, [
    attemptOfferRedemption,
    clearSelectedOffer,
    selectOfferById,
    tentativeRedemptionMethod,
    uniqOfferId,
  ]);

  const onCancelSwitchOffers = useCallback(() => {
    setShowConfirmSwitchOffersDialog(false);
  }, []);

  const onConfirmSwitchServiceMode = useCallback(() => {
    setShowSwitchServiceModeDialog(false);
    const nextUrl = makeUrlForOffer(offer) || routes.menu;
    if (!nextUrl) {
      logger.warn(
        `Attempting to make a url for mobile offer that is missing critical data: offer ${offer._id}`
      );
      return;
    }
    const deliveryOnly = offerIsDeliveryOnly(offer);
    if (deliveryOnly) {
      setStoreLocatorCallbackUrl(nextUrl);
      navigate(getLocalizedRouteForPath(routes.address));
      clearSelectedOffer();
      selectOfferById(uniqOfferId);
      return;
    }
    setStoreLocatorCallbackUrl(nextUrl);
    setServiceMode(ServiceMode.TAKEOUT);
    clearSelectedOffer();
    selectOfferById(uniqOfferId);
    navigate(routes.locationService);
  }, [
    clearSelectedOffer,
    getLocalizedRouteForPath,
    navigate,
    offer,
    selectOfferById,
    setServiceMode,
    setStoreLocatorCallbackUrl,
    uniqOfferId,
  ]);

  const onCancelSwitchServiceMode = useCallback(() => {
    setShowSwitchServiceModeDialog(false);
  }, []);

  const onConfirmReadyToRedeem = useCallback(
    (isLockedOffer = false) => {
      onConfirmRedemption(offer, isLockedOffer);
      setRedeemedOffer(offer);
      setShowReadyToRedeemDialog(false);
      setRestaurantRedemptionStartTime(Date.now());

      hapticNotification({ type: HapticsNotificationType.SUCCESS });

      setShowRestaurantRedemptionModal(true);
    },
    [setRedeemedOffer, offer, onConfirmRedemption]
  );

  const onCancelReadyToRedeem = useCallback(
    (isLockedOffer = false) => {
      onCancelRedemption(isLockedOffer);
      setShowReadyToRedeemDialog(false);
    },
    [onCancelRedemption]
  );

  const onDismissRestaurantRedemption = useCallback(() => {
    setRestaurantRedemptionStartTime(0);
    setShowRestaurantRedemptionModal(false);
    setRedeemedOffer(null);

    navigate(offersUrl);
  }, [navigate, offersUrl, setRedeemedOffer]);

  useEffect(() => {
    if (redemptionInProgress) {
      setRedemptionInProgress(false);
      attemptOfferRedemption({ redemptionMethod: RedemptionMethod.restaurant });
    }
  }, [redemptionInProgress, attemptOfferRedemption, setRedemptionInProgress]);

  return useMemo(
    () => ({
      attemptOfferRedemption,
      loadingOfferConfigs,
      onConfirmSwitchOffers,
      onConfirmSwitchServiceMode,
      onCancelSwitchOffers,
      onCancelSwitchServiceMode,
      onConfirmReadyToRedeem,
      onCancelReadyToRedeem,
      onDismissRestaurantRedemption,
      showConfirmSwitchOffersDialog,
      showReadyToRedeemDialog,
      showRestaurantRedemptionModal,
      showSwitchServiceModeDialog,
      restaurantRedemptionStartTime,
    }),
    [
      attemptOfferRedemption,
      loadingOfferConfigs,
      onCancelReadyToRedeem,
      onConfirmSwitchOffers,
      onConfirmSwitchServiceMode,
      onCancelSwitchOffers,
      onCancelSwitchServiceMode,
      onConfirmReadyToRedeem,
      onDismissRestaurantRedemption,
      restaurantRedemptionStartTime,
      showConfirmSwitchOffersDialog,
      showReadyToRedeemDialog,
      showRestaurantRedemptionModal,
      showSwitchServiceModeDialog,
    ]
  );
};
