import React, { useCallback, useState } from 'react';

import { Box, Text } from '@rbilabs/universal-components';
import { useIntl } from 'react-intl';

import ActionButton from 'components/action-button';
import UserVerificationModal from 'components/user-verification-modal';
import { useNavigation } from 'hooks/navigation/use-navigation';

const Container = Box.withConfig({
  width: 'full',
  textAlign: 'center',
  display: 'flex',
  justifyContent: 'center',
});

const StyledText = Text.withConfig({
  marginTop: '$3',
  marginBottom: '$3',
});

interface IVerifyEmailMessageProps {
  redirectUrl?: string;
  showModalOnLoad?: boolean;
}

const VerifyEmailMessage = ({ redirectUrl, showModalOnLoad = false }: IVerifyEmailMessageProps) => {
  const { formatMessage } = useIntl();
  const { navigate } = useNavigation();
  const [showVerificationModal, setShowVerificationModal] = useState(showModalOnLoad);
  const handleModalDismiss = useCallback(() => {
    if (redirectUrl) {
      navigate(redirectUrl);
    } else {
      setShowVerificationModal(false);
    }
  }, [navigate, redirectUrl]);
  return (
    <>
      <Container testID="verify-email-banner">
        <StyledText>{formatMessage({ id: 'pleaseVerifyEmail' })}</StyledText>
        <ActionButton testID="verify-email-link" onPress={() => setShowVerificationModal(true)}>
          {formatMessage({ id: 'verify' })}
        </ActionButton>
      </Container>
      {showVerificationModal && <UserVerificationModal showDialog onDismiss={handleModalDismiss} />}
    </>
  );
};

export default VerifyEmailMessage;
