import React from 'react';

import { Button, View } from '@rbilabs/universal-components';
import { GestureResponderEvent } from 'react-native';

import { theme } from 'styles/configure-theme';

import {
  ButtonWrapper,
  Description,
  Header,
  ImageLink,
  Terms,
  TextWrapper,
  Wrapper,
} from './card.styled';
import { CardProps } from './types';

/**
 * Displays a link comprised of an image and text, used for marketing
 */
const Card = ({
  image,
  header,
  description,
  callToAction,
  href,
  termsLinkText,
  termsLinkHref,
  marketingId,
  variant = 'default',
  bgColor,
  textColor,
  buttonBgColor,
  buttonTextColor,
  onPress,
  onPressTermsLink,
}: CardProps) => {
  const onPressMainLink = (event: GestureResponderEvent | undefined) =>
    onPress?.(event, { marketingId });

  const colorTweaks =
    variant === 'campaign'
      ? { color: textColor, bgColor }
      : {
          color: theme.token('text-default'),
          bgColor: theme.token('background-pattern'),
        };

  const buttonProps = {
    onPress: onPressMainLink,
    size: 'md',
    variant: variant === 'default' ? 'outline' : 'solid',
    ...(variant === 'campaign' && {
      bgColor: buttonBgColor,
      _text: {
        color: buttonTextColor,
      },
    }),
  } as const;

  return (
    <Wrapper {...colorTweaks}>
      <ImageLink linkPath={href} onPress={onPressMainLink}>
        {image}
      </ImageLink>
      <TextWrapper>
        <View>
          <Header {...colorTweaks}>{header}</Header>
          <Description {...colorTweaks}>
            {description}
            {termsLinkText && (
              <Terms
                {...colorTweaks}
                linkPath={termsLinkHref || ''}
                onPress={(event: GestureResponderEvent | undefined) =>
                  onPressTermsLink?.(event, { marketingId })
                }
              >
                {termsLinkText}
              </Terms>
            )}
          </Description>
        </View>
        <ButtonWrapper>
          <Button {...buttonProps}>{callToAction}</Button>
        </ButtonWrapper>
      </TextWrapper>
    </Wrapper>
  );
};

export default Card;
