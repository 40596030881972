import * as React from 'react';

import { Header, VStack } from '@rbilabs/universal-components';
import { useIntl } from 'react-intl';

import SanityBlockRenderer from 'components/sanity-block-renderer';

import { getQuestTermsText } from '../custom-text';

import { IQuestTermsProps } from './types';

export const QuestTerms = ({ cmsQuestTerms }: IQuestTermsProps) => {
  const { formatMessage } = useIntl();

  if (!cmsQuestTerms?.localeRaw) {
    return null;
  }

  return (
    <VStack space="$4">
      <Header variant="headerThree" fontWeight="normal" margin={0}>
        {getQuestTermsText(formatMessage)}
      </Header>
      <SanityBlockRenderer textProps={{ variant: 'copyTwo' }} content={cmsQuestTerms.localeRaw} />
    </VStack>
  );
};
