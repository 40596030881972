import React, { useEffect } from 'react';

import { Box } from '@rbilabs/universal-components';

import { IItem, IItemOption, ISanityItemOption } from '@rbi-ctg/menu';

import { Modifiers } from '../modifiers';
import { HandleModifierSelection, HandleQuantityUpdate } from '../types';

const ItemUIContainer = Box.withConfig({
  marginTop: '$8',
});

interface IItemUiProps {
  addToOrder: Function;
  addToOrderText: string;
  cartIdEditing: boolean;
  currentPrice: number;
  excludesForPickerAspects: any[];
  handleModifierQuantityUpdate: HandleQuantityUpdate;
  handleModifierSelection: HandleModifierSelection;
  item: IItem | null;
  modifierSelections: any[];
  setModalCta: Function;
}

const ItemUI: React.FC<React.PropsWithChildren<IItemUiProps>> = ({
  addToOrder,
  addToOrderText,
  currentPrice,
  excludesForPickerAspects = [],
  handleModifierQuantityUpdate,
  handleModifierSelection,
  item,
  modifierSelections,
  setModalCta,
}) => {
  const itemId = (item && item._id) || '';
  const modifiers = (item && item.options) as (IItemOption | ISanityItemOption)[] | null;

  useEffect(() => {
    setModalCta({
      children: [addToOrderText, currentPrice],
      onClick: () => addToOrder(item),
    });
  }, [addToOrder, addToOrderText, currentPrice, item, setModalCta]);

  return (
    <ItemUIContainer>
      <Modifiers
        item={item}
        modifiers={modifiers}
        excludesForPickerAspects={excludesForPickerAspects}
        radioGroupParentId={itemId}
        modifierSelections={modifierSelections}
        handleModifierSelection={handleModifierSelection}
        handleModifierQuantityUpdate={handleModifierQuantityUpdate}
      />
    </ItemUIContainer>
  );
};

export default ItemUI;
