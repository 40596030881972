import React, { useCallback, useMemo } from 'react';

import { RewardsBadge, VerticalCarouselItem } from '@rbilabs/universal-components';

import {
  ItemWidth,
  ResponsiveCarousel,
  ResponsiveCarouselWrapper,
} from 'components/responsive-carousel';
import { IReward } from 'generated/sanity-graphql';
import { useIsMobileBp } from 'hooks/breakpoints';
import { useNavigation } from 'hooks/navigation/use-navigation';
import { appendObjectToQueryString } from 'hooks/navigation/utils';
import { useLoyaltyRewardsList } from 'hooks/use-loyalty-rewards-list';
import { routes } from 'utils/routing';

import { RewardImage } from './styles';
import { ILoyaltyFeaturedRewardsCarouselWidgetProps, ILoyaltyReward } from './types';

const LoyaltyFeaturedRewardsCarouselWidget = ({
  label,
  rewards,
}: ILoyaltyFeaturedRewardsCarouselWidgetProps) => {
  const { sanityRewardsMap, engineRewardsMap } = useLoyaltyRewardsList();
  const { linkTo } = useNavigation();
  const isMobile = useIsMobileBp();

  const rewardsList = useMemo(
    () => (sanityRewardsMap ? rewards.map(reward => sanityRewardsMap[reward._id] || null) : []),
    [rewards, sanityRewardsMap]
  );

  const itemNameLocaleKeyExtractor = (reward: IReward, index: number) =>
    reward?.name?.en || index.toString();

  const handleRewardOnPress = useCallback(
    (incentiveSanityId: string) => {
      const incentiveDetailRoute = appendObjectToQueryString(
        `${routes.loyaltyRewardsList}/${incentiveSanityId}`,
        {}
      );
      linkTo(incentiveDetailRoute);
    },
    [linkTo]
  );

  const itemWidth = isMobile
    ? ItemWidth.VERTICAL_CAROUSEL_ITEM_MOBILE
    : ItemWidth.VERTICAL_CAROUSEL_ITEM_WEB;

  const renderCarouselItem = (loyaltyReward: { item: ILoyaltyReward }) => {
    if (!loyaltyReward || !loyaltyReward.item) {
      return null;
    }

    const engineReward = engineRewardsMap[loyaltyReward.item.loyaltyEngineId || ''];
    const pointCost = engineReward?.pointCost || '';
    const locked = engineReward?.locked || false;
    const itemName = loyaltyReward.item.name?.locale || '';
    const localeImage = loyaltyReward.item.image.locale;

    return (
      <VerticalCarouselItem
        onPress={() => handleRewardOnPress(loyaltyReward.item._id)}
        bg="token.background-pattern"
        borderWidth="1"
        width={itemWidth}
        header={itemName}
        image={<RewardImage objectFitContain alt={itemName ?? ''} image={localeImage} />}
        variant="rewards"
        rewardsBadge={<RewardsBadge isLocked={locked}>{pointCost.toString()}</RewardsBadge>}
      />
    );
  };

  if (!rewardsList?.length) {
    return null;
  }

  return (
    <ResponsiveCarouselWrapper background="transparent" header={label?.locale || ''}>
      <ResponsiveCarousel
        alignWithParent
        data={rewardsList}
        keyExtractor={itemNameLocaleKeyExtractor}
        itemWidth={itemWidth}
        bgColor="transparent"
        loop={false}
        inactiveSlideOpacity={1}
        inactiveSlideScale={0.9}
        renderItem={renderCarouselItem}
      />
    </ResponsiveCarouselWrapper>
  );
};

export default LoyaltyFeaturedRewardsCarouselWidget;
