import React, { FC } from 'react';

// @ts-expect-error TS(2307) FIXME: Cannot find module 'graphql/tsutils/Maybe' or its ... Remove this comment to see the full error message
import Maybe from 'graphql/tsutils/Maybe';

import { ILoyaltyFeaturedRewardsCarouselWidgetProps } from 'pages/loyalty/loyalty-widgets/loyalty-featured-rewards-carousel-widget/types';

export type Widget =
  | FC<React.PropsWithChildren<Omit<IWidgetKey, 'componentKey'>>>
  | FC<React.PropsWithChildren<ILoyaltyFeaturedRewardsCarouselWidgetProps>>;

export interface IWidgetElement {
  id: string;
  element: JSX.Element;
}

export type IWidgetMap<TWidgets extends string> = Record<TWidgets, Widget>;

export interface IWidgetKey {
  readonly componentKey?: Maybe<string>;
  readonly _key?: Maybe<string>;
  readonly [key: string]: any;
}

export const hasComponentKey = <T extends Object>(
  widget: Maybe<T & { componentKey?: Maybe<string> }>
): widget is T & { componentKey: string } => {
  return !!widget?.componentKey;
};
