import { useMemo } from 'react';

import { useIntl } from 'react-intl';

import { IOffer } from '@rbi-ctg/menu';
import { useIsMobileBp } from 'hooks/breakpoints';
import useEffectOnce from 'hooks/use-effect-once';
import { useDayPartContext } from 'state/day-part';
import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';
import { OfferAvailableRedemptionMethods } from 'state/launchdarkly/variations';
import { useMenuContext } from 'state/menu';
import { useStoreContext } from 'state/store';
import logger from 'utils/logger';
import { allowsUnauthenticatedRedemption } from 'utils/offers';
import { useIsMobileOrderingAvailable } from 'utils/restaurant';

import { isOfferAvailableForDayParts, makeOfferUnavailableMessage } from '../utils';

import { RedemptionTypes, RestaurantRedemptionButtonUiOption } from './types';

const TEMPORARY_DUMMY_OPTION_ID_FOR_RESTAURANT_ONLY = 'Lj4sBINzEO93P6iQCkyFCf';
const MOBILE_REDEMPTION: string[] = [
  RedemptionTypes.MOBILE_ONLY,
  RedemptionTypes.MOBILEANDRESTAURANT,
];
const RESTAURANT_REDEMPTION: string[] = [
  RedemptionTypes.RESTAURANT_ONLY,
  RedemptionTypes.MOBILEANDRESTAURANT,
];

interface IUseOfferArgs {
  offer: IOffer;
  authenticated: boolean;
  offerIndex: number;
}
export const useOfferDetails = ({ offer, authenticated, offerIndex }: IUseOfferArgs) => {
  const enableOfferRedemptionMethods = useFlag(LaunchDarklyFlag.ENABLE_OFFER_REDEMPTION_METHODS);

  const enableOrdering = useFlag(LaunchDarklyFlag.ENABLE_ORDERING);
  const { showBurgersForBreakfast } = useMenuContext();
  const { activeDayParts, dayParts } = useDayPartContext();
  const { formatMessage } = useIntl();
  const { store } = useStoreContext();
  const mobileOrderAvailable = useIsMobileOrderingAvailable(store);
  const storeSelected = !!store?._id;

  useEffectOnce(() => {
    if (offerIndex < 0) {
      logger.warn('Invalid offer index in use-offer-details');
    }
  });

  const isMobile = useIsMobileBp();

  const redemptionIsDisabled =
    enableOfferRedemptionMethods === OfferAvailableRedemptionMethods.DISABLE_ALL;

  const offerAvailableRightNow = useMemo(
    () =>
      isOfferAvailableForDayParts({
        offer,
        activeDayParts,
        showBurgersForBreakfast,
      }),
    [activeDayParts, offer, showBurgersForBreakfast]
  );

  const loginRequiredForRedemption = useMemo(
    () => !allowsUnauthenticatedRedemption(offer.ruleSet),
    [offer.ruleSet]
  );

  const showSignUpToRedeem = !redemptionIsDisabled && !authenticated && loginRequiredForRedemption;

  const redemptionMethod = offer?.redemptionMethod;
  const isSupportOffer = !!offer?.forSupport;

  const mobileRedemptionAvailable = redemptionIsDisabled
    ? !!(redemptionMethod ? MOBILE_REDEMPTION.includes(redemptionMethod) : isSupportOffer)
    : !!(
        offer?.option?._id !== TEMPORARY_DUMMY_OPTION_ID_FOR_RESTAURANT_ONLY &&
        offerAvailableRightNow
      );

  const shouldShowMobileOfferRedemptions = redemptionIsDisabled
    ? mobileRedemptionAvailable
    : !showSignUpToRedeem && enableOrdering && (!storeSelected || mobileOrderAvailable);

  const disableMobileOfferRedemptions =
    enableOfferRedemptionMethods === OfferAvailableRedemptionMethods.ENABLE_RESTAURANT_ONLY ||
    !mobileRedemptionAvailable ||
    !enableOrdering;

  const showMobileRedemptionButton =
    shouldShowMobileOfferRedemptions && !disableMobileOfferRedemptions;

  const restaurantRedemptionAvailable = redemptionIsDisabled
    ? !!(redemptionMethod && RESTAURANT_REDEMPTION.includes(redemptionMethod))
    : !isSupportOffer;

  const disableInRestaurantOfferRedemptions = redemptionIsDisabled
    ? !restaurantRedemptionAvailable
    : enableOfferRedemptionMethods === OfferAvailableRedemptionMethods.ENABLE_MOBILE_ONLY ||
      !mobileRedemptionAvailable ||
      isSupportOffer;

  const showRestaurantRedemptionButton =
    !showSignUpToRedeem && restaurantRedemptionAvailable && offerAvailableRightNow;

  const restaurantRedemptionButtonUiType =
    isMobile ||
    showMobileRedemptionButton ||
    disableMobileOfferRedemptions ||
    !mobileRedemptionAvailable
      ? RestaurantRedemptionButtonUiOption.BUTTON
      : RestaurantRedemptionButtonUiOption.LINK;

  const noPossibleRedemption =
    redemptionIsDisabled && !mobileRedemptionAvailable && !restaurantRedemptionAvailable;

  const redemptionErrorMessage = useMemo(() => {
    let message: string | null = null;
    if (!showSignUpToRedeem && !offerAvailableRightNow) {
      message = makeOfferUnavailableMessage({
        dayParts,
        offer,
        formatMessage,
      });
    } else if (
      (!mobileRedemptionAvailable || !showMobileRedemptionButton) &&
      (!restaurantRedemptionAvailable || !showRestaurantRedemptionButton)
    ) {
      message = formatMessage({ id: 'offerNotAvailable' });
    } else if (!mobileRedemptionAvailable && showMobileRedemptionButton) {
      message = formatMessage({ id: 'offerNotAvailableForMobileRedemption' });
    }
    return message;
  }, [
    restaurantRedemptionAvailable,
    offerAvailableRightNow,
    mobileRedemptionAvailable,
    showSignUpToRedeem,
    showMobileRedemptionButton,
    showRestaurantRedemptionButton,
    dayParts,
    offer,
    formatMessage,
  ]);

  return useMemo(
    () => ({
      showMobileRedemptionButton,
      showSignUpToRedeem,
      offerAvailableRightNow,
      mobileRedemptionAvailable,
      restaurantRedemptionAvailable,
      loginRequiredForRedemption,
      showRestaurantRedemptionButton,
      disableInRestaurantOfferRedemptions,
      disableMobileOfferRedemptions,
      redemptionErrorMessage,
      restaurantRedemptionButtonUiType,
      noPossibleRedemption,
    }),
    [
      showMobileRedemptionButton,
      showSignUpToRedeem,
      offerAvailableRightNow,
      mobileRedemptionAvailable,
      restaurantRedemptionAvailable,
      loginRequiredForRedemption,
      showRestaurantRedemptionButton,
      disableInRestaurantOfferRedemptions,
      disableMobileOfferRedemptions,
      redemptionErrorMessage,
      restaurantRedemptionButtonUiType,
      noPossibleRedemption,
    ]
  );
};
