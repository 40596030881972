import React from 'react';

import { Box } from '@rbilabs/universal-components';

import { Animation } from 'components/lottie-animations/loyalty-celebration-loading';

export const AccountCreationLoadingAnimation = () => (
  <Box w="full" alignItems="center" paddingX="$9">
    <Animation />
  </Box>
);
