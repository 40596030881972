import React from 'react';

import { NavLink } from 'components/mobile-web-navigation-bar/helpers';
import { NavLinkText } from 'components/mobile-web-navigation-bar/styled';
import SkipToContent from 'components/skip-content';
import UniversalLink from 'components/universal-link';
import NavLinkExternal from 'pages/loyalty/loyalty-widgets/loyalty-points-meter-widget/nav-link-external';

import type { ILeftNavProps } from './types';

const StyledNavLink = NavLink.withConfig({
  marginLeft: '$5',
  _web: {
    cursor: 'pointer',
  },
});

const StyledNavLinkText = NavLinkText.withConfig({
  variant: 'copyTwo',
  fontSize: 'sm',
});

export const LeftNav = ({ navLinks }: ILeftNavProps) => {
  return (
    <>
      <SkipToContent />
      {navLinks.map(navLink => (
        <UniversalLink
          to={navLink.link}
          key={navLink.key}
          data-testid={navLink.text}
          internal={{
            component: StyledNavLink,
            props: {
              to: navLink.link,
              requiresExactMatch: true,
              component: (isActive: boolean) => (
                <StyledNavLinkText fontWeight="bold" $isActive={isActive}>
                  {navLink.text}
                </StyledNavLinkText>
              ),
            },
          }}
          external={{ component: NavLinkExternal }}
        >
          {navLink.text}
        </UniversalLink>
      ))}
    </>
  );
};
