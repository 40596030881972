import { useReversedUIContext } from 'state/reversed-ui';
import { theme } from 'styles/configure-theme';
import { NAV_HEIGHT } from 'styles/constants/enums';

import { headerStyles } from './constants';
import { IHeaderStylesHook } from './types';

export const useHeaderStyles = (): IHeaderStylesHook => {
  const { reversedUI } = useReversedUIContext();

  const iconColor = theme.token(
    reversedUI ? headerStyles.headerIconColorReversed : headerStyles.headerIconColor
  );
  const backgroundColor = theme.token(
    reversedUI ? headerStyles.headerBackgroundReversed : headerStyles.headerBackground
  );

  return {
    iconColor,
    backgroundColor,
    height: NAV_HEIGHT.MOBILE,
  };
};
