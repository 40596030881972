import { isTest } from 'utils/environment';

export const GOOGLE_MAPS_API_BASE_URL = 'https://maps.googleapis.com/maps/api';

export const GET_CURRENT_POSITION_TIMEOUT = isTest ? 20000 : 5000;

export const MAX_GET_CURRENT_POSITION_REQUEST_TRIES = 2;

export const getGoogleApiSrcPath = (googleMapsApiKey: string) => {
  return `${GOOGLE_MAPS_API_BASE_URL}/js?key=${googleMapsApiKey}&libraries=places`;
};
