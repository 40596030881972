import React, { FC } from 'react';

import { useIntl } from 'react-intl';

import { useUIContext } from 'state/ui';

import { Price } from './price';

interface IModifierPriceProps {
  price: number;
}

const ModifierPrice: FC<React.PropsWithChildren<IModifierPriceProps>> = ({ price }) => {
  const { formatMessage } = useIntl();
  const { formatCurrencyForLocale } = useUIContext();
  const priceString = formatMessage(
    { id: 'price' },
    {
      price: formatCurrencyForLocale(price),
    }
  );
  return price > 0 ? <Price testID="modifier-upcharge">{priceString}</Price> : null;
};

export default ModifierPrice;
