import * as React from 'react';
import { FC } from 'react';

import { useSystemwidePromotionMessageQuery } from 'generated/sanity-graphql';

import Ribbon from './ribbon';
import { RibbonPromoMessageProps } from './ribbon.types';

const PromoMessageRibbon: FC<React.PropsWithChildren<RibbonPromoMessageProps>> = ({ promoId }) => {
  const { data } = useSystemwidePromotionMessageQuery({
    variables: {
      id: promoId,
    },
    skip: !promoId,
  });

  const promoMessage = data?.SystemwidePromotion?.promoMessage?.locale;

  return promoMessage ? <Ribbon text={promoMessage} /> : null;
};

export default PromoMessageRibbon;
