import * as React from 'react';
import { FC } from 'react';

import { useIntl } from 'react-intl';

import { ModalItemUnavailableView } from 'components/modal-item-unavailable/modal-item-unavailable/modal-item-unavailable.view';
import { useNavigation } from 'hooks/navigation/use-navigation';
import { IUseDialogComponentProps } from 'hooks/use-dialog-modal';
import { useFeatureStaticMenu } from 'hooks/use-feature-static-menu';
import { ServiceMode, useServiceModeContext } from 'state/service-mode';
import { routes } from 'utils/routing';

/**
 *
 * This Modal appears if a the reward benefit item is not available in
 * the users selected store or the nearest store that can deliver.
 *
 */
const ModalRewardItemUnavailable: FC<React.PropsWithChildren<
  IUseDialogComponentProps & { rewardName: string }
>> = ({ onDismiss, rewardName }) => {
  const { itemUnavailableModal } = useFeatureStaticMenu();
  const { formatMessage } = useIntl();
  const { navigate } = useNavigation();
  const { serviceMode } = useServiceModeContext();

  const imageAlt = `${formatMessage({ id: 'itemsUnavailable' })} ${formatMessage({ id: 'image' })}`;

  if (!itemUnavailableModal) {
    return null;
  }

  const {
    header,
    image,
    body: defaultBodyText,
    selectNew: selectNewBtnText,
    itemNotAvailableForDelivery,
  } = itemUnavailableModal;

  const continueBtnText = formatMessage({ id: 'continue' });

  const handleChangeStore = () => {
    onDismiss();
    navigate(routes.storeLocator, { replace: true });
  };

  const isDelivery = serviceMode === ServiceMode.DELIVERY;
  const body = isDelivery ? itemNotAvailableForDelivery : defaultBodyText;

  return (
    <ModalItemUnavailableView
      header={header}
      image={image}
      body={body}
      continueBtnText={continueBtnText}
      selectNewBtnText={selectNewBtnText}
      handleContinue={onDismiss}
      handleChangeStore={handleChangeStore}
      unavailableItemName={rewardName}
      imageAlt={imageAlt}
      hideSelectNewBtn={isDelivery}
    />
  );
};

export default ModalRewardItemUnavailable;
