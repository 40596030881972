import React, { FC, useMemo, useRef, useState } from 'react';

import { IconButton, View } from '@rbilabs/universal-components';
import { FlatList } from 'react-native';

import { useIsDesktopBp, useIsMobileBp } from 'hooks/breakpoints';
import { hiddenAccessibilityPlatformProps } from 'utils/accessibility';

import { ResponsiveCarouselSkeletonItem } from './responsive-carousel.skeleton';
import {
  CardContainerWrapper,
  Container,
  LeftButtonWrapper,
  RightButtonWrapper,
} from './responsive-carousel.styled.web';
import { IResponsiveCarouselProps, Sizes } from './types';

const SKELETON_ITEMS = [null, null, null, null, null] as const;

const skeletonRenderItem = () => <ResponsiveCarouselSkeletonItem width={150} />;

const ResponsiveCarousel: FC<React.PropsWithChildren<IResponsiveCarouselProps<any>>> = ({
  data: propData,
  renderItem: propRenderItem,
  bgColor = 'transparent',
  itemWidth = 150,
  keyExtractor,
  isLoading,
  centerCards = false,
}) => {
  const [initialPageItem, setInitialPageItem] = useState(0);

  const flatListRef = useRef<FlatList>(null);
  const isMobile = useIsMobileBp();
  const isDesktop = useIsDesktopBp();

  const data = isLoading && !propData?.length ? SKELETON_ITEMS : propData ?? SKELETON_ITEMS;
  const renderItem = propRenderItem ?? skeletonRenderItem;

  const cardsPerPage = useMemo(() => {
    return isDesktop ? Sizes.CARDS_PER_SLIDE_DESKTOP : Sizes.CARDS_PER_SLIDE_TABLET;
  }, [isDesktop]);

  const totalCards = data.length;
  const disableRightButton = useMemo(() => {
    return initialPageItem + cardsPerPage >= totalCards;
  }, [initialPageItem, cardsPerPage, totalCards]);

  const showMore = () => {
    setInitialPageItem(initialPageItem => initialPageItem + cardsPerPage);
    flatListRef.current?.scrollToIndex({
      animated: true,
      index: initialPageItem + cardsPerPage,
    });
  };

  const showLess = () => {
    setInitialPageItem(initialPageItem => initialPageItem - cardsPerPage);
    if (initialPageItem < cardsPerPage) {
      flatListRef.current?.scrollToIndex({
        animated: true,
        index: 0,
      });
    } else {
      flatListRef.current?.scrollToIndex({
        animated: true,
        index: initialPageItem - cardsPerPage,
      });
    }
  };

  const cardContainerWidth = isMobile ? 'full' : (itemWidth + Sizes.SPACER + 16) * cardsPerPage;

  return (
    <Container bgColor={bgColor} justifyContent={isMobile ? 'flex-start' : 'center'} margin="$2">
      {!isMobile && (
        <LeftButtonWrapper bgColor={bgColor}>
          <IconButton
            isDisabled={initialPageItem === 0}
            variant="outline"
            iconName="back"
            onPress={showLess}
            {...hiddenAccessibilityPlatformProps}
          />
        </LeftButtonWrapper>
      )}
      <CardContainerWrapper width={cardContainerWidth}>
        <FlatList
          ref={flatListRef}
          accessibilityRole="button"
          horizontal
          centerContent={centerCards || totalCards < cardsPerPage}
          showsHorizontalScrollIndicator={false}
          data={data}
          keyExtractor={keyExtractor}
          renderItem={isLoading ? skeletonRenderItem : renderItem}
          ItemSeparatorComponent={() => <View style={{ marginRight: Sizes.SPACER }} />}
        />
      </CardContainerWrapper>
      {!isMobile && (
        <RightButtonWrapper bgColor={bgColor}>
          <IconButton
            variant="outline"
            iconName="forward"
            onPress={showMore}
            isDisabled={disableRightButton}
            {...hiddenAccessibilityPlatformProps}
          />
        </RightButtonWrapper>
      )}
    </Container>
  );
};

export default ResponsiveCarousel;
