import React, { FC } from 'react';

import { Box, HStack, Skeleton, VStack } from '@rbilabs/universal-components';

interface IProductCardListSkeletonProps {
  hasFooter?: boolean;
}

export const ProductSkeleton = () => {
  return (
    <Box flexDirection="row" justifyContent="flex-start" alignItems="flex-start">
      <HStack marginTop="$3" space="$4">
        <Skeleton rounded={12} w="$24" h="$20" />
        <VStack space="$2" justifyContent="center" pr="$4" flex={1}>
          <Skeleton rounded="full" h="$5" />
          <HStack space="$2">
            <Skeleton rounded="full" h="$5" flex={3} />
            <Skeleton rounded="full" h="$5" flex={1} mr="$12" />
          </HStack>
        </VStack>
      </HStack>
    </Box>
  );
};

export const ProductCardListSkeleton: FC<React.PropsWithChildren<
  IProductCardListSkeletonProps
>> = ({ hasFooter }) => {
  return (
    <VStack w="100%" px="$4" mt="$4">
      <HStack marginTop="$3" space="$10">
        <Skeleton rounded="full" flex={5} h="$5" />
        <Skeleton
          rounded="full"
          h="$9"
          flex={2}
          startColor="token.background-skeleton-loader-cta-start"
          endColor="token.background-skeleton-loader-cta-end"
        />
      </HStack>
      <ProductSkeleton />
      {hasFooter && (
        <HStack space="$2" w="$20" marginTop="$3">
          <Skeleton rounded="full" h="$4" flex={2} />
          <Skeleton rounded="full" h="$4" flex={5} />
        </HStack>
      )}
    </VStack>
  );
};
