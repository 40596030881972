import { sharedDesignTokens } from './desigh-tokens-shared';
import { primitive } from './primitives';

import Styles from './index';

export const designTokens = {
  ...sharedDesignTokens,
  Styles,

  'background-active': primitive.bk.$fieryRed,
  'background-appbar-navbar': primitive.bk.$mayoEggWhite,
  'background-button-hover-reversed': primitive.$whiteOpacity10,
  'background-button-primary-default': primitive.bk.$fieryRed,
  'background-button-primary-default-reversed': primitive.$white,
  'background-button-primary-hover': primitive.bk.$fieryRedDarken10,
  'background-button-secondary-hover': primitive.bk.$fieryRedOpacity10,
  'background-checkbox-disabled': primitive.$blackOpacity30,
  'background-checked': primitive.bk.$bbqBrown,
  'background-chip': primitive.bk.$bbqBrown,
  'background-chip-rewards': primitive.bk.$bbqBrown,
  'background-chip-rewards-locked': primitive.bk.$mayoEggWhite,
  'background-default': primitive.bk.$mayoEggWhite,
  'background-dialog': primitive.$white,
  'background-error': primitive.$error,
  'background-fixed-bottom': primitive.bk.$mayoEggWhite,
  'background-focus': primitive.$blackOpacity4,
  'background-focus-reversed': primitive.$whiteOpacity4,
  'background-footer': primitive.bk.$bbqBrown,
  'background-hero-light': primitive.bk.$mayoEggWhite,
  'background-hero-reversed': primitive.bk.$bbqBrown,
  'background-icon-hover-focus': primitive.$blackOpacity4,
  'background-icon-hover-focus-reversed': primitive.$whiteOpacity4,
  'background-input': primitive.$white,
  'background-input-disabled': primitive.$blackOpacity4,
  'background-pattern': primitive.$white,
  'background-switch-thumb': primitive.$white,
  'background-switch-track': primitive.bk.$bbqBrown,
  'background-switch-track-disabled': primitive.$blackOpacity30,
  'border-color-active': primitive.bk.$fieryRed,
  'border-color-button-secondary': primitive.bk.$fieryRed,
  'border-color-button-secondary-reversed': primitive.$white,
  'border-color-checked': primitive.bk.$bbqBrown,
  'border-color-default': primitive.$blackOpacity10,
  'border-color-disabled': primitive.$blackOpacity30,
  'border-color-disabled-reversed': primitive.$whiteOpacity30,
  'border-color-error': primitive.$error,
  'border-color-hover': primitive.bk.$bbqBrown,
  'icon-active': primitive.bk.$fieryRed,
  'icon-button-primary': primitive.$white,
  'icon-button-primary-reversed': primitive.bk.$bbqBrown,
  'icon-button-reversed': primitive.$white,
  'icon-button-secondary': primitive.bk.$fieryRed,
  'icon-button-tertiary-default': primitive.bk.$bbqBrown,
  'icon-button-tertiary-hover': primitive.bk.$fieryRed,
  'icon-default': primitive.bk.$bbqBrown,
  'icon-disabled': primitive.$blackOpacity30,
  'icon-disabled-reversed': primitive.$whiteOpacity30,
  'icon-form-checkbox': primitive.$white,
  'icon-form-input-action': primitive.bk.$bbqBrown,
  'icon-form-input-default': primitive.$blackOpacity30,
  'icon-reversed': primitive.bk.$mayoEggWhite,
  'padding-button-icon-end': `${primitive.$spacing0} ${primitive.$spacing2} ${primitive.$spacing0} ${primitive.$spacing4}`,
  'padding-button-icon-start': `${primitive.$spacing0} ${primitive.$spacing4} ${primitive.$spacing0} ${primitive.$spacing2}`,
  'padding-button-large': `${primitive.$spacing0} ${primitive.$spacing5}`,
  'padding-button-small': `${primitive.$spacing0} ${primitive.$spacing4}`,
  'padding-input': `${primitive.$spacing0} ${primitive.$spacing4}`,
  'text-active': primitive.bk.$fieryRed,
  'text-button-primary': primitive.$white,
  'text-button-primary-reversed': primitive.bk.$bbqBrown,
  'text-button-reversed': primitive.$white,
  'text-button-secondary': primitive.bk.$fieryRed,
  'text-button-tertiary-default': primitive.bk.$bbqBrown,
  'text-button-tertiary-hover': primitive.bk.$fieryRed,
  'text-default': primitive.bk.$bbqBrown,
  'text-disabled': primitive.$disabledText,
  'text-disabled-reversed': primitive.$disabledTextReversed,
  'text-error': primitive.$error,
  'text-hero-light': primitive.bk.$bbqBrown,
  'text-hero-reversed': primitive.bk.$mayoEggWhite,
  'text-link-default': primitive.bk.$bbqBrown,
  'text-link-hover': primitive.bk.$fieryRed,
  'text-link-reversed': primitive.bk.$mayoEggWhite,
  'text-reversed': primitive.bk.$mayoEggWhite,
  'text-style-back-nav': 'var(--font-copyOne)',
  'text-style-bottom-nav': 'var(--font-copyTwo)',
  'text-style-weight-bottom-nav': 'normal',
  'text-style-button-large': 'var(--font-headerThree)',
  'text-style-button-small': primitive.bk.$headerFour,
  'text-style-card-copy': 'var(--font-copyTwo)',
  'text-style-card-header': 'var(--font-headerThree)',
  'text-style-chip': 'var(--font-copyTwo)',
  'text-style-chip-rewards': primitive.bk.$headerFour,
  'text-style-dialog-copy': 'var(--font-copyOne)',
  'text-style-dialog-header': 'var(--font-headerOne)',
  'text-style-footer-large': 'var(--font-headerThree)',
  'text-style-footer-location': primitive.bk.$headerFour,
  'text-style-footer-small': 'var(--font-copyTwo)',
  'text-style-form-checkbox': 'var(--font-copyOne)',
  'text-style-form-checkbox-header': 'var(--font-headerThree)',
  'text-style-form-hint': primitive.bk.$formUtility,
  'text-style-form-input': 'var(--font-copyOne)',
  'text-style-form-label-default': 'var(--font-copyOne)',
  'text-style-form-label-value': primitive.bk.$formUtility,
  'text-style-form-legend': 'var(--font-headerThree)',
  'text-style-form-options': primitive.bk.$formUtility,
  'text-style-hero-header': 'var(--font-hero)',
  'text-style-hero-subheader': 'var(--font-headerThree)',
  'text-style-hero-terms': 'var(--font-copyTwo)',
  'text-style-inline-alert': 'var(--font-copyTwo)',
  'text-style-item-description-copy': 'var(--font-copyTwo)',
  'text-style-item-description-header': 'var(--font-headerThree)',
  'text-style-item-description-price': primitive.bk.$headerFour,
  'text-style-item-description-subheader': primitive.bk.$formUtility,
  'text-style-list-item': 'var(--font-copyOne)',
  'text-style-list-item-info': 'var(--font-copyTwo)',
  'text-style-list-item-restaurant': 'var(--font-headerThree)',
  'text-style-module': 'var(--font-headerOne)',
  'text-style-segment-header': 'var(--font-headerThree)',
  'text-style-tabs': 'var(--font-copyOne)',
  'text-style-top-nav': 'var(--font-copyOne)',
  'text-style-top-nav-right': primitive.bk.$headerFour,
  'text-tabs-default': primitive.bk.$bbqBrown,
  'text-toggle-selected': primitive.$white,
  'transition-component': 'all .3s ease',
  'width-checkbox-radio': '20px',
  'width-icon': '24px',
  'width-icon-small': '16px',
  // Whitelabel-app-specific tokens to be added here, e.g.:
  // 'my-token-name': primitive.$black,
  'background-contrast-loyalty': primitive.bk.$bbqBrown,
  'background-contrast-loyalty-reversed': primitive.bk.$flamingOrange,
  'background-badge-cart-entry': primitive.$blackOpacity30,
  'background-icon-header-contrast': primitive.$blackOpacity4,
  'background-inline-alert': primitive.$blackOpacity4,
  'background-menu-customization-image': primitive.bk.$bbqBrown,
  'border-default': `1px solid ${primitive.$blackOpacity10}`,
  'icon-header-contrast': primitive.bk.$bbqBrown,
  'text-accent-order-number': primitive.bk.$fieryRed,
  'text-loyalty-highlight': primitive.bk.$bbqBrown,
  'pickup-timed-fire-selector-button-background': primitive.$white,
  'tims-rewards-text-color': primitive.th.$coffeeBrown,
  'padding-list-item-group-header': `${primitive.$spacing6} ${primitive.$spacing4} ${primitive.$spacing2}`,
  'margin-top-product-wizard-layout': '0',

  // Global App Colors
  'top-nav-bar-background-color': primitive.bk.$mayoEggWhite,
  'bottom-nav-bar-background-color': primitive.bk.$mayoEggWhite,
  'main-menu-carousel-background': primitive.bk.$mayoEggWhite,
  'bsm-default-background-color': primitive.$white,
  'bsm-in-progress-background-color': primitive.bk.$bbqBrown,
  'bsm-drop-shadow': '0 0 3px rgb(0 0 0 / 10%)',

  'crown-color': '#FFAA00',
};
