import { AdyenAcceptedCards } from 'components/credit-card-form-inputs/adyen-credit-card-form-inputs/types';
import { CardTypes } from 'state/payment/types';

const adyenPaymentMethodCardTypes = {
  [AdyenAcceptedCards.MASTERCARD]: CardTypes.MASTERCARD,
  [AdyenAcceptedCards.VISA]: CardTypes.VISA,
  [AdyenAcceptedCards.AMEX]: CardTypes.AMEX,
  [AdyenAcceptedCards.DISCOVER]: CardTypes.DISCOVER,
  [AdyenAcceptedCards.JCB]: CardTypes.JCB,
  [AdyenAcceptedCards.DINERS_CLUB]: CardTypes.DINERS_CLUB,
};

/**
 * We are attempting to map our payment processor cardTypes to our FE CardType's.
 *
 * NOTE: This is intended to map the returned cardType for credit payment methods from the userAccounts query.
 */
export const mapPaymentMethodCardTypes = (paymentMethodCardType: string): CardTypes | undefined => {
  // check for all original card types, mostly first data values
  if (CardTypes[paymentMethodCardType]) {
    return CardTypes[paymentMethodCardType];
  }
  // check for adyen payment processor card types
  // NOTE: in the future we may need to expand this
  return adyenPaymentMethodCardTypes[paymentMethodCardType];
};
