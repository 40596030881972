import React, { FC, useMemo } from 'react';

import { Box } from '@rbilabs/universal-components';

import { useGetProductCategoryQuery } from 'generated/sanity-graphql';
import { ILockedOfferStep } from 'state/offers/hooks/use-locked-offers';

import {
  Checkpoint,
  Circle,
  CircleDot,
  ItemDetail,
  List,
  PictureIcon,
  ProductLabel,
} from './locked-offers-card.styled';
import { IOfferListProps } from './types';

export interface IProductCategoryProps extends ILockedOfferStep {}

export const ProductCategory: FC<React.PropsWithChildren<IProductCategoryProps>> = ({
  isComplete,
  sanityId,
}) => {
  const { data, loading, error } = useGetProductCategoryQuery({
    fetchPolicy: 'cache-and-network',
    skip: !sanityId,
    variables: {
      id: sanityId || '',
    },
  });
  const doc = data?.ProductCategory;
  if (!doc || loading || error) {
    return <Box testID="locked-offer-product-category-none"></Box>;
  }

  return (
    <Checkpoint>
      <ItemDetail isComplete={isComplete}>
        {isComplete ? <Circle /> : <CircleDot />}
        {doc.image && (
          <PictureIcon objectFitContain image={doc.image} alt={doc.name?.locale || ''} />
        )}
        <ProductLabel>{doc.name?.locale || ''}</ProductLabel>
      </ItemDetail>
    </Checkpoint>
  );
};

export const OfferList: FC<React.PropsWithChildren<IOfferListProps>> = ({ list = [] }) => {
  const listDisplay = useMemo(() => {
    return list.map((item, index) => {
      return (
        <ProductCategory
          key={`${item.sanityId}_${index}`}
          isComplete={item.isComplete}
          name={item.name}
          image={item.image}
          sanityId={item.sanityId}
        />
      );
    });
  }, [list]);

  return <List>{listDisplay}</List>;
};
