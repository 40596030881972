import React, { FC } from 'react';

import { useIsLoyaltyEnabled } from 'state/loyalty/hooks/use-is-loyalty-enabled';

import { RewardsSectionView } from './rewards-section.view';
import { IRewardsSectionProps } from './types';
import { useCarouselRewards } from './use-carousel-rewards';

const RewardsSection: FC<React.PropsWithChildren<IRewardsSectionProps>> = ({
  item: { rewardsList },
}) => {
  const loyaltyEnabled = useIsLoyaltyEnabled();
  const carouselRewards = useCarouselRewards({ rewards: rewardsList });

  if (!loyaltyEnabled || !carouselRewards.length) {
    return null;
  }
  return <RewardsSectionView rewards={carouselRewards} />;
};

export default RewardsSection;
