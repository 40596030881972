import React from 'react';

import { Box, Button, Header, Text, makeUclComponent } from '@rbilabs/universal-components';
import { useIntl } from 'react-intl';

import { IUpsellOffer } from '@rbi-ctg/menu';
import Modal, { ModalContent, ModalHeading } from 'components/modal';
import Picture from 'components/picture';
import SanityBlockRenderer, {
  IBlockContentOverrides,
  ISerializers,
  PassthroughComponent,
} from 'components/sanity-block-renderer';

import { useOffersUIContext } from './offers-ui-context';

const UpsellModalHeading = makeUclComponent(ModalHeading).withConfig({
  marginBottom: '$4',
});
const UpsellModalContent = makeUclComponent(ModalContent).withConfig(
  {}
); /*
TODO: RN - unsupported CSS
Some attributes were not converted.


  & div.modal-inner {
    display: flex;
    height: 100%;
    justify-content: space-between;
  }

*/
const UpsellOptionsWrapper = Box.withConfig<{ $numColumns: number }>({
  // TODO: RN - unsupported CSS
  // display: 'grid',

  marginBottom: '$4',
});
const UpsellOption = Button.withConfig<{ $limitWidth: boolean }>({
  // Reset default button styles
  backgroundColor: 'transparent',

  borderWidth: 0,
  borderColor: 'black',

  _text: {
    textAlign: 'left',
  },

  // TODO: RN - unsupported CSS
  // display: 'grid',

  // TODO: RN - unsupported CSS
  // gridTemplateColumns: '140px 1fr',

  // TODO: RN - unsupported CSS
  // gridGap: '0.5rem',

  alignItems: 'center',

  // TODO: RN - unsupported CSS
  // width: '100vw',

  height: '140px',
  padding: '$4',

  borderBottomWidth: {
    base: 1,
    md: 0,
  },

  borderBottomColor: {
    base: Styles.color.grey.five,
    md: 'none',
  },

  borderStyle: {
    base: 'solid',
    md: 'solid',
  },

  borderTopLeftRadius: {
    md: 10,
  },

  borderTopRightRadius: {
    md: 10,
  },

  borderBottomRightRadius: {
    md: 10,
  },

  borderBottomLeftRadius: {
    md: 10,
  },
});
const TextContent = Box.withConfig({
  height: '85px',
});

const Title = Header.withConfig({
  // TODO: RN - unsupported CSS
  // display: '-webkit-box',

  // TODO: RN - unsupported CSS
  // WebkitLineClamp: '2',

  // TODO: RN - unsupported CSS
  // WebkitBoxOrient: 'vertical',

  overflow: 'hidden',
  margin: 0,
  fontFamily: Styles.fontFamily.base,
  fontWeight: 400,
  fontSize: 'md',
  variant: 'headerThree',
});
const Description = Text.withConfig({
  // TODO: RN - unsupported CSS
  // display: '-webkit-box',

  // TODO: RN - unsupported CSS
  // WebkitLineClamp: '2',

  // TODO: RN - unsupported CSS
  // WebkitBoxOrient: 'vertical',

  overflow: 'hidden',
  marginTop: '$2',
  marginRight: 0,
  marginBottom: 0,
  marginLeft: 0,
  fontSize: 'sm',
});
const ButtonWrapper = Box.withConfig({
  marginBottom: '$8',
});

const titleBlockContentOverrides: IBlockContentOverrides = {
  normal: Title,
};

const titleBlockSerializers: ISerializers = {
  marks: {
    em: PassthroughComponent,
  },
  container: PassthroughComponent,
};

const descriptionBlockContentOverrides: IBlockContentOverrides = {
  normal: Description,
};

const descriptionBlockSerializers: ISerializers = {
  container: PassthroughComponent,
};

interface IOfferUpsellModal {
  onDismiss: () => void;
  parentId: string;
  upsellOptions: Array<IUpsellOffer>;
}
const OfferUpsellModal: React.FC<React.PropsWithChildren<IOfferUpsellModal>> = ({
  onDismiss,
  parentId,
  upsellOptions,
}) => {
  const { formatMessage } = useIntl();
  const { onSelectOffer } = useOffersUIContext();

  return (
    <Modal
      testID="offer-upsell-modal"
      onDismiss={onDismiss}
      mParticleEventData={{
        modalAppearanceEventMessage: 'Upsell Offer',
        modalMessage: `Parent Offer ID: ${parentId}`,
      }}
    >
      <UpsellModalContent>
        <UpsellModalHeading testID="modal-heading">
          {formatMessage({ id: 'offerUpsellTitle' })}
        </UpsellModalHeading>
        <UpsellOptionsWrapper $numColumns={upsellOptions.length > 1 ? 2 : 1}>
          {upsellOptions.map(offer => (
            <UpsellOption
              testID={`${offer._id}__offer-upsell`}
              key={offer._id}
              onPress={() => onSelectOffer(offer._id, true)}
              $limitWidth={upsellOptions.length > 1}
            >
              <Picture image={offer.localizedImage?.locale?.app} alt="" objectFitContain={true} />
              <TextContent>
                <SanityBlockRenderer
                  blockContentOverrides={titleBlockContentOverrides}
                  serializers={titleBlockSerializers}
                  content={offer?.name?.localeRaw}
                />
                <SanityBlockRenderer
                  blockContentOverrides={descriptionBlockContentOverrides}
                  serializers={descriptionBlockSerializers}
                  content={offer?.description?.localeRaw}
                />
              </TextContent>
            </UpsellOption>
          ))}
        </UpsellOptionsWrapper>
        <ButtonWrapper>
          <Button testID="offer-upsell-discard" onPress={onDismiss}>
            {formatMessage({ id: 'offerUpsellDiscard' })}
          </Button>
        </ButtonWrapper>
      </UpsellModalContent>
    </Modal>
  );
};

export default OfferUpsellModal;
