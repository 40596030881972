import { useCallback, useRef } from 'react';

import { AnyArgs, VariadicFn } from '@rbi-ctg/frontend';
import logger from 'utils/logger';

type Queueable<A extends AnyArgs = any[]> = VariadicFn<A, void>;
export type WithReadyQueue = <A extends AnyArgs = any[]>(fn: Queueable<A>) => Queueable<A>;

interface IUseReadyQueue {
  drainQueue(): void;
  enqueueIfNotDrained: WithReadyQueue;
}

type ReadyQueue = Array<[Queueable, AnyArgs]>;

const tryCalling = (callback: Queueable) => (...args: AnyArgs) => {
  try {
    callback(...args);
  } catch (error) {
    // @ts-expect-error TS(2345) FIXME: Argument of type 'unknown' is not assignable to pa... Remove this comment to see the full error message
    logger.error(error);
  }
};

/**
 * @function useReadyQueue
 * useReadyQueue provides helper functions for creating callbacks
 * that should be queued rather than called immediately. These are used
 * to ensure that any asynchronously loaded dependencies are not called
 * before loading.
 *
 * Usage:
 *
 * const { enqueueIfNotDrained, drainQueue } = useReadyQueue();
 *
 * const functionWithGlobalDependency = enqueueIfNotDrained(() => {
 *   window.someSdk.method();
 * });
 *
 * useEffect(() => {
 *   loadDependencyAsynchronously().then(drainQueue);
 * });
 *
 * @returns {IUseReadyQueue} an object containing the above functions
 */
const useReadyQueue = (): IUseReadyQueue => {
  const readyQueue = useRef<ReadyQueue>([]);
  const drained = useRef(false);

  const enqueueIfNotDrained: WithReadyQueue = callback => (...args) => {
    if (drained.current) {
      // @ts-expect-error TS(2345) FIXME: Argument of type 'Queueable<A>' is not assignable ... Remove this comment to see the full error message
      return tryCalling(callback)(...args);
    }
    // @ts-expect-error TS(2345) FIXME: Argument of type 'Queueable<A>' is not assignable ... Remove this comment to see the full error message
    readyQueue.current = [...readyQueue.current, [tryCalling(callback), args]];
  };

  const drainQueue = useCallback((): void => {
    // does nothing if already drained
    if (drained.current) {
      return;
    }

    readyQueue.current.forEach(([callback, args]) => {
      callback(...args);
    });

    readyQueue.current = [];
    drained.current = true;
  }, []);

  return {
    enqueueIfNotDrained,
    drainQueue,
  };
};

export default useReadyQueue;
