import * as React from 'react';

import { Header } from '@rbilabs/universal-components';
import { useIntl } from 'react-intl';

import { getQuestsText } from '../custom-text';

export const QuestLibraryHeader = () => {
  const { formatMessage } = useIntl();

  return <Header>{getQuestsText(formatMessage)}</Header>;
};
