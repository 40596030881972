import { Stack, View } from '@rbilabs/universal-components';

// ./row
export const RowContainer = Stack.withConfig({
  flexDir: { base: 'column', md: 'row' },
  width: 'full',
  borderBottomWidth: '1',
  borderBottomColor: 'whiteOpacity.30',
  py: '$10',
});

// ./column
export const ColumnContainer = Stack.withConfig({
  flexDir: 'column',
  flex: 1,
});

// ./vertical-link-list
export const VerticalListLinkContainer = Stack.withConfig({
  flexDir: 'column',
  paddingRight: '$10',
});

// ./wordmark-and-social-icons
export const WordmarkSocialIconsContainer = Stack.withConfig({
  flexDir: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginBottom: '$6',
});

// ./links
export const Divisor = View.withConfig({
  marginX: '$4',
  borderLeftWidth: '1',
  borderLeftColor: 'whiteOpacity.30',
});
