import { useMemo } from 'react';

import { isEmpty } from 'lodash-es';

import { CarouselReward } from 'components/rewards-carousel/types';
import { IncentiveEvaluationErrorCodes, IncentiveEvaluationMap } from 'state/loyalty/hooks/types';
import useRewardsEvaluation from 'state/loyalty/hooks/use-loyalty-rewards-evaluation';

import { IUseCarouselRewardsParams } from '../types';

const useCarouselRewards = ({ rewards }: IUseCarouselRewardsParams) => {
  const { rewardsEvaluationMap, engineRewardsMap } = useRewardsEvaluation();

  const carouselRewards = useMemo(() => {
    if (!rewards) {
      return [];
    }

    return rewards.reduce((acc: CarouselReward[], featuredReward) => {
      const loyaltyRewardId = featuredReward?.reward?.loyaltyEngineId || '';
      const engineReward = engineRewardsMap[loyaltyRewardId];
      const { isAvailabilityLoading, isAvailable } = getAvailabilityStatus(
        rewardsEvaluationMap,
        loyaltyRewardId
      );
      if (featuredReward?.reward && engineReward) {
        acc.push({
          ...featuredReward?.reward,
          locked: engineReward.locked || false,
          pointCost: engineReward.pointCost,
          rewardBenefitId: engineReward.rewardBenefitId,
          isAvailable,
          isAvailabilityLoading,
        });
      }
      return acc;
    }, []);
  }, [rewards, engineRewardsMap, rewardsEvaluationMap]);

  return carouselRewards;
};

const NOT_AVAILABLE_ERRORS_CODES = [
  String(IncentiveEvaluationErrorCodes.NOT_AVAILABLE_IN_STORE),
  String(IncentiveEvaluationErrorCodes.OUT_OF_DAY_PART),
];

const getAvailabilityStatus = (
  rewardsEvaluationMap: IncentiveEvaluationMap,
  loyaltyRewardId: string
) => {
  const rewardEvaluationResults = rewardsEvaluationMap[loyaltyRewardId] || [];
  const isAvailable = !rewardEvaluationResults.some(({ code }) =>
    NOT_AVAILABLE_ERRORS_CODES.includes(code)
  );
  const isAvailabilityLoading = isEmpty(rewardsEvaluationMap);
  return {
    isAvailable,
    isAvailabilityLoading,
  };
};

export default useCarouselRewards;
