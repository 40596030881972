import * as React from 'react';
import { FC } from 'react';

import { Box, Stack } from '@rbilabs/universal-components';

import { MarketingCard } from 'components/features/components/marketing-card';
import { IMarketingCard, IMarketingCardFragment } from 'generated/sanity-graphql';

import { getWeekDay } from '../../../../state/day-part/hooks/use-active-day-parts';

import { IMarketingCardGroupProps } from './types';

/**
 *
 * MarketingCardGroup renders a group of marketing cards in a grid
 *
 */
const MarketingCardGroup: FC<React.PropsWithChildren<IMarketingCardGroupProps>> = ({
  doc,
  onShowTermsModal,
}) => {
  const cards = doc.Cards as IMarketingCard[];

  if (!cards || cards.length <= 0) {
    return null;
  }

  const hasRules = ({ daysOfWeek, timeRanges }: IMarketingCard) =>
    !!daysOfWeek?.length || !!timeRanges?.length;

  const tileShouldBeDisplayed = (card: IMarketingCard) => {
    const now = new Date();
    const currentDayOfWeek = getWeekDay(now);
    const { daysOfWeek, timeRanges } = card;

    const getTimeParts = (time: string) => {
      const [hours, minutes, seconds] = time.split(':');
      const hoursInt = Number.parseInt(hours, 10);
      const minutesInt = Number.parseInt(minutes, 10);
      const secondsInt = Number.parseInt(seconds, 10);
      return [hoursInt, minutesInt, secondsInt];
    };

    const timeRangeAvailability = timeRanges?.map(range => {
      if (range && range.startTime && range && range && range.endTime) {
        const [startHour, startMinutes] = getTimeParts(range.startTime);
        const [endHour, endMinutes] = getTimeParts(range.endTime);
        const startTimeMs = new Date().setHours(startHour, startMinutes);
        const endTimeMs = new Date().setHours(endHour, endMinutes);
        const nowMs = now.getTime();
        return startTimeMs <= nowMs && nowMs <= endTimeMs;
      }

      return false;
    });

    const isActiveForDayOfWeek = daysOfWeek ? daysOfWeek.includes(currentDayOfWeek) : false;

    const isActiveForTimeRangeAvailability = timeRangeAvailability
      ? timeRangeAvailability?.some(range => range === true)
      : true; // If there is no timeRanges it is available all day.

    return isActiveForDayOfWeek && isActiveForTimeRangeAvailability;
  };

  // NOTE: Return all cards, except those cards that have rule and do not meet the requirement
  const cardsList = cards.filter(card => {
    if (hasRules(card) && !tileShouldBeDisplayed(card)) {
      return false;
    }
    return true;
  });

  return (
    <Stack
      testID="marketing-tile-group-grid"
      space={{ base: 4, md: 0 }}
      marginX={{ base: '$4', md: '$6' }}
      direction={{ base: 'column', md: 'row' }}
      flexWrap="wrap"
    >
      {cardsList?.map((card, index) => {
        if (!card) {
          return null;
        }
        const key = `MarketingCard_${card._id}`;
        return (
          <Box key={key} mx={{ md: '$2' }} mb={{ md: '$8' }}>
            <MarketingCard
              card={card as IMarketingCardFragment}
              onShowTermsModal={onShowTermsModal}
              index={index}
            />
          </Box>
        );
      })}
    </Stack>
  );
};

export default MarketingCardGroup;
