import React, { useContext, useState } from 'react';

// @ts-expect-error TS(7016) FIXME: Could not find a declaration file for module 'reac... Remove this comment to see the full error message
import createDOMProps from 'react-native-web/dist/modules/createDOMProps';
import { Link as RRLink, useLocation } from 'react-router-dom';

import { IBaseProps } from '@rbi-ctg/frontend';
import noop from 'utils/noop';

import { useNavigateBack } from './hooks/use-navigate-back';
import { usePriorValue } from './hooks/use-prior-value';
import { ILocation } from './types';

export { useMatch, useParams, useSearchParams, Routes, Route } from 'react-router-dom';

export const Link = (props: any) => {
  const domProps = createDOMProps('a', props);

  return <RRLink {...domProps} />;
};

export const LocationContext = React.createContext<ILocation>({} as ILocation);
export const useLocationContext = () => useContext(LocationContext);

export function LocationProvider(props: IBaseProps) {
  const [storeLocatorCallbackUrl, setStoreLocatorCallbackUrl] = useState('');

  const { addNavigateBackListener } = useNavigateBack();
  const addHardwareBackButtonListener = noop;
  const removeHardwareBackButtonListener = noop;
  const [signUpEmail, setSignUpEmail] = useState('');
  const location = useLocation();
  const referrerLocation = usePriorValue(location.pathname + location.search, undefined);

  return (
    <LocationContext.Provider
      value={{
        addNavigateBackListener,
        addHardwareBackButtonListener,
        signUpEmail,
        location,
        referrerLocation,
        removeHardwareBackButtonListener,
        setSignUpEmail,
        setStoreLocatorCallbackUrl,
        storeLocatorCallbackUrl,
      }}
    >
      {props.children}
    </LocationContext.Provider>
  );
}

export default LocationContext.Consumer;
