import { gql } from '@apollo/client';

export default gql`
  fragment MenuImageFragment on Image {
    asset {
      metadata {
        blurHash
      }
      _id
    }
  }
`;
