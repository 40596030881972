import React from 'react';

import { Box, Icon, Text as UCLText } from '@rbilabs/universal-components';
import { useIntl } from 'react-intl';

import ActionButton from 'components/action-button';
import Modal, { ModalContent, ModalHeading } from 'components/modal';
import { useOrderDetails } from 'hooks/use-order-details';
import { useAccountOrdersFlow } from 'pages/account/account-orders/use-account-orders-flow';
import { useOrderContext } from 'state/order';
import { useReorder } from 'state/order/hooks/use-reorder';
import { hiddenAccessibilityPlatformProps } from 'utils/accessibility';

const Container = Box.withConfig({
  alignItems: 'center',

  _text: {
    textAlign: 'center',
  },

  paddingTop: '$8',
  paddingRight: 0,
  paddingBottom: '$12',
  paddingLeft: 0,
  margin: 'auto',

  maxWidth: {
    base: 300,
    md: 500,
  },
});

const Heading = ModalHeading.withConfig({
  marginTop: '$12',
  marginRight: 0,
  marginBottom: '$4',
  marginLeft: 0,
});

const Text = UCLText.withConfig({
  marginTop: 0,
  marginRight: 0,
  marginBottom: '$12',
  marginLeft: 0,
});

const ErrorIcon = Icon.withConfig({
  variant: 'error',
  // TODO: RN
  // color:"primary",
  width: '$32',
  height: '$32',
});

interface IOrderCancelledModalProps {
  onDismiss: () => void;
  modalAppearanceEventMessage?: string;
}

const OrderCancelledModal: React.FC<React.PropsWithChildren<IOrderCancelledModalProps>> = ({
  onDismiss,
  modalAppearanceEventMessage = 'Order Cancelled',
}) => {
  const {
    serverOrder,
    reorder: { reordering },
  } = useOrderContext();
  const accountOrdersFlow = useAccountOrdersFlow();
  const rbiOrderId = serverOrder.rbiOrderId || accountOrdersFlow.rbiOrderId;
  const { order } = useOrderDetails(rbiOrderId);
  const { handleReorderClick } = useReorder(order);
  const { formatMessage } = useIntl();

  const modalHeading = formatMessage({ id: 'cancelledOrderTitle' });
  const modalBody = formatMessage({ id: 'cancelledOrderAction' });

  return (
    <Modal
      onDismiss={onDismiss}
      mParticleEventData={{
        modalAppearanceEventMessage,
        modalMessage: modalBody,
      }}
    >
      <ModalContent>
        <Container>
          <ErrorIcon {...hiddenAccessibilityPlatformProps} />
          <Heading>{modalHeading}</Heading>
          <Text>{modalBody}</Text>
          <ActionButton onPress={handleReorderClick} isLoading={reordering} disabled={reordering}>
            {formatMessage({ id: 'reorder' })}
          </ActionButton>
        </Container>
      </ModalContent>
    </Modal>
  );
};

export default OrderCancelledModal;
