import React from 'react';

import { Box, Header, Image, Spacer, Text } from '@rbilabs/universal-components';

import { IHeroMaskCardFragment } from 'generated/sanity-graphql';
import { useUIContext } from 'state/ui';
import { theme } from 'styles/configure-theme';

const Section = Box.withConfig({
  width: 'full',
  background: theme.token('background-pattern'),
  top: {
    md: 4,
  },
  maxWidth: {
    md: '1200px',
  },
  borderRadius: Styles.borderRadius,
  shadow: 2,
});

const Card = Text.withConfig({
  flexGrow: 1,
  paddingX: {
    base: 4,
    md: 13.5,
  },
  paddingY: {
    base: 6,
    md: 10,
  },
  flexDirection: {
    base: 'row-reverse',
    md: 'column',
  },
  alignItems: 'center',
});

const StyledImage = Image.withConfig({
  // TODO: RN - This causes a crash on Android. Commenting out for now to unlock.
  // width: '1/3',
});

const TextContainer = Box.withConfig({
  padding: {
    base: 4,
    md: 0,
  },

  // TODO: RN - do we need this?
  // &:nth-child(3n+3) {
  //   flex-direction: row;
  // }
});

const CardHeading = Header.withConfig({
  variant: 'headerFour',
  color: theme.token('text-default'),
  marginY: '4',
  marginX: 'auto',
  textAlign: {
    md: 'center',
  },
});

const CardDescription = Text.withConfig({
  margin: 0,
  maxWidth: 96,
  textAlign: {
    md: 'center',
  },
});

// TODO: RN - find out how this look
// const LineBreak = styled.hr`
//   border-width: 0px 0px 2px 0px;
//   border-color: ${p => p.theme.token('border-color-default')};
//   width: 80%;
//   &:last-child {
//     display: none;
//   }
//   ${Styles.desktop`
//     display: none;
//   `}
// `;

const HeroMaskCards: React.FC<React.PropsWithChildren<{ cards: IHeroMaskCardFragment[] }>> = ({
  cards,
}) => {
  const { buildImageUrl } = useUIContext();

  return (
    <Section>
      {cards.map(card => {
        return (
          <>
            <Card>
              {card.image && card.image.asset && card.title && card.title?.locale && (
                <StyledImage
                  src={buildImageUrl(card.image, { height: 140 })}
                  alt={card.title?.locale}
                />
              )}
              <TextContainer>
                {card.title && <CardHeading>{card.title?.locale}</CardHeading>}
                {card.description && <CardDescription>{card.description?.locale}</CardDescription>}
              </TextContainer>
            </Card>
            <Spacer />
          </>
        );
      })}
    </Section>
  );
};

export default HeroMaskCards;
