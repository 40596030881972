import * as React from 'react';
import { FC, memo } from 'react';

// @ts-expect-error TS(2307) FIXME: Cannot find module 'graphql/tsutils/Maybe' or its ... Remove this comment to see the full error message
import Maybe from 'graphql/tsutils/Maybe';

import { useOffersUIContext } from 'components/offers/ui-refresh/offers-ui-context';
import { IOffersSelectRestaurantCta } from 'components/offers/ui-refresh/use-feature-offers';
import { useNavigation } from 'hooks/navigation/use-navigation';

import { SelectRestaurantCtaView } from './select-restaurant-cta.view';
import { NoNullFields } from './types';

/**
 *
 * Displays a CTA to select restaurant if available number of non-restaurant specific offers falls below
 * the "minimumNumberOfOffers" number set in Sanity.
 *
 */

const dataIsValid = (
  data: Maybe<IOffersSelectRestaurantCta>
): data is NoNullFields<IOffersSelectRestaurantCta> => {
  return !!data && Object.values(data).every(Boolean);
};

const SelectRestaurantCta: FC<React.PropsWithChildren<unknown>> = () => {
  const { featureOffers } = useOffersUIContext();
  const { offersSelectRestaurantCta } = featureOffers?.offersBrowsingPanel ?? {};
  const { navigate } = useNavigation();

  if (!dataIsValid(offersSelectRestaurantCta)) {
    return null;
  }

  const { title, body, ctaButtonText, link } = offersSelectRestaurantCta;

  const handlePress = () => navigate(link);

  return (
    <SelectRestaurantCtaView
      title={title}
      body={body}
      ctaButtonText={ctaButtonText}
      onPress={handlePress}
    />
  );
};

export default memo(SelectRestaurantCta);
