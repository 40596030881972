import React, { createContext, useContext } from 'react';

import { IBaseProps } from '@rbi-ctg/frontend';

import { IAssetsPerRegion } from './types';

export const AssetsContext = createContext<IAssetsPerRegion>({} as IAssetsPerRegion);

export const useAssetsContext = () => useContext(AssetsContext);

export const AssetsProvider = ({
  children,
  sanityAssets,
}: IBaseProps & { sanityAssets: IAssetsPerRegion }) => {
  return <AssetsContext.Provider value={sanityAssets}>{children}</AssetsContext.Provider>;
};
