import { IHeaderStyles } from 'navigation/header/types';

export const baseHeaderStyles: IHeaderStyles = {
  headerBackground: 'background-hero-light',
  headerBackgroundReversed: 'background-hero-reversed',
  headerIconColor: 'icon-header-contrast',
  headerIconColorReversed: 'icon-reversed',
  logoWidth: '120',
  logoHeight: '35',
  logoLabel: 'plkLogoTitle',
};
