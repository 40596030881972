import { ComponentProps } from 'react';

import { Text } from '@rbilabs/universal-components';

interface ITextButton extends ComponentProps<typeof Text> {
  color?: string;
  centered?: boolean;
}

// TODO: rn - this should probably be replaced with an actual button.
const TextButton = Text.withConfig<ITextButton>(props => ({
  variant: 'copyOne',
  fontSize: 'sm',
  color: props.color || Styles.color.black,
  textDecorationLine: 'underline',
  paddingY: '20px',
  _hover: {
    color: Styles.color.tertiary,
  },
  textAlign: props.centered ? 'center' : 'left',
}));

export default TextButton;
