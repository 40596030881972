import type { IImageFragment, ILocaleImageFragment } from 'generated/sanity-graphql';
import { useLocale } from 'state/intl';

export function useLocaleImage(
  localeImage: ILocaleImageFragment | undefined | null
): IImageFragment | undefined | null {
  const { language } = useLocale();

  return localeImage?.[language] ?? localeImage?.locale ?? localeImage?.en;
}
