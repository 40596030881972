import { useMemo } from 'react';

import {
  LoyaltyServiceMode,
  OfferRedemptionType,
  useLoyaltyNotificationsRedeemableQuery,
} from 'generated/graphql-gateway';
import { LoyaltyDashboardWidgets } from 'pages/loyalty/loyalty-dashboard/types';
import { useFeaturesLoyaltyDashboard } from 'pages/loyalty/loyalty-dashboard/use-feature-loyalty-dashboard';
import { hasComponentKey } from 'pages/loyalty/loyalty-widget-components';
import { useServiceModeContext } from 'state/service-mode';
import { useStoreContext } from 'state/store';

import { LoyaltyNotifications, NotificationDetails, RedeemableItems } from './types';

export const useLoyaltyNotifications: LoyaltyNotifications = (
  loyaltyUser,
  loyaltyOffersEnabled
) => {
  const { serviceMode: nullableServiceMode } = useServiceModeContext();
  const { store } = useStoreContext();

  const serviceMode = LoyaltyServiceMode[nullableServiceMode || ''] || undefined;
  const storeId = store?.number || undefined;

  const { data, loading: loadingDashboard } = useFeaturesLoyaltyDashboard();
  const widget = data?.widgets?.find(
    record =>
      record &&
      hasComponentKey<typeof record>(record) &&
      record.componentKey === LoyaltyDashboardWidgets.Notifications
  );
  const loyaltyId = loyaltyUser?.id;

  // Get all of user's redeemable notification items
  const { data: redeemableData, loading: loadingUserData } = useLoyaltyNotificationsRedeemableQuery(
    {
      fetchPolicy: 'cache-and-network',
      skip: !loyaltyId || !widget,
      variables: {
        rewardsInput: { ignorePointBalance: false, serviceMode, storeId },
        offersInput: { global: true, serviceMode, storeId },
        loyaltyId: loyaltyId || '',
      },
    }
  );

  const redeemableLoyaltyIds = useMemo(() => {
    if (!loyaltyId || !redeemableData?.loyaltyUserV2) {
      return [];
    }
    const {
      loyaltyUserV2: { rewards, offers, promotions },
    } = redeemableData;
    const results: string[] = [];
    const addToResults = (records: RedeemableItems) => {
      if (!records) {
        return;
      }
      records.forEach(record => {
        if (record) {
          results.push(record.id);
        }
      });
    };
    // @ts-expect-error TS(2345) FIXME: Argument of type 'readonly { readonly __typename?:... Remove this comment to see the full error message
    addToResults(rewards);
    // @ts-expect-error TS(2345) FIXME: Argument of type 'readonly { readonly __typename?:... Remove this comment to see the full error message
    addToResults(offers);
    // @ts-expect-error TS(2345) FIXME: Argument of type 'readonly { readonly __typename?:... Remove this comment to see the full error message
    addToResults(promotions);
    return results;
  }, [loyaltyId, redeemableData]);

  const redeemableNotifications = useMemo(() => {
    if (!widget || widget.__typename !== 'LoyaltyNotificationsWidget') {
      return [];
    }

    const notifications = widget.notifications || [];
    return notifications.reduce<NotificationDetails[]>((results, notification) => {
      if (
        !notification?.loyaltyEngineId ||
        !notification.notificationDetails?.enabled ||
        (!loyaltyOffersEnabled &&
          notification?.__typename === 'SystemwideOffer' &&
          notification?.redemptionType === OfferRedemptionType.SURPRISE)
      ) {
        return results;
      }

      const { loyaltyEngineId, notificationDetails: details } = notification;
      if (redeemableLoyaltyIds.includes(loyaltyEngineId)) {
        return results.concat({ ...details, id: loyaltyEngineId });
      }

      return results;
    }, []);
  }, [widget, loyaltyOffersEnabled, redeemableLoyaltyIds]);

  return {
    notifications: redeemableNotifications,
    loadingNotifications: !redeemableData && (loadingDashboard || loadingUserData),
  };
};
