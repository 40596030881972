import * as React from 'react';
import { useEffect, useState } from 'react';

import { useLoyaltyUserTransactionsQuery } from 'generated/graphql-gateway';
import LoyaltyClaimPointsLink from 'pages/loyalty/loyalty-claim-points/loyalty-claim-points-link';
import { Transaction } from 'pages/loyalty/loyalty-history/types';
import { LoyaltyNoRewardPoints } from 'pages/loyalty/loyalty-no-reward-points';
import { useLocalizedTransactionTransformer } from 'pages/loyalty/transactions/use-localized-transaction-transformer';
import { useLoyaltyContext } from 'state/loyalty';
import { useLoyaltyUser } from 'state/loyalty/hooks/use-loyalty-user';

import LoyaltyHistoryTable from './loyalty-history-table';
import LoyaltyHistoryWidgetWrapper from './loyalty-history-widget-wrapper';
import { StyledLoyaltyClaimPointsLink } from './loyalty-history-widget.styled';

const MAX_TRANSACTIONS = 3;

const LoyaltyHistoryWidget: React.FC<React.PropsWithChildren<unknown>> = () => {
  const [tableData, setTableData] = useState<Transaction[]>([]);
  const transactionTransformer = useLocalizedTransactionTransformer();
  const { loading: loyaltyLoading, loyaltyUser } = useLoyaltyUser();
  const { claimPointsEnabled } = useLoyaltyContext();

  const loyaltyUserId = loyaltyUser?.id || '';
  const { data, loading: transactionsLoading } = useLoyaltyUserTransactionsQuery({
    skip: !loyaltyUserId,
    variables: { loyaltyId: loyaltyUserId },
    fetchPolicy: 'network-only',
  });

  const transactions = data?.loyaltyUserV2?.transactions;
  const loading = loyaltyLoading || transactionsLoading;

  useEffect(() => {
    if (transactions?.length) {
      setTableData(transactions.slice(0, MAX_TRANSACTIONS).map(transactionTransformer));
    }
  }, [transactionTransformer, transactions, setTableData]);

  if (loading) {
    return null;
  }

  return (
    <LoyaltyHistoryWidgetWrapper>
      {transactions?.length ? (
        <>
          <LoyaltyHistoryTable transactions={tableData} />
          {claimPointsEnabled && <StyledLoyaltyClaimPointsLink />}
        </>
      ) : (
        <>
          <LoyaltyNoRewardPoints />
          {claimPointsEnabled && <LoyaltyClaimPointsLink />}
        </>
      )}
    </LoyaltyHistoryWidgetWrapper>
  );
};

export default LoyaltyHistoryWidget;
