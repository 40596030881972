import React from 'react';

import { HeroSuperText, SanityDescription, SanityHeroHeading } from './styled';

export interface IMainHeroTextProps {
  html: string | null;
  defaultTextColor: string;
  heroTextColor: string | null;
  heroSuperText: string | null;
  heroSubText: string | null;
}

export const MainHeroText: React.FC<React.PropsWithChildren<IMainHeroTextProps>> = ({
  heroSuperText,
  defaultTextColor,
  heroTextColor,
  html,
  heroSubText,
}) => {
  const mapHtml = html?.replace(/<br>/g, '\n') || '';

  return (
    <>
      {heroSuperText !== '' && (
        <HeroSuperText
          // @ts-expect-error TS(2322) FIXME: Type '{ children: string | null; testId: string; $... Remove this comment to see the full error message
          testId="hero-super-text"
          $defaultTextColor={defaultTextColor}
          $sanityTextColor={heroTextColor}
        >
          {heroSuperText}
        </HeroSuperText>
      )}
      {mapHtml !== '' && (
        <SanityHeroHeading
          // @ts-expect-error TS(2769) FIXME: No overload matches this call.
          testId="hero-heading"
          $defaultTextColor={defaultTextColor}
          $sanityTextColor={heroTextColor}
          paddingTop={'$1'}
        >
          {mapHtml}
        </SanityHeroHeading>
      )}
      {heroSubText !== '' && (
        <SanityDescription
          // @ts-expect-error TS(2769) FIXME: No overload matches this call.
          testId="hero-sub-text"
          $defaultTextColor={defaultTextColor}
          $sanityTextColor={heroTextColor}
          paddingBottom={'$1'}
          marginTop={'$1'}
        >
          {heroSubText}
        </SanityDescription>
      )}
    </>
  );
};
