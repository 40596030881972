import { Header, makeUclComponent } from '@rbilabs/universal-components';

import { QuestCard } from './loyalty-quest-card/loyalty-quest-card';

export const QuestsTitle = Header.withConfig({
  fontSize: '2xl',
  paddingLeft: '26px',
  color: Styles.color.primary,
  variant: 'headerTwo',
});

export const StyledQuestCard = makeUclComponent(QuestCard).withConfig<{
  $fadeDelayMultiplier: number;
}>({
  // TODO: RN - unsupported CSS
  // opacity: 0,
});
// animation: ${fadeIn} 0.5s ${({ $fadeDelayMultiplier = 0 }) => 0.15 * $fadeDelayMultiplier}s
//    forwards;
