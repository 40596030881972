import React from 'react';

import { Box, BrandLogo, makeUclComponent } from '@rbilabs/universal-components';
import { useIntl } from 'react-intl';

import Picture from 'components/picture';
import { useFeatureLoyaltyUi } from 'hooks/use-feature-loyalty-ui';

const PictureContainer = Box.withConfig({
  minWidth: '$32',
  minHeight: '$10',
});

const DefaultLoyaltyLogo = BrandLogo.withConfig({
  width: '48',
  height: '16',
});

const LoyaltyRewardsLogo: React.FC<React.PropsWithChildren<{
  testID?: string;
  inverted?: boolean;
}>> = ({ ...props }) => {
  const { featureLoyaltyUiLoading, featureLoyaltyUi } = useFeatureLoyaltyUi();
  const { formatMessage } = useIntl();
  const rewardLogo = props.inverted
    ? featureLoyaltyUi?.rewardsLogoInverted?.locale
    : featureLoyaltyUi?.rewardsLogo?.locale;

  if (!rewardLogo && featureLoyaltyUiLoading) {
    return null;
  }

  // If custom logo hasn't been uploaded in Sanity, use default one
  if (!featureLoyaltyUi) {
    return <DefaultLoyaltyLogo variant="BkLogoRoyalPerks" />;
  }

  return (
    <PictureContainer testID="bk-rewards-logo" {...props}>
      <Picture
        image={rewardLogo}
        alt={formatMessage({ id: 'royalPerks' })}
        objectFitContain
        lazy={false}
        lqOff
      />
    </PictureContainer>
  );
};

export default makeUclComponent(LoyaltyRewardsLogo);
