import * as React from 'react';

import { Box, HStack } from '@rbilabs/universal-components';
import { useIntl } from 'react-intl';

import { useIsLoyaltyEnabled } from 'state/loyalty/hooks/use-is-loyalty-enabled';
import { useLoyaltyUser } from 'state/loyalty/hooks/use-loyalty-user';

import { POINTS_TITLE } from './constants';
import {
  Points,
  PointsContainer,
  PointsLabel,
  StyledArrowLink,
  StyledPointsIcon,
  StyledRewardsLogo,
} from './rewards-link.styled';

interface IRewardsLinkProps {
  showPointsLabel?: boolean;
  textLabel?: string;
}

const RewardsLinkBase = ({ showPointsLabel, textLabel }: IRewardsLinkProps) => {
  const { formatMessage } = useIntl();
  const loyaltyEnabled = useIsLoyaltyEnabled();
  const { loyaltyUser } = useLoyaltyUser();

  return loyaltyEnabled && loyaltyUser ? (
    <StyledArrowLink
      testID="loyalty-link"
      to={formatMessage({ id: 'routes.rewardsHistory' })}
      labelEnd={
        <HStack>
          <StyledPointsIcon />
          <Points
            accessibilityLabel={`${loyaltyUser.points} ${formatMessage({ id: POINTS_TITLE })}`}
          >
            {loyaltyUser.points}
          </Points>
        </HStack>
      }
    >
      <HStack>
        <Box _text={{ variant: 'copyOne' }} height="100%" testID="logo-box">
          {textLabel || <StyledRewardsLogo />}
        </Box>
        <PointsContainer>
          {showPointsLabel && <PointsLabel>{formatMessage({ id: 'points' })}</PointsLabel>}
        </PointsContainer>
      </HStack>
    </StyledArrowLink>
  ) : null;
};

export default RewardsLinkBase;
