import React, { useMemo } from 'react';

import { PromoCodeOffer } from 'components/promo-code-offer';
import { useAuthContext } from 'state/auth';
import { UIPattern } from 'state/offers/types';

import { RedemptionButtons } from '../redemption-buttons';
import { IRedemptionPanelProps } from '../types';

const RedemptionPanel: React.FC<React.PropsWithChildren<IRedemptionPanelProps>> = ({
  offer,
  onRedeemOffer,
  showRestaurantRedemptionButton,
  disableMobileOfferRedemptions,
  disableInRestaurantOfferRedemptions,
  redemptionErrorMessage,
  showSignUpToRedeem,
  showMobileRedemptionButton,
  restaurantRedemptionButtonUiType,
  noPossibleRedemption,
  togglePromoCodeOfferRedeemable,
}) => {
  const { isAuthenticated, user } = useAuthContext();

  const cognitoId = useMemo(() => user?.cognitoId || '', [user]);

  const showPromoCodeUI =
    offer?.uiPattern === UIPattern.PROMO && isAuthenticated && !offer.isRedeemable;
  const showRedemptionButtons = !isAuthenticated || offer.isRedeemable;

  return (
    <>
      {showPromoCodeUI && (
        <PromoCodeOffer
          cognitoId={cognitoId}
          offer={offer}
          togglePromoCodeOfferRedeemable={togglePromoCodeOfferRedeemable}
        />
      )}
      {!showPromoCodeUI && showRedemptionButtons && (
        <RedemptionButtons
          showMobileRedemptionButton={showMobileRedemptionButton}
          disableMobileOfferRedemptions={disableMobileOfferRedemptions}
          disableInRestaurantOfferRedemptions={disableInRestaurantOfferRedemptions}
          showSignUpToRedeem={showSignUpToRedeem}
          showRestaurantRedemptionButton={showRestaurantRedemptionButton}
          onRedeemOffer={onRedeemOffer}
          redemptionErrorMessage={redemptionErrorMessage}
          restaurantRedemptionButtonUiType={restaurantRedemptionButtonUiType}
          noPossibleRedemption={noPossibleRedemption}
        />
      )}
    </>
  );
};

export default RedemptionPanel;
