import { Box } from '@rbilabs/universal-components';

export const Container = Box.withConfig({
  flexDir: 'row',
  width: 'full',
});

export const CardContainerWrapper = Box.withConfig({
  width: 'inherit',
  marginBottom: '$3',
});

const SideButtonWrapped = Box.withConfig({
  flexDirection: 'row',
  width: '$10',
  alignItems: 'center',
});

export const RightButtonWrapper = SideButtonWrapped.withConfig({
  justifyContent: 'flex-end',
  marginLeft: '$4',
});

export const LeftButtonWrapper = SideButtonWrapped.withConfig({
  justifyContent: 'flex-start',
  marginRight: '$4',
});
