import { FC, PropsWithChildren } from 'react';

import { Drawer } from 'components/drawer';
import { useIsTabletBp } from 'hooks/breakpoints';
import noop from 'utils/noop';

import { DESKTOP_PRODUCT_CUSTOMIZATION_WIDTH } from '../constants';

export const ProductCustomizationContainer: FC<PropsWithChildren> = ({ children }) => {
  const isTablet = useIsTabletBp();
  if (!isTablet) {
    return <>{children}</>;
  }
  return (
    <Drawer
      isOpen
      position="right"
      onClose={noop}
      contentWidth={DESKTOP_PRODUCT_CUSTOMIZATION_WIDTH}
    >
      {children}
    </Drawer>
  );
};
