import { isNil, partition } from 'lodash-es';

import {
  ICombo,
  IComboSlot,
  IComboSlotSelections,
  IItem,
  IItemOption,
  IModifierSelection,
  IPicker,
  IPickerOption,
  PickerSelection,
} from '@rbi-ctg/menu';
import { MenuObjectTypes } from 'enums/menu';
import { ModifierComponentStyle } from 'utils/menu/modifiers';

export interface OptionQuantityError {
  quantity: number;
  minAmount: number;
  maxAmount: number;
  respectMaximum: boolean;
  name: string;
}

const getItemOptionError = (
  itemOption: IItemOption,
  modSelections: IModifierSelection[],
  item: IItem | IPicker
): OptionQuantityError | null => {
  if (itemOption.componentStyle === ModifierComponentStyle.Stepper) {
    return null;
  }
  const quantity = modSelections.reduce(
    (total, selection) =>
      selection._key === itemOption._key && selection.itemId === item._id
        ? total + selection.modifier.quantity
        : total,
    0
  );

  if (quantity < itemOption.minAmount || quantity > itemOption.maxAmount) {
    return {
      quantity,
      name: itemOption.name.locale,
      minAmount: itemOption.minAmount,
      maxAmount: itemOption.maxAmount,
      respectMaximum: false,
    };
  }

  return null;
};

const getComboSlotItemOptionError = (
  comboSlot: IComboSlot,
  itemOption: IItemOption | IPickerOption,
  modSelections: IModifierSelection[],
  item: IItem | IPicker
): OptionQuantityError | null => {
  if (itemOption._type !== MenuObjectTypes.ITEM_OPTION || !itemOption.options) {
    // if not an item option, requirements are met
    return null;
  }

  const relevantModSelections = modSelections.filter(
    modSelection => modSelection.comboSlotId === comboSlot._id
  );

  return getItemOptionError(itemOption, relevantModSelections, item);
};

export const getModifierErrors = (
  selectedPicker: PickerSelection,
  modSelections: IModifierSelection[]
) => {
  let options: IItemOption[];
  let item: IItem;

  // If selected picker is an item then just get its modifiers
  if (selectedPicker._type === MenuObjectTypes.ITEM) {
    options = selectedPicker.options;
    item = selectedPicker;
  } else {
    // If selected picker is not an item then get modifiers from the set main item
    if (!selectedPicker.mainItem || !selectedPicker.mainItem.options) {
      return [];
    }
    options = selectedPicker.mainItem.options;
    item = selectedPicker.mainItem;
  }

  // if options is null or [], modifier requirements are met
  if (!options || options.length === 0) {
    return [];
  }

  return options.reduce<OptionQuantityError[]>((errors, itemOption) => {
    const error = getItemOptionError(itemOption, modSelections, item);

    if (error) {
      errors.push(error);
    }

    return errors;
  }, []);
};

export const getComboSlotOptionErrors = (
  selectedPicker: ICombo,
  comboSlotSelectionsMap: IComboSlotSelections = {},
  modSelections: IModifierSelection[]
): OptionQuantityError[] => {
  return (selectedPicker.options || []).flatMap(comboSlot => {
    const selectionsForCurrentComboSlot = comboSlotSelectionsMap[comboSlot._id];

    return (comboSlot.options || []).flatMap<OptionQuantityError>(comboSlotOption => {
      const selection =
        selectionsForCurrentComboSlot &&
        selectionsForCurrentComboSlot.selections.find(
          ({ option }) => option.option._id === comboSlotOption.option._id
        );

      // if the comboSlotOption is optional (min === 0), only check modifiers if
      // the user has already made a selection for it
      if (comboSlotOption.minAmount === 0 && (!selection || !selection.quantity)) {
        return [];
      }

      if (!selection || !selection.quantity || selection.quantity < comboSlotOption.minAmount) {
        return {
          quantity: selection?.quantity ?? 0,
          minAmount: comboSlotOption.minAmount,
          maxAmount: comboSlotOption.maxAmount,
          respectMaximum: false,
          name: comboSlot.name.locale,
        };
      }

      // if the combo slot option has modifiers, check that they are satisfied
      if (comboSlotOption.option && comboSlotOption.option.options) {
        const item = comboSlotOption.option;

        return (item.options as Array<IPickerOption | IItemOption>).reduce<OptionQuantityError[]>(
          (errors, itemOption) => {
            const error = getComboSlotItemOptionError(comboSlot, itemOption, modSelections, item);

            if (error) {
              errors.push(error);
            }

            return errors;
          },
          []
        );
      }

      return [];
    });
  });
};

const getComboSlot = ({ combo, id }: { combo: ICombo; id: string }): IComboSlot | undefined => {
  return (combo.options || []).find(option => option._id === id);
};

export const getComboSlotErrors = (
  selectedPicker: ICombo,
  comboSlotSelectionsMap: IComboSlotSelections = {}
) => {
  return Object.entries(comboSlotSelectionsMap)
    .filter(([id]) => id !== 'updatedComboSlot')
    .reduce<OptionQuantityError[]>((results, [id, { selections }]) => {
      const comboSlot = getComboSlot({ combo: selectedPicker, id });

      if (!comboSlot) {
        return results;
      }

      const quantitySelected = selections.reduce((total, { quantity }) => total + quantity, 0);

      if (quantitySelected < comboSlot.minAmount) {
        results.push({
          quantity: quantitySelected,
          minAmount: comboSlot.minAmount,
          maxAmount: comboSlot.maxAmount,
          respectMaximum: comboSlot.respectMaximum,
          name: comboSlot.name.locale,
        });
      }

      return results;
    }, []);
};

export const getRequirementsErrors = (
  selectedPicker: PickerSelection,
  modifierSelections: IModifierSelection[],
  comboSlotSelections?: IComboSlotSelections
) => {
  if (selectedPicker._type === 'item') {
    return getModifierErrors(selectedPicker, modifierSelections);
  }

  const [mainItemModifiers, comboSlotModifiers] = partition(modifierSelections, ({ comboSlotId }) =>
    isNil(comboSlotId)
  );

  return [
    getModifierErrors(selectedPicker, mainItemModifiers),
    getComboSlotErrors(selectedPicker, comboSlotSelections),
    getComboSlotOptionErrors(selectedPicker, comboSlotSelections, comboSlotModifiers),
  ].flat();
};
