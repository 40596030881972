import { useRoute } from 'hooks/navigation/use-route';
import { LaunchDarklyFlag, useFlag, useLDContext } from 'state/launchdarkly';
import { usePageManagerContext } from 'state/static-page-manager';

export const useIsEnrollmentEnabled = () => {
  const { ldUserIsAuthenticated } = useLDContext();
  const { routes: staticPageRoutes } = usePageManagerContext();
  const { pathname } = useRoute();
  const isOptInModalEnabled = useFlag(LaunchDarklyFlag.ENABLE_LOYALTY_OPT_IN_MODAL);

  // Check if page is static to avoid showing loyalty opt in.
  const isStaticPage = staticPageRoutes.some(route => `/${route.path.current}` === pathname);

  return isOptInModalEnabled && !isStaticPage && ldUserIsAuthenticated;
};
