import React from 'react';

import { Box, Header, Text } from '@rbilabs/universal-components';

import { ISanityImage } from '@rbi-ctg/menu';
import { OfferBadges } from 'components/offer-badges';
import Picture from 'components/picture';
import { IImageFragment } from 'generated/sanity-graphql';

import { OfferPictureContainer, OfferPictureSizer } from './offer-picture-container.styled';
import theme from './theme';

export const Container = Box.withConfig({
  alignItems: 'center',
  width: 'full',
  height: 'full',
  backgroundColor: Styles.color.background,
});

export const UnconstrainedHeightContainer = Container.withConfig({
  height: 'auto',
});

export const BackgroundPicture = Picture.withConfig({
  position: 'absolute',
  left: 0,
  top: 0,
  width: 'full',
  flex: 1,
});

const StyledPicture = Picture.withConfig({
  position: 'absolute',
  w: 'full',
  h: 'full',
});

interface IOfferPictureProps {
  image: ISanityImage | IImageFragment;
  alt: string;
  objectFitContain?: boolean;
}

export const OfferPicture: React.FC<React.PropsWithChildren<IOfferPictureProps>> = ({
  image,
  alt,
  objectFitContain = true,
}) => (
  <OfferPictureContainer>
    <OfferPictureSizer />
    <StyledPicture alt={alt} image={image} objectFitContain={objectFitContain} />
  </OfferPictureContainer>
);

export const ImgBgContainer = Box.withConfig({
  justifyContent: 'center',
  width: 'full',
  marginBottom: '4',
});

export const OfferInfoContainer = Box.withConfig({
  alignItems: 'center',
  width: {
    base: '4/5',
    md: '65%',
  },
});

export const Name = Header.withConfig({
  accessibilityLevel: 2,
  margin: 0,
  marginBottom: {
    base: '2',
    md: '9',
  },
  textTransform: theme.headingTextTransform,
  color: Styles.color.black,
  fontSize: {
    base: 'xl',
    md: '4xl',
  },
  textAlign: 'center',
  lineHeight: '24px',
});

export const Description = Text.withConfig({
  margin: 0,
  marginBottom: '6',
  textAlign: 'center',
  fontSize: {
    base: 'xs',
    md: 'md',
  },
  lineHeight: {
    base: '20px',
    md: '22px',
  },
  color: theme.descriptionColor,
});

export const ErrorMessage = Text.withConfig({
  variant: 'copyOne',
  margin: 0,
  marginTop: {
    base: '3',
    md: '4',
  },
  fontSize: 'xs',
  color: Styles.color.error,
  textAlign: 'center',
});

// TODO - RN: Confirm css is correct. Removed :not(:empty) css here
// &:not(:empty) {
//   margin: 0;
//   margin-bottom: 24px;
// }
export const StyledOfferBadges = OfferBadges.withConfig({
  justifyContent: 'flex-end',
  marginBottom: '6',
});
