import React from 'react';

import { Box, Header, Pressable, Text } from '@rbilabs/universal-components';

import { IBaseProps, OnClick } from '@rbi-ctg/frontend';
import { IOffer } from '@rbi-ctg/menu';
import { OfferBadges } from 'components/offer-badges';
import {
  IMG_FIT_CONTAIN,
  INCLUDE_BG_IMG,
  SELECTED_OFFER_BORDER,
} from 'components/offers/ui-refresh/constants';
import Picture from 'components/picture';
import SanityBlockRenderer, {
  IBlockContentOverrides,
  ISerializers,
  PassthroughComponent,
} from 'components/sanity-block-renderer';
import { UIPattern } from 'state/offers/types';
import { IDays, IHours } from 'utils/offers/rule-sets/time-left-to-redeem';

import theme from './theme';

const Wrapper = Box.withConfig({
  width: 'full',
  borderBottomWidth: '1px',
  borderBottomColor: Styles.color.grey.five,
});

export const OFFER_SIDES_PADDING = '14px';
export const OFFER_TOP_BOTTOM_PADDING = '8px';
export const OFFER_CONTAINER_PADDING = `${OFFER_TOP_BOTTOM_PADDING} ${OFFER_SIDES_PADDING}`;

export const StyledClickableContainer = Pressable.withConfig<{ disabled: boolean }>(p => ({
  borderWidth: 0,
  textAlign: 'left',
  width: 'full',
  padding: OFFER_CONTAINER_PADDING,
  backgroundColor: p.disabled ? Styles.color.grey.five : Styles.color.white,
}));

/**
 * This component avoids scenarios where a typical `border` on the Wrapper
 * would make the selected Offer slightly larger than its non-selected siblings
 */
const SelectedState = Box.withConfig<{ selected: boolean; disabled: boolean }>(p => ({
  position: 'absolute',
  left: 0,
  top: 0,
  flex: 1,
  width: 'full',
  borderRightWidth: '2.5',
  borderRightColor: p.selected ? SELECTED_OFFER_BORDER : Styles.color.grey.four,
  // TODO : RN - CSS transition: transition: opacity 0.25s
  opacity: p.selected ? 1 : 0,
  _hover: !p.selected && !p.disabled && { opacity: 1 },
}));

const ContentRow = Box.withConfig({
  flexDirection: 'row',
  alignItems: 'center',
  flex: 1,
  width: 'full',
});

export const OFFER_HEIGHT_MOBILE = '110px';
export const OFFER_HEIGHT_DESKTOP = '138px';

export const PictureContainer = Box.withConfig({
  width: {
    base: '130px',
    md: '164px',
  },
  height: {
    base: OFFER_HEIGHT_MOBILE,
    md: OFFER_HEIGHT_DESKTOP,
  },
  borderRadius: '2',
  backgroundColor: Styles.color.background,
});

const BackgroundPicture = Picture.withConfig({
  position: 'absolute',
  left: 0,
  top: 0,
  width: 'full',
  flex: 1,
});

const StyledPicture = Picture.withConfig({
  width: 'full',
  flex: 1,
});

const TextContainer = Box.withConfig({
  flex: 1,
  marginLeft: '4',
});

const Name = Header.withConfig({
  variant: 'headerThree',
  accessibilityLevel: 3,
  margin: 0,
  marginBottom: {
    base: '1',
    md: '2',
  },
  textTransform: theme.headingTextTransform,
  color: Styles.color.black,
  numberOfLines: 2,
});

const nameBlockContentOverrides: IBlockContentOverrides = {
  normal: Name,
};

const nameBlockSerializers: ISerializers = {
  marks: {
    em: PassthroughComponent,
  },
  container: PassthroughComponent,
};

const Description = Text.withConfig({
  variant: 'copyOne',
  fontSize: 'sm',
  margin: 0,
  color: theme.descriptionColor,
  numberOfLines: 2,
});

const descriptionBlockContentOverrides: IBlockContentOverrides = {
  normal: Description,
};

const descriptionBlockSerializers: ISerializers = {
  container: PassthroughComponent,
};

// TODO - RN: Confirm css is correct. Removed :not(:empty) css here
// &:not(:empty) {
//   margin-top: 8px;
// }
export const StyledOfferBadges = OfferBadges.withConfig({
  justifyContent: 'flex-start',
  marginTop: '0.5',
});

interface ICellLayoutProps extends IBaseProps {
  offer: IOffer;
  onPress: OnClick;
  isSelected: boolean;
  redeemable: boolean;
  enableOfferBadges: boolean;
  endingIn: IDays | IHours | null;
  platforms: string[];
  displayLoginRequiredBadge: boolean;
}

export const CellLayout: React.FC<React.PropsWithChildren<ICellLayoutProps>> = ({
  offer,
  onPress,
  isSelected,
  redeemable,
  enableOfferBadges,
  endingIn,
  platforms,
  displayLoginRequiredBadge,
}) => {
  const disableCell = !redeemable;

  return (
    <Wrapper testID={`${offer._id}__offer`}>
      <StyledClickableContainer onPress={onPress} disabled={disableCell}>
        <ContentRow>
          <PictureContainer>
            {INCLUDE_BG_IMG && offer.backgroundImage && (
              <BackgroundPicture alt="" image={offer.backgroundImage} objectFitContain={false} />
            )}
            {offer?.localizedImage?.locale?.app && (
              <StyledPicture
                alt={offer.localizedImage?.locale.imageDescription ?? ''}
                image={offer.localizedImage?.locale?.app}
                objectFitContain={IMG_FIT_CONTAIN}
              />
            )}
          </PictureContainer>
          <TextContainer testID={`cell-layout-text-container-${offer._id}`}>
            <SanityBlockRenderer
              blockContentOverrides={nameBlockContentOverrides}
              serializers={nameBlockSerializers}
              content={offer?.name?.localeRaw}
            />
            <SanityBlockRenderer
              blockContentOverrides={descriptionBlockContentOverrides}
              serializers={descriptionBlockSerializers}
              content={offer?.description?.localeRaw}
            />
            {enableOfferBadges && (
              <StyledOfferBadges
                platforms={platforms}
                displayLoginRequiredBadge={displayLoginRequiredBadge}
                endingIn={endingIn}
                uiPattern={offer.uiPattern as UIPattern}
                isUnlocked={!!offer.isRedeemable}
              />
            )}
          </TextContainer>
        </ContentRow>
        <SelectedState
          testID={`${offer._id}__offer-selected-state`}
          selected={isSelected}
          disabled={disableCell}
        />
      </StyledClickableContainer>
    </Wrapper>
  );
};
