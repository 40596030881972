import {
  Box,
  Header,
  Text,
  ZStack,
  addWithConfig,
  makeUclComponent,
} from '@rbilabs/universal-components';

import UniversalLink from 'components/universal-link';

import NavLinkExternal from './nav-link-external';

export const Container = Box.withConfig({
  paddingY: '$4',
  _text: {
    color: Styles.color.white,
  },
});

export const MeterContainer = ZStack.withConfig({
  width: 'full',
  alignItems: 'center',
  justifyContent: 'center',
});

export const PointsContainer = Box.withConfig<{ containerHeight: number }>(p => ({
  // TODO: RN - fontFamily: 'brand',
  height: `${p.containerHeight}px`,
  justifyContent: 'center',
}));

export const PointsText = Text.withConfig({
  // TODO: RN - fontFamily: 'brand',
  color: Styles.color.white,
  textAlign: 'center',
  fontSize: {
    base: '5xl',
    md: '7xl',
  },
  lineHeight: 'md',
});

export const PointsLabel = Header.withConfig({
  variant: 'headerTwo',
  // TODO: RN - fontFamily: 'brand',
  textAlign: 'center',
  fontSize: {
    base: 'lg',
    md: '3xl',
  },
  color: Styles.color.white,
  margin: 0,
});

export const RewardTextContainer = Box.withConfig({
  textAlign: 'center',
});

export const RewardTitle = Header.withConfig({
  variant: 'headerTwo',
  // TODO: RN - fontFamily: 'brand',
  fontSize: {
    base: 'md',
    md: 'lg',
  },
  textAlign: 'center',
  margin: 0,
  color: Styles.color.white,
});

export const RewardDetails = Text.withConfig({
  fontSize: { base: 'sm', md: 'md' },
  textAlign: 'center',
  color: Styles.color.white,
});

export const PointsTextContainer = Box;

export const MeterIconInnerWrapper = Box;

export const PointMeterCtaButton = makeUclComponent(UniversalLink).withConfig({
  marginY: '$4',
  marginX: 'auto',
});

export const ExternalLinkStyled = addWithConfig(NavLinkExternal).withConfig({
  _text: {
    color: Styles.color.primary,
  },
  paddingY: '$3',
  paddingX: '$8',
  backgroundColor: Styles.color.white,
  borderRadius: 'full',
});
