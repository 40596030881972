import * as React from 'react';
import { useMemo } from 'react';

// @ts-expect-error TS(2307) FIXME: Cannot find module 'graphql/tsutils/Maybe' or its ... Remove this comment to see the full error message
import Maybe from 'graphql/tsutils/Maybe';

import { IWidgetElement, IWidgetKey, IWidgetMap, hasComponentKey } from './types';

const useWidgetComponents = <TWidgetKeys extends string>(
  widgetMap: IWidgetMap<TWidgetKeys>,
  widgetKeys: Maybe<ReadonlyArray<Maybe<IWidgetKey>>>
): IWidgetElement[] => {
  const widgetComponents = useMemo(() => {
    if (!widgetKeys?.length) {
      return [];
    }

    // Maps Sanity widget key to widget component
    const createWidgetComponents = (keys: ReadonlyArray<Maybe<IWidgetKey>>) =>
      keys.reduce((acc: IWidgetElement[], widget, idx) => {
        if (hasComponentKey<IWidgetKey>(widget)) {
          const { componentKey, _key, ...props } = widget;
          const Component = componentKey && widgetMap[componentKey];
          if (widgetMap[componentKey]) {
            const id = `${_key || idx}`;
            acc.push({
              element: <Component key={id} {...(props as any)} />,
              id,
            });
          }
        }

        return acc;
      }, []);

    return createWidgetComponents(widgetKeys);
  }, [widgetKeys, widgetMap]);

  return widgetComponents;
};

export default useWidgetComponents;
