import React from 'react';

import { Box, HStack, Icon, Text, makeUclComponent } from '@rbilabs/universal-components';
import {
  Defs,
  LinearGradientProps,
  LinearGradient as RNSVGLinearGradient,
  Rect,
  Stop,
  Svg,
} from 'react-native-svg';

import ActionButton from 'components/action-button';
import { ClickableContainer } from 'components/clickable-container';
import Picture from 'components/picture';
import SanityBlockRenderer from 'components/sanity-block-renderer';

export const CARD_WIDTH = '250px';

/**
 * LockedOffersCardView
 */
export const Container = Box.withConfig({
  borderTopLeftRadius: 4,
  borderTopRightRadius: 4,
  borderBottomRightRadius: 4,
  borderBottomLeftRadius: 4,
  width: CARD_WIDTH,
  minWidth: CARD_WIDTH,
});

export const BackgroundImageContainer = makeUclComponent(Picture).withConfig({});

/**
 * LockedCard
 */
export const CardContainer = ClickableContainer.withConfig<{
  centerVertical?: boolean;
}>(p => ({
  flexDirection: 'column',
  backgroundColor: Styles.color.background,
  paddingTop: '9px',
  paddingRight: '$4',
  paddingBottom: '$3',
  paddingLeft: '$4',
  width: 'full',
  height: 'full',
  shadow: '2',
  rounded: 'lg',
  justifyContent: 'space-between',
}));

export const Header = SanityBlockRenderer.withConfig({
  textProps: {
    variant: 'headerOne',
  },
});

export const ErrorText = Box.withConfig({
  _text: {
    textAlign: 'center',
  },

  paddingX: 0,
  paddingY: '$4',
});

export const ErrorControlsContainer = Box.withConfig({
  flexDirection: 'row',
  paddingX: 0,
  paddingY: '$3',
  justifyContent: 'center',
});

export const SubheaderContainer = Box.withConfig<{ mode: string }>(p => ({
  width: p.mode ? 'full' : '5/6;',
}));

export const Subheader = SanityBlockRenderer.withConfig({
  textProps: {
    variant: 'headerThree',
  },
});

export const CardFooter = HStack.withConfig({
  space: '$2',
});

export const ButtonContainer = Box.withConfig({
  flexDirection: 'row',
});

export const Countdown = Text.withConfig({
  variant: 'copyTwo',
  justifyContent: 'center',
});

export const StyledActionButton = ActionButton;

/**
 * OfferStepper
 */
export const Circle = Icon.withConfig({
  variant: 'checkCircle',
  color: Styles.color.primary,
});

export const CircleDot = Box.withConfig({
  width: '$6',
  height: '$6',
  borderTopLeftRadius: 240,
  borderTopRightRadius: 240,
  borderBottomRightRadius: 240,
  borderBottomLeftRadius: 240,
  borderTopWidth: '8',
  borderBottomWidth: '8',
  borderLeftWidth: '8',
  borderRightWidth: '8',
  borderColor: Styles.color.grey.four,
  borderStyle: 'solid',
  backgroundColor: Styles.color.white,
});

export const Stepper = HStack.withConfig({
  paddingBottom: '$4',
  justifyContent: 'center',
});

export const Checkpoint = Box.withConfig({
  zIndex: Styles.zIndex.normal,
});

export const CheckpointDetail = Box.withConfig({
  display: 'flex',
  flexDirection: 'column',
});

export const Label = Text.withConfig({
  padding: '$2',
  fontSize: 'xs',
  fontWeight: 'extrabold',
});

// Setup this way to pass typechecking as react-native-svg is not react 18 ready
const LinearGradient: React.FC<React.PropsWithChildren<LinearGradientProps>> = props => {
  return <RNSVGLinearGradient {...props} />;
};

export const Segment: React.FC<{ totalSteps: number; percentComplete: number }> = ({
  totalSteps,
  percentComplete,
}) => {
  return (
    <Box width={`1/${totalSteps}`} height={'3px'} marginTop={'auto'} marginBottom={'10px'}>
      <Svg width="100%" height="100%">
        <Defs>
          <LinearGradient id="grad" x1="0" y1="0" x2="1" y2="0">
            <Stop offset={`0`} stopColor={Styles.color.primary} stopOpacity="1" />
            <Stop offset={`${percentComplete}%`} stopColor={Styles.color.primary} stopOpacity="1" />
            <Stop
              offset={`${percentComplete}%`}
              stopColor={'#eee'} // @TODO RN: setting Styles.color.grey.four causes invalid color, may be related to JIT/class name template string
              stopOpacity="1"
            />
          </LinearGradient>
        </Defs>
        <Rect x="0" y="0" height="100%" width="100%" fill="url(#grad)" />
      </Svg>
    </Box>
  );
};

/**
 * OfferList
 */
export const List = Box.withConfig({
  height: 'auto',
  padding: '$2',
});

export const PictureIcon = Picture.withConfig({
  padding: '1px',
  marginLeft: '$1.5',
  width: '30px',
  height: '30px',
});

export const ItemDetail = Box.withConfig<{ isComplete: boolean }>(p => ({
  flexDirection: 'row',
  alignItems: 'center',

  _text: {
    color: p.isComplete ? Styles.color.primary : Styles.color.secondary,
  },
}));
export const ProductLabel = Box.withConfig({
  paddingX: '$1.5',
  paddingY: '$1',

  _text: {
    fontSize: 'sm',
  },
});
