import * as React from 'react';
import { FC, useMemo } from 'react';

import { useIntl } from 'react-intl';

import Table from 'components/table';
import { getTransactionTableHistoryStructure } from 'pages/loyalty/loyalty-history/transaction-table-structure';

import { SubText, TableWrap } from './loyalty-history-widget.styled';
import { LoyaltyHistoryWidgetTableProps } from './types';

const LoyaltyHistoryTable: FC<React.PropsWithChildren<LoyaltyHistoryWidgetTableProps>> = ({
  transactions,
}) => {
  const { formatMessage } = useIntl();

  const table = useMemo(
    () =>
      getTransactionTableHistoryStructure(
        formatMessage,
        transactions,
        formatMessage({ id: 'date' })
      ),
    [formatMessage, transactions]
  );

  return (
    <>
      <SubText to={`${formatMessage({ id: 'routes.loyaltyHome' })}/history`}>
        {formatMessage({ id: 'viewAllPurchases' })}
      </SubText>

      <TableWrap>
        <Table table={table} />
      </TableWrap>
    </>
  );
};

export default LoyaltyHistoryTable;
