import { OffersNavigator } from 'navigation/offers-navigator';
import { routes } from 'utils/routing';

import type { RawTab } from '../../types';

export const id: RawTab['id'] = 'offers';

export const screen: RawTab['screen'] = {
  Component: OffersNavigator,
};

export const text: RawTab['text'] = {
  en: 'Offers',
  fr: 'Offres',
};

export const link: RawTab['link'] = {
  en: routes.loyaltyOfferList,
  fr: routes.loyaltyOfferList,
};
