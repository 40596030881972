import React, { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';

import { IBaseProps } from '@rbi-ctg/frontend';
import { IOffer } from '@rbi-ctg/menu';
import {
  IEvaluateUserOffersQuery,
  IOfferFeedbackEntryFragment,
  Locale,
  useEvaluateUserOffersQuery,
} from 'generated/rbi-graphql';
import { useNavigation } from 'hooks/navigation/use-navigation';
import { useRoute } from 'hooks/navigation/use-route';
import { useAuthContext } from 'state/auth';
import { useErrorContext } from 'state/errors';
import { useLocale } from 'state/intl';
import { useOffersContext } from 'state/offers';
import { mapOfferDataToNewOffer } from 'state/offers/util';
import { useOrderContext } from 'state/order';
import { useServiceModeContext } from 'state/service-mode';
import { useStoreContext } from 'state/store';
import { platform } from 'utils/environment';
import { redeemedOnTime } from 'utils/offers';
import { routes } from 'utils/routing';

import { shouldShowDeliveryMessage } from '../should-show-delivery-message';

import { IFeatureOffers, useFeatureOffers } from './use-feature-offers';

export interface IOffersUIContext {
  suffixUrl: string;
  offersUrl: string;
  showDeliveryMessage: boolean;
  loading: boolean;
  showEmptyState: boolean;
  showMissingOffer: boolean;
  showMobileBackButton: boolean;
  authenticated: boolean;
  onSelectOffer: (offerId: string, isUpsell?: boolean) => void;
  previewOffer: IOffer | undefined;
  previewOfferId: string | null;
  previewOfferFeedback: IOfferFeedbackEntryFragment | null;
  offers: IOffer[];
  offersFeedback: Record<string, IOfferFeedbackEntryFragment> | undefined;
  refreshOffers: () => Promise<void>;
  offersLoading: boolean;
  featureOffers: IFeatureOffers | null;
  togglePromoCodeOfferRedeemable: (offerId: string) => void;
}

export const OffersUIContext = createContext<IOffersUIContext>({} as IOffersUIContext);

export const useOffersUIContext = () => useContext(OffersUIContext);

interface IOffersUIProviderProps extends IBaseProps {
  suffixUrl: string;
  currentUrl: string;
  urlOfferId: string | null;
}

export const OffersUIProvider: React.FC<React.PropsWithChildren<IOffersUIProviderProps>> = ({
  suffixUrl,
  currentUrl,
  children,
  urlOfferId,
}) => {
  const [redeemedOn, setRedeemedOn] = useState<string>(redeemedOnTime());
  const { navigate } = useNavigation();
  const { pathname } = useRoute();
  const { setSanityDataRef } = useErrorContext();
  const { language } = useLocale();
  const { isDelivery } = useOrderContext();
  const {
    loading: offersLoading,
    offers,
    offersFeedback,
    refreshOffers,
    togglePromoCodeOfferRedeemable,
    redeemedOffer,
    selectedOffer,
    setUpsellOfferId,
  } = useOffersContext();
  const [previewOffer, setPreviewOffer] = useState<IOffer | undefined>(
    redeemedOffer || (urlOfferId ? offers.find(offer => offer._id === urlOfferId) : offers[0])
  );
  const { store } = useStoreContext();
  const { serviceMode } = useServiceModeContext();
  const { isAuthenticated: authenticated } = useAuthContext();
  const { featureOffersLoading, featureOffers } = useFeatureOffers();

  useEffect(() => {
    // want to check the redeemed offer first in case modal is still open
    setPreviewOffer(
      redeemedOffer || (urlOfferId ? offers.find(offer => offer._id === urlOfferId) : offers[0])
    );
  }, [offers, redeemedOffer, urlOfferId]);

  const { data: singleOfferData, loading: singleOfferLoading } = useEvaluateUserOffersQuery({
    skip: !!previewOffer,
    fetchPolicy: 'network-only',
    variables: {
      couponIds: [urlOfferId],
      locale: language as Locale,
      platform: platform(),
      serviceMode,
      redeemedOn,
      storeId: store.number,
    },
  });

  const updateSingleOffer = useCallback(
    (queryData: IEvaluateUserOffersQuery | undefined) => {
      if (!queryData?.evaluateUserOffers?.offersFeedback?.[0]?.offerDetails) {
        setPreviewOffer(undefined);
        return;
      }

      // Get all redeemable user offers in the same order as sortedOffers
      const { offersFeedback: offerFeedbackResult } = queryData.evaluateUserOffers;

      const mappedOffer = mapOfferDataToNewOffer({
        offerFeedback: offerFeedbackResult[0] as IOfferFeedbackEntryFragment,
        language,
      });

      setPreviewOffer(mappedOffer);
    },
    [language]
  );

  useEffect(() => {
    if (!singleOfferLoading && singleOfferData) {
      updateSingleOffer(singleOfferData);
    }
  }, [singleOfferData, updateSingleOffer, singleOfferLoading]);

  // set data ref as the offers and
  useEffect(() => {
    if (pathname.includes(routes.offers) && offers) {
      setSanityDataRef({ previewOffer, offers });
    }
  }, [offers, pathname, previewOffer, setSanityDataRef]);
  const previewOfferId = useMemo(() => previewOffer?._id || null, [previewOffer]);
  const previewOfferFeedback =
    previewOfferId && offersFeedback ? offersFeedback[previewOfferId] : null;

  const loading = offersLoading || featureOffersLoading;
  const showEmptyState = !loading && offers.length === 0 && !previewOffer;

  const showMissingOffer = !loading && !previewOffer && !singleOfferLoading;
  const showMobileBackButton = !!urlOfferId;
  const offersUrl = `${currentUrl}${suffixUrl}`;
  const showDeliveryMessage = shouldShowDeliveryMessage({
    offers,
    loading: offersLoading,
    isDelivery,
  });

  const onSelectOffer = useCallback(
    (offerId: string, isUpsell = false) => {
      // TODO - RN: Remove before we go live. Added this guard to make sure I didn't miss something
      if (!offersUrl.startsWith('/')) {
        throw new Error('offersUrl didnt start with / but it is expected to prefix with /');
      }
      navigate(`${offersUrl}/${offerId}`);
      setUpsellOfferId(isUpsell ? offerId : null);
    },
    [navigate, offersUrl, setUpsellOfferId]
  );

  useEffect(() => {
    setRedeemedOn(redeemedOnTime());
  }, [offersUrl]);

  const contextValue: IOffersUIContext = useMemo(
    () => ({
      suffixUrl,
      offersUrl,
      showDeliveryMessage,
      loading,
      showEmptyState,
      showMissingOffer,
      showMobileBackButton,
      authenticated,
      onSelectOffer,
      previewOffer,
      previewOfferId,
      previewOfferFeedback,
      offers,
      offersFeedback,
      refreshOffers,
      offersLoading,
      featureOffers,
      togglePromoCodeOfferRedeemable,
      selectedOffer,
    }),
    [
      suffixUrl,
      offersUrl,
      showDeliveryMessage,
      loading,
      showEmptyState,
      showMissingOffer,
      showMobileBackButton,
      authenticated,
      onSelectOffer,
      previewOffer,
      previewOfferId,
      previewOfferFeedback,
      offers,
      offersFeedback,
      refreshOffers,
      offersLoading,
      featureOffers,
      togglePromoCodeOfferRedeemable,
      selectedOffer,
    ]
  );

  return <OffersUIContext.Provider value={contextValue}>{children}</OffersUIContext.Provider>;
};
