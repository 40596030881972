import React, { VFC, useCallback, useMemo } from 'react';

import { ValueStepper } from '@rbilabs/universal-components';

import { useCRMEventsContext } from 'state/crm-events';

import { ModifierListItem } from './modifier-list-item';
import { IModifierTypeUIProps } from './types';
import {
  getModifierOptionMultipliers,
  getSingleSelectedOption,
  logProductModifierCustomization,
  transformSelectedValueToSelections,
} from './utils';

export const ModifierNumeric: VFC<IModifierTypeUIProps> = ({
  modifier,
  selections,
  onSelectionsChange,
}) => {
  const selectedOption = useMemo(() => getSingleSelectedOption(selections), [selections]);
  const selectedOptionIndex = useMemo(
    () => modifier.options.findIndex(opt => opt._key === selectedOption._key),
    [modifier.options, selectedOption._key]
  );
  const { logRBIEvent } = useCRMEventsContext();
  const handleNumberInputChange = useCallback(
    (newOptionsValue: number) => {
      const newSelectedOption = modifier.options[newOptionsValue];
      onSelectionsChange(transformSelectedValueToSelections(modifier.options, newOptionsValue));
      logProductModifierCustomization(
        newSelectedOption.name?.locale ?? '',
        modifier.name.locale,
        newSelectedOption._key,
        logRBIEvent
      );
    },
    [modifier.options, onSelectionsChange]
  );
  const options = getModifierOptionMultipliers(modifier.options);

  return (
    <ModifierListItem
      content={modifier}
      selectedOption={selectedOption}
      controls={
        <ValueStepper<Number>
          testID="modifier-number-input"
          defaultValue={options[selectedOptionIndex].value}
          value={options[selectedOptionIndex].value}
          // @ts-expect-error TS(2322) FIXME: Type '(newOptionsValue: number) => void' is not as... Remove this comment to see the full error message
          onChangeValue={handleNumberInputChange}
          options={options}
          accessibilityHint=""
          accessibilityLabel=""
          _decrementButton={{
            height: 6,
            width: 6,
          }}
          _incrementButton={{
            height: 6,
            width: 6,
          }}
          _selectedOptionLabel={{
            alignItems: 'center',
            justifyContent: 'center',
            height: 12,
            width: 12,
            marginX: 2,
            marginY: 0,
          }}
          space={0}
        />
      }
    />
  );
};
