import { PropsWithChildren } from 'react';

import { Box } from '@rbilabs/universal-components';
import { Image, ImageContentFit } from 'expo-image';
import { Platform } from 'react-native';

import { ImageSource, ImageStyle } from './types';

interface IImageWithPreloadProps {
  testID?: string;
  format?: string;
  loadingStyle: ImageStyle;
  onLoad?: () => void;
  preloadImage: ImageSource['uri'] | undefined;
  priority: NonNullable<ImageSource['priority']>;
  style: ImageStyle;
  uri: NonNullable<ImageSource['uri']>;
  accessibilityLabel?: string;
  contentFit?: ImageContentFit;
  sizedByChildren?: boolean;
}

// there is a known issue with Glide (Android image library) which fails
// to load images more than 5MB unless disk caching is active.
// https://github.com/bumptech/glide/issues/4950
const defaultCachePolicy = Platform.OS === 'android' ? 'disk' : 'memory';

/**
 * ```expo-image``` is not rendering children, it renders at first time, but then it is removed
 * once the image loaded.
 * TODO: verify why ```expo-image``` is not rendering children anymore. Can't revert version since
 * last one has fixes for reported crashes.
 */
const ImageWithPreload: React.FC<PropsWithChildren<IImageWithPreloadProps>> = ({
  format,
  loadingStyle,
  preloadImage,
  uri,
  children,
  sizedByChildren,
  ...props
}) => {
  if (children) {
    return (
      <Box h={sizedByChildren ? undefined : 'full'} justifyContent="center">
        <Box position="absolute" w="full" h="full">
          <Image
            cachePolicy={defaultCachePolicy}
            placeholderContentFit="fill"
            placeholder={preloadImage}
            source={uri}
            transition={0}
            {...props}
            style={{
              ...props.style,
              height: '100%',
              width: '100%',
            }}
          />
        </Box>
        {children}
      </Box>
    );
  }
  return (
    <Image
      cachePolicy={defaultCachePolicy}
      placeholderContentFit="fill"
      placeholder={preloadImage}
      source={uri}
      transition={0}
      {...props}
    />
  );
};

export default ImageWithPreload;
