import { addWithConfig } from '@rbilabs/universal-components';

import ActionButton from 'components/action-button';
import LoyaltyPointsIcon from 'components/icons/loyalty-points-icon';
import { theme } from 'styles/configure-theme';

import componentTheme from './theme';

export const StyledLoyaltyPointsIcon = LoyaltyPointsIcon.withConfig<{ reversed: boolean }>(
  ({ reversed }) => ({
    fill: reversed ? theme.token('icon-reversed') : theme.token('crown-color'),
    size: 17,
  })
);

export const StyledLoyaltyButton = addWithConfig(ActionButton).withConfig<{ reversed: boolean }>(
  ({ reversed }) => ({
    borderColor: reversed ? '__legacyToken.icon-reversed' : componentTheme.buttonContrastColor,
    backgroundColor: reversed
      ? '__legacyToken.background-hero-reversed'
      : '__legacyToken.background-hero-light',

    paddingX: '11px',
    paddingY: '$1.5',
    _text: {
      color: reversed ? '__legacyToken.text-reversed' : componentTheme.buttonContrastColor,
    },
  })
);
