import React, { useMemo } from 'react';

import { Box, Header, Text } from '@rbilabs/universal-components';
import { useIntl } from 'react-intl';

import { INutritionInfo } from '@rbi-ctg/menu';
import Picture from 'components/picture';
import { useFormatCalories } from 'hooks/use-format-calories';
import { useFormatJoules } from 'hooks/use-format-joules';
import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';
import {
  ItemNutritionInfoToHide,
  defaultItemNutritionInfoToHide,
} from 'state/launchdarkly/variations';
import { getUnitForNutrient } from 'utils/menu/units';

import { AdditionalInfo } from '../additional-information';
import { Allergens } from '../allergens';
import { Nutrition } from '../v2/nutrition';
import { TableHeader } from '../v2/table-header';

import { Nutrient } from './item-nutrition.styled';
import { IItemNutritionProps } from './types';

export const ItemNutrition: React.FC<React.PropsWithChildren<IItemNutritionProps>> = ({
  allergenGroups,
  itemImage,
  itemName,
  nutritionInfo,
  enableFeatureNutritionIntegration,
  enableStaticMenuAllergens,
  additionalItemInformation,
}) => {
  const { formatNumber, formatMessage } = useIntl();
  const formatCalories = useFormatCalories();
  const formatJoules = useFormatJoules();

  const enableEUNutritionDisplay = useFlag(LaunchDarklyFlag.ENABLE_EU_NUTRITION_DISPLAY);
  const itemsFromNutritionInfoToHide =
    useFlag<ItemNutritionInfoToHide>(LaunchDarklyFlag.HIDE_ITEMS_FROM_NUTRITION_INFO) ||
    defaultItemNutritionInfoToHide;

  const nutritionIds = useMemo(
    () =>
      enableEUNutritionDisplay
        ? ([
            'weight',
            'calories',
            'carbohydrates',
            'sugar',
            'fat',
            'saturatedFat',
            'proteins',
            'salt',
          ] as const)
        : ([
            'weight',
            'calories',
            'fat',
            'saturatedFat',
            'transFat',
            'cholesterol',
            'sodium',
            'carbohydrates',
            'fiber',
            'sugar',
            'proteins',
          ] as const),
    [enableEUNutritionDisplay]
  );

  const perServing = useMemo(() => {
    return (
      <>
        <TableHeader testID="per-serving-header" title={formatMessage({ id: 'perServing' })} />
        {/* Todo: Remove both `any` casts once on Typescript v4 */}
        {(nutritionIds as any).map((key: keyof INutritionInfo) => {
          const value = nutritionInfo[key];

          if ((key === 'weight' && value === 0) || itemsFromNutritionInfoToHide[key]) {
            return null;
          }

          if (key === 'calories' && enableEUNutritionDisplay) {
            return (
              <Nutrient key={key}>
                <Text textTransform="capitalize">{formatMessage({ id: `eu-${key}` })}</Text>
                <Text>
                  {formatMessage(
                    { id: 'euNutritionValues' },
                    {
                      calories: formatCalories(value),
                      joules: formatJoules(value),
                    }
                  )}
                </Text>
              </Nutrient>
            );
          }

          return (
            <Nutrient key={key}>
              <Text textTransform="capitalize">
                {formatMessage({ id: (enableEUNutritionDisplay ? 'eu-' : 'us-') + key })}
              </Text>
              <Text>
                {`${formatNumber(value, {
                  maximumFractionDigits: 1,
                })} ${getUnitForNutrient(key)}`}
              </Text>
            </Nutrient>
          );
        })}
      </>
    );
  }, [
    formatMessage,
    nutritionIds,
    nutritionInfo,
    itemsFromNutritionInfoToHide,
    enableEUNutritionDisplay,
    formatNumber,
    formatCalories,
    formatJoules,
  ]);

  const perUnit = useMemo(() => {
    if (
      nutritionInfo.weight === 0 ||
      nutritionInfo.weight === undefined ||
      nutritionInfo.weight === null ||
      !enableEUNutritionDisplay
    ) {
      return null;
    }

    const createContent = (key: keyof INutritionInfo) => {
      const weightRatio = nutritionInfo.weight / 100;

      if (key === 'weight') {
        return null;
      }

      const value = nutritionInfo[key] / weightRatio;

      if (key === 'calories' && enableEUNutritionDisplay) {
        return (
          <Nutrient key={key}>
            <Text textTransform="capitalize">{formatMessage({ id: `eu-${key}` })}</Text>
            <Text>
              {formatMessage(
                { id: 'euNutritionValues' },
                {
                  calories: formatCalories(value),
                  joules: formatJoules(value),
                }
              )}
            </Text>
          </Nutrient>
        );
      }

      return (
        <Nutrient key={key}>
          <Text textTransform="capitalize">
            {formatMessage({ id: (enableEUNutritionDisplay ? 'eu-' : 'us-') + key })}
          </Text>
          <Text>
            {`${formatNumber(value, {
              maximumFractionDigits: 1,
            })} ${getUnitForNutrient(key)}`}
          </Text>
        </Nutrient>
      );
    };

    /* Todo: Remove both `any` casts once on Typescript v4 */
    return (nutritionIds as any).map((key: keyof INutritionInfo) => {
      if (itemsFromNutritionInfoToHide[key]) {
        return null;
      }

      return (
        <>
          <TableHeader
            testID="per-mass-header"
            title={formatMessage({ id: 'perOneHundredGOrMl' })}
          />
          {createContent(key)}
        </>
      );
    });
  }, [
    nutritionInfo,
    enableEUNutritionDisplay,
    nutritionIds,
    itemsFromNutritionInfoToHide,
    formatMessage,
    formatNumber,
    formatCalories,
    formatJoules,
  ]);

  return (
    <>
      <Picture
        image={itemImage}
        alt="itemImage"
        objectFitContain
        width={300}
        height={300}
        alignSelf="center"
      />
      <Box marginBottom="$4">
        <Header textAlign="center">{itemName}</Header>
        {enableFeatureNutritionIntegration ? (
          <Nutrition nutritionInfo={nutritionInfo} />
        ) : (
          <>
            {perServing}
            {perUnit}
          </>
        )}
        {additionalItemInformation ? (
          <>
            <TableHeader
              testID="additional-information-header"
              title={formatMessage({ id: 'additionalInfoTitle' })}
            />
            <AdditionalInfo additionalInfo={additionalItemInformation} />
          </>
        ) : null}
        {enableStaticMenuAllergens && !!allergenGroups?.length && (
          <>
            <TableHeader
              testID="allergens-header"
              title={formatMessage({ id: 'allergenInformation' })}
            />
            <Allergens allergenGroups={allergenGroups} />
          </>
        )}
      </Box>
    </>
  );
};
