import React, { useMemo } from 'react';

import { useLocalizedTabs } from 'navigation/tab-navigator/tabs';

import { LeftNav } from './left-nav';

export const LeftNavContainer = () => {
  const localizedTabs = useLocalizedTabs();
  const navLinks = useMemo(
    () => localizedTabs.map(tab => ({ key: tab.id, link: tab.link, text: tab.text })),
    [localizedTabs]
  );
  return <LeftNav navLinks={navLinks} />;
};
