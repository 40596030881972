import React from 'react';

import { BoxProps, HeaderProps, addWithConfig } from '@rbilabs/universal-components';

import { HeaderContainer, HeaderText } from './styled';

export * from './styled';

type Props = {
  children?: HeaderProps['children'];
  style?: BoxProps;
};

export const Header = addWithConfig(function UCLHeader(props: Props) {
  if (typeof props.children !== 'string') {
    return null;
  }

  return (
    <HeaderContainer {...props.style}>
      <HeaderText variant="headerOne">{props.children}</HeaderText>
    </HeaderContainer>
  );
});
