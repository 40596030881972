import React, { useEffect, useState } from 'react';

import { Button, Icon } from '@rbilabs/universal-components';
import { useIntl } from 'react-intl';

import { deliveryCompleteTimeout, postOrderTimeout } from 'components/bottom-service-mode/utils';
import {
  DeliveryStatus,
  IUserOrderFragment,
  RbiOrderStatus,
  useGetUserOrdersQuery,
} from 'generated/rbi-graphql';
import { useNavigation } from 'hooks/navigation/use-navigation';
import {
  STATUS_MESSAGES,
  STATUS_PERCENTS,
} from 'pages/order-confirmation/delivery/delivery-progress';
import { useAuthContext } from 'state/auth';
import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';
import { useMatch } from 'state/location';
import { useInRestaurantRedemptionContext } from 'state/loyalty/in-restaurant-redemption';
import { ServiceMode, useOrderContext } from 'state/order';
import { useServiceModeContext } from 'state/service-mode';
import { hiddenAccessibilityPlatformProps } from 'utils/accessibility';
import { routes } from 'utils/routing';

import { LOYALTY_ICON_COLOR, LOYALTY_ICON_NAME } from '../constants';
import { Close } from '../top-service-mode-notification.styled';
import { IOrderInProgress, IOrderInProgressResult, IServiceNotification } from '../types';

const DELIVERY_COMPLETED = [
  DeliveryStatus.ORDER_DROPPED_OFF,
  DeliveryStatus.ORDER_ABANDONED,
  DeliveryStatus.ORDER_CANCELLED,
];

const ORDER_PROGRESS_POLLING_DURATION_IN_MS = 10000;
const DELIVERY_COMPLETED_TIMEOUT_IN_MINUTES = 10;
export const PICKUP_UPDATED_TIMEOUT_IN_MINUTES = 10;
export const PRE_CURBSIDE_CONFIRM_ARRIVAL_TIMEOUT_IN_MINUTES = 60;

const getDeliveryHeading = ({ deliveryStatus }: { deliveryStatus: DeliveryStatus }) => {
  const deliveryProgress = STATUS_PERCENTS[deliveryStatus];
  if (deliveryProgress <= STATUS_PERCENTS[DeliveryStatus.ORDER_CREATED]) {
    return 'orderPlaced';
  }
  if (deliveryProgress < STATUS_PERCENTS[DeliveryStatus.ORDER_PICKED_UP]) {
    return 'deliveryOrderBeingPrepared';
  }
  if (deliveryProgress < STATUS_PERCENTS[DeliveryStatus.ORDER_DROPPED_OFF]) {
    return 'deliveryDriverEnroute';
  }
  return 'deliveryDelivered';
};

const isPollingForDelivery = (
  recentOrder: IUserOrderFragment | null | undefined,
  isDeliveryOrderCompleted: boolean
) => {
  if (
    recentOrder?.delivery &&
    !isDeliveryOrderCompleted &&
    !DELIVERY_COMPLETED.includes(recentOrder.delivery.status)
  ) {
    return true;
  }
  return false;
};

const orderInProgress = ({
  recentOrder,
  fireOrderAhead,
  curbsidePickupOrderId,
  curbsidePickupOrderTimePlaced,
  isDeliveryOrderCompleted,
  isCurbside,
}: IOrderInProgress): IOrderInProgressResult | null => {
  if (
    recentOrder &&
    [ServiceMode.CATERING_PICKUP, ServiceMode.CATERING_DELIVERY].includes(
      // @ts-expect-error TS(2345) FIXME: Argument of type 'import("/Users/rbish028/src/ctg-... Remove this comment to see the full error message
      recentOrder?.cart.serviceMode
    )
  ) {
    return null;
  }

  const isPickupTimedOut = () => {
    // Add the fire order delay to the total timeout
    const fireOrderInSeconds = recentOrder?.fireOrderIn
      ? recentOrder?.fireOrderIn + fireOrderAhead
      : 0;
    const fireOrderInMinutes = fireOrderInSeconds / 60;
    const timeOutWithDelay = PICKUP_UPDATED_TIMEOUT_IN_MINUTES + fireOrderInMinutes;

    return (
      recentOrder &&
      !recentOrder?.delivery &&
      postOrderTimeout(recentOrder?.updatedAt, timeOutWithDelay)
    );
  };

  const isPreConfirmCurbsideTimedOut = () =>
    curbsidePickupOrderId &&
    postOrderTimeout(
      curbsidePickupOrderTimePlaced,
      PRE_CURBSIDE_CONFIRM_ARRIVAL_TIMEOUT_IN_MINUTES
    );

  // ServiceMode.DELIVERY - ordered
  if (recentOrder?.delivery && !isDeliveryOrderCompleted) {
    const deliveryStatus = recentOrder.delivery.status;
    const headingId = getDeliveryHeading({ deliveryStatus });
    const detailsId = STATUS_MESSAGES[deliveryStatus];

    return {
      headingId,
      detailsId,
      icon: (
        <Icon variant="location" color="white" size="6" {...hiddenAccessibilityPlatformProps} />
      ),
    };
  }
  // ServiceMode.CURBSIDE - Before order is commited on confirming arrival , selecting "I'm here!"
  if (isCurbside && isPreConfirmCurbsideTimedOut()) {
    return {
      headingId: 'serviceModeDetailsCurbsideHeader',
      detailsId: 'headToRestaurant',
      icon: (
        <Icon variant="restaurant" color="white" size="6" {...hiddenAccessibilityPlatformProps} />
      ),
    };
  }
  // PICKUP - ordered
  if (recentOrder && isPickupTimedOut()) {
    return {
      headingId: 'orderPlaced',
      detailsId: 'yourOrderIsBeingPrepared',
      icon: (
        <Icon variant="restaurant" color="white" size="6" {...hiddenAccessibilityPlatformProps} />
      ),
    };
  }

  return null;
};

export const LOYALTY_REDEEM_TEXT_KEY = 'perksReadyToUse';

const StyledLoyaltyPointsIcon = Icon.withConfig({
  color: LOYALTY_ICON_COLOR,
});

export const useServiceModeNotification = (): IServiceNotification | null => {
  const { formatMessage } = useIntl();
  const fireOrderAhead = useFlag(LaunchDarklyFlag.FIRE_ORDER_AHEAD);
  const { isAuthenticated } = useAuthContext();
  const {
    serviceMode,
    curbsidePickupOrderId,
    curbsidePickupOrderTimePlaced,
    serverOrder,
  } = useOrderContext();
  const { isCurbside } = useServiceModeContext();

  const {
    inRestaurantRedemptionEnabled,
    inRestaurantRedemptionCart,
  } = useInRestaurantRedemptionContext();

  const { linkTo } = useNavigation();

  const [hasSeenCancelModal, setHasSeenCancelModal] = useState(false);
  const [shouldDisplayNotification, setShouldDisplayNotification] = useState<boolean>(true);
  const [shouldDisplayLoyaltyNotification, setShouldDisplayLoyaltyNotification] = useState<boolean>(
    true
  );
  const [currentOrderId, setCurrentOrderId] = useState<string>('');
  const [currentDeliveryStatus, setCurrentDeliveryStatus] = useState<DeliveryStatus>();

  const isOnMenuPage = useMatch({
    path: routes.menu,
    end: false,
  });

  // start polling for recent user orders
  const { data, startPolling, stopPolling, refetch } = useGetUserOrdersQuery({
    variables: {
      limit: 1,
      orderStatuses: [
        RbiOrderStatus.INSERT_SUCCESSFUL,
        RbiOrderStatus.UPDATE_SUCCESSFUL,
        RbiOrderStatus.REFUND_SUCCESSFUL,
      ],
    },
    skip: !isAuthenticated,
  });

  const navigateToOrder = () => {
    if (currentOrderId) {
      linkTo(`${routes.orderConfirmation}/${currentOrderId}`);
    }
  };

  // Upon order confirmation, data from `useGetUseOrdersQuery` is not updated with newly placed order
  // Force a refetch to update query with new confirm order instead of previous order
  useEffect(() => {
    if (isCurbside && serverOrder?.status === RbiOrderStatus.INSERT_SUCCESSFUL) {
      refetch();
    }
  }, [isCurbside, refetch, serverOrder]);

  useEffect(() => {
    // Update notification for new orders being placed
    const order = data?.userOrders?.orders?.[0];
    if (order && !isCurbside && order.rbiOrderId !== currentOrderId) {
      setShouldDisplayNotification(true);
      setCurrentOrderId(order.rbiOrderId);
    }
  }, [currentOrderId, data, isCurbside]);

  useEffect(() => {
    // Update notification for delivery status changes
    const order = data?.userOrders?.orders?.[0];
    if (order?.delivery?.status && order?.delivery.status !== currentDeliveryStatus) {
      setShouldDisplayNotification(true);
      setCurrentDeliveryStatus(order?.delivery?.status);
    }
  }, [currentDeliveryStatus, data]);

  useEffect(() => {
    // reactivate shouldDisplayNotification after closing first Curbside notification
    if (serverOrder && serverOrder.status === RbiOrderStatus.INSERT_SUCCESSFUL) {
      setShouldDisplayNotification(true);
    }
  }, [serverOrder]);

  useEffect(() => {
    // reset shouldDisplayLoyaltyNotification when the loyalty redemption cart is updated.
    if (inRestaurantRedemptionCart?.length > 0) {
      setShouldDisplayLoyaltyNotification(true);
    }
  }, [inRestaurantRedemptionCart]);

  const isCurbsideOrderCompleted =
    isCurbside && serverOrder && serverOrder.status === RbiOrderStatus.INSERT_SUCCESSFUL;

  const recentOrder = isCurbsideOrderCompleted
    ? { ...serverOrder, updatedAt: serverOrder.createdAt }
    : data?.userOrders?.orders?.[0];

  if (
    !isOnMenuPage &&
    isAuthenticated &&
    recentOrder?.status !== RbiOrderStatus.REFUND_SUCCESSFUL
  ) {
    const isDeliveryOrderCompleted =
      !!recentOrder?.delivery &&
      DELIVERY_COMPLETED.includes(recentOrder.delivery.status) &&
      (hasSeenCancelModal ||
        deliveryCompleteTimeout(recentOrder?.updatedAt, DELIVERY_COMPLETED_TIMEOUT_IN_MINUTES));

    if (isPollingForDelivery(recentOrder, isDeliveryOrderCompleted)) {
      if (hasSeenCancelModal) {
        setHasSeenCancelModal(false);
      }
      startPolling(ORDER_PROGRESS_POLLING_DURATION_IN_MS);
    }

    const inRestaurantRedemptionServiceModes = [
      serviceMode === ServiceMode.TAKEOUT,
      serviceMode === ServiceMode.EAT_IN,
      serviceMode === ServiceMode.DRIVE_THRU,
    ];

    if (
      inRestaurantRedemptionServiceModes.includes(true) &&
      inRestaurantRedemptionEnabled &&
      inRestaurantRedemptionCart?.length &&
      shouldDisplayLoyaltyNotification
    ) {
      return {
        heading: formatMessage({ id: LOYALTY_REDEEM_TEXT_KEY }),
        details: null,
        icon: <StyledLoyaltyPointsIcon variant={LOYALTY_ICON_NAME} />,
        cta: (
          <Close onPress={() => setShouldDisplayLoyaltyNotification(false)}>
            <Icon color="white" variant="cancel" size="6" {...hiddenAccessibilityPlatformProps} />
          </Close>
        ),
      };
    }

    const inProgress = orderInProgress({
      recentOrder,
      fireOrderAhead,
      curbsidePickupOrderId,
      curbsidePickupOrderTimePlaced,
      isDeliveryOrderCompleted,
      isCurbside,
    });
    if (recentOrder && inProgress && shouldDisplayNotification) {
      const { headingId, detailsId, icon } = inProgress;

      return {
        heading: formatMessage({ id: headingId }),
        details: formatMessage({ id: detailsId }),
        icon,
        cta: (
          <Button onPress={navigateToOrder} variant="solid-reversed">
            {formatMessage({ id: 'details' })}
          </Button>
        ),
        orderId: currentOrderId,
      };
    }
  }

  // Stop polling for recent user orders
  stopPolling();

  return null;
};
