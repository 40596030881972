import { Box, Icon, NbModal, Text } from '@rbilabs/universal-components';

import { theme } from 'styles/configure-theme';

export const ModalWrapper = NbModal.withConfig({
  alignItems: 'flex-start',
  justifyContent: 'flex-start',
});

export const AccountWrapper = Box.withConfig({
  background: theme.token('background-default'),
  flex: 1,
  width: 360,
  padding: '$3',
});

export const Row = Box.withConfig({
  flexDir: 'row',
  width: 'full',
  alignItems: 'center',
});

export const AccountTitle = Text.withConfig({
  variant: 'headerOne',
});

export const IconClose = Icon.withConfig({
  variant: 'cancel',
  color: theme.token('icon-default'),
});
