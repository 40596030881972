import { Box, HStack, HStackProps } from '@rbilabs/universal-components';

import { FIXED_STICKY_FOOTER_HEIGHT } from 'components/sticky-footer/constants';
import { theme } from 'styles/configure-theme';
import styles from 'styles/constants';
import { designTokens } from 'styles/constants/design-tokens';
import { WEB_APP_CTA_MAX_WIDTH } from 'utils/constants';

export const StyledProductCtaWrapper = HStack.withConfig({
  width: 'full',
  minHeight: `${FIXED_STICKY_FOOTER_HEIGHT}px`,
  background: designTokens['background-pattern'],
});

export const StyledProductCtaWrapperChild = Box.withConfig({
  alignItems: 'center',
  justifyContent: 'center',
  flex: 1,
});

export const displayValueStyleProps = () => ({
  background: 'transparent',
  height: 8,
  minWidth: 8,
  width: 8,
});

export const footerWrapperProps: HStackProps = {
  paddingX: 4,
  paddingBottom: 3,
  style: {
    shadowOpacity: 0.1,
    shadowOffset: {
      width: styles.shadowOffsetX,
      height: -styles.shadowOffsetY,
    },
    shadowRadius: 2,
  },
  _android: {
    style: {
      elevation: 10,
    },
  },
};

export const NumberInputWrapper = Box.withConfig({
  _web: {
    mr: { lg: 8 },
    alignSelf: {
      lg: 'end',
    },
  },
});

export const HomeCTAContainer = Box.withConfig<{ showIncrementor: boolean }>(p => ({
  alignSelf: {
    base: 'stretch',
    lg: p.showIncrementor ? 'start' : 'center',
  },
  width: { lg: p.showIncrementor ? 'unset' : WEB_APP_CTA_MAX_WIDTH },
}));

export const incrementorStyleProps = ({ disabled }: { disabled?: boolean }) => ({
  padding: 1,
  borderRadius: 20,
  borderWidth: 1,
  margin: 0.5,
  borderColor: theme.token(disabled ? 'icon-disabled' : 'text-active'),
  _icon: {
    color: theme.token(disabled ? 'icon-disabled' : 'text-active'),
    size: 'xs',
  },
  _pressed: {
    backgroundColor: theme.token('background-button-secondary-hover'),
  },
});
