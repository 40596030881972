import { ServiceModeExperimentVariants } from 'experiments/service-mode/types';
import { useIsTabletBp } from 'hooks/breakpoints';
import { RunningExperiments, useExperiments } from 'state/experiments';

export const useIsTopServiceModeEnabled = () => {
  const { variant } = useExperiments();

  // We are forcing bottom service mode in 'desktop' regardless of experiment, so we disable top service mode in 'desktop'
  const isTablet = useIsTabletBp();

  return (
    !isTablet && variant(RunningExperiments.ServiceMode, ServiceModeExperimentVariants.SHOW_TSM)
  );
};
