import React from 'react';

import { ScrollViewProps, ViewProps } from '@rbilabs/universal-components';
import { ScrollView } from 'react-native';

// ScrollViewProps from UCL does not include RN ScrollView props causing type errors
export interface CustomScrollViewProps extends ScrollViewProps, ScrollView {}

export interface IMeasures {
  x: number;
  y: number;
  width: number;
  height: number;
  pageY: number;
  pageX: number;
}

export type ContainerMetrics = Pick<IMeasures, 'width' | 'height' | 'pageY'> | null;

export interface ILaunchOnboardingProps {
  redirectLink?: string;
}

export interface IStepData {
  target_id: string;
  tooltip_content: {
    title: string;
    body: string;
  };
  tooltip_position?: string;
}
export interface IOnboardingStepsData {
  id: string;
  steps: IStepData[];
}

export interface IOnboardingProviderProps {
  stepsData: IOnboardingStepsData;
  containerMetrics: ContainerMetrics;
  scrollViewRef?: React.MutableRefObject<CustomScrollViewProps | null>;
  launchOnLoad?: boolean;
}
export interface IOnboardingContainerProps {
  stepsData: IOnboardingStepsData;
  scrollViewRef?: React.MutableRefObject<CustomScrollViewProps | null>;
  launchOnLoad?: boolean;
}

export interface IOnboardingStep extends ViewProps {
  stepIndex: string;
  targetId: string;
  tooltipOffset?: number;
}

export interface ILocalStorageOnboardingProps {
  [key: string]: boolean;
}

export interface IComponentDetails {
  component: React.ReactNode;
  measures: IMeasures;
  styles?: ViewProps;
  tooltipOffset?: number;
}

export interface IInitialState {
  stepsData: IOnboardingStepsData | null;
  componentsList: { [key: string]: IComponentDetails };
  currentStep: number;
  isLoading: boolean;
  containerMetrics: ContainerMetrics;
  doneMeasures: boolean;
  hasDoneOnboarding: ILocalStorageOnboardingProps;
  scrollToValueY: number | null;
  displayOnboarding: boolean;
  linkRedirect: string;
  isOnboardingAvailable: boolean;
}

export interface IContextActions {
  addComponentsToList: (param: { [key: string]: IComponentDetails }) => void;
  updateComponentDetails: (key: string, componentDetails: IComponentDetails) => void;
  clearComponentsList: () => void;
  setCurrentStep: () => void;
  setIsLoading: (boolean: boolean) => void;
  setDoneMeasures: (boolean: boolean) => void;
  setHasDoneOnboarding: (values: ILocalStorageOnboardingProps) => void;
  setDisplayOnboarding: (shouldDisplay: boolean) => void;
  setRedirectLink: (link: string) => void;
  launchOnboarding: (props: ILaunchOnboardingProps) => void;
  setIsOnboardingAvailable: (isOnboarding: boolean) => void;
}

export interface IOnboardingContextProps extends IContextActions, IInitialState {}

export enum ACTIONS {
  GO_TO_NEXT_STEP = 'GO_TO_NEXT_STEP',
  ADD_COMPONENT_DETAILS_TO_LIST = 'ADD_COMPONENT_DETAILS_TO_LIST',
  UPDATE_COMPONENT_DETAILS = 'UPDATE_COMPONENT_DETAILS',
  CLEAR_COMPONENTS_FROM_LIST = 'CLEAR_COMPONENTS_FROM_LIST',
  SET_LOADING = 'SET_LOADING',
  SET_DONE_MEASURES = 'SET_DONE_MEASURES',
  HAS_DONE_ONBOARDING = 'HAS_DONE_ONBOARDING',
  SET_HAS_DONE_ONBOARDING = 'SET_HAS_DONE_ONBOARDING',
  SET_SCROLL_Y_VALUE = 'SET_SCROLL_Y_VALUE',
  SET_DISPLAY_ONBOARDING = 'SET_DISPLAY_ONBOARDING',
  SET_REDIRECT_LINK = 'SET_REDIRECT_LINK',
  SET_IS_ONBOARDING_AVAILABLE = 'SET_IS_ONBOARDING_AVAILABLE',
}

export type ActionType =
  | { type: ACTIONS.GO_TO_NEXT_STEP }
  | {
      type: ACTIONS.CLEAR_COMPONENTS_FROM_LIST;
    }
  | {
      type: ACTIONS.ADD_COMPONENT_DETAILS_TO_LIST;
      componentDetails: { [key: string]: IComponentDetails };
    }
  | {
      type: ACTIONS.UPDATE_COMPONENT_DETAILS;
      key: string;
      componentDetails: IComponentDetails;
    }
  | {
      type: ACTIONS.SET_LOADING;
      loading: boolean;
    }
  | {
      type: ACTIONS.SET_DONE_MEASURES;
      done: boolean;
    }
  | {
      type: ACTIONS.HAS_DONE_ONBOARDING;
    }
  | {
      type: ACTIONS.SET_HAS_DONE_ONBOARDING;
      values: ILocalStorageOnboardingProps;
    }
  | {
      type: ACTIONS.SET_SCROLL_Y_VALUE;
      scrollY: number;
    }
  | {
      type: ACTIONS.SET_DISPLAY_ONBOARDING;
      shouldDisplay: boolean;
    }
  | {
      type: ACTIONS.SET_REDIRECT_LINK;
      link: string;
    }
  | {
      type: ACTIONS.SET_IS_ONBOARDING_AVAILABLE;
      isAvailable: boolean;
    };
