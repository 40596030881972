import { Box, Header, Text } from '@rbilabs/universal-components';

import BlockRenderer from 'components/sanity-block-renderer';
import { theme } from 'styles/configure-theme';

export const UnknownWidget = Box;

export const Header2 = Header.withConfig<{ pageSlug?: string }>({
  textAlign: 'center',
  variant: 'headerTwo',
  fontSize: {
    base: '2xl',
    md: '4xl',
  },

  fontWeight: 'light',
  padding: 0,
  paddingTop: '$8',
  margin: 0,
  marginBottom: '$2',
});

export const Header1 = Header.withConfig({
  textAlign: 'center',
  variant: 'headerOne',
  fontWeight: 'light',
  padding: 0,
  paddingTop: '$8',
  margin: 0,
  marginBottom: '$2',
  fontSize: {
    base: '4xl',
    md: '6xl',
  },
});

export const Header3 = Header.withConfig({
  textAlign: 'center',
  variant: 'headerThree',
  fontSize: {
    base: 'lg',
    md: '3xl',
  },
  fontWeight: 'light',
  padding: 0,
  paddingTop: '$8',
  margin: 0,
  marginBottom: '$2',
});

export const Header4 = Header.withConfig({
  variant: 'headerFour',
  fontSize: 'lg',
  fontWeight: 'light',
  padding: 0,
  paddingTop: '$8',
  margin: 0,
  marginBottom: '$2',
});

export const Header5 = Header.withConfig({
  variant: 'headerFour',
  fontSize: 'lg',
  fontWeight: 'light',
  padding: 0,
  paddingTop: '$8',
  margin: 0,
  marginBottom: '$2',
});

export const Paragraph = Text.withConfig<{ pageSlug?: string }>({
  variant: 'copyOne',
  fontSize: {
    base: 'md',
    md: 'xl',
  },
  lineHeight: 'lg',
  marginTop: '$8',
});

export const BlockWidgetContainer = BlockRenderer.withConfig({
  marginBottom: '$6',
  marginX: 'auto',
  width: '90%',
  maxWidth: '730px',
});

export const ListItemNumber = Text;

export const ListItem = Text.withConfig({
  variant: 'copyOne',
  fontSize: {
    base: 'md',
    md: 'xl',
  },
  lineHeight: 'md',
  marginTop: '$2',
});

export const blockWidgetStrongProps = {
  lineHeight: 'lg',
};

export const blockWidgetLinkProps = {
  color: theme.token('text-link-hover'),
};
