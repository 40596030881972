import { differenceInDays, parse } from 'date-fns';

import { IBaseProps } from '@rbi-ctg/frontend';
import { IOffer } from '@rbi-ctg/menu';
import { IOfferFeedbackEntryFragment } from 'generated/rbi-graphql';
import { useIdCounter } from 'hooks/use-id-counter';
import { useAuthContext } from 'state/auth';
import {
  OfferActivationStatus,
  activationStatusFromOfferState,
  allowsUnauthenticatedRedemption,
  getRequiredPlatforms,
  timeLeftToRedeem,
} from 'utils/offers';
import { IDays, IHours } from 'utils/offers/rule-sets/time-left-to-redeem';

interface IUseOffer extends IBaseProps {
  offer: IOffer;
  offerFeedback: IOfferFeedbackEntryFragment | null;
}

interface IUseOfferValue {
  id: string;
  isNewOffer: boolean;
  endingIn: IDays | IHours | null;
  platforms: string[];
  displayLoginRequiredBadge: boolean;
  state: OfferActivationStatus | null;
}

export const useOffer = ({ offer, offerFeedback }: IUseOffer): IUseOfferValue => {
  const id = useIdCounter('offer');
  const { isAuthenticated } = useAuthContext();

  let activationDate: string | null = null;
  if (offer.activationDate) {
    activationDate =
      typeof offer.activationDate === 'string'
        ? offer.activationDate
        : offer.activationDate.toISOString();
  }

  const endingIn = offerFeedback ? timeLeftToRedeem(offerFeedback) : null;
  const isNewOffer = activationDate
    ? differenceInDays(new Date(), parse(activationDate, 'yyyy-MM-dd', new Date())) < 5
    : false;
  const requiredPlatforms = getRequiredPlatforms(offer.ruleSet);

  const displayLoginRequiredBadge =
    !isAuthenticated && !allowsUnauthenticatedRedemption(offer.ruleSet);

  const offerState = offerFeedback && offerFeedback.offerState;
  const activationStatus = offerState ? activationStatusFromOfferState(offerState) : null;

  return {
    id,
    isNewOffer,
    endingIn,
    platforms: requiredPlatforms,
    displayLoginRequiredBadge,
    state: activationStatus,
  };
};
