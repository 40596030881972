import React, { VFC, useEffect, useMemo } from 'react';

import { ICartEntry, ICombo } from '@rbi-ctg/menu';
import { BackArrowHistory } from 'components/navigation/back-arrow';
import { OrderSummary } from 'components/product-detail/order-summary';
import { PickerAspects } from 'components/product-detail/picker-aspects';
import { MenuObjectTypes } from 'enums/menu';
import { useNavigation } from 'hooks/navigation/use-navigation';
import { useRoute } from 'hooks/navigation/use-route';
import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';
import { useMenuContext } from 'state/menu';
import { useMenuUIContext } from 'state/menu-ui';
import { useProductWizardContext } from 'state/product-wizard';
import { useEditingCartEntry } from 'state/product-wizard/hooks/use-editing-cart-entry';
import { ProductWizardUtils } from 'state/product-wizard/utils';
import { isWeb } from 'utils/environment';
import { transformMenuObjectToCartItem } from 'utils/wizard';

import { ProductUpsell } from '../product-upsell';
import {
  transformUserSelectionsComboToComboSlotSelections,
  transformUserSelectionsModifierToModifierSelection,
} from '../utils';

import { ProductSelectionsContainer } from './product-home.styled';

export interface IProductHomeProps {
  showStaticPickers?: boolean;
}

/**
 * The summary of the Product Wizard.
 * NOTE: this component is rendered on the DOM even when the user is viewing another step on the wizard.
 * Do not rely on unmount principles, because it does not get unmounted.
 * The fixed buttons in mobile are removed to avoid collisions with overlays.
 */
export const ProductHome: VFC<IProductHomeProps> = ({ showStaticPickers }) => {
  const { pathname } = useRoute();
  const {
    priceForItemOptionModifier,
    pricingFunction,
    priceForComboSlotSelection,
  } = useMenuContext();
  const { setActiveCartEntries } = useMenuUIContext();
  const { menuObject, productQuantity, userSelections } = useProductWizardContext();
  const { goBack } = useNavigation();
  const userSelectedPickerAspects = userSelections.pickerAspects;

  const useEnableMenuUpsellItems = useFlag(LaunchDarklyFlag.ENABLE_MENU_UPSELL_ITEMS);

  const editingCartEntry = useEditingCartEntry();

  const computedProduct = useMemo(
    () => ProductWizardUtils.ComputeSelectedOption(menuObject, userSelections.pickerAspects),
    [menuObject, userSelections]
  );

  const { mainEntry, upsellEntries } = useMemo(
    () =>
      transformMenuObjectToCartItem(
        computedProduct,
        priceForItemOptionModifier,
        pricingFunction,
        priceForComboSlotSelection,
        {
          quantity: productQuantity,
          modifierSelections: transformUserSelectionsModifierToModifierSelection(userSelections),
          pickerSelections: userSelections.pickerAspects,
          comboSlotSelections: transformUserSelectionsComboToComboSlotSelections(
            userSelections.comboSlot,
            computedProduct as ICombo
          ),
          pathname,
          // quickConfigs, // ignore for now - only TH uses quickConfigs
          existingCartId: editingCartEntry?.cartId,
        }
      ),
    [
      computedProduct,
      editingCartEntry,
      pathname,
      priceForComboSlotSelection,
      priceForItemOptionModifier,
      pricingFunction,
      productQuantity,
      userSelections,
    ]
  );

  useEffect(() => {
    setActiveCartEntries([mainEntry, ...upsellEntries] as ICartEntry[]);
  }, [mainEntry, setActiveCartEntries, upsellEntries]);

  return (
    <>
      {isWeb && <BackArrowHistory onPress={goBack} />}
      <ProductSelectionsContainer testID="product-home-customization-panel">
        {menuObject._type === MenuObjectTypes.PICKER && (
          <PickerAspects
            pickerData={menuObject}
            userSelectedPickerAspects={userSelectedPickerAspects}
          />
        )}
        {!showStaticPickers && <OrderSummary />}
      </ProductSelectionsContainer>
      {useEnableMenuUpsellItems && !showStaticPickers && <ProductUpsell />}
    </>
  );
};

export default ProductHome;
