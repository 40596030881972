import React from 'react';

import { FormControl, Radio } from '@rbilabs/universal-components';
import delv from 'dlv';
import { useIntl } from 'react-intl';

import { IPickerAspect, IPickerAspectUiPattern, IPickerPromotion } from '@rbi-ctg/menu';
import { PickerAspectOption } from 'components/picker-aspect-option';
import { useFormatJoules } from 'hooks/use-format-joules';
import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';
import { PickerAspectUiPattern } from 'state/menu/types';
import { formatCaloriesToJoulesFromString } from 'utils/menu/calories';

interface IProps extends IPickerAspectUiPattern {
  handleSelection(id: string, identifier: string): void;
  aspect: IPickerAspect;
  aspectPromotionMap?: {
    [id: string]: IPickerPromotion;
  };
  selectedPickerIdentifiers: {
    [id: string]: string;
  };
  calorieMap: {
    [identifier: string]: string;
  };
  pricePickerAspect: (pickerAspectId: string, pickerAspectOptionId: string) => string;
  isInStaticMenu: boolean;
}

const Form = FormControl;

// COMMENT 1.
// When changing this file to ts, i found that the pickerAspectOptions have been
// monkey patched to include a `disabled` property.
// With type systems, we should avoid changing object shape.
// It'll take more work than this PR deserves to fix, so I added ts-ignores
// But we will need to remove the object patching on the pickerAspectOptions.

const PickerDetail: React.FC<React.PropsWithChildren<IProps>> = props => {
  const { formatMessage } = useIntl();
  const formatJoules = useFormatJoules();
  const enableEUNutritionDisplay = useFlag(LaunchDarklyFlag.ENABLE_EU_NUTRITION_DISPLAY);
  const aspectId = props.aspect._id;

  const handleSelection = (identifier: string) => {
    props.handleSelection(aspectId, identifier);
  };

  // By default all `showInStaticMenu` is undefined. To hide in the UI it has to be explicitely false. This is the one minor difference from the other `showInStaticMenu` we have this is almost the opposite.
  // On all the others having that boolean undefined will hide it in the UI. This is to avoid having to update all aspects to turn this option on.
  const filteredAspectOptions = props.isInStaticMenu
    ? props.aspect.pickerAspectOptions.filter(option => option?.showInStaticMenu !== false)
    : props.aspect.pickerAspectOptions;

  // Filter out the picker options marked as disabled
  const enabledOptions = filteredAspectOptions.filter(option => !option.disabled);

  const visibleOptions = enabledOptions;

  const selectedPickerIdentifier = props.selectedPickerIdentifiers[aspectId];
  const uiPattern = delv(props, 'aspect.uiPattern', PickerAspectUiPattern.SELECTOR);

  return (
    <Form>
      <Radio.Group value={selectedPickerIdentifier} name={props.aspect.name?.locale || ''}>
        {(visibleOptions || []).map(option => {
          const { identifier } = option;
          const currentCalories = props.calorieMap[identifier];

          const caloriesInJoules = formatCaloriesToJoulesFromString(
            currentCalories || '',
            formatJoules
          );

          const euFormattedNutritionText = formatMessage(
            { id: 'euNutritionValues' },
            { calories: currentCalories, joules: caloriesInJoules }
          );

          const globalizedCalorieFormat = currentCalories ? `${euFormattedNutritionText}` : '';

          const calories = enableEUNutritionDisplay ? globalizedCalorieFormat : currentCalories;

          return (
            <PickerAspectOption
              key={identifier}
              option={option}
              uiPattern={uiPattern}
              handleSelection={handleSelection}
              inputName={props.aspect.name?.locale}
              selected={selectedPickerIdentifier === identifier}
              // @ts-expect-error TS(2322) FIXME: Type 'boolean | undefined' is not assignable to ty... Remove this comment to see the full error message
              disabled={option.disabled}
              calories={calories}
              description={delv(option.description, 'locale')}
              price={props.pricePickerAspect(props.aspect._id, identifier)}
            />
          );
        })}
      </Radio.Group>
    </Form>
  );
};

PickerDetail.defaultProps = {
  pricePickerAspect: () => '',
};

export default PickerDetail;
