import React from 'react';

import { Skeleton, VStack, VStackProps } from '@rbilabs/universal-components';

export const ResponsiveCarouselSkeletonItem = React.memo((props: VStackProps) => (
  <VStack
    borderWidth="1"
    space={8}
    overflow="hidden"
    rounded="md"
    borderColor="coolGray.200"
    background="white"
    {...props}
  >
    <Skeleton h="32" />
    <Skeleton.Text px="4" h="20" />
  </VStack>
));
