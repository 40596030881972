import { Box, makeUclComponent } from '@rbilabs/universal-components';
import { ImageBackground } from 'react-native';

export const Wrapper = Box.withConfig<{
  $fullHeight?: boolean;
  $constrainToViewHeight?: boolean;
  $windowHeight?: number;
}>(({ $fullHeight, $constrainToViewHeight, $windowHeight, ...props }) => ({
  backgroundColor: Styles.color.white,
  ...($fullHeight && { height: '100%' }),
  ...($constrainToViewHeight && $windowHeight && { maxHeight: $windowHeight }),
  ...props,
}));

export const StyledImageBackground = makeUclComponent(ImageBackground).withConfig({
  height: '100%',
  width: '100%',
  flex: 1,
});
