import { QuestFragmentDoc, useActivateQuestMutation } from 'generated/graphql-gateway';
import { useLoyaltyUser } from 'state/loyalty/hooks/use-loyalty-user';
import logger from 'utils/logger';

export const useActivateQuest = ({ questId }: { questId: string }) => {
  const { loyaltyUser } = useLoyaltyUser();
  const loyaltyId = loyaltyUser?.id;

  // this mutation leverages from the Apollo's cache to update the proper Loyalty Quest object
  return useActivateQuestMutation({
    variables: {
      questId,
      loyaltyId: loyaltyId!,
    },
    onError: errors => {
      logger.error(`Error activating quest: ${questId} ${JSON.stringify(errors)}`);
    },
    update(cache, { data }) {
      const activeQuest = data?.loyaltyActivateQuest;

      if (activeQuest) {
        cache.writeFragment({
          id: `LoyaltyQuest:${questId}`,
          fragment: QuestFragmentDoc,
          data: {
            ...activeQuest,
            // the id changes bc a personalized quest is created when it's activated
            id: questId,
          },
          fragmentName: 'QuestFragment',
        });
      }
    },
  });
};
