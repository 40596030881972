import React from 'react';

import {
  ButtonProps,
  Pressable,
  Text,
  TextProps,
  LinkProps as UCCLLinkProps,
  addWithConfig,
} from '@rbilabs/universal-components';
import * as WebBrowser from 'expo-web-browser';
import { GestureResponderEvent, Linking } from 'react-native';

import { getTextFromChildren } from 'components/action-button';
import { useNavigation } from 'hooks/navigation/use-navigation';
import useDebounceOnPressCallback from 'hooks/use-debounce-on-press-callback';
import { isExternalLink } from 'utils/is-external-link';

export { useHref } from 'react-router-native';

const StyledLink = Pressable.withConfig<{
  isSelected: boolean;
}>(props => ({
  color: props.isSelected ? Styles.color.tertiary : Styles.color.black,
}));

type IButtonProps = ButtonProps;

interface ILinkSharedProps {
  // Route to navigate with or without state
  to?: string;
  // Path to navigate using linking options
  linkPath?: string;
  // This will open in a new tab
  isExternal?: boolean;
  // Is the link active
  isSelected?: boolean;
  isUnderlined?: boolean;
  // React Router
  replace?: boolean;
  // React Router
  state?: any;
  variant?: TextProps['variant'];
}

export interface IInLineLinkProps extends ILinkSharedProps, TextProps {
  // Is the link used inside of a text block
  isInline: true;
  // Native base had different types
  onPress?: ((event?: GestureResponderEvent) => void) | undefined;
  // TODO: RN WEB - implement hover
  _hover?: Omit<IButtonProps, '_hover'>;
  _text?: TextProps;
}

export interface ILinkProps extends ILinkSharedProps, UCCLLinkProps {
  isInline?: boolean | undefined;
  // Native base had different types
  onPress?: ((event?: GestureResponderEvent) => any) | null | undefined;
}

const openExternalLink = async (URL: string) => {
  try {
    if (!URL.startsWith('http')) {
      await Linking.openURL(URL);
      return;
    }
    await WebBrowser.openBrowserAsync(URL);
  } catch (error) {
    // eslint-disable-next-line no-console
    console.warn(`Link: there was an error opening the URL: ${URL}`, error);
  }
};

// TODO: RN - add web version of StyledInLineLink
function LinkBase(props: IInLineLinkProps | ILinkProps): React.ReactElement {
  const {
    to,
    linkPath,
    onPress: _onPress,
    children,
    replace,
    state,
    isUnderlined,
    isInline,
    isSelected,
    variant,
    _text: textProps,
    ...rest
  } = props;

  const { navigate, linkTo } = useNavigation();
  const toIsExternal = isExternalLink(to);

  const onPress = useDebounceOnPressCallback(
    (event?: GestureResponderEvent) => {
      if (_onPress) {
        _onPress(event);
      }

      if (linkPath) {
        const openLink = isExternalLink(linkPath) ? openExternalLink : linkTo;
        return openLink(linkPath);
      }

      if (to) {
        // This is an internal link
        // React Router Native
        if (toIsExternal) {
          openExternalLink(to);
          event?.preventDefault();
          return;
        }
        event?.preventDefault();
        navigate(to, { state, replace });
      }
    },
    [to, linkPath, _onPress, toIsExternal, state, replace]
  );

  const propsToForward = {
    isUnderlined: Boolean(isUnderlined),
    isSelected: Boolean(isSelected),
    accessibilityRole: to ? 'link' : 'button',
    accessible: true,
    onPress,
    children,
    ...(rest as any),
  } as const;

  const ddActionName = props?.['dd-action-name'] || props?.testID || getTextFromChildren(children);

  return (
    <StyledLink {...propsToForward} dd-action-name={ddActionName} href={to}>
      {typeof children === 'string' || typeof children === 'number' ? (
        <Text variant={variant ?? 'copyOne'} underline={isUnderlined} {...textProps}>
          {children}
        </Text>
      ) : (
        children
      )}
    </StyledLink>
  );
}

const Link = addWithConfig(LinkBase);

const secondaryHoverColor = '#000'; // deviates from theme to increase color contrast

export const SecondaryLink = Link.withConfig<{ isSelected?: boolean }>(p => ({
  color: p.isSelected ? secondaryHoverColor : Styles.color.white,
}));

export default Link;
