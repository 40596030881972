import React from 'react';

import { IMarketingTileBasicFragment, Maybe } from 'generated/sanity-graphql';

import MarketingTileListElement from './marketing-tile-list-element';
import { ListContainer } from './marketing-tile-list.styled';

interface MarketingTileListProps {
  tiles: Maybe<ReadonlyArray<Maybe<IMarketingTileBasicFragment>>>;
}

const MarketingTileList = ({ tiles, ...props }: MarketingTileListProps) => {
  if (!tiles?.length) {
    return null;
  }

  return (
    <ListContainer {...props}>
      {tiles.map(basicTile => {
        if (!basicTile) {
          return null;
        }
        return <MarketingTileListElement key={basicTile._id} basicTile={basicTile} />;
      })}
    </ListContainer>
  );
};

export default MarketingTileList;
