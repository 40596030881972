import React, { useLayoutEffect } from 'react';

import { Icon } from '@rbilabs/universal-components';
import { useNavigation } from '@react-navigation/native';
import { useIntl } from 'react-intl';

import { HeaderLeftContainer } from 'navigation/header/styles';
import useProductWizardFlow from 'state/product-wizard/hooks/use-product-wizard-flow';
import { ProductWizardStep } from 'state/product-wizard/types';
import { theme } from 'styles/configure-theme';
import { hiddenAccessibilityPlatformProps } from 'utils/accessibility';

interface IProductWizardProps {
  goBackHandler?: () => void;
}

export const ProductWizardHeader = ({ goBackHandler }: IProductWizardProps) => {
  const { formatMessage } = useIntl();
  const { goBack, setOptions } = useNavigation();
  const { currentStep } = useProductWizardFlow();
  const isCustomizingProduct = currentStep === ProductWizardStep.ProductCustomization;

  const noMenuBackgroundThemeColor =
    isCustomizingProduct || theme.token('background-menu-customization-image') === 'transparent';
  const iconColor = theme.token(noMenuBackgroundThemeColor ? 'text-default' : 'text-reversed');

  useLayoutEffect(() => {
    setOptions({
      headerStyle: {
        backgroundColor: theme.token(
          noMenuBackgroundThemeColor ? 'background-pattern' : 'background-menu-customization-image'
        ),
      },
    });
  }, [setOptions, isCustomizingProduct, noMenuBackgroundThemeColor]);

  return (
    <HeaderLeftContainer
      onPress={goBackHandler || goBack}
      accessibilityLabel={
        isCustomizingProduct ? formatMessage({ id: 'back' }) : formatMessage({ id: 'close' })
      }
      accessibilityRole="button"
    >
      <Icon
        variant={isCustomizingProduct ? 'back' : 'cancel'}
        size="sm"
        color={iconColor}
        {...hiddenAccessibilityPlatformProps}
      />
    </HeaderLeftContainer>
  );
};
