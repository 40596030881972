import { HStack } from '@rbilabs/universal-components';

import Link from 'components/link';

import theme from './theme';

export const StyledLink = Link.withConfig({
  borderBottomWidth: '1px',
  borderStyle: 'solid',
  borderBottomColor: `${Styles.color.grey.four}`,
  width: '100%',
  color: `${theme.arrowLinkLabelColor}`,
  paddingX: '0',
  paddingY: '5',
  _web: {
    cursor: 'pointer',
  },
});

export const Row = HStack.withConfig({
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '100%',
});
