import React from 'react';

import { useIntl } from 'react-intl';

import { NativeStackNavigatorWithBack } from 'navigation/native-stack-navigator-with-back';
import { LoyaltyRewards } from 'pages/loyalty/loyalty-rewards';

import { rootScreenProps } from './constants';

export const RewardsNavigator = () => {
  const { formatMessage } = useIntl();
  return (
    <NativeStackNavigatorWithBack
      screens={[
        {
          name: rootScreenProps.name,
          component: rootScreenProps.component,
        },
        {
          name: formatMessage({ id: 'routes.loyaltyRewardList' }),
          component: LoyaltyRewards,
        },
      ]}
    />
  );
};
