import { AllowedEvent } from '@rbilabs/mparticle-client';

export interface ILogRBIEvent {
  (event: AllowedEvent): void;
}

export enum SignInPhases {
  START = 'start',
  COMPLETE = 'complete',
}

/**
 * Used for attribution tracking
 */
export interface IUtmParams {
  utm_source?: string;
  utm_medium?: string;
  utm_campaign?: string;
  utm_term?: string;
  utm_content?: string;
}

interface IUtmParamsAttributes {
  'UTM Source'?: string;
  'UTM Medium'?: string;
  'UTM Campaign'?: string;
  'UTM Term'?: string;
  'UTM Content'?: string;
}

export interface ICRMEventsUniversalAttributes extends IUtmParamsAttributes {
  'Service Mode': string;
  'Pickup Mode': string;
  isSmallScreen: boolean;
  currentBuild: string;
}

export interface ICRMEventsAttributes extends IUtmParamsAttributes {
  brand?: string;
  region?: string;
  env?: string;
  'Date of Birth'?: string;
  'Email Opt In'?: string;
  ccToken?: string;
  favoriteStores?: string;
  language?: string;
  Locale?: string;
  'Legacy User'?: string;
  'RBI Cognito ID'?: string;
  'IOS Location Permissions'?: string;
  'Android Location Permissions'?: string;
}
