import { useEffect } from 'react';

import { useAmplitudeContext } from 'state/amplitude';
import { useLocale } from 'state/intl';
import { useGatewayFlags } from 'state/launchdarkly';

import { Header, setHeaders } from '../headers';

/**
 * Synchronizes dynamic context values with http headers to be sent with each GraphQL request
 */
export const useHeadersSync = () => {
  const { language, region } = useLocale();
  const { sessionId } = useAmplitudeContext();
  const gatewayFlags = useGatewayFlags();

  useEffect(() => {
    setHeaders(Header.LANGUAGE, language);
    setHeaders(Header.REGION, region);
    setHeaders(Header.SESSION_ID, sessionId);
    setHeaders(Header.GATEWAY_FLAGS, gatewayFlags);
  }, [language, region, sessionId, gatewayFlags]);
};
