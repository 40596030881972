import React from 'react';

import AppHeader from 'components/app-header';
import Layout from 'components/layout';
import LayoutBottomContainer from 'components/layout/layout-bottom-container';
import MobileNavigation from 'components/mobile-web-navigation-bar';
import { TopServiceModeNotification } from 'components/top-service-mode-notification';
import { CustomBottomTabBar_SimplifiedLandingPage__Experiment } from 'experiments/simplified-landing-page';
import { useIsTabletBp } from 'hooks/breakpoints';
import { GlobalStylesheet } from 'styles/global';

export const AppContainer: React.VFC = React.memo(() => {
  const isTablet = useIsTabletBp();
  return (
    <>
      <GlobalStylesheet />
      <AppHeader />
      <Layout />

      <LayoutBottomContainer>
        {isTablet && <TopServiceModeNotification />}
        <CustomBottomTabBar_SimplifiedLandingPage__Experiment>
          <MobileNavigation />
        </CustomBottomTabBar_SimplifiedLandingPage__Experiment>
      </LayoutBottomContainer>
    </>
  );
});
