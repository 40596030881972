import { configureStore } from '@reduxjs/toolkit';
import { Platform } from 'react-native';

import { loyaltyReducer, orderingReducer } from './models';

const isReduxDebuggerEnabled = __DEV__ && Platform.OS !== 'web';

export const store = configureStore({
  reducer: {
    loyalty: loyaltyReducer,
    ordering: orderingReducer,
  },
  middleware: getDefaultMiddleware => {
    const middleware = getDefaultMiddleware();

    if (isReduxDebuggerEnabled) {
      const createReduxFlipperDebugger = require('redux-flipper').default;
      middleware.push(createReduxFlipperDebugger());
    }

    return middleware;
  },
  devTools: isReduxDebuggerEnabled,
});

export type ConfiguredStore = typeof store;

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;
