import React from 'react';

import { Box, Skeleton, Stack } from '@rbilabs/universal-components';

import { Section } from 'components/features/components/marketing-tile-group/styled';
import { useIsMobileBp } from 'hooks/breakpoints';

import {
  MARKETING_CARD_WIDTH_DESKTOP,
  MARKETING_CARD_WIDTH_DESKTOP_LG,
} from '../features/components/marketing-card/constants';

import { CARD_COLOR } from './constants';
import { ButtonContainer } from './styles';
import { Direction, HorizontalPosition } from './types';

const Container = Box.withConfig({
  marginTop: '$3',
});

const SkeletonButton = ButtonContainer.withConfig({
  marginTop: '$3',
});

const MarketingTileWrapper = ({ width }: { width: {} }) => (
  <Box
    width={width}
    bg={CARD_COLOR}
    borderRadius={16}
    mb={{ md: '$4' }}
    marginX={{ base: '$4', md: '$2' }}
  >
    <Skeleton h="$40" borderTopRadius={16} />
    <Box p="$4" width="80%">
      <Skeleton h="$5" rounded="full" mb="$4" />
      <Skeleton.Text lines={1} />
      <SkeletonButton
        isShowingTwoButtons={false}
        $direction={Direction.DEFAULT}
        buttonPosition={HorizontalPosition.LEFT}
      >
        <Skeleton
          height="$10"
          width="$24"
          rounded="full"
          startColor="token.background-skeleton-loader-cta-start"
          endColor="token.background-skeleton-loader-cta-end"
        />
      </SkeletonButton>
    </Box>
  </Box>
);

export const MarketingTileSkeleton = () => {
  const isMobile = useIsMobileBp();
  const width = {
    base: 'full',
    md: MARKETING_CARD_WIDTH_DESKTOP,
    lg: MARKETING_CARD_WIDTH_DESKTOP_LG,
  };

  if (isMobile) {
    return (
      <Container>
        <Section>
          <MarketingTileWrapper width={width} />
        </Section>
        <Section>
          <MarketingTileWrapper width={width} />
        </Section>
      </Container>
    );
  }

  return (
    <Stack
      testID="marketing-tile-skeleton-loader"
      direction="row"
      flexWrap="wrap"
      mt="$8"
      justifyContent="center"
    >
      <MarketingTileWrapper width={width} />
      <MarketingTileWrapper width={width} />
      <MarketingTileWrapper width={width} />
      <MarketingTileWrapper width={width} />
    </Stack>
  );
};
