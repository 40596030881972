import { Text, View } from '@rbilabs/universal-components';

import Link from 'components/link';
import { theme } from 'styles/configure-theme';

export const IconWrap = View.withConfig({
  alignItems: 'center',
  justifyContent: 'center',
  height: '20px',
});

export const NavLinkText = Text.withConfig<{ $isActive?: boolean }>(p => ({
  marginBottom: 0,
  color: p.$isActive ? theme.token('text-active') : theme.token('text-default'),
}));

export const FloatingLink = Link.withConfig({
  minW: '109px',
  maxH: '50',
  bgColor: theme.token('icon-button-secondary'),
  position: 'absolute',
  bottom: '66px',
  right: '11px',
  borderRadius: '9999px',
  paddingX: 4,
  paddingY: 3,
  alignItems: 'center',
  alignContent: 'center',
  shadow: '1',
  borderWidth: 0,
});
