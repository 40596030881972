import { differenceInYears, parse } from 'date-fns';
import { isNil, upperFirst } from 'lodash-es';

import { NonNullableObject } from '@rbi-ctg/frontend';
import { IUserInfoSubmitData } from 'components/user-info-form/types';
import { CommunicationPreferences, UserDetails } from 'state/auth/hooks/types';
import { normalizeBooleans } from 'state/crm-events/utils';

import { Identify, identify } from './amplitude-package';
import { IAmplitudeUserAttributes } from './types';

export function sanitizeValues<M extends object = IAmplitudeUserAttributes>(
  attributes: M
): NonNullableObject<Partial<M>> {
  return Object.entries(attributes).reduce((memo, [key, value]) => {
    const attrIsEmptyString = typeof value === 'string' && value.length === 0;
    if (!isNil(value) && !attrIsEmptyString) {
      memo[key] = value;
    }
    return memo;
  }, {} as NonNullableObject<Partial<M>>) as NonNullableObject<Partial<M>>;
}

const flattenCommunicationPreferences = (userCommPreferences?: CommunicationPreferences | null) =>
  isNil(userCommPreferences)
    ? {}
    : userCommPreferences.reduce(
        (acc, commPreference) => ({
          ...acc,
          [commPreference.id]: upperFirst(commPreference.value),
        }),
        {}
      );

/**
 *
 * @param userAttributes object with any values (we'll only take the ones we care about)
 * @returns
 */
export function extractAmplitudeUserAttributes(
  userAttributes: Record<string, any>
): IAmplitudeUserAttributes {
  const flattenedCommunicationPreferences = userAttributes.communicationPreferences
    ? flattenCommunicationPreferences(userAttributes.communicationPreferences)
    : null;
  return {
    ...(userAttributes.Age && { Age: userAttributes.Age }),
    ...(userAttributes.brand && { brand: userAttributes.brand }),
    ...(userAttributes['Date of Birth'] && { 'Date of Birth': userAttributes['Date of Birth'] }),
    ...(userAttributes.env && { env: userAttributes.env }),
    ...(userAttributes.favoriteStores && { favoriteStores: userAttributes.favoriteStores }),
    ...(userAttributes.joinDate && { 'Join Date': userAttributes.joinDate }),
    ...(userAttributes.ccToken && { ccToken: userAttributes.ccToken }),
    ...(userAttributes.language && { language: userAttributes.language }),
    ...(userAttributes.legacyUser && { 'Legacy User': userAttributes.legacyUser }),
    ...(userAttributes.locale && { Locale: userAttributes.locale }),
    ...(userAttributes['Email Opt In'] && { 'Email Opt In': userAttributes['Email Opt In'] }),
    ...(userAttributes.region && { region: userAttributes.region }),
    ...(userAttributes.locationServices && {
      'Location Services': userAttributes.locationServices,
    }),
    ...(userAttributes['RBI Cognito ID'] && { 'RBI Cognito ID': userAttributes['RBI Cognito ID'] }),
    ...(userAttributes['IOS Location Permissions'] && {
      'IOS Location Permissions': userAttributes['IOS Location Permissions'],
    }),
    ...(userAttributes['Android Location Permissions'] && {
      'Android Location Permissions': userAttributes['Android Location Permissions'],
    }),
    ...flattenedCommunicationPreferences,
  };
}

const getAge = (dob?: string): number | undefined => {
  if (dob) {
    return differenceInYears(new Date(), parse(dob, 'yyyy-MM-dd', new Date()));
  }
  return undefined;
};

export const transformUserDetailsToAmplitudeUserAttributes = (
  userInformation: (IUserInfoSubmitData | UserDetails['details']) & {
    customerid: string;
    rbiCognitoId: string;
  }
) => {
  const age = getAge(userInformation.dob);
  const sanitized = sanitizeValues({
    ...(age && { Age: age }),
    'Date of Birth': userInformation.dob,
    'Email Opt In': userInformation.promotionalEmails,
    'RBI Cognito ID': userInformation.customerid,
  });
  const normalized = normalizeBooleans(sanitized);
  const extracted = extractAmplitudeUserAttributes(normalized);
  return extracted;
};

export const setAmplitudeUserAttributes = (userAttributes: IAmplitudeUserAttributes) => {
  const identifyObj = new Identify();
  for (const userAttributeKey in userAttributes) {
    identifyObj.set(userAttributeKey, userAttributes[userAttributeKey]);
  }
  identify(identifyObj);
};
