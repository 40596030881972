import { DefaultTheme } from '@react-navigation/native';

import { designTokens } from 'styles/constants/design-tokens';

export const navigationTheme = {
  ...DefaultTheme,
  colors: {
    ...DefaultTheme.colors,
    primary: Styles.color.primary,
    background: designTokens['bottom-nav-bar-background-color'],
    card: designTokens['background-default'],
    text: designTokens['text-default'],
    border: designTokens['border-default'],
    notification: Styles.color.secondary,
  },
};
