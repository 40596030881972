import React, { useCallback, useMemo, useState } from 'react';

import { useIntl } from 'react-intl';

import { usePromotionValidatePromoCodeMutation } from 'generated/graphql-gateway';
import logger from 'utils/logger';
import { getUniqIdForOffer } from 'utils/offers';

import PromoCodeOfferView from './promo-code-offer.view';
import { IPromoCodeOfferProps, PromoCodeErrorMessageIds } from './types';

const PromoCodeOffer: React.FC<React.PropsWithChildren<IPromoCodeOfferProps>> = ({
  cognitoId,
  offer,
  togglePromoCodeOfferRedeemable,
}) => {
  const { formatMessage } = useIntl();
  const [promotionValidatePromoCodeMutation] = usePromotionValidatePromoCodeMutation();
  const [code, setCode] = useState<string>('');
  const [promoCodeValidationLoading, setPromoCodeValidationLoading] = useState(false);
  const [
    promoCodeErrorMessageId,
    setPromoCodeErrorMessageId,
  ] = useState<PromoCodeErrorMessageIds | null>(null);

  const uniqOfferId = getUniqIdForOffer(offer);

  const formattedMessages = useMemo(
    () => ({
      buttonText: formatMessage({ id: 'unlock' }),
      inputLabel: formatMessage({ id: 'enterPromoCode' }),
      errorMessage: formatMessage({
        id: promoCodeErrorMessageId || 'unknownException',
      }),
      noPromoCodeText: formatMessage({ id: 'dontHaveAPromoCode' }),
    }),
    [formatMessage, promoCodeErrorMessageId]
  );

  const onChangeInput = ({ target: { value } }: React.ChangeEvent<any>) => {
    setCode(value);
    // also clear any input error as the user is changing the code
    setPromoCodeErrorMessageId(currentErrorMsgId => (currentErrorMsgId ? null : currentErrorMsgId));
  };

  const onSubmitPromoCode = useCallback(async () => {
    if (!code) {
      return setPromoCodeErrorMessageId(PromoCodeErrorMessageIds.required);
    }
    try {
      setPromoCodeValidationLoading(true);
      const { data } = await promotionValidatePromoCodeMutation({
        variables: {
          code,
          cognitoId,
          // in this case, shouldRedeem is set to true
          // to trigger immediate redemtion in vendor
          // and enable regular offer redemption flow
          shouldRedeem: true,
          // ensure the code is valid for specific offer
          offers: [offer._id],
        },
      });
      if (!data?.validatePromoCode?.isValid) {
        throw data;
      }
      const offerId = data?.validatePromoCode?.offerId || uniqOfferId;
      togglePromoCodeOfferRedeemable(offerId);
    } catch (error) {
      const errMsgId =
        // @ts-expect-error TS(2571) FIXME: Object is of type 'unknown'.
        PromoCodeErrorMessageIds[error?.validatePromoCode?.reason] ||
        PromoCodeErrorMessageIds.default;
      setPromoCodeErrorMessageId(errMsgId);
      logger.error(`Error applying promo code ${error}`);
    } finally {
      setPromoCodeValidationLoading(false);
      setCode('');
    }
  }, [
    code,
    cognitoId,
    offer._id,
    promotionValidatePromoCodeMutation,
    togglePromoCodeOfferRedeemable,
    uniqOfferId,
  ]);
  return (
    <PromoCodeOfferView
      messages={formattedMessages}
      offer={offer}
      code={code}
      onSubmit={onSubmitPromoCode}
      onChangeInput={onChangeInput}
      isLoading={promoCodeValidationLoading}
      errorMessageId={promoCodeErrorMessageId}
    />
  );
};

export default PromoCodeOffer;
