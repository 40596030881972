import * as React from 'react';
import { FC } from 'react';

import { useIntl } from 'react-intl';

import LoadingAnimation from 'components/loading-animation';
import { ResponsiveCarousel } from 'components/responsive-carousel';
import { LockedOffersCard } from 'components/rewards/locked-offers-card';
import { ILockedOffer } from 'state/offers/hooks/use-locked-offers';

import { Wrapper } from './locked-offers.styled';
import { ILockedOffersViewProps } from './types';
import { cardAttributes } from './utils';

export const LockedOffersView: FC<React.PropsWithChildren<ILockedOffersViewProps>> = ({
  isLoading,
  offers,
  onPress,
}) => {
  const { formatMessage } = useIntl();
  if (isLoading) {
    return <LoadingAnimation />;
  }
  if (!offers || !offers.length) {
    return null;
  }

  const keyExtractor = (item: ILockedOffer, index: number) => {
    const { description } = cardAttributes(item, formatMessage);
    return `${description}-${index}`;
  };

  const renderItem = ({ item, index }: { item: ILockedOffer; index: number }) => {
    const { mode, header, description, expiresText } = cardAttributes(item, formatMessage);
    return (
      <LockedOffersCard
        key={`${description}-${index}`}
        header={header || null}
        subheader={description || null}
        expiresText={expiresText}
        stepsCompleted={item.stepsCompleted}
        offerState={item.offerState}
        progressData={item.steps}
        mode={mode}
        onPress={() => onPress(item)}
      />
    );
  };

  return (
    <Wrapper testID="locked-offers-view-wrapper">
      <ResponsiveCarousel
        centerCards={false}
        bgColor="transparent"
        data={offers}
        itemWidth={250}
        keyExtractor={keyExtractor}
        renderItem={renderItem}
        loop={false}
      />
    </Wrapper>
  );
};
