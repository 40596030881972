import { Box } from '@rbilabs/universal-components';

import theme from './theme';

export const Badge = Box.withConfig({
  _text: {
    fontSize: 'sm',
    textTransform: theme.textTransform,
    fontWeight: 'bold',
  },
  paddingX: '1',
  paddingY: '0.5',
  marginTop: '1',
});
