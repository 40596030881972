import React from 'react';

import { useIntl } from 'react-intl';

import ActionButton from 'components/action-button';
import { useNavigation } from 'hooks/navigation/use-navigation';
import { useOffersContext } from 'state/offers';
import { routes } from 'utils/routing';

import { ButtonContainer, Container, StyledText, Title } from './menu-item-unavailable.styled';
import { IItemUnavailableData, MenuItemUnavailableProps } from './types';

const MenuItemUnavailable: React.FC<React.PropsWithChildren<MenuItemUnavailableProps>> = ({
  isOffer,
}) => {
  const { formatMessage } = useIntl();
  const { selectedOffer, clearSelectedOffer } = useOffersContext();
  const { navigate } = useNavigation();

  const backToMenu = () => navigate(routes.menu);
  const backToOffers = () => {
    if (selectedOffer) {
      clearSelectedOffer({ popToast: true });
    }
    navigate(routes.offers);
  };

  const menuItemUnavailableData: IItemUnavailableData = isOffer
    ? {
        title: 'offerNotAvailable',
        content: 'chooseDifferentOffer',
        buttonLabel: 'backToOffers',
        cta: backToOffers,
      }
    : {
        title: 'itemNotAvailable',
        content: 'chooseDifferentItem',
        buttonLabel: 'backToMenu',
        cta: backToMenu,
      };

  const { title, content, buttonLabel, cta } = menuItemUnavailableData;

  return (
    <Container>
      <Title variant="headerOne" testID="menu-item-unavailable-title">
        {formatMessage({ id: title })}
      </Title>
      <StyledText variant="copyOne">
        {formatMessage({ id: 'selectionNotAvailable' })}
        {'\n'}
        {formatMessage({ id: content })}
      </StyledText>
      <ButtonContainer>
        <ActionButton testID="menu-item-unavailable-cta" fullWidth onPress={cta}>
          {formatMessage({ id: buttonLabel })}
        </ActionButton>
      </ButtonContainer>
    </Container>
  );
};

export default MenuItemUnavailable;
