import { routes } from 'utils/routing';

export const navigatorsNameSanitizationMap = {
  [routes.drawer]: routes.base,
  [routes.tabs]: routes.base,
  [routes.baseMain]: routes.base,
  [routes.menuMain]: routes.menu,
  [routes.menuEmpty]: routes.menu,
  [routes.offersList]: routes.loyaltyOfferList,
};
