import { ActionButtonVariants } from 'components/action-button';
import { theme } from 'styles/configure-theme';

import * as Base from './base';

interface ILinkStylesProps {
  reversed: boolean;
  selected: boolean;
}

const getLinkStyles = ({ reversed, selected }: ILinkStylesProps) => ({
  color: selected
    ? Styles.color.navLinkColor.active
    : reversed
    ? theme.token('text-reversed')
    : theme.token('text-default'),

  _hover: {
    ...(selected && { color: Styles.color.navLinkColor.active }),
  },
});

const StyledLink = Base.StyledLink.withConfig<ILinkStylesProps>(props => getLinkStyles(props));

const StyledLinkExternal = Base.StyledLinkExternal.withConfig<ILinkStylesProps>(props =>
  getLinkStyles(props)
);

export default {
  buttonVariant: ActionButtonVariants.PRIMARY,
  linkVariant: ActionButtonVariants.INVERSE,
  linkVariantReversed: ActionButtonVariants.PRIMARY,
};

export * from './base';
export { StyledLink, StyledLinkExternal };
