import React, { ReactNode, useContext, useEffect } from 'react';

import { QueryLazyOptions } from '@apollo/client';

import { IStaticPage, IStaticPageRoute } from 'remote/queries/static-page';
import { useCRMEventsContext } from 'state/crm-events';

import { useStaticPage } from './hooks/use-static-page';
import { useStaticPageRoutes } from './hooks/use-static-page-routes';

export interface IStaticPageContext {
  currentPage: IStaticPage | undefined;
  currentPageLoading: boolean;
  retrieveStaticPageById(id: string, isNutritionPage?: boolean): void;
  routes: IStaticPageRoute[];
  routesLoading: boolean;
  loadRoutes: (options?: QueryLazyOptions<Record<string, any>> | undefined) => void;
  loadRoutesHasBeenCalled: boolean;
}

export const PageManagerContext = React.createContext<IStaticPageContext>({} as IStaticPageContext);
export const usePageManagerContext = () => useContext(PageManagerContext);

export function PageManagerProvider({ children }: { children: ReactNode }) {
  const {
    loadRoutes,
    routes,
    loading: routesLoading,
    loadRoutesHasBeenCalled,
  } = useStaticPageRoutes();
  const { currentPage, loading: currentPageLoading, retrieveStaticPageById } = useStaticPage();
  const { updateStaticRoutes } = useCRMEventsContext();

  // Updates static routes in mParticle to log when users visit static pages
  useEffect(() => {
    updateStaticRoutes(routes);
  }, [routes, updateStaticRoutes]);

  return (
    <PageManagerContext.Provider
      value={{
        currentPage,
        currentPageLoading,
        retrieveStaticPageById,
        routes,
        routesLoading,
        loadRoutes,
        loadRoutesHasBeenCalled,
      }}
    >
      {children}
    </PageManagerContext.Provider>
  );
}

export default PageManagerContext.Consumer;
