import { useCallback } from 'react';

import { useLocale } from 'state/intl';
import { LOCALE_SELECTED_QUERY_PARAM } from 'state/intl/constants';
import { REGIONS } from 'state/intl/types';
import { NEXT_URL_QUERY_PARAM } from 'state/location/constants';
import { isNative } from 'utils/environment';

import { domainChangeByRegion } from './domainChangeByRegion';
import { IUseChangeLocaleProps } from './types';

export const useChangeLocale = ({
  redirectToCurrentLocation,
  langKey,
  regionKey,
}: IUseChangeLocaleProps) => {
  const {
    regionDomainValues,
    region,
    clearRegionSpecificStorage,
    setHasShownLocaleSelector,
  } = useLocale();
  const changeLocale = useCallback(() => {
    const isDeployPreview = window.location.href.includes('preview-');

    const encodedCurrentUrl = encodeURIComponent(window.location.pathname);
    let strParams = `?lang=${langKey}&${LOCALE_SELECTED_QUERY_PARAM}=1`;

    if (redirectToCurrentLocation) {
      strParams += `&${NEXT_URL_QUERY_PARAM}=${encodedCurrentUrl}`;
    }

    const params = new URLSearchParams(strParams);

    let url = window.location.origin.replace(
      regionDomainValues[region],
      regionDomainValues[regionKey]
    );

    url = domainChangeByRegion(url, regionKey as REGIONS);

    // Don't change the URL on native
    // Changing the url will cause Android to open the web browser
    if (isNative || isDeployPreview) {
      url = window.location.origin;
    }

    // Add the region query param and clear localstorage
    // if we are not going to do a full url change
    if (url === window.location.origin) {
      params.append('region', regionKey);

      clearRegionSpecificStorage();
    }

    setHasShownLocaleSelector();
    window.location.href = `${url}?${params}`;
  }, [
    clearRegionSpecificStorage,
    langKey,
    redirectToCurrentLocation,
    region,
    regionDomainValues,
    regionKey,
    setHasShownLocaleSelector,
  ]);
  return { changeLocale };
};
