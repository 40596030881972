import React from 'react';

import { NativeStackNavigatorWithBack } from 'navigation/native-stack-navigator-with-back';
import { INativeStackNavigatorScreens } from 'navigation/native-stack-navigator-with-back/types';
import Home from 'pages/home';
import StaticPage from 'pages/static';
import { routes } from 'utils/routing';

const screens: INativeStackNavigatorScreens[] = [
  {
    name: routes.baseMain,
    component: Home,
  },
  {
    name: routes.staticPage,
    component: StaticPage,
  },
];

export const HomeNavigator = () => <NativeStackNavigatorWithBack screens={screens} />;
