import { Header, Text, VStack } from '@rbilabs/universal-components';

import { CONTAINER_SPACING_TOP, CONTAINER_SPACING_TOP_DESKTOP } from './constants';

export const Container = VStack.withConfig({
  width: 'full',
  alignItems: 'center',
  marginTop: {
    base: CONTAINER_SPACING_TOP,
    md: CONTAINER_SPACING_TOP_DESKTOP,
  },
  marginBottom: {
    md: '8',
  },
});

export const Title = Header.withConfig({
  textTransform: 'capitalize',
  margin: 0,
});

export const StyledText = Text.withConfig({
  margin: 0,
  textAlign: 'center',
});

export const ButtonContainer = VStack.withConfig({
  alignItems: 'center',
  marginTop: '4',
  width: {
    base: '80%',
    md: '300px',
  },
});

export const ButtonLabel = Text.withConfig({
  margin: 0,
  textTransform: 'capitalize',
});
