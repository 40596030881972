import { IMainMenuView, IMainMenuViewOption } from '@rbi-ctg/menu';
import { IGetMenuSectionsQuery } from 'generated/sanity-graphql';
import { useRoute } from 'hooks/navigation/use-route';
import { useDayPartContext } from 'state/day-part';
import { useMenuContext } from 'state/menu';
import { useServiceModeContext } from 'state/service-mode';
import { getTabbedMenuData, optionToUrl } from 'utils/menu';
import { useIsBrowseMenu } from 'utils/menu/hooks/use-is-browse-menu';

interface IUseTabbedHeaderProps {
  menuData: IGetMenuSectionsQuery['Menu'] | IMainMenuView | null | undefined;
}

/**
 * Hook to be used in Menu Section Navigator for React Navigation
 * Decouples from unnecessary logic in useTabbedMenuHeader since tabs are split between screens in new navigation
 */
export function useMenuSectionTabs({ menuData }: IUseTabbedHeaderProps) {
  const { pathname } = useRoute();
  const { isBrowseMenu } = useIsBrowseMenu(pathname);
  const { serviceMode } = useServiceModeContext();
  const { activeDayParts: currentActiveDayParts } = useDayPartContext();
  const {
    getPricingAndAvailability,
    getDayPart,
    showStaticMenu,
    showBurgersForBreakfast,
  } = useMenuContext();

  const mainMenuFilteredItems: IMainMenuViewOption[] =
    (!showStaticMenu
      ? // @ts-expect-error TS(2349) FIXME: This expression is not callable.
        menuData?.options?.filter(item => {
          if (item?.__typename === 'Combo') {
            return false;
          }

          return getPricingAndAvailability(item?._id)?.isAvailable;
        })
      : menuData?.options) || [];

  const { currentDayPart } = getDayPart({ showStaticMenu });

  const menuItems = getTabbedMenuData({
    mainMenuItems: mainMenuFilteredItems,
    dayPartMenu: currentDayPart,
    activeDayParts: currentActiveDayParts,
    showBurgersForBreakfast,
    serviceMode,
    showStaticMenu,
  });

  const tabbedMenuData = menuItems.map(item => ({
    label: item.name?.locale,
    to: optionToUrl(item, isBrowseMenu),
    tabItemId: item._id,
  }));

  return tabbedMenuData;
}
