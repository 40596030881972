import * as React from 'react';
import { memo } from 'react';

import { Box, HStack, Header, Link, Pressable, Text } from '@rbilabs/universal-components';

import Picture from 'components/picture';
import { theme } from 'styles/configure-theme';

import { QuestBadge } from './quest-badge';
import { QuestCallToAction } from './quest-card.styled';
import { IQuestCardProps } from './types';

export const QuestCard = memo(
  ({
    image,
    altText,
    title,
    callToAction,
    callToActionText,
    onPressImage,
    onPressLink,
    linkText,
    description,
    badgeText,
    reversedBadge,
  }: IQuestCardProps) => {
    return (
      <Box borderRadius="xl" backgroundColor={theme.token('background-pattern')} shadow="1">
        {image && (
          <Pressable onPress={onPressImage}>
            <Picture
              height="$48"
              width="full"
              borderTopRadius="xl"
              image={image}
              flexDirection="column"
              backgroundColor={theme.token('background-menu-customization-image')}
              alt={altText || 'quest image'}
            />
            <QuestBadge
              position="absolute"
              top="$4"
              left="$4"
              reversedBadge={reversedBadge}
              text={badgeText}
            />
          </Pressable>
        )}

        <Box padding="$4">
          {!image && <QuestBadge mb="$1" text={badgeText} reversedBadge={reversedBadge} />}

          <Header variant="headerTwo">{title}</Header>
          <Text marginBottom="$4">{description}</Text>

          <HStack
            // row reverse to display the link aligned to the right
            {...(Boolean(linkText) && { direction: 'row-reverse' })}
            justifyContent="space-between"
            alignItems="flex-end"
          >
            {Boolean(linkText) && <Link onPress={onPressLink}>{linkText}</Link>}

            {Boolean(callToActionText) && (
              <QuestCallToAction
                _text={{ variant: 'copyTwo', fontSize: 'sm' }}
                onPress={callToAction}
              >
                {callToActionText}
              </QuestCallToAction>
            )}
          </HStack>
        </Box>
      </Box>
    );
  }
);
