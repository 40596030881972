import React, { VFC, useMemo } from 'react';

import { useIntl } from 'react-intl';

import { ListItemGroup } from 'components/list-item-group';
import { getModifiersActions } from 'components/product-detail/utils';

import { ModifierGroup } from './product-customization-modifier-group';
import { ProductCustomizationReset } from './product-customization-reset';
import { IModifierGroup, IProductCustomizationModifiersProps } from './types';
import { groupModifiersByDisplayName, isDefaultSelectionAction } from './utils';

export const ProductCustomizationModifiers: VFC<IProductCustomizationModifiersProps> = ({
  modifierSelections,
  modifierDefaults,
  onModifierSelectionsChange,
  onResetModifierSelections,
  pickerAspectExclusions,
  selectedItem,
}) => {
  const { formatMessage } = useIntl();

  const { options: selectedItemModifiers } = selectedItem;

  const modifierGroupsProps = useMemo<IModifierGroup[]>(() => {
    if (!selectedItemModifiers?.length) {
      return [];
    }

    const modifierGroupsMap = groupModifiersByDisplayName({
      modifiers: selectedItemModifiers,
      modifiersMap: modifierSelections,
      pickerAspectExclusions,
    });
    return Object.entries(modifierGroupsMap).map<IModifierGroup>(
      ([displayGroupName, modifiers], index) => {
        // We need to auto expand the first item and all the other items with the autoExpandAccordion prop enabled.
        const shouldAutoExpand =
          index === 0 || modifiers[0].modifier.displayGroup?.autoExpandAccordion;

        return {
          displayGroupName,
          autoExpandAccordion: shouldAutoExpand,
          modifiers,
        };
      }
    );
  }, [selectedItemModifiers, modifierSelections, pickerAspectExclusions]);

  const showResetButton = useMemo(() => {
    return getModifiersActions(modifierSelections).some(
      selAction => !isDefaultSelectionAction(modifierDefaults, selAction)
    );
  }, [modifierSelections, modifierDefaults]);

  return (
    <ListItemGroup
      header={formatMessage({ id: 'customize' })}
      headerAction={
        <ProductCustomizationReset
          showButton={showResetButton}
          onReset={onResetModifierSelections}
        />
      }
      items={modifierGroupsProps.map(modifierGroupProps => (
        <ModifierGroup
          key={modifierGroupProps.displayGroupName}
          {...modifierGroupProps}
          onModifierSelectionChange={onModifierSelectionsChange}
          selectedItem={selectedItem}
        />
      ))}
    />
  );
};
