import React, { FC } from 'react';

import { makeUclComponent } from '@rbilabs/universal-components';

import { hiddenAccessibilityPlatformProps } from 'utils/accessibility';

import { ChipContainer, ChipIcon, ChipText } from './chip.styled';
import { ChipProps } from './types';

const Chip: FC<React.PropsWithChildren<ChipProps>> = ({
  children,
  icon: iconProps,
  roundedCorners,
  ...props
}) => (
  <ChipContainer $roundedCorners={roundedCorners} {...props}>
    {!!iconProps && (
      <ChipIcon
        {...iconProps}
        testID={props.testID ? `${props.testID}-icon` : undefined}
        {...hiddenAccessibilityPlatformProps}
      />
    )}
    <ChipText $hasIcon={!!iconProps} {...props._text}>
      {children}
    </ChipText>
  </ChipContainer>
);

export default makeUclComponent(Chip);
