import { createNavigationContainerRef } from '@react-navigation/native';

import { INavigationHook } from 'hooks/navigation/types';

/**
 * The navigation ref is exposed to all components that might need to navigate and that not
 * have access to call the `useNavigation` hook.
 * Docs reference: https://reactnavigation.org/docs/navigating-without-navigation-prop/
 */
export const navigationRef = createNavigationContainerRef<Record<string, any>>();

/**
 * This method should only be used in cases where we can't call the `useNavigation` hook
 * @param route The string key for the route to navigate
 * @param params Optional object with parameters for the navigated screen
 */
export const navigate: INavigationHook['navigate'] = (route, params) => {
  if (navigationRef.isReady()) {
    navigationRef.navigate(route, params);
  }
};
