import { getStateFromPath } from '@react-navigation/native';

import { processLocalizedRoute } from 'hooks/navigation/utils';
import { convertQueryStringToObject } from 'utils/navigation';
import { isItemPickerOrComboRoute, routes } from 'utils/routing';

type GetStateFromPathFn = typeof getStateFromPath;

/**
 * Utility middleware to redirect deeplink paths to specific routes that
 * cannot be expressed as a linking config. For example, exact same path that
 * redirects to multiple Routes.
 *
 * e.g. /menu/:id
 * /menu/section-123 -> Drawer>Tabs>/menu>/menu-content
 * /menu/combo-abc   -> /product-detail
 */
export const getStateFromPathMiddleware: GetStateFromPathFn = (pathRaw, options) => {
  // when the route comes from Deeplinking it is missing the prefix `/`
  const path = pathRaw.startsWith('/') ? pathRaw : `/${pathRaw}`;
  if (path.startsWith('/menu/')) {
    const [routePart, queryString] = path.split('?');
    const id = routePart.split('/menu/')[1];
    const name = isItemPickerOrComboRoute(path) ? routes.productDetail : routePart;

    return {
      routes: [
        {
          name,
          params: {
            ...(id && { id }),
            ...(queryString && convertQueryStringToObject(queryString)),
          },
          path,
        },
      ],
    };
  }

  const screenPath = processLocalizedRoute(path);
  return getStateFromPath(screenPath, options);
};
