import { useIsMobileBp } from 'hooks/breakpoints';
import { useRoute } from 'hooks/navigation/use-route';
import { RunningExperiments, useExperiments } from 'state/experiments';
import type { VariantOptions } from 'state/experiments/types';
import { routes } from 'utils/routing';

import type { ExperimentVariant } from './types';

export function useExperimentVariant(variantOptions?: VariantOptions): ExperimentVariant {
  const { variant } = useExperiments();

  const isMobile = useIsMobileBp();

  if (!isMobile) {
    return 'control';
  }

  if (variant(RunningExperiments.SimplifiedLandingPage, 'separate-ordering-ctas', variantOptions)) {
    return 'separate-ordering-ctas';
  }

  return 'control';
}

export function useShouldShowExperimentAtPath(): boolean {
  const { pathname } = useRoute();
  return pathname === routes.base || pathname === routes.baseMain;
}
