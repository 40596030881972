/**
 * This file utilizes and enhances @sanity/block-content-to-react so that we can
 * more easily customize how the sanity block renderer maps its data structure to
 * react components.
 */
import React, { useMemo } from 'react';

import {
  Box,
  BoxProps,
  Header,
  Text,
  TextProps,
  View,
  makeUclComponent,
} from '@rbilabs/universal-components';
import BlockContent from '@sanity/block-content-to-react';

import { IBaseProps } from '@rbi-ctg/frontend';
import { ISanityBlockContent, ISanityTypographyBlock } from '@rbi-ctg/menu';

import { FormatSanityLink } from './helpers';
import {
  ComponentOverride,
  IBlockContentOverrides,
  ISerializers,
  ITypographyBlockOverrides,
} from './types';

interface IDefaultBlockRenderer extends IBaseProps {
  node: {
    style?: string;
  };
}

interface IBlockRenderer extends IDefaultBlockRenderer {
  blockContentOverrides?: IBlockContentOverrides | ITypographyBlockOverrides;
  textProps?: TextProps;
  isCollapsed?: boolean;
}

const blockContent: IBlockContentOverrides = {
  h1: ({ children }) => <Header variant="headerOne">{children}</Header>,
  h2: ({ children }) => <Header variant="headerTwo">{children}</Header>,
  h3: ({ children }) => <Header variant="headerThree">{children}</Header>,
  h4: ({ children }) => <Header variant="headerFour">{children}</Header>,
  h5: ({ children }) => <Header variant="headerFour">{children}</Header>,
  h6: ({ children }) => <Header variant="headerFour">{children}</Header>,
  p: ({ children }) => <Text variant="copyOne">{children}</Text>,
  quote: ({ children }) => <Text variant="copyOne">{children}</Text>,
  blockquote: ({ children }) => <Text variant="copyOne">{children}</Text>,
  // @ts-expect-error TS(2322) FIXME: Type '({ children, textProps }: { children: React.... Remove this comment to see the full error message
  normal: ({ children, textProps }: { children: React.ReactNode; textProps: TextProps }) => (
    <Text variant="copyOne" {...textProps}>
      {children}
    </Text>
  ),
};

// This code is based off of https://github.com/sanity-io/block-content-to-react#customizing-the-default-serializer-for-block-type
const BlockRenderer: React.FC<React.PropsWithChildren<IBlockRenderer>> = props => {
  const { node, children, blockContentOverrides = {}, textProps } = props;
  const { style = 'normal' } = node;

  // Match style to the block child tag override if it exists
  const MatchingTagOverride: ComponentOverride | undefined = blockContentOverrides[style];

  if (MatchingTagOverride) {
    return <MatchingTagOverride textProps={textProps}>{children}</MatchingTagOverride>;
  }

  // Fall back to default handling
  return BlockContent.defaultSerializers.types.block(props);
};

interface ISanityBlockRenderer extends BoxProps {
  content?: ISanityBlockContent[] | ISanityTypographyBlock[] | null;
  serializers?: ISerializers;
  blockContentOverrides?: IBlockContentOverrides | ITypographyBlockOverrides;
  textProps?: TextProps;
  linkProps?: TextProps;
  strongProps?: TextProps;
}

// See https://github.com/sanity-io/block-content-to-react
// for more options
const createDefaultSerializers = (
  blockContentOverrides: IBlockContentOverrides | ITypographyBlockOverrides,
  textProps?: TextProps,
  linkProps?: TextProps,
  strongProps?: TextProps
): ISerializers => ({
  marks: {
    small: props => (
      <View>
        <Text variant="copyTwo" {...props} />
      </View>
    ),
    link: props => FormatSanityLink({ ...props, linkProps }),
    strong: props => <Text bold {...props} {...strongProps} />,
    em: props => <Text underline {...props} />,
  },
  types: {
    // @ts-expect-error TS(2322) FIXME: Type '(props: IDefaultBlockRenderer) => JSX.Elemen... Remove this comment to see the full error message
    block: (props: IDefaultBlockRenderer) => (
      <BlockRenderer
        {...props}
        blockContentOverrides={blockContentOverrides}
        textProps={textProps}
      />
    ),
  },
});

const SanityBlockRenderer: React.FC<React.PropsWithChildren<ISanityBlockRenderer>> = ({
  content,
  serializers = {},
  blockContentOverrides = blockContent,
  textProps,
  linkProps,
  strongProps,
  ...props
}) => {
  const allSerializers = useMemo(() => {
    const defaultSerializers = createDefaultSerializers(
      blockContentOverrides,
      textProps,
      linkProps,
      strongProps
    );
    return { ...defaultSerializers, ...serializers };
  }, [blockContentOverrides, textProps, linkProps, strongProps, serializers]);

  if (!content) {
    return null;
  }

  return (
    <Box {...props}>
      <BlockContent
        blocks={content}
        serializers={allSerializers}
        renderContainerOnSingleChild
        isCollapsed
      />
    </Box>
  );
};

const UCLSanityBlockRenderer = makeUclComponent(SanityBlockRenderer);

export default UCLSanityBlockRenderer;
