import React from 'react';

import { ScrollView } from '@rbilabs/universal-components';

import { MarketingTileSkeleton } from 'components/marketing-tile/marketing-tile.skeleton';
import { HeroSkeleton } from 'components/ucl/hero/hero.skeleton';

export default React.memo(function HomePageSkeleton() {
  return (
    <ScrollView>
      <HeroSkeleton />
      <MarketingTileSkeleton />
    </ScrollView>
  );
});
