import * as React from 'react';
import { useCallback } from 'react';

import { MarketingCarouselItem } from '@rbilabs/universal-components';

import Picture from 'components/picture';
import { useNavigation } from 'hooks/navigation/use-navigation';
import { NotificationDetails } from 'state/loyalty/hooks/types';

import { DescriptionTextBlock } from './loyalty-notification-card.styled';

const LoyaltyNotificationCard = React.memo<{
  notification: NotificationDetails;
}>(({ notification: { title, image, description, path, id } }) => {
  const mainImage = image?.locale;
  const titleText = title?.locale;
  const descriptionContent = description?.localeRaw;

  const { linkTo } = useNavigation();

  const onClickHandler = useCallback(() => {
    const fullPath = path?.locale || '/';

    linkTo(fullPath);
  }, [linkTo, path]);

  if (!mainImage || !titleText || !descriptionContent) {
    return null;
  }

  return (
    <MarketingCarouselItem
      header={titleText}
      image={
        image && <Picture image={mainImage} alt={titleText} borderTopRadius="xl" width="253px" />
      }
      content={<DescriptionTextBlock width="180px" content={descriptionContent} />}
      onPress={onClickHandler}
    />
  );
});

export default LoyaltyNotificationCard;
