import React, { useCallback, useEffect } from 'react';

import { Box, Header, Text, makeUclComponent } from '@rbilabs/universal-components';
import { useIntl } from 'react-intl';

import Modal, { ModalSize, modalSizeStyles } from 'components/modal';
import RestaurantRedemptionModal from 'components/offer-redemption-modal/redemption-modal';
import { RedemptionButtons } from 'components/offers/ui-refresh/redemption-buttons';
import { IAttemptOfferRedemptionArgs } from 'components/offers/ui-refresh/types';
import { useOfferDetails } from 'components/offers/ui-refresh/use-offer-details';
import { useOfferRedemptionFlow } from 'components/offers/ui-refresh/use-offer-redemption-flow';
import Picture from 'components/picture';
import { LockedCard } from 'components/rewards/locked-offers-card/locked-card';
import { Container as CardContainer } from 'components/rewards/locked-offers-card/locked-offers-card.styled';
import { cardAttributes } from 'components/rewards/locked-offers/utils';
import SanityBlockRenderer from 'components/sanity-block-renderer';
import useDialogModal from 'hooks/use-dialog-modal';
import { useAuthContext } from 'state/auth';
import { useOffersContext } from 'state/offers';
import { ILockedOffer } from 'state/offers/hooks/use-locked-offers';
import noop from 'utils/noop';

const StyledModal = makeUclComponent(Modal).withConfig(p => ({
  flexDirection: 'row',
  justifyContent: 'center',

  minWidth: {
    md: modalSizeStyles.minWidth.REGULAR,
  },
}));

const Container = Box.withConfig({
  height: '100%',
  width: 'full',
  alignItems: 'center',
  backgroundColor: Styles.color.white,
  maxWidth: {
    base: 240,
    md: 380,
  },
});

const StyledPicture = makeUclComponent(Picture).withConfig({
  height: 'auto',

  minHeight: {
    md: 160,
    base: 120,
  },
});

const Heading = Header.withConfig({
  margin: 0,
  fontFamily: Styles.fontFamily.base,
  fontSize: '2xl',
  lineHeight: '2xs',
  textTransform: 'uppercase',
  variant: 'headerThree',
});

const Description = Text.withConfig({
  marginTop: '$0.5',
  marginRight: 0,
  marginBottom: 0,
  marginLeft: 0,
  fontFamily: Styles.fontFamily.body,
  lineHeight: 'sm',
  fontSize: 'sm',
});

const ButtonWrapper = Box.withConfig({
  alignItems: 'center',
  marginTop: '-16px',
});

const CardWrapper = makeUclComponent(CardContainer).withConfig({
  width: 'full',
  height: 'unset',
  minWidth: 'unset',
  margin: 0,
  backgroundColor: Styles.color.white,
});

interface IRedemptionLockedOfferModalProps {
  offer: ILockedOffer;
  onDismiss: () => void;
}

const RedemptionLockedOfferDialog = ({ offer, onDismiss }: IRedemptionLockedOfferModalProps) => {
  const { formatMessage } = useIntl();
  const { isAuthenticated: authenticated } = useAuthContext();
  const { clearSelectedOffer, lockedOffers = [], selectedOffer } = useOffersContext();
  const offerIndex = lockedOffers.findIndex(o => o._id === offer._id);

  const {
    loginRequiredForRedemption,
    mobileRedemptionAvailable,
    disableInRestaurantOfferRedemptions,
    disableMobileOfferRedemptions,
    offerAvailableRightNow,
    redemptionErrorMessage,
    restaurantRedemptionAvailable,
    showRestaurantRedemptionButton,
    showMobileRedemptionButton,
    restaurantRedemptionButtonUiType,
    showSignUpToRedeem,
    noPossibleRedemption,
  } = useOfferDetails({
    authenticated,
    offer,
    offerIndex,
  });

  const {
    attemptOfferRedemption: onRedeemOffer,
    onConfirmReadyToRedeem,
    onCancelReadyToRedeem,
    onDismissRestaurantRedemption,
    showReadyToRedeemDialog,
    showRestaurantRedemptionModal,
    restaurantRedemptionStartTime,
  } = useOfferRedemptionFlow({
    offer,
    authenticated,
    offerAvailableRightNow,
    mobileRedemptionAvailable,
    restaurantRedemptionAvailable,
    loginRequiredForRedemption,
  });

  const [ReadyToRedeemDialog, openReadyToRedeemDialog] = useDialogModal({
    modalAppearanceEventMessage: 'Confirm Ready to Redeem',
    onConfirm: () => onConfirmReadyToRedeem(true),
    onDismiss: () => onCancelReadyToRedeem(true),
    showCancel: false,
  });

  useEffect(() => {
    if (showReadyToRedeemDialog) {
      openReadyToRedeemDialog();
    }
  }, [openReadyToRedeemDialog, showReadyToRedeemDialog]);

  const onPressRedemption = useCallback(
    (redemptionMethod: IAttemptOfferRedemptionArgs) => {
      onRedeemOffer(redemptionMethod);
    },
    [onRedeemOffer]
  );

  const onDismissRestaurantRedeemDialog = useCallback(() => {
    onDismissRestaurantRedemption();
    clearSelectedOffer({ doLog: false });
    onDismiss();
  }, [clearSelectedOffer, onDismiss, onDismissRestaurantRedemption]);

  const {
    mode,
    header,
    description,
    expiresText,
    terms,
    howToRedeemSanityContent,
  } = cardAttributes(offer, formatMessage);

  const isComplete = offer.stepsCompleted === offer.stepsTotal;

  return (
    <StyledModal
      size={ModalSize.SMALL}
      onDismiss={() => {
        clearSelectedOffer();
        onDismiss();
      }}
      mParticleEventData={{
        modalAppearanceEventMessage: 'Redeem Locked Offer',
      }}
    >
      <Container testID="redemption-locked-offer-dialog">
        <StyledPicture
          alt={formatMessage({ id: 'lockedOfferImage' })}
          image={offer?.localizedImage?.locale?.app!}
        />
        <Box textAlign="center">
          <Heading>
            <SanityBlockRenderer content={header} />
          </Heading>
          <Description>
            <SanityBlockRenderer content={description} />
          </Description>
        </Box>
        {isComplete ? (
          <ButtonWrapper testID="redemption-buttons">
            <RedemptionButtons
              disableMobileOfferRedemptions={disableMobileOfferRedemptions}
              disableInRestaurantOfferRedemptions={disableInRestaurantOfferRedemptions}
              showMobileRedemptionButton={showMobileRedemptionButton}
              showSignUpToRedeem={showSignUpToRedeem}
              showRestaurantRedemptionButton={showRestaurantRedemptionButton}
              onRedeemOffer={onPressRedemption}
              redemptionErrorMessage={redemptionErrorMessage}
              restaurantRedemptionButtonUiType={restaurantRedemptionButtonUiType}
              noPossibleRedemption={noPossibleRedemption}
            />
          </ButtonWrapper>
        ) : (
          <CardWrapper>
            <LockedCard
              header={null}
              subheader={description || null}
              expiresText={expiresText}
              stepsCompleted={offer.stepsCompleted}
              offerState={offer.offerState}
              progressData={offer.steps}
              mode={mode}
              showButton={false}
              showBadge={false}
              onPress={noop}
            />
          </CardWrapper>
        )}
        <Box>
          <Header variant="headerFour">{formatMessage({ id: 'howToRedeem' })}</Header>
          <SanityBlockRenderer content={howToRedeemSanityContent} />
        </Box>
        <Box>
          <Header variant="headerFour">{formatMessage({ id: 'offerTerms' })}</Header>
          <SanityBlockRenderer content={terms} />
        </Box>
      </Container>

      <ReadyToRedeemDialog
        testID="ready-to-redeem-dialog"
        heading={formatMessage({ id: 'couponWillExpire' })}
        body={formatMessage({ id: 'confirmDialogBody' })}
        buttonLabel={formatMessage({ id: 'redeem' })}
        showCloseButton
      />
      {showRestaurantRedemptionModal && selectedOffer && (
        <RestaurantRedemptionModal
          selectedOffer={selectedOffer}
          redemptionStartTime={restaurantRedemptionStartTime}
          onDismiss={onDismissRestaurantRedeemDialog}
        />
      )}
    </StyledModal>
  );
};

export default RedemptionLockedOfferDialog;
