import { Badge } from './badge';
import { IAccentBadgeProps } from './types';

export const AccentBadge = Badge.withConfig<IAccentBadgeProps>({
  _text: {
    color: Styles.color.grey.three,
    fontWeight: '400',
  },
  backgroundColor: Styles.color.grey.five,
  marginY: '1',
  marginRight: '1',
});
