import { Box, Pressable } from '@rbilabs/universal-components';

/**
 * Spreads children horizontally and centers them vertically
 */
export const SpreadContentContainer = Box.withConfig({
  justifyContent: 'space-between',
  alignItems: 'center',
  textAlign: 'left',
  flexDirection: 'row',
  width: 'full',
});

/**
 * Adds space for an Accordion Item's content.
 */
export const AccordionContentWrapper = Box.withConfig({
  marginRight: '$4',
  width: 'full',
  marginTop: 0,
  marginBottom: 0,
  paddingX: 0,
});

/**
 * Unstyled button for triggering Accordion dropdowns.
 */
export const AccordionActivatorButton = Pressable.withConfig({
  width: 'full',
  paddingTop: '$3',
  paddingBottom: '$2',
  paddingLeft: '$4',
  paddingRight: '$8',
  background: 'transparent',
});

export const AccordionWrapper = Box.withConfig({
  marginX: 0,
  borderBottomWidth: '2',
  borderColor: 'token.background-default',
});
