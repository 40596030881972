import { IntlFormatters } from 'react-intl';

import { ILockedOffer } from 'state/offers/hooks/use-locked-offers';
import { UIPattern } from 'state/offers/types';

const HOUR_IN_MS = 1000 * 60 * 60;
const DAY_IN_MS = HOUR_IN_MS * 24;

export const headerText = (offer: ILockedOffer) => {
  if (offer.allGoalsCompleted) {
    return offer?.completedChallengeHeader?.localeRaw;
  }
  return offer?.name?.localeRaw;
};
export const descriptionText = (offer: ILockedOffer) => {
  if (offer.allGoalsCompleted) {
    return offer?.completedChallengeDescription?.localeRaw;
  }
  return offer?.description?.localeRaw;
};

export const countDown = (expireDate: Date, formatMessage: IntlFormatters['formatMessage']) => {
  const now = new Date().getTime();
  const countdown = expireDate.getTime() - now;
  if (countdown > 0) {
    const days = Math.floor(countdown / DAY_IN_MS);
    const hours = Math.floor((countdown % DAY_IN_MS) / HOUR_IN_MS);
    return formatMessage({ id: 'lockedOfferExpires' }, { days, hours });
  }
  return formatMessage({ id: 'promotionEnded' });
};

export const cardAttributes = (
  offer: ILockedOffer,
  formatMessage: IntlFormatters['formatMessage']
) => {
  const mode = (offer?.uiPattern as UIPattern) || UIPattern.STEPPER;
  const header = headerText(offer);
  const description = descriptionText(offer);
  const expiresText = countDown(offer?.expiresOnDatetime || new Date(), formatMessage);
  const terms = offer.moreInfo?.localeRaw;
  const howToRedeemSanityContent = offer?.howToRedeem?.localeRaw;

  return {
    mode,
    header,
    description,
    expiresText,
    terms,
    howToRedeemSanityContent,
  };
};
