import * as React from 'react';
import { FC } from 'react';

import { useRoutesDisplayBottomService as useRoutesDisplayServiceNotification } from 'components/bottom-service-mode/hooks/use-routes-display-bottom-service';
import { DisableTopServiceMode__Experiment } from 'experiments/service-mode/disable-top-service-mode-experiment';
import { DisableServiceModeForHomePage__Experiment } from 'experiments/simple-home-page';

import { useServiceModeNotification } from './hooks/use-service-mode-notification';
import TopServiceModeNotificationView from './top-service-mode-notification.view';
import { ITopServiceModeNotificationProps } from './types';

const StickyServiceNotification = TopServiceModeNotificationView.withConfig({
  zIndex: Styles.zIndex.overlay + 100,
  width: 'full',
});

/**
 *
 * TopServiceModeNotification purpose
 *
 */
const TopServiceModeNotification: FC<React.PropsWithChildren<
  ITopServiceModeNotificationProps
>> = () => {
  const showServiceNotificationOnRoutes = useRoutesDisplayServiceNotification();
  const topServiceModeNotificationDetails = useServiceModeNotification();

  if (!showServiceNotificationOnRoutes) {
    return null;
  }

  return (
    topServiceModeNotificationDetails && (
      <DisableServiceModeForHomePage__Experiment>
        <StickyServiceNotification {...topServiceModeNotificationDetails} />
      </DisableServiceModeForHomePage__Experiment>
      // <CancellationModal /> Logic will be done in the FAB button work
    )
  );
};

export default function TopServiceModeNotificationIfExperimentAllowed() {
  return (
    <DisableTopServiceMode__Experiment>
      <TopServiceModeNotification />
    </DisableTopServiceMode__Experiment>
  );
}
