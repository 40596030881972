import * as React from 'react';
import { FC, useState } from 'react';

import { IconButton } from '@rbilabs/universal-components';
import { useIntl } from 'react-intl';

import { IBackendCartEntries } from '@rbi-ctg/menu';
import { FavoriteIcon } from 'components/favorite-icon';
import { SaveFavoriteOrder } from 'components/save-favorite-order';
import {
  GetUserFavoritesRefsDocument,
  ICartEntryInput,
  useCreateFavoriteMutation,
  useDeleteFavoriteMutation,
  useGetUserFavoritesRefsQuery,
} from 'generated/rbi-graphql';
import useDialogModal from 'hooks/use-dialog-modal';
import { useToast } from 'hooks/use-toast';
import { isOfferType } from 'state/order/utils';
import { frontendToBackendCartEntryMap } from 'utils/cart';
import { mapBackendToFrontend } from 'utils/reorder';

import { IFavoriteOrderButtonProps } from './types';

/**
 *
 * Favorite Order Button
 *
 */
const FavoriteOrderButton: FC<React.PropsWithChildren<IFavoriteOrderButtonProps>> = ({ order }) => {
  const { formatMessage } = useIntl();
  const { rbiOrderId } = order;
  const toast = useToast();
  const [saveModalOpen, setSaveModalOpen] = useState<boolean>(false);

  const shouldDisplayHeart = !(order.cart.cartEntries || []).some((entry: IBackendCartEntries) =>
    isOfferType(entry.type)
  );

  const { data } = useGetUserFavoritesRefsQuery({
    skip: !shouldDisplayHeart,
  });

  const [deleteFavorite, { loading: deleteLoading }] = useDeleteFavoriteMutation({
    onCompleted: () =>
      toast.show({
        text: formatMessage({ id: 'removeFavoriteSucess' }),
        variant: 'positive',
      }),
  });
  const [createFavorite, { loading: createLoading }] = useCreateFavoriteMutation({
    onCompleted: () =>
      toast.show({
        text: formatMessage({ id: 'saveFavoriteOrderSucess' }),
        variant: 'positive',
      }),
  });

  const onClick = () => {
    if (isFavorite) {
      openRemoveFavoriteModal();
    } else {
      setSaveModalOpen(true);
    }
  };

  const entries = (order.cart.cartEntries.map(cartEntry =>
    frontendToBackendCartEntryMap(mapBackendToFrontend(cartEntry))
  ) as unknown) as ICartEntryInput[];

  const saveFavoriteOrder = async (name: string) => {
    return await createFavorite({
      variables: {
        input: {
          entries,
          name,
          ref: rbiOrderId,
        },
      },
      awaitRefetchQueries: true,
      refetchQueries: [{ query: GetUserFavoritesRefsDocument }],
    });
  };

  const removeFavoriteOrder = async (favoriteId: string) => {
    return await deleteFavorite({
      variables: {
        favoriteId,
      },
      awaitRefetchQueries: true,
      refetchQueries: [{ query: GetUserFavoritesRefsDocument }],
    });
  };

  const refFavorite = data?.userFavorites.favorites?.find(favorite => favorite?.ref === rbiOrderId);
  const isFavorite = !!refFavorite;

  const [RemoveFavoriteModal, openRemoveFavoriteModal] = useDialogModal({
    onConfirm: () => removeFavoriteOrder(refFavorite?._id ?? ''),
    showCancel: true,
    modalAppearanceEventMessage: 'Delete Favorite Order',
  });

  const loading = createLoading || deleteLoading;

  return shouldDisplayHeart ? (
    <>
      <IconButton
        variant="ghost"
        onPress={onClick}
        testID="favorite-order-icon"
        disabled={loading}
        icon={<FavoriteIcon isFavorite={isFavorite} disabled={loading} loading={loading} />}
      />

      <RemoveFavoriteModal
        buttonLabel={formatMessage({ id: 'remove' })}
        heading={formatMessage({ id: 'removeFavorite' })}
        body={formatMessage({ id: 'removeFavoriteConfirm' })}
        showCloseButton={true}
      />
      {saveModalOpen && (
        <SaveFavoriteOrder
          showDialog={saveModalOpen}
          onSave={name => {
            saveFavoriteOrder(name);
            setSaveModalOpen(false);
          }}
          onDismiss={() => setSaveModalOpen(false)}
        />
      )}
    </>
  ) : null;
};

export default FavoriteOrderButton;
