import React from 'react';

import { IStaticPage } from 'remote/queries/static-page';
import { AnchorContextProvider } from 'utils/static-page/hooks/useAnchorContext';

import theme from '../theme';

import { StaticPageContainer } from './static-page-container';
import { WidgetType } from './widgetEnums';

export const RenderCurrentStaticPage = ({
  currentPage,
  currentPageOverrideCss = '',
}: {
  currentPage: IStaticPage;
  currentPageOverrideCss?: string;
}) => {
  const html = `
  <style type="text/css">
    html, body {
      box-sizing: border-box;
      height: 100%;
      width: 100%;
      margin: 0;
    }

    .static-page {
      color: ${Styles.color.black};
      background-color: ${theme.backgroundColor};
    }

    ${currentPage && currentPage.pageCSS && currentPage.pageCSS.code}
    ${currentPageOverrideCss}
  </style>

  <div class="static-page">
    ${currentPage.pageHtml?.code}
  </div>
  `;

  const hasWidgets = !!currentPage.widgets;
  const hasHtml = !!currentPage.pageHtml?.code;

  const webView = hasHtml
    ? {
        _type: WidgetType.WEB_VIEW,
        renderWebView: () => <div dangerouslySetInnerHTML={{ __html: html }} />,
      }
    : undefined;

  const fixedHeightContent = !hasWidgets && !!webView && webView.renderWebView();
  const currentWidgets = currentPage?.widgets ? [...currentPage.widgets] : [];

  const listData = hasHtml ? [webView, ...currentWidgets] : [...currentWidgets];

  return (
    <AnchorContextProvider>
      <StaticPageContainer
        currentPage={currentPage}
        isScroll={hasWidgets}
        fixedHeightContent={fixedHeightContent || undefined}
        data={listData}
        webViewLoading={hasHtml}
      />
    </AnchorContextProvider>
  );
};
