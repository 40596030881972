import * as React from 'react';
import { FC, useMemo } from 'react';

import { useReversedUIContext } from 'state/reversed-ui';

import MobileHeaderCta from '../app-header/mobile-header/mobile-header-cta';
import ChooseLocation from '../top-service-mode/actions/choose-location';

import {
  ClickableWrapper,
  Container,
  Details,
  DetailsContainer,
  Heading,
  IconContainer,
} from './top-service-mode-app-header.styled.';
import { ITopServiceModeViewProps } from './types';

// Maximum allowed number of characters for the selected location address as per DS
const maxChars = 13;

export const TopServiceModeAppHeaderDefaultView: FC<React.PropsWithChildren<
  ITopServiceModeViewProps
>> = ({ heading, details, icon, onTopServiceClick }) => {
  const { reversedUI } = useReversedUIContext();

  const customDetails = useMemo(
    () =>
      typeof details !== 'string' || details.length <= maxChars
        ? details
        : `${details.substring(0, maxChars)}...`,
    [details]
  );

  return (
    <Container>
      <ClickableWrapper
        dd-action-name="top-service-mode-wrapper"
        testID="top-service-mode-wrapper"
        onPress={onTopServiceClick}
        accessibilityRole="link"
      >
        <IconContainer>{icon}</IconContainer>
        <DetailsContainer>
          <Heading reversedUI={reversedUI}>{heading}</Heading>
          {details ? (
            <Details reversedUI={reversedUI}>{customDetails}</Details>
          ) : (
            <ChooseLocation reversedUI={reversedUI} />
          )}
        </DetailsContainer>
      </ClickableWrapper>
      <MobileHeaderCta />
    </Container>
  );
};
