import { getConfigValue, googleTagManagerScriptId } from '../environment';

export function initGoogleTagManager() {
  // TODO: RN - locale switching -- should pull from specific region
  const gtmContainerId = getConfigValue({ key: 'googleTagManager', defaultValue: {} }).containerId;

  if (!gtmContainerId) {
    return;
  }

  const scriptId = googleTagManagerScriptId();

  (function (w, d, l, i) {
    const existing = d.querySelectorAll(`script#${scriptId}`);

    if (existing.length > 0) {
      return;
    }

    w[l] = w[l] || [];
    w[l].push({
      'gtm.start': new Date().getTime(),
      event: 'gtm.js',
    });

    const f = d.getElementsByTagName('script')[0];
    const j = d.createElement('script');
    const dl = l !== 'dataLayer' ? '&l=' + l : '';

    j.async = true;
    j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
    j.id = scriptId;

    if (f && f.parentNode) {
      f.parentNode.insertBefore(j, f);
    } else {
      d.body.appendChild(j);
    }
  })(window, document, 'dataLayer', gtmContainerId);
}
