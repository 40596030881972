import * as React from 'react';
import { FC } from 'react';

import { RibbonContent, RibbonWrapper, StyledRibbon } from './ribbon.styled';
import { IRibbonProps } from './ribbon.types';

const Ribbon: FC<React.PropsWithChildren<IRibbonProps>> = ({ text }) => (
  <RibbonContent>
    <RibbonWrapper>
      <StyledRibbon>{text}</StyledRibbon>
    </RibbonWrapper>
  </RibbonContent>
);

export default Ribbon;
