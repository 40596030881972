import React from 'react';

import { AccentBadge } from './accent-badge';
import { FilledAccentBadge, StyledIcon } from './badges.styled';
import theme from './theme';
import { IBadgeProps, IPlatformBadgeProps, ITimeLeftBadgeProps } from './types';

export { LoginRequiredBadge } from './login-required-badge';

export const NewOfferBadge: React.FC<React.PropsWithChildren<IBadgeProps>> = ({
  formatMessage,
}) => <AccentBadge testID="new-offer-badge">{formatMessage({ id: 'newOffer' })}</AccentBadge>;

export const PlatformBadge: React.FC<React.PropsWithChildren<IPlatformBadgeProps>> = ({
  platform,
  formatMessage,
}) => {
  const platformOnlyString = formatMessage({ id: 'platformOnly' }, { platform });
  return <AccentBadge testID="platform-badge">{platformOnlyString}</AccentBadge>;
};

export const TimeLeftBadge: React.FC<React.PropsWithChildren<ITimeLeftBadgeProps>> = ({
  days,
  formatMessage,
}) => {
  const displayDaysLeft = days !== undefined && days > 0;

  if (!displayDaysLeft) {
    return null;
  }

  const timeLeftString = formatMessage({ id: 'days' }, { days });
  return (
    <AccentBadge testID="time-left-badge">
      {theme.expiresPrefix} {timeLeftString}
    </AccentBadge>
  );
};

export const LockedPromoCodeOfferBadge: React.FC<React.PropsWithChildren<IBadgeProps>> = ({
  formatMessage,
}) => (
  <FilledAccentBadge
    $fillColor={theme.lockedPromoCodeOfferFillColor}
    testID="locked-promo-code-offer-badge"
    _text={{ color: Styles.color.white }}
  >
    <StyledIcon variant="locked" testID="promo-code-offer-badge-locked-icon" size={3} />
    {formatMessage({ id: 'lockedPromoCodeOffer' })}
  </FilledAccentBadge>
);

export const UnlockedPromoCodeOfferBadge: React.FC<React.PropsWithChildren<IBadgeProps>> = ({
  formatMessage,
}) => (
  <FilledAccentBadge
    $fillColor={Styles.color.grey.five}
    testID="unlocked-promo-code-offer-badge"
    _text={{ color: Styles.color.grey.three }}
  >
    <StyledIcon
      // @ts-expect-error TS(2769) FIXME: No overload matches this call.
      name="unlocked"
      $iconColor={Styles.color.grey.three}
      testID="promo-code-offer-badge-unlocked-icon"
      size={5}
    />
    {formatMessage({ id: 'unlockedPromoCodeOffer' })}
  </FilledAccentBadge>
);
