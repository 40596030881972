import * as React from 'react';

import { Skeleton, VStack } from '@rbilabs/universal-components';

import { QuestCardSkeleton } from '../quest-card/quest-card-skeleton';

export const QuestCardListSkeleton = ({ showHeaderLoader }: { showHeaderLoader: boolean }) => {
  return (
    <VStack space="$8">
      {showHeaderLoader && <Skeleton alignSelf="center" rounded="full" h="$4" w="50%" />}
      <QuestCardSkeleton />
      <QuestCardSkeleton />
      <QuestCardSkeleton />
    </VStack>
  );
};
