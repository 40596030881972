import { Box, Header, Text, VStack } from '@rbilabs/universal-components';

export const Wrapper = VStack.withConfig({
  height: 'full',
  justifyContent: 'center',
  paddingX: '10',
});

export const IconWrapper = Box.withConfig({
  alignItems: 'center',
  justifyContent: 'center',
  height: '32',
});

export const HeadingText = Header.withConfig({
  textAlign: 'center',
  marginTop: '$3',
});

export const BodyText = Text.withConfig({
  fontSize: 'md',
  textAlign: 'center',
  marginBottom: '$4',
});
