import React from 'react';

import { createMaterialTopTabNavigator } from '@react-navigation/material-top-tabs';
import { useIntl } from 'react-intl';

import FavoritesCardList from 'components/favorites-card-list';
import RecentOrderCardList from 'components/recent-order-card-list';
import MainMenu from 'pages/main-menu';
import { CustomEventNames, EventTypes, useCRMEventsContext } from 'state/crm-events';
import { ClickEventComponentNames } from 'state/crm-events/constants';
import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';
import { useServiceModeContext } from 'state/service-mode';
import { routes } from 'utils/routing';
import { isCateringPickup } from 'utils/service-mode';

import { TopTabLabel } from './top-tab-label';

const MainMenuTab = createMaterialTopTabNavigator();

export const MainMenuNavigator: React.VFC = () => {
  const { formatMessage } = useIntl();
  const enableTabbedMenuBarRecents = useFlag(LaunchDarklyFlag.ENABLE_TABBED_MENU_BAR_RECENTS);
  const enableTabbedMenuBarFavorites = useFlag(LaunchDarklyFlag.ENABLE_TABBED_MENU_BAR_FAVORITES);
  const enableCatering = useFlag(LaunchDarklyFlag.ENABLE_CATERING);

  const { logRBIEvent } = useCRMEventsContext();
  const { serviceMode } = useServiceModeContext();

  const logTabClickEvent = (labelKey: string) => {
    logRBIEvent({
      name: CustomEventNames.CLICK_EVENT,
      type: EventTypes.Other,
      attributes: {
        component: ClickEventComponentNames.PRODUCT_SECTION_TABS,
        text: formatMessage({ id: labelKey }),
        componentId: labelKey,
      },
    });
  };

  return (
    <MainMenuTab.Navigator
      screenOptions={{
        lazy: true,
        tabBarStyle: {
          backgroundColor: Styles.color.white,
        },
      }}
      sceneContainerStyle={{
        overflow: 'visible',
      }}
    >
      {enableCatering && isCateringPickup(serviceMode) ? (
        <MainMenuTab.Screen
          name={routes.menu}
          options={{
            tabBarLabel: ({ focused }) => {
              return (
                <TopTabLabel label={formatMessage({ id: 'cateringMenu' })} focused={focused} />
              );
            },
          }}
          component={MainMenu}
          listeners={{
            tabPress: () => logTabClickEvent('cateringMenu'),
          }}
        />
      ) : (
        <>
          <MainMenuTab.Screen
            name={routes.menu}
            options={{
              tabBarLabel: ({ focused }) => {
                return <TopTabLabel label={formatMessage({ id: 'fullMenu' })} focused={focused} />;
              },
            }}
            component={MainMenu}
            listeners={{
              tabPress: () => logTabClickEvent('fullMenu'),
            }}
          />
          {enableTabbedMenuBarRecents && (
            <MainMenuTab.Screen
              name={routes.menuRecent}
              options={{
                tabBarLabel: ({ focused }) => {
                  return <TopTabLabel label={formatMessage({ id: 'recents' })} focused={focused} />;
                },
              }}
              component={RecentOrderCardList}
              listeners={{
                tabPress: () => logTabClickEvent('recents'),
              }}
            />
          )}
          {enableTabbedMenuBarFavorites && (
            <MainMenuTab.Screen
              name={routes.favorites}
              options={{
                tabBarLabel: ({ focused }) => {
                  return (
                    <TopTabLabel label={formatMessage({ id: 'favorite' })} focused={focused} />
                  );
                },
              }}
              component={FavoritesCardList}
              listeners={{
                tabPress: () => logTabClickEvent('favorite'),
              }}
            />
          )}
        </>
      )}
    </MainMenuTab.Navigator>
  );
};
