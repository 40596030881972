import React, { FC } from 'react';

import { useWindowDimensions } from 'react-native';

import ActionButton from 'components/action-button';
import { MarketingTileList } from 'components/features/components/marketing-tile-group/marketing-tile-list';
import { useIsTabletBp } from 'hooks/breakpoints';
import { useAuthContext } from 'state/auth';
import { WEB_APP_CTA_MAX_WIDTH } from 'utils/constants';

import {
  ButtonContainer,
  ButtonWrapper,
  Container,
  DescriptionTextBlock,
  HeadingText,
  ImageContainer,
  LogoContainer,
  SubtitleText,
  Terms,
  TitleText,
} from './empty-state.styled';
import { IEmptyStateProps } from './types';

const EmptyState: FC<React.PropsWithChildren<IEmptyStateProps>> = ({ content }) => {
  const {
    image,
    headerText,
    title,
    subtitle,
    description,
    buttonAction,
    marketingTileList,
    terms,
    logo,
  } = content;
  const mainImage = image?.locale?.app;
  const header = headerText?.locale;
  const titleText = title?.locale;
  const subtitleText = subtitle?.locale;
  const termsText = terms?.locale;
  const descriptionContent = description?.locale;
  const tiles = marketingTileList?.tiles || [];
  const mainLogo = logo?.locale?.app;
  const { height: $windowHeight } = useWindowDimensions();
  const { isAuthenticated } = useAuthContext();
  const isTablet = useIsTabletBp();

  const shouldRenderSignUpButton = Boolean(!isAuthenticated && buttonAction?.actionText);

  const button = (
    <ActionButton
      to={buttonAction?.actionUrl?.locale || ''}
      testID="empty-state-button"
      fullWidth
      maxW={WEB_APP_CTA_MAX_WIDTH}
    >
      {buttonAction?.actionText?.locale}
    </ActionButton>
  );
  return (
    <>
      <Container showsVerticalScrollIndicator={false} hasSignUpButton={shouldRenderSignUpButton}>
        {header && <HeadingText>{header}</HeadingText>}
        {mainImage && (
          <ImageContainer
            image={mainImage}
            alt={image?.locale?.imageDescription || 'Empty Image'}
            objectFitContain
            $windowHeight={$windowHeight}
          />
        )}
        {mainLogo && (
          <LogoContainer
            image={mainLogo}
            alt={logo?.locale?.imageDescription || ''}
            objectFitContain
          />
        )}
        {titleText && <TitleText>{titleText}</TitleText>}
        {subtitleText && <SubtitleText>{subtitleText}</SubtitleText>}
        {descriptionContent && <DescriptionTextBlock content={descriptionContent} />}

        {shouldRenderSignUpButton && isTablet && <ButtonContainer>{button}</ButtonContainer>}
        <MarketingTileList tiles={tiles} />

        {termsText && <Terms content={termsText} />}
      </Container>
      {shouldRenderSignUpButton && !isTablet && <ButtonWrapper>{button}</ButtonWrapper>}
    </>
  );
};

export default EmptyState;
