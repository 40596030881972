import React, { FC } from 'react';

import { useIntl } from 'react-intl';

import ActionButton from 'components/action-button';
import { useNavigation } from 'hooks/navigation/use-navigation';

import { useFeatureOffers } from '../use-feature-offers';

import { EmptyStateBody, EmptyStateContainer, EmptyStateImage, EmptyStateTitle } from './styled';

export const OffersEmptyState: FC = () => {
  const { formatMessage } = useIntl();
  const { navigate } = useNavigation();

  const { featureOffers } = useFeatureOffers();
  const { offersBrowsingPanel = null } = featureOffers || {};
  const emptyStateTitle = offersBrowsingPanel?.emptyStateTitle ?? null;
  const emptyStateBody = offersBrowsingPanel?.emptyStateBody ?? null;
  const emptyStateImage = offersBrowsingPanel?.emptyStateImage ?? null;
  const emptyStateSelectRestaurantCTAText =
    offersBrowsingPanel?.offersSelectRestaurantCta?.ctaButtonText ?? null;
  const emptyStateSelectRestaurantCTALink =
    offersBrowsingPanel?.offersSelectRestaurantCta?.link ?? null;

  return (
    <EmptyStateContainer testID="browsing-panel-empty-state">
      {emptyStateImage && (
        <EmptyStateImage
          image={emptyStateImage}
          alt={formatMessage({ id: 'noNewOffers' })}
          objectFitContain
        />
      )}
      {emptyStateTitle && (
        <EmptyStateTitle testID="browsing-panel-empty-state-title" variant="headerThree">
          {emptyStateTitle}
        </EmptyStateTitle>
      )}
      {emptyStateBody && (
        <EmptyStateBody testID="browsing-panel-empty-state-body" variant="copyOne">
          {emptyStateBody}
        </EmptyStateBody>
      )}
      {emptyStateSelectRestaurantCTAText && emptyStateSelectRestaurantCTALink && (
        <ActionButton onPress={() => navigate(emptyStateSelectRestaurantCTALink)} fullWidth>
          {emptyStateSelectRestaurantCTAText}
        </ActionButton>
      )}
    </EmptyStateContainer>
  );
};
