import { useMemo } from 'react';

import { useFeatureLoyaltyRewardListCategories } from 'hooks/use-feature-loyalty-reward-list-categories';
import { useLoyaltyRewardsList } from 'hooks/use-loyalty-rewards-list';
import { useLoyaltyUser } from 'state/loyalty/hooks/use-loyalty-user';

import { ICurrentAndNextRewardPoints } from './types';

export const useCurrentAndNextRewardPoints = (): ICurrentAndNextRewardPoints => {
  const { engineRewardsMap, sanityRewardsMap } = useLoyaltyRewardsList();
  const { data: rewardCategories } = useFeatureLoyaltyRewardListCategories();
  const { loyaltyUser } = useLoyaltyUser();
  const userPoints = loyaltyUser?.points || 0;

  return useMemo(() => {
    if (!rewardCategories || !engineRewardsMap || !sanityRewardsMap) {
      return { hasData: false };
    }
    let currentRewardPoints: undefined | number;
    let nextRewardPoints: undefined | number;
    let hasTier: undefined | boolean = false;
    rewardCategories.forEach(category => {
      if (!category?.rewards) {
        return;
      }
      category.rewards.forEach(reward => {
        const engineRewardId = reward && sanityRewardsMap[reward._id]?.loyaltyEngineId;
        const engineReward = engineRewardId && engineRewardsMap[engineRewardId];
        if (!engineReward) {
          return;
        }
        const rewardPoints = engineReward.pointCost;
        if (
          userPoints >= rewardPoints &&
          (!currentRewardPoints || rewardPoints > currentRewardPoints)
        ) {
          currentRewardPoints = rewardPoints;
        } else if (userPoints >= rewardPoints && currentRewardPoints) {
          hasTier = true;
        } else if (
          userPoints < rewardPoints &&
          (!nextRewardPoints || rewardPoints < nextRewardPoints)
        ) {
          nextRewardPoints = rewardPoints;
        }
      });
    });
    return {
      hasData: true,
      hasTier,
      nextRewardPoints,
      currentRewardPoints,
    };
  }, [engineRewardsMap, rewardCategories, sanityRewardsMap, userPoints]);
};
