import { useCallback, useContext } from 'react';

import { UNSAFE_NavigationContext } from 'react-router';
import { useSearchParams } from 'react-router-dom';

import { INavigationHook } from './types';

export const useNavigation = (): INavigationHook => {
  // [rbi/shollington] we use UNSAFE_NavigationContext instead of useNavigate()
  // due to issue remix-run/react-router#7634 where React Router v6's useNavigate()
  // hook triggers re-renders every time the location changes due to their need for
  // resolving relative paths. It appears we use absolute paths in our case.
  const { navigator } = useContext(UNSAFE_NavigationContext);
  const [, setSearchParams] = useSearchParams();

  const navigate: INavigationHook['navigate'] = useCallback(
    (to, options) => (options?.replace ? navigator.replace : navigator.push)(to, options?.state),
    [navigator]
  );

  const linkTo: INavigationHook['linkTo'] = navigate;

  const goBack = useCallback(() => navigator.go(-1), [navigator]);

  const setParams = useCallback<INavigationHook['setParams']>(
    params => {
      // Link the setParams method to their equivalent for react-router as we need to clear the params
      // @ts-expect-error TS(2345) FIXME: Argument of type 'object' is not assignable to par... Remove this comment to see the full error message
      return setSearchParams(params);
    },
    [setSearchParams]
  );

  return {
    navigate,
    linkTo,
    goBack,
    setParams,
    setScreenHeaderTitle: () => {},
  };
};
