import * as React from 'react';
import { memo } from 'react';

import { useIntl } from 'react-intl';

import type { ILoyaltyOffer } from 'state/loyalty/offers/types';
import { isOfferDeliveryOnly } from 'state/loyalty/offers/utils';

import { OfferListItemBase } from './loyalty-offer-list-item-base';

export const LoyaltyOffersInCartList = memo(
  ({
    offers,
    onPress,
    onPressRemove,
  }: {
    offers: ILoyaltyOffer[];
    onPress: (offer: ILoyaltyOffer) => void;
    onPressRemove: (offer: ILoyaltyOffer) => void;
  }) => {
    const { formatMessage } = useIntl();

    return (
      <>
        {offers.map((offer, idx) => {
          const { description, image, imageDescription, name: incentiveName, serviceMode } = offer;

          return (
            <OfferListItemBase
              key={offer.loyaltyEngineId || idx}
              incentiveName={incentiveName}
              image={image}
              imageDescription={imageDescription}
              description={description}
              serviceMode={serviceMode}
              onPress={() => onPress(offer)}
              offerApplied
              offerText={formatMessage({ id: 'offerApplied' })}
              handleRemove={() => onPressRemove(offer)}
              removeOfferText={formatMessage({ id: 'remove' })}
              serviceModeStyle={isOfferDeliveryOnly(offer) ? 'default-outline' : 'default-solid'}
            />
          );
        })}
      </>
    );
  }
);
