import { keyBy, uniqBy } from 'lodash-es';
import { IntlFormatters } from 'react-intl';

import { LoyaltyServiceMode } from 'generated/graphql-gateway';
import { IncentiveEvaluationErrorCodes } from 'state/loyalty/hooks/types';
import { ICartEntryType } from 'state/loyalty/in-restaurant-redemption';
import { LoyaltyOffer } from 'state/loyalty/types';
import { incentiveIsOfferBenefit } from 'state/loyalty/utils';
import { replaceKeyRegex } from 'utils/language';

import { OffersServiceMode, ReduceOffers } from './types';

/**
 * Classify the offers that arrived from the CMS between two groups: those available to the current user and those that have already been applied to the cart.
 * Filter the offers that are not available and those that do not match the base offer models.
 */
export const reduceOffers: ReduceOffers = ({
  cmsOffers,
  personalizedOffers = [],
  baseOffers,
  appliedOffers,
  inRestaurantRedemptionCart,
  checkLoyaltyOfferAvailability,
  enableSortOffersForServiceMode,
  setOffersFeedbackMap,
  appliedFilters,
}) => {
  const appliedOffersMap = keyBy(appliedOffers, 'id');
  const baseOffersMap = keyBy(baseOffers, 'id');
  const personalizedOffersMap = keyBy(personalizedOffers, 'loyaltyEngineId');
  const inRestaurantRedemptionMap = keyBy(inRestaurantRedemptionCart, cartEntry =>
    cartEntry.type === ICartEntryType.OFFER ? cartEntry.details.offer._id : cartEntry.referenceId
  );

  let offers: LoyaltyOffer[] = [];
  const offersInCart: LoyaltyOffer[] = [];
  const bottomSortedOffers: LoyaltyOffer[] = [];
  const offerFeedback = {};
  //Evaluating personalizedOffers first
  const mixedOffers = uniqBy([...personalizedOffers, ...cmsOffers], 'loyaltyEngineId');
  mixedOffers?.forEach((offer: LoyaltyOffer) => {
    if (
      !offer.loyaltyEngineId ||
      !offer._id ||
      !checkLoyaltyOfferAvailability(offer) ||
      // hide the offer of the incentive is not an offer benefit
      !incentiveIsOfferBenefit(offer)
    ) {
      return;
    }
    const personalizedOffer = personalizedOffersMap[offer.loyaltyEngineId];
    const engineOffer = baseOffersMap[offer.loyaltyEngineId];
    const metadata = personalizedOffer ? personalizedOffer?.metadata : engineOffer?.metadata;

    const getOfferServiceMode = (
      offerServiceModes: ReadonlyArray<LoyaltyServiceMode | null> | undefined | null
    ) => {
      let serviceMode = undefined;

      if (offerServiceModes?.length) {
        const hasPickupService =
          offerServiceModes.includes(LoyaltyServiceMode.TAKEOUT) ||
          offerServiceModes.includes(LoyaltyServiceMode.CURBSIDE) ||
          offerServiceModes.includes(LoyaltyServiceMode.DRIVE_THRU);
        const hasDelivery = offerServiceModes.includes(LoyaltyServiceMode.DELIVERY);
        const isDeliveryOnly = offerServiceModes.length === 1 && hasDelivery;

        if (isDeliveryOnly) {
          serviceMode = OffersServiceMode.DELIVERY_ONLY;
        } else if (!hasDelivery && hasPickupService) {
          serviceMode = OffersServiceMode.PICKUP_ONLY;
        }
      }
      return serviceMode;
    };

    const offerServiceMode = getOfferServiceMode(engineOffer?.serviceModes);
    const fullOffer = {
      ...offer,
      isStackable: offer.isStackable || engineOffer?.isStackable,
      metadata,
      offerServiceMode,
    };

    const { pickUpOnly, deliveryOnly, inStoreEnabled } = appliedFilters;
    if ((pickUpOnly || deliveryOnly) && !appliedFilters[offerServiceMode || '']) {
      return;
    }

    if (inStoreEnabled && fullOffer?.mobileOrderOnly) {
      return;
    }

    if (appliedOffersMap[offer.loyaltyEngineId] || inRestaurantRedemptionMap[offer._id]) {
      offersInCart.push(fullOffer);
    } else if (personalizedOffer) {
      offers.push(fullOffer);
    } else if (engineOffer) {
      // If offer invalid for current service mode, add to bottom of list
      const offerHasInvalidServiceMode = engineOffer?.errors?.some(
        ({ code }) => code === IncentiveEvaluationErrorCodes.INVALID_SERVICE_MODE
      );
      if (offerHasInvalidServiceMode && enableSortOffersForServiceMode) {
        bottomSortedOffers.push(fullOffer);
      } else {
        offers.push(fullOffer);
      }

      if (engineOffer?.errors?.length) {
        offerFeedback[engineOffer.id] = engineOffer.errors;
      }
    }
  });

  if (bottomSortedOffers.length) {
    offers = offers.concat(bottomSortedOffers);
  }

  setOffersFeedbackMap(offerFeedback);

  return [offers, offersInCart];
};

/**
 * @param loyaltyEngineId incentive Id that came in the url EG: /rewards/offers/{loyaltyEngineId}
 * @param offersInCart array of applied offers
 * @param offers array of available offers (without the applied)
 * @param selectFirstByDefault boolean
 * @returns
 * If the user access through /rewards/offers/{loyaltyEngineId}
 * returns the offer that matches with that ${loyaltyEngineId}. Undefined otherwise
 *
 * If the user access through /rewards/offers
 * returns undefined if ${selectFirstByDefault} is false.
 * returns the first applied incentive if there is any in ${offersInCart}, if not, returns the first offer in ${offers}.
 */
export const getSelectedIncentive = (
  loyaltyEngineId: string | undefined,
  offers: LoyaltyOffer[],
  offersInCart: LoyaltyOffer[],
  selectFirstByDefault: boolean
): LoyaltyOffer | undefined => {
  let selectedIncentive: LoyaltyOffer | undefined;
  if (loyaltyEngineId) {
    selectedIncentive = offersInCart?.find(offer => offer.loyaltyEngineId === loyaltyEngineId);
    return selectedIncentive || offers?.find(offer => offer.loyaltyEngineId === loyaltyEngineId);
  }

  // first offer select by default doesn't apply
  if (selectFirstByDefault) {
    return undefined;
  }

  if (!offersInCart && !offers) {
    return undefined;
  }

  return offersInCart?.length ? offersInCart[0] : offers[0];
};

/**
 *
 * Given a string, will look for a key like {{KEY}} and will replace it with the given value.
 *
 * @param stringValue A string that might contain an specific key to be replaced
 * @param key the key you want to replaced with value
 * @param value the value you want to use to replace the KEY
 * @returns the string with the replaced key
 */
export const interpolateSanityKey = (stringValue: string, key: string, value: any) => {
  return stringValue.replace(replaceKeyRegex(key), value);
};

export const getServiceModeText = (
  // Remove the string `type`
  offerServiceMode: OffersServiceMode | string | undefined,
  formatMessage: IntlFormatters['formatMessage']
) => {
  if (offerServiceMode === OffersServiceMode.DELIVERY_ONLY) {
    return formatMessage({ id: 'deliveryOnly' });
  } else if (offerServiceMode === OffersServiceMode.PICKUP_ONLY) {
    return formatMessage({ id: 'pickUpOnly' });
  }

  return undefined;
};
