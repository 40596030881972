import React from 'react';

import { Box, NbModal, NbModalProps, makeUclComponent } from '@rbilabs/universal-components';
import { noop } from 'lodash-es';
import { Platform, useWindowDimensions } from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';

import { IBaseProps } from '@rbi-ctg/frontend';
import CloseButton from 'components/close-button';
import usePreventBodyScroll from 'hooks/use-prevent-body-scroll';
import { isIOS } from 'utils/environment';

import theme from './theme';
import { IModalProps } from './types';

export enum ModalSize {
  FULLSCREEN = 'FULLSCREEN',
  REGULAR = 'REGULAR',
  SMALL = 'SMALL',
  LANDSCAPE = 'LANDSCAPE',
}

export const modalSizeStyles = {
  borderRadius: {
    [ModalSize.FULLSCREEN]: '0',
    [ModalSize.REGULAR]: Styles.borderRadius,
    [ModalSize.SMALL]: Styles.borderRadius,
    [ModalSize.LANDSCAPE]: Styles.borderRadius,
  },
  height: {
    [ModalSize.FULLSCREEN]: '100%',
    [ModalSize.REGULAR]: 'calc(100% - 10rem)',
    [ModalSize.SMALL]: 'calc(100% - 15rem)',
    [ModalSize.LANDSCAPE]: 'calc(100% - 10rem)',
  },
  width: {
    [ModalSize.FULLSCREEN]: '100%',
    [ModalSize.REGULAR]: 'calc(100% - 10rem)',
    [ModalSize.SMALL]: 'calc(60% - 15rem)',
    [ModalSize.LANDSCAPE]: '400px',
  },
  maxHeight: {
    [ModalSize.FULLSCREEN]: 'auto',
    [ModalSize.REGULAR]: '100%',
    [ModalSize.SMALL]: '100%',
    [ModalSize.LANDSCAPE]: 'auto',
  },
  maxWidth: {
    [ModalSize.FULLSCREEN]: 'auto',
    [ModalSize.REGULAR]: '860px',
    [ModalSize.SMALL]: '480px',
    [ModalSize.LANDSCAPE]: '400px',
  },
  minHeight: {
    [ModalSize.FULLSCREEN]: 'auto',
    [ModalSize.REGULAR]: '860px',
    [ModalSize.SMALL]: '480px',
    [ModalSize.LANDSCAPE]: '860px',
  },
  minWidth: {
    [ModalSize.FULLSCREEN]: 'auto',
    [ModalSize.REGULAR]: '540px',
    [ModalSize.SMALL]: '400px',
    [ModalSize.LANDSCAPE]: '400px',
  },
};

export const SAFE_AREA_TOP_INSET = 5;

// Returning `true` for iOS will set a default inset
const SAFE_AREA_Y_VALUE = Platform.OS === 'web' || !isIOS() ? SAFE_AREA_TOP_INSET : true;

const ModalContainer = NbModal.withConfig<{
  $backgroundColor?: NbModalProps['backgroundColor'];
  $backgroundImage?: NbModalProps['bgImage'];
}>(p => ({
  backgroundColor: p.$backgroundColor,
  bgImage: p.$backgroundImage ? p.$backgroundImage : null,
}));

// TODO: RN - Migrate Modal Content using UCL
// const ModalContainer = styled(DialogOverlay)<IModalProps>`
//   background: rgba(0, 0, 0, 0.7);
//   height: 100%;
//   left: 0;
//   position: fixed;
//   top: 0;
//   width: 100%;
//   /* TODO: refactor z-indexes */
//   z-index: ${Styles.zIndex.max - 200};

//   [data-reach-dialog-content] {
//     background-color: ${p => p.$backgroundColor || theme.modalContentBackground};
//     background-image: ${p => (p.$backgroundImage ? `url(${p.$backgroundImage})` : '')};
//     display: flex;
//     flex-direction: column;
//     height: 100%;
//     left: 0;
//     overflow-y: auto;
//     position: absolute;
//     top: 0;
//     width: 100%;

//     //webkit hack to ensure content renders with -webkit-overflow-scrolling: touch above;
//     > * {
//       -webkit-transform: translateZ(0px);
//     }

//     ${Styles.desktop`
//       border-radius: ${(p: IModalProps) => modalSizeStyles.borderRadius[p.size!]};
//       flex-direction: row;
//       left: 50%;
//       top: 50%;
//       transform: translate(-50%, -50%);
//       height: ${(p: IModalProps) => modalSizeStyles.height[p.size!]};
//       width: ${(p: IModalProps) => modalSizeStyles.width[p.size!]};
//       max-width: ${(p: IModalProps) => modalSizeStyles.maxWidth[p.size!]};
//       max-height: ${(p: IModalProps) => modalSizeStyles.maxHeight[p.size!]};
//       height: ${({ height }: IModalProps) => height};
//     `}
//   }
// `;

const CloseButtonWrapper = Box.withConfig<{ topInset: number }>(p => ({
  position: 'absolute',
  top: !isIOS() ? SAFE_AREA_TOP_INSET : `${p.topInset}px`,
  left: '$5',
  zIndex: 2,
  _web: {
    top: 0,
    borderRadius: '50%',
    safeAreaY: 0,
  },
  width: '48px',
  height: '48px',
  justifyContent: 'center',
  alignItems: 'center',
  marginLeft: '-12px',
  marginTop: '4px',
}));

const Modal = ({
  allowsDismiss = true,
  children,
  onDismiss = noop,
  closeButton: CloseButtonCmp = CloseButton,
  mParticleEventData: inEventData,
  height,
  skipLoggingHeaderAndMessage = false,
  backgroundColor = theme.modalContentBackground,
  backgroundImage,
  header = null,
  footer = null,
  body = null,
  // TODO: RN - check props and size against UCL Modal
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  size = ModalSize.REGULAR,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  ...props
}: IModalProps) => {
  usePreventBodyScroll();

  const { height: wHeight } = useWindowDimensions();
  const insets = useSafeAreaInsets();

  return (
    <ModalContainer
      isOpen
      size="full"
      onClose={onDismiss}
      $backgroundColor={backgroundColor}
      $backgroundImage={backgroundImage}
      // size={size}
      height={height}
    >
      <NbModal.Content
        accessibilityRole="alert"
        accessibilityViewIsModal
        maxHeight={wHeight}
        backgroundColor={backgroundColor}
        safeAreaX={isIOS() || 0}
        safeAreaY={SAFE_AREA_Y_VALUE}
        flex={1}
      >
        {allowsDismiss && (
          <CloseButtonWrapper _web={{ position: 'fixed' }} topInset={insets.top}>
            <CloseButtonCmp
              onClick={onDismiss}
              testID="modal-close-button"
              style={{ elevation: 1 }}
            />
          </CloseButtonWrapper>
        )}
        <NbModal.Header padding={0} zIndex={1} borderBottomWidth={0}>
          {header}
        </NbModal.Header>

        <NbModal.Body padding={0}>{body || children}</NbModal.Body>
        {footer && <NbModal.Footer padding={0}>{footer}</NbModal.Footer>}
      </NbModal.Content>
    </ModalContainer>
  );
};

export const ModalContent = makeUclComponent((props: IBaseProps) => {
  return (
    <Box paddingX="$4" paddingY="$6" {...props}>
      {props.children}
    </Box>
  );
});

export { default as ModalHeading } from 'components/modal/modal-heading';
export default Modal;
