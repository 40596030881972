import * as React from 'react';
import { FC, useCallback } from 'react';

import { Box, useBreakpointValue } from '@rbilabs/universal-components';
import { useIntl } from 'react-intl';
import { Linking, useWindowDimensions } from 'react-native';

import { ISanityImage } from '@rbi-ctg/menu';
import Picture from 'components/picture';
import { Card } from 'components/ucl/card';
import { useNavigation } from 'hooks/navigation/use-navigation';
import {
  ClickEventComponentNames,
  CustomEventNames,
  EventTypes,
  useCRMEventsContext,
} from 'state/crm-events';
import { EventName, emitEvent } from 'utils/event-hub';
import { isExternalLink } from 'utils/is-external-link';

import useTerms from '../terms/terms.hook';

import { MARKETING_CARD_WIDTH_DESKTOP, MARKETING_CARD_WIDTH_DESKTOP_LG } from './constants';
import { IMarketingCardProps } from './types';

/**
 *
 * MarketingCard is the Sanity Data component that wraps the Component-library Card component
 *
 */
const MarketingCard: FC<React.PropsWithChildren<IMarketingCardProps>> = ({
  card,
  onShowTermsModal,
  index,
}) => {
  const { linkTo } = useNavigation();
  const { logRBIEvent } = useCRMEventsContext();
  const dimension = useWindowDimensions();
  const { formatMessage } = useIntl();

  // Colors
  const isCustom = card.hasCustomColors;
  const variant = isCustom ? 'campaign' : 'default';
  const buttonTextColor = card.buttonTextColor?.hex ?? undefined;
  const buttonBgColor = card.buttonBackgroundColor?.hex ?? undefined;
  const bgColor = card.backgroundColor?.hex ?? undefined;
  const textColor = card.textColor?.hex ?? undefined;
  // Call to action
  const callToAction = (card.callToAction?.actionText?.locale ?? '') as string;
  const href = (card.callToAction?.actionUrl?.locale ?? '') as string;
  // Content
  const header = card.header?.locale ?? '';
  const description = card.description?.locale ?? '';
  const sanityImage = card.image?.locale?.app as ISanityImage;
  // If content team doesn't write an image description try to use the CTA as description of the image link to ensure context for accessibility users.
  const imageAlt = (card.image?.locale?.imageDescription ?? callToAction) as string;
  // In React Native if we don’t pass the width or height as a pixel value, the image will not render immediately.
  // There will be a flicker as RN uses the layout engine to determine the size of the image
  const cardWidth = useBreakpointValue({
    base: dimension.width - 32,
    md: MARKETING_CARD_WIDTH_DESKTOP,
    lg: MARKETING_CARD_WIDTH_DESKTOP_LG,
  });
  // Images have an aspect ratio of 2:1
  const height = cardWidth / 2;
  const image = sanityImage && (
    <Picture
      height={height}
      image={sanityImage}
      borderTopRadius={16}
      lockedAspectRatio
      alt={imageAlt}
      onImageLoaded={() => {
        emitEvent(EventName.MARKETING_IMAGE_LOADED);
      }}
    />
  );

  const ONE_INDEX_OFFSET = 1;
  const logDataEvent = useCallback(() => {
    logRBIEvent({
      name: CustomEventNames.CLICK_EVENT,
      type: EventTypes.Other,
      attributes: {
        component: ClickEventComponentNames.MARKETING_TILE,
        text: header,
        position: `Tile ${index + ONE_INDEX_OFFSET}`,
        componentId: card._id,
      },
    });
  }, [card._id, header, index, logRBIEvent]);

  const onPress = () => {
    logDataEvent();
    // Prevent navigating to an empty url
    if (!href) {
      return;
    }
    if (isExternalLink(href)) {
      Linking.openURL(href);
    } else {
      linkTo(href);
    }
  };
  // Terms
  const hasTerms = card.hasTerms;
  // Fallback to "terms and conditions" localized
  const termsAccessibilityLabel = formatMessage(
    { id: 'termsAccessibilityLabel' },
    { cardTitle: header }
  );
  const termsButtonText = (card.termsButton?.actionText?.locale ??
    formatMessage({ id: 'termsConditions' })) as string;
  const termsButtonUrl = (card.termsButton?.actionUrl?.locale ?? '') as string;
  const termsLinkHref = hasTerms ? termsButtonUrl : undefined;
  const termsLinkText = hasTerms ? termsButtonText : undefined;
  const termsText = card.termsText?.localeRaw;

  const onPressTermsLink = useTerms({
    onShowTermsModal,
    termsButtonText,
    termsButtonUrl,
    termsText,
  });

  return (
    <Box shadow="2" width={cardWidth} borderRadius={16} flex={1}>
      <Card
        href={href}
        variant={variant}
        header={header}
        description={description}
        image={image}
        callToAction={callToAction}
        onPress={onPress}
        buttonTextColor={buttonTextColor}
        buttonBgColor={buttonBgColor}
        bgColor={bgColor}
        textColor={textColor}
        // Terms
        termsAccessibilityLabel={termsAccessibilityLabel}
        termsLinkHref={termsLinkHref}
        termsLinkText={termsLinkText}
        onPressTermsLink={() => onPressTermsLink()}
      />
    </Box>
  );
};

export default MarketingCard;
