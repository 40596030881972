import { AllowedEvent } from '@rbilabs/mparticle-client';
import { isString } from 'lodash-es';

import { IAmplitudeProduct } from 'state/amplitude/types';
import ReactAppboy from 'utils/appboy';
import logger from 'utils/logger';

export function logBrazeCustomEvent(eventName: string, attributes?: Record<string, any>) {
  ReactAppboy.logCustomEvent(eventName, attributes);
}

/**
 * Given the mParticle eCommerce arguments, this method translates those to equivalent Braze events
 * and logs an event to Braze.
 * Here we do our best to emulate the same logic used in the mParticl web SDK to maintain parity on the same
 * event naming and attribute naming schemas so our Braze events match correctly.
 *
 * The mParticle web SDK code which implements similar logic we are trying to model is found here:
 * https://github.com/mParticle/mparticle-web-sdk/blob/acd756b041262e033ec6adead16a7135bacd1b0c/src/ecommerce.js#L441
 *
 * @param mParticleProductActionType the type of action
 * @param products list of Products and information being logged
 * @param attributes list of other attributes to log adittionally
 */
export const logBrazeCommerceEvent = (
  customEventName: AllowedEvent['name'],
  products: IAmplitudeProduct[],
  attributes: { Currency?: string } | null | undefined = {}
) => {
  const attributesCopy = { ...attributes };
  const productsCopy = [...products];

  if (customEventName === 'eCommerce - Purchase - Item') {
    return;
  }

  if (attributesCopy?.Currency) {
    attributesCopy['Currency Code'] = attributesCopy.Currency;
  }

  const productList = Array.isArray(productsCopy) ? productsCopy : [productsCopy];
  const sanitizedProductList = getSanitizedProductList(productList);

  if (customEventName === 'eCommerce - Purchase') {
    logBatchedBrazeCommerceEvent(customEventName, sanitizedProductList, attributes);
  }

  sanitizedProductList.forEach(product => {
    logSingleBrazeCommerceEvent(customEventName, product, attributes);
  });
};

const logSingleBrazeCommerceEvent = (
  customEventName: AllowedEvent['name'],
  product: IAmplitudeProduct,
  attributes: { Currency?: string } | null | undefined
) => {
  try {
    const brazeEventName = amplitudeEcommerceEventToBrazeEventName(customEventName, false);
    const brazeAttributes = {
      ...product,
      ...attributes,
    };
    const convertedAttributes = convertAmplitudeAttributesToBrazeAttributes(brazeAttributes);

    ReactAppboy.logCustomEvent(brazeEventName, convertedAttributes);

    if (customEventName === 'eCommerce - Purchase') {
      const currencyCode = attributes?.Currency ?? '';
      ReactAppboy.logPurchase(
        product.id,
        product.price.toString(),
        currencyCode,
        product.quantity,
        {
          ...convertedAttributes,
        }
      );
    }
  } catch (error) {
    logger.warn({ error, message: 'Error logging custom event to Braze' });
  }
};

const logBatchedBrazeCommerceEvent = (
  customEventName: AllowedEvent['name'],
  products: IAmplitudeProduct[],
  attributes: object | null | undefined
) => {
  try {
    const brazeEventName = amplitudeEcommerceEventToBrazeEventName(customEventName, true);
    const brazeAttributes = {
      ...attributes,
    };

    const convertedAttributes = convertAmplitudeAttributesToBrazeAttributes(brazeAttributes);

    convertedAttributes['Product Count'] = products ? products.length : 0;
    ReactAppboy.logCustomEvent(brazeEventName, convertedAttributes);
  } catch (error) {
    logger.warn({ error, message: 'Error logging batched commerce event to Braze' });
  }
};

const getSanitizedProductList = (productList: IAmplitudeProduct[]): IAmplitudeProduct[] => {
  return productList.map(product => {
    const productCopy = { ...product };
    if (productCopy.total_product_amount) {
      productCopy.total_product_amount = sanitizeAmount(productCopy.total_product_amount);
    }
    if (productCopy.price) {
      productCopy.price = sanitizeAmount(productCopy.price);
    }
    if (productCopy.quantity) {
      productCopy.quantity = sanitizeAmount(productCopy.quantity);
    }
    return productCopy;
  });
};

const sanitizeAmount = (amount: string | number): number => {
  if (isString(amount)) {
    return ensureValidNumber(parseFloat(amount));
  }
  return ensureValidNumber(amount);
};

const ensureValidNumber = (value: number): number => {
  if (isNaN(value) || !isFinite(value)) {
    return 0;
  }
  return value;
};

/**
 * Translates the Amplitude commerce event name to the equivalent Braze custom event name for logging
 * https://github.com/mParticle/mparticle-web-sdk/blob/acd756b041262e033ec6adead16a7135bacd1b0c/src/ecommerce.js#L136
 * @param customEventName
 * @param batched boolean for whether or not this event represents a single product or multiple products
 */
export const amplitudeEcommerceEventToBrazeEventName = (
  customEventName: AllowedEvent['name'],
  batched: boolean
) => {
  const brazeEventName = amPlitudeToBrazeMapping.get(customEventName);
  return `eCommerce - ${brazeEventName} - ${batched ? 'Total' : 'Item'}`;
};

// Get expanded names
const amPlitudeToBrazeMapping = new Map<AllowedEvent['name'], string>([
  ['Click', 'click'],
  ['eCommerce - AddToCart', 'add_to_cart'],
  ['eCommerce - Checkout', 'checkout'],
  ['eCommerce - Purchase', 'purchase'],
  ['eCommerce - RemoveFromCart', 'remove_from_cart'],
  ['eCommerce - ViewDetail', 'view_detail'],
]);

const convertAmplitudeAttributesToBrazeAttributes = (brazeAttributes: Record<string, any>) => {
  const {
    products,
    id,
    total_product_amount,
    name,
    price,
    quantity,
    custom_attributes,
    ...rest
  } = brazeAttributes;
  const convertedAttributes = { ...rest };

  if (custom_attributes && typeof custom_attributes === 'object') {
    convertedAttributes.Attributes = {
      ...custom_attributes,
    };
  }

  if (custom_attributes?.couponCode) {
    convertedAttributes['Coupon Code'] = custom_attributes?.couponCode;
  }
  if (name) {
    convertedAttributes.Name = name;
  }
  if (id) {
    convertedAttributes.Id = id;
    convertedAttributes.Sku = id;
  }
  if (price || price == 0) {
    convertedAttributes['Item Price'] = price;
    convertedAttributes.Price = price;
  }

  if (quantity) {
    convertedAttributes.Quantity = quantity;
  }
  convertedAttributes['Total Product Amount'] = total_product_amount || 0;
  return convertedAttributes;
};
