import React, { FC } from 'react';

import { StickyFooter } from 'components/sticky-footer/sticky-footer';
import { useIsTabletBp } from 'hooks/breakpoints';
import { useReactNavigationFlag } from 'hooks/use-react-navigation-flag';

import {
  StyledProductCtaWrapper,
  StyledProductCtaWrapperChild,
  footerWrapperProps,
} from './product-cta.styled';
import { IProductCtaWrapperProps } from './types';

// Wrapper for the CTA buttons depending on the device
export const ProductCtaWrapper: FC<React.PropsWithChildren<IProductCtaWrapperProps>> = ({
  elements,
}) => {
  const enableReactNativeNavigation = useReactNavigationFlag();
  const isTablet = useIsTabletBp();
  const elementsToRender = elements.filter(({ element }) => element);

  if (enableReactNativeNavigation || isTablet) {
    return (
      <StyledProductCtaWrapper {...footerWrapperProps}>
        {elementsToRender.map(({ element }, index) => (
          <StyledProductCtaWrapperChild key={index}>{element}</StyledProductCtaWrapperChild>
        ))}
      </StyledProductCtaWrapper>
    );
  }

  return <StickyFooter boxProps={footerWrapperProps} elements={elementsToRender} />;
};
