import { FormControl, Header, IconButton, Input } from '@rbilabs/universal-components';

export const ContentHeader = Header.withConfig({
  marginTop: '$3',
  marginBottom: '$8',
  marginX: 'auto',
});

export const StyledInput = Input.withConfig({
  marginRight: '$3',
  flex: 1,
  flexBasis: { base: '65%', lg: '100%' },
});

export const StyledFormControl = FormControl.withConfig({
  flexDir: 'row',
  flexWrap: 'wrap',
});

export const CloseButton = IconButton.withConfig({
  position: 'absolute',
  top: '$2',
  left: '$2',
  variant: 'ghost',
  iconName: 'cancel',
  size: 'sm',
});
