import React, { FC } from 'react';

import { OffersListItem } from '@rbilabs/universal-components';
import { useIntl } from 'react-intl';

import Picture from 'components/picture';
import { LoyaltyOffer } from 'state/loyalty/types';

import { IncentiveCardDescription } from '../../loyalty-incentives-components/incentive-card/incentive-card.styled';
import { parseIncentiveData } from '../../loyalty-incentives-components/incentive-card/parse-incentive-data';
import { OffersServiceMode } from '../types';
import { getServiceModeText } from '../utils';

interface IOffersInCartProps {
  offers: Array<LoyaltyOffer>;
  handlePress(incentiveId: string, incentiveName: string, incentiveSanityId: string): void;
  handleRemove: (incentive: LoyaltyOffer) => void;
}

export const OffersInCart: FC<React.PropsWithChildren<IOffersInCartProps>> = ({
  offers,
  handlePress,
  handleRemove,
}) => {
  const { formatMessage } = useIntl();

  // A future ticket will change Remove handler to work at the item level.
  // For now this feature is used only with one offer in cart rule, so taking the first one is valid.
  const [firstOffer] = offers;

  return (
    <>
      {offers.map((offer, idx) => {
        const incentiveData = parseIncentiveData(offer);
        const {
          description,
          image,
          imageDescription,
          name: incentiveName,
          id: incentiveSanityId,
          loyaltyEngineId: incentiveId,
          offerServiceMode,
        } = incentiveData;

        const serviceModeText = getServiceModeText(offerServiceMode, formatMessage);

        return (
          <OffersListItem
            key={offer.loyaltyEngineId || idx}
            image={
              <Picture
                image={image}
                alt={imageDescription}
                height="97px"
                width="97px"
                objectFitContain
              />
            }
            header={incentiveName}
            description={description && <IncentiveCardDescription content={description} />}
            onPress={() => handlePress(incentiveId, incentiveName, incentiveSanityId)}
            offerApplied
            offerText={formatMessage({ id: 'offerApplied' })}
            handleRemove={() => handleRemove(firstOffer)}
            removeOfferText={formatMessage({ id: 'remove' })}
            serviceModeStyle={
              offerServiceMode === OffersServiceMode.DELIVERY_ONLY
                ? 'default-outline'
                : 'default-solid'
            }
            serviceModeText={serviceModeText}
            marginBottom={{ base: '$2', md: '$4' }}
          />
        );
      })}
    </>
  );
};
