import Color from 'color';
import { indexOf, isNumber, isPlainObject, set } from 'lodash-es';
import { AnimatedLottieViewProps } from 'lottie-react-native';

function isCKPath(currentPath: string[]) {
  const lastIndexC = currentPath.lastIndexOf('c');
  const lastIndexK = currentPath.lastIndexOf('k');
  return lastIndexC === lastIndexK - 1;
}

function isColorArray(value: any[]) {
  return Array.isArray(value) && value.length === 4 && value.every(isNumber);
}

function walkPaths(obj: any, parentPath: string[], collection: string[][]) {
  if (!Array.isArray(obj) && !isPlainObject(obj)) {
    return;
  }

  for (const key of Object.keys(obj)) {
    const value = obj[key];
    const currentPath = [...parentPath, key];
    if (isCKPath(currentPath)) {
      if (isColorArray(value)) {
        collection.push(currentPath);
      }
    }

    walkPaths(value, [...parentPath, key], collection);
  }
}

export function colorizeLottie(json: any, colorFilters: AnimatedLottieViewProps['colorFilters']) {
  const nextJson = JSON.parse(JSON.stringify(json));
  if (!colorFilters || !colorFilters.length) {
    return nextJson;
  }
  colorFilters.forEach(colorFilter => {
    const { keypath, color } = colorFilter;
    // Find the layer that matches the keypath
    const layer = nextJson.layers?.find((layer: any) => layer.nm === keypath);
    if (!color || !layer) {
      return;
    }
    const layerIndexString = indexOf(nextJson.layers, layer).toString();
    const pathCollection: string[][] = [];
    // walk all paths in the layer to find color arrays
    walkPaths(layer, [], pathCollection);
    if (!pathCollection.length) {
      return;
    }
    // Convert new color to rgb fractions for lottie color arrays
    const rgbValues = Color(color).object();
    const rFraction = rgbValues.r / 255;
    const gFraction = rgbValues.g / 255;
    const bFraction = rgbValues.b / 255;
    // Update the color arrays with the new color
    pathCollection.forEach(path => {
      const fullPath = ['layers', layerIndexString, ...path];
      set(nextJson, [...fullPath, 0], rFraction);
      set(nextJson, [...fullPath, 1], gFraction);
      set(nextJson, [...fullPath, 2], bFraction);
    });
  });

  return nextJson;
}
