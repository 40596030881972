import { Text } from '@rbilabs/universal-components';

import { theme } from 'styles/configure-theme';

export const Description = Text.withConfig({
  color: theme.token('text-reversed'),
  textAlign: {
    base: 'center',
    md: 'left',
  },
  paddingX: {
    base: '4',
    md: '0',
  },
  maxWidth: {
    md: '96',
  },
});
