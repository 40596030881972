import { SupportedLanguages, SupportedRegions } from '@rbi-ctg/frontend';

export enum REGIONS {
  US = 'US', // USA
  CA = 'CA', // Canada
}

export enum LANGUAGES {
  en = 'en', // english
  fr = 'fr', // french
}

export enum DOMAINS {
  com = '.com',
  ca = '.ca',
}

export enum MONEY_FORMAT {
  en_us = '$US',
  en_ca = '$CA',
  fr_ca = 'CA$',
}

export enum CURRENCY_CODE_FORMAT {
  en_us = 'USD',
  en_ca = 'CAD',
  fr_ca = 'CAD',
}

export enum LOCALES {
  en_ca = 'en-CA',
  en_us = 'en-US',
  fr_ca = 'fr-CA',
}

export type LocaleData = {
  code: LOCALES;
  region: REGIONS;
  language: LANGUAGES;
  moneyFormat?: MONEY_FORMAT;
  currencyCodeFormat?: CURRENCY_CODE_FORMAT;
};

export const CURRENCY_PREFIX = {
  [LOCALES.en_us]: '$',
  [LOCALES.en_ca]: '$',
  [LOCALES.fr_ca]: '$',
};

export type LanguageAndRegionFromLocaleString = [SupportedLanguages, SupportedRegions];
