import { IItem, IItemOption, IModifierSelection } from '@rbi-ctg/menu';

// TODO: RN - remove comboSlotId logic
export const isItemOptionSelection = (
  selection: IModifierSelection,
  action: any // @todo type reducer actions
): boolean =>
  selection.itemId === action.itemId &&
  selection._key === action.option._key &&
  selection.comboSlotId === action.comboSlotId;

export const fifoAdjustment = (
  state: IModifierSelection[],
  action: any // @todo
): IModifierSelection[] => {
  const ioSelections = state
    .filter(sel => isItemOptionSelection(sel, action))
    .filter(sel => !isModifierSelected(sel, action));

  if (!ioSelections.length) {
    return state;
  }

  const selToRemove: IModifierSelection = ioSelections.reduce((firstIn, sel) => {
    if (Math.min(...sel.fifoPositions) < Math.min(...firstIn.fifoPositions)) {
      return sel;
    }
    return firstIn;
  });

  const newState = state.filter(sel => sel !== selToRemove);

  return selToRemove.modifier.quantity > 1
    ? [
        ...newState,
        {
          ...selToRemove,
          fifoPositions: selToRemove.fifoPositions.slice(1, selToRemove.fifoPositions.length),
          modifier: {
            ...selToRemove.modifier,
            quantity: selToRemove.modifier.quantity - 1,
          },
        },
      ]
    : newState;
};

// TODO: RN - remove comboSlotId logic
export const selectionWillExceedMax = (state: IModifierSelection[], action: any): boolean => {
  const ioSelections = state.filter(
    sel => isItemOptionSelection(sel, action) && sel.comboSlotId === action.comboSlotId
  );
  const total = ioSelections.reduce((acc, sel) => {
    return acc + sel.modifier.quantity;
  }, 0);
  return total >= action.option.maxAmount;
};

// TODO: RN - remove comboSlotId logic
export const isModifierSelected = (
  selection: IModifierSelection,
  action: any /* @todo */
): boolean =>
  selection.itemId === action.itemId &&
  selection._key === action.option._key &&
  selection.modifier._key === action.modifier._key &&
  selection.comboSlotId === action.comboSlotId;

// TODO: RN - remove comboSlotId logic
export const removeDefaultsAndInapplicableSelections = (item: IItem, comboSlotId: string) => (
  acc: IModifierSelection[],
  selected: IModifierSelection
): IModifierSelection[] => {
  // only clear selections for the current combo slot
  if (selected.comboSlotId !== comboSlotId) {
    return acc.concat(selected);
  }

  if (selected.modifier.default) {
    return acc;
  }

  const itemOption: IItemOption | undefined = item.options.find(
    (itemOpt: IItemOption) => !!itemOpt.options.find(({ _key }) => selected.modifier._key === _key)
  ) as IItemOption | undefined;

  if (!itemOption) {
    return acc;
  }

  return acc.concat({
    ...selected,
    itemId: item._id,
    _key: itemOption._key,
    name: itemOption.name,
  });
};
