import { HStack, Pressable, Spinner, Text, makeUclComponent } from '@rbilabs/universal-components';

import LoyaltyPointsIcon from 'components/icons/loyalty-points-icon';
import { LinkButton } from 'components/link-button';
import LoyaltyRewardsLogo from 'pages/loyalty/loyalty-rewards-logo';

import { LoyaltyGreetingWidget } from '../loyalty-widgets';

export const pointsTextID = 'points';

export const Wrapper = makeUclComponent(Pressable).withConfig({
  flexDirection: 'row',
  backgroundColor: Styles.color.contrastBackground,
  justifyContent: 'space-between',
  padding: '$3',
  minHeight: '$16',
});

export const PointsWrapper = HStack.withConfig({
  flex: 1,
  alignItems: 'center',
});

export const CTAWrapper = HStack.withConfig({
  justifyContent: 'center',
  alignItems: 'center',
  flex: 1,
  paddingX: '$3',
});

export const PointText = Text.withConfig({
  fontFamily: 'heading',
  fontSize: 'md',
  color: Styles.color.white,
});

export const P = Text.withConfig({
  color: Styles.color.white,
  lineHeight: 'md',
  fontFamily: 'heading',
});

export const Separator = Text.withConfig({
  marginX: '$1',
  color: Styles.color.white,
  lineHeight: 'md',
  fontFamily: 'heading',
});

export const Balance = HStack;
export const PointBalancePointsIcon = LoyaltyPointsIcon.withConfig({
  marginX: '$1',
});

export const JoinNowCTA = LinkButton.withConfig({
  padding: 0,
  alignItems: 'center',
  justifyContent: 'center',
  _text: {
    textDecorationColor: 'transparent',
    fontFamily: 'heading',
    color: Styles.color.white,
  },
});

export const GreetingWrapper = makeUclComponent(LoyaltyGreetingWidget);

export const StyledLoadingAnimation = Spinner.withConfig({
  reversed: true,
  size: 'sm',
  marginRight: '$6',
  marginLeft: '$6',
});

export const JoinNowContainer = HStack.withConfig({
  alignItems: 'center',
  justifyContent: 'flex-end',
  flex: 1,
});

export const StyledLoyaltyRewardsLogo = LoyaltyRewardsLogo.withConfig({
  justifyContent: 'center',
});
