import * as React from 'react';
import { FC } from 'react';

import { Icon } from '@rbilabs/universal-components';
import { useIntl } from 'react-intl';

import { useLoyaltyUser } from 'state/loyalty/hooks/use-loyalty-user';
import { getDayPeriod } from 'utils/dateTime';

import { GreetingLabel, GreetingUserNameText, Wrapper } from './loyalty-greeting-widget.styled';

interface IGreetingWidgetProps {
  className?: string;
  dayPeriodEnabled?: boolean;
}

/**
 *
 * LoyaltyGreetingWidget shows a day based greeting to the loyaltyUser
 *
 */
const LoyaltyGreetingWidget: FC<React.PropsWithChildren<IGreetingWidgetProps>> = ({
  dayPeriodEnabled = true,
}: IGreetingWidgetProps) => {
  const { formatMessage } = useIntl();
  const { loyaltyUser } = useLoyaltyUser();
  const dayPeriod = getDayPeriod(formatMessage);
  const [userName] = loyaltyUser?.name?.split(' ') || [];

  const greetingMessage = dayPeriodEnabled
    ? formatMessage(
        { id: 'goodDayPeriodX' },
        {
          dayPeriod,
          name: <>{userName}</>,
        }
      )
    : formatMessage(
        { id: 'hiX' },
        { name: <GreetingUserNameText>{userName}</GreetingUserNameText> }
      );
  return (
    <Wrapper testID="greeting-widget">
      <GreetingLabel>{greetingMessage}</GreetingLabel>
      <Icon name="custom:Next" color="white" size="xs" aria-hidden marginTop={'$1'} />
    </Wrapper>
  );
};

export default LoyaltyGreetingWidget;
