import { ImageUrlBuilderOptionsWithAliases } from '@sanity/image-url/lib/types/types';
import { PixelRatio, Platform } from 'react-native';

import { ISanityImage } from '@rbi-ctg/menu';
import { IImageFragment } from 'generated/sanity-graphql';
import { BuildImageUrlType } from 'state/ui';
import logger from 'utils/logger';

interface IDevice {
  dpr: number;
  width: number;
}

export interface ISizeMediaQuery {
  bp: string | 'default';
  vw: number;
}

export type ImageFormat = 'jpg' | 'pjpg' | 'png' | 'webp';

export declare type AutoMode = 'format';

export type PictureBoundaryDimensions =
  | {
      width?: string | number | undefined;
      height?: string | number | undefined;
    }
  | {
      height: null;
      width: null;
    };

export type PictureMeasuredDimensions = { width: number; height: number };

/**
 * We should shorten this list to get more cache hits
 * & spend less compute time on permutations.
 */
const supportedDevices: Array<IDevice> = [
  // small image on desktops or slow connections
  { width: 320, dpr: 1 },
  // phones 2x (iPhone 6,7,8)
  { width: 375, dpr: 2 },
];

/**
 * Order matters here for where you want the browser to
 * set priority on format.
 */
export const supportedImageFormats: Array<ImageFormat> = ['webp', 'png', 'jpg'];

/**
 * This is an optimistic guess for what percentages of the viewport
 * an image will take up.
 */
const defaultWidths: Array<number> = [100];

export interface IGenerateSrc {
  buildImageUrl: BuildImageUrlType;
  image: ISanityImage | IImageFragment;
  device: IDevice;
  format?: ImageFormat;
  viewportWidth?: number;
  quality?: number;
  resolutionMultiplier?: number;
  auto?: AutoMode;
}

/**
 * This function creates a url to send to sanity. Try not to pump anything in here that's too
 * dynamic as we want to try to maximize cache hits instead of breaking them.
 *
 * This drastically minimizes the power of sanity's image pipleline, but is intended to simplify image use.
 * Cropping and image formatting should be done on the client with `object-fit` & `object-position` css styles
 * to minimize complexity. This function is purely for generating a url for a downscaled image.
 *
 * @param {ISanityImage} params.image - Sanity image object.
 * @param {IDevice} params.device - object representing device width & dpr
 * @param {number} params.device.width - width of device in css px.
 * @param {number} params.device.dpr - pixel density of a device. Typically ranging between 1-3
 * @param {ImageFormat} params.format -  'jpg' | 'pjpg' | 'png' | 'webp'
 * @param {number} params.[viewportWidth] - optional percent of viewport image will take after styles
 * @param {number} params.[resolutionMultiplier] - optional number a multiplier to be used when requesting the image. default is 1.
 * @param {number} [params.auto] - Should the image format be automatically generated based on the client requesting. Sanity does this on their servers. default is 'format'.
 *
 * @returns {string} - url for sanity with params to optimize.
 */
export const generateSrc = ({
  buildImageUrl,
  image,
  device,
  viewportWidth = 100,
  quality = 40,
  resolutionMultiplier = 1,
  format,
  auto,
}: IGenerateSrc) => {
  const devicePixelWidth = device.width * device.dpr;
  const normalizedViewportWidth = viewportWidth / 100;

  const width = Math.ceil(devicePixelWidth * normalizedViewportWidth * resolutionMultiplier);
  return (
    buildImageUrl(image, {
      fit: 'max',
      quality,
      width,
      format,
      auto,
    }) || ''
  );
};

export interface IGenerateSrcSet {
  buildImageUrl: BuildImageUrlType;
  image: ISanityImage | IImageFragment;
  format?: ImageFormat;
  viewportWidths?: Array<number>;
  quality?: number;
  resolutionMultiplier?: number;
  auto?: AutoMode;
  aspectRatio?: number;
}

export interface IImageSource {
  uri: string;
  width?: number;
  height?: number;
}

/**
 * Given a sanity image, this function generates the srcset
 * for all devices at all formats and given viewport widths
 * for a React Native Image component
 *
 * @param {ISanityImage} params.image - Sanity image to generate array for.
 * @param {IImageFormat} params.format - 'jpg' | 'pjpg' | 'png' | 'webp'
 * @param {Array<number>} [params.viewportWidths] - Widths of the viewport this image will appear at. Doesn't have to be exact, just rough estimate i.e [33, 50, 100]
 * @param {number} [params.quality] - number between 1-100. default is 40.
 * @param {number} [params.resolutionMultiplier] - number a multiplier to be used when requesting the image. default is 1.
 * @param {number} [params.auto] - Should the image format be automatically generated based on the client requesting. Sanity does this on their servers. default is 'format'.
 *
 * @returns An array of objects which contain the uri and width of possible src resolutions.
 */
export const generateSrcSetRn = ({
  buildImageUrl,
  image,
  format,
  viewportWidths = defaultWidths,
  quality = 40,
  resolutionMultiplier = 1,
  auto = 'format',
  aspectRatio = 1,
}: IGenerateSrcSet) => {
  return viewportWidths.reduce<Array<IImageSource>>((srcSet, viewportWidth) => {
    supportedDevices.forEach(device => {
      const pixelWidth = device.width * device.dpr * (viewportWidth / 100);
      const srcSetUrl = generateSrc({
        buildImageUrl,
        image,
        device,
        viewportWidth,
        quality,
        resolutionMultiplier,
        format,
        auto,
      });
      const width = Math.floor(pixelWidth);
      const height = Math.floor(pixelWidth / aspectRatio);
      if (pixelWidth && srcSetUrl) {
        srcSet.push({
          uri: srcSetUrl,
          width,
          height,
        });
      }
    });
    return srcSet;
  }, []);
};

/**
 * Some browsers have a bug with the picture component where they preemptively
 * request the fallback for a picture before selecting the src (currently a bug in safari):
 * https://www.smashingmagazine.com/2013/05/how-to-avoid-duplicate-downloads-in-responsive-images/
 *
 * This allows us to detect if a browser even needs a fallback and only renders the fallback
 * if it's needed so greedy safari doesn't fetch the fallback & the correct srcset.
 */
export const needsLegacyImgSupport = () => {
  if (typeof navigator === 'undefined' || !navigator.userAgent) {
    return false;
  }
  return navigator.userAgent.match(/Trident/); // IE-only
};

/**
 * Image container width ranges and fixed image widths for each range.
 * These are used to generate the src uri for the picture component.
 * These widths are based on devices with a pixel ratio of 1.
 */
const breakpoints = [
  { range: [1, 50], width: 25 },
  { range: [51, 100], width: 75 },
  { range: [101, 150], width: 125 },
  { range: [151, 100], width: 175 },
  { range: [201, 250], width: 225 },
  { range: [251, 300], width: 275 },
  { range: [301, 350], width: 325 },
  { range: [351, 400], width: 375 },
  { range: [401, 450], width: 425 },
  { range: [451, 500], width: 475 },
];

export interface IGenerateSrcUri {
  buildImageUrl: BuildImageUrlType;
  image: ISanityImage | IImageFragment;
  boundaryDimensions: PictureBoundaryDimensions;
  measuredDimensions: PictureMeasuredDimensions;
  screenWidth: number;
  quality?: number;
  resolutionMultiplier?: number;
}

// we can achieve ~90% reduction uploaded file size with webp without noticeable quality reduction, use it if we can!
const format: Partial<ImageUrlBuilderOptionsWithAliases> = (function resolveBestImageFormat() {
  try {
    // android SDK 18+ supports webp  w/ transparency
    // https://developer.android.com/studio/write/convert-webp
    if (Platform.OS === 'android' && Platform.Version >= 18) {
      return { format: 'webp' };
    }

    // ios 14+ supports webp
    // https://developer.apple.com/documentation/uniformtypeidentifiers/uttype/3551599-webp
    if (Platform.OS === 'ios' && +Platform.Version >= 14) {
      return { format: 'webp' };
    }
  } catch (error) {
    logger.error({
      message: 'Error detecting webp support, defaulting to sanity auto format',
      error,
    });
  }

  // per docs automatically select best supported image format - doesn't work on react-native but *DOES* work on web
  // https://www.sanity.io/docs/image-urls#auto-777d41f23d56
  return { auto: 'format' };
})();

/**
 * Given a sanity image, this function generates the CDN src uri based
 * on the device's pixel ratio and the width of the image container.
 *
 * @param {BuildImageUrlType} buildImageUrl - Sanity url builder for CDN images.
 * @param {ISanityImage} image - Sanity image to generate uri for.
 * @param {PictureBoundaryDimensions} boundaryDimensions - Calculated dimensions of Picture boundary.
 * @param {PictureMeasuredDimensions} measuredDimensions - Dimensions from onLayout callback.
 * @param {number} screenWidth - Width dimension of the device screen.
 * @param {number} [quality] - number between 1-100 dictating the generated image quality.
 * @param {number} [resolutionMultiplier] - a multiplier to be used to adjust the image size when requesting the image
 * @default 1
 *
 * @returns A uri string to be used to fetch an image from the CDN.
 */
export function generateSrcUri({
  buildImageUrl,
  image,
  boundaryDimensions,
  measuredDimensions,
  screenWidth,
  quality,
  resolutionMultiplier = 1,
}: IGenerateSrcUri) {
  // boundaryDimensions.width can be a string, number, null or undefined.
  // Make a string version
  const boundaryWidthString = String(boundaryDimensions.width || 0);
  // parseInt to get the number value
  const boundaryWidthInt = parseInt(boundaryWidthString, 10);
  // check for percentage width
  const widthIsPercent = boundaryWidthString.includes('%');
  // If we have a boundary width calculate percentage width if percent value, or use the number value
  // Otherwise fallback to the measured width
  const containerWidth = boundaryDimensions.width
    ? widthIsPercent
      ? (boundaryWidthInt / 100) * screenWidth // TODO: should this be percent of screen width? How would we get parent width?
      : boundaryWidthInt
    : measuredDimensions.width;
  // Find the breakpoint the image container width falls into.
  // If we can't find a matching breakpoint, use a middle of the road breakpoint
  // to try to get an image width that should work for most use cases.
  const defaultBreakpoint = breakpoints[5];
  const breakpoint =
    breakpoints.find(
      breakpoint => containerWidth >= breakpoint.range[0] && containerWidth <= breakpoint.range[1]
    ) || defaultBreakpoint;
  // Get the current device's pixel ratio
  const pixelRatio = PixelRatio.get();
  // Calculate the final width to request from the CDN, without decimals
  const finalWidth = Math.floor(breakpoint.width * pixelRatio * resolutionMultiplier);

  const uri = buildImageUrl(image, {
    fit: 'max',
    quality,
    width: finalWidth,
    ...format,
  });

  return uri;
}
