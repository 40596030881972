/* eslint-disable no-console */
import { runtimeVersion, updateId } from 'expo-updates';

import { Loggable } from '@rbi-ctg/frontend';
import AuthStorage from 'utils/cognito/storage';
import { dataDogLogger } from 'utils/datadog';
import {
  RBIBrand,
  RBIEnv,
  RBIPlatform,
  brand,
  env,
  minDevLogLevel,
  platform,
} from 'utils/environment';
import { EventName, emitEvent } from 'utils/event-hub';
import { StorageKeys } from 'utils/local-storage';

import {
  ErrorCategory,
  ILoggerMiddleware,
  deviceIdMiddleware,
  errorCategoryMiddleware,
  uuidScrubberMiddleware,
} from './middleware';

export enum LogLevel {
  trace = 10,
  debug = 20,
  info = 30,
  warn = 40,
  error = 50,
  fatal = 60,
}

interface ILoggerBaseFields {
  /**
   * The Appflow build id, if applicable
   */
  appflowBuildId?: number | string;
  /**
   * The binary version code, if available, or commit sha1 otherwise
   */
  appVersionCode?: number | string;
  /**
   * The binary version name, if available
   */
  brand: RBIBrand;
  platform: RBIPlatform;
  stage: RBIEnv;
  userId?: string;
}

type LogFunction = <O extends Loggable | Array<Loggable>>(message: O) => void;

export interface ILoggerOptions {
  middlewares?: ILoggerMiddleware[];
  currentEnv?: RBIEnv;
}

export interface ILogger extends ILoggerBaseFields {
  DEBUG: LogLevel.debug;
  ERROR: LogLevel.error;
  FATAL: LogLevel.fatal;
  INFO: LogLevel.info;
  TRACE: LogLevel.trace;
  WARN: LogLevel.warn;
  debug: LogFunction;
  error: LogFunction;
  fatal: LogFunction;
  groupCollapsed(...name: string[]): void;
  groupEnd(): void;
  info: LogFunction;
  trace: LogFunction;
  warn: LogFunction;
}

type Method = keyof typeof LogLevel;

const methods: Method[] = ['debug', 'error', 'fatal', 'info', 'trace', 'warn'];

export const isLoggable = (item: any): item is Loggable => {
  const type = typeof item;
  return type === 'object' || type === 'undefined' || type === 'number' || type === 'string';
};

const prepareMessage = <O extends Loggable>(
  message: O,
  level: LogLevel,
  loggerAttributes: ILoggerBaseFields
) => {
  if (message instanceof Error) {
    return Object.assign({}, { error: message }, loggerAttributes, { level });
  }

  if (typeof message === 'object') {
    return Object.assign({}, message, loggerAttributes, { level });
  }

  return Object.assign({}, { message }, loggerAttributes, {
    level,
  });
};

/**
 * This function is only exported for testing.
 * The default export for this file is a logger
 * instantiated with fields we want to send
 * on _every_ message to logger.
 * We should only use loggers that are
 * a child of the default logger in production.
 */
export function Logger<T extends object, U extends object>(
  fields: T,
  parentFields?: U,
  loggerOptions: ILoggerOptions = {}
): ILogger {
  const { middlewares = [], currentEnv = env() } = loggerOptions;
  // merge child into parent attributes, then clear undefined attributes
  const loggerAttributes = Object.entries(Object.assign({}, parentFields, fields)).reduce(
    (acc, [key, value]) => (value === undefined ? acc : { ...acc, [key]: value }),
    {}
  );

  const logFunction = (method: Method): LogFunction => message => {
    const level = LogLevel[method];

    type middlewareAttributes = { [key: string]: Loggable };

    // NOTE: If `error` is present uuid-scrubber middleware
    // will replace `message` with `error.message` and
    // append the original `message` property
    // as `originalDeveloperMessage` in attributes
    const attributes = middlewares.reduce<middlewareAttributes>(
      (attr, middleware) => middleware(attr),
      prepareMessage(message, level, loggerAttributes as ILoggerBaseFields) as middlewareAttributes
    );

    const { category } = attributes;

    if (category === ErrorCategory.DoNotLog) {
      return;
    }

    emitEvent(EventName[`LOGGER_${method.toUpperCase()}`], attributes);

    /**
     * Sends events to dataDog
     */
    if (level >= LogLevel.info && currentEnv !== RBIEnv.TEST) {
      dataDogLogger({
        message: (attributes.error as Error) || attributes.message,
        context: attributes,
        status: LogLevel[method],
      });
    }

    if ((currentEnv === RBIEnv.DEV || __DEV__) && level >= minDevLogLevel()) {
      /**
       * Note: If you use logger.debug the chrome web
       * console will not show the messages by default.
       * There is a dropdown that says "Default Levels",
       * by clicking it and turning on "Verbose" you will
       * be able to see debug logs.
       */
      const f = (console[method] || console.error).bind(console);

      f(message);
    }
  };

  const loggerPublicInterface = methods.reduce<ILogger>((logger, method) => {
    return { ...logger, [method]: logFunction(method), [method.toUpperCase()]: LogLevel[method] };
  }, {} as ILogger);

  ['group', 'groupCollapsed', 'groupEnd'].forEach(method => {
    loggerPublicInterface[method] = console[method].bind(console);
  });

  return loggerPublicInterface;
}

const baseFields: ILoggerBaseFields = {
  appVersionCode: runtimeVersion || env(),
  appflowBuildId: updateId ?? undefined,
  brand: brand(),
  platform: platform(),
  stage: env(),
  userId: AuthStorage?.getItem(StorageKeys.USER_AUTH_TOKEN) ?? undefined,
};

let loggingContext = {};

export const addLoggingContext = (context: Object) => {
  loggingContext = { ...loggingContext, ...context };
};

const loggingContextMiddleware = (attributes => {
  return { ...attributes, ...loggingContext };
}) as ILoggerMiddleware;

const DEFAULT_MIDDLEWARES = [
  errorCategoryMiddleware,
  uuidScrubberMiddleware,
  deviceIdMiddleware,
  loggingContextMiddleware,
];

/**
 * The preferred used export is a configured logger
 * with appVersion, brand, env and platform attributes.
 * Add additional interesting logging context to all log statements with `addLoggingContext`
 */
export const defaultLogger = Logger(baseFields, {}, { middlewares: DEFAULT_MIDDLEWARES });

export default defaultLogger;
