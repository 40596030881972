import { VStack } from '@rbilabs/universal-components';

export const ProductSelectionsContainer = VStack.withConfig({
  paddingTop: {
    base: 6,
    md: 0,
  },
  paddingBottom: {
    base: 6,
    md: 0,
  },
  _web: {
    display: 'block',
  },
});
