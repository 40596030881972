import React, { FC } from 'react';

import { LockedCard } from './locked-card';
import { BackgroundImageContainer, Container } from './locked-offers-card.styled';
import { ILockedOffersCardViewProps } from './types';

export const LockedOffersCardView: FC<React.PropsWithChildren<ILockedOffersCardViewProps>> = ({
  backgroundImage,
  header,
  subheader,
  expiresText,
  stepsCompleted,
  offerState,
  progressData,
  mode,
  onPress,
}) => {
  const lockedCard = (
    <LockedCard
      header={header}
      subheader={subheader}
      expiresText={expiresText}
      stepsCompleted={stepsCompleted}
      offerState={offerState}
      progressData={progressData}
      mode={mode}
      onPress={onPress}
    />
  );

  return (
    <Container>
      {backgroundImage ? (
        <BackgroundImageContainer image={backgroundImage} alt="image" objectFitContain={false}>
          {lockedCard}
        </BackgroundImageContainer>
      ) : (
        lockedCard
      )}
    </Container>
  );
};
