import React from 'react';

import { Svg, SvgCircle } from '@rbilabs/universal-components';
import { Animated } from 'react-native';

interface ISemiCircleProgress {
  width: number;
  height: number;
  strokeColor: string;
  strokeWidth: number;
  backgroundColor: string;
  diameter: number;
  outerStrokeWidth?: number;
  progressAnimation: Animated.Value;
}

const AnimatedSvgCircle = Animated.createAnimatedComponent(SvgCircle);

const GAP_PERCENTAGE = 0.25;

const SemiCircleProgress = ({
  width = 220,
  height = 220,
  strokeColor = Styles.color.white,
  backgroundColor = 'rgba(34, 34, 34, 0.26)',
  strokeWidth = 8,
  diameter = 200,
  outerStrokeWidth,
  progressAnimation,
}: ISemiCircleProgress) => {
  // radius minus stroke width
  const radius = diameter / 2 - strokeWidth / 2;
  const totalCircumference = Math.ceil(2 * Math.PI * radius);
  // This is the bottom gap
  const gap = Math.floor(totalCircumference * GAP_PERCENTAGE);
  // circumference minus the gap
  const circumference = totalCircumference - gap;

  const centerX = width / 2;
  const centerY = height / 2;
  const viewBox = [0, 0, width, height].join(' ');

  const circleStrokeOffsetAnimation = progressAnimation.interpolate({
    inputRange: [0, 100],
    outputRange: [totalCircumference, gap],
  });

  return (
    <Svg
      width={width}
      height={height}
      viewBox={viewBox}
      style={[
        {
          transform: [
            {
              rotate: '-45deg',
            },
            { rotateY: '180deg' },
            { rotateX: '180deg' },
          ],
        },
      ]}
    >
      <SvgCircle
        cx={centerX}
        cy={centerY}
        r={radius}
        fill="none"
        stroke={backgroundColor}
        strokeWidth={outerStrokeWidth || strokeWidth}
        strokeDasharray={circumference}
        strokeDashoffset={0}
        strokeLinecap="round"
      />
      <AnimatedSvgCircle
        cx={centerX}
        cy={centerY}
        r={radius}
        fill="none"
        stroke={strokeColor}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeDasharray={totalCircumference}
        strokeDashoffset={circleStrokeOffsetAnimation}
      />
    </Svg>
  );
};

export default SemiCircleProgress;
