import React, { useMemo } from 'react';

import { FlatList, Header } from '@rbilabs/universal-components';
import { useWindowDimensions } from 'react-native';

import { Calories } from 'components/price-and-calories';
import { useIsDesktopBp, useIsMobileBp } from 'hooks/breakpoints';
import { useRoute } from 'hooks/navigation/use-route';
import { useMainMenuContext } from 'state/main-menu';
import { isDesktopMediumViewPort, optionToUrl } from 'utils/menu';
import { useIsBrowseMenu } from 'utils/menu/hooks/use-is-browse-menu';

import { MenuTile } from './menu-tile';
import MenuTileGridContainer from './menu-tile-grid-container';
import { getCaloriesAndPrice } from './menu-tile/helpers';
import { useCalories } from './menu-tile/hooks';
import { MainMenuObject } from './menu-tile/types';
import { Separator } from './separator';
import {
  StyledItemPrice,
  StyledLink,
  StyledListItemCalories,
  StyledPriceAndCaloriesListWrapper,
  TileCard,
  TileFooter,
  TileImage,
} from './styled';
import { IMenuTileGridProps } from './types';

const MenuTileGrid = React.memo<IMenuTileGridProps>(({ data = [], gridTitle }) => {
  const { getPricingAndAvailability, isStaticMenu } = useMainMenuContext();

  const filteredMenuData = useMemo(
    () =>
      !isStaticMenu
        ? data.filter(option => getPricingAndAvailability(option._id)?.isAvailable)
        : data,
    [getPricingAndAvailability, isStaticMenu, data]
  );

  const isMobile = useIsMobileBp();
  const isDesktop = useIsDesktopBp();

  const { width } = useWindowDimensions();
  const isDesktopViewPort: boolean = isDesktopMediumViewPort(width);

  const { pathname } = useRoute<{ id?: string }>();
  const { isBrowseMenu } = useIsBrowseMenu(pathname);
  const getCalories = useCalories();

  const numColumns = isMobile ? 1 : isDesktop ? 3 : 2;
  const listKey = isMobile ? 'mobile-list' : isDesktop ? 'desktop-list' : 'tablet-list';

  return (
    <MenuTileGridContainer testID="menu-tile-grid">
      {gridTitle && <Header variant="headerThree">{gridTitle}</Header>}
      <FlatList
        key={listKey}
        data={filteredMenuData}
        flex={1}
        ItemSeparatorComponent={isMobile ? Separator : null}
        numColumns={numColumns}
        showsVerticalScrollIndicator={false}
        keyExtractor={mainMenuObject => mainMenuObject._id}
        renderItem={({ item }) => {
          const linkPath = optionToUrl(item, isBrowseMenu);
          const { calories, price } = getCaloriesAndPrice(
            item as MainMenuObject,
            getCalories,
            undefined
          );
          const shouldRenderPriceAndCalories = item._type !== 'section';

          let itemContent = (
            <MenuTile
              objectFitContain={false}
              showStaticMenu={false}
              item={item as MainMenuObject}
            />
          );

          if (!isMobile) {
            itemContent = (
              <StyledLink linkPath={linkPath}>
                <TileCard alignItems={isDesktopViewPort ? 'center' : 'initial'}>
                  <TileImage
                    isMenuItem={shouldRenderPriceAndCalories}
                    objectFitContain={true}
                    image={item.image}
                    alt={item.imageDescription?.locale ?? 'menu item image'}
                  />
                  <TileFooter isMenuItem={shouldRenderPriceAndCalories}>
                    {item.name?.locale}
                    {shouldRenderPriceAndCalories && (
                      <StyledPriceAndCaloriesListWrapper>
                        <StyledItemPrice>{price}</StyledItemPrice>
                        <Calories>
                          <StyledListItemCalories>{calories}</StyledListItemCalories>
                        </Calories>
                      </StyledPriceAndCaloriesListWrapper>
                    )}
                  </TileFooter>
                </TileCard>
              </StyledLink>
            );
          }

          return itemContent;
        }}
      />
    </MenuTileGridContainer>
  );
});

export default MenuTileGrid;
