import { useUpsellRecommendationsQuery } from 'generated/graphql-gateway';

import { IUseUpsellQueryHook } from './types';

export const useChefUpsell: IUseUpsellQueryHook = ({
  limit,
  products,
  recommendationTypeLogic,
  serviceMode,
  skip,
  storeId,
}) => {
  const { loading, data } = useUpsellRecommendationsQuery({
    variables: {
      limit,
      products,
      serviceMode,
      storeId,
      type: recommendationTypeLogic,
    },
    skip,
  });

  return {
    loading,
    recommendationToken: data?.upsellRecommendations?.recommendationToken,
    recommender: data?.upsellRecommendations?.recommender,
    upsellData: data?.upsellRecommendations?.results,
  };
};
