import React from 'react';

import { Box, Header } from '@rbilabs/universal-components';

import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';

import { IPrice, IPriceAndCalories } from './types';

const Container = Box.withConfig<{ $condensed?: boolean }>(p => ({
  justifyContent: p.$condensed ? 'flex-start' : 'space-between',
  marginY: '0',
  marginX: p.$condensed ? '0' : '4',
}));

const StyledText = Header.withConfig<{ red?: boolean }>(p => ({
  variant: 'headerFour',
  color: p.red ? Styles.color.pricing : Styles.color.grey.one,
  fontWeight: p.red ? '900' : 'normal',
}));

const GreyStyledText = StyledText.withConfig({
  color: Styles.color.grey.two,
});

export const Price: React.FC<React.PropsWithChildren<IPrice>> = props => (
  <StyledText testID="wizard-price" red={props.red}>
    {props.children}
  </StyledText>
);

/**
 * Conditionally hide site-wide calories based on an LD flag. All rendered calories outside of
 * the nutrition explorer should be wrapped with this component
 */
export const Calories: React.FC<React.PropsWithChildren<{
  children: React.ReactNode;
  hideCalories?: boolean;
}>> = ({ children, hideCalories = false }) => {
  const hideUiCalories = useFlag(LaunchDarklyFlag.HIDE_UI_CALORIES) || hideCalories;

  if (hideUiCalories) {
    return null;
  }

  return <>{children}</>;
};

export const PriceAndCalories: React.FC<React.PropsWithChildren<IPriceAndCalories>> = ({
  isOffer,
  currentPrice,
  shouldHideCalories,
  formattedCalories,
  shouldHidePrice,
  condensedContainer = false,
}) => {
  return (
    <Container $condensed={condensedContainer}>
      {!shouldHidePrice && <Price red={isOffer}>{currentPrice}</Price>}
      {formattedCalories ? (
        <Calories hideCalories={shouldHideCalories}>
          <GreyStyledText testID="wizard-calories">{formattedCalories}</GreyStyledText>
        </Calories>
      ) : null}
    </Container>
  );
};

export default PriceAndCalories;
