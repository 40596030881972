import { useCallback } from 'react';

import { ICartEntry, ICombo } from '@rbi-ctg/menu';
import { useRedeemReward } from 'components/cart-item/redeem-reward/use-redeem-reward';
import {
  transformUserSelectionsComboToComboSlotSelections,
  transformUserSelectionsModifierToModifierSelection,
} from 'components/product-detail/utils';
import { useProductPrice } from 'hooks/menu/use-product-price';
import { useNavigation } from 'hooks/navigation/use-navigation';
import { useRoute } from 'hooks/navigation/use-route';
import { mapEntryTypeToOfferType } from 'state/cart/helpers';
import { CustomEventNames, EventTypes, useCRMEventsContext } from 'state/crm-events';
import { useFavoritesContext } from 'state/favorites';
import { actions, selectors, useAppDispatch, useAppSelector } from 'state/global-state';
import { useGetAvailableRewards } from 'state/loyalty/hooks/use-get-available-rewards';
import { LoyaltyAppliedOffer, isLoyaltyOffer, isSurpriseOffer } from 'state/loyalty/types';
import { findRewardInAppliedRewards, isAppliedOfferSwap } from 'state/loyalty/utils';
import { useMenuContext } from 'state/menu';
import { useOffersContext } from 'state/offers';
import { useOrderContext } from 'state/order';
import { useProductWizardContext } from 'state/product-wizard';
import { useEditingCartEntry } from 'state/product-wizard/hooks/use-editing-cart-entry';
import useProductWizardFlow from 'state/product-wizard/hooks/use-product-wizard-flow';
import { getCartEntryBenefit } from 'utils/cart/helper';
import { HapticsImpactStyle, hapticImpact } from 'utils/haptic';
import { routes } from 'utils/routing';
import { IMenuObjectToCartEntry, transformMenuObjectToCartItem } from 'utils/wizard';

export const useProductCart = () => {
  const { selectFavorite, tempFavorite: currentFavorite } = useFavoritesContext();
  const { getAvailableRewardFromCartEntry } = useGetAvailableRewards();
  const {
    priceForItemOptionModifier,
    pricingFunction,
    priceForComboSlotSelection,
    isOffer,
  } = useMenuContext();
  const { logRBIEvent } = useCRMEventsContext();
  const {
    selectedOffer: selectedLegacyOffer,
    setSelectedOfferCartEntry,
    setSelectedOfferPrice,
  } = useOffersContext();
  const { removeFromCart, upsertCart } = useOrderContext();
  const {
    menuObject,
    selectedProduct,
    productQuantity,
    userSelections,
    manualModifications,
  } = useProductWizardContext();

  const dispatch = useAppDispatch();
  const editingCartEntry = useEditingCartEntry();
  const { totalPrice } = useProductPrice();
  const { incentiveNotInMenu } = useRedeemReward();

  const { navigate } = useNavigation();
  const { pathname } = useRoute();
  const { isFromCart, isFromSurprise, rewardBenefitId } = useProductWizardFlow();

  const appliedOffers = useAppSelector(selectors.loyalty.selectAppliedOffers);
  const selectedLoyaltyOffer = useAppSelector(selectors.loyalty.selectSelectedOffer);
  const appliedLoyaltyRewardsArray = useAppSelector(
    selectors.loyalty.selectAppliedLoyaltyRewardsArray
  );
  const selectedOffer = selectedLoyaltyOffer || selectedLegacyOffer;

  const handleRewardRedemption = useCallback(
    (cartEntries: ICartEntry[]) => {
      if (rewardBenefitId) {
        const entryRedemption = getCartEntryBenefit(
          cartEntries,
          getAvailableRewardFromCartEntry,
          rewardBenefitId
        );

        if (entryRedemption) {
          const rewardInfo = getAvailableRewardFromCartEntry(entryRedemption);

          logRBIEvent({
            name: CustomEventNames.REWARD_ADDED_TO_ORDER,
            type: EventTypes.Other,
            attributes: {
              engineId: rewardInfo?.id ?? '',
              sanityId: rewardInfo?.sanityId ?? '',
              name: rewardInfo?.name ?? '',
              redemptionMode: 'Online',
            },
          });

          const { appliedReward, limitPerOrderMet } = findRewardInAppliedRewards(
            appliedLoyaltyRewardsArray || [],
            rewardInfo?.id || '',
            rewardInfo?.limitPerOrder
          );

          if (appliedReward && limitPerOrderMet) {
            removeFromCart({ cartId: appliedReward.cartId });
          }

          dispatch(
            actions.loyalty.applyReward({
              rewardBenefitId,
              cartId: entryRedemption.cartId,
              // If a loyalty incentive is not available as a regular menu entry,
              // the quantity must match rewards applied
              ...(incentiveNotInMenu && { quantity: entryRedemption.quantity }),
            })
          );
        }
      }
    },
    [
      appliedLoyaltyRewardsArray,
      dispatch,
      getAvailableRewardFromCartEntry,
      incentiveNotInMenu,
      logRBIEvent,
      removeFromCart,
      rewardBenefitId,
    ]
  );

  const handleOffers = useCallback(
    ({
      mainEntry,
      upsellEntries,
      modifiersInjected,
      cartEntries,
    }: IMenuObjectToCartEntry & { cartEntries: ICartEntry[] }) => {
      const offerPrice = totalPrice(modifiersInjected);
      if (selectedOffer && isLoyaltyOffer(selectedOffer)) {
        if (!selectedOffer.isStackable) {
          appliedOffers.forEach(offer => {
            if (!offer.isStackable && !isAppliedOfferSwap(offer)) {
              removeFromCart({ cartId: offer.cartId });
            }
          });
        }
        dispatch(
          actions.loyalty.applyOffer({
            id: selectedOffer.loyaltyEngineId,
            type: (selectedOffer as LoyaltyAppliedOffer).type,
            cartId: mainEntry?.cartId,
            isStackable: selectedOffer.isStackable,
            isSurprise: isSurpriseOffer(selectedOffer),
            cmsId: selectedOffer._id,
          })
        );
        dispatch(actions.loyalty.setSelectedOffer(null));
        upsertCart([
          {
            ...mainEntry,
            price: offerPrice,
            offerVendorConfigs: selectedOffer.vendorConfigs,
            ...(mainEntry && { type: mapEntryTypeToOfferType(mainEntry.type) }),
          },
          ...upsellEntries,
        ]);
      } else {
        const offerEntry = mainEntry ? { ...mainEntry, sanityId: selectedOffer?._id ?? '' } : null;
        setSelectedOfferCartEntry(offerEntry, cartEntries);
        setSelectedOfferPrice(offerPrice);
      }
    },
    [
      appliedOffers,
      dispatch,
      removeFromCart,
      selectedOffer,
      setSelectedOfferCartEntry,
      setSelectedOfferPrice,
      totalPrice,
      upsertCart,
    ]
  );

  const addToOrder = useCallback(() => {
    const { mainEntry, upsellEntries, modifiersInjected } = transformMenuObjectToCartItem(
      selectedProduct,
      priceForItemOptionModifier,
      pricingFunction,
      priceForComboSlotSelection,
      {
        quantity: productQuantity,
        modifierSelections: transformUserSelectionsModifierToModifierSelection(userSelections),
        pickerSelections: userSelections.pickerAspects,
        comboSlotSelections: transformUserSelectionsComboToComboSlotSelections(
          userSelections.comboSlot,
          selectedProduct as ICombo
        ),
        pathname,
        // quickConfigs, // ignore for now - only TH uses quickConfigs
        existingCartId: editingCartEntry?.cartId,
      }
    );
    // Don't include the main entry if its an offer that gets set separately in offerCtx
    const cartEntries = isOffer ? upsellEntries : ([mainEntry, ...upsellEntries] as ICartEntry[]);

    if (currentFavorite) {
      return selectFavorite({ cartEntries });
    }

    // Only update the cart if we actually have anything to add. (Offers will most likely not have anything here)
    if (cartEntries.length) {
      const selections = {
        itemModified: manualModifications.modifiers,
        comboSlotSelection: manualModifications.comboSlot,
        pickerAspectSelection: manualModifications.pickerAspects,
      };
      upsertCart(cartEntries, selections);
    }

    handleRewardRedemption(cartEntries);

    if (isOffer && selectedOffer) {
      handleOffers({
        mainEntry,
        upsellEntries,
        modifiersInjected,
        cartEntries,
      });
    }

    hapticImpact({ style: HapticsImpactStyle.Heavy });

    // if editing and user coming from the cart or if is coming from the surprise modal
    if ((editingCartEntry && isFromCart) || isFromSurprise) {
      return navigate(routes.cart, { popCurrent: true });
    }
    return navigate(routes.menu);
  }, [
    selectedProduct,
    priceForItemOptionModifier,
    pricingFunction,
    priceForComboSlotSelection,
    productQuantity,
    userSelections,
    pathname,
    editingCartEntry,
    isOffer,
    menuObject,
    currentFavorite,
    handleRewardRedemption,
    selectedOffer,
    isFromCart,
    isFromSurprise,
    navigate,
    selectFavorite,
    manualModifications.modifiers,
    manualModifications.comboSlot,
    manualModifications.pickerAspects,
    upsertCart,
    handleOffers,
  ]);

  return {
    addToOrder,
  };
};
