import React from 'react';

import Link from 'components/link';
import { ILinkWidgetFragment } from 'generated/sanity-graphql';

import { Divisor } from './styled';

type ILinksProps = {
  links: ILinkWidgetFragment[];
  isHorizontalLink?: boolean;
};

export const Links = ({ links, isHorizontalLink }: ILinksProps) => (
  <>
    {links.map((widget, index) => {
      const linkProps = {
        to: widget?.link?.locale ?? '',
        children: widget?.text?.locale,
      };
      const lastLink = links.length === index + 1;
      return (
        <>
          <Link
            key={widget?._key ?? index}
            {...linkProps}
            _text={{
              variant: 'copyTwo',
              color: 'token.text-reversed',
            }}
            mb={!isHorizontalLink ? '$1' : '$0'}
          />
          {isHorizontalLink && !lastLink ? <Divisor /> : null}
        </>
      );
    })}
  </>
);
