import React, { Suspense } from 'react';

import { Icon } from '@rbilabs/universal-components';

import { Animation } from 'components/lottie-animations/favorite-loading';
import { theme } from 'styles/configure-theme';

import { LoadingContainer, LoadingSizer } from './styled';
import { IFavoriteIcon } from './types';

export const FavoriteIcon: React.VFC<IFavoriteIcon> = ({ isFavorite, disabled, loading }) => {
  const color = !isFavorite || disabled ? theme.token('icon-disabled') : theme.token('icon-active');
  const icon = <Icon variant="favoriteFill" color={color} />;
  const loadingIcon = (
    <Suspense fallback={icon}>
      <LoadingContainer>
        <LoadingSizer>
          <Animation />
        </LoadingSizer>
      </LoadingContainer>
    </Suspense>
  );
  return loading ? loadingIcon : icon;
};
