import { useEffect, useState } from 'react';

import { Asset } from 'expo-asset';
import { ImageRequireSource } from 'react-native';

import { Cognito } from 'utils/cognito';
import NativeAuthStorage from 'utils/cognito/storage';
import { isWeb } from 'utils/environment';
import { initExpoFonts } from 'utils/expo';
import { WebLocalStorage } from 'utils/local-storage/web-local-storage';

import { injectLaunchArguments } from '../inject-launch-args';

/**
 * -- Global App Initialization status --
 *
 * Loading - Blocks the app from rendering
 * Initialized - All data is there and the app can load without crashing
 * Error - Prevents the app from rendering at all - TODO: RN - Add better feedback to guests to resolve the errors (ex. Network errors)
 *
 * */
export enum initStatuses {
  LOADING = 'loading',
  INITIALIZED = 'initialized',
  ERROR = 'error',
}

type IAssets = {
  [assetName: string]: ImageRequireSource;
};

export const useAppBlockerInit = ({
  cachedFonts,
  sanityAssets,
}: {
  cachedFonts: Record<string, string>;
  sanityAssets: IAssets;
}) => {
  const [state, setState] = useState(initStatuses.LOADING);

  useEffect(() => {
    const prepare = async () => {
      try {
        // Startup congito with its configuration as early as possible.
        Cognito.configure();

        const importedSanityAssets = Object.keys(sanityAssets).reduce((acc, region) => {
          const regionAssets = Object.entries(sanityAssets[region]).map(([, assetRequire]) => {
            return Asset.fromModule(assetRequire).downloadAsync();
          });
          return [...acc, ...regionAssets];
        }, [] as Promise<Asset>[]);

        await Promise.all([
          NativeAuthStorage.sync(), // this is called internally by Cognito.configure() but it doesn't give us a promise!
          initExpoFonts(cachedFonts).catch(err => {
            // Website showed bummer page when loading with slow connection due to font loading timing out.
            // Web can render without the fonts so we will rather show the site without the font than show the bummer page.
            // We still throw in native because native will crash trying to access a font that isn't loaded.
            if (!isWeb) {
              throw err;
            }
          }),
          WebLocalStorage.sync(),
          ...importedSanityAssets,
        ]);

        injectLaunchArguments();

        setState(initStatuses.INITIALIZED);
      } catch {
        setState(initStatuses.ERROR);
      }
    };
    prepare();
  }, [cachedFonts, sanityAssets]);

  return state;
};
