import { HomeNavigator } from 'navigation/home-navigator';

import type { RawTab } from '../../types';

export const id: RawTab['id'] = 'home';

export const screen: RawTab['screen'] = {
  Component: HomeNavigator,
};

export const link: RawTab['link'] = {
  en: '/',
  fr: '/',
};

export const text: RawTab['text'] = {
  en: 'Home',
  fr: 'Accueil',
};
