import { ActionButtonVariants } from 'components/action-button';
import { theme } from 'styles/configure-theme';

export default {
  textTransform: Styles.textTransform.headlines,
  delivery: {
    icon: 'delivery',
    height: '7',
    width: '7',
  },
  pickup: {
    icon: 'restaurant',
    height: '7',
    width: '7',
    size: 7,
  },
  catering: {
    delivery: {
      icon: 'delivery',
      height: '7',
      width: '7',
    },
    pickup: {
      icon: 'catering',
      height: '9',
      width: '10',
    },
  },
  iconContainer: {
    color: 'primary',
  },
  bottomServiceWrapper: {
    color: theme.token('text-link-default'),
  },
  underline: {
    color: theme.token('text-link-default'),
  },
  badge: {
    color: Styles.color.red,
  },
  buttonIcon: 'cart',
  defaultButtonVariant: ActionButtonVariants.PRIMARY,
  inProgressVariant: ActionButtonVariants.INVERSE,
} as const;
