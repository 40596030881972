import React, { FC } from 'react';

import { InlineAlert } from '@rbilabs/universal-components';
import { useIntl } from 'react-intl';

import { Helmet } from 'components/helmet';
import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';
import { isWeb } from 'utils/environment';

import { DashboardContainer, WidgetElementWrapper } from './loyalty-dashboard.styled';
import { ILoyaltyDashboardViewProps } from './types';

export const LoyaltyDashboardView: FC<React.PropsWithChildren<ILoyaltyDashboardViewProps>> = ({
  widgets,
}) => {
  const { formatMessage } = useIntl();
  const enableCateringRewardsOnlyAvailableOnWebsite = useFlag(
    LaunchDarklyFlag.ENABLE_CATERING_REWARDS_ONLY_AVAILABLE_ON_WEBSITE
  );

  return (
    <>
      <Helmet title={formatMessage({ id: 'rewards' })} />
      <DashboardContainer
        testID="loyalty-dashboard"
        showsVerticalScrollIndicator={false}
        contentContainerStyle={{ flexGrow: 1 }}
      >
        {enableCateringRewardsOnlyAvailableOnWebsite && !isWeb && (
          <InlineAlert
            status="info"
            message={formatMessage({ id: 'cateringRewardsMessage' })}
            mx="$4"
            mt="$6"
            borderRadius={8}
          />
        )}
        {widgets.map(({ element: widgetElement, id: widgetId }, idx) => (
          <WidgetElementWrapper nativeID={widgetId} key={widgetId || idx}>
            {widgetElement}
          </WidgetElementWrapper>
        ))}
      </DashboardContainer>
    </>
  );
};
