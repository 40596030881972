import React, { useCallback, useState } from 'react';

import { Icon } from '@rbilabs/universal-components';
import { useIntl } from 'react-intl';

import LoyaltyPointsIcon from 'components/icons/loyalty-points-icon';
import OrderCancelledModal from 'components/order-cancelled-modal';
import {
  DeliveryStatus,
  IUserOrderFragment,
  RbiOrderStatus,
  useGetUserOrdersQuery,
} from 'generated/rbi-graphql';
import { useNavigation } from 'hooks/navigation/use-navigation';
import { useRoute } from 'hooks/navigation/use-route';
import { appendObjectToQueryString } from 'hooks/navigation/utils';
import useDialogModal from 'hooks/use-dialog-modal';
import {
  STATUS_MESSAGES,
  STATUS_PERCENTS,
} from 'pages/order-confirmation/delivery/delivery-progress';
import { useAuthContext } from 'state/auth';
import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';
import { useInRestaurantRedemptionContext } from 'state/loyalty/in-restaurant-redemption';
import { ServiceMode, useOrderContext } from 'state/order';
import { useStoreContext } from 'state/store';
import { hiddenAccessibilityPlatformProps } from 'utils/accessibility';
import { routes } from 'utils/routing';

import { LOYALTY_REDEEM_TEXT_KEY } from '../constants';
import theme from '../theme';
import {
  BottomServiceVariants,
  IBottomServiceModeDetail,
  IOrderInProgress,
  IOrderInProgressResult,
} from '../types';
import { deliveryCompleteTimeout, postOrderTimeout } from '../utils';

import { useCartButtonText } from './use-cart-button-text';
import { useRewardQuantity } from './use-reward-quantity';

const DELIVERY_COMPLETED = [
  DeliveryStatus.ORDER_DROPPED_OFF,
  DeliveryStatus.ORDER_ABANDONED,
  DeliveryStatus.ORDER_CANCELLED,
];

const ORDER_PROGRESS_POLLING_DURATION_IN_MS = 10000;
const DELIVERY_COMPLETED_TIMEOUT_IN_MINUTES = 10;
export const PICKUP_UPDATED_TIMEOUT_IN_MINUTES = 10;
export const PRE_CURBSIDE_CONFIRM_ARRIVAL_TIMEOUT_IN_MINUTES = 60;

export const getDeliveryHeading = ({ deliveryStatus }: { deliveryStatus: DeliveryStatus }) => {
  const deliveryProgress = STATUS_PERCENTS[deliveryStatus];
  if (deliveryProgress <= STATUS_PERCENTS[DeliveryStatus.ORDER_CREATED]) {
    return 'orderPlaced';
  }
  if (deliveryProgress < STATUS_PERCENTS[DeliveryStatus.ORDER_PICKED_UP]) {
    return 'deliveryOrderBeingPrepared';
  }
  if (deliveryProgress < STATUS_PERCENTS[DeliveryStatus.ORDER_DROPPED_OFF]) {
    return 'deliveryDriverEnroute';
  }
  return 'deliveryDelivered';
};

export const isPollingForDelivery = (
  recentOrder: IUserOrderFragment | null | undefined,
  isDeliveryOrderCompleted: boolean
) => {
  if (
    recentOrder?.delivery &&
    !isDeliveryOrderCompleted &&
    !DELIVERY_COMPLETED.includes(recentOrder.delivery.status)
  ) {
    return true;
  }
  return false;
};

export const BSM_GET_USER_ORDER_STATUSES = [
  RbiOrderStatus.INSERT_SUCCESSFUL,
  RbiOrderStatus.UPDATE_SUCCESSFUL,
  RbiOrderStatus.REFUND_SUCCESSFUL,
];

// Timeout time of 10 minutes post order placed:
// - ServiceMode.TAKEOUT
// - ServiceMode.DRIVE_THRU
// - ServiceMode.EAT_IN
// - ServiceMode.CURBSIDE
// - ServiceMode.DELIVERY
// Timeout time of 60 minutes until order placed, pre-confirm arrival:
// - ServiceMode.CURBSIDE
export const orderInProgress = ({
  recentOrder,
  fireOrderAhead,
  curbsidePickupOrderId,
  curbsidePickupOrderTimePlaced,
  isDeliveryOrderCompleted,
}: IOrderInProgress): IOrderInProgressResult | null => {
  if (
    recentOrder &&
    [ServiceMode.CATERING_PICKUP, ServiceMode.CATERING_DELIVERY].includes(
      // @ts-expect-error TS(2345) FIXME: Argument of type 'import("/Users/rbish028/src/ctg-... Remove this comment to see the full error message
      recentOrder?.cart.serviceMode
    )
  ) {
    return null;
  }

  const isPickupTimedOut = () => {
    // Add the fire order delay to the total timeout
    const fireOrderInSeconds = recentOrder?.fireOrderIn
      ? recentOrder?.fireOrderIn + fireOrderAhead
      : 0;
    const fireOrderInMinutes = fireOrderInSeconds / 60;
    const timeOutWithDelay = PICKUP_UPDATED_TIMEOUT_IN_MINUTES + fireOrderInMinutes;

    return (
      recentOrder &&
      !recentOrder?.delivery &&
      postOrderTimeout(recentOrder?.updatedAt, timeOutWithDelay)
    );
  };

  const isPreConfirmCurbsideTimedOut = () =>
    curbsidePickupOrderId &&
    postOrderTimeout(
      curbsidePickupOrderTimePlaced,
      PRE_CURBSIDE_CONFIRM_ARRIVAL_TIMEOUT_IN_MINUTES
    );

  // ServiceMode.DELIVERY - ordered
  if (recentOrder?.delivery && !isDeliveryOrderCompleted) {
    const deliveryStatus = recentOrder.delivery.status;
    const headingId = getDeliveryHeading({ deliveryStatus });
    const detailsId = STATUS_MESSAGES[deliveryStatus];
    const buttonContentTextId =
      deliveryStatus === DeliveryStatus.ORDER_DROPPED_OFF ? 'details' : 'track';
    return {
      headingId,
      detailsId,
      buttonContentTextId,
      buttonContentIcon: null,
      icon: (
        <Icon
          variant="location"
          color="white"
          width="6"
          height="6"
          {...hiddenAccessibilityPlatformProps}
        />
      ),
      variant: BottomServiceVariants.IN_PROGRESS,
    };
  }
  // PICKUP - ordered
  if (recentOrder && isPickupTimedOut()) {
    return {
      headingId: 'orderPlaced',
      detailsId: 'yourOrderIsBeingPrepared',
      icon: (
        <Icon
          variant="restaurant"
          color="white"
          width="6"
          height="6"
          {...hiddenAccessibilityPlatformProps}
        />
      ),
      buttonContentTextId: 'details',
      buttonContentIcon: null,
      variant: BottomServiceVariants.IN_PROGRESS,
    };
  }
  // ServiceMode.CURBSIDE - Before order is commited on confirming arrival , selecting "I'm here!"
  if (isPreConfirmCurbsideTimedOut()) {
    return {
      headingId: 'serviceModeDetailsCurbsideHeader',
      detailsId: 'headToRestaurant',
      icon: (
        <Icon
          variant="restaurant"
          color="white"
          width="6"
          height="6"
          {...hiddenAccessibilityPlatformProps}
        />
      ),
      buttonContentTextId: 'confirmArrival',
      buttonContentIcon: null,
      variant: BottomServiceVariants.IN_PROGRESS,
    };
  }

  return null;
};

export const useBottomServiceModeDetails = (): IBottomServiceModeDetail => {
  const {
    serviceMode,
    deliveryAddress,
    isCartEmpty,
    curbsidePickupOrderId,
    curbsidePickupOrderTimePlaced,
  } = useOrderContext();
  const { store } = useStoreContext();
  const rewardQuantity = useRewardQuantity();
  const enableOrdering = useFlag(LaunchDarklyFlag.ENABLE_ORDERING);
  const fireOrderAhead = useFlag(LaunchDarklyFlag.FIRE_ORDER_AHEAD);
  const { formatMessage } = useIntl();
  const { isAuthenticated } = useAuthContext();
  const [hasSeenCancelModal, setHasSeenCancelModal] = useState(false);

  const [CancellationModal, openCancellationModal] = useDialogModal({
    // @ts-expect-error TS(2322) FIXME: Type 'FC<IOrderCancelledModalProps>' is not assign... Remove this comment to see the full error message
    Component: OrderCancelledModal,
    onDismiss: () => setHasSeenCancelModal(true),
  });
  const { navigate, linkTo } = useNavigation();
  const { pathname } = useRoute();

  const { cartButtonText } = useCartButtonText();

  const isOnMenuPage = pathname.startsWith(routes.menu);

  const { data, startPolling, stopPolling, refetch } = useGetUserOrdersQuery({
    variables: {
      limit: 1,
      orderStatuses: BSM_GET_USER_ORDER_STATUSES,
    },
    skip: !isAuthenticated,
  });
  const {
    inRestaurantRedemptionEnabled,
    inRestaurantRedemptionCart,
  } = useInRestaurantRedemptionContext();

  const handleCartClick = useCallback(() => {
    if (store._id) {
      navigate(routes.cart);
    } else {
      navigate(routes.serviceMode);
    }
  }, [navigate, store._id]);
  const handleServiceModeChange = useCallback(() => {
    navigate(routes.serviceMode);
  }, [navigate]);
  const handleNavigateToOrderConfirmation = useCallback(
    ({ rbiOrderId, showToast }: { rbiOrderId: string; showToast?: boolean }) => () => {
      linkTo(appendObjectToQueryString(`${routes.orderConfirmation}/${rbiOrderId}`, { showToast }));
    },
    [linkTo]
  );
  // We don't want to block user from ordering when there is existing
  // order in progress. We will always shows default bottom service mode
  // on menu page
  const recentOrder = data?.userOrders?.orders?.[0];

  // This will change the display of BSM after the order has been placed
  // and delivery is being tracked, a waiting that pickup from curbside.
  // Any refunds will not block users from making a new order
  if (
    !isOnMenuPage &&
    isAuthenticated &&
    recentOrder?.status !== RbiOrderStatus.REFUND_SUCCESSFUL
  ) {
    // Completed delivery order is when status is completed and
    // exceeded 10 minutes after order is dropped off (in order for user to view the order)
    const isDeliveryOrderCompleted =
      !!recentOrder?.delivery &&
      DELIVERY_COMPLETED.includes(recentOrder.delivery.status) &&
      (hasSeenCancelModal ||
        deliveryCompleteTimeout(recentOrder?.updatedAt, DELIVERY_COMPLETED_TIMEOUT_IN_MINUTES));

    if (isPollingForDelivery(recentOrder, isDeliveryOrderCompleted)) {
      if (hasSeenCancelModal) {
        setHasSeenCancelModal(false);
      }
      startPolling(ORDER_PROGRESS_POLLING_DURATION_IN_MS);
    }

    const inProgressResult = orderInProgress({
      recentOrder,
      fireOrderAhead,
      curbsidePickupOrderId,
      curbsidePickupOrderTimePlaced,
      isDeliveryOrderCompleted,
    });
    if (inProgressResult) {
      // TODO: review this curbside logic. It should be derived by orderInProgress instead
      const isRecentOrderCurbsidePickup = recentOrder?.rbiOrderId === curbsidePickupOrderId;

      // TODO: It does not make sense logically, because it's always using recentOrder?.rbiOrderId
      const rbiOrderId: string = isRecentOrderCurbsidePickup
        ? curbsidePickupOrderId
        : recentOrder?.rbiOrderId;

      const onButtonClick =
        recentOrder?.delivery?.status === DeliveryStatus.ORDER_CANCELLED
          ? openCancellationModal
          : handleNavigateToOrderConfirmation({
              rbiOrderId,
              showToast: isRecentOrderCurbsidePickup,
            });

      return {
        ...inProgressResult,
        heading: formatMessage({ id: inProgressResult.headingId }),
        details: formatMessage({ id: inProgressResult.detailsId }),
        buttonContentText: formatMessage({ id: inProgressResult.buttonContentTextId }),
        buttonContentIcon: undefined,
        onButtonClick,
        onBottomServiceClick: onButtonClick,
        CancellationModal,
        refetchUserOrder: refetch,
      };
    }
  }

  const resultBase: IBottomServiceModeDetail = {
    heading: formatMessage({ id: 'forItemAvailability' }),
    details: formatMessage({ id: 'chooseALocation' }),
    icon: (
      <Icon
        variant="location"
        color={theme.iconContainer.color}
        width="6"
        height="6"
        {...hiddenAccessibilityPlatformProps}
      />
    ),
    buttonContentText: cartButtonText,
    buttonContentTextLabel: `${cartButtonText} ${formatMessage({ id: 'cartTotal' })}`,
    buttonContentIcon: <Icon variant="cart" {...hiddenAccessibilityPlatformProps} />,
    buttonBadgeContentText: rewardQuantity,
    onButtonClick: handleCartClick,
    onBottomServiceClick: handleServiceModeChange,
    buttonDisabled: isCartEmpty,
    variant: BottomServiceVariants.DEFAULT,
    CancellationModal,
    refetchUserOrder: refetch,
  };

  stopPolling();
  if (store._id) {
    if ((!store.hasMobileOrdering || !enableOrdering) && store.physicalAddress?.address1) {
      return {
        ...resultBase,
        heading: formatMessage({ id: 'yourSelectedStore' }),
        details: store.physicalAddress.address1,
        icon: (
          <Icon
            variant="restaurant"
            color={theme.iconContainer.color}
            width="6"
            height="6"
            {...hiddenAccessibilityPlatformProps}
          />
        ),
        buttonContentText: '',
        buttonContentIcon: undefined,
      };
    }
    switch (serviceMode) {
      case ServiceMode.DELIVERY:
        if (deliveryAddress) {
          return {
            ...resultBase,
            heading: formatMessage({ id: 'delivery' }),
            details: deliveryAddress.addressLine1,
            icon: (
              <Icon
                variant={theme.delivery.icon}
                color={theme.iconContainer.color}
                width={theme.delivery.width}
                height={theme.delivery.height}
                {...hiddenAccessibilityPlatformProps}
              />
            ),
          };
        }
        break;
      case ServiceMode.CATERING_DELIVERY:
        if (deliveryAddress) {
          return {
            ...resultBase,
            heading: formatMessage({ id: 'cateringDashDelivery' }),
            details: deliveryAddress.addressLine1,
            icon: (
              <Icon
                variant={theme.catering.delivery.icon}
                color={theme.iconContainer.color}
                width={theme.catering.delivery.width}
                height={theme.catering.delivery.height}
                {...hiddenAccessibilityPlatformProps}
              />
            ),
          };
        }
        break;
      case ServiceMode.CATERING_PICKUP:
        if (store.physicalAddress?.address1) {
          return {
            ...resultBase,
            heading: formatMessage({ id: 'cateringDashPickUp' }),
            details: store.physicalAddress.address1,
            icon: (
              <Icon
                variant={theme.catering.pickup.icon}
                color={theme.iconContainer.color}
                width={theme.catering.pickup.width}
                height={theme.catering.pickup.height}
                {...hiddenAccessibilityPlatformProps}
              />
            ),
          };
        }
        break;
      case ServiceMode.CURBSIDE:
        if (store.physicalAddress?.address1) {
          return {
            ...resultBase,
            heading: formatMessage({ id: 'curbside' }),
            details: store.physicalAddress.address1,
            icon: (
              <Icon
                variant={theme.pickup.icon}
                color={theme.iconContainer.color}
                width={theme.catering.pickup.width}
                height={theme.catering.pickup.height}
                {...hiddenAccessibilityPlatformProps}
              />
            ),
          };
        }
        break;
      case ServiceMode.TAKEOUT:
      case ServiceMode.DRIVE_THRU:
      case ServiceMode.EAT_IN:
        if (store.physicalAddress?.address1) {
          let result = {
            ...resultBase,
            heading: formatMessage({ id: 'pickUp' }),
            details: store.physicalAddress.address1,
            icon: (
              <Icon
                variant={theme.pickup.icon}
                color={theme.iconContainer.color}
                width={theme.pickup.width}
                height={theme.pickup.height}
                size={theme.pickup.size}
                {...hiddenAccessibilityPlatformProps}
              />
            ),
          };

          if (inRestaurantRedemptionEnabled && inRestaurantRedemptionCart?.length) {
            // Badge should display quantity of items added to inRestaurantCart
            const badgeContent = inRestaurantRedemptionCart.reduce(
              (acc, entry) => (acc += entry.quantity),
              0
            );

            result = {
              ...result,
              heading: formatMessage({ id: LOYALTY_REDEEM_TEXT_KEY }),
              details: store.physicalAddress.address1,
              icon: <LoyaltyPointsIcon />,
              buttonBadgeContentText: `${badgeContent}`,
              buttonContentText: formatMessage({ id: 'redeem' }),
              buttonContentIcon: undefined,
              buttonDisabled: false,
              onButtonClick: () => navigate(formatMessage({ id: 'routes.redemptionInRestaurant' })),
              variant: BottomServiceVariants.IN_RESTAURANT_LOYALTY,
            };
          }

          return result;
        }
        break;
      default:
    }
  }
  return {
    ...resultBase,
    buttonContentText: '0',
    buttonContentTextLabel: `0 ${formatMessage({ id: 'cartTotal' })}`,
    buttonContentIcon: <Icon variant="cart" aria-hidden />,
    buttonDisabled: undefined,
    variant: BottomServiceVariants.NO_STORE,
  };
};
