import * as React from 'react';

import Link from 'components/link';
import { ISerializers, SanityBlockContentLinkProps } from 'components/sanity-block-renderer';

const StyledLink = Link.withConfig({
  color: Styles.color.black,
  isInline: true,
  isUnderlined: true,
});

export const linkOptions = ({ children = [], mark = {} }: SanityBlockContentLinkProps) => {
  const [linkText] = children;
  const { href } = mark;

  if (!href || !linkText) {
    return null;
  }

  if (href.startsWith('/')) {
    return <StyledLink to={href}>{linkText}</StyledLink>;
  }

  return (
    <StyledLink to={href} isExternal>
      {linkText}
    </StyledLink>
  );
};

const linkSerializers: ISerializers = {
  marks: {
    link: linkOptions,
  },
};

export default linkSerializers;
