import React, { FC } from 'react';

import { useIntl } from 'react-intl';

import ActionButton, { ActionButtonVariants } from 'components/action-button';

import { ButtonsContainer, CloseButton, Container, CookieNoticeLink, InfoText } from './styles';
import { ICookieBannerProps } from './types';

const CookieBanner: FC<React.PropsWithChildren<ICookieBannerProps>> = ({
  infoText,
  buttonText,
  link,
  handleAccept,
  handleClose,
}) => {
  const { formatMessage } = useIntl();
  return (
    <Container>
      <InfoText>
        {infoText} <CookieNoticeLink href={link.href}>{link.text}</CookieNoticeLink>
      </InfoText>
      <ButtonsContainer>
        <CloseButton testID="close-cookies-button" onPress={handleClose}>
          {formatMessage({ id: 'close' })}
        </CloseButton>
        <ActionButton
          testID="accept-all-cookies-button"
          variant={ActionButtonVariants.INVERSE}
          color="statusValidated"
          onPress={handleAccept}
          fullWidth
        >
          {buttonText}
        </ActionButton>
      </ButtonsContainer>
    </Container>
  );
};

export { CookieBanner };
