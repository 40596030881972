export enum FeatureHomePageComponentTypes {
  Alert = 'Alert',
  Hero = 'Hero',
  LockedOffers = 'LockedOffers',
  MainHero = 'MainHero',
  MarketingTileGroup = 'MarketingTileGroup',
  MarketingCardGroup = 'MarketingCardGroup',
  OffersSection = 'OffersSection',
  RecentItems = 'RecentItems',
  RewardsSection = 'RewardsSection',
  QuestsSection = 'QuestsSection',
}

export type FeatureHomePageComponents = {
  [key in FeatureHomePageComponentTypes]?: any;
};

export interface IRawFeatureHomePageComponent {
  _id: string;
  __typename: FeatureHomePageComponentTypes;
}
