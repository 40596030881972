import React, { ReactNode } from 'react';

import { Icon } from '@rbilabs/universal-components';
import { useIntl } from 'react-intl';

import ActionButton, { ActionButtonSizes, ActionButtonVariants } from 'components/action-button';
import { useIsTopServiceModeEnabled } from 'experiments/service-mode/use-is-top-service-mode-enabled';
import type { LocalizedTab } from 'navigation/tab-navigator/tabs/types';
import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';

import { CustomBottomTabBar } from './custom-bottom-tab-bar';
import { LandingPage } from './landing-page';
import { TabNavigatorHeader } from './tab-navigator-header';
import { useExperimentVariant, useShouldShowExperimentAtPath } from './utils';

/*
 * What do we do if this test wins?
 *
 * If we move forward with this, we should do the following:
 *
 * - Localize hardcoded copy
 * - Move landing page images to sanity and update image usage to use those images.
 * - Replace the existing home page with this new landing page
 * - Use tab-navigator-header for landing page component via update in tab navigator
 * - Use custom-bottom-tab-bar for landing page component via update in tab navigator
 */

type ChildrenOnlyProps = {
  children: ReactNode;
};

export function SimplifiedLandingPage__Experiment(props: ChildrenOnlyProps) {
  const experimentVariant = useExperimentVariant();

  if (experimentVariant !== 'control') {
    return <LandingPage />;
  }

  return <>{props.children}</>;
}

export function TabNavigatorHeader_SimplifiedLandingPage__Experiment(props: ChildrenOnlyProps) {
  const isExperimentTreatmentEnabled =
    useExperimentVariant({ skipExposureLogging: true }) !== 'control';
  const shouldShowExperimentAtPath = useShouldShowExperimentAtPath();

  if (isExperimentTreatmentEnabled && shouldShowExperimentAtPath) {
    return <TabNavigatorHeader />;
  }

  return <>{props.children}</>;
}

export function CustomBottomTabBar_SimplifiedLandingPage__Experiment(props: ChildrenOnlyProps) {
  const isExperimentTreatmentEnabled =
    useExperimentVariant({ skipExposureLogging: true }) !== 'control';
  const shouldShowExperimentAtPath = useShouldShowExperimentAtPath();

  if (isExperimentTreatmentEnabled && shouldShowExperimentAtPath) {
    return <CustomBottomTabBar />;
  }

  return <>{props.children}</>;
}

export function useNavigationTabs_SimplifiedLandingPage__Experiment(
  tabList: Array<LocalizedTab>
): Array<LocalizedTab> {
  const experimentTreatmentEnabled =
    useExperimentVariant({ skipExposureLogging: true }) !== 'control';

  if (experimentTreatmentEnabled) {
    return tabList.filter(({ id }) => id !== 'redeem');
  }

  return tabList;
}

export function DisableAddToMobileOrder_SimplifiedLandingPage__Experiment(
  props: ChildrenOnlyProps
) {
  const isExperimentTreatmentEnabled =
    useExperimentVariant({ skipExposureLogging: true }) !== 'control';

  if (isExperimentTreatmentEnabled) {
    return null;
  }

  return <>{props.children}</>;
}

export function DisabledLoyaltyPointsButton_SimplifiedLandingPage__Experiment(
  props: ChildrenOnlyProps
) {
  const enableInRestaurantLoyalty = useFlag(LaunchDarklyFlag.ENABLE_IN_RESTAURANT_LOYALTY);
  const { formatMessage } = useIntl();
  const isExperimentTreatmentEnabled =
    useExperimentVariant({ skipExposureLogging: true }) !== 'control';
  if (isExperimentTreatmentEnabled) {
    return enableInRestaurantLoyalty ? (
      <ActionButton
        leftIcon={<Icon variant="qrScan" size="4" />}
        variant={ActionButtonVariants.OUTLINE}
        size={ActionButtonSizes.SMALL}
        to={formatMessage({ id: 'routes.redemptionInRestaurant' })}
      >
        Scan
      </ActionButton>
    ) : null;
  }

  return <>{props.children}</>;
}

export const useIsTopServiceModeEnabled_SimplifiedLandingPage__Experiment = (
  isTopServiceModeEnabled: ReturnType<typeof useIsTopServiceModeEnabled>
) => {
  const isExperimentTreatmentEnabled =
    useExperimentVariant({ skipExposureLogging: true }) !== 'control';
  const shouldShowExperimentAtPath = useShouldShowExperimentAtPath();

  if (isExperimentTreatmentEnabled && shouldShowExperimentAtPath) {
    return false;
  }

  return isTopServiceModeEnabled;
};
