import { useRef } from 'react';

import { useIsTopServiceModeEnabled } from 'experiments/service-mode/use-is-top-service-mode-enabled';
import { useIsTabletBp } from 'hooks/breakpoints';
import { useRoute } from 'hooks/navigation/use-route';
import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';
import {
  isSectionRoute,
  routes,
  routesForBottomServiceModeDisplay,
  routesForTopServiceModeDisplay,
} from 'utils/routing';

import { HIDE_BOTTOM_SERVICE_MODE_ROUTES } from './constants';

export const useRoutesDisplayBottomService = (): boolean => {
  const enableFixedAddToOrderAndQuantityButton = useFlag(
    LaunchDarklyFlag.ENABLE_FIXED_ADD_TO_ORDER_AND_QUANTITY_BUTTON
  );
  const enableTopServiceMode = useIsTopServiceModeEnabled();

  const routesForServiceMode = enableTopServiceMode
    ? routesForTopServiceModeDisplay
    : routesForBottomServiceModeDisplay;

  const resolutionMap = useRef(new Map());

  const isTablet = useIsTabletBp();
  const { pathname } = useRoute();

  const routeKey = `${pathname}-${isTablet}-${enableFixedAddToOrderAndQuantityButton}`;

  if (!resolutionMap.current.has(routeKey)) {
    const shouldShowFromRoutesForServiceMode = routesForServiceMode.some(({ route, exact }) => {
      if (enableFixedAddToOrderAndQuantityButton && !isTablet && route === routes.menu) {
        // when not on desktop, only show BSM on base menu or section routes, not item / combo / picker
        return pathname === routes.menu || isSectionRoute(pathname);
      }
      if (exact) {
        return pathname === route;
      }
      return pathname.startsWith(route);
    });
    const shouldHideBottomServiceMode = HIDE_BOTTOM_SERVICE_MODE_ROUTES.some(route => {
      return pathname.startsWith(route);
    });
    const shouldShow = shouldShowFromRoutesForServiceMode && !shouldHideBottomServiceMode;
    resolutionMap.current.set(routeKey, shouldShow);
  }

  return resolutionMap.current.get(routeKey);
};
