import React, { VFC, useCallback, useMemo } from 'react';

import { Box } from '@rbilabs/universal-components';

import { ClickableTextWithPencil, Label } from 'components/clickable-text-with-pencil';
import { RadioCheckList } from 'components/radio-check-list';
import { BottomDrawer, useBottomDrawerUIState } from 'components/ucl/bottom-drawer';
import { MenuObjectTypes } from 'enums/menu';
import { useCRMEventsContext } from 'state/crm-events';
import { useMenuContext } from 'state/menu';
import { useProductWizardContext } from 'state/product-wizard';
import { useUIContext } from 'state/ui';
import noop from 'utils/noop';

import { DESKTOP_PRODUCT_CUSTOMIZATION_WIDTH } from '../constants';

import { ModifierListItem } from './modifier-list-item';
import { IModifierTypeUIProps } from './types';
import {
  getModifierNonNumericOptionLabel,
  getModifierOptionMultiplier,
  getSingleSelectedOption,
  logProductModifierCustomization,
  transformSelectedOptionToSelections,
} from './utils';

export const ModifierNonNumeric: VFC<IModifierTypeUIProps> = ({
  modifier,
  selectedItem,
  selections,
  onSelectionsChange,
}) => {
  const { formatCurrencyForLocale } = useUIContext();
  const { priceForItemOptionModifier } = useMenuContext();
  const { selectedProduct } = useProductWizardContext();
  const selectedOption = useMemo(() => getSingleSelectedOption(selections), [selections]);
  const allOptionsHavePrefix = useMemo(
    () => modifier.options.every(opt => !!opt.modifierMultiplier?.prefix),
    [modifier.options]
  );

  const { dialogState } = useBottomDrawerUIState();

  const { logRBIEvent } = useCRMEventsContext();
  const handleSelect = useCallback(
    (val: string) => {
      const newSelectedOption = modifier.options.find(opt => opt._key === val) || selectedOption;
      dialogState.hide();
      onSelectionsChange(transformSelectedOptionToSelections(newSelectedOption));
      logProductModifierCustomization(
        newSelectedOption.name?.locale ?? '',
        modifier.name.locale,
        val,
        logRBIEvent
      );
    },
    [dialogState, logRBIEvent, modifier.options, onSelectionsChange, selectedOption]
  );

  const selectedProductItem = useMemo(() => {
    if (selectedProduct._type === MenuObjectTypes.COMBO) {
      if (selectedProduct.mainItem && !selectedProduct.mainItem.isDummyItem) {
        return selectedProduct.mainItem;
      } else if (selectedItem) {
        return selectedItem;
      }
    }
    return selectedProduct;
  }, [selectedItem, selectedProduct]);

  const radioListOptions = useMemo(() => {
    return modifier.options.map(opt => {
      const label = getModifierNonNumericOptionLabel(opt, allOptionsHavePrefix);
      const upchargePrice = priceForItemOptionModifier({
        item: selectedProductItem,
        itemOption: modifier,
        modifier: opt,
      });
      return {
        label: label || '',
        labelSuffix: upchargePrice > 0 ? `+${formatCurrencyForLocale(upchargePrice)}` : '',
        value: opt._key,
      };
    });
  }, [
    allOptionsHavePrefix,
    formatCurrencyForLocale,
    modifier,
    priceForItemOptionModifier,
    selectedProductItem,
  ]);

  const pencilIconLabel = allOptionsHavePrefix
    ? selectedOption.modifierMultiplier?.prefix?.locale
    : getModifierOptionMultiplier(selectedOption).toString();

  const isEditEnabled = modifier.options.length > 1;

  return (
    <>
      <ModifierListItem
        isListItemClickable={isEditEnabled}
        content={modifier}
        selectedOption={selectedOption}
        onListItemClick={isEditEnabled ? dialogState.show : noop}
        controls={
          isEditEnabled ? (
            <ClickableTextWithPencil
              onPress={dialogState.show}
              accessibilityLabel={`Edit modifier`}
              label={pencilIconLabel || ''}
            />
          ) : (
            <Label style={{ marginRight: 28 }}>{pencilIconLabel}</Label>
          )
        }
      ></ModifierListItem>
      <BottomDrawer
        testID="modifier-bottom-drawer"
        dialogState={dialogState}
        header={modifier.name.locale}
        accessibilityLabel={modifier.name.locale}
        horizontalAlignment={{
          md: 'flex-end',
        }}
        contentWidth={{
          md: DESKTOP_PRODUCT_CUSTOMIZATION_WIDTH,
        }}
      >
        <Box testID="modifier-options">
          <RadioCheckList
            options={radioListOptions}
            selectedValue={selectedOption._key}
            onChange={handleSelect}
          />
        </Box>
      </BottomDrawer>
    </>
  );
};
