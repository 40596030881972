import React, { PropsWithChildren } from 'react';

import { Icon, useBreakpointValue } from '@rbilabs/universal-components';
import { useIntl } from 'react-intl';

import { ActionButton, LinkNoDecoration } from 'components/app-header/mobile-header/styled';
import { useNavigation } from 'hooks/navigation/use-navigation';
import { useAuthContext } from 'state/auth';
import { useReversedUIContext } from 'state/reversed-ui';
import { useUIContext } from 'state/ui';
import { theme } from 'styles/configure-theme';
import { HEADER_HAMBURGER_BUTTON } from 'utils/test-ids';

const LinkNoDecorationWrapper = ({ children }: PropsWithChildren) => {
  const { formatMessage } = useIntl();

  return (
    <LinkNoDecoration
      testID={HEADER_HAMBURGER_BUTTON}
      to={formatMessage({ id: 'routes.account' })}
      padding={1}
    >
      {children}
    </LinkNoDecoration>
  );
};

const ActionButtonWrapper = ({ children }: PropsWithChildren) => {
  const { setIsAccountDrawerOpen } = useUIContext();
  const { isAuthenticated } = useAuthContext();
  const { navigate } = useNavigation();
  const { formatMessage } = useIntl();

  const handleActionButtonPress = () => {
    if (isAuthenticated) {
      setIsAccountDrawerOpen(true);
      return;
    }

    navigate(formatMessage({ id: 'routes.signIn' }));
  };

  return (
    <ActionButton padding={1} onPress={handleActionButtonPress}>
      {children}
    </ActionButton>
  );
};

export const HamburgerButton: React.FC<React.PropsWithChildren<{}>> = () => {
  const { reversedUI } = useReversedUIContext();

  const iconColor = reversedUI ? theme.token('icon-reversed') : theme.token('icon-header-contrast');

  const IconWrapper = useBreakpointValue({
    base: LinkNoDecorationWrapper,
    lg: ActionButtonWrapper,
  });

  return (
    <IconWrapper>
      <Icon
        variant={{ base: 'menu', lg: 'account' }}
        color={iconColor}
        aria-hidden
        size={{ base: 'sm', lg: 'md' }}
      />
    </IconWrapper>
  );
};
