import { Box } from '@rbilabs/universal-components';

import { getWidgetSpacing } from '../utils';

export const DoubleButtonContainer = Box.withConfig({
  flexDirection: 'row',
  justifyContent: 'center',
  paddingTop: '$6',
  paddingRight: 0,
  paddingBottom: '$4',
  paddingLeft: 0,
  backgroundColor: Styles.color.highlight,

  marginTop: {
    base: 0,
    md: 0,
  },

  marginRight: {
    base: 0,
    md: 0,
  },

  marginBottom: {
    base: getWidgetSpacing.vertical(),
    md: getWidgetSpacing.vertical('desktop'),
  },

  marginLeft: {
    base: 0,
    md: 0,
  },

  borderTopLeftRadius: {
    md: 0,
  },

  borderTopRightRadius: {
    md: 0,
  },

  borderBottomRightRadius: {
    md: 0,
  },

  borderBottomLeftRadius: {
    md: 0,
  },
}); /*
TODO: RN - unsupported CSS
Some attributes were not converted.

export const DoubleButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  padding: 1.5rem 0 1rem;
  background-color: ${Styles.color.highlight};
  margin: 0 0 ${getWidgetSpacing.vertical()};
  & button {
    margin: 0 0.5rem;
    height: 2.75rem;
    padding: 0;
    width: 9.375rem;
  }
  ${Styles.desktop`
    margin: 0 0 ${getWidgetSpacing.vertical('desktop')};
    border-radius: 0 0 ${Styles.borderRadius} ${Styles.borderRadius}
  `}
`;

*/
