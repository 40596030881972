export enum WidgetType {
  WEB_VIEW = 'webView',
  REWARDS_CAROUSEL = 'rewardsCarouselWidget',
  HEADER = 'headerWidget',
  ANCHOR = 'anchorWidget',
  ANCHOR_LINKS = 'anchorLinksWidget',
  LOCALE_BLOCK_TEXT = 'localeBlockTextWidget',
  IMAGE = 'imageWidget',
  CALL_TO_ACTION = 'callToActionWidget',
  ACCORDION = 'accordionWidget',
  PREVIEW = 'previewWidget',
  NUTRITION_EXPLORER = 'nutritionExplorerWidget',
  VIDEO = 'videoWidget',
  YOUTUBE = 'youtubeWidget',
  WEB_VIEW_WIDGET = 'webViewWidget',
  MULTI_WIDGET = 'multiWidget',
  LANGUAGE_SELECTOR = 'languageSelectorWidget',
}
