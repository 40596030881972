import React from 'react';

import { CommonActions } from '@react-navigation/native';

import { getActiveRoute } from 'hooks/navigation/utils';
import { MenuSectionNavigator } from 'navigation/menu-navigator/menu-section-top-tab-bar';
import { NativeStackNavigatorWithBack } from 'navigation/native-stack-navigator-with-back';
import {
  BackButtonOverride,
  INativeStackNavigatorScreens,
} from 'navigation/native-stack-navigator-with-back/types';
import { useStoreRequired } from 'state/store/hooks/use-store-required';
import { routes } from 'utils/routing';

import { StoreClosedConfirmation } from '../../pages/main-menu/store-closed-confirmation';

import { MainMenuNavigator } from './main-menu-top-tab-bar';
import MenuEmptyState from './menu-empty-state';

const menuComponentScreens: INativeStackNavigatorScreens[] = [
  {
    name: routes.menuMain,
    component: MainMenuNavigator,
  },
  {
    name: routes.menuContent,
    component: MenuSectionNavigator,
  },
];

const menuEmptyScreen: INativeStackNavigatorScreens[] = [
  {
    name: routes.menuEmpty,
    component: MenuEmptyState,
  },
];

export const MenuNavigator = () => {
  const { isStoreRequired } = useStoreRequired();

  const screens = isStoreRequired ? menuEmptyScreen : menuComponentScreens;

  return (
    <>
      <NativeStackNavigatorWithBack
        screens={screens}
        backButtonOverride={menuSectionBackButtonOverride}
      />
      {!isStoreRequired && <StoreClosedConfirmation />}
    </>
  );
};

/**
 * This allows for the sometimes funky UX that we have that we've tried to ask for different UX on but they want it this way (for now at least).
 * Presently menus can go many levels deep on a single stack for PLK, providing alternative back button functionality allows for a user to get out of these nested menus.
 */
const menuSectionBackButtonOverride: BackButtonOverride = navigation => {
  const navState = navigation.getState();
  const activeRoute = getActiveRoute<{ subSectionId?: string }>(navState);
  if (!activeRoute?.params?.subSectionId) {
    return;
  }
  return () =>
    navigation.dispatch({
      ...CommonActions.setParams({
        sectionId: undefined,
        subSectionId: undefined,
      }),
      source: activeRoute.key,
    });
};
