import { IWidgetElement } from '../loyalty-widget-components';

export interface ILoyaltyDashboardViewProps {
  /** Array of widgets to populate dashboard view */
  widgets: Array<IWidgetElement>;
  /** Dismisses loyalty dashboard */
  handleDismiss(): void;
}

export enum LoyaltyDashboardWidgets {
  DoubleButton = 'doubleButtonWidget',
  Greeting = 'greetingWidget',
  History = 'historyWidget',
  Links = 'linkWidget',
  Logo = 'logoWidget',
  MarketingTiles = 'marketingTileGroupWidget',
  PointsMeter = 'pointsMeterWidget',
  Reward = 'pointsAndRewardsWidget',
  Tiers = 'tiersWidget',
  Notifications = 'notificationsWidget',
  QuestsTile = 'questsTileWidget',
  RewardsListWidget = 'rewardsListWidget',
  FeatureRewardsCarouselWidget = 'featuredRewardsCarouselWidget',
}
