import React from 'react';

import { Box, Text } from '@rbilabs/universal-components';
import { useIntl } from 'react-intl';
import { useWindowDimensions } from 'react-native';

import ActionButton, { ActionButtonVariants } from 'components/action-button';
import Picture from 'components/picture';
import { useIsMobileBp, useIsTabletBp } from 'hooks/breakpoints';
import HeroLink from 'pages/home/hero-link';
import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';
import { useStoreContext } from 'state/store';
import { theme } from 'styles/configure-theme';
import { EventName, emitEvent } from 'utils/event-hub';
import { routes } from 'utils/routing';

import HeroMaskCards from './hero-mask-cards';
import { MainHeroText } from './main-hero-text';
import {
  BottomHeroButtonWrapper,
  DoubleActionBtnWrapper,
  HeroContainer,
  HeroContent,
  Icon,
  LeftDesktopBtnWrapper,
  WhiteBGWrapper,
} from './styled';
import { IMainHero } from './types';

const MainHeroContainer = Box.withConfig<{ $windowHeight: number }>(({ $windowHeight }) => ({
  height: 0.65 * $windowHeight,
}));

const StyledPicture = Picture.withConfig({
  width: 'full',
  flex: 1,
});

const DefaultButtonContainer = Box.withConfig({
  // TODO: RN - static is not supported. Figure out how we want to use this.
  // position: {
  //   md: 'static',
  // },
  margin: 0,
  marginTop: {
    base: 12,
    md: 0,
  },
  top: {
    base: -1.5,
    md: 0,
  },
  justifyContent: {
    base: 'center',
    md: 'flex-start',
  },
});

const MainHero: React.FC<React.PropsWithChildren<IMainHero>> = ({
  image,
  mobileImage,
  html,
  actionTo,
  heroTextColor,
  heroSuperText,
  heroActionText,
  heroSubText,
  heroMaskCards,
  heroActionLeft,
  heroActionLeftLink,
  heroActionLeftIcon,
  heroActionRight,
  heroActionRightLink,
  heroActionRightIcon,
}) => {
  const { formatMessage } = useIntl();
  const { isStoreOpenAndAvailable } = useStoreContext();
  const menuHref = isStoreOpenAndAvailable ? routes.menu : routes.storeLocator;
  const isMobile = useIsMobileBp();
  const isTablet = useIsTabletBp();
  const enableSideBySideHeroButtons = useFlag(LaunchDarklyFlag.ENABLE_SIDE_BY_SIDE_HERO_BUTTONS);
  const enableBottomHeroButton = useFlag(LaunchDarklyFlag.ENABLE_BOTTOM_HERO_BUTTON);
  const dimension = useWindowDimensions();
  // TODO: Refactor these so that we're not creating components inside of components...
  const DefaultButton = () => (
    <DefaultButtonContainer>
      <HeroLink to={actionTo || menuHref}>
        <ActionButton
          variant={isTablet ? ActionButtonVariants.INVERSE : ActionButtonVariants.PRIMARY}
          testID="default-action-button"
        >
          <Text>{heroActionText ? heroActionText : formatMessage({ id: 'viewMenu' })}</Text>
        </ActionButton>
      </HeroLink>
    </DefaultButtonContainer>
  );

  // Place action in dom depending on mobile/desktop
  // Show default or side by side buttons depending on sanity
  const ActionMobile = () =>
    enableSideBySideHeroButtons ? (
      <DoubleActionBtnWrapper>
        <WhiteBGWrapper>
          <Box width="100%" marginX="0" marginY="0.5">
            {/* @ts-expect-error TS(2322) FIXME: Type '{ children: (false | Element | null | undefi... Remove this comment to see the full error message */}
            <ActionButton onlyIcon isSquare fullWidth to={heroActionLeftLink || menuHref}>
              {heroActionLeftIcon && <Icon image={heroActionLeftIcon} alt="" aria-hidden />}
              {heroActionLeft !== '' && <Text>{heroActionLeft}</Text>}
            </ActionButton>
          </Box>
          <Box width="100%" marginX="0" marginY="0.5">
            {/* @ts-expect-error TS(2322) FIXME: Type '{ children: (false | Element | null | undefi... Remove this comment to see the full error message */}
            <ActionButton onlyIcon isSquare fullWidth wrapText to={heroActionRightLink || menuHref}>
              {heroActionRightIcon && <Icon image={heroActionRightIcon} alt="" aria-hidden />}
              {heroActionRight !== '' && <Text>{heroActionRight}</Text>}
            </ActionButton>
          </Box>
        </WhiteBGWrapper>
      </DoubleActionBtnWrapper>
    ) : (
      <DefaultButton />
    );

  const ActionDesktop = () =>
    enableSideBySideHeroButtons ? (
      <DoubleActionBtnWrapper>
        <ActionButton variant={ActionButtonVariants.INVERSE} to={heroActionLeftLink || menuHref}>
          {heroActionLeftIcon && <Icon image={heroActionLeftIcon} alt="" aria-hidden />}
          {heroActionLeft && <Text>{heroActionLeft}</Text>}
        </ActionButton>
        <LeftDesktopBtnWrapper>
          <ActionButton variant={ActionButtonVariants.INVERSE} to={heroActionRightLink || menuHref}>
            {heroActionRightIcon && <Icon image={heroActionRightIcon} alt="" aria-hidden />}
            {heroActionRight && <Text>{heroActionRight}</Text>}
          </ActionButton>
        </LeftDesktopBtnWrapper>
      </DoubleActionBtnWrapper>
    ) : (
      <DefaultButton />
    );

  return (
    <MainHeroContainer $windowHeight={dimension.height}>
      <StyledPicture
        image={isMobile && mobileImage ? mobileImage : image}
        alt="hero-image"
        onImageLoaded={() => {
          emitEvent(EventName.MAIN_HERO_IMAGE_LOADED);
        }}
      >
        <HeroContent $windowHeight={dimension.height}>
          <HeroContainer>
            <MainHeroText
              heroSuperText={heroSuperText}
              defaultTextColor={theme.token('text-reversed')}
              heroTextColor={heroTextColor}
              html={html}
              heroSubText={heroSubText}
            />
            {isTablet && !enableBottomHeroButton && <ActionDesktop />}
          </HeroContainer>
        </HeroContent>
      </StyledPicture>
      {isMobile && !enableBottomHeroButton && <ActionMobile />}

      {enableBottomHeroButton && (
        <BottomHeroButtonWrapper testID="bottom-hero-button">
          <HeroLink to={actionTo || menuHref}>
            <ActionButton variant={ActionButtonVariants.PRIMARY}>
              {/* TODO: RN - Remove this Text when ActionButton is converted */}
              <Text>{heroActionText ? heroActionText : formatMessage({ id: 'viewMenu' })}</Text>
            </ActionButton>
          </HeroLink>
        </BottomHeroButtonWrapper>
      )}

      {heroMaskCards && heroMaskCards.length > 0 && <HeroMaskCards cards={heroMaskCards} />}
    </MainHeroContainer>
  );
};

export default MainHero;
