import React from 'react';

import { AccentBadge } from './accent-badge';
import { IBadgeProps } from './types';

export const LoginRequiredBadge: React.FC<React.PropsWithChildren<IBadgeProps>> = ({
  formatMessage,
}) => (
  <AccentBadge testID="login-required-badge">{formatMessage({ id: 'signUpToRedeem' })}</AccentBadge>
);
