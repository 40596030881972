import { Dispatch, SetStateAction } from 'react';

import { ISanityBlockContent, ISanityImage, ISanityTypographyBlock } from '@rbi-ctg/menu';
import { IOffersFragment } from 'generated/graphql-gateway';
import { IAppliedOffer } from 'generated/rbi-graphql';
import { IImageFragment } from 'generated/sanity-graphql';
import { IncentiveEvaluationMap } from 'state/loyalty/hooks/types';
import { ICart } from 'state/loyalty/in-restaurant-redemption';
import { LoyaltyOffer } from 'state/loyalty/types';

export type ReduceOffers = ({
  cmsOffers,
  baseOffers,
  appliedOffers,
  inRestaurantRedemptionCart,
  checkLoyaltyOfferAvailability,
  enableSortOffersForServiceMode,
  setOffersFeedbackMap,
  appliedFilters,
}: {
  cmsOffers: LoyaltyOffer[];
  personalizedOffers: LoyaltyOffer[];
  baseOffers: IOffersFragment[];
  appliedOffers: IAppliedOffer[];
  inRestaurantRedemptionCart: ICart;
  checkLoyaltyOfferAvailability: (offer: LoyaltyOffer) => boolean;
  enableSortOffersForServiceMode: boolean;
  setOffersFeedbackMap: Dispatch<SetStateAction<IncentiveEvaluationMap | null>>;
  appliedFilters: IOffersFilters;
}) => LoyaltyOffer[][];

export interface ILoyaltyOffersParams {
  offerEngineId?: string;
  isConfigOffer?: boolean;
}

export enum OffersServiceMode {
  PICKUP_ONLY = 'pickUpOnly',
  DELIVERY_ONLY = 'deliveryOnly',
}

export enum OfferFiltersActionType {
  TOGGLE_PICKUP,
  TOGGLE_DELIVERY,
  TOGGLE_INSTORE,
  SELECT_PICKUP,
  SELECT_DELIVERY,
}

export type OfferFilterAction = {
  type: OfferFiltersActionType;
};

export interface IOffersFilters {
  [OffersServiceMode.PICKUP_ONLY]: boolean;
  [OffersServiceMode.DELIVERY_ONLY]: boolean;
  inStoreEnabled: boolean;
}

export interface IAdaptableMenuOffer {
  _id: string;
  loyaltyEngineId?: string | null | undefined;
  header: ISanityBlockContent[] | ISanityTypographyBlock[] | null;
  description: ISanityBlockContent[] | ISanityTypographyBlock[] | null;
  image: ISanityImage | IImageFragment | null | undefined;
}
