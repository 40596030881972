import React from 'react';

import { useBreakpointValue } from '@rbilabs/universal-components';

import { BkProductBgMobileSvg, BkProductBgWebSvg } from 'components/svgs/bk-product-bg';

import { BackgroundImageColor } from './product-overview.styled';

export const ProductBackground = () => {
  const BkProductBgSvg = useBreakpointValue({
    base: BkProductBgMobileSvg,
    md: BkProductBgWebSvg,
  });
  return (
    <BackgroundImageColor>
      <BkProductBgSvg />
    </BackgroundImageColor>
  );
};
