import { IImageFragment } from 'generated/sanity-graphql';

export const mockedQuestData = Array(3).fill({
  title: {
    locale: 'Free Chicken Sandwich',
  },
  image: {
    locale: {
      asset: {
        metadata: {
          lqip:
            'data:image/jpeg;base64,/9j/2wBDAAYEBQYFBAYGBQYHBwYIChAKCgkJChQODwwQFxQYGBcUFhYaHSUfGhsjHBYWICwgIyYnKSopGR8tMC0oMCUoKSj/2wBDAQcHBwoIChMKChMoGhYaKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCj/wAARCAAIABQDASIAAhEBAxEB/8QAFgABAQEAAAAAAAAAAAAAAAAAAAcG/8QAHxAAAQQCAwEBAAAAAAAAAAAAAQACAwQFEQYhQRQx/8QAFwEAAwEAAAAAAAAAAAAAAAAAAwQFBv/EABwRAAICAgMAAAAAAAAAAAAAAAECAAQDIRESE//aAAwDAQACEQMRAD8AzfHPhM8b7tg1xE4HbBtzlYIL2C4rh7d0SPEt1hOz2XOI668REe2zeypzomau5jDsFJ0ZEZstYmkLppJXnemku/B4ERFW6iPhBP/Z',
          __typename: 'SanityImageMetadata',
        },
        _id: 'image-0fe568ca4a01196a9a1ccc8068746b52c49ab4a0-240x97-svg',
        __typename: 'SanityImageAsset',
      },
      __typename: 'Image',
    } as IImageFragment,
    __typename: 'LocaleImage',
  },
  description: {
    locale: 'Order 3 times in a week to unlock this deal.',
  },
  expiration: {
    locale: 'Expires in 5 days',
  },
});

export type Quest = typeof mockedQuestData[0];

export const useQuests = () => {
  return { data: mockedQuestData, loading: false };
};
