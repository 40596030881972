import React, { useCallback, useMemo, useState } from 'react';

import { IPickerAspect } from '@rbi-ctg/menu';
import { useResetConfirmDialog } from 'hooks/menu/use-reset-confirm-dialog';
import { CustomEventNames, EventTypes, useCRMEventsContext } from 'state/crm-events';
import { ClickEventComponentNames } from 'state/crm-events/constants';
import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';
import { useProductWizardContext } from 'state/product-wizard';
import { UserSelectionPickerAspect } from 'state/product-wizard/types';
import { pickerAspectOptHasResolution } from 'utils/menu';
import { buildCalorieMapForPickerPossibilities } from 'utils/menu/picker-calorie-ranges';

import { createPickerPromotionMap } from '../utils';

import { PickerAspectGroup } from './picker-aspect-group';
import { IHandlePickerChangeParams, IPickerAspectSelectionArgs, IPickerAspects } from './types';

export const PickerAspects = React.memo<IPickerAspects>(
  ({ pickerData, userSelectedPickerAspects }) => {
    const { updatePickerAspectSelections, isDirty } = useProductWizardContext();
    const [selectedPickerAspects, setSelectedPickerAspects] = useState(
      userSelectedPickerAspects || {}
    );
    const pickerAspects = pickerData.pickerAspects || [];

    const displayNutritionWithModifiersFromSanity = useFlag(
      LaunchDarklyFlag.DISPLAY_NUTRITION_WITH_MODIFIERS_FROM_SANITY
    );

    const calorieMap = useMemo(
      () =>
        buildCalorieMapForPickerPossibilities(pickerData, displayNutritionWithModifiersFromSanity),
      [pickerData, displayNutritionWithModifiersFromSanity]
    );

    const getPreviousPickerSelectionIdentifiers = useCallback(
      (startPoint: number, endPoint: number) =>
        pickerData.pickerAspects
          .slice(startPoint, endPoint)
          .map(aspect => selectedPickerAspects?.[aspect._id]),
      [pickerData.pickerAspects, selectedPickerAspects]
    );

    const filteredPickerAspects = useMemo<IPickerAspect[]>(
      () =>
        pickerAspects.map((currentAspect, index) => {
          const resolvedOpts = currentAspect.pickerAspectOptions.filter(option =>
            pickerAspectOptHasResolution(
              option.identifier,
              getPreviousPickerSelectionIdentifiers(0, index),
              pickerData.options
            )
          );
          return {
            ...currentAspect,
            pickerAspectOptions: resolvedOpts,
          };
        }),
      [pickerAspects, getPreviousPickerSelectionIdentifiers, pickerData.options]
    );

    const { logRBIEvent } = useCRMEventsContext();
    const handleOnAspectOptionClick = useCallback(
      (args: IPickerAspectSelectionArgs) => {
        let newSelectedPickerAspects: UserSelectionPickerAspect;
        const pickerAspectsLength = pickerData.pickerAspects.length;
        // Check new resolved options for the preceding aspects unless it's the last one
        if (pickerAspectsLength > 1 && args.index !== pickerAspectsLength - 1) {
          const pickerSelections = {} as UserSelectionPickerAspect;
          pickerData.pickerAspects.slice(args.index + 1, pickerAspectsLength).forEach(aspect => {
            const possibleOptsForPickerAspect = aspect.pickerAspectOptions.filter(option =>
              pickerAspectOptHasResolution(
                option.identifier,
                [...getPreviousPickerSelectionIdentifiers(0, args.index), args.optionIdentifier],
                pickerData.options
              )
            );
            const currentMatchingSelectedOption = possibleOptsForPickerAspect.find(
              opt => opt?.identifier === selectedPickerAspects?.[aspect._id]
            );
            // Use the value in state if it's already a possible resolved option
            // Else, choose the first option in the list to default select
            pickerSelections[aspect._id] = currentMatchingSelectedOption
              ? currentMatchingSelectedOption.identifier
              : possibleOptsForPickerAspect[0].identifier;
          });
          newSelectedPickerAspects = {
            ...selectedPickerAspects,
            ...pickerSelections,
            [args.aspect._id]: args.optionIdentifier,
          };
        } else {
          newSelectedPickerAspects = {
            ...selectedPickerAspects,
            [args.aspect._id]: args.optionIdentifier,
          };
        }
        logRBIEvent({
          name: CustomEventNames.CLICK_EVENT,
          type: EventTypes.Other,
          attributes: {
            component: ClickEventComponentNames.PRODUCT_SELECTION_MODIFICATION,
            text: args.optionIdentifier,
            headerText: args.aspect?.name?.locale,
            componentId: args.aspect?._id,
          },
        });
        setSelectedPickerAspects(newSelectedPickerAspects);
        updatePickerAspectSelections(newSelectedPickerAspects);
      },
      [
        getPreviousPickerSelectionIdentifiers,
        pickerData.options,
        pickerData.pickerAspects,
        selectedPickerAspects,
        logRBIEvent,
        updatePickerAspectSelections,
      ]
    );

    const { openConfirmationDialog, ResetConfirmDialog } = useResetConfirmDialog<
      IHandlePickerChangeParams
    >(
      'Confirmation: picker aspect will reset customizations to default',
      params => params && handleOnAspectOptionClick(params.args),
      params => params && params.onCancel()
    );

    const handlePickerAspectChange = useCallback(
      (params: IHandlePickerChangeParams) => {
        if (isDirty) {
          openConfirmationDialog(params);
        } else {
          handleOnAspectOptionClick(params.args);
        }
        logRBIEvent({
          name: CustomEventNames.CLICK_EVENT,
          type: EventTypes.Other,
          attributes: {
            component: ClickEventComponentNames.PRODUCT_SELECTION_MODIFICATION,
            text: params.args.optionIdentifier,
            headerText: params.args.aspect.name.locale,
            componentId: params.args.aspect._id,
          },
        });
      },
      [handleOnAspectOptionClick, isDirty, openConfirmationDialog, logRBIEvent]
    );

    const promotionMap = useMemo(() => createPickerPromotionMap(pickerData.options), [
      pickerData.options,
    ]);

    return (
      <>
        {filteredPickerAspects.map((aspect, aspectIndex) => (
          <PickerAspectGroup
            key={aspect._id}
            pickerAspect={aspect}
            userSelectedPickerAspects={selectedPickerAspects}
            calorieMap={calorieMap}
            handlePickerAspectChange={handlePickerAspectChange}
            aspectIndex={aspectIndex}
            promotionMap={promotionMap}
          />
        ))}
        {ResetConfirmDialog}
      </>
    );
  }
);
