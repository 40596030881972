import React from 'react';

import { Box } from '@rbilabs/universal-components';
import { useIntl } from 'react-intl';

import { HomePage } from 'components/features/home-page';
import { SimplifiedLandingPage__Experiment } from 'experiments/simplified-landing-page';
import { useRumPageView } from 'hooks/rum/use-rum-page-view';
import { LoyaltyBanner } from 'pages/loyalty/loyalty-banner';
import { LoyaltyCtaBar } from 'pages/loyalty/loyalty-cta-bar';
import useAuthFlow from 'state/auth/hooks/use-auth-flow';
import { isMobile } from 'utils/environment';

const Home = () => {
  const { formatMessage } = useIntl();

  const { triggerSignInAccessibility } = useAuthFlow();
  const signInAccessibilityMsg = triggerSignInAccessibility
    ? formatMessage({ id: 'visuallyHiddenSignInSuccess' })
    : '';

  useRumPageView('home', 'Home');

  return (
    <Box
      flex={1}
      testID="homepage"
      accessibilityLabel={signInAccessibilityMsg}
      accessibilityRole="alert"
    >
      {isMobile() && <LoyaltyCtaBar />}
      <LoyaltyBanner />
      <HomePage />
    </Box>
  );
};

function HomeControlOrTreatment() {
  return (
    <SimplifiedLandingPage__Experiment>
      <Home />
    </SimplifiedLandingPage__Experiment>
  );
}

export default HomeControlOrTreatment;
