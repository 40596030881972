import React, { VFC, useMemo } from 'react';

import { useIntl } from 'react-intl';

import LegalDisclaimers from 'components/legal-disclaimers';
import NutritionModal from 'components/nutrition';
import { AllergensDisclaimer } from 'components/nutrition/allergens-disclaimer';
import { useNutritionFacts } from 'components/nutrition/use-nutrition-facts';
import { transformUserSelectionsComboToComboSlotSelections } from 'components/product-detail/utils';
import { TextWithArrowButton } from 'components/text-with-arrow-button';
import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';
import { useProductWizardContext } from 'state/product-wizard';

import { InformationTitle, NutritionInformationWrapper } from './nutrition-information.styled';

const NutritionInformation: VFC = () => {
  const { formatMessage } = useIntl();
  const { selectedProduct, userSelections } = useProductWizardContext();

  const displayNutritionWithModifiersFromSanity = useFlag(
    LaunchDarklyFlag.DISPLAY_NUTRITION_WITH_MODIFIERS_FROM_SANITY
  );
  const enableAllergensDisclaimer = useFlag(LaunchDarklyFlag.ENABLE_ALLERGENS_DISCLAIMER);

  // TODO: Remove this transform and refactor the hook to support this new data structure
  const comboSlotSelections = useMemo(
    () => transformUserSelectionsComboToComboSlotSelections(userSelections.comboSlot),
    [userSelections.comboSlot]
  );

  const nutritionFacts = useNutritionFacts({
    pickerSelection: selectedProduct,
    comboSlotSelections,
    itemName: selectedProduct?.name?.locale,
    itemImage: selectedProduct.image,
    displayNutritionWithModifiersFromSanity,
  });

  return (
    <NutritionInformationWrapper>
      {!selectedProduct.hideNutritionLegalDisclaimer && (
        <InformationTitle>{formatMessage({ id: 'information' })}</InformationTitle>
      )}

      {enableAllergensDisclaimer && (
        <AllergensDisclaimer allergenGroups={nutritionFacts?.[0]?.allergenGroups} />
      )}

      <LegalDisclaimers
        hideNutritionLegalDisclaimer={selectedProduct.hideNutritionLegalDisclaimer}
      />

      {!selectedProduct.hideCalories && (
        <NutritionModal
          nutritionFacts={nutritionFacts}
          activatingElement={<TextWithArrowButton messageId="nutritionalInformation" />}
        />
      )}
    </NutritionInformationWrapper>
  );
};

export default NutritionInformation;
