import { VFC } from 'react';

import { useNavigation } from 'hooks/navigation/use-navigation';
import { useRumPageView } from 'hooks/rum/use-rum-page-view';
import useEffectOnce from 'hooks/use-effect-once';
import { routes } from 'utils/routing';

const NotFound: VFC = () => {
  useRumPageView('not-found', 'Not Found');
  const { navigate } = useNavigation();

  useEffectOnce(() => {
    /**
     * In the case of React Native, we navigate the user back to the home page
     * If we encounter an unexpected route which leads us to rendering the NotFound component
     */
    navigate(routes.base);
  });
  return null;
};

export default NotFound;
