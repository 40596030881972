import { useCallback, useEffect, useState } from 'react';

import { IBackendCartEntries, IHistoricalOrder, ServiceMode } from '@rbi-ctg/menu';
import { useNavigation } from 'hooks/navigation/use-navigation';
import { useRoute } from 'hooks/navigation/use-route';
import useFoodware from 'hooks/use-foodware';
import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';
import { useLocationContext } from 'state/location';
import { OrderStatus, useOrderContext } from 'state/order';
import { useStoreContext } from 'state/store';
import { routes } from 'utils/routing';
import { isCatering } from 'utils/service-mode';

import { isOfferType } from '../utils';

export interface IFavoriteOrder {
  cart: {
    cartEntries: IBackendCartEntries[];
    /* reorderCartEntries are needed to handleReorder */
    reorderCartEntries: IBackendCartEntries[];
    serviceMode: ServiceMode | null;
    cartVersion: number;
  };
  status?: OrderStatus;
}

export const useReorder = (order?: IHistoricalOrder | IFavoriteOrder | null) => {
  const [hasOffer, setHasOffer] = useState(false);
  const {
    reorder: { handleReorder, setPendingReorder },
  } = useOrderContext();
  const { isStoreOpenAndAvailable, noStoreSelected } = useStoreContext();
  const { setStoreLocatorCallbackUrl } = useLocationContext();
  const { navigate } = useNavigation();
  const { pathname } = useRoute();
  const enableReorder = useFlag(LaunchDarklyFlag.ENABLE_REORDER);
  const { isFoodware } = useFoodware();

  /**
   * TODO: Add support for offers with reorder.
   * - right now just disabling reorder if there's an offer.
   */
  useEffect(() => {
    const hasOfferEntry =
      order && order.cart
        ? (order.cart.cartEntries || []).some((entry: IBackendCartEntries) =>
            isOfferType(entry.type)
          )
        : false;

    setHasOffer(hasOfferEntry);
  }, [order]);

  const handleReorderClick = useCallback(async () => {
    if (!order) {
      return;
    }

    const filteredReorderCartEntries = order.cart.reorderCartEntries?.filter(
      item => !(item.isDonation || isFoodware(item))
    );

    const filteredOrder = {
      ...order,
      cart: {
        ...order.cart,
        reorderCartEntries: filteredReorderCartEntries,
      },
    };

    if (isStoreOpenAndAvailable) {
      handleReorder(filteredOrder);
    } else {
      const route = isCatering(filteredOrder.cart.serviceMode)
        ? routes.serviceModeCatering
        : routes.storeLocator;
      setPendingReorder(filteredOrder);
      setStoreLocatorCallbackUrl(pathname);
      navigate(route, { replace: true });
    }
  }, [
    order,
    isStoreOpenAndAvailable,
    isFoodware,
    handleReorder,
    setPendingReorder,
    setStoreLocatorCallbackUrl,
    pathname,
    navigate,
  ]);

  const serviceMode = order?.cart.serviceMode ?? null;
  const isCateringWithWrongStatus =
    isCatering(serviceMode) && order?.status !== OrderStatus.CATERING_SUCCESSFUL;

  const disabled =
    !enableReorder ||
    hasOffer ||
    isCateringWithWrongStatus ||
    isCatering(serviceMode) ||
    (!isStoreOpenAndAvailable && !noStoreSelected);

  return { handleReorderClick, disabled };
};
