import { StorePermissions, StoreProxy, curriedGetStoreStatus } from './types';

/**
 * Creates a function to get a store's status for store selection 1.0
 *
 * @param {ServiceMode} selectedServiceMode the default service mode to check against
 * @param {ServiceMode} serviceMode service mode to check
 * @param {ServiceModeStatus} serviceModeStatuses the service mode status of the store
 */
export const curriedGetStoreStatusV1: curriedGetStoreStatus = ({
  store,
  serviceModeStatuses,
  selectedServiceMode,
}) => (serviceMode = selectedServiceMode) => {
  const noStoreSelected =
    !store ||
    (store &&
      'selectedUnavailableStoreNumber' in store &&
      store.selectedUnavailableStoreNumber === null);

  if (noStoreSelected || !serviceMode) {
    return StorePermissions.NO_STORE_SELECTED;
  }

  const serviceModeStatus = serviceModeStatuses[serviceMode];
  const unavailableStoreSelected = store && !('hasMobileOrdering' in store);

  if (unavailableStoreSelected) {
    return StorePermissions.OPEN_AND_UNAVAILABLE;
  }

  if (serviceModeStatus.available && !serviceModeStatus.disabled) {
    return StorePermissions.OPEN_AND_AVAILABLE;
  }

  return serviceModeStatus.capable
    ? StorePermissions.CLOSED_AND_AVAILABLE
    : StorePermissions.CLOSED;
};

/**
 * Creates a function to get a store's status for store selection 2.0
 *
 * @param {ServiceMode} selectedServiceMode the default service mode to check against
 * @param {ServiceMode} serviceMode service mode to check
 * @param {ServiceModeStatus} serviceModeStatuses the service mode status of the store
 */
export const curriedGetStoreStatusV2: curriedGetStoreStatus = ({
  store,
  serviceModeStatuses,
  selectedServiceMode,
}) => (serviceMode = selectedServiceMode) => {
  if (!store || !serviceMode) {
    return StorePermissions.NO_STORE_SELECTED;
  }

  const serviceModeStatus = serviceModeStatuses[serviceMode];

  const storeHasMobileOrdering = 'hasMobileOrdering' in store && store.hasMobileOrdering;

  if (serviceModeStatus.open && !serviceModeStatus.disabled) {
    return serviceModeStatus.available && storeHasMobileOrdering
      ? StorePermissions.OPEN_AND_AVAILABLE
      : StorePermissions.OPEN_AND_UNAVAILABLE;
  }

  return serviceModeStatus.capable && storeHasMobileOrdering
    ? StorePermissions.CLOSED_AND_AVAILABLE
    : StorePermissions.CLOSED;
};

export const initialStoreProxyState: StoreProxy = {
  _id: null,
  chaseMerchantId: null,
  curbsideHours: null,
  deliveryHours: null,
  diningRoomHours: null,
  distanceInMiles: null,
  drinkStationType: null,
  driveThruHours: null,
  driveThruLaneType: null,
  email: null,
  environment: null,
  franchiseGroupId: null,
  franchiseGroupName: null,
  frontCounterClosed: null,
  hasBreakfast: null,
  hasBurgersForBreakfast: null,
  hasCatering: null,
  hasCurbside: null,
  hasDelivery: null,
  hasDineIn: null,
  hasDriveThru: null,
  hasMobileOrdering: null,
  hasParking: null,
  hasPlayground: null,
  hasTakeOut: null,
  hasWifi: null,
  hasLoyalty: null,
  isDarkKitchen: null,
  isHalal: null,
  latitude: null,
  longitude: null,
  mobileOrderingStatus: null,
  name: null,
  number: null,
  parkingType: null,
  phoneNumber: null,
  physicalAddress: null,
  playgroundType: null,
  pos: null,
  posRestaurantId: null,
  restaurantImage: null,
  restaurantPosData: null,
  status: null,
  amenities: [],
  vatNumber: null,
};
