import React from 'react';

import { Icon } from '@rbilabs/universal-components';
import { useIntl } from 'react-intl';

import { HeaderLeftIconType } from 'navigation/header/types';
import { useHeaderStyles } from 'navigation/header/use-header-styles';
import { hiddenAccessibilityPlatformProps } from 'utils/accessibility';
import { HEADER_HAMBURGER_BUTTON } from 'utils/test-ids';

import { HeaderLeftContainer } from './styles';

export interface IHeaderLeftProps {
  onPress?: () => void;
  iconProps?: {
    variant: HeaderLeftIconType;
    color?: string;
  };
}

export const HeaderLeft: React.VFC<IHeaderLeftProps> = ({ onPress, iconProps }) => {
  const { iconColor } = useHeaderStyles();
  const { formatMessage } = useIntl();

  return (
    <HeaderLeftContainer
      onPress={onPress}
      accessibilityRole="button"
      accessibilityLabel={formatMessage({ id: iconProps?.variant || HeaderLeftIconType.Menu })}
      testID={HEADER_HAMBURGER_BUTTON}
    >
      <Icon
        variant={iconProps?.variant || HeaderLeftIconType.Menu}
        color={iconProps?.color || iconColor}
        size="sm"
        testID="header-ham-icon"
        {...hiddenAccessibilityPlatformProps}
      />
    </HeaderLeftContainer>
  );
};
