import { Box, Header, Pressable, VStack } from '@rbilabs/universal-components';

import Picture from 'components/picture';
import { delayUnmountHOC } from 'utils/delay-unmount-hoc';

const RewardContainer = Box.withConfig({});
const CATEGORY_HEADER_HEIGHT = 57;

export const DelayedUnmountRewardContainer = delayUnmountHOC(RewardContainer);

export const RewardCategoryAccordion = VStack.withConfig<{
  open: boolean;
  categoryHeight: number;
  isCurrentSelection: boolean;
}>(p => ({
  height: (p.open ? p.categoryHeight : 0) + CATEGORY_HEADER_HEIGHT,
  background: 'token.background-pattern',
}));

export const CategoryHeader = VStack.withConfig({
  justifyContent: 'center',
  height: CATEGORY_HEADER_HEIGHT,
  borderBottomWidth: 1,
  borderBottomColor: 'blackOpacity.10',
  background: 'token.background-pattern',
});

export const AccordionTrigger = Pressable.withConfig({
  display: 'flex',
  flexDir: 'row',
  alignItems: 'center',
  alignContent: 'space-between',
  paddingX: 4,
  flexGrow: 1,
});

export const CategoryImage = Picture.withConfig({
  height: '$6',
  width: '$6',
  marginRight: '$3',
});

export const CategoryLabel = Header.withConfig({
  variant: 'headerThree',
  margin: 0,
  flexGrow: 2,
});

export const LoyaltyRewardsTitle = Header.withConfig({
  variant: 'headerThree',
  margin: 0,
  padding: 4,
});

export const LoyaltyLoaderContainer = Box.withConfig({
  height: '100%',
  minHeight: '$80',
  justifyContent: 'center',
});
