import * as React from 'react';
import { FC, useMemo } from 'react';

import { useReversedUIContext } from 'state/reversed-ui';
import { HEADER_CHOOSE_LOCATION } from 'utils/test-ids';

import ChooseLocation from './actions/choose-location';
import {
  ButtonWrapper,
  ClickableWrapper,
  Container,
  Details,
  DetailsContainer,
  Heading,
  IconContainer,
} from './top-service-mode.styled';
import { ITopServiceModeViewProps } from './types';

// Maximum allowed number of characters for the selected location address as per DS
const maxChars = 24;

export const TopServiceModeDefaultView: FC<React.PropsWithChildren<ITopServiceModeViewProps>> = ({
  heading,
  details,
  icon,
  button,
  onTopServiceClick,
}) => {
  const { reversedUI } = useReversedUIContext();

  const customDetails = useMemo(
    () =>
      typeof details !== 'string' || details.length <= maxChars
        ? details
        : `${details.substring(0, maxChars)}...`,
    [details]
  );

  return (
    <Container testID="top-service-mode" reversedUI={reversedUI}>
      <ClickableWrapper
        dd-action-name="top-service-mode-wrapper"
        testID={HEADER_CHOOSE_LOCATION}
        onPress={onTopServiceClick}
        accessibilityRole="link"
        height="$12"
      >
        <IconContainer>{icon}</IconContainer>
        <DetailsContainer>
          <Heading reversedUI={reversedUI}>{heading}</Heading>
          {details ? (
            <Details reversedUI={reversedUI}>{customDetails}</Details>
          ) : (
            <ChooseLocation reversedUI={reversedUI} />
          )}
        </DetailsContainer>
      </ClickableWrapper>
      {button && <ButtonWrapper>{button}</ButtonWrapper>}
    </Container>
  );
};
