import { Box } from '@rbilabs/universal-components';

import { theme } from 'styles/configure-theme';

export interface IListable {
  first: boolean;
  last: boolean;
  shouldRenderDefaultBackground?: boolean;
}

export const Section = Box.withConfig<IListable>(p => ({
  backgroundColor: p.shouldRenderDefaultBackground
    ? theme.token('background-default')
    : 'transparent',
  paddingX: 0,

  /* HACK: The hero has too much padding on the bottom */
  /* TODO: change to 2rem */
  paddingTop: {
    base: '4',
    md: p.first ? '7' : '4',
  },
  paddingBottom: p.last ? '7' : '4',
}));

interface ISpacer {
  $isSmall: boolean;
}
export const Spacer = Box.withConfig<ISpacer>(p => ({
  height: {
    base: '0',
    md: p.$isSmall ? '78px' : '110px',
  },
}));
