import React from 'react';

import { HStack, makeUclComponent } from '@rbilabs/universal-components';
import { formatDuration } from 'date-fns';
import { useIntl } from 'react-intl';

import { UIPattern } from 'state/offers/types';

import { AccentBadge } from './accent-badge';
import {
  LockedPromoCodeOfferBadge,
  LoginRequiredBadge,
  PlatformBadge,
  TimeLeftBadge,
  UnlockedPromoCodeOfferBadge,
} from './badges';
import { IOfferBadgesProps } from './types';

export const BadgeContentContainer = HStack.withConfig({
  flexGrow: 0,
  flexWrap: 'wrap',
  alignItems: 'flex-start',
  marginTop: 0,
});

export const OfferBadges = makeUclComponent(function ({
  endingIn,
  platforms,
  displayLoginRequiredBadge,
  uiPattern,
  isUnlocked,
  style,
}: IOfferBadgesProps) {
  const { formatMessage } = useIntl();

  const BadgeContent = React.useMemo(() => {
    // if it's a promo code offer, we don't want to show any other badges
    // NOTE: still nesting in an array for type consistency later on
    if (uiPattern === UIPattern.PROMO) {
      return isUnlocked
        ? [
            <UnlockedPromoCodeOfferBadge
              formatMessage={formatMessage}
              key="unlocked-promo-code-offer-badge"
            />,
          ]
        : [
            <LockedPromoCodeOfferBadge
              formatMessage={formatMessage}
              key="locked-promo-code-offer-badge"
            />,
          ];
    }

    // otherwise, let's add any other possible badges to the content array
    const content = [];
    if (endingIn) {
      content.push(
        <TimeLeftBadge {...endingIn} formatMessage={formatMessage} key="time-left-badge" />
      );
      content.push(
        // https://date-fns.org/v2.27.0/docs/formatDuration
        // Customize the delimiter
        // formatDuration({ years: 2, months: 9, weeks: 3 }, { delimiter: ', ' })
        //=> '2 years, 9 months, 3 weeks'
        <AccentBadge {...endingIn} key="accent-badge">
          {formatDuration({ ...endingIn, days: 0 }, { delimiter: ' and ' })}
        </AccentBadge>
      );
    }
    // NOTE: unsure why platforms comes in as an array when we're explicitly checking for length of 1
    //       this was the legacy code that was in here.
    // TODO: determine if we can change this to either: expect a single value instead of an array
    //       OR potentially expect multiple values and replace this explicit length check with
    //       just a forEach over the platforms array
    if (platforms.length === 1) {
      content.push(
        <PlatformBadge platform={platforms[0]} formatMessage={formatMessage} key={platforms[0]} />
      );
    }
    if (displayLoginRequiredBadge) {
      content.push(<LoginRequiredBadge key="login-required-badge" formatMessage={formatMessage} />);
    }
    return content;
  }, [displayLoginRequiredBadge, endingIn, formatMessage, isUnlocked, platforms, uiPattern]);

  if (!BadgeContent.length) {
    return null;
  }

  return (
    <BadgeContentContainer {...style} testID="offer-badges-container">
      {BadgeContent}
    </BadgeContentContainer>
  );
});
