import * as React from 'react';
import { FC } from 'react';

import { useLoyaltyOffersUiIncentiveNotAvailableQuery } from 'generated/sanity-graphql';
import { maybeLocale, maybeLocaleImages } from 'utils/graphql';

import LoyaltyIncentiveNotAvailableModalView from './loyalty-incentive-not-available-modal.view';

interface LoyaltyIncentiveNotAvailableModalProps {
  isOffer?: boolean;
}

const LoyaltyIncentiveNotAvailableModal: FC<React.PropsWithChildren<
  LoyaltyIncentiveNotAvailableModalProps
>> = ({ isOffer }) => {
  const { data, loading } = useLoyaltyOffersUiIncentiveNotAvailableQuery({
    variables: { id: 'feature-loyalty-offers-ui-singleton' },
  });

  const content = isOffer
    ? data?.LoyaltyOffersUI?.offerNotAvailableModalContent
    : data?.LoyaltyOffersUI?.rewardNotAvailableModalContent;

  const image = maybeLocaleImages(content?.image);
  const title = maybeLocale(content?.title);
  const description = maybeLocale(content?.description);
  const primaryButtonText = maybeLocale(content?.primaryButton?.buttonText);
  const primaryButtonPath = maybeLocale(content?.primaryButton?.buttonPath);
  const secondaryButtonText = maybeLocale(content?.secondaryButton?.buttonText);
  const secondaryButtonPath = maybeLocale(content?.secondaryButton?.buttonPath);

  if (loading || !image || !title || !description || !primaryButtonText) {
    return null;
  }

  const defaultPath = '/';
  const actionButtons = [
    { buttonText: primaryButtonText, buttonPath: primaryButtonPath || defaultPath },
    {
      buttonText: secondaryButtonText || '',
      buttonPath: secondaryButtonPath || defaultPath,
      inverted: true,
    },
  ];

  return (
    <LoyaltyIncentiveNotAvailableModalView
      image={image}
      title={title}
      description={description}
      actionButtons={actionButtons}
    />
  );
};

export default LoyaltyIncentiveNotAvailableModal;
