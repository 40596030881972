import React, { FC } from 'react';

import { Container, ElementWrapper } from 'components/menu-order-wizard/wizard-cta-wrapper/styled';
import { StickyFooter } from 'components/sticky-footer/sticky-footer';
import { ElementContent } from 'components/sticky-footer/types';
import { useReactNavigationFlag } from 'hooks/use-react-navigation-flag';

interface IWizardCtaWrapperProps {
  elements: ElementContent[];
}

export const WizardCtaWrapper: FC<React.PropsWithChildren<IWizardCtaWrapperProps>> = ({
  elements,
}) => {
  const enableReactNativeNavigation = useReactNavigationFlag();
  const elementsToRender = elements.filter(({ element }) => element);

  if (enableReactNativeNavigation) {
    return (
      <Container>
        {elementsToRender.map(({ element }, index) => (
          <ElementWrapper key={index}>{element}</ElementWrapper>
        ))}
      </Container>
    );
  }

  return <StickyFooter padding="16px" elements={elementsToRender} />;
};
