import React from 'react';

import { useIntl } from 'react-intl';
import { Linking, Platform } from 'react-native';

import { useConfigValue } from 'hooks/configs/use-config-value';
import useDialogModal from 'hooks/use-dialog-modal';
import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';
import logger from 'utils/logger';

export enum ForceAppUpdateVariations {
  NONE = 'none',
  LOW = 'low',
  MEDIUM = 'medium',
  HIGH = 'high',
}

export const ForceUpdateModal = () => {
  const forcedNativeAppUpdateVariant =
    useFlag(LaunchDarklyFlag.ENABLE_FORCED_NATIVE_APP_UPDATE) ?? ForceAppUpdateVariations.NONE;
  const { formatMessage } = useIntl();
  const appStoreUrl = useConfigValue({ key: 'appStoreUrl', isRegionalized: false });
  const playStoreUrl = useConfigValue({ key: 'playStoreUrl', isRegionalized: false });
  const storeUrl = Platform.OS === 'ios' ? appStoreUrl : playStoreUrl;

  const displayForceUpdateDialog =
    storeUrl && ForceAppUpdateVariations.NONE !== forcedNativeAppUpdateVariant;
  const [ForceUpdateModalComponent] = useDialogModal({
    showCancel: false,
    onConfirm: () => {
      Linking.openURL(storeUrl).catch(error => {
        logger.warn({
          error,
          message: `Link: there was an error opening the app store URL: ${storeUrl}`,
        });
      });
    },
    init: displayForceUpdateDialog,
    modalAppearanceEventMessage: 'Force update app',
    allowDismiss: false,
    allowClose: false,
    showCloseButton: false,
  });
  return (
    <ForceUpdateModalComponent
      heading=""
      body={formatMessage({ id: 'newUpdateRequired' })}
      buttonLabel={formatMessage({ id: 'updateApp' })}
    />
  );
};

ForceUpdateModal.whyDidYouRender = true;
