import { SupportedLanguages } from '@rbi-ctg/frontend';
import LocalStorage, { StorageKeys } from 'utils/local-storage';

import { findSupportedLanguage } from './find-supported-language';
import { getMessagesForLanguage } from './get-messages-for-language';
import { getNavigatorLanguage } from './get-navigator-language';
import { inferLanguageFromNavigator } from './infer-language-from-navigator';
import { inferLanguageFromUrlParams } from './infer-language-from-url-params';

export { findSupportedLanguage };
export { getMessagesForLanguage };

export function loadLanguage(): SupportedLanguages {
  const navigatorLanguage = getNavigatorLanguage();
  const inferredUrlParamLanguage = inferLanguageFromUrlParams();

  const lang =
    inferredUrlParamLanguage ||
    LocalStorage.getItem<SupportedLanguages>(StorageKeys.LANGUAGE) ||
    navigatorLanguage.split(/-.*/)[0];

  return findSupportedLanguage(lang);
}

export function inferLanguage(): Promise<string> {
  return Promise.resolve(inferLanguageFromNavigator());
}
