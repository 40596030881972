import React, { useState } from 'react';

import { View } from '@rbilabs/universal-components';

import { useRoute } from 'hooks/navigation/use-route';
import { useReactNavigationFlag } from 'hooks/use-react-navigation-flag';
import { routes } from 'utils/routing';

import { OffersUIProvider } from './offers-ui-context';
import { OffersRouter } from './routes';

const Container = View.withConfig({
  flex: 1,
  width: 'full',
  minHeight: '1/2',
  flexDirection: {
    md: 'row',
  },
});

interface IUIRefreshOffersViewProps {
  suffixUrl: string;
  currentUrl: string;
  urlOfferId: string | null;
}

const UIRefreshOffersView: React.FC<React.PropsWithChildren<IUIRefreshOffersViewProps>> = ({
  suffixUrl,
  currentUrl,
  urlOfferId,
}) => {
  return (
    <OffersUIProvider suffixUrl={suffixUrl} currentUrl={currentUrl} urlOfferId={urlOfferId}>
      <Container>
        <OffersRouter showOfferDetails={!!urlOfferId} />
      </Container>
    </OffersUIProvider>
  );
};

/**
 * This component grabs the `offerId` from the URL and passes it to the main Offers view.
 * The url is the source of truth for which offer is selected/previewed. This plays a critical role
 * in navigation, sub-routing, and shareability of offers!
 */
export interface IUIRefreshOffersProps {
  suffixUrl?: string;
  currentUrl?: string;
}

export const UIRefreshOffers: React.FC<React.PropsWithChildren<IUIRefreshOffersProps>> = ({
  suffixUrl = '/',
  currentUrl = routes.offers,
}) => {
  const enableReactNativeNavigation = useReactNavigationFlag();

  /**
   * Since ```useRoute``` hook takes params from the current active route
   * in the whole app (native), we use ```useState``` approach to take the first value
   * from ```useRoute```, then we can reuse the screen for list and details.
   */
  const {
    params: { offerEngineId: dynamicOfferEngineId },
  } = useRoute<{ offerEngineId: string | null }>();
  const [staticOfferIdSlug] = useState(() => dynamicOfferEngineId);

  return (
    <UIRefreshOffersView
      urlOfferId={enableReactNativeNavigation ? staticOfferIdSlug : dynamicOfferEngineId}
      suffixUrl={suffixUrl}
      currentUrl={currentUrl}
    />
  );
};
