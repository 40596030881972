import React from 'react';

import { Box, Skeleton, View } from '@rbilabs/universal-components';

const MenuObjectSkeleton = () => (
  <View p="$4">
    <Box p="$8">
      <Skeleton h="$48" w="$48" mb="$3" rounded="full" mx="auto" />
    </Box>
    <Skeleton h="$6" rounded="full" mb="$6" />
    <Skeleton h="$4" rounded="full" mb="$8" width="40%" mx="auto" />
    <Box>
      <Skeleton h="$4" rounded="full" mb="$8" width="30%" />
      <Skeleton.Text lines={2} mb="$8" />
      <Skeleton.Text lines={2} mb="$8" />
      <Skeleton.Text lines={8} mb="$8" />
    </Box>
  </View>
);

export default MenuObjectSkeleton;
