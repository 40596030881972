import { Text, Header as UCLHeader, makeUclComponent } from '@rbilabs/universal-components';

import Picture from 'components/picture';

import theme from './theme';

export const StyledModal = () => null; // TODO: RN - This styled component could not be codemoded. Check manual-work/*.md for guidance

export const Header = UCLHeader.withConfig({
  fontFamily: Styles.fontFamily.brand,
  lineHeight: '2xs',
  textAlign: 'center',
  margin: 0,
  width: '200px',
  letterSpacing: 0.8,
  accessibilityLevel: 1,
  variant: 'headerOne',

  fontSize: {
    md: '4xl',
    base: '3xl',
  },
});

export const ItemText = Header.withConfig({
  textTransform: theme.itemNameTextTransform,

  fontSize: {
    md: 'xl',
    base: 'md',
  },
});

export const Body = Text.withConfig({
  textAlign: 'center',
  marginX: 0,
  marginY: '$2',
});

export const StyledPicture = makeUclComponent(Picture).withConfig({
  height: '$40',
  width: '$40',

  marginX: {
    md: 0,
    base: 0,
  },

  marginY: {
    md: '$6',
    base: '$4',
  },
});
