export enum SanityHeroTextColor {
  White = 'white',
  Black = 'black',
}

export interface ISanityHeroTextProps {
  $sanityTextColor: string | null;
  $defaultTextColor: string;
}

export interface IHeroSuperText {
  $sanityTextColor: string | null;
  $defaultTextColor: string;
}
