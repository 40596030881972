import React, { VFC, useState } from 'react';

import { IItemOptionModifier } from '@rbi-ctg/menu';
import { ListItemGroup } from 'components/list-item-group';
import RadioIndicator from 'components/radio-indicator';
import { useCRMEventsContext } from 'state/crm-events';

import { ModifierListItem } from './modifier-list-item';
import { IModifierTypeUIProps } from './types';
import {
  getSingleSelectedOption,
  logProductModifierCustomization,
  transformSelectedOptionToSelections,
} from './utils';

export const ModifierSingleChoice: VFC<IModifierTypeUIProps> = ({
  modifier,
  selections,
  onSelectionsChange,
}) => {
  const [selectedOptionKey, setSelectedOptionKey] = useState(
    getSingleSelectedOption(selections)?._key
  );

  const { logRBIEvent } = useCRMEventsContext();

  const handleListItemPress = (option: IItemOptionModifier) => {
    setSelectedOptionKey(option._key);
    logProductModifierCustomization(
      option.name?.locale ?? '',
      modifier.name.locale,
      option._key,
      logRBIEvent
    );
    requestAnimationFrame(() => {
      onSelectionsChange(transformSelectedOptionToSelections(option));
    });
  };

  return (
    <ListItemGroup
      testID="modifier-single-choice"
      items={modifier.options.map(option => (
        <ModifierListItem
          key={option._key}
          isListItemClickable
          onListItemClick={() => handleListItemPress(option)}
          content={option}
          controls={
            <RadioIndicator
              isSelected={option._key === selectedOptionKey}
              accessibilityLabel={option.name?.locale}
            />
          }
        />
      ))}
    />
  );
};
