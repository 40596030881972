import { Box } from '@rbilabs/universal-components';

export const LockContainer = Box.withConfig({
  width: '60px',
  height: '60px',
  position: 'absolute',
  alignSelf: 'center',
  top: 0,
  bottom: 0,
  left: 0,
  right: 0,
  borderTopLeftRadius: '100%',
  borderTopRightRadius: '100%',
  borderBottomRightRadius: '100%',
  borderBottomLeftRadius: '100%',
  zIndex: Styles.zIndex.top,
});

export const Lock = Box.withConfig({
  backgroundColor: Styles.color.white,
  width: '31px',
  height: '$5',
  position: 'absolute',
  top: '50%',
  left: '50%',
  marginTop: '-4px',
  marginLeft: '-15px',
  borderTopLeftRadius: 2,
  borderTopRightRadius: 2,
  borderBottomRightRadius: 2,
  borderBottomLeftRadius: 2,
}); /*
TODO: RN - unsupported CSS
Some attributes were not converted.

export const Lock = styled.div`
  background-color: ${Styles.color.white};
  width: 31px;
  height: 20px;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -4px;
  margin-left: -15px;
  border-radius: 2px;
  animation: ${sinkDown} 0.3s 0.85s forwards;
  &:before,
  &:after {
    content: '';
    position: absolute;
    left: 4px;
    animation: ${close} 2s 0.45s linear forwards;
  }
  &:before {
    border-top: 4.5px solid ${Styles.color.white};
    border-left: 4.5px solid ${Styles.color.white};
    border-right: 4.5px solid ${Styles.color.white};
    border-radius: 12px 12px 0 0;
    width: 23px;
    height: 17px;
    top: -22px;
    -webkit-transform-origin: 1px 0;
    -ms-transform-origin: 1px 0;
    transform-origin: 1px 0;
  }
  &:after {
    width: 4px;
    background-color: ${Styles.color.white};
    height: 15px;
    top: -11px;
  }
`;

*/
