import { Box, Header, Text, makeUclComponent } from '@rbilabs/universal-components';

import ActionButton from 'components/action-button';
import Picture from 'components/picture';

import { RewardLock } from '../../../reward-lock';

export const QuestCardContainer = Box.withConfig({
  height: '284px',
  width: '342px',

  _text: {
    textAlign: 'center',
  },

  overflow: 'hidden',
  backgroundColor: Styles.color.white,
  borderTopLeftRadius: Styles.borderRadius,
  borderTopRightRadius: Styles.borderRadius,
  borderBottomRightRadius: Styles.borderRadius,
  borderBottomLeftRadius: Styles.borderRadius,

  // TODO: RN - unsupported CSS
  // boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.1)',

  // TODO: RN - unsupported CSS
  // boxShadow: 'unset',

  // TODO: RN - unsupported CSS
  // scrollSnapAlign: 'unset',
}); /*
TODO: RN - unsupported CSS
Some attributes were not converted.

export const QuestCardContainer = styled.div`
  position: relative;
  height: 17.75rem;
  width: 21.4rem;
  text-align: center;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  overflow: hidden;
  background-color: ${Styles.color.white};
  border-radius: ${Styles.borderRadius};
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.1);
  ${Styles.desktop`
    box-shadow: unset;
    scroll-snap-align: unset;
  `};
`;

*/

export const QuestCardPicture = Picture.withConfig({
  maxHeight: 121,
  width: 'full',
});

export const QuestContent = Box.withConfig({
  // TODO: RN - unsupported CSS
  // display: 'grid',

  // TODO: RN - unsupported CSS
  // gridRowGap: '0.25rem',

  padding: '$4',
});

export const QuestTitle = Header.withConfig({
  fontSize: '2xl',
  lineHeight: 'md',
  fontFamily: Styles.fontFamily.brand,
  color: Styles.color.loyaltyHighlight,
  margin: 0,
  variant: 'headerFour',
});

export const QuestDescription = Text.withConfig({
  fontSize: 'sm',
  lineHeight: 'xs',
  margin: 0,
});

export const QuestExpirationText = Text.withConfig({
  fontSize: 'xs',
  lineHeight: '2xs',
  color: 'blackOpacity.30',
  margin: 0,
});

export const StyledButton = makeUclComponent(ActionButton).withConfig({
  fontSize: 'sm',
  lineHeight: '2xs',
  width: 'fit-content',
  paddingX: '$2.5',
  paddingY: '$1',
  borderTopWidth: 2,
  borderRightWidth: 2,
  borderBottomWidth: 2,
  borderLeftWidth: 2,
  alignSelf: 'center',
});

export const RewardLockContainer = Box.withConfig({});

export const StyledRewardLock = makeUclComponent(RewardLock).withConfig({
  // TODO: RN - unsupported CSS
  // transform: 'scale(0.5)',

  padding: '34px',
  backgroundColor: Styles.color.loyaltyHighlight,
});
