import React, { FC } from 'react';

import OfferRedemptionModal from 'components/offer-redemption-modal';
import { useOffersModal } from 'hooks/use-offers-modal';
import { useOffersContext } from 'state/offers';
import { ILockedOffer } from 'state/offers/hooks/use-locked-offers';
import { getUniqIdForOffer } from 'utils/offers';

import { LockedOffersView } from './locked-offers.view';
import { ILockedOffersProps } from './types';

/**
 *
 * LockedOffers
 *
 */
const LockedOffers: FC<React.PropsWithChildren<ILockedOffersProps>> = () => {
  const offersCtx = useOffersContext();
  const { modalIsOpen, openModal, closeModal } = useOffersModal(offersCtx);
  const { selectOfferById, offersFeedback, loading } = offersCtx;
  const handleOfferPress = (lockedOffer: ILockedOffer) => {
    if (lockedOffer) {
      const uniqOfferId = getUniqIdForOffer(lockedOffer);
      selectOfferById(uniqOfferId);
      openModal();
    }
  };

  const isLoading = loading && !offersFeedback;

  return (
    <>
      <LockedOffersView
        isLoading={isLoading}
        offers={offersCtx.lockedOffers}
        onPress={handleOfferPress}
      />
      <OfferRedemptionModal modalIsOpen={modalIsOpen} closeModal={closeModal} />
    </>
  );
};

export default LockedOffers;
