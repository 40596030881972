import React, { FC } from 'react';

import { useIntl } from 'react-intl';

import { ResponsiveCarouselWrapper } from 'components/responsive-carousel';
import { RewardsCarousel } from 'components/rewards-carousel';
import { routes } from 'utils/routing';

import { IRewardsSectionViewProps } from './types';

export const RewardsSectionView: FC<React.PropsWithChildren<IRewardsSectionViewProps>> = ({
  rewards,
}) => {
  const { formatMessage } = useIntl();
  const header = formatMessage({ id: 'rewards' });
  const rewardsText = formatMessage({ id: 'viewAll' });
  const rewardsPath = routes.loyaltyDashboard;
  const rewardsAccessibilityLabel = formatMessage({ id: 'viewAllRewards' });
  return (
    <ResponsiveCarouselWrapper
      mt="$4"
      header={header}
      linkText={rewardsText}
      linkPath={rewardsPath}
      linkAccessibilityLabel={rewardsAccessibilityLabel}
    >
      <RewardsCarousel rewards={rewards} />
    </ResponsiveCarouselWrapper>
  );
};
