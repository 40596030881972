import { useBreakpointValue } from '@rbilabs/universal-components';

// mobile min-width breakpoint - 0
export function useIsMobileBp(): boolean {
  return useBreakpointValue({
    base: true,
    md: false,
  });
}

// tablet min-width breakpoint - 768px
export function useIsTabletBp(): boolean {
  return useBreakpointValue({
    base: false,
    md: true,
  });
}

// desktop small min-width breakpoint - 962px
export function useIsDesktopBp(): boolean {
  return useBreakpointValue({
    base: false,
    lg: true,
  });
}

// desktop large min-width breakpoint - 1280px
export function useIsLgDesktopBp(): boolean {
  return useBreakpointValue({
    base: false,
    xl: true,
  });
}
