import { shouldPolyfill } from '@formatjs/intl-pluralrules/should-polyfill';

import { LOCALES } from 'state/intl/types';
import logger from 'utils/logger';

import polyfillIntlLocale from './polyfill-intl-locale';

const localeImport = {
  en: () => import('@formatjs/intl-pluralrules/locale-data/en'),
  de: () => import('@formatjs/intl-pluralrules/locale-data/de'),
  fr: () => import('@formatjs/intl-pluralrules/locale-data/fr'),
  it: () => import('@formatjs/intl-pluralrules/locale-data/it'),
};

async function polyfillPluralRulesLocale(locale: string) {
  const supportedLocale = shouldPolyfill(locale);
  // This locale is supported
  if (!supportedLocale) {
    return;
  }
  // Load the polyfill 1st BEFORE loading data
  await import('@formatjs/intl-pluralrules/polyfill-force');
  await localeImport[supportedLocale]();
}

export default async function polyfillPluralRules(locale: LOCALES) {
  try {
    await polyfillIntlLocale();
    // Requires the above polyfills: Intl.getCanonicalLocales or polyfill
    // And Intl.Locale or polyfill.
    await polyfillPluralRulesLocale(locale);
  } catch (e) {
    // @ts-expect-error TS(2345) FIXME: Argument of type 'unknown' is not assignable to pa... Remove this comment to see the full error message
    logger.warn(e);
  }
}
