import { Box, Text } from '@rbilabs/universal-components';

export const RibbonWrapper = Box.withConfig({
  position: 'relative',
  zIndex: Styles.zIndex.max,
});

const RIBBON_COLOR = '#ff8732';
export const RibbonContent = Box.withConfig({
  position: 'relative',
  zIndex: Styles.zIndex.normal,
  backgroundColor: RIBBON_COLOR,
  height: '$5',
});

export const StyledRibbon = Text.withConfig({
  backgroundColor: 'black',
  variant: 'headerFour',
  height: '$4',
  position: 'relative',
  color: '#ffffff',
  textAlign: 'left',
  paddingRight: '$0.5',
  paddingBottom: '$0.5',
  paddingLeft: '$3',
  zIndex: Styles.zIndex.max,
});
