import * as React from 'react';
import { FC } from 'react';

import { useIntl } from 'react-intl';

import { Container, Content, Header } from './loyalty-history-widget.styled';

const LoyaltyHistoryWidgetWrapper: FC<React.PropsWithChildren<unknown>> = ({ children }) => {
  const { formatMessage } = useIntl();

  return (
    <Container testID="history-widget">
      <Content>
        <Header>{formatMessage({ id: 'recentHistory' })}</Header>
        {children}
      </Content>
    </Container>
  );
};

export default LoyaltyHistoryWidgetWrapper;
