import React from 'react';

import { AccountCreationLoadingAnimation } from './animations/loading-animation';
import {
  OptInDialogBody,
  OptInDialogBodyContent,
  OptInDialogBodyText,
  OptInDialogHeader,
  OptInDialogHeading,
} from './styled';
import type { IOptInLoadingContentProps } from './types';

export const OptInLoadingContent = ({ heading, description }: IOptInLoadingContentProps) => {
  return (
    <>
      <AccountCreationLoadingAnimation />

      <OptInDialogHeader>
        <OptInDialogHeading>{heading}</OptInDialogHeading>
      </OptInDialogHeader>

      <OptInDialogBodyContent>
        <OptInDialogBody>
          <OptInDialogBodyText>{description}</OptInDialogBodyText>
        </OptInDialogBody>
      </OptInDialogBodyContent>
    </>
  );
};
