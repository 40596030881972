import React, { FC, useCallback, useState } from 'react';

import { useIntl } from 'react-intl';
import { ScrollView } from 'react-native';

import { IOffer, ISanityVendorConfigs } from '@rbi-ctg/menu';
import ConfirmDialog from 'components/confirm-dialog';
import Modal from 'components/modal';
import { OfferDisclaimer } from 'components/offers/ui-refresh/offer-disclaimer';
import Picture from 'components/picture';
import { VisuallyHidden } from 'components/ucl/visually-hidden';
import { useAndroidBackButton } from 'hooks/use-android-back-button';
import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';
import { LoyaltyOffer } from 'state/loyalty/types';
import AuthStorage from 'utils/cognito/storage';
import { StorageKeys } from 'utils/local-storage';
import noop from 'utils/noop';

import QRCode from '../qrcode';

import { OBJECT_FIT_CONTAIN } from './constants';
import {
  BodyContent,
  CouponTitle,
  Description,
  Directions,
  ModalInner,
  Name,
  PictureHeading,
  QRCodeContainer,
  RedeemInNext,
  ShortCode,
  ShortCodeWrapper,
  StyledCountdown,
  UseCodeAtCashier,
} from './styled';
import { IRedemptionModalProps } from './types';

/**
 * Get rPOS PLU for QR code usage.
 * @param vendorConfig vendor configuration object.
 * @returns string representing rPOS PLU if present; empty string otherwise.
 */
function getPlu(vendorConfig: ISanityVendorConfigs): string {
  const pluType = vendorConfig?.rpos?.pluType;
  if (!pluType) {
    return '';
  }
  return vendorConfig?.rpos?.[pluType] || '';
}

/**
 * Hook to retrieve barcode contents based on various LD flags.
 * @param selectedOffer offer for which barcode contents should be calculated.
 * @returns string to be used as barcode contents.
 */
export function useBarcodeContents(selectedOffer: IOffer | LoyaltyOffer): string {
  const enableMparticleQrCode = useFlag(LaunchDarklyFlag.ENABLE_MPARTICLE_QR_CODE);
  const enablePluOfferQrCode = useFlag(LaunchDarklyFlag.ENABLE_PLU_OFFER_QR_CODE);

  const shortCode = selectedOffer?.shortCode || '';
  const plu = getPlu(selectedOffer.vendorConfigs as ISanityVendorConfigs);

  if (enableMparticleQrCode) {
    const user = AuthStorage.getItem(StorageKeys.USER_AUTH_TOKEN) ?? 0;
    // If no PLU is available, fall back to using the short code.
    const code = plu || shortCode;
    return `BKCP01_${user}_${code}`;
  }

  if (enablePluOfferQrCode) {
    return plu;
  }

  return shortCode;
}

const RedemptionModal: FC<React.PropsWithChildren<IRedemptionModalProps>> = ({
  redemptionStartTime = 0,
  selectedOffer,
  onDismiss = noop,
}) => {
  const [closeConfirmationIsOpen, setCloseConfirmationIsOpen] = useState(false);
  const handleModalDismiss = useCallback(() => {
    setCloseConfirmationIsOpen(true);
  }, []);
  const handleDialogDismiss = useCallback(() => {
    setCloseConfirmationIsOpen(false);
  }, []);
  const { formatMessage } = useIntl();
  const barcodeOptions = {
    margin: 1,
    scale: 6,
    color: {
      dark: '#502314',
      light: '#FFFFFF00',
    },
  };

  const enableOfferQrCode = useFlag(LaunchDarklyFlag.ENABLE_OFFER_QR_CODE);
  const barcodeContents = useBarcodeContents(selectedOffer);
  // TODO: RN - add web support to dismiss on back event
  useAndroidBackButton(handleModalDismiss);
  return (
    <Modal
      onDismiss={handleModalDismiss}
      mParticleEventData={{
        modalAppearanceEventMessage: 'Redeem Offer',
      }}
    >
      <ModalInner testID="redemption-modal">
        <ScrollView>
          <BodyContent>
            <PictureHeading>
              <Picture
                image={selectedOffer?.localizedImage?.locale?.app!}
                alt={selectedOffer?.localizedImage?.locale?.imageDescription! || ''}
                placeholderAspectRatio={400 / 170}
                objectFitContain={OBJECT_FIT_CONTAIN}
              />
            </PictureHeading>
            <CouponTitle>
              <Name content={selectedOffer?.name?.localeRaw} />
            </CouponTitle>
            <Description content={selectedOffer?.description?.localeRaw} />
            <UseCodeAtCashier>{formatMessage({ id: 'useCodeAtCashier' })}</UseCodeAtCashier>
            <ShortCodeWrapper>
              {enableOfferQrCode && (
                <QRCodeContainer>
                  <QRCode
                    testID="redemption-qr-code"
                    barcode={barcodeContents}
                    options={barcodeOptions}
                  />
                </QRCodeContainer>
              )}
              <ShortCode
                qrcode={enableOfferQrCode}
                testID="redemption-short-code"
                aria-describedby="redemption-code-description"
              >
                {selectedOffer.shortCode || '5000'}
              </ShortCode>
              <VisuallyHidden
                testID="redemption-code-description"
                accessibilityLabel={formatMessage({ id: 'redemptionCode' })}
              />
            </ShortCodeWrapper>
            <Directions>
              <RedeemInNext>{formatMessage({ id: 'redeemInNext' })}</RedeemInNext>
              <StyledCountdown
                useClockStyle
                endTime={redemptionStartTime + 60 * 15 * 1000}
                onFinish={onDismiss}
              />
            </Directions>
            <OfferDisclaimer offer={selectedOffer} />
          </BodyContent>
        </ScrollView>
      </ModalInner>
      {closeConfirmationIsOpen && (
        <ConfirmDialog
          showDialog={closeConfirmationIsOpen}
          heading={formatMessage({ id: 'offerConfirmDialogHeading' })}
          body={formatMessage({ id: 'offerConfirmDialogBody' })}
          cancelLabel={formatMessage({ id: 'cancel' })}
          confirmLabel={formatMessage({ id: 'offerConfirmDialogConfirmLabel' })}
          onDismiss={handleDialogDismiss}
          onCancel={handleDialogDismiss}
          onConfirm={onDismiss}
          modalAppearanceEventMessage="Confirm Close Redemption"
        />
      )}
    </Modal>
  );
};

export default RedemptionModal;
