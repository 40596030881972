import React, { useRef, useState } from 'react';

import { AlertDialog, Box, Button, Header, Icon, Radio } from '@rbilabs/universal-components';
import { useIntl } from 'react-intl';

import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';
import { hiddenAccessibilityPlatformProps } from 'utils/accessibility';

import { StaticContentLanguageType, useStaticContentLanguage } from '../static-content-language';

export interface OptionalColorProps {
  color?: string;
}

export const LanguageSelectorWidget: React.FC<React.PropsWithChildren<OptionalColorProps>> = ({
  color = Styles.color.white,
}) => {
  // TODO: leastDestructiveRef is required by AlertDialog props but causes UI freeze when set to a real ref...
  const leastDestructiveRef = useRef(null);
  const { formatMessage } = useIntl();
  const { staticContentLanguage: language, setStaticContentLanguage } = useStaticContentLanguage();
  const isLocalizationDisabled = useFlag(LaunchDarklyFlag.DISABLE_LOCALIZATION);
  const [showLanguageSelectorModal, setShowLanguageSelectorModal] = useState(false);
  const [formLanguageValue, setFormLanguageValue] = useState<StaticContentLanguageType>(language);

  const onModalDismiss = () => {
    setShowLanguageSelectorModal(false);
  };

  const onModalShow = () => {
    setShowLanguageSelectorModal(true);
  };

  return isLocalizationDisabled ? null : (
    <>
      <Button
        width="100%"
        testID="language-selector-button"
        variant="ghost"
        justifyContent="center"
        onPress={onModalShow}
        leftIcon={<Icon variant="language" {...hiddenAccessibilityPlatformProps} />}
        rightIcon={<Icon variant="select" {...hiddenAccessibilityPlatformProps} />}
        accessibilityLabel={formatMessage({ id: 'languageAndRegionSelector' })}
      >
        {formatMessage({ id: { en: 'english', fr: 'french' }[language] })}
      </Button>
      {showLanguageSelectorModal && (
        <AlertDialog
          closeOnOverlayClick
          isOpen
          leastDestructiveRef={leastDestructiveRef}
          onClose={onModalDismiss}
        >
          <AlertDialog.Content>
            <AlertDialog.Header>
              <Header variant="headerTwo" marginX="auto">
                {formatMessage({ id: 'selectLanguage' })}
              </Header>
            </AlertDialog.Header>
            <AlertDialog.Body>
              <Radio.Group
                name="language"
                value={formLanguageValue}
                onChange={val => setFormLanguageValue(val as StaticContentLanguageType)}
              >
                <Box marginX="auto">
                  <Radio value="en">{formatMessage({ id: 'english' })}</Radio>
                  <Radio value="fr">{formatMessage({ id: 'french' })}</Radio>
                </Box>
              </Radio.Group>
            </AlertDialog.Body>
            <AlertDialog.Footer>
              <Button
                size="sm"
                width="100%"
                onPress={() => {
                  setStaticContentLanguage(formLanguageValue);
                  onModalDismiss();
                }}
              >
                {formatMessage({ id: 'apply' })}
              </Button>
            </AlertDialog.Footer>
          </AlertDialog.Content>
        </AlertDialog>
      )}
    </>
  );
};
