import React, { useState } from 'react';

import {
  Button,
  ButtonProps,
  Icon,
  IconProps,
  makeUclComponent,
} from '@rbilabs/universal-components';
import { useIntl } from 'react-intl';

import { RotateAnimatedView } from 'components/ucl';
import { theme } from 'styles/configure-theme';

import { NO_BACKGROUND } from './constants';

export type CloseButtonIconProps = 'cancel' | 'back';

type CloseButtonProps = ButtonProps & {
  onClick?: ButtonProps['onPress'];
  inverted?: boolean;
  noBackground?: boolean;
  _svg?: IconProps;
  icon?: CloseButtonIconProps;
};

const StyledButton = Button.withConfig<{ noBackground?: boolean; inverted?: boolean }>(p => ({
  background: p.noBackground ? 'transparent' : p.inverted ? Styles.color.black : Styles.color.white,
  borderRadius: 'full',
  height: '$9',
  width: '$9',
}));

const StyledIcon = Icon.withConfig<{ inverted?: boolean }>(p => ({
  color: p.inverted ? theme.token('text-button-primary') : Styles.color.black,
  ...p,
}));

const CloseButton = ({
  onClick,
  inverted = false,
  noBackground = NO_BACKGROUND,
  _svg,
  icon = 'cancel',
  ...props
}: CloseButtonProps) => {
  const { formatMessage } = useIntl();
  const [isRotated, setIsRotated] = useState(false);
  return (
    <RotateAnimatedView isRotated={isRotated} toDeg={90}>
      <StyledButton
        variant={inverted ? 'ghost-reversed' : 'ghost'}
        onPress={onClick}
        onPressIn={() => setIsRotated(true)}
        onPressOut={() => setIsRotated(false)}
        onHoverIn={() => setIsRotated(true)}
        onHoverOut={() => setIsRotated(false)}
        noBackground={noBackground}
        testID="close-button"
        accessibilityLabel={formatMessage({ id: 'close' })}
        inverted={inverted}
        {...props}
        _pressed={{
          style: { transform: [{ scale: 0.9 }] },
        }}
      >
        <StyledIcon variant={icon} inverted={inverted} {..._svg} accessibilityElementsHidden />
      </StyledButton>
    </RotateAnimatedView>
  );
};

export default makeUclComponent(CloseButton);
