import React, { useCallback, useState } from 'react';

import delv from 'dlv';
import { useIntl } from 'react-intl';

import { IOffer } from '@rbi-ctg/menu';
import ConfirmDialog from 'components/confirm-dialog';
import AuthRequired from 'pages/authentication/auth-required';
import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';
import { OfferAvailableRedemptionMethods } from 'state/launchdarkly/variations';
import { RedemptionMethod, useOffersContext } from 'state/offers';
import { ILockedOffer } from 'state/offers/hooks/use-locked-offers';
import { UIPattern } from 'state/offers/types';
import { HapticsNotificationType, hapticNotification } from 'utils/haptic';
import { allowsUnauthenticatedRedemption } from 'utils/offers';

import RedemptionLockedOfferModal from './redemption-locked-offer-dialog';
import RedemptionModal from './redemption-modal';

interface IOfferRedemptionModalProps {
  modalIsOpen: boolean;
  closeModal(): void;
}

const OfferRedemptionModal = ({ closeModal, modalIsOpen }: IOfferRedemptionModalProps) => {
  const [redemptionConfirmed, setRedemptionConfirmed] = useState(false);
  const [redemptionStartTime, setRedemptionStartTime] = useState(0);
  const [offerToRedeem, setOfferToRedeem] = useState<IOffer | null>(null);
  const {
    onCancelRedemption,
    onConfirmRedemption,
    redemptionMethod,
    selectedOffer,
  } = useOffersContext();
  const enableOfferRedemptionMethods = useFlag(LaunchDarklyFlag.ENABLE_OFFER_REDEMPTION_METHODS);
  const ruleSet = delv(offerToRedeem!, 'ruleSet');
  const { formatMessage } = useIntl();
  const heading = formatMessage({ id: 'confirmDialogHeader' }, { br: <br /> });
  const body = formatMessage({ id: 'confirmDialogBody' });

  const redemptionMethodsDisabled =
    enableOfferRedemptionMethods === OfferAvailableRedemptionMethods.DISABLE_ALL;
  const inRestaurantOffersDisabled =
    redemptionMethodsDisabled ||
    enableOfferRedemptionMethods === OfferAvailableRedemptionMethods.ENABLE_MOBILE_ONLY;

  const onConfirmRestaurantRedemption = useCallback(async () => {
    // @todo handle error and do not set redemption confirmed true
    // when onConfirmRedemption fails
    const confirmedOffer = selectedOffer!;
    await onConfirmRedemption(confirmedOffer);
    setRedemptionConfirmed(true);
    setRedemptionStartTime(Date.now());
    setOfferToRedeem(confirmedOffer);
  }, [onConfirmRedemption, selectedOffer]);

  const onCompleteRestaurantRedemption = useCallback(() => {
    closeModal();
    setRedemptionConfirmed(false);
    setOfferToRedeem(null);
  }, [closeModal]);

  // Check if offer requires "Log in" and optionally wrap with <AuthRequired>
  const offerRequiresLogin = !allowsUnauthenticatedRedemption(
    (selectedOffer && selectedOffer.ruleSet) || ruleSet
  );

  const OptionalAuth = offerRequiresLogin ? AuthRequired : React.Fragment;

  const onPreRedemptionModalsDismiss = () => {
    onCancelRedemption();
    closeModal();
  };

  if (!modalIsOpen) {
    return null;
  }

  const lockedOfferPatterns = [UIPattern.STEPPER, UIPattern.LIST];
  if (lockedOfferPatterns.find(pattern => pattern === selectedOffer?.uiPattern)) {
    return (
      <RedemptionLockedOfferModal offer={selectedOffer as ILockedOffer} onDismiss={closeModal} />
    );
  }

  if (offerToRedeem && redemptionConfirmed) {
    return (
      <OptionalAuth>
        <RedemptionModal
          onDismiss={onCompleteRestaurantRedemption}
          redemptionStartTime={redemptionStartTime}
          selectedOffer={offerToRedeem}
        />
      </OptionalAuth>
    );
  }

  // exit if in restaurant offers aren't enabled
  if (redemptionMethod === RedemptionMethod.mobile || inRestaurantOffersDisabled) {
    return null;
  }

  return (
    <OptionalAuth>
      <ConfirmDialog
        showDialog={modalIsOpen}
        body={body}
        confirmLabel={formatMessage({ id: 'redeem' })}
        headingComponent={heading}
        onCancel={onPreRedemptionModalsDismiss}
        onConfirm={() => {
          hapticNotification({ type: HapticsNotificationType.SUCCESS });
          onConfirmRestaurantRedemption();
        }}
        onDismiss={onPreRedemptionModalsDismiss}
        modalAppearanceEventMessage="Confirmation: User in restaurant to redeem offer"
      />
    </OptionalAuth>
  );
};

export default OfferRedemptionModal;
