import React from 'react';

import { Icon } from '@rbilabs/universal-components';

import { InputWithButton } from 'components/input-with-button';
import { routes } from 'utils/routing';
import { getFirstStringInLocaleBlockContent } from 'utils/sanity';

import {
  Container,
  Description,
  ErrorMessage,
  IconDescription,
  Label,
  PromoLink,
} from './promo-code-offer.styled';
import { IPromoCodeOfferViewProps } from './types';

const PromoCodeOfferView: React.FC<React.PropsWithChildren<IPromoCodeOfferViewProps>> = ({
  errorMessageId,
  isLoading,
  messages,
  offer,
  code,
  onSubmit,
  onChangeInput,
}) => {
  const description = getFirstStringInLocaleBlockContent(
    offer?.promoCodePanel?.promoCodeDescription
  );
  const label = getFirstStringInLocaleBlockContent(offer?.promoCodePanel?.promoCodeLabel);
  const link = offer?.promoCodePanel?.promoCodeLink || routes.base;

  return (
    <Container testID="promo-code-offer-container">
      {description && (
        <IconDescription>
          <Icon mr="5px" variant="locked" color={Styles.color.black} />
          <Description>{description}</Description>
        </IconDescription>
      )}
      <InputWithButton
        buttonLabel={messages.buttonText}
        label={messages.inputLabel}
        name="promoCodeOffer"
        value={code}
        onChange={onChangeInput}
        isLoading={isLoading}
        onSubmit={onSubmit}
      />
      {errorMessageId && (
        <ErrorMessage testID="promo-code-offer-error-message">{messages.errorMessage}</ErrorMessage>
      )}
      {link && label && (
        <>
          <Label>{messages.noPromoCodeText}</Label>
          <PromoLink href={link}>{label}</PromoLink>
        </>
      )}
    </Container>
  );
};

export default PromoCodeOfferView;
