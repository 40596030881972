import React, { createContext, useCallback, useContext, useRef } from 'react';

import { ScrollView } from 'react-native';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';

import { IBaseProps } from '@rbi-ctg/frontend';

type ScrollViewInstance = ScrollView | KeyboardAwareScrollView | null;

const isKeyboardAwareScrollViewInstance = (
  val: ScrollViewInstance
): val is KeyboardAwareScrollView => !!(val as KeyboardAwareScrollView)?.scrollToFocusedInput;

export interface IScrollContext {
  scrollRef: React.MutableRefObject<ScrollViewInstance>;
  scrollTo: ScrollView['scrollTo'];
}

export const ScrollContext = createContext<IScrollContext>({} as IScrollContext);
export const useScrollContext = () => useContext(ScrollContext);

export default ScrollContext.Consumer;

export const ScrollProvider = ({ children }: IBaseProps) => {
  const scrollRef = useRef<ScrollViewInstance>(null);

  // If no coordinates given, default scroll to top
  const scrollTo = useCallback(({ x, y, animated } = { x: 0, y: 0, animated: true }) => {
    if (!isKeyboardAwareScrollViewInstance(scrollRef.current)) {
      scrollRef.current?.scrollTo?.({ x, y, animated });
    } else {
      scrollRef.current?.scrollToPosition(x, y, animated);
    }
  }, []);

  return (
    <ScrollContext.Provider
      value={{
        scrollRef,
        scrollTo,
      }}
    >
      {children}
    </ScrollContext.Provider>
  );
};
