import { Box, Text, withConfig } from '@rbilabs/universal-components';

import { ModalHeading } from 'components/modal';

import { modalHeadingStyles } from './constants';

export const ContentContainer = Box.withConfig({
  paddingBottom: '8',
});

export const StyledModalHeading = withConfig(ModalHeading, {
  ...modalHeadingStyles,
});

export const PrimaryText = Text.withConfig({
  marginY: '8',
  marginX: 'auto',
  textAlign: 'center',
});

export const SecondaryText = Text.withConfig({
  textAlign: 'center',
});
