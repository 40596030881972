import { Box, Header, Text } from '@rbilabs/universal-components';

export const Wrapper = Box.withConfig({
  flexDirection: 'column',
  justifyContent: 'center',
});

export const HeaderText = Header.withConfig({
  variant: 'headerTwo',
  textAlign: 'center',
});

export const BodyText = Text.withConfig({
  _web: {
    textAlign: 'center',
    marginY: '$4',
  },
});
