import {
  Box,
  HStack,
  Pressable,
  Text,
  VStack,
  makeUclComponent,
} from '@rbilabs/universal-components';

import ActionButton from 'components/action-button';
import { theme as StylesTheme } from 'styles/configure-theme';

import theme from './theme';
import { BottomServiceVariants } from './types';

//TODO handle colors when background switches
export const mapVariantToColor = (variant: BottomServiceVariants, isButtonTextColor = false) => {
  switch (variant) {
    case BottomServiceVariants.IN_PROGRESS:
      return StylesTheme.token('bsm-in-progress-background-color');
    case BottomServiceVariants.IN_RESTAURANT_LOYALTY:
      return StylesTheme.token('background-contrast-loyalty');
    case BottomServiceVariants.DEFAULT:
    default:
      return isButtonTextColor
        ? StylesTheme.token('text-link-default')
        : StylesTheme.token('bsm-default-background-color');
  }
};

export const mapTextToColor = (variant: BottomServiceVariants) => {
  switch (variant) {
    case BottomServiceVariants.IN_RESTAURANT_LOYALTY:
    case BottomServiceVariants.IN_PROGRESS:
      return StylesTheme.token('text-reversed');
    case BottomServiceVariants.DEFAULT:
    default:
      return StylesTheme.token('text-link-default');
  }
};

export const ButtonContainer = Box.withConfig({
  alignSelf: 'center',
  position: 'relative',
});

export const BadgeContent = Box.withConfig({
  background: theme.badge.color,
  borderRadius: 'full',
  position: 'absolute',
  right: '-5px',
  top: '-5px',
  width: '$5',
  height: '$5',
  _text: {
    textAlign: 'center',
    color: Styles.color.white,
    fontSize: 'xs',
  },
});

export const StyledButton = makeUclComponent(ActionButton).withConfig({
  paddingX: '$5',
  _text: {
    fontWeight: 'bold',
  },
});

export const IconContainer = Box.withConfig<{ variant: BottomServiceVariants }>(p => ({
  width: '$10',
  marginRight: '$1.5',
  alignItems: p.variant === BottomServiceVariants.IN_RESTAURANT_LOYALTY ? 'flex-start' : 'center',
  justifyContent:
    p.variant === BottomServiceVariants.IN_RESTAURANT_LOYALTY ? 'flex-start' : 'center',
}));

export const BSM_HEIGHT = 64;

export const Container = Box.withConfig<{ variant: BottomServiceVariants }>(p => ({
  position: 'relative',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '$2',
  height: `${BSM_HEIGHT}px`,
  background: mapVariantToColor(p.variant),
  shadow: '1',
  _web: {
    cursor: 'pointer',
    // boxShadow won't apply on web unless i put it in "style"
    style: {
      boxShadow: 'rgb(0 0 0 / 10%) 0px 0px 1px 1px inset',
    } as any, // TS doesn't like style being here, any cast to ignore
  },
}));

export const LocationOverlayButton = Pressable.withConfig({
  flex: 1,
  minHeight: 'full',
  zIndex: Styles.zIndex.top,
  flexDirection: 'row',
  _web: {
    cursor: 'pointer',
  },
});

export const BottomServiceWrapper = HStack.withConfig({
  maxWidth: Styles.layout.sectionMaxWidth,
  color: theme.bottomServiceWrapper.color,
  width: 'full',
});

export const HeadingDetailsWrapper = VStack.withConfig({
  flex: 1,
});

export const Heading = Box.withConfig<{ variant: BottomServiceVariants }>(p => ({
  paddingY: '$0',
  paddingX: '$2.5',
  overflow: 'hidden',
  paddingRight: '$3',
  _text: {
    fontFamily: 'heading',
    fontSize: 'md',
    color: mapTextToColor(p.variant),
    textTransform: theme.textTransform,
    numberOfLines: 1,
    fontWeight: 'bold',
  },
  marginRight: '$2.5',
}));

export const Details = Box.withConfig({
  overflow: 'hidden',
  paddingY: '$0',
  paddingX: '$2.5',
  marginRight: '$0.5',
});

export const AddressStyle = Box.withConfig({
  overflow: 'hidden',
  paddingRight: '$3',
  flex: 1,
});

export const Underline = Box.withConfig({
  backgroundColor: theme.underline.color,
  width: 'full',
  height: '$0.5',
});

export const LocationStyle = HStack.withConfig({});

export const OrderStatusText = Text.withConfig<{ variant: BottomServiceVariants }>(p => ({
  color: mapTextToColor(p.variant),
  fontSize: 'md',
  numberOfLines: 1,
}));
