export const INCLUDE_BG_IMG = true;
export const IMG_FIT_CONTAIN = true;
export const OFFER_PICTURE_CONTAIN = true;
export const HEADER_BG_COLOR = Styles.color.black;
export const SELECTED_OFFER_BORDER = Styles.color.primary;
export const REFRESH_BG_COLOR = Styles.color.grey.one;

// When true sets LoadingAnimation component as refresh content on LoyaltyPullToReload.
export const LOADING_ANIMATION_AS_REFRESH_CONTENT = false;
export const SHOULD_DISPLAY_MOBILE_ICON = false;
