import React from 'react';

import { Box, HStack, Text } from '@rbilabs/universal-components';
import { useIntl } from 'react-intl';

import { IAdditionalItemInformation } from '@rbi-ctg/menu';

interface IAdditionalItemInfoProps {
  additionalInfo?: IAdditionalItemInformation;
}

const AdditionalInfoLine = HStack.withConfig({
  alignItems: 'baseline',
});

const StyledText = Text.withConfig({
  fontSize: 'sm',
  fontWeight: 'light',
  marginLeft: 1,
});

export const AdditionalInfo = ({ additionalInfo }: IAdditionalItemInfoProps) => {
  const { formatMessage } = useIntl();

  return (
    <Box>
      {additionalInfo?.additives?.locale ? (
        <AdditionalInfoLine>
          <Text>{formatMessage({ id: 'additionalInfoAdditives' })}: </Text>
          <StyledText>{additionalInfo.additives.locale}</StyledText>
        </AdditionalInfoLine>
      ) : null}
      {additionalInfo?.ingredients?.locale ? (
        <AdditionalInfoLine>
          <Text>{formatMessage({ id: 'additionalInfoIngredients' })}:</Text>
          <StyledText>{additionalInfo.ingredients.locale}</StyledText>
        </AdditionalInfoLine>
      ) : null}
      {additionalInfo?.producerDetails?.locale ? (
        <AdditionalInfoLine>
          <Text>{formatMessage({ id: 'additionalInfoProducerDetails' })}:</Text>
          <StyledText>{additionalInfo.producerDetails.locale}</StyledText>
        </AdditionalInfoLine>
      ) : null}
    </Box>
  );
};
