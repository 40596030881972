import React, { FC } from 'react';

import { FadeInAnimatedView } from 'components/ucl';
import { useAuthContext } from 'state/auth';
import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';
import { useMatch } from 'state/location';
import { useIsLoyaltyEnabled } from 'state/loyalty/hooks/use-is-loyalty-enabled';
import { useLoyaltyUser } from 'state/loyalty/hooks/use-loyalty-user';
import { routes } from 'utils/routing';

import AuthenticatedBanner from './loyalty-banner-authenticated';
import UnauthenticatedBanner from './loyalty-banner-unauthenticated';
import { BannerContainer } from './styled';

const LoyaltyBanner: FC<React.PropsWithChildren<unknown>> = () => {
  const isHome = useMatch(routes.base);
  const loyaltyEnabled = useIsLoyaltyEnabled();
  const { loyaltyUser, loading: loyaltyLoading } = useLoyaltyUser();
  const { loading: authLoading } = useAuthContext();

  const showLoyaltyBannerV2 = useFlag(LaunchDarklyFlag.SHOW_LOYALTY_BANNER_V2);
  const showLoyaltyBannerV2InHome = useFlag(LaunchDarklyFlag.SHOW_LOYALTY_BANNER_V2_IN_HOME);
  const loading = loyaltyLoading || authLoading;

  if (!loyaltyEnabled || loading || !showLoyaltyBannerV2) {
    return null;
  }

  // Hide the loyalty banner v2 if:
  // - The user is authenticated
  // - The user is on the "home" page
  // - The LD flag "show-loyalty-banner-v2-in-home" is false
  if (loyaltyUser && isHome && !showLoyaltyBannerV2InHome) {
    return null;
  }

  return (
    <FadeInAnimatedView isVisible>
      <BannerContainer testID="loyalty-banner">
        {loyaltyUser ? <AuthenticatedBanner /> : <UnauthenticatedBanner />}
      </BannerContainer>
    </FadeInAnimatedView>
  );
};

export default LoyaltyBanner;
