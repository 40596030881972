import React, { FC, useCallback } from 'react';

import { Box } from '@rbilabs/universal-components';
import { useIntl } from 'react-intl';
import { GestureResponderEvent } from 'react-native';

import { ISanityBlockContent, ISanityTypographyBlock } from '@rbi-ctg/menu';
import ActionButton, { ActionButtonSizes, ActionButtonVariants } from 'components/action-button';
import { TypographyBlock } from 'components/features/components/typography-block';
import Link from 'components/link';
import Picture from 'components/picture';
import { IImageFragment } from 'generated/sanity-graphql';
import { EventName, emitEvent } from 'utils/event-hub';
import noop from 'utils/noop';

import defaultConfig from './config';
import { ButtonContainer, Content, Wrapper } from './styles';
import { Direction, HorizontalPosition, TileSize } from './types';

export type ModalPayloadType = { heading: string; content: ISanityBlockContent[] };
export type OnShowModalType = (data: ModalPayloadType) => void;

export interface IMarketingTileProps {
  /**
   * A unique identifier used for click tracking
   */
  id: string;
  /**
   * Text Content
   */
  typographyBlock?: ISanityTypographyBlock[] | null;
  /**
   * Text color, current options are `white` and `black`
   */
  textColor?: string;
  /**
   * Text horizontal position
   */
  textPosition?: HorizontalPosition | null;
  /**
   * The Size of the marketing tile. Mobile always used the `TileSize.HALF`
   * Desktop can use both `TileSize.HALF` and `TileSize.FULL`
   */
  tileSize?: TileSize;
  /**
   * The image to be used in the background.
   * if `hasLockedAspectRatio === true` the image will not be cropped. Instead the height will be adjusted.
   * if `hasLockedAspectRatio === false` the image will be cropped.
   *   A panoramic image will be used if `TileSize.FULL`
   *   A center cropped image will be used if  `TileSize.FULL`
   */
  backgroundImage?: IImageFragment | null;
  /**
   * Text that describes background image.
   */
  backgroundImageDescription?: string | null;
  /**
   * The text for the button. If the value is an empty and the `buttonUrl` contains a link
   * the button will be hidden, and the entire marketing tile will be clickable
   */
  buttonText: string;
  /**
   * A URL for the button.
   */
  buttonUrl: string;
  /**
   * The buttons horizontal position
   */
  buttonPosition: HorizontalPosition;
  /**
   * Button type. This values is set to the brand specific theme file,
   * but can be overridden here
   */
  buttonVariant?: ActionButtonVariants;
  /**
   * The text to be used for the terms button
   */
  termsButtonText?: string;
  /**
   * The URL to be used for the terms button if one is not provided and
   * terms text is, the terms will be shown in a modal
   */
  termsButtonUrl?: string;
  /**
   * The terms an conditions that will appear in the modal
   */
  termsText?: ISanityBlockContent[];
  /**
   * The URL to be used for the terms button if one is not provided and
   * terms text is, the terms will be shown in a modal
   */
  onShowTermsModal?: OnShowModalType;
  /**
   * Should the marketing tile have a box shadow
   */
  hasBoxShadow?: boolean;
  /**
   * Should the marketing tile have a border
   */
  hasBorder?: boolean;
  /**
   * When checked the marketing will resize based on the image provided; no cropping will occur.
   */
  hasLockedAspectRatio?: boolean;
  /**
   * When true, the marketingTile will have 0.5rem padding for all sides. This is needed for marketing tiles on the offers page.
   */
  additionalPadding?: string;
}

export const MarketingTile: FC<React.PropsWithChildren<IMarketingTileProps>> = ({
  id,
  buttonUrl,
  buttonText,
  buttonPosition = HorizontalPosition.LEFT,
  backgroundImage,
  backgroundImageDescription,
  tileSize = TileSize.FULL,
  typographyBlock,
  textPosition = HorizontalPosition.LEFT,
  textColor = 'black',
  termsButtonText,
  termsButtonUrl,
  termsText,
  onShowTermsModal = noop,
  buttonVariant = defaultConfig.buttonVariant,
  hasBoxShadow = defaultConfig.hasBoxShadow,
  hasBorder = defaultConfig.hasBorder,
  // Defaulting to false on undefined, because that was the existing behavior
  // New marketing tiles will the `lockedAspectRatio` attribute set to true
  hasLockedAspectRatio = false,
  additionalPadding = '',
}) => {
  const { formatMessage } = useIntl();

  // If we are displaying the half tile we are only showing a portion of the image, therefore we must
  // request a much larger image.
  //
  // Multiplier:
  // - FULL: 1
  // - HALF: 3.4 == 1200 / 350
  let resolutionMultiplier = TileSize.HALF ? 3.4 : 1;

  let objectFitContain = false;

  if (hasLockedAspectRatio) {
    // The image should not be cropped if the Aspect Ratio is locked
    objectFitContain = true;
    // We don't need a high res image
    resolutionMultiplier = 1;
  }

  // Only show the button if there is button text
  const isShowingButton = !!buttonText;
  // Terms is link it there is a terms button url
  // check for empty string
  const isTermsALink = !!termsButtonUrl && termsButtonUrl?.replace(' ', '')?.length > 0;
  // Terms is modal if there is not terms button url but there is terms text
  const isTermsAModal = !isTermsALink && !!termsText;
  // we must have button text, and either terms text or a terms URL
  const isShowingTermsLink = isTermsAModal || isTermsALink;
  const isShowingTwoButtons = isShowingButton && isShowingTermsLink;

  const actionButton = isShowingButton && (
    <Box>
      <Link linkPath={buttonUrl}>
        <ActionButton variant={buttonVariant} size={ActionButtonSizes.SMALL}>
          {buttonText}
        </ActionButton>
      </Link>
    </Box>
  );

  // default to "Terms and Conditions"
  const termsButtonTextDisplay = termsButtonText || formatMessage({ id: 'termsConditions' });
  const onTermsPress = useCallback(
    (e: GestureResponderEvent) => {
      // The terms button is wrapped in a clickable container.
      // Prevent the click from bubbling up.
      e.stopPropagation();

      // If the intent is open a modal
      if (isTermsAModal) {
        onShowTermsModal({ heading: termsButtonTextDisplay, content: termsText || [] });
        e.preventDefault();
      }
    },
    [isTermsAModal, onShowTermsModal, termsButtonTextDisplay, termsText]
  );

  // TODO: we should NOT use the styled component once we fix themeBK branding colors
  const termsButton = isShowingTermsLink && (
    <Box>
      {/* @ts-expect-error TS(2322) FIXME: Type '{ children: Element; linkPath: string; onPre... Remove this comment to see the full error message */}
      <Link linkPath={termsButtonUrl || ''} onPress={onTermsPress}>
        <ActionButton
          variant={ActionButtonVariants.TEXT_ONLY}
          size={ActionButtonSizes.SMALL}
          flexDirection="row"
          _text={{
            color: Styles.color[textColor] || Styles.color.black,
            letterSpacing: 'md',
            fontFamily: 'body',
          }}
          _focus={{
            borderStyle: undefined,
          }}
          _web={{
            _hover: {
              background: 'transparent',
              color: Styles.color[textColor] || Styles.color.black,
            },
          }}
        >
          {`*${termsButtonTextDisplay}`}
        </ActionButton>
      </Link>
    </Box>
  );

  // Button Positions
  // Default:
  //   Button -> Left
  //   Terms -> Right
  let direction = Direction.DEFAULT;

  // If the horizontal position of the button is set to center,
  // and we showing both buttons, then override the position to Button right
  if (isShowingTwoButtons && buttonPosition === HorizontalPosition.CENTER) {
    direction = Direction.DEFAULT;
  }
  // If both button are visible Flip them so that the button is on the right side
  if (isShowingTwoButtons && buttonPosition === HorizontalPosition.RIGHT) {
    direction = Direction.REVERSE;
  }

  const buttonContainer = (
    <ButtonContainer
      buttonPosition={buttonPosition}
      $direction={direction}
      isShowingTwoButtons={isShowingTwoButtons}
    >
      {isShowingButton && actionButton}
      {isShowingTermsLink && termsButton}
    </ButtonContainer>
  );

  const content = (
    <Content tileSize={tileSize}>
      {typographyBlock && (
        <TypographyBlock
          content={typographyBlock}
          color={Styles.color[textColor]}
          horizontalPosition={textPosition || HorizontalPosition.LEFT}
        />
      )}
      {buttonContainer}
    </Content>
  );

  return (
    <Link linkPath={buttonUrl}>
      <Wrapper
        tileSize={tileSize}
        hasBoxShadow={hasBoxShadow}
        hasBorder={hasBorder}
        lockedAspectRatio={hasLockedAspectRatio}
        additionalPadding={additionalPadding}
      >
        {backgroundImage ? (
          <Picture
            image={backgroundImage}
            alt={backgroundImageDescription ?? ''}
            objectFitContain={objectFitContain}
            lockedAspectRatio
            resolutionMultiplier={resolutionMultiplier}
            onImageLoaded={() => {
              emitEvent(EventName.MARKETING_IMAGE_LOADED);
            }}
          >
            {content}
          </Picture>
        ) : (
          content
        )}
      </Wrapper>
    </Link>
  );
};

export default MarketingTile;
