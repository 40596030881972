import React from 'react';

import { TopServiceModeNotification } from 'components/top-service-mode-notification';

export function CustomBottomTabBar() {
  return (
    <>
      <TopServiceModeNotification />
    </>
  );
}
