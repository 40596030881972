/**
 * Module to add functions that evaluates the Quest object
 */
import {
  ILoyaltyQuestCompletion,
  LoyaltyQuestStatus,
  LoyaltyQuestStepStatus,
} from 'generated/graphql-gateway';

export const isQuestCompleted = (questStatus: LoyaltyQuestStatus) =>
  questStatus === LoyaltyQuestStatus.COMPLETED;
export const isQuestActivated = (questStatus: LoyaltyQuestStatus) =>
  questStatus === LoyaltyQuestStatus.ACTIVATED;
export const canActivateQuest = (questStatus: LoyaltyQuestStatus) =>
  questStatus === LoyaltyQuestStatus.NOT_ENROLLED;
export const isStepCompleted = (status: LoyaltyQuestStepStatus) =>
  status === LoyaltyQuestStepStatus.COMPLETED;
export const isIncentiveRedeemed = (lastCompletion: ILoyaltyQuestCompletion | null) =>
  !!lastCompletion?.redeemed;
