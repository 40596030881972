import React, { useCallback } from 'react';

import { Image } from 'react-native';

import type { LocalizedTab } from 'navigation/tab-navigator/tabs/types';
import { useScrollContext } from 'state/scroll';
import { hiddenAccessibilityPlatformProps } from 'utils/accessibility';
import { EventName, emitEvent } from 'utils/event-hub';
import { routes } from 'utils/routing';

import { NAV_BAR_ICON_SIZE } from './constants';
import { NavLink } from './helpers';
import { IconWrap, NavLinkText } from './styled';

type BottomNavMobileTabProps = {
  componentKey: string;
  tab: LocalizedTab;
};

export const BottomNavMobileTab = ({ componentKey, tab }: BottomNavMobileTabProps) => {
  const requiresExactMatch = tab.link === routes.base || tab.link === routes.account;

  const { scrollTo } = useScrollContext();

  const onPress = useCallback(
    (clickRoute: string, isActive: boolean) => {
      emitEvent(EventName.BOTTOM_TAB_PRESSED, {
        route: clickRoute,
      });
      if (isActive) {
        // whenever a mobile nav button is pressed when the route already matches,
        // scroll to the top of the view
        scrollTo({ animated: false });
      }
    },
    [scrollTo]
  );

  return (
    <NavLink
      to={tab.link}
      requiresExactMatch={requiresExactMatch}
      testID={`nav-with-picture-icon-${tab.text.split(' ').join('-')}`}
      onClick={onPress}
      component={(isActive: boolean) => (
        <>
          <IconWrap>
            <Image
              source={isActive ? tab.iconActiveSource : tab.iconSource}
              resizeMode="contain"
              style={{
                height: NAV_BAR_ICON_SIZE,
                width: NAV_BAR_ICON_SIZE,
              }}
              {...hiddenAccessibilityPlatformProps}
            />
          </IconWrap>
          <NavLinkText variant="copyTwo" $isActive={isActive} fontSize="xs" marginTop={1}>
            {tab.text}
          </NavLinkText>
        </>
      )}
      navBarText={tab.text}
      componentKey={componentKey}
    />
  );
};
