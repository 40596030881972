import { Box, Header } from '@rbilabs/universal-components';

export const headerHeight = {
  desktop: '84px',
  mobile: '20',
};

export const HeaderContainer = Box.withConfig({
  justifyContent: 'center',
  alignItems: 'flex-start',
  width: 'full',
  height: {
    base: headerHeight.mobile,
    md: headerHeight.desktop,
  },
  paddingY: 0,
  paddingX: {
    base: '18px',
    md: '5',
  },
  backgroundColor: Styles.color.black,
});

export const HeaderText = Header.withConfig({
  accessibilityLevel: 1,
  marginBottom: '5px',
  color: Styles.color.white,
  textTransform: Styles.textTransform.headlines,
  letterSpacing: '-0.53px',
  lineHeight: 0,
  fontSize: {
    base: '3xl',
    md: '4xl',
  },
  fontWeight: 'normal',
});
