import { theme } from 'styles/configure-theme';

import { SanityHeroTextColor } from './types';

export const getColorForSanityHeroTextColor = (
  $sanityColor: string | null,
  $defaultColor: string
) => {
  if (!$sanityColor) {
    return $defaultColor;
  }

  return $sanityColor === SanityHeroTextColor.White
    ? theme.token('text-reversed')
    : theme.token('text-default');
};
