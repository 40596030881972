import { useCallback, useState } from 'react';

import { OffersValue } from 'state/offers';
import { offerMatchesUniqID } from 'utils/offers';

import useDialogModal from './use-dialog-modal';

export const useOffersModal = (offers: OffersValue) => {
  const { clearSelectedOffer, selectOfferById, selectedOffer, setRedemptionMethod } = offers;

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const openModal = useCallback(() => setModalIsOpen(true), []);
  const closeModal = useCallback(() => setModalIsOpen(false), []);

  const selectOffer = useCallback(
    (id: string) => {
      selectOfferById(id);
      setRedemptionMethod(null);
      openModal();
    },
    [openModal, selectOfferById, setRedemptionMethod]
  );

  const [Confirm, openConfirm] = useDialogModal<{ id: string }>({
    onConfirm: modalState => {
      if (modalState) {
        clearSelectedOffer();
        selectOffer(modalState.id);
      }
    },
    showCancel: true,
    modalAppearanceEventMessage: 'Confirmation: Select new offer',
  });

  const makeOfferClickHandler = useCallback(
    (id: string) => () => {
      if (selectedOffer && !offerMatchesUniqID(id, selectedOffer)) {
        openConfirm({ id });
      } else {
        selectOffer(id);
      }
    },
    [selectedOffer, selectOffer, openConfirm]
  );

  return {
    Confirm,
    modalIsOpen,
    openModal,
    closeModal,
    makeOfferClickHandler,
  };
};
