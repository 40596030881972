import {
  LinksContainer as BaseLinksContainer,
  StyledLink as BaseStyledLink,
} from './loyalty-links-widget.styled.base';

export const LinksContainer = BaseLinksContainer.withConfig({
  marginBottom: {
    base: '$6',
    md: '$10',
  },
  borderColor: Styles.color.black,
});

export const StyledLink = BaseStyledLink.withConfig({
  paddingY: {
    base: '$5',
    md: '$8',
  },
  borderColor: Styles.color.black,
  _text: {
    fontFamily: 'heading',
  },
});
