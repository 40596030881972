import { IDiscount } from '@rbi-ctg/menu';
import { DiscountTypes } from 'enums/menu';
import { ITotals } from 'pages/cart/your-cart/totals/types';
import { selectors, useAppSelector } from 'state/global-state';
import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';
import { useIsLoyaltyEnabled } from 'state/loyalty/hooks/use-is-loyalty-enabled';
import { OrderStatus, useOrderContext } from 'state/order';
import { computeOtherDiscountAmount, getLoyaltyRewardsDiscount } from 'utils/cart/helper';
import { isDelivery } from 'utils/service-mode';

export const useTotalTipCalculations = ({ serverOrder, serviceMode }: ITotals) => {
  const orderContext = useOrderContext();
  const loyaltyEnabled = useIsLoyaltyEnabled();
  const appliedLoyaltyRewards = useAppSelector(selectors.loyalty.selectAppliedLoyaltyRewards);
  const isPricingRewardApplication = useAppSelector(
    selectors.loyalty.selectIsPricingRewardApplication
  );

  const loading = !serverOrder || serverOrder.status === OrderStatus.PRICE_REQUESTED;
  const hideTipAmount = useFlag(LaunchDarklyFlag.HIDE_TIP_AMOUNT_CHECKOUT);
  const isDeliveryOrder = isDelivery(serviceMode);

  const { cart, delivery } = serverOrder || {};
  const deliveryFee = delivery ? delivery.feeCents : null;
  const deliveryFeeDiscount = delivery ? delivery.feeDiscountCents : null;
  const allDiscounts = cart?.discounts || [];
  const posRewardDiscount = () => {
    return allDiscounts.find(
      (discount: IDiscount) => discount.name === DiscountTypes.REWARDS_DISCOUNTS
    )?.value;
  };
  const otherDiscountAmount = computeOtherDiscountAmount(allDiscounts);

  // delivery.tipCents is null until the user advances to PAYMENT_REQUESTED
  // at the same time, totalCents increases by the tip amount
  // we needed to do this to render the correct grand total/tip as user advances
  const serverOrderTip = Number(delivery?.tipCents || 0);

  // currently not all POS vendors return discount amount in cart response when offers are applied
  // for loyalty rewards with loyaltyEnabled = true, the discount amount is calculated in the FE
  // otherwise it is returned from the POS system
  const rewardDiscountAmount =
    loyaltyEnabled && !isPricingRewardApplication
      ? getLoyaltyRewardsDiscount({
          cartEntries: orderContext.cartEntries,
          loyaltyEnabled,
          appliedLoyaltyRewards,
        })
      : posRewardDiscount();

  let additionalDiscountAmount = otherDiscountAmount;

  // subtract loyalty reward amount for posVendors that return "otherDiscountAmount" e.g. carrols
  if (
    loyaltyEnabled &&
    rewardDiscountAmount &&
    additionalDiscountAmount &&
    additionalDiscountAmount >= rewardDiscountAmount
  ) {
    additionalDiscountAmount -= rewardDiscountAmount;
  }

  const { subTotalCents, taxCents } = cart || {};

  return {
    hideTipAmount,
    subTotalCents,
    taxCents,
    rewardDiscountAmount,
    serverOrderTip,
    additionalDiscountAmount,
    deliveryFee,
    deliveryFeeDiscount,
    loading,
    isDeliveryOrder,
    cart,
    delivery,
  };
};
