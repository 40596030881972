import React from 'react';

import { Link as UCLLink, makeUclComponent } from '@rbilabs/universal-components';

import Link from 'components/link';

interface IHeroLinkProps {
  to: string;
  fullWidth?: boolean;
  [key: string]: any;
}

const UnStyledLink = makeUclComponent(Link).withConfig<IHeroLinkProps>(p => ({
  flexDirection: 'row',
  alignItems: 'center',
  width: p.fullWidth ? '100%' : 'auto',
  isUnderlined: false,
}));

const UnStyledA = UCLLink.withConfig<{ fullWidth: boolean }>(p => ({
  flexDirection: 'row',
  alignItems: 'center',
  width: p.fullWidth ? '100%' : 'auto',
  isUnderlined: false,
}));

const HeroLink: React.FC<React.PropsWithChildren<IHeroLinkProps>> = ({
  to,
  fullWidth = false,
  children,
  ...rest
}) => {
  if (to.startsWith('/')) {
    return (
      <UnStyledLink fullWidth={fullWidth} to={to} {...rest}>
        {children}
      </UnStyledLink>
    );
  }
  return (
    <UnStyledA fullWidth={fullWidth} href={to} {...rest}>
      {children}
    </UnStyledA>
  );
};

export default HeroLink;
