import { IBaseItem, ICartEntry } from '@rbi-ctg/menu';
import { IRecommendationProducts } from 'generated/graphql-gateway';
import { CartEntryType, ComboSlotUiPattern } from 'utils/cart';

export const existsInEntries = (item: IBaseItem, entries: ICartEntry[]): boolean =>
  entries.some(
    (entry: ICartEntry) =>
      item._id === entry._id ||
      (entry.children.length ? existsInEntries(item, entry.children) : false)
  );

export function getUpsellProduct(entries: ICartEntry[], multiplier = 1): IRecommendationProducts[] {
  let products: IRecommendationProducts[] = [];
  entries.forEach((entry: ICartEntry) => {
    if (!entry || entry.uiPattern === ComboSlotUiPattern.HIDDEN) {
      return;
    } else if (entry.type === CartEntryType.item) {
      products.push({
        id: entry._id,
        quantity: entry.quantity * multiplier,
      });
    } else if (entry.children.length) {
      if (entry.type === CartEntryType.comboSlot) {
        products = products.concat(getUpsellProduct(entry.children));
      } else {
        products = products.concat({
          id: entry._id,
          quantity: entry.quantity * multiplier,
          sublevelItems: getUpsellProduct(entry.children),
        });
      }
    }
  });
  return products;
}
