import React, { ReactNode } from 'react';

import { IUserOrderFragment } from 'generated/rbi-graphql';

export interface IBottomServiceModeProps {}

export interface IBottomServiceModeDetail {
  heading: string;
  details: string;
  icon: ReactNode;
  buttonContentText: string;
  buttonContentTextLabel?: string;
  buttonContentIcon?: ReactNode;
  buttonBadgeContentText?: string | number;
  onButtonClick?: () => void;
  onBottomServiceClick?: () => void;
  buttonDisabled?: boolean;
  variant?: BottomServiceVariants;
  CancellationModal: React.FC<React.PropsWithChildren<unknown>>;
  refetchUserOrder: () => void;
  isLocatorUIRefreshEnabled?: boolean;
}

export interface IOrderInProgress {
  recentOrder: IUserOrderFragment | null | undefined;
  fireOrderAhead: number;
  curbsidePickupOrderId: string;
  curbsidePickupOrderTimePlaced: string;
  isDeliveryOrderCompleted: boolean;
}
export interface IOrderInProgressResult {
  headingId: string;
  detailsId: string;
  icon: ReactNode;
  buttonContentTextId: string;
  buttonContentIcon: ReactNode;
  variant: BottomServiceVariants;
}

export interface IBottomServiceModeViewProps
  extends Omit<IBottomServiceModeDetail, 'CancellationModal' | 'refetchUserOrder'> {
  className?: string;
  isLoading?: boolean;
}

export enum BottomServiceVariants {
  DEFAULT = 'DEFAULT',
  IN_PROGRESS = 'IN_PROGRESS',
  NO_STORE = 'NO_STORE',
  IN_RESTAURANT_LOYALTY = 'IN_RESTAURANT_LOYALTY',
}
