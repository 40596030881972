import { useMemo } from 'react';

import { keyBy } from 'lodash-es';

import { IBaseItem, ISanityItem } from '@rbi-ctg/menu';
import { RecommendationType } from 'generated/rbi-graphql';
import { getUpsellProduct } from 'pages/cart/upsell/utils';
import GetAllItemsQuery from 'remote/queries/all-items';
import { useCartContext } from 'state/cart';
import { useSanityQuery } from 'state/graphql';
import { useMainMenuContext } from 'state/main-menu';
import { useMenuUIContext } from 'state/menu-ui';
import { useOffersContext } from 'state/offers';
import { useOrderContext } from 'state/order';

import { IUseUpsell, IUseUpsellQueryHook } from './types';

// Only called when authenticated
export const useUpsell = ({
  isInCart = false,
  storeId,
  useUpsellQuery,
  limit,
}: {
  isInCart?: boolean;
  storeId: string;
  useUpsellQuery: IUseUpsellQueryHook;
  limit?: number | null;
}): IUseUpsell => {
  const { getPricingAndAvailability } = useMainMenuContext();

  const useClosestContext = isInCart ? useCartContext : useOrderContext;
  const { cartEntries, serviceMode } = useClosestContext();

  const { activeCartEntries } = useMenuUIContext();
  const { selectedOfferCartEntry } = useOffersContext();

  // TODO: If we are able to pass the cartEntries by props to this hook we could remove the useMenuUIContext as a whole
  // On the Wizard we should pass cartEntries + current item, on the cart we should only pass cartEntries

  // This logic selects the cart entries that we send to the useUpsellRecommendationsQuery below
  // For this to work in menu, we send activeCartEntries - the item the user is viewing in the shape of a cart item
  // and when we navigate to the cart, we only send what the user has in their cart - cartEntries
  const allCartEntriesAndActiveItem = useMemo(() => {
    const offerMenuCartEntry = selectedOfferCartEntry ? [selectedOfferCartEntry] : [];
    return isInCart
      ? cartEntries.concat(offerMenuCartEntry)
      : cartEntries.concat(offerMenuCartEntry, activeCartEntries);
  }, [activeCartEntries, cartEntries, isInCart, selectedOfferCartEntry]);
  const products = getUpsellProduct(allCartEntriesAndActiveItem);

  // As we add more areas for upsell, we should add more RecommendationTypes
  const recommendationTypeLogic = isInCart ? RecommendationType.CART : RecommendationType.MENU;
  const { recommendationToken, recommender, upsellData, loading: upsellLoading } = useUpsellQuery({
    limit,
    products,
    recommendationTypeLogic,
    serviceMode,
    skip: !products?.length,
    storeId,
  });

  const recommendedItemIds = useMemo(
    () =>
      (upsellData || []).reduce((acc, item) => {
        if (item?.id) {
          acc.push(item.id);
        }
        return acc;
      }, [] as string[]),
    [upsellData]
  );

  const { loading: sanityLoading, data: sanityData } = useSanityQuery<{ allItems: ISanityItem[] }>(
    GetAllItemsQuery,
    {
      variables: {
        where: {
          _id_in: recommendedItemIds,
        },
      },
      skip: !recommendedItemIds.length,
    }
  );
  const sanityItemsMap = keyBy(sanityData?.allItems || [], i => i._id);

  const items = useMemo<IBaseItem[]>(() => {
    const sanityItems: IBaseItem[] = [];

    for (const itemId of recommendedItemIds) {
      const sanityItem = sanityItemsMap[itemId];
      if (sanityItem && getPricingAndAvailability(sanityItem._id)?.isAvailable) {
        sanityItems.push(sanityItem);
      }

      if (limit && limit === sanityItems.length) {
        break;
      }
    }

    return sanityItems;
  }, [getPricingAndAvailability, limit, recommendedItemIds, sanityItemsMap]);

  return {
    items,
    loading: upsellLoading || sanityLoading,
    recommender,
    recommendationToken,
    upsellData,
  };
};
