import React, { PropsWithChildren } from 'react';

import { useRoute } from 'hooks/navigation/use-route';
import { RunningExperiments, useExperiments } from 'state/experiments';
import { useServiceModeContext } from 'state/service-mode';

type Props = PropsWithChildren<{}>;

export function DisableServiceModeForHomePage__Experiment(props: Props) {
  const { serviceMode } = useServiceModeContext();
  const { variant } = useExperiments();
  const { pathname } = useRoute();

  if (
    !serviceMode &&
    pathname === '/' &&
    variant(RunningExperiments.SimpleHomePage, 'treatment', { skipExposureLogging: true })
  ) {
    return null;
  }

  return props.children ? <>{props.children}</> : <></>;
}
