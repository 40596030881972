import { Box, Header } from '@rbilabs/universal-components';

export const ContentWrapper = Box.withConfig({
  width: '100%',
  justifyContent: 'center',
  padding: '$4',
  textAlign: 'center',
});

export const ContentText = Box.withConfig({
  textAlign: 'center',
  marginBottom: '$4',
});

export const ContentHeader = Header.withConfig({
  textAlign: 'center',
});
