import { useMemo } from 'react';

import isNull from 'lodash/isNull';

import { useGetLoyaltyQuestsQuery } from 'generated/graphql-gateway';
import logger from 'utils/logger';

import { createQuest, validateQuest } from './create-quest';
import { IQuest, IUseLoyaltyQuestQuery } from './types';

export const useLoyaltyQuestQuery = ({
  loyaltyId,
}: {
  loyaltyId: string;
}): IUseLoyaltyQuestQuery => {
  const { loading, data, error, refetch } = useGetLoyaltyQuestsQuery({
    fetchPolicy: 'cache-and-network',
    variables: {
      loyaltyId,
    },
    skip: !loyaltyId,
  });

  const loyaltyUserQuests = data?.loyaltyUserV2?.quests;
  const quests = useMemo(() => {
    if (!loyaltyUserQuests) {
      return null;
    }

    // create and validate quests
    // invalid quests should be filtered out
    const questList = loyaltyUserQuests.map(q => {
      const createdQuest = createQuest(q);
      const questErrors = validateQuest(createdQuest);

      if (questErrors.length) {
        logger.warn(`Quest validation ${JSON.stringify(questErrors)}`);
        return null;
      }

      return createdQuest;
    });

    // filter invalid quests
    const result = questList.filter((quest: IQuest | null): quest is IQuest => !isNull(quest));

    return result;
    // stringifying the response to create the quest list only if the response has changed
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(loyaltyUserQuests)]);

  return {
    loadingQuests: loading,
    quests,
    error,
    refetchQuests: refetch,
  };
};
