import * as React from 'react';
import { FC } from 'react';

import { Mutable } from '@rbi-ctg/frontend';
import MainHero from 'components/features/components/main-hero';
import { IHeroMaskCardFragment, IImageFragment, IMainHeroFragment } from 'generated/sanity-graphql';

export const MainHeroContainer: FC<React.PropsWithChildren<{ item: IMainHeroFragment }>> = ({
  item,
}) =>
  item?.image?.locale ? (
    <MainHero
      testID="main-hero-container"
      image={item?.image?.locale}
      mobileImage={item?.mobileImage?.locale as IImageFragment}
      html={item?.mainText?.locale || ''}
      actionTo={item?.action?.actionUrl?.locale || item?.action?.route || ''}
      // @ts-expect-error TS(2322) FIXME: Type 'string | null | undefined' is not assignable... Remove this comment to see the full error message
      heroTextColor={item.textColor}
      heroActionText={item?.action?.actionText?.locale || ''}
      heroSuperText={item?.superText?.locale || ''}
      heroSubText={item?.subText?.locale || ''}
      heroMaskCards={(item.maskCards || []) as Mutable<IHeroMaskCardFragment[]>}
      heroActionLeft={item?.actionLeft?.actionText?.locale || ''}
      heroActionLeftLink={item?.actionLeft?.actionUrl?.locale || item?.actionLeft?.route || ''}
      heroActionLeftIcon={item?.actionLeftIcon?.locale}
      heroActionRight={item?.actionRight?.actionText?.locale || ''}
      heroActionRightLink={item?.actionRight?.actionUrl?.locale || item?.actionRight?.route || ''}
      heroActionRightIcon={item?.actionRightIcon?.locale}
    />
  ) : null;
