import { Box } from '@rbilabs/universal-components';

import Link from 'components/link';
import { WEB_APP_DESKTOP_LG_MAX_WIDTH, WEB_APP_MAX_WIDTH } from 'utils/constants';

export const SectionContainer = Box.withConfig({
  background: {
    base: 'white',
    md: 'transparent',
  },
  width: 'full',
  maxWidth: {
    md: WEB_APP_MAX_WIDTH,
    lg: WEB_APP_DESKTOP_LG_MAX_WIDTH,
  },
  paddingTop: '$6',
  paddingLeft: { base: '$4', md: '$0' },
  marginX: 'auto',
  marginBottom: '$5',
  minHeight: '$4',
});

export const HeaderLink = Link.withConfig({
  marginLeft: '$3',
  marginRight: '$3',
  height: {
    md: 'full',
  },
  justifyContent: {
    md: 'center',
  },
  _text: {
    fontSize: 'sm',
    textTransform: 'capitalize',
  },
  position: { md: 'absolute' },
  right: '0',
});

export const HeaderTextWrapper = Box.withConfig({
  flexDirection: 'row',
  alignItems: 'baseline',
  justifyContent: {
    md: 'center',
    base: 'space-between',
  },
});

export const IconWrapper = Box.withConfig({
  marginLeft: '$2',
});
