import { useEffect } from 'react';

import { useIntl } from 'react-intl';

import { useRoute } from 'hooks/navigation/use-route';
import Account from 'pages/account';

import { AccountTitle, AccountWrapper, IconClose, ModalWrapper, Row } from './styles';

type AccountDrawerPropss = {
  isOpen: boolean;
  onClose: () => void;
};

export const AccountDrawer = ({ isOpen = false, onClose }: AccountDrawerPropss) => {
  const { formatMessage } = useIntl();
  const { pathname } = useRoute();

  useEffect(() => {
    if (isOpen) {
      onClose();
    }
  }, [pathname]);

  return (
    <ModalWrapper isOpen={isOpen} onClose={onClose}>
      <AccountWrapper>
        <Row justifyContent="flex-end" height="$10">
          <IconClose onPress={onClose} />
        </Row>
        <Row justifyContent="center">
          <AccountTitle>{formatMessage({ id: 'account' })}</AccountTitle>
        </Row>
        <Account />
      </AccountWrapper>
    </ModalWrapper>
  );
};
