import React, { VFC, useMemo } from 'react';

import { ProductListItem } from 'components/product-list-item';
import { FallbackImageSvg } from 'components/svgs/fallback-image';
import { MenuObjectTypes } from 'enums/menu';
import { useMenuCalories } from 'hooks/menu/use-menu-calories';

import { IModifierListItemProps } from './types';
import { getModifierImage, getModifierOptionImage } from './utils';

export const ModifierListItem: VFC<IModifierListItemProps> = ({
  controls,
  content,
  isListItemClickable = false,
  onListItemClick,
  selectedOption,
}) => {
  const { formatCalories } = useMenuCalories();

  const image = useMemo(
    () =>
      content._type === MenuObjectTypes.ITEM_OPTION
        ? getModifierImage(content)
        : getModifierOptionImage(content),
    [content]
  );

  const calories = useMemo(
    () =>
      content._type === MenuObjectTypes.ITEM_OPTION
        ? formatCalories(selectedOption?.nutrition?.calories)
        : formatCalories(content.nutrition?.calories),
    [content, formatCalories, selectedOption]
  );

  return (
    <ProductListItem
      isClickable={isListItemClickable}
      onProductClick={onListItemClick}
      productProps={{
        name: content.name?.locale,
        image,
        // @ts-expect-error TS(2322) FIXME: Type '(props: SvgProps) => JSX.Element' is not ass... Remove this comment to see the full error message
        fallbackImage: FallbackImageSvg,
        imageSize: 'small',
        calories,
      }}
      controls={controls}
    />
  );
};
