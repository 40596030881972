import { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react';

import { useIntl } from 'react-intl';

import { useRoute } from 'hooks/navigation/use-route';
import useRoutes from 'hooks/use-routes';
import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';
import { ServiceMode, useServiceModeContext } from 'state/service-mode';
import { isNotNull } from 'utils/is-not-null';
import { routes } from 'utils/routing';
import { ServiceModeCategory } from 'utils/service-mode';

interface IToggleOption {
  value: string;
  label: string;
  disabled?: boolean;
}

export interface IUseServiceModeCategoryResult {
  serviceModeCategory: ServiceModeCategory;
  setServiceModeCategory: Dispatch<SetStateAction<ServiceModeCategory>>;
  serviceModeCategoryOptions: IToggleOption[];
}

export interface IUseServiceModeCategory {
  (): IUseServiceModeCategoryResult;
}

export interface ServiceModeCategoryParams {
  serviceModeCategory: ServiceModeCategory;
}

export const useServiceModeCategory: IUseServiceModeCategory = () => {
  const isCateringEnabled = useFlag(LaunchDarklyFlag.ENABLE_CATERING);
  const isDeliveryEnabled = useFlag(LaunchDarklyFlag.ENABLE_DELIVERY);
  const isOnlyDeliveryEnabled = useFlag(LaunchDarklyFlag.ENABLE_DELIVERY_ONLY);
  const isCateringDeliveryEnabled = useFlag(LaunchDarklyFlag.ENABLE_CATERING_DELIVERY);

  const { pathname, params } = useRoute<ServiceModeCategoryParams>();
  const { getLocalizedRouteForPath } = useRoutes();
  const { serviceMode } = useServiceModeContext();

  const { formatMessage } = useIntl();

  const serviceModeCategoryOptions = useMemo(() => {
    if (isOnlyDeliveryEnabled) {
      return [{ label: formatMessage({ id: 'delivery' }), value: ServiceModeCategory.DELIVERY }];
    }

    return [
      { label: formatMessage({ id: 'pickUp' }), value: ServiceModeCategory.PICKUP },
      isDeliveryEnabled
        ? { label: formatMessage({ id: 'delivery' }), value: ServiceModeCategory.DELIVERY }
        : null,
      isCateringEnabled || isCateringDeliveryEnabled
        ? { label: formatMessage({ id: 'catering' }), value: ServiceModeCategory.CATERING }
        : null,
    ].filter(isNotNull);
  }, [
    formatMessage,
    isCateringDeliveryEnabled,
    isCateringEnabled,
    isDeliveryEnabled,
    isOnlyDeliveryEnabled,
  ]);

  let initialServiceModeCategory = ServiceModeCategory.PICKUP;

  switch (serviceMode) {
    case ServiceMode.CURBSIDE:
    case ServiceMode.DRIVE_THRU:
    case ServiceMode.EAT_IN:
    case ServiceMode.TAKEOUT:
      initialServiceModeCategory = ServiceModeCategory.PICKUP;
      break;
    case ServiceMode.DELIVERY:
      initialServiceModeCategory = ServiceModeCategory.DELIVERY;
      break;
    case ServiceMode.CATERING_PICKUP:
    case ServiceMode.CATERING_DELIVERY:
      initialServiceModeCategory = ServiceModeCategory.CATERING;
      break;
    default:
      break;
  }

  const initialServiceModeSelected = serviceModeCategoryOptions.find(
    option => option.value === initialServiceModeCategory
  );

  const [serviceModeCategory, setServiceModeCategory] = useState(
    initialServiceModeSelected?.value || ServiceModeCategory.PICKUP
  );

  // Keep the service mode category tabs in sync with the related routes.
  useEffect(() => {
    // Catering uses the pickup address input on the main route.
    if (
      pathname === routes.serviceModeCatering ||
      params?.serviceModeCategory === ServiceModeCategory.CATERING
    ) {
      setServiceModeCategory(ServiceModeCategory.CATERING);
      return;
    }

    // Delivery route.
    if (
      pathname === routes.address ||
      params?.serviceModeCategory === ServiceModeCategory.DELIVERY
    ) {
      setServiceModeCategory(ServiceModeCategory.DELIVERY);
      return;
    }

    // Main pickup route.
    if (
      pathname === getLocalizedRouteForPath('store-locator') ||
      params?.serviceModeCategory === ServiceModeCategory.PICKUP
    ) {
      setServiceModeCategory(ServiceModeCategory.PICKUP);
      return;
    }
  }, [getLocalizedRouteForPath, params?.serviceModeCategory, pathname, serviceMode]);

  return {
    serviceModeCategory,
    setServiceModeCategory,
    serviceModeCategoryOptions,
  };
};
