import * as React from 'react';
import { FC, useEffect, useMemo, useRef, useState } from 'react';

import { Box } from '@rbilabs/universal-components';
import { useIntl } from 'react-intl';
import { Animated, Easing } from 'react-native';

import { ISanityTextNode } from '@rbi-ctg/menu';
import ActionButton, { ActionButtonVariants } from 'components/action-button';
import LoadingAnimation from 'components/loading-animation';
import { Animation as LoyaltyPoppyTrophyAnimation } from 'components/lottie-animations/loyalty-poppy-trophy';
import Picture from 'components/picture';
import SemiCircleProgress from 'components/progress-bar/semi-circle';
import { IImageBackground, Maybe } from 'generated/sanity-graphql';
import { useIsTabletBp } from 'hooks/breakpoints';
import { useLoyaltyUser } from 'state/loyalty/hooks/use-loyalty-user';
import { clamp } from 'utils/calc';
import { isNative } from 'utils/environment';
import { getUrlHash } from 'utils/get-url-hash';

import {
  ENABLE_NEW_TIER_CELEBRATION_ANIMATION,
  METER_BG_COLOR,
  METER_DESKTOP_HEIGHT,
  METER_DESKTOP_WIDTH,
  METER_DIAMETER_WIDTH_ADJUSTMENT,
  METER_HEIGHT,
  METER_OUTER_STROKE_DESKTOP_WIDTH,
  METER_OUTER_STROKE_WIDTH,
  METER_STROKE_COLOR,
  METER_STROKE_DESKTOP_WIDTH,
  METER_STROKE_WIDTH,
  METER_WIDTH,
  MIN_PROGRESS_WHEN_REDEEMABLE,
  POINTS_METER_ANIMATION_DELAY,
} from './constants';
import {
  Container,
  ExternalLinkStyled,
  MeterContainer,
  PointMeterCtaButton,
  PointsContainer,
  PointsLabel,
  PointsText,
  PointsTextContainer,
} from './loyalty-points-meter-widget.styled';
import { MeterIcon } from './meter-icon';
import { RewardText } from './reward-text';
import { useCurrentAndNextRewardPoints } from './use-current-and-next-reward-points';

interface ILoyaltyPointMeterProps {
  ctaButton?: Maybe<{ buttonText: Maybe<ISanityTextNode>; buttonPath: Maybe<ISanityTextNode> }>;
  imageBackground?: IImageBackground;
}

/**
 *
 * LoyaltyPointsMeterWidget shows a points meter
 *
 */
const LoyaltyPointsMeterWidget: FC<React.PropsWithChildren<ILoyaltyPointMeterProps>> = ({
  ctaButton,
  imageBackground,
}) => {
  const bgImage = imageBackground?.image;
  const { formatMessage } = useIntl();
  const { loyaltyUser, loading: loyaltyLoading } = useLoyaltyUser();
  const isTablet = useIsTabletBp();
  const { hasTier, currentRewardPoints, nextRewardPoints } = useCurrentAndNextRewardPoints();
  const userPoints = loyaltyUser?.points || 0;
  const hasReward = currentRewardPoints !== undefined;
  const [animationCompleted, setAnimationCompleted] = useState(false);
  const { targetPercent, nextTierPointsNeeded } = useMemo(() => {
    if (currentRewardPoints === undefined) {
      return { targetPercent: 0, nextTierPointsNeeded: nextRewardPoints };
    }
    if (nextRewardPoints === undefined) {
      return { targetPercent: 100 };
    }
    const pointsNeeded = nextRewardPoints - userPoints;
    const percent = (1 - pointsNeeded / (nextRewardPoints - currentRewardPoints)) * 100;
    return {
      targetPercent: percent > 0 ? percent : MIN_PROGRESS_WHEN_REDEEMABLE,
      nextTierPointsNeeded: pointsNeeded,
    };
  }, [currentRewardPoints, nextRewardPoints, userPoints]);

  const percentAnimation = useRef(new Animated.Value(0));
  useEffect(() => {
    // Animate progress after a short delay
    if (targetPercent > 0) {
      Animated.timing(percentAnimation.current, {
        toValue: clamp(0, 100)(targetPercent),
        delay: POINTS_METER_ANIMATION_DELAY,
        duration: 2000,
        easing: Easing.out(Easing.cubic),
        useNativeDriver: isNative,
      }).start();
    }
  }, [targetPercent]);

  const handleComplete = () => {
    setAnimationCompleted(true);
  };

  const onCtaPress = (url?: string) => {
    const hash = getUrlHash(url);
    if (!hash) {
      return;
    }

    // TODO: RN migrate this
    // const widget = document.getElementById(hash);
    // const widgetVerticalPos = widget?.getBoundingClientRect().y || 0;
    // const offset = parseInt(stylesConstants.layout.navHeight.mobile, 10) || 0;
    // scrollTo({ top: widgetVerticalPos - offset });
  };

  const loading = loyaltyLoading && !loyaltyUser;
  const width = isTablet ? METER_DESKTOP_WIDTH : METER_WIDTH;
  const height = isTablet ? METER_DESKTOP_HEIGHT : METER_HEIGHT;
  const diameter = width - METER_DIAMETER_WIDTH_ADJUSTMENT;
  const strokeWidth = isTablet ? METER_STROKE_DESKTOP_WIDTH : METER_STROKE_WIDTH;
  const outerStrokeWidth = isTablet ? METER_OUTER_STROKE_DESKTOP_WIDTH : METER_OUTER_STROKE_WIDTH;
  const displayTierAnimation =
    ENABLE_NEW_TIER_CELEBRATION_ANIMATION && hasTier && !animationCompleted;

  return (
    <Container backgroundColor={Styles.color.contrastBackground}>
      <Box position="absolute" top={0} bottom={0} left={0} right={0} justifyContent="center">
        <Picture image={bgImage} alt="" objectFitContain />
      </Box>
      <MeterContainer>
        <Box>
          <SemiCircleProgress
            strokeWidth={strokeWidth}
            outerStrokeWidth={outerStrokeWidth}
            strokeColor={METER_STROKE_COLOR}
            backgroundColor={METER_BG_COLOR}
            width={width}
            height={height}
            diameter={diameter}
            progressAnimation={percentAnimation.current}
          />
        </Box>
        <PointsContainer containerHeight={height}>
          {loading ? (
            <LoadingAnimation reversed />
          ) : (
            <PointsTextContainer>
              {displayTierAnimation ? (
                <LoyaltyPoppyTrophyAnimation onComplete={() => handleComplete()} />
              ) : (
                <Box textAlign="center">
                  <PointsText>{loyaltyUser?.points}</PointsText>
                  <PointsLabel>{formatMessage({ id: 'points' })}</PointsLabel>
                </Box>
              )}
            </PointsTextContainer>
          )}
        </PointsContainer>
        <MeterIcon width={width} height={height} progressAnimation={percentAnimation.current} />
      </MeterContainer>
      <RewardText hasReward={hasReward} nextTierPointsNeeded={nextTierPointsNeeded} />
      {ctaButton && (
        <PointMeterCtaButton
          to={ctaButton?.buttonPath?.locale || ''}
          internal={{
            component: ActionButton,
            props: {
              onPress: () => onCtaPress(ctaButton?.buttonPath?.locale),
              variant: ActionButtonVariants.INVERSE,
              paddingLeft: '$8',
              paddingRight: '$8',
              _text: {
                color: Styles.color.primary,
              },
              backgroundColor: Styles.color.white,
            },
          }}
          // @ts-expect-error TS(2769) FIXME: No overload matches this call.
          external={{ component: ExternalLinkStyled }}
        >
          {ctaButton?.buttonText?.locale}
        </PointMeterCtaButton>
      )}
    </Container>
  );
};

export default LoyaltyPointsMeterWidget;
