import React from 'react';

import { Box } from '@rbilabs/universal-components';

import { IOffer } from '@rbi-ctg/menu';
import { OfferCell } from 'components/offer/ui-refresh/cell';
import { ClearSelectedOffer } from 'state/offers/hooks/types';

interface IOfferSelectedProps {
  offer: IOffer;
  onRemoveSelectedOffer: ClearSelectedOffer;
  onPress: (offerId: string) => void;
  isSelected: boolean;
}

const Container = Box.withConfig({
  backgroundColor: Styles.color.grey.five,
  padding: '5px',
});

const OfferSelected: React.FC<React.PropsWithChildren<IOfferSelectedProps>> = ({
  offer,
  onRemoveSelectedOffer,
  isSelected,
  onPress,
}) => {
  return (
    <Container testID="offer-selected-container">
      <OfferCell
        offer={offer}
        isSelected={isSelected}
        onPress={onPress}
        onRemove={onRemoveSelectedOffer}
      />
    </Container>
  );
};

export default OfferSelected;
