import { useFeatureSimplifiedLandingPageQuery } from 'generated/sanity-graphql';
import { useFeaturesContext } from 'state/features';

export const useFeatureSimplifiedLandingPage = () => {
  const { featureSimplifiedLandingPageId = '', featureIdsLoading } = useFeaturesContext();

  const { data, loading } = useFeatureSimplifiedLandingPageQuery({
    variables: { featureSimplifiedLandingPageId },
    skip: !featureSimplifiedLandingPageId,
  });

  return {
    isLoading: featureIdsLoading || loading,
    data: data?.FeatureSimplifiedLandingPage,
  };
};
