import React, { useCallback, useState } from 'react';

import { ListItemGroup } from 'components/list-item-group';
import { ProductListItem } from 'components/product-list-item';
import RadioIndicator from 'components/radio-indicator';
import { FallbackImageSvg } from 'components/svgs/fallback-image';

import { ProductListItemSeparator, ProductListWrapper } from '../styled';

import { IPickerAspectGroupProps } from './types';

export const PickerAspectGroup = React.memo<IPickerAspectGroupProps>(
  ({
    pickerAspect,
    userSelectedPickerAspects,
    calorieMap,
    handlePickerAspectChange,
    aspectIndex,
    promotionMap,
  }) => {
    const [selectedOption, setSelectedOption] = useState(
      userSelectedPickerAspects?.[pickerAspect._id]
    );
    const showImages = pickerAspect.pickerAspectOptions.some(option => !!option.image);

    const handleOnProductClick = useCallback(
      (optionIdentifier: string) => {
        if (optionIdentifier === selectedOption) {
          return;
        }
        const prevOption = selectedOption;
        setSelectedOption(optionIdentifier);

        handlePickerAspectChange({
          args: {
            aspect: pickerAspect,
            optionIdentifier,
            index: aspectIndex,
          },
          onCancel: () => setSelectedOption(prevOption),
        });
      },
      [aspectIndex, handlePickerAspectChange, pickerAspect, selectedOption]
    );

    return (
      <ProductListWrapper key={pickerAspect._id}>
        <ListItemGroup
          header={pickerAspect.name.locale}
          items={pickerAspect.pickerAspectOptions.map((option, optionIndex) => (
            <ProductListItem
              key={`${option.identifier}-${optionIndex}`}
              controls={<RadioIndicator isSelected={selectedOption === option.identifier} />}
              isClickable
              onProductClick={() => handleOnProductClick(option.identifier)}
              productProps={{
                name: option.name?.locale,
                description: option.description?.locale,
                image: option.image ? JSON.stringify(option.image) : undefined,
                // @ts-expect-error TS(2322) FIXME: Type '((props: Omit<SvgProps, "opacity" | "height"... Remove this comment to see the full error message
                fallbackImage: showImages ? FallbackImageSvg : undefined,
                // @ts-expect-error TS(2739) FIXME: Type 'IPickerPromotion' is missing the following p... Remove this comment to see the full error message
                promotion: promotionMap[`${pickerAspect._id}-${option.identifier}`],
              }}
            />
          ))}
          ItemSeparator={<ProductListItemSeparator />}
        />
      </ProductListWrapper>
    );
  }
);
