import 'styles';

import React, { PropsWithChildren, useCallback, useRef, useState } from 'react';

import { Alert, LogBox, Text, View } from 'react-native';
import Crash from 'react-native-crash-tester';
import uuidv4 from 'uuid/v4';

import LocalStorage from 'utils/cognito/storage';
import { StorageKeys } from 'utils/local-storage';
import logger from 'utils/logger';

import App from './app';
import bindExceptionHandler from './exception-handler';
import { ExceptionHandler, GtmEvent, IBootstrapProps } from './types';

// We don't like logbox. Why use it when we have flipper?
LogBox.ignoreAllLogs();
LogBox.uninstall();

declare global {
  interface Window {
    dataLayer: GtmEvent[];
    LOADING_START_TIME: number;
    /**
     * window.Cypress exists when our app is running within Cypress
     * Places we currently check for this:
     * - frontend/src/state/graphql/links/index.ts - avoid BatchHttpLink
     *
     * https://docs.cypress.io/faq/questions/using-cypress-faq.html#Is-there-any-way-to-detect-if-my-app-is-running-under-Cypress
     */
    Cypress?: any;
    // When run with cypress for cypress-v2 package,
    // we instantiate the LD client with predefined flags
    _initial_cypress_feature_flags?: object;
    // Skip the interval for checking for unavailable items.
    // Avoids race condition in cart with recorded tests
    _skipUnavailableItemsIntervalCheck?: boolean;
  }
}

const NON_SESSION_SPECIFIC_STORAGE_KEYS = [
  StorageKeys.LANGUAGE,
  StorageKeys.REGION,
  StorageKeys.HAS_SHOWN_LOCALE_SELECTOR,
  StorageKeys.USER_AUTH_TOKEN,
  StorageKeys.USER,
];

// Config options for exception handler
const errorTitle = 'Fatal Error';
const errorBoundaryBody = 'Something went wrong on our end. Please restart the app.';

const exceptionHandler: ExceptionHandler = (error, isFatal) => {
  const uuid = uuidv4();
  if (isFatal) {
    const message = `FatalError: ${error.message} (${uuid})`;
    logger.fatal({ error: { ...error, message } });
    Alert.alert(
      errorTitle,
      `${errorBoundaryBody} (id: ${uuid})`,
      [
        {
          text: 'Reload',
          onPress: () => {
            LocalStorage.clear({ excludeKeys: NON_SESSION_SPECIFIC_STORAGE_KEYS });
            Crash.nativeCrash('ForcedCrash');
          },
          style: 'default',
        },
      ],
      {
        cancelable: true,
        onDismiss: () => {},
      }
    );
  } else {
    // Using warn, since non-fatal errors will be caught by the error boundary
    const message = `NonFatalError: ${error.message} (${uuid})`;
    logger.warn({ error: { ...error, message } });
  }
};

bindExceptionHandler(exceptionHandler);

class FatalBootHandler extends React.Component<PropsWithChildren> {
  state = {
    didCrash: false,
    error: new Error(),
  };

  static getDerivedStateFromError(error: Error) {
    return { didCrash: true, error };
  }

  componentDidCatch(error: Error) {
    logger.fatal({ error: { ...error, message: `FatalError: ${error.message}` } });
  }

  render() {
    return this.state.didCrash ? (
      <View style={{ flex: 1, marginTop: '20%', marginLeft: 8, marginRight: 8 }}>
        <Text style={{ textAlign: 'center', marginBottom: 16 }}>FATAL CRASH</Text>
        <Text style={{ fontWeight: 'bold', marginBottom: 16 }}>
          {this.state.error.name}: {this.state.error.message}
        </Text>
      </View>
    ) : (
      this.props.children
    );
  }
}

const AppWithFatalHandler: React.VFC<IBootstrapProps> = bootstrapProps => {
  const reloadCount = useRef(0);
  const [forcingReload, setForceReload] = useState(false);

  // If the app crashes and the error boundary is caught
  // our best attempt at recovering is simply to unmount the entire application
  // and remount it. This may have other downstream consequences...
  // We currently will try recovering the app three times:
  //
  // 1. The first time we just unmount and remount.
  // 2. If that crashes the app again, we clear local storage items and unmount/remount
  // 3. If taht crashes again, we force a true app crash to demand a full session reset
  const onErrorBoundaryRequestReload = useCallback(() => {
    reloadCount.current = reloadCount.current + 1;
    setForceReload(true);

    if (reloadCount.current > 1) {
      LocalStorage.clear({ excludeKeys: NON_SESSION_SPECIFIC_STORAGE_KEYS });
    }

    setTimeout(() => setForceReload(false), 1);
  }, []);

  if (forcingReload) {
    return null;
  }

  if (reloadCount.current > 2) {
    throw new Error('Intentionally crashing the app. Error recovery failing.');
  }

  return (
    <FatalBootHandler>
      <App {...bootstrapProps} onErrorBoundaryRequestReload={onErrorBoundaryRequestReload} />
    </FatalBootHandler>
  );
};

export default AppWithFatalHandler;
