import * as Linking from 'expo-linking';
import * as Notifications from 'expo-notifications';
import {
  PermissionStatus,
  getTrackingPermissionsAsync,
  requestTrackingPermissionsAsync,
} from 'expo-tracking-transparency';
import { Platform } from 'react-native';

import { ILocation } from '@rbi-ctg/frontend';
import { updateAmplitudeATTStatus } from 'state/amplitude/amplitude-att-plugin';
import {
  GeolocationPermissionStates,
  checkIfLocationPermissionIsPreapproved,
  getUsersCurrentPosition,
  promptUserForLocationPermission,
} from 'utils/geolocation';
import logger from 'utils/logger';

export const enablePush = async (): Promise<Notifications.NotificationPermissionsStatus | null> => {
  try {
    const settings = await Notifications.getPermissionsAsync();
    if (
      settings.status === PermissionStatus.UNDETERMINED ||
      settings.ios?.status === Notifications.IosAuthorizationStatus.NOT_DETERMINED
    ) {
      return await Notifications.requestPermissionsAsync();
    }
    return settings;
  } catch (error) {
    logger.error({ error, message: 'Prompt For Push Permissions error' });
    return null;
  }
};

export const openPushSetting = () => {
  if (Platform.OS === 'android') {
    Linking.openSettings();
  } else if (Platform.OS === 'ios') {
    Linking.openURL('app-settings:');
  }
};

export const enableLocation = (): Promise<ILocation | void> => {
  return checkIfLocationPermissionIsPreapproved().then(state => {
    switch (state) {
      case GeolocationPermissionStates.PROMPT: {
        return new Promise(async (resolve, reject) => {
          try {
            const permissionState = await promptUserForLocationPermission();
            if (permissionState === GeolocationPermissionStates.DENIED) {
              return reject('User denied location permission');
            }
          } catch (error) {
            const message = 'Prompt For Location Permissions error';
            logger.error({ error, message });
            return reject(message);
          }

          return resolve(await getUsersCurrentPosition());
        });
      }
      case GeolocationPermissionStates.GRANTED:
        return getUsersCurrentPosition();
      case GeolocationPermissionStates.DENIED:
      default:
        return;
    }
  });
};

export const appTrackingTransparency = async (): Promise<PermissionStatus | undefined> => {
  try {
    const { granted, canAskAgain, status } = await getTrackingPermissionsAsync();

    if (!granted && canAskAgain) {
      const response = await requestTrackingPermissionsAsync();

      // Update Amplitude att status after we have requested permissions
      updateAmplitudeATTStatus(response.status);

      return response.status;
    }

    return status;
  } catch (error) {
    logger.error({
      error,
      message: 'Error in fetching user App Tracking Transparency status',
      // In legacy implementations, this was always set to true. I'm keeping this here hardcoded
      // just to ensure our data lines up as expected. But it seems like this could go away without
      // any consequences.
      requestPermission: true,
    });
  }
  return undefined;
};
