import React from 'react';

import { Box, Center } from '@rbilabs/universal-components';
import { useIntl } from 'react-intl';

import { HamburgerButton } from 'components/app-header/mobile-header/hamburger-button';
import {
  Header,
  LeftNavWrapper,
  LinkNoDecoration,
  MenuButtonContainer,
  MobileHeaderContainer,
} from 'components/app-header/mobile-header/styled';
import SkipToContent from 'components/skip-content';
import { TopServiceMode } from 'components/top-service-mode';
import { TopServiceModeAppHeader } from 'components/top-service-mode-app-header';
import { useIsTopServiceModeEnabled } from 'experiments/service-mode/use-is-top-service-mode-enabled';
import { useIsTopServiceModeEnabled_SimplifiedLandingPage__Experiment } from 'experiments/simplified-landing-page';
import { useIsDesktopBp, useIsTabletBp } from 'hooks/breakpoints';
import { useRoute } from 'hooks/navigation/use-route';
import useAuthFlow from 'state/auth/hooks/use-auth-flow';
import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';
import { useLoadingIndicator } from 'state/loading';
import { useMobileHeaderBackButton, useMobileHeaderComponent } from 'state/mobile-header-nav';
import { useReversedUIContext } from 'state/reversed-ui';
import { routes } from 'utils/routing';

import { LeftNav } from '../left-nav';
import SignupMobileHeader from '../signup-mobile-header';
import { StyledCenterSpacer, StyledRightSpacer } from '../styled/base';
import { HeaderLogo, TopServiceModeContainer } from '../styled/mobile-header';

import MobileHeaderCta from './mobile-header-cta';

export const StandardMobileHeader = () => {
  const BackButtonComponent = useMobileHeaderBackButton();
  const { pathname } = useRoute();

  const LoadingIndicator = useLoadingIndicator();
  const { formatMessage } = useIntl();
  const enableTopServiceMode = useIsTopServiceModeEnabled_SimplifiedLandingPage__Experiment(
    useIsTopServiceModeEnabled()
  );
  const { reversedUI } = useReversedUIContext();

  const isHamburgerMenuEnabled = useFlag(LaunchDarklyFlag.ENABLE_HAMBURGER_MENU_ON_MOBILE);
  const isTablet = useIsTabletBp();
  const isDesktop = useIsDesktopBp();

  const shouldShowHamburgerMenu =
    pathname.includes(formatMessage({ id: 'routes.termsConditionsRewards' })) ||
    pathname.includes(formatMessage({ id: 'routes.rewardsPolicy' })) ||
    pathname.includes(formatMessage({ id: 'routes.deliveryTerms' })) ||
    pathname.includes(formatMessage({ id: 'routes.termsOfService' })) ||
    pathname.includes(formatMessage({ id: 'routes.privacyPolicy' }));

  const { isInAuthFlow } = useAuthFlow();
  if (isInAuthFlow) {
    return <SignupMobileHeader />;
  }

  const LeftSideComponent =
    (!shouldShowHamburgerMenu && !isTablet && BackButtonComponent) ||
    (isHamburgerMenuEnabled ? HamburgerButton : () => null);

  return (
    <MobileHeaderContainer
      accessibilityLabel={formatMessage({ id: 'primaryNavLinks' })}
      testID="mobile-header"
      reversed={reversedUI}
    >
      <Center>
        <Box width="full" maxW={{ base: Styles.layout.sectionMaxWidth, lg: 'full' }}>
          <Header>
            <MenuButtonContainer>
              <LeftSideComponent />
              {isDesktop && (
                <LeftNavWrapper>
                  <LeftNav />
                </LeftNavWrapper>
              )}
            </MenuButtonContainer>
            <SkipToContent />
            <StyledCenterSpacer>
              <LinkNoDecoration to={routes.base}>
                <HeaderLogo />
              </LinkNoDecoration>
            </StyledCenterSpacer>
            <StyledRightSpacer>
              {isTablet ? <TopServiceModeAppHeader /> : <MobileHeaderCta />}
            </StyledRightSpacer>
          </Header>
          <LoadingIndicator />

          {enableTopServiceMode && (
            <TopServiceModeContainer>
              <TopServiceMode />
            </TopServiceModeContainer>
          )}
        </Box>
      </Center>
    </MobileHeaderContainer>
  );
};

export default function MobileHeader() {
  const HeaderComponent = useMobileHeaderComponent();

  return HeaderComponent ? <HeaderComponent /> : <StandardMobileHeader />;
}
