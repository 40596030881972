import React, { FC } from 'react';

import { Box, BrandLogo, useBreakpointValue } from '@rbilabs/universal-components';

import { useBrandLogoVariant } from 'hooks/use-brand-logo-variant';

const StyledLogo = Box.withConfig({
  height: Styles.layout.navHeight.mobile,
  paddingX: '$2',
  justifyContent: 'center',
});

export const TopServiceModeContainer = Box;

export const HeaderLogo: FC<React.PropsWithChildren<unknown>> = () => {
  const { brandPrimaryLogoVariant, brandLogoWordmarkVariant } = useBrandLogoVariant();

  const brandLogoVariant = useBreakpointValue({
    base: brandPrimaryLogoVariant,
    lg: brandLogoWordmarkVariant,
  });

  return (
    <StyledLogo testID="mobile-header-logo">
      <BrandLogo variant={brandLogoVariant} padding="0" maxHeight="$12" minWidth="$12" />
    </StyledLogo>
  );
};
