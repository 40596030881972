import React, { FC } from 'react';

import SanityBlockRenderer, {
  IBlockContentOverrides,
  ISerializers,
  PassthroughComponent,
} from 'components/sanity-block-renderer';

import { IMG_FIT_CONTAIN, INCLUDE_BG_IMG } from './constants';
import {
  BackgroundPicture,
  Description,
  ImgBgContainer,
  Name,
  OfferInfoContainer,
  OfferPicture,
  StyledOfferBadges,
  UnconstrainedHeightContainer,
} from './details-panel-components';
import { OfferDisclaimer } from './offer-disclaimer';
import { RedemptionPanel } from './redemption-panel';
import { IDetailsPanelViewProps } from './types';

const nameBlockContentOverrides: IBlockContentOverrides = {
  normal: Name,
};

const nameBlockSerializers: ISerializers = {
  marks: {
    em: PassthroughComponent,
  },
  container: PassthroughComponent,
};

const descriptionBlockContentOverrides: IBlockContentOverrides = {
  normal: Description,
};

const descriptionBlockSerializers: ISerializers = {
  container: PassthroughComponent,
};

export const DetailsPanelView: FC<React.PropsWithChildren<IDetailsPanelViewProps>> = ({
  offer,
  onRedeemOffer,
  showRestaurantRedemptionButton,
  disableMobileOfferRedemptions,
  disableInRestaurantOfferRedemptions,
  redemptionErrorMessage,
  enableOfferBadges,
  endingIn,
  platforms,
  showSignUpToRedeem,
  showMobileRedemptionButton,
  restaurantRedemptionButtonUiType,
  noPossibleRedemption,
  togglePromoCodeOfferRedeemable,
}) => {
  return (
    <UnconstrainedHeightContainer>
      <ImgBgContainer>
        {INCLUDE_BG_IMG && offer.backgroundImage && (
          <BackgroundPicture alt="" image={offer.backgroundImage} objectFitContain={false} />
        )}
        {offer?.localizedImage?.locale?.app && (
          <OfferPicture
            image={offer?.localizedImage?.locale?.app!}
            alt={offer?.localizedImage?.locale?.imageDescription ?? ''}
            key={offer._id}
            objectFitContain={IMG_FIT_CONTAIN}
          />
        )}
      </ImgBgContainer>

      <OfferInfoContainer>
        {enableOfferBadges && (
          <StyledOfferBadges
            platforms={platforms}
            displayLoginRequiredBadge={false}
            endingIn={endingIn}
          />
        )}
        <SanityBlockRenderer
          blockContentOverrides={nameBlockContentOverrides}
          serializers={nameBlockSerializers}
          content={offer?.name?.localeRaw}
        />
        <SanityBlockRenderer
          blockContentOverrides={descriptionBlockContentOverrides}
          serializers={descriptionBlockSerializers}
          content={offer?.description?.localeRaw}
        />

        <RedemptionPanel
          togglePromoCodeOfferRedeemable={togglePromoCodeOfferRedeemable}
          offer={offer}
          showMobileRedemptionButton={showMobileRedemptionButton}
          disableInRestaurantOfferRedemptions={disableInRestaurantOfferRedemptions}
          disableMobileOfferRedemptions={disableMobileOfferRedemptions}
          showSignUpToRedeem={showSignUpToRedeem}
          showRestaurantRedemptionButton={showRestaurantRedemptionButton}
          onRedeemOffer={onRedeemOffer}
          redemptionErrorMessage={redemptionErrorMessage}
          restaurantRedemptionButtonUiType={restaurantRedemptionButtonUiType}
          noPossibleRedemption={noPossibleRedemption}
        />
        <OfferDisclaimer offer={offer} />
      </OfferInfoContainer>
    </UnconstrainedHeightContainer>
  );
};
