import { Button } from '@rbilabs/universal-components';

import { theme } from 'styles/configure-theme';

export const QuestCallToAction = Button.withConfig({
  _text: {
    color: theme.token('text-button-primary'),
    fontWeight: 'bold',
  },
});
