import React, { useEffect, useMemo } from 'react';

import delv from 'dlv';
import { useIntl } from 'react-intl';

import { ArrowLink } from 'components/arrow-link';
import { useStaticPageRoutes } from 'state/static-page-manager/hooks/use-static-page-routes';
import { useSupportLink } from 'utils/support/use-support-link';

import { SUBMIT_FEEDBACK_INTL_KEY } from './constants';

const SubmitFeedbackLink = () => {
  const { formatMessage } = useIntl();
  const supportLink = useSupportLink();
  const { routes: allRoutes, loadRoutes } = useStaticPageRoutes();
  const redirectUrl = useMemo(() => {
    let result = '';
    allRoutes.forEach(route => {
      if (delv(route, `path.current`) === supportLink) {
        result = delv(route, `redirectUrl.locale`);
      }
    });
    return result;
  }, [allRoutes, supportLink]);

  useEffect(() => {
    loadRoutes();
  }, []);

  return (
    <ArrowLink to={!redirectUrl ? supportLink : redirectUrl}>
      {formatMessage({ id: SUBMIT_FEEDBACK_INTL_KEY })}
    </ArrowLink>
  );
};

export default SubmitFeedbackLink;
