import React, { VFC } from 'react';

import { useIntl } from 'react-intl';

import { LocationEmptyState } from 'components/location-empty-state';
import StoreSelected from 'components/store-selected';

const MenuEmptyState: VFC = () => {
  const { formatMessage } = useIntl();
  return (
    <StoreSelected>
      <LocationEmptyState
        title={formatMessage({ id: 'emptyMenuHeading' })}
        message={formatMessage({ id: 'emptyMenuMessage' })}
      />
    </StoreSelected>
  );
};

export default MenuEmptyState;
