import { IOffer } from '@rbi-ctg/menu';
import { RedemptionMethod } from 'state/offers';
import { IDays, IHours } from 'utils/offers/rule-sets/time-left-to-redeem';

export interface IAttemptOfferRedemptionArgs {
  redemptionMethod: RedemptionMethod;
  bypassCheckForAnotherOffer?: boolean;
}

export type IAttemptOfferRedemption = (args: IAttemptOfferRedemptionArgs) => void;

export type IShouldUseCustomDisclaimer = (
  isCustomDisclaimerAvailable: boolean,
  isMoreInfoDisclaimerFlagEnabled: boolean
) => boolean;

export interface IDetailsPanelViewProps {
  offer: IOffer;
  onRedeemOffer: IAttemptOfferRedemption;
  showRestaurantRedemptionButton: boolean;
  disableMobileOfferRedemptions: boolean;
  disableInRestaurantOfferRedemptions: boolean;
  redemptionErrorMessage: string | null;
  enableOfferBadges: boolean;
  endingIn: IDays | IHours | null;
  platforms: string[];
  showMobileRedemptionButton: boolean;
  showSignUpToRedeem: boolean;
  restaurantRedemptionButtonUiType: RestaurantRedemptionButtonUiOption;
  noPossibleRedemption: boolean;
  togglePromoCodeOfferRedeemable: (offerId: string) => void;
}

export interface IRedemptionButtonsProps {
  onRedeemOffer: IAttemptOfferRedemption;
  showRestaurantRedemptionButton: boolean;
  disableMobileOfferRedemptions: boolean;
  disableInRestaurantOfferRedemptions: boolean;
  redemptionErrorMessage: string | null;
  showMobileRedemptionButton: boolean;
  showSignUpToRedeem: boolean;
  restaurantRedemptionButtonUiType: RestaurantRedemptionButtonUiOption;
  noPossibleRedemption: boolean;
}

export interface IRedemptionPanelProps {
  offer: IOffer;
  onRedeemOffer: IAttemptOfferRedemption;
  showRestaurantRedemptionButton: boolean;
  disableMobileOfferRedemptions: boolean;
  disableInRestaurantOfferRedemptions: boolean;
  redemptionErrorMessage: string | null;
  showMobileRedemptionButton: boolean;
  showSignUpToRedeem: boolean;
  restaurantRedemptionButtonUiType: RestaurantRedemptionButtonUiOption;
  noPossibleRedemption: boolean;
  togglePromoCodeOfferRedeemable: (offerId: string) => void;
}

export enum RestaurantRedemptionButtonUiOption {
  BUTTON = 'button',
  LINK = 'link',
}

export interface IRedemptionButton {
  onPressRestaurantRedemption: () => void;
  restaurantRedemptionButtonUiType?: RestaurantRedemptionButtonUiOption;
  restaurantRedemptionButtonTitle: string;
  disabled?: boolean;
}

export enum RedemptionTypes {
  MOBILE_ONLY = 'mobileOnly',
  RESTAURANT_ONLY = 'restaurantOnly',
  MOBILEANDRESTAURANT = 'mobileAndRestaurant',
}
