import { Box } from '@rbilabs/universal-components';

import Link from 'components/link';

export const LinksContainer = Box.withConfig({
  flexDirection: {
    base: 'column',
    md: 'row',
  },
  alignItems: {
    base: 'flex-start',
    md: 'center',
  },
  justifyContent: 'space-around',
  borderStyle: 'solid',
  borderTopWidth: '1',
  borderBottomWidth: '1',
});

export const StyledLink = Link.withConfig<{ $hideBorderBottom?: boolean }>(
  ({ $hideBorderBottom }) => ({
    width: 'full',
    borderStyle: 'solid',
    borderBottomWidth: {
      base: $hideBorderBottom ? 0 : '1',
      md: 0,
    },
    _text: {
      color: Styles.color.black,
      fontSize: {
        base: 'md',
        md: '2xl',
      },
      textDecoration: 'none',
    },
  })
);
