import React from 'react';

import { Icon, IconProps } from '@rbilabs/universal-components';

import { useLocale } from 'state/intl';

const SodiumFlag = (props: IconProps) => {
  const { region } = useLocale();

  if (region !== 'US') {
    return null;
  }

  return <Icon variant="sodiumFlag" testID="sodium-flag" {...props} />;
};

export default SodiumFlag;
