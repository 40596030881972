import * as React from 'react';
import { FC, useEffect } from 'react';

import { useRoutesDisplayBottomService as useRoutesDisplayTopService } from 'components/bottom-service-mode/hooks/use-routes-display-bottom-service';
import { DisableTopServiceMode__Experiment } from 'experiments/service-mode/disable-top-service-mode-experiment';
import { useNavigation } from 'hooks/navigation/use-navigation';
import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';
import { useOrderContext } from 'state/order';
import { useStoreContext } from 'state/store';
import { routes } from 'utils/routing';

import { useTopServiceModeDetails } from './hooks/use-top-service-details';
import { TopServiceModeView } from './top-service-mode.view';
import { ITopServiceModeProps } from './types';

/**
 *
 * TopServiceMode purpose
 *
 */
const TopServiceMode: FC<React.PropsWithChildren<ITopServiceModeProps>> = () => {
  const enableOrdering = useFlag(LaunchDarklyFlag.ENABLE_ORDERING);
  const { resetStore, store } = useStoreContext();
  const topServiceModeDetails = useTopServiceModeDetails();
  const {
    CurbsideConfirmationModal,
    curbsideConfirmationModalOpen,
    setCurbsideConfirmationModalOpen,
  } = topServiceModeDetails;
  const showTopServiceOnRoutes = useRoutesDisplayTopService();
  const {
    emptyCart,
    setCurbsidePickupOrderId,
    setCurbsidePickupOrderTimePlaced,
  } = useOrderContext();

  const { navigate } = useNavigation();

  useEffect(() => {
    if (!enableOrdering) {
      resetStore();
    }
  }, [enableOrdering, store]);

  if (!showTopServiceOnRoutes) {
    return null;
  }

  const modalDismiss = () => setCurbsideConfirmationModalOpen(false);
  const cancelCurbsideOrder = () => {
    emptyCart();
    setCurbsidePickupOrderId('');
    setCurbsidePickupOrderTimePlaced('');
    modalDismiss();
    navigate(routes.storeLocator);
  };

  return (
    <>
      <TopServiceModeView {...topServiceModeDetails} />

      {curbsideConfirmationModalOpen && (
        <CurbsideConfirmationModal
          showDialog={curbsideConfirmationModalOpen}
          onKeepOrder={modalDismiss}
          onCancelOrder={cancelCurbsideOrder}
          onDismiss={modalDismiss}
        />
      )}
    </>
  );
};

export default function TopServiceModeIfExperimentAllowed() {
  return (
    <DisableTopServiceMode__Experiment>
      <TopServiceMode />
    </DisableTopServiceMode__Experiment>
  );
}
