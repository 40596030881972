import { Box, Header } from '@rbilabs/universal-components';

export const SimpleWizardItemWrapper = Box.withConfig({
  _text: {
    color: Styles.color.black,
  },

  backgroundColor: Styles.color.white,
  marginTop: 0,
  marginRight: 0,
  marginBottom: '$2',
  marginLeft: 0,
  padding: '$5',
  borderTopLeftRadius: Styles.borderRadius,
  borderTopRightRadius: Styles.borderRadius,
  borderBottomRightRadius: Styles.borderRadius,
  borderBottomLeftRadius: Styles.borderRadius,

  // TODO: RN - unsupported CSS
  // boxShadow: '__3substitution__',
});

export const Title = Header.withConfig({
  variant: 'headerOne',
  textAlign: 'center',
});

export const Spacer = Box.withConfig({
  marginBottom: '$10',
});
