import React, { useMemo, useState } from 'react';

import { Radio } from '@rbilabs/universal-components';
import { useIntl } from 'react-intl';

import MenuLineItem from 'components/menu-order-wizard/menu-line-item';
import ModifierPrice from 'components/menu-order-wizard/modifier-price';
import { NumberInput } from 'components/ucl/number-input';
import { useFormatJoules } from 'hooks/use-format-joules';
import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';

import { ModifierSelectionPrice, QuantityDetail } from './menu-option-selector.styled';
import { IMenuOptionSelectorProps } from './types';

const MenuOptionSelector = ({
  checked,
  testID: dataTestId,
  disabled,
  image,
  imageDescription,
  // inputName,
  itemId,
  itemOption,
  name,
  onQuantityUpdate,
  price,
  quantity,
  subtitle,
  testId,
  value,
}: IMenuOptionSelectorProps) => {
  const [prevQuantity, setPrevQuantity] = useState(0);
  const handleQuantityChange = (newQuantity: number) => {
    onQuantityUpdate(
      newQuantity,
      itemOption._key,
      itemId,
      value,
      prevQuantity,
      itemOption.maxAmount
    );
  };

  const enableEUNutritionDisplay = useFlag(LaunchDarklyFlag.ENABLE_EU_NUTRITION_DISPLAY);
  // * Normally this logic is handled in the <Calories /> wrapper
  // * but seeing as MenuLineItem relies on boolean logic to show/hide the details
  // * its imported here so that an explicit falsy value can be provided
  // * (instead of providing a React Element)
  const hideUiCalories = useFlag(LaunchDarklyFlag.HIDE_UI_CALORIES);
  const formatJoules = useFormatJoules();

  const formattedJoules = formatJoules(Number(subtitle?.split(' ')[0]));
  const { formatMessage } = useIntl();

  const euFormattedNutritionText = formatMessage(
    { id: 'euNutritionValues' },
    { calories: subtitle, joules: formattedJoules }
  );

  const globalizedCalorieFormat = subtitle ? `${euFormattedNutritionText}` : '';

  let finalCalories;
  if (!hideUiCalories) {
    finalCalories = enableEUNutritionDisplay ? globalizedCalorieFormat : subtitle;
  }

  // Capture the state change from the auto adjustment from the modifier reducer
  const qty = useMemo(() => {
    setPrevQuantity(quantity);
    return quantity;
  }, [quantity]);

  return (
    <Radio
      testID={testId}
      isDisabled={disabled}
      value={value}
      flexDirection="row-reverse"
      paddingX="$8"
    >
      <MenuLineItem
        bottomContent={
          <>
            {checked && itemOption.allowMultipleSelections && (
              <QuantityDetail>
                <ModifierSelectionPrice>{formatMessage({ id: 'included' })}</ModifierSelectionPrice>
                <NumberInput
                  hideNumberInput
                  displayedValue={qty}
                  value={qty}
                  onChange={handleQuantityChange}
                  min={1}
                  max={itemOption.maxAmount}
                  ariaLabel={`Quantity ${quantity} of ${name}`}
                  decrementAriaLabel={`decrement-${name}`}
                  incrementAriaLabel={`increment-${name}`}
                />
              </QuantityDetail>
            )}
            <ModifierPrice price={price} />
          </>
        }
        testID={dataTestId}
        disabled={disabled}
        image={image}
        imageDescription={imageDescription}
        indicator={<></>}
        name={name}
        selected={checked}
        details={finalCalories}
      />
    </Radio>
  );
};

export default MenuOptionSelector;
//TODO: refactor picker aspects to implement this style
