import { ItemAvailabilityStatus } from 'enums/menu';
import { useMapToFrontendCartEntry } from 'state/order/hooks/use-map-to-frontend-cart-entry';
import { routes } from 'utils/routing';

/**
 *
 * Returns a function to reorder a previous order.
 */
export const useHandleReorder = ({
  addToCart,
  navigate,
  linkTo,
  setPendingReorder,
  setReordering,
  storeHasSelection,
  setUnavailableCartEntries,
  setReorderedOrderId,
  cartEntries,
}) => {
  const mapBackendToFrontend = useMapToFrontendCartEntry();

  return async (order, url = routes.orders) => {
    if (order && storeHasSelection) {
      setReordering(true);
      setReorderedOrderId(order.rbiOrderId);
      const { reorderCartEntries } = order.cart;

      const unavailableEntries = [];
      let containsExtras = false;

      for (const entry of reorderCartEntries) {
        // Map backend entry to frontend
        const { cartEntry: frontendEntry, status } = await mapBackendToFrontend(entry, cartEntries);
        // If this is an extra we want to avoid adding it to the cart or unavailable items.
        // Simply mark this order as having an extra and go to the next entry
        if (status === ItemAvailabilityStatus.CART_EXTRA) {
          containsExtras = true;
          continue;
        }
        // Check if the item is available
        if (status === ItemAvailabilityStatus.AVAILABLE) {
          addToCart(frontendEntry);
        } else {
          unavailableEntries.push(frontendEntry);
        }
      }

      if (unavailableEntries.length) {
        setUnavailableCartEntries(unavailableEntries);
      }

      setReordering(false);
      setReorderedOrderId(null);
      setPendingReorder(null);

      // If the order had extras go to the extras modal in the cart
      if (containsExtras) {
        linkTo(routes.cartAddExtras);
      } else if (unavailableEntries.length === 0) {
        navigate(routes.cart);
      } else {
        // Navigate to the url provided if there are unavailable items
        navigate(url);
      }
    } else {
      // if there's no order or no order or store selection, navigate to where was initially intended
      navigate(url);
    }
  };
};
