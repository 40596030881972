import { Box, HStack } from '@rbilabs/universal-components';

import Link from 'components/link';

export const StyledLinkExternal = Link.withConfig<{ active?: boolean }>(p => ({
  // TODO: RN - this should work when Link is the Link from UCL
  isExternal: true,
  fontSize: 'lg',
  marginLeft: '20px',

  ...(p.active && { color: Styles.color.navLinkColor.active }),

  _hover: {
    textDecoration: 'none',
    color: Styles.color.navLinkColor.active,
  },

  // TODO: RN - Do we need this?
  // &:first-of-type {
  //   margin-left: 0;
  // }
}));

export const StyledLink = Link.withConfig<{ active: boolean }>(p => ({
  fontSize: 'lg',
  marginLeft: '20px',

  ...(p.active && { color: Styles.color.navLinkColor.active }),

  _hover: {
    textDecoration: 'none',
    color: Styles.color.navLinkColor.active,
  },

  // TODO: RN - Do we need this?
  // &:first-of-type {
  //   margin-left: 0;
  // }
}));

export const StyledCenterSpacer = Box.withConfig({
  flexGrow: 1,
  alignItems: { base: 'flex-start', lg: 'flex-end' },
  marginLeft: { base: '$10', lg: '$0' },
  textAlign: 'center',
});

export const StyledRightSpacer = HStack.withConfig({
  flex: 1,
  justifyContent: 'flex-end',
  alignItems: 'center',
});
