import React, { FC } from 'react';

import { ButtonLabel } from './button-label';
import { ButtonContainer } from './locked-offers-card.styled';
import { IDetailButton } from './types';

export const DetailButton: FC<React.PropsWithChildren<IDetailButton>> = ({
  offerState,
  progressData,
  onPress,
}) => (
  <ButtonContainer>
    <ButtonLabel offerState={offerState} progressData={progressData} onPress={onPress} />
  </ButtonContainer>
);
