import { Box } from '@rbilabs/universal-components';

export const OfferPictureContainer = Box.withConfig({
  width: {
    base: '4/5',
    md: '70%',
  },
  maxWidth: '550px',
  marginBottom: '2.5',
  marginTop: {
    base: '9',
    md: '20',
  },
  marginX: 'auto',
});

export const OfferPictureSizer = Box.withConfig({
  paddingBottom: {
    base: '35%',
    md: '30%',
  },
});
