import { Box } from '@rbilabs/universal-components';

import { SINGLE_PADDING } from './constants';

export const SingleWrapper = Box.withConfig({
  flexDirection: 'row',
  justifyContent: 'center',
  width: 'full',
  padding: SINGLE_PADDING,

  paddingX: {
    md: '$4',
  },

  paddingY: {
    md: '$5',
  },
});

export const Wrapper = Box.withConfig({
  height: '350px',
  padding: {
    md: 0,
  },
});
