import React, { FC, useCallback } from 'react';

import { useIntl } from 'react-intl';

import ActionButton from 'components/action-button';
import { useNavigation } from 'hooks/navigation/use-navigation';
import { routes } from 'utils/routing';

import { EmptyMenuIcon } from './icon/icon';
import { BodyText, HeadingText, IconWrapper, Wrapper } from './styles';
import { ILocationEmptyState } from './types';

export const LocationEmptyState: FC<React.PropsWithChildren<ILocationEmptyState>> = ({
  title,
  message,
  onPress,
}) => {
  const { formatMessage } = useIntl();

  const { navigate } = useNavigation();

  const handlePress = useCallback(() => {
    navigate(routes.storeLocator, { replace: true });
  }, [navigate]);

  return (
    <Wrapper>
      <IconWrapper>
        <EmptyMenuIcon />
      </IconWrapper>
      <HeadingText>{title}</HeadingText>
      <BodyText>{message}</BodyText>
      <ActionButton onPress={onPress ?? handlePress}>
        {formatMessage({ id: 'selectLocation' })}
      </ActionButton>
    </Wrapper>
  );
};
