import { Header } from '@rbilabs/universal-components';

import { theme } from 'styles/configure-theme';

export const PriceAndCaloriesText = Header.withConfig({
  variant: 'headerTwo',
  width: 'full',
  color: theme.token('text-default'),
  textAlign: 'center',
  textTransform: 'capitalize',
  marginTop: 0,
  marginBottom: 3,
});
