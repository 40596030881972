import React, { FC } from 'react';

import { useSystemwidePromotionMessageQuery } from 'generated/sanity-graphql';

import { Text, Wrapper } from './promo-message.styled';

interface PromoMessageProps {
  promoId: string;
}

const PromoMessage: FC<React.PropsWithChildren<PromoMessageProps>> = ({ promoId }) => {
  const { data } = useSystemwidePromotionMessageQuery({
    variables: {
      id: promoId,
    },
    skip: !promoId,
  });

  const promoMessage = data?.SystemwidePromotion?.promoMessage?.locale;

  if (!promoMessage) {
    return null;
  }

  return (
    <Wrapper>
      <Text>{promoMessage}</Text>
    </Wrapper>
  );
};

export default PromoMessage;
