import React from 'react';

import { BackArrowHistory as BackArrow } from 'components/navigation/back-arrow';
import { useNavigation } from 'hooks/navigation/use-navigation';
import { routes } from 'utils/routing';

import { IMainMenuLink } from './types';

const MainMenuLink: React.FC<React.PropsWithChildren<IMainMenuLink>> = ({ backToFullMenu }) => {
  const { goBack, navigate } = useNavigation();

  const onClickCallback = backToFullMenu ? () => navigate(routes.menu) : () => goBack();
  return <BackArrow onPress={onClickCallback} />;
};

export default MainMenuLink;
