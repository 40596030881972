import React, { ComponentProps, FC } from 'react';

import { VStack } from '@rbilabs/universal-components';
import { Header, HeaderBackButton } from '@react-navigation/elements';
import { useNavigation } from '@react-navigation/native';

import MobileHeaderCta from 'components/app-header/mobile-header/mobile-header-cta';
import { TopServiceMode } from 'components/top-service-mode';
import { TabNavigatorHeader_SimplifiedLandingPage__Experiment } from 'experiments/simplified-landing-page';

import { HeaderLogo } from './header-logo';
import { useHeaderStyles } from './use-header-styles';

type Props = {
  navigation: any;
  headerOptions: Omit<ComponentProps<typeof Header>, 'title'>;
};

const headerPaddingX = 16;

const _TabNavigatorHeader: FC<React.PropsWithChildren<Props>> = ({ navigation, headerOptions }) => {
  const { backgroundColor, iconColor, height } = useHeaderStyles();
  const { canGoBack, goBack } = useNavigation();
  const HeaderLeft = canGoBack()
    ? () => <HeaderBackButton tintColor={iconColor} onPress={goBack} />
    : undefined;
  return (
    <VStack safeAreaTop>
      <Header
        title=""
        headerTitleAlign="left"
        headerStatusBarHeight={0}
        headerTitle={() => <HeaderLogo navigation={navigation} />}
        headerTitleContainerStyle={{ marginLeft: 0 }}
        headerRight={MobileHeaderCta}
        headerLeft={HeaderLeft}
        headerLeftContainerStyle={{
          paddingLeft: headerPaddingX,
        }}
        headerRightContainerStyle={{
          paddingRight: headerPaddingX,
        }}
        headerStyle={{
          backgroundColor,
          height,
        }}
        {...headerOptions}
      />
      <TopServiceMode />
    </VStack>
  );
};

export const TabNavigatorHeader: FC<React.PropsWithChildren<Props>> = props => (
  <TabNavigatorHeader_SimplifiedLandingPage__Experiment>
    <_TabNavigatorHeader {...props} />
  </TabNavigatorHeader_SimplifiedLandingPage__Experiment>
);
