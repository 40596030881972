import { Button } from '@rbilabs/universal-components';

import { LinkButtonProps } from './types';

export const LinkButton = Button.withConfig(({ fontSize = 'md' }: LinkButtonProps) => ({
  variant: 'ghost',
  _text: {
    fontSize,
    textDecorationLine: 'underline',
  },
  _web: {
    cursor: 'pointer',
    _hover: {
      _text: {
        color: Styles.color.tertiary,
      },
    },
  },
}));
