import { ComponentProps } from 'react';

import { Box, Text, VStack } from '@rbilabs/universal-components';

import ActionButton from 'components/action-button';

const ImageSizeMap = {
  small: '55px',
  medium: '60px',
  large: '70px',
  extraLarge: '80px',
};

export const Wrapper = Box.withConfig({ flexShrink: 1 });

export const ImageContainer = Box.withConfig<{ $size?: string }>(({ $size }) => ({
  padding: 0,
  margin: 0,
  marginRight: '$3',
  height: $size ? ImageSizeMap[$size] : ImageSizeMap.medium,
  width: $size ? ImageSizeMap[$size] : ImageSizeMap.medium,
  // for quantity badge positioning
  position: 'relative',
  justifyContent: 'center',
}));

export const DetailsContainer = VStack.withConfig({
  flexShrink: 1,
  alignSelf: 'center',
  justifyContent: 'flex-start',
  // maxWidth: '178px',
  marginRight: '$3',
});

export const ProductName = Text.withConfig<{ $isProductNameBold?: boolean }>(
  ({ $isProductNameBold }) => ({
    variant: $isProductNameBold ? 'headerThree' : 'copyOne',
    margin: '0',
    numberOfLines: 2,
    fontSize: 'sm',
    maxWidth: '$48',
  })
);

export const ProductDescription = Text.withConfig({
  variant: 'copyTwo',
  fontSize: 'xs',
  margin: 0,
});

export const Layout = Box.withConfig<{ $inline?: boolean }>(({ $inline }) => ({
  flexDirection: $inline ? 'row' : 'column',
  justifyContent: $inline ? 'flex-start' : 'center',
  alignItems: 'flex-start',
}));

export const SelectionsListItem = Box.withConfig({
  _text: {
    variant: 'copyTwo',
    fontSize: 'xs',
  },
});

export const actionButtonStyles: ComponentProps<typeof ActionButton> = {
  margin: 0,
  padding: 0,
  alignSelf: 'flex-start',
  _text: {
    textDecorationLine: 'underline',
  },
};
