import { RewardCategory } from 'hooks/use-feature-loyalty-reward-list-categories-only';

import { BannerTier } from './types';

// Removes all characters that are not numbers
export const sanitizeStringToNumber = (value: string): number => {
  if (!value) {
    return 0;
  }
  return Number(value.replace(/[^0-9]/g, ''));
};

export const getProgressBarValue = (userPoints: number, tiers: BannerTier[]): number => {
  if (userPoints === 0) {
    return 0;
  }

  // Adding 0 Tier (default min value) to the list.
  const allTiers: BannerTier[] = [{ id: '0', value: '0' }, ...tiers];

  // Distance between Tiers (in %)
  const tierWidth = 100 / tiers.length;

  let progressBarValue = 0;
  allTiers.forEach((tier: BannerTier) => {
    const currentTier = allTiers.indexOf(tier);
    const lastTierIndex = allTiers.length - 1;
    const nextTier = currentTier < lastTierIndex ? currentTier + 1 : lastTierIndex;
    const currentNumber = sanitizeStringToNumber(tier.value);
    const nextNumber = sanitizeStringToNumber(allTiers[nextTier].value);

    // Exact match with a tier
    if (userPoints === currentNumber) {
      progressBarValue = tierWidth * currentTier;
    }
    if (userPoints === nextNumber) {
      progressBarValue = tierWidth * nextTier;
    }

    // UserPoints are between tiers
    if (userPoints > currentNumber && userPoints < nextNumber) {
      const pointDiff = userPoints - currentNumber;
      const diffBetweenTiers = nextNumber - currentNumber;

      // Calculates the percentage between one tier and another
      const aux = pointDiff / diffBetweenTiers;
      progressBarValue = (currentTier + aux) * tierWidth;
    }

    // Handles exceeding max tier
    if (currentTier === allTiers.length - 1 && userPoints > currentNumber) {
      progressBarValue = tierWidth * currentTier;
    }
  });

  // If percentage is too low, show at least 2% of progress
  return userPoints > 0 && progressBarValue < 2 ? 2 : progressBarValue;
};

export const mapCategoriesToTiers = (categories: RewardCategory[]) => {
  if (!categories || categories.length === 0) {
    return [];
  }
  return categories.map((category: RewardCategory, index: number) => ({
    id: category?._id || index.toString(),
    value: category?.label?.en || '0',
  }));
};
