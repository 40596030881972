import { Box, Header, Text, addWithConfig } from '@rbilabs/universal-components';

import ActionButtonLink from 'components/action-button';

export const EmptyStateWrapper = Box.withConfig({
  marginTop: '$8',
  paddingX: '$4',
  alignItems: 'center',
  justifyContent: 'center',
  color: '#432726',
  textAlign: 'center',
});

export const StyledActionButtonLink = addWithConfig(ActionButtonLink).withConfig({
  marginTop: '$6',
  _text: { fontFamily: 'alternative' },
});

export const HeaderText = Header.withConfig({
  variant: 'headerFour',
  textAlign: 'center',
  margin: 0,
  fontSize: '2xl',
  lineHeight: 'md',
  marginTop: '$5',
  fontWeight: Styles.fontWeight.black,
});

export const SubText = Text.withConfig({
  marginX: '$16',
  marginTop: '$1',
  fontSize: 'md',
  textAlign: 'center',
});

export const ImageWrapper = Box.withConfig({
  width: '$40',
  height: '$40',
});
