import React, { createContext, useCallback, useContext, useMemo, useState } from 'react';

import imageUrlBuilder from '@sanity/image-url';
import {
  ImageUrlBuilderOptionsWithAliases,
  SanityImageSource,
} from '@sanity/image-url/lib/types/types';
import { Platform } from 'react-native';
import { IFormatCurrencyProps, formatCurrency } from 'utils';

import { IBaseProps } from '@rbi-ctg/frontend';
import { useConfigValue } from 'hooks/configs/use-config-value';
import useEffectOnce from 'hooks/use-effect-once';
import buildImageUrlUtil from 'remote/build-image-url';
import { useAuthContext } from 'state/auth';
import { UserDetails } from 'state/auth/hooks/types';
import { useLocale } from 'state/intl';
import { PROD_DEFAULT_REGION } from 'state/intl/constants';
import { LANGUAGES, LOCALES, REGIONS } from 'state/intl/types';
import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';
// TODO: RN - locale switching -- shouldn't this pull from config?
import { sanityDataset as defaultSanityDataset } from 'utils/environment';
import { ISOs, ISOsToRegions, getCountryAndCurrencyCodes } from 'utils/form/constants';
import { setIsWebViewVisible } from 'utils/ui';

export type ImageBuilderType = ReturnType<typeof imageUrlBuilder>;

export type BuildImageUrlType = (
  source: SanityImageSource,
  options?: Partial<ImageUrlBuilderOptionsWithAliases>
) => string;

export type FormatCurrencyForType = (amount: number) => string;

export type FormatCardNumberForType = (cardNumber: string) => string;
export interface IAppDimensions {
  top: number;
  left: number;
  width: number;
  height: number;
}

export interface IUIContext {
  isShowingStoreInfoDialog: boolean;
  isAccountDrawerOpen: boolean;
  userEmail: string | null;
  setIsAccountDrawerOpen: (isOpen: boolean) => void;
  setUserEmail: (email: string) => void;
  setIsWebViewVisible(isVisible: boolean): void;
  showStoreInfoDialog: () => void;
  hideStoreInfoDialog: () => void;
  imageBuilder: ImageBuilderType;
  buildImageUrl: (
    source: SanityImageSource,
    options?: Partial<ImageUrlBuilderOptionsWithAliases>
  ) => string;
  shouldConfirmStoreOnCheckout(flag: boolean): void;
  confirmStoreOnCheckout: boolean;
  formatCurrencyForLocale(amount: number): string;
  formatCurrencyForUser(amount: number | undefined): string;
  currencySymbol: string;
  currencyIsSuffix: boolean;
}

interface GetFormatCurrenctForUserOptions {
  user: UserDetails | null;
  locale: string;
  region: 'US' | 'CH' | 'CA' | 'DE' | 'GB' | 'ZA' | 'NZ';
}

export const getFormatCurrencyForUser = ({
  user,
  locale,
  region,
}: GetFormatCurrenctForUserOptions) => (amount: number) => {
  const userISOCountryCode = user?.details?.isoCountryCode || PROD_DEFAULT_REGION;
  const { currencyCode, countryCode } = getCountryAndCurrencyCodes(ISOs[userISOCountryCode]);

  const formatOptions: IFormatCurrencyProps = {
    language: locale,
    currency: currencyCode,
    // React native does not currently suport the `narrowSymbol` option
    // with their Hermes engine.
    currencyDisplay:
      Platform.OS === 'web' ? (countryCode === region ? 'narrowSymbol' : 'symbol') : 'symbol',
    amount,
  };

  return formatCurrency(formatOptions);
};

interface GetFormatCurrenctForLocaleOptions {
  user: UserDetails | null;
  language: string;
  region: 'US' | 'CH' | 'CA' | 'DE' | 'GB' | 'ZA' | 'NZ';
}

export const getFormatCurrencyForLocale = ({
  user,
  language,
  region,
}: GetFormatCurrenctForLocaleOptions) => (amount: number) => {
  const userISOCountryCode = user?.details?.isoCountryCode;
  const userRegion = ((userISOCountryCode && ISOsToRegions[userISOCountryCode]) ||
    PROD_DEFAULT_REGION) as REGIONS;

  const userLocale = `${language}-${userRegion}` as LOCALES;

  const { currencyCode } = getCountryAndCurrencyCodes(ISOs[region]);

  const formatOptions: IFormatCurrencyProps = {
    language: userLocale,
    currency: currencyCode,
    // React native does not currently suport the `narrowSymbol` option
    // with their Hermes engine.
    currencyDisplay:
      Platform.OS === 'web' ? (userRegion === region ? 'narrowSymbol' : 'symbol') : 'symbol',
    amount,
  };

  return formatCurrency(formatOptions);
};

export const UIContext = createContext<IUIContext>({} as IUIContext);
export const useUIContext = () => useContext(UIContext);

export const UIProvider = ({ children }: IBaseProps) => {
  const { user } = useAuthContext();
  const { language, region, locale } = useLocale();
  const [confirmStoreOnCheckout, setConfirmStoreOnCheckout] = useState(true);
  const [isShowingStoreInfoDialog, setIsShowingStoreInfoDialog] = useState<boolean>(false);
  const [isAccountDrawerOpen, setIsAccountDrawerOpen] = useState(false);
  const [userEmail, setUserEmail] = useState<string | null>(null);
  const enableStoreConfirmationModal = useFlag(LaunchDarklyFlag.ENABLE_STORE_CONFIRMATION_MODAL);
  const sanityProjectId = useConfigValue({ key: 'sanityProjectId', isRegionalized: false });

  useEffectOnce(() => {
    // Just in case there was an issue re-showing webview
    // Set isVisible on mount
    setIsWebViewVisible(true);
  });

  const showStoreInfoDialog = useCallback(() => {
    setIsShowingStoreInfoDialog(true);
  }, [setIsShowingStoreInfoDialog]);

  const hideStoreInfoDialog = useCallback(() => {
    setIsShowingStoreInfoDialog(false);
  }, [setIsShowingStoreInfoDialog]);

  const shouldConfirmStoreOnCheckout = useCallback(
    (flag: boolean) => {
      if (enableStoreConfirmationModal) {
        setConfirmStoreOnCheckout(flag);
      }
    },
    [setConfirmStoreOnCheckout, enableStoreConfirmationModal]
  );

  const imageBuilder = useMemo(() => {
    const dataset = region
      ? `${defaultSanityDataset()}_${region.toLowerCase()}`
      : defaultSanityDataset().toLowerCase();

    return imageUrlBuilder({ dataset, projectId: sanityProjectId });
  }, [region, sanityProjectId]);

  const buildImageUrl: BuildImageUrlType = useCallback(
    (source, options = {}) => buildImageUrlUtil(imageBuilder, source, options),
    [imageBuilder]
  );

  /**
   * Use the user profile's isoCountryCode to determine the locale.
   * Use the site's locale to determine currency.
   * Useful for product prices which are displayed in the currency of the site (e.g. show a US user '$CA' on the fr-CA and en-CA sites)
   */
  const formatCurrencyForLocale: FormatCurrencyForType = useCallback(
    getFormatCurrencyForLocale({ user, region, language }),
    [user, region, language]
  );

  /**
   * Use the site's locale as the formatted number's locale
   * Use the user profile's isoCountryCode to determine the currency,
   * Useful for showing user an amount that they are going to pay in their account's currency (e.g. reloading a pre-paid/TimCard)
   */
  const formatCurrencyForUser: FormatCurrencyForType = useCallback(
    getFormatCurrencyForUser({ user, locale, region }),
    [user, locale, region]
  );

  const currencySymbol = useMemo(() => {
    return formatCurrencyForLocale(0)
      .replace(/\d+(,|\.)\d+/, '')
      .trim();
  }, [formatCurrencyForLocale]);

  const currencyIsSuffix = useMemo(() => {
    return language.toString() === LANGUAGES.fr.toString();
  }, [language]);

  const value = useMemo(
    () => ({
      userEmail,
      isShowingStoreInfoDialog,
      isAccountDrawerOpen,
      setIsAccountDrawerOpen,
      setUserEmail,
      hideStoreInfoDialog,
      showStoreInfoDialog,
      buildImageUrl,
      imageBuilder,
      confirmStoreOnCheckout: enableStoreConfirmationModal && confirmStoreOnCheckout,
      shouldConfirmStoreOnCheckout,
      formatCurrencyForLocale,
      formatCurrencyForUser,
      currencySymbol,
      currencyIsSuffix,
      setIsWebViewVisible,
    }),
    [
      buildImageUrl,
      confirmStoreOnCheckout,
      currencyIsSuffix,
      currencySymbol,
      enableStoreConfirmationModal,
      formatCurrencyForLocale,
      formatCurrencyForUser,
      hideStoreInfoDialog,
      imageBuilder,
      isShowingStoreInfoDialog,
      isAccountDrawerOpen,
      shouldConfirmStoreOnCheckout,
      showStoreInfoDialog,
      userEmail,
    ]
  );

  return <UIContext.Provider value={value}>{children}</UIContext.Provider>;
};
