import React from 'react';

import { Box } from '@rbilabs/universal-components';

import { INutritionInfo } from '@rbi-ctg/menu';
import { useFeatureNutrition } from 'hooks/use-feature-nutrition';

import { NutritionTable } from './nutrition-table';

type NutritionProps = {
  nutritionInfo: INutritionInfo;
};

export const Nutrition: React.FC<React.PropsWithChildren<NutritionProps>> = ({ nutritionInfo }) => {
  const { nutritionTables, roundNutritionValue, featureNutritionLoading } = useFeatureNutrition();

  if (featureNutritionLoading || !nutritionTables.length) {
    return null;
  }

  return (
    <Box testID="nutrition-v2">
      {nutritionTables.map(
        (table, index) =>
          table && (
            <NutritionTable
              key={table._key || index}
              nutritionInfo={nutritionInfo}
              nutritionTable={table}
              roundNutritionValue={roundNutritionValue}
            />
          )
      )}
    </Box>
  );
};
