import { ActionButtonVariants } from 'components/action-button';

import ThemeBase from './theme-base';

export default {
  ...ThemeBase,
  pickup: {
    ...ThemeBase.pickup,
    icon: 'restaurant',
  },
  catering: {
    ...ThemeBase.catering,
    pickup: {
      ...ThemeBase.catering.pickup,
      icon: 'catering',
    },
  },
  iconContainer: {
    ...ThemeBase.iconContainer,
    color: 'coffeeBrown',
  },
  inProgressVariant: ActionButtonVariants.INVERSE,
} as const;
