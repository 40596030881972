import React, { useEffect } from 'react';

import ErrorNetwork from 'components/error-network';
import { ForceUpdateModal } from 'components/force-update-modal';
import LanguageSelectorModal from 'components/language-selector-modal';
import { useLayoutLanguageSelectorModal } from 'components/layout/use-layout-language-selector-modal';
import { useNavigation } from 'hooks/navigation/use-navigation';
import { useResetStoreResetCartResetServiceModeTimeout } from 'hooks/reset-store-timeout';
import { useDatadogRum } from 'hooks/rum/use-datadog-rum';
import useEffectOnce from 'hooks/use-effect-once';
import { useForter } from 'hooks/use-forter';
import { useGeoData } from 'hooks/use-geo-data';
//import { useIosAtt } from 'hooks/use-ios-att';
import { useOfflineRedirect } from 'hooks/use-offline-redirect';
//import { DeeplinksListener } from 'state/branch/deeplinks-listener';
import { useCRMEventsContext } from 'state/crm-events';
import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';
import { checkForOSPrompts } from 'state/location/routes';
import { DeeplinkEventArgs, EventName, addEventListener } from 'utils/event-hub';
import { initGoogleTagManager } from 'utils/google-tag-manager';

import { AppContainer as AppContainerWeb } from './app-container-web';
import { Wrapper } from './wrapper';

/**
 * Once the React Navigate is fully tested and in PROD, we can deprecate the ENABLE_REACT_NATIVE_NAVIGATION feature flag.
 * At which point this file will have its web counterpart instead of app-container-rn and app-container-web.
 */
export const AppContainer: React.VFC = React.memo(() => {
  //const requestIosAttPermissions = useIosAtt();
  const { showLanguageSelectorModal, handleModalDismiss } = useLayoutLanguageSelectorModal();
  const { logRBIEvent } = useCRMEventsContext();
  const { navigate } = useNavigation();
  const enableGoogleTagManager = useFlag(LaunchDarklyFlag.ENABLE_GOOGLE_TAG_MANAGER);

  useResetStoreResetCartResetServiceModeTimeout();
  useDatadogRum();
  useForter();
  useOfflineRedirect();
  useGeoData();

  useEffect(() => {
    if (enableGoogleTagManager) {
      initGoogleTagManager();
    }
  }, []);

  // Initialize Deeplink listeners
  useEffectOnce(() => {
    addEventListener<DeeplinkEventArgs>(EventName.DEEPLINK, deeplinkArgs => {
      // Ensure removing deeplink extra slash for RN
      const sanitizeRegex = /[/]+/;
      const deepLinkPath = deeplinkArgs.route.replace(sanitizeRegex, '/');

      if (!checkForOSPrompts(deepLinkPath, logRBIEvent)) {
        navigate(deepLinkPath);
      }
    });

    // Ensure deep link listener singleton is instantiated
    // TODO: RN-WEB - Implement Branch deeplinks
    //DeeplinksListener.getInstance();
  });

  return (
    <Wrapper>
      <ForceUpdateModal />
      <AppContainerWeb />
      {/* Stuff rendered outside the typical flow */}
      {showLanguageSelectorModal ? (
        <LanguageSelectorModal onModalDismiss={handleModalDismiss} />
      ) : null}
      <ErrorNetwork />
    </Wrapper>
  );
});
