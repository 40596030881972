import delv from 'dlv';

import {
  IItemOption,
  IItemOptionModifier,
  IModifierMultiplier,
  IModifierSelection,
  IQuickConfig,
  ISanityItem,
  ISanityItemOption,
  ISanityItemOptionModifier,
  ISanityTextNode,
  ISanityVendorConfigs,
  IVendorConfig,
} from '@rbi-ctg/menu';

import { PosVendors } from '../vendor-config';

export enum ModifierComponentStyle {
  Stepper = 'stepper',
  Boolean = 'boolean',
  Selector = 'selector',
}

interface IChooseConfigArgs {
  modifierConfig?: IVendorConfig | null;
  modifierMultiplierConfig?: IVendorConfig | null;
  itemOptionModifierConfig?: IVendorConfig | null;
}

const configForModifier = ({
  modifierConfig,
  modifierMultiplierConfig,
  itemOptionModifierConfig,
}: IChooseConfigArgs): IVendorConfig | null => {
  return [
    itemOptionModifierConfig,
    modifierMultiplierConfig,
    modifierConfig,
  ].reduce<IVendorConfig | null>(
    (acc: IVendorConfig | null, vendorConfigObj?: IVendorConfig | null) => {
      if (acc) {
        return acc;
      }
      if (vendorConfigObj) {
        return vendorConfigObj;
      }
      return null;
    },
    null
  );
};

const getModifierMultiplierVendorConfigs = (
  vendorConfigs: ISanityVendorConfigs | undefined,
  modifierMultiplier: IModifierMultiplier
): ISanityVendorConfigs => {
  const modifierMultiplierConfigs: ISanityVendorConfigs =
    delv(modifierMultiplier, 'vendorConfigs') || {};
  const modifierConfigs: ISanityVendorConfigs =
    delv(modifierMultiplier, 'modifier.vendorConfigs') || {};

  return Object.values(PosVendors).reduce<ISanityVendorConfigs>(
    (acc: ISanityVendorConfigs, vendor: PosVendors) => ({
      ...acc,
      [vendor]: configForModifier({
        itemOptionModifierConfig: (vendorConfigs || {})[vendor],
        modifierMultiplierConfig: modifierMultiplierConfigs[vendor],
        modifierConfig: modifierConfigs[vendor],
      }),
    }),
    {}
  );
};

const composeName = (modifierMultiplier: IModifierMultiplier): ISanityTextNode => {
  return {
    locale: `${delv(modifierMultiplier, 'prefix.locale', '')} ${delv(
      modifierMultiplier,
      'modifier.name.locale',
      ''
    )}`,
  };
};

export function modifiersForItem(item: ISanityItem) {
  const itemModifiers = (item.options || []).reduce(
    (acc: object, itemOption: ISanityItemOption) => {
      return {
        ...acc,
        [itemOption._key]: {
          ...itemOption,
          options: [
            ...(itemOption.options || []).map((option: ISanityItemOptionModifier) =>
              makeItemOptionModifier(option)
            ),
          ],
        },
      };
    },
    {}
  );

  const options = Object.keys(itemModifiers).map((_key: string) => {
    return itemModifiers[_key];
  });

  const newItem = {
    ...item,
    options,
  };
  return newItem;
}

export function makeItemOptionModifier(option: ISanityItemOptionModifier) {
  const { _key, name, _type, type, modifierMultiplier, vendorConfigs } = option;
  const image = delv(modifierMultiplier, 'modifier.image', null);
  const imageDescription = delv(modifierMultiplier, 'modifier.imageDescription', null);

  return {
    _key,
    _type: _type || type,
    image,
    imageDescription,
    default: option.default,
    name: name?.locale ? name : composeName(modifierMultiplier),
    nutrition: option.nutrition || {},
    multiplier: delv(modifierMultiplier, 'multiplier', null),
    quantity: 1,
    modifierMultiplier,
    vendorConfigs: getModifierMultiplierVendorConfigs(vendorConfigs, modifierMultiplier),
  };
}

export interface IGroupedItemOption {
  title: string;
  options: ISanityItemOption[];
}

// create an array of displayGroups with `title` and `options`
// based on itemOption.displayGroup or the name of the itemOption
// if no displayGroup is defined
export function groupItemOptionsUsingDisplayGroups(
  itemOptions: ISanityItemOption[]
): IGroupedItemOption[] {
  const groupedItemOptions = itemOptions.reduce<{ [identifier: string]: ISanityItemOption[] }>(
    (acc, itemOption) => {
      const identifier =
        delv(itemOption, 'displayGroup.name.locale') || delv(itemOption, 'name.locale', '');

      return {
        ...acc,
        [identifier]: (acc[identifier] || []).concat(itemOption),
      };
    },
    {}
  );

  const options = Object.entries(groupedItemOptions).map(([title, entryItemOptions]) => ({
    options: entryItemOptions,
    title,
  }));

  return options;
}

// @todo refactor
const findModifierSelectionInSelections = (
  modifier: ISanityItemOption | ISanityItemOptionModifier,
  modifierSelections: IModifierSelection[],
  comboSlotId?: string,
  itemOption?: ISanityItemOption
) => {
  return modifierSelections.find(
    ({ _key: itemOptionKey, comboSlotId: selectedComboSlotId, modifier: { _key: modifierKey } }) =>
      (modifier._key === modifierKey || modifier._key === itemOptionKey) &&
      (itemOption ? itemOption._key === itemOptionKey : true) &&
      comboSlotId === selectedComboSlotId
  );
};

// @todo refactor
export function isModifierSelected(
  modifier: ISanityItemOption | ISanityItemOptionModifier,
  modifierSelections: IModifierSelection[],
  comboSlotId?: string,
  itemOption?: ISanityItemOption
) {
  const selection = findModifierSelectionInSelections(
    modifier,
    modifierSelections,
    comboSlotId,
    itemOption
  );
  // if we are checking if an itemOptionModifier is selected,
  // its as simple as seing if the selection is in the modifierSelections
  // @ts-expect-error TS(2551) FIXME: Property 'type' does not exist on type 'ISanityIte... Remove this comment to see the full error message
  if (modifier._type === 'itemOptionModifier' || modifier.type === 'itemOptionModifier') {
    return !!selection;
  }
  // if the modifier is an itemOption, then we have to see if the item is
  // in the modifierSelections, and check to see if index[1] is the active modifier.
  // with boolean type selections, the modifier options are expected to be 2, and the
  // index 1 modifier means selected.
  // this path gets ran when the item is rendered as part of a displayGroup, in which case
  // the rendered row is actually an itemOption, instead of an itemOptionModifier

  if (!selection) {
    return false;
  }

  if (modifier.options.length === 2) {
    return modifier.options[1]._key === selection.modifier._key;
  }

  return false;
}

// If a modifier has a componentStyle of 'stepper', the selected modifier is actually just an
// index in the itemOption.options array. As the user moves along we just move indexes.
// We can derive the index by finding the matching selection from modifierSelections, then
// seeing where in the array of itemOption.options it exists.
export function getStepperSelectionIndex(
  option: ISanityItemOption,
  modifierSelections: IModifierSelection[],
  comboSlotId?: string
) {
  const selection = findModifierSelectionInSelections(option, modifierSelections, comboSlotId);

  if (!selection) {
    return 0;
  }

  return delv(option, 'options', []).findIndex(
    ({ _key }: { _key: string }) => _key === delv(selection, 'modifier._key')
  );
}

export function modifierSelectedQuantity(
  option: ISanityItemOption | ISanityItemOptionModifier,
  modifierSelections: IModifierSelection[],
  comboSlotId?: string
) {
  const selection = findModifierSelectionInSelections(option, modifierSelections, comboSlotId);

  if (!selection) {
    return 0;
  }

  return selection.modifier.quantity;
}

// A modifier group that has a child with a componentStyle of 'selector' should only
// ever contain one child, and we should be looking through that child's options for
// the selected optionModifier. Possible active optionModifiers live in:
// group.options[0].options
// NOTE selector is the default component style, it may not always be set explicitly.
export function getSelectorSelectionIndex(
  modifierGroup: {
    options: ISanityItemOption[];
  },
  modifierSelections: IModifierSelection[],
  comboSlotId?: string
) {
  const itemOption = delv(modifierGroup, 'options.0');

  if (!itemOption) {
    return -1;
  }

  const groupComponentStyle = itemOption.componentStyle || ModifierComponentStyle.Selector;

  return groupComponentStyle === ModifierComponentStyle.Selector
    ? (
        itemOption.options || []
      ).findIndex((optionModifier: ISanityItemOption | ISanityItemOptionModifier) =>
        isModifierSelected(optionModifier, modifierSelections, comboSlotId, itemOption)
      )
    : -1;
}

// This is used to gauge which name to display for an itemOption's
// option with a componentStyle 'stepper' given an index
export const curriedModifierMultiplierNameFromIndex = (modifier: ISanityItemOption) => (
  index: number
) => {
  // These names are long, but let's try to be
  // descriptive and semantically correct in wizzard.
  const itemOptionModifierMultiplierValue = delv(modifier, `options.${index}.multiplier`, '');
  const itemOptionModifierName = delv(
    modifier,
    `options.${index}.name.locale`,
    itemOptionModifierMultiplierValue
  );
  const itemOptionModifierMultiplierPrefix = delv(
    modifier,
    `options.${index}.modifierMultiplier.prefix.locale`,
    itemOptionModifierMultiplierValue
  );
  const isItemOptionModifierMultiplierPrefixDisplayed = modifier.displayModifierMultiplierName;

  return isItemOptionModifierMultiplierPrefixDisplayed
    ? itemOptionModifierMultiplierPrefix
    : itemOptionModifierName;
};

/**
 * determine if a boolean or stepper modifier
 * selection is the default or a smaller multiplier
 * than the default
 */
export function isDefaultOrLess(
  itemOption: ISanityItemOption | IItemOption,
  modifier: IItemOptionModifier & { quantity?: number }
): boolean {
  if (modifier.default) {
    return true;
  }

  const defaultModifier = (itemOption as IItemOption).options.find(
    ({ default: isDefault }) => isDefault
  );
  const modMultiplier = delv(modifier, 'multiplier') || 0;
  const defaultModMultiplier = delv(defaultModifier!, 'multiplier') || 0;
  return modMultiplier <= defaultModMultiplier;
}

const isQuickConfigActive = (
  modifierSelections: Array<{ modifier: { _key: string } }>,
  quickConfigs?: IQuickConfig[]
) =>
  quickConfigs?.some(config =>
    config.rules.every(rule =>
      modifierSelections.some(selection => selection.modifier._key === rule.modifier.value)
    )
  );

export const isSelectionGovernedByConfigRules = (
  selection: { _key: string },
  modifierSelections: IModifierSelection[],
  quickConfigs?: IQuickConfig[]
) =>
  !isQuickConfigActive(modifierSelections, quickConfigs) &&
  quickConfigs?.some(({ rules }) =>
    rules.some(({ itemOptions }) => itemOptions.some(({ value }) => value === selection._key))
  );

export const getModifierSelectionsText = (
  options: ISanityItemOption[],
  modifierSelections: IModifierSelection[],
  quickConfigs?: IQuickConfig[]
): string =>
  options.reduceRight((accumulatedSelectionText: string, option: ISanityItemOption) => {
    const itemOptionKey = option._key;
    const selection = modifierSelections.find(
      selectionItem => selectionItem._key === itemOptionKey
    );
    if (!selection) {
      return accumulatedSelectionText;
    }

    if (
      selection.modifier.default &&
      !isSelectionGovernedByConfigRules(selection, modifierSelections, quickConfigs)
    ) {
      return accumulatedSelectionText;
    }

    const selectionText = delv(selection, 'modifier.name.locale') || '';

    return accumulatedSelectionText
      ? `${selectionText}, ${accumulatedSelectionText}`
      : selectionText;
  }, '');

export const filterExcludedItemOptions = (excludes: { value: string }[]) => <
  Options extends { _key: string }[]
>(
  options: Options
): Options => {
  return options.filter(opt => !excludes.find(({ value }) => opt._key === value)) as Options;
};

export { getDefaultModifiersForMenuItem } from './get-default-modifiers';
export { onlyDefaultModifierIsAvailable } from './only-default-modifier-is-available';
export { shouldDisplayItemOptionGroup } from './should-display-item-option-group';
export { shouldDisplayItemOption } from './should-display-item-option';
