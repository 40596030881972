import * as React from 'react';
import { FC } from 'react';

import { useIntl } from 'react-intl';

import {
  RewardDetails,
  RewardTextContainer,
  RewardTitle,
} from './loyalty-points-meter-widget.styled';
import { IRewardText } from './types';

export const RewardText: FC<React.PropsWithChildren<IRewardText>> = ({
  hasReward,
  nextTierPointsNeeded,
}) => {
  const { formatMessage } = useIntl();

  const rewardTitle = hasReward
    ? formatMessage({ id: 'haveFreeReward' })
    : formatMessage({ id: 'earnPointsToRedeem' });
  const rewardText = nextTierPointsNeeded
    ? formatMessage({ id: 'xPointsUntilReward' }, { points: nextTierPointsNeeded })
    : formatMessage({ id: 'usePointsToday' });

  return (
    <RewardTextContainer>
      <RewardTitle>{rewardTitle}</RewardTitle>
      <RewardDetails>{rewardText}</RewardDetails>
    </RewardTextContainer>
  );
};
