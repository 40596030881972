import React, { FC } from 'react';

import { Box, Icon, Pressable } from '@rbilabs/universal-components';

import { IPickerAspectUiPattern } from '@rbi-ctg/menu';
import { RotateAnimatedView } from 'components/ucl';
import { useToggleState } from 'hooks/use-toggle-state';
import { isUiPatternRadio } from 'state/menu/types';

import {
  RightContainer,
  SelectedOption,
  SelectionOptions,
  SelectionOptionsDescription,
  SelectionOptionsHeader,
  SelectionOptionsTitle,
} from './selection-container.styled';

interface ISelectionContainerInternalStateProps extends IPickerAspectUiPattern {
  title?: React.ReactNode;
  focusOnMount?: boolean;
  openOnMount?: boolean;
  required?: boolean;
  requirementSatisfied?: boolean;
  children: React.ReactNode;
  selection?: string;
  description?: string;
}

interface ISelectionContainerProps extends ISelectionContainerInternalStateProps {
  optionsVisible: boolean;
  toggleOptionsVisible: () => void;
}

export const SelectionContainer: FC<React.PropsWithChildren<ISelectionContainerProps>> = props => {
  const {
    children,
    optionsVisible,
    selection,
    title,
    toggleOptionsVisible,
    uiPattern,
    description,
  } = props;

  const isOpen = optionsVisible || isUiPatternRadio(props);

  return (
    <SelectionOptions testID={`selection-${title}`}>
      {!isUiPatternRadio({ uiPattern }) && (
        <Pressable onPress={() => toggleOptionsVisible()}>
          <SelectionOptionsHeader
            accessibilityLabel={`${title}-selection-content-${
              optionsVisible ? 'expanded' : 'collapsed'
            }`}
          >
            <SelectionOptionsTitle>{title}</SelectionOptionsTitle>
            <RightContainer>
              <SelectedOption>{selection}</SelectedOption>
              <RotateAnimatedView isRotated={optionsVisible}>
                <Icon variant="select" testID="collapse-icon" />
              </RotateAnimatedView>
            </RightContainer>
          </SelectionOptionsHeader>
        </Pressable>
      )}

      {isOpen && (
        <Box testID={`${title}-selection-content`}>
          {description && <SelectionOptionsDescription>{description}</SelectionOptionsDescription>}
          {children}
        </Box>
      )}
    </SelectionOptions>
  );
};

const SelectionContainerInternalState: FC<React.PropsWithChildren<
  ISelectionContainerInternalStateProps
>> = props => {
  const [optionsVisible, toggleOptionsVisible] = useToggleState(Boolean(props.openOnMount));

  return (
    <SelectionContainer
      optionsVisible={optionsVisible}
      toggleOptionsVisible={toggleOptionsVisible}
      {...props}
    />
  );
};

export default SelectionContainerInternalState;
