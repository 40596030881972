import React, { VFC } from 'react';

import { Box, BoxProps } from '@rbilabs/universal-components';

import { theme } from 'styles/configure-theme';

interface RadioIndicatorProps extends BoxProps {
  isSelected: boolean;
  isDisabled?: boolean;
}

export const RadioIndicator: VFC<RadioIndicatorProps> = ({
  isSelected,
  isDisabled,
  ...boxProps
}) => {
  return (
    <Box
      width="$5"
      height="$5"
      borderRadius="full"
      backgroundColor={
        isDisabled ? theme.token('background-input-disabled') : theme.token('background-input')
      }
      borderWidth={isSelected ? 5 : 1}
      borderColor={isSelected ? Styles.color.black : Styles.color.grey.two}
      {...boxProps}
    />
  );
};

export default RadioIndicator;
