import React, { useMemo } from 'react';

import { Box } from '@rbilabs/universal-components';

import { Helmet } from 'components/helmet';
import useMenuByDayPart, { IMenuByDayPartProps } from 'hooks/use-menu-by-day-part';
import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';
import { useMainMenuContext } from 'state/main-menu';
import { useServiceModeContext } from 'state/service-mode';

import { reduceMenuSectionOptions } from '../main-menu/utils';

import MenuItemUnavailable from './item-unavailable';
import { MainMenuLink } from './main-menu-link';
import { RenderInlineTileGrid, RenderStandard } from './menu-section-content-tiles';
import { IMenuContentProps } from './types';
import { useLogMenuContent } from './use-log-menu-content';

export const MenuSectionContent = ({ id, pathname }: IMenuContentProps) => {
  const { getMenuObject, isOffer, isStaticMenu } = useMainMenuContext();
  const { serviceMode } = useServiceModeContext();
  const enableTabbedMenuBar = useFlag(LaunchDarklyFlag.ENABLE_TABBED_MENU_BAR);

  // TODO: There is a type mismatch here, this need to be fixed in a future refactor
  const { data: menuSection } = useMenuByDayPart(getMenuObject(id) as IMenuByDayPartProps);

  const { inlineTileSections, standardSections, totalSectionsLength } = useMemo(
    () =>
      reduceMenuSectionOptions({
        // TODO: There is a type mismatch here, this need to be fixed in a future refactor
        // @ts-ignore
        menuSectionOptions: menuSection?.options,
        isStaticMenu,
        serviceMode,
      }),
    [isStaticMenu, menuSection, serviceMode]
  );

  useLogMenuContent({
    loggedValues: {
      id: menuSection?._id || '',
      name: menuSection?.name?.locale || '',
      menuType: menuSection?._type || '',
    },
    pathname,
  });

  if (!menuSection || totalSectionsLength <= 0) {
    return <MenuItemUnavailable isOffer={isOffer} />;
  }

  return (
    <Box flex="1">
      <Helmet title={menuSection?.name?.locale || ''} />
      <MainMenuLink backToFullMenu={enableTabbedMenuBar} />
      <RenderStandard sections={standardSections} />
      <RenderInlineTileGrid sections={inlineTileSections} />
    </Box>
  );
};
