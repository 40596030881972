import { Header } from '@rbilabs/universal-components';

const SectionHeading = Header.withConfig({
  variant: 'headerTwo',
  color: Styles.color.black,
  marginTop: '$5',
  marginBottom: '$4',
});

export default SectionHeading;
