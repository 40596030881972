import * as React from 'react';

import { Button, HStack, Header, Icon, Text, VStack } from '@rbilabs/universal-components';
import { useIntl } from 'react-intl';

import { theme } from 'styles/configure-theme';

import { getQuestCheckConnectionText, getQuestLoadErrorText } from '../custom-text';

const Wrapper = VStack.withConfig({
  height: '100%',
  alignItems: 'center',
  justifyContent: 'center',
  space: '$6',
  padding: '$4',
});

const ReloadButton = Button.withConfig({
  variant: 'outline',
  borderColor: theme.token('border-color-error'),
  _text: {
    variant: 'copyTwo',
    fontSize: 'sm',
    color: theme.token('text-error'),
  },
});

export const QuestDetailError = ({ refetch }: { refetch: () => void }) => {
  const { formatMessage } = useIntl();

  return (
    <Wrapper>
      <VStack>
        <Header textAlign="center">{getQuestLoadErrorText(formatMessage)}</Header>
        <Text textAlign="center">{getQuestCheckConnectionText(formatMessage)}</Text>
      </VStack>
      <HStack flexShrink={1}>
        <ReloadButton
          leftIcon={<Icon variant="refresh" size="sm" color={theme.token('border-color-error')} />}
          onPress={refetch}
        >
          {formatMessage({ id: 'tryAgain' })}
        </ReloadButton>
      </HStack>
    </Wrapper>
  );
};
