import React, { useEffect, useRef } from 'react';

import { Box } from '@rbilabs/universal-components';
import { Animated, Easing, StyleSheet, ViewStyle, useWindowDimensions } from 'react-native';

import { IBaseProps } from '@rbi-ctg/frontend';
import { isNative } from 'utils/environment';

const LoadingContainer = Box.withConfig({
  height: '$1.5',
  backgroundColor: 'transparent',
  width: 'full',
  overflow: 'hidden',
  zIndex: Styles.zIndex.overlay,
});

const styles = ({ backgroundColor }: ViewStyle) =>
  StyleSheet.create({
    loadingBar: {
      width: '100%',
      height: '100%',
      backgroundColor,
    },
  });
interface ILoadingIndicatorProps extends IBaseProps {
  show: boolean;
  fill?: ViewStyle['backgroundColor'];
}

const LoadingIndicatorBar: React.FC<React.PropsWithChildren<ILoadingIndicatorProps>> = ({
  show,
  fill = Styles.color.secondary,
}) => {
  const { width } = useWindowDimensions();
  const opacity = useRef(new Animated.Value(0));

  const translateXValue = opacity.current.interpolate({
    inputRange: [0, 1],
    outputRange: [-width, width],
  });

  const transformStyle = [
    styles({ backgroundColor: fill }).loadingBar,
    {
      opacity: opacity.current,
      transform: [{ translateX: translateXValue }],
    },
  ];

  const animation = useRef(
    Animated.loop(
      Animated.timing(opacity.current, {
        toValue: 1,
        duration: 2750,
        easing: Easing.bezier(0.86, 0, 0.62, 1),
        useNativeDriver: isNative,
      })
    )
  );

  useEffect(() => {
    opacity.current.setValue(0);
    if (show) {
      animation.current.start();
    } else {
      animation.current.stop();
    }
  }, [show]);

  return show ? (
    <LoadingContainer>
      <Animated.View style={transformStyle} />
    </LoadingContainer>
  ) : null;
};

export default LoadingIndicatorBar;
