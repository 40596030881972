export {
  getDeviceId,
  getSessionId,
  identify,
  Identify,
  init,
  revenue,
  Revenue,
  setDeviceId,
  setOptOut,
  setSessionId,
  setUserId,
  track,
} from '@amplitude/analytics-browser';
