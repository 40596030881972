import { useCallback } from 'react';

import { useNavigation } from 'hooks/navigation/use-navigation';
import { useRoute } from 'hooks/navigation/use-route';
import { routes } from 'utils/routing';

import { OrdersDetailsRouteParams, UseAccountOrdersFlowHook } from './types';

export const useAccountOrdersFlow = (): UseAccountOrdersFlowHook => {
  const { params } = useRoute<OrdersDetailsRouteParams>();
  const routeRbiOrderId = params.rbiOrderId;
  const { navigate } = useNavigation();
  const goToDetailsModal = useCallback(
    (rbiOrderId: any, modalType: any) => {
      navigate(routes.ordersDetails, {
        state: {
          rbiOrderId,
          modalType,
        },
        replace: !!routeRbiOrderId,
      });
    },
    [navigate, routeRbiOrderId]
  );
  return {
    ...params,
    rbiOrderId: routeRbiOrderId,
    goToDetailsModal,
  };
};
