import { ReactNode } from 'react';

export type Props = {
  children: ReactNode;
};

export enum ServiceModeExperimentVariants {
  SHOW_BSM = 'control',
  SHOW_TSM = 'treatment',
}
