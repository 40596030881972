import React from 'react';

import { Text } from '@rbilabs/universal-components';

export const getDateInputLabel = ({ label = '', required = false }) => {
  return (
    <>
      <Text fontSize="md" bold>
        {label}
      </Text>
      {required && <Text color="errorRed">*</Text>}
    </>
  );
};
