import { useCallback } from 'react';

import { useBrazeContext } from 'state/braze';
import { useCRMEventsContext } from 'state/crm-events';
import { useLocale } from 'state/intl';
import { useLDContext } from 'state/launchdarkly';
import ReactAppboy from 'utils/appboy';
import { brand, env } from 'utils/environment';

import { UserDetails } from './types';

export const useThirdPartyAuthentication = () => {
  const { setUserId: brazeSetUserId } = useBrazeContext();
  const { region } = useLocale();
  const { login: launchDarklyLogin, logout: launchDarklyLogout } = useLDContext();
  const { loginCRM, logoutCRM } = useCRMEventsContext();

  const logUserInToThirdPartyServices = useCallback(
    (currentUser: UserDetails) => {
      launchDarklyLogin(currentUser);

      loginCRM({
        customerId: currentUser.thLegacyCognitoId
          ? `us-east-1:${currentUser.thLegacyCognitoId}`
          : currentUser.cognitoId,
        rbiCognitoId: currentUser.cognitoId,
        ...currentUser.details,
        brand: brand().toUpperCase(),
        region,
        env: env() as string,
      });

      brazeSetUserId(
        currentUser.thLegacyCognitoId
          ? `us-east-1:${currentUser.thLegacyCognitoId}`
          : currentUser.cognitoId
      );

      // Update Braze Email for the user
      ReactAppboy.setEmail(currentUser.details.email);
    },
    [brazeSetUserId, launchDarklyLogin, loginCRM, region]
  );

  const logUserOutOfThirdPartyServices = useCallback(() => {
    launchDarklyLogout();
    logoutCRM();
    brazeSetUserId(null);
  }, [brazeSetUserId, launchDarklyLogout, logoutCRM]);

  return { logUserInToThirdPartyServices, logUserOutOfThirdPartyServices };
};
