import { useState } from 'react';

import { Icon } from '@rbilabs/universal-components';
import { useIntl } from 'react-intl';

import ConfirmDialog from 'components/confirm-dialog';
import { DialogActionSheet } from 'components/confirmation-actionsheet';
import { useIsDesktopBp } from 'hooks/breakpoints';
import { useNavigation } from 'hooks/navigation/use-navigation';
import { theme } from 'styles/configure-theme';
import DateSingleton, { DateSingletonInstanceKey } from 'utils/dateTime/DateSingleton';
import { routes } from 'utils/routing';

import { BodyText, CustomText, HeaderText, Wrapper } from './styled';

const dateSingleton = DateSingleton.getInstance(DateSingletonInstanceKey.cateringConfirmation);

const CateringConfirmationDataSheet = () => {
  const { formatMessage } = useIntl();
  const { navigate } = useNavigation();
  const isDesktop = useIsDesktopBp();
  const [isOpen, setIsOpen] = useState<boolean>(() => {
    if (dateSingleton.isDateExpired()) {
      dateSingleton.saveDate();
      return true;
    }
    return false;
  });

  const HeadingComponent = () => (
    <Wrapper>
      <Icon
        variant="catering"
        testID="catering-icon"
        color={theme.token('text-active')}
        alignSelf="center"
        size="20"
      />
      <HeaderText>
        {formatMessage(
          { id: 'cateringDialogTitle' },
          {
            cateringTextComponent: (
              <CustomText color={theme.token('text-active')}>
                {formatMessage({ id: 'catering' })}
              </CustomText>
            ),
          }
        )}
      </HeaderText>
    </Wrapper>
  );

  return (
    <>
      {isDesktop ? (
        <>
          <ConfirmDialog
            showDialog={isOpen}
            onConfirm={() => setIsOpen(false)}
            onDismiss={() => setIsOpen(false)}
            onCancel={() => navigate(routes.storeLocator)}
            confirmLabel={formatMessage({ id: 'confirmCatering' })}
            cancelLabel={formatMessage({ id: 'switchServiceMode' })}
            modalAppearanceEventMessage={formatMessage({ id: 'cateringDialogTitleString' })}
            headingComponent={<HeadingComponent />}
            bodyComponent={<BodyText>{formatMessage({ id: 'cateringDialogBody' })}</BodyText>}
          />
        </>
      ) : (
        <DialogActionSheet
          isOpen={isOpen}
          onConfirm={() => setIsOpen(false)}
          onDismiss={() => setIsOpen(false)}
          onPressDismissButton={() => {
            setIsOpen(false);
            navigate(routes.storeLocator);
          }}
          confirmButtonLabel={formatMessage({ id: 'confirmCatering' })}
          dismissButtonLabel={formatMessage({ id: 'switchServiceMode' })}
          titleString={formatMessage({ id: 'cateringDialogTitleString' })}
          title={<HeadingComponent />}
          body={<BodyText>{formatMessage({ id: 'cateringDialogBody' })}</BodyText>}
        />
      )}
    </>
  );
};

export default CateringConfirmationDataSheet;
