import React, { useState } from 'react';

import { Button, Icon } from '@rbilabs/universal-components';
import { useIntl } from 'react-intl';

import LanguageSelectorModal from 'components/language-selector-modal';
import { useLocale } from 'state/intl';
import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';
import { hiddenAccessibilityPlatformProps } from 'utils/accessibility';
import { formatActiveLanguageForRegion } from 'utils/language/format-active-language-for-region';

export interface OptionalColorProps {
  color?: string;
}

const LanguageSelector: React.FC<React.PropsWithChildren<OptionalColorProps>> = ({
  color = Styles.color.white,
}) => {
  const { formatMessage } = useIntl();
  const { language, region } = useLocale();
  const isLocalizationDisabled = useFlag(LaunchDarklyFlag.DISABLE_LOCALIZATION);
  const [showLanguageSelectorModal, setShowLanguageSelectorModal] = useState(false);

  const onModalDismiss = () => {
    setShowLanguageSelectorModal(false);
  };

  const onModalShow = () => {
    setShowLanguageSelectorModal(true);
  };

  return isLocalizationDisabled ? null : (
    <>
      <Button
        testID="language-selector-button"
        variant="ghost"
        justifyContent="flex-start"
        p="$0"
        onPress={onModalShow}
        color={color}
        leftIcon={<Icon variant="language" color={color} {...hiddenAccessibilityPlatformProps} />}
        accessibilityLabel={formatMessage({ id: 'languageAndRegionSelector' })}
      >
        {formatActiveLanguageForRegion(language, region)}
      </Button>
      {showLanguageSelectorModal && <LanguageSelectorModal onModalDismiss={onModalDismiss} />}
    </>
  );
};

export default LanguageSelector;
