import React from 'react';

import { Box } from '@rbilabs/universal-components';
import { Header } from '@react-navigation/elements';
import { DrawerActions, useNavigation } from '@react-navigation/native';

import MobileHeaderCta from 'components/app-header/mobile-header/mobile-header-cta';
import { HeaderLeft } from 'navigation/header/header-left';
import { theme } from 'styles/configure-theme';
import { NAV_HEIGHT } from 'styles/constants/enums';

const HEADER_PADDING_X = 16;

const HeaderLeftWithNavigation = () => {
  const navigation = useNavigation();
  return <HeaderLeft onPress={() => navigation.dispatch(DrawerActions.toggleDrawer())} />;
};

export function TabNavigatorHeader() {
  return (
    <Box safeAreaTop>
      <Header
        title=""
        headerStatusBarHeight={0}
        headerRight={MobileHeaderCta}
        headerLeft={HeaderLeftWithNavigation}
        headerLeftContainerStyle={{
          paddingLeft: HEADER_PADDING_X,
        }}
        headerRightContainerStyle={{
          paddingRight: HEADER_PADDING_X,
        }}
        headerStyle={{
          backgroundColor: theme.token('background-hero-light'),
          height: NAV_HEIGHT.MOBILE,
        }}
      />
    </Box>
  );
}
