import { useHeroQuery } from 'generated/sanity-graphql';

export const useHero = (id?: string) => {
  const heroId = id ?? '';
  const { data, loading } = useHeroQuery({
    variables: {
      heroId,
    },
    skip: !heroId,
  });

  const hero = data?.Hero;

  return {
    hero,
    heroLoading: loading,
  };
};
