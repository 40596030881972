import React from 'react';

import { Text } from '@rbilabs/universal-components';

import Link from 'components/link';
import { useNavigation } from 'hooks/navigation/use-navigation';

import { useStaticContentLanguage } from '../static-content-language';

import {
  Card,
  CardBody,
  CardContent,
  CardImage,
  CardImageContainer,
  CardTitle,
  Section,
} from './styled';
import { IPreviewWidgetProps } from './types';

export const PreviewWidget = ({ previewContent }: IPreviewWidgetProps) => {
  const { navigate } = useNavigation();
  const { staticContentLanguage: language } = useStaticContentLanguage();
  return (
    <Section>
      {previewContent.map((card, i) => {
        const {
          titleText,
          bodyText,
          linkText,
          linkURL,
          localizedLinkURL,
          previewImage,
          imageDescription,
        } = card;
        const url = localizedLinkURL?.[language] || linkURL || '';

        const shouldRenderLink = !!(linkText && url);

        const navigateToUrl = () => {
          navigate(url);
        };

        return (
          <Card key={`card${i}`}>
            <CardImageContainer>
              <CardImage image={previewImage[language]} alt={imageDescription?.[language] || ''} />
            </CardImageContainer>
            <CardContent>
              {titleText ? <CardTitle>{titleText[language]}</CardTitle> : null}

              {bodyText ? <CardBody>{bodyText[language]}</CardBody> : null}

              {shouldRenderLink ? (
                <Link linkPath={url} onPress={navigateToUrl}>
                  <Text variant="headerThree" color={Styles.color.primary} fontWeight="bold">
                    {linkText[language]}
                  </Text>
                </Link>
              ) : null}
            </CardContent>
          </Card>
        );
      })}
    </Section>
  );
};
