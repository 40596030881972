import React, { useCallback } from 'react';

import { Box, Text } from '@rbilabs/universal-components';

import ActionButton from 'components/action-button';
import { IImageFragment } from 'generated/sanity-graphql';
import { useNavigation } from 'hooks/navigation/use-navigation';
import useRoutes from 'hooks/use-routes';

import {
  BackgroundPicture,
  Container,
  Description,
  Name,
  OfferInfoContainer,
  OfferPicture,
} from './details-panel-components';
import { ILinkAction } from './use-feature-offers';

export interface IDetailsPanelErrorProps {
  backgroundImage: IImageFragment | null;
  image: IImageFragment | null;
  title: string | null;
  body: string | null;
  action: ILinkAction | null;
  showAction: boolean;
  titleTestId?: string;
  bodyTestId?: string;
  actionButtonTestId?: string;
}

export const DetailsPanelError: React.FC<React.PropsWithChildren<IDetailsPanelErrorProps>> = ({
  backgroundImage,
  image,
  title,
  body,
  action,
  showAction,
  titleTestId,
  bodyTestId,
  actionButtonTestId,
}) => {
  const { getLocalizedRouteForPath } = useRoutes();

  const { route, actionText } = action ?? {};
  const { navigate } = useNavigation();
  const onClick = useCallback(() => {
    if (route) {
      navigate(getLocalizedRouteForPath(route));
    }
  }, [route, navigate, getLocalizedRouteForPath]);

  return (
    <Container>
      {backgroundImage && (
        <BackgroundPicture alt="" image={backgroundImage} objectFitContain={false} />
      )}
      {image && <OfferPicture image={image} alt={title ?? ''} />}
      <OfferInfoContainer>
        <Name variant="headerTwo" testID={titleTestId}>
          {title}
        </Name>
        <Description variant="copyOne" testID={bodyTestId}>
          {body}
        </Description>

        {showAction && actionText && (
          <Box marginTop={{ md: '5', base: '4' }}>
            <ActionButton testID={actionButtonTestId} onPress={onClick}>
              <Text variant="copyOne">{actionText}</Text>
            </ActionButton>
          </Box>
        )}
      </OfferInfoContainer>
    </Container>
  );
};
