import React from 'react';

import { useIntl } from 'react-intl';

import ConfirmDialog from 'components/confirm-dialog';

interface IConfirmRedemptionDialogProps {
  showDialog: boolean;
  dismiss: () => void;
  onConfirm: () => void;
}

const ConfirmRedemptionDialog = ({
  showDialog,
  dismiss,
  onConfirm,
}: IConfirmRedemptionDialogProps) => {
  const { formatMessage } = useIntl();

  return (
    <ConfirmDialog
      showDialog={showDialog}
      heading={formatMessage({ id: 'confirmRedemptionHeader' })}
      body={formatMessage({ id: 'confirmRedemption' })}
      confirmLabel={formatMessage({ id: 'redeemInRestaurant' })}
      onCancel={dismiss}
      onConfirm={onConfirm}
      onDismiss={dismiss}
      modalAppearanceEventMessage="Confirm redeem in restaurant Dialog"
    />
  );
};

export default ConfirmRedemptionDialog;
