import React, { useCallback } from 'react';

import { OffersListItem } from '@rbilabs/universal-components';
import { useIntl } from 'react-intl';

import { IBaseProps } from '@rbi-ctg/frontend';
import { IOffer } from '@rbi-ctg/menu';
import Picture from 'components/picture';
import { IncentiveCardDescription } from 'pages/loyalty/loyalty-incentives-components/incentive-card/incentive-card.styled';
import { blockContentToPlainText } from 'utils/sanity';

interface IOfferCellProps extends IBaseProps {
  offer: IOffer;
  onPress: (offerId: string) => void;
  isSelected: boolean;
  onRemove: () => void;
}

export const OfferCell: React.FC<React.PropsWithChildren<IOfferCellProps>> = ({
  offer,
  onPress,
  isSelected,
  onRemove,
}) => {
  const onPressOffer = useCallback(() => onPress(offer._id), [offer, onPress]);
  const { formatMessage } = useIntl();

  return (
    <OffersListItem
      handleRemove={onRemove}
      offerText={formatMessage({ id: 'offerApplied' })}
      removeOfferText={formatMessage({ id: 'remove' })}
      image={
        <Picture
          image={offer.localizedImage?.locale.app}
          alt={offer.localizedImage?.locale.imageDescription ?? ''}
          height="97px"
          width="97px"
          objectFitContain
        />
      }
      header={blockContentToPlainText(offer.name?.localeRaw)}
      description={
        offer?.description?.localeRaw && (
          <IncentiveCardDescription content={offer?.description?.localeRaw} />
        )
      }
      offerApplied={isSelected}
      promoCodeLabel={blockContentToPlainText(offer.promoCodePanel?.promoCodeLabel?.localeRaw)}
      onPress={onPressOffer}
    />
  );
};
