import { useCallback, useEffect, useMemo, useState } from 'react';

import { IOfferRedemptions } from '@rbi-ctg/offers';
import { Locale, useEvaluateAllUserOffersQuery } from 'generated/rbi-graphql';
import { useLocale } from 'state/intl';
import { ServiceMode } from 'state/service-mode';
import { useStoreContext } from 'state/store';
import { platform } from 'utils/environment';
import { isNotNull } from 'utils/is-not-null';
import LocalStorage, { StorageKeys } from 'utils/local-storage';
import { redeemedOnTime, reduceOffersFeedback } from 'utils/offers';

import { mapOfferDataToNewOffer, shouldDisplayOffer } from '../util';

import { UNAUTHENTICATED_REDEMPTIONS_ENABLED } from './constants';

export interface IUseOfferListProps {
  serviceMode: null | ServiceMode;
  isAuthenticated: boolean;
  skip?: boolean;
}

const getAllRedeemedOffersNoAuth = (): IOfferRedemptions =>
  LocalStorage.getItem(StorageKeys.OFFERS_REDEEMED_NO_AUTH) || {};

// @todo if we have stored offers and a user signs up afterward,
// should we attribute those redemptions to that user, or allow
// them to redeem the offers again?
const persistUnauthenticatedRedemptions = (offerRedemptions: IOfferRedemptions) => {
  const allRedeemed = getAllRedeemedOffersNoAuth();
  LocalStorage.setItem(StorageKeys.OFFERS_REDEEMED_NO_AUTH, {
    ...allRedeemed,
    ...offerRedemptions,
  });
  return allRedeemed;
};

/**
 * returns a list of currently usable offers for the given user
 * (if no user is logged in returns all offers)
 */

function useOffersList({ serviceMode, isAuthenticated, skip = false }: IUseOfferListProps) {
  const { language } = useLocale();
  const preloadedOfferRedemptions = useMemo(getAllRedeemedOffersNoAuth, []);
  const { store } = useStoreContext();
  const [redeemedOn, setRedeemedOn] = useState<string>(redeemedOnTime());
  const [unauthenticatedRedemptions, setUnauthenticatedRedemptions] = useState(
    preloadedOfferRedemptions
  );

  // make the first EvaluateUserOffers query, and then trigger the rest in the onCompleted callback
  const { data, loading, refetch, previousData } = useEvaluateAllUserOffersQuery({
    skip,
    context: { shouldNotBatch: true },
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    variables: {
      locale: language as Locale,
      platform: platform(),
      serviceMode,
      redeemedOn,
      storeId: store?.number,
    },
    partialRefetch: true,
  });

  /*
   * Make sure we utilize old data until the new results have finished loading otherwise the
   * user may be kicked off of the redemption screen if their offer isn't in the list yet.
   **/
  const lastLoadedQueryData = loading ? previousData : data;

  const queryRedeemableOffers = useCallback(() => {
    if (isAuthenticated) {
      setRedeemedOn(redeemedOnTime());
    }
  }, [isAuthenticated]);

  const redeemUnauthenticatedOffer = useCallback((couponId: string) => {
    setUnauthenticatedRedemptions(redemptions => ({
      ...redemptions,
      [couponId]: [...(redemptions[couponId] ?? []), redeemedOnTime()],
    }));
  }, []);

  const lastLoadedOffersFeedback = lastLoadedQueryData?.evaluateAllUserOffers?.offersFeedback;

  const results = useMemo(() => {
    const sortedOffersFeedback = lastLoadedOffersFeedback?.filter(isNotNull) ?? [];

    const offers = sortedOffersFeedback
      .filter(offerFeedback => offerFeedback.offerDetails)
      .map(offerFeedback => mapOfferDataToNewOffer({ offerFeedback, language }))
      .filter(
        offer =>
          !UNAUTHENTICATED_REDEMPTIONS_ENABLED ||
          shouldDisplayOffer(offer, unauthenticatedRedemptions[offer._id])
      );

    // Add only if not logged in, or if it is redeemable
    const standardOffers = offers.filter(offer => offer.isRedeemable || !isAuthenticated);

    const offersFeedback = reduceOffersFeedback(sortedOffersFeedback);

    return { offersFeedback, offers, standardOffers } as const;
  }, [lastLoadedOffersFeedback, language, unauthenticatedRedemptions, isAuthenticated]);

  useEffect(() => {
    if (UNAUTHENTICATED_REDEMPTIONS_ENABLED) {
      persistUnauthenticatedRedemptions(unauthenticatedRedemptions);
    }
  }, [unauthenticatedRedemptions]);

  return {
    ...results,
    updatingOffers: loading,
    queryRedeemableOffers,
    redeemUnauthenticatedOffer,
    refetch,
  };
}

export default useOffersList;
