import React, { FC } from 'react';

import {
  IconWrapper,
  PointsDescription,
  PointsLineWrapper,
  PointsText,
  StyledPointsIcon,
} from './transaction.styled';

export const PointsLine: FC<React.PropsWithChildren<{
  points: number;
  description: string;
}>> = ({ points, description }) => {
  const pointsEarned = points > 0;

  return (
    <PointsLineWrapper>
      <IconWrapper $pointsEarned={pointsEarned}>
        <StyledPointsIcon />
        <PointsText $pointsEarned={pointsEarned}>{`${
          pointsEarned ? '+' : ''
        }${points}`}</PointsText>
      </IconWrapper>
      <PointsDescription $pointsEarned={pointsEarned}>{description}</PointsDescription>
    </PointsLineWrapper>
  );
};
