import * as React from 'react';

import { BoxProps, makeUclComponent } from '@rbilabs/universal-components';

import { QuestBadgeIcon, QuestBadgeText, QuestBadgeWrapper } from './quest-badge.styled';

type QuestBadge = BoxProps & { text?: string; reversedBadge?: boolean };

export const QuestBadge = makeUclComponent<QuestBadge>(({ text, reversedBadge, ...props }) => {
  if (!text) {
    return null;
  }

  return (
    <QuestBadgeWrapper
      flexDirection="row"
      alignItems="center"
      padding="$1.5"
      borderRadius="sm"
      alignSelf="flex-start"
      reversed={reversedBadge}
      shadow={2}
      {...props}
    >
      <QuestBadgeIcon marginRight="$1.5" reversed={reversedBadge} />
      <QuestBadgeText variant="copyTwo" reversed={reversedBadge}>
        {text}
      </QuestBadgeText>
    </QuestBadgeWrapper>
  );
});
