import { ScrollView } from '@rbilabs/universal-components';

import { WEB_APP_DESKTOP_LG_MAX_WIDTH, WEB_APP_MAX_WIDTH } from 'utils/constants';

export const ScreenScrollView = ScrollView.withConfig({
  showsVerticalScrollIndicator: true,
  _web: {
    _contentContainerStyle: {
      marginX: 'auto',
      w: {
        base: 'full',
        md: WEB_APP_MAX_WIDTH,
        lg: WEB_APP_DESKTOP_LG_MAX_WIDTH,
      },
    },
  },
});
