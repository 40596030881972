import React from 'react';

import { View } from '@rbilabs/universal-components';
import { useIntl } from 'react-intl';

import EmptyState from 'components/empty-state';
import { routes } from 'utils/routing';

const Wrap = View.withConfig({
  flexDirection: 'row',
  paddingX: 0,
  paddingY: '$10',
  alignItems: 'center',
  justifyContent: 'center',
});

export const DeliveryMessageView = () => {
  const { formatMessage } = useIntl();

  return (
    <Wrap>
      <EmptyState
        header={formatMessage({ id: 'deliveryNoOffersMessage' })}
        linkText={formatMessage({ id: 'deliverySeeMenu' })}
        linkTo={routes.menu}
        subheader={formatMessage({ id: 'subheaderForDeliveryNoOffers' })}
      />
    </Wrap>
  );
};
