import { useCallback } from 'react';

import { useIntl } from 'react-intl';

import { getProductPropsSelections } from 'components/product-detail/utils';
import { useMenuCalories } from 'hooks/menu/use-menu-calories';
import { useMenuContext } from 'state/menu';
import { UserSelectionModifierSlotMap } from 'state/product-wizard/types';
import { useUIContext } from 'state/ui';

export const useProductPropsSelections = () => {
  const { priceForItemOptionModifier } = useMenuContext();
  const { formatCurrencyForLocale } = useUIContext();

  const { formatMessage } = useIntl();
  const { formatCalories } = useMenuCalories();

  const buildProductPropsSelections = useCallback(
    (modifierSelections: UserSelectionModifierSlotMap) =>
      getProductPropsSelections({
        formatCalories,
        formatCurrencyForLocale,
        formatMessage,
        modifierSelections,
        priceForItemOptionModifier,
      }),
    [formatCalories, formatCurrencyForLocale, formatMessage, priceForItemOptionModifier]
  );

  return {
    buildProductPropsSelections,
  };
};
