import React from 'react';

import { Box, BoxProps, Pressable, addWithConfig } from '@rbilabs/universal-components';

import { Dictionary } from '@rbi-ctg/frontend';
import useShallowMemo from 'hooks/use-shallow-memo';
import { theme } from 'styles/configure-theme';
import { ITable } from 'utils/table';

interface ITableProps<Row extends Dictionary> extends BoxProps {
  table: ITable<Partial<Row>>;
  columnOrder?: readonly (keyof Row)[];
  onPressRow?: (r: Row | Partial<Row>) => void;
}

const TableTag = Box.withConfig({
  width: 'full',
  borderColor: Styles.color.grey.four,
  borderStyle: 'solid',
  borderWidth: 1,
  borderRadius: 10,
});

const Th = Box.withConfig({
  flex: 1,
});

const Td = Box.withConfig({
  height: 'full',
  flexGrow: 1,
});

const Tr = Box.withConfig<{ withBorderBottom?: boolean }>(({ withBorderBottom }) => ({
  width: 'full',
  paddingY: '$4',
  flexDirection: 'row',
  borderBottomColor: theme.token('border-default'),
  borderStyle: 'solid',
  borderBottomWidth: withBorderBottom ? 1 : 0,
  borderBottomRadius: !withBorderBottom ? 10 : 0,
  paddingX: '$4',
}));

const Table = <Row extends Dictionary>({
  table,
  columnOrder: columnOrderProp,
  onPressRow: onPressProp,
  ...boxProps
}: ITableProps<Row>) => {
  /**
   * Make sure all column keys line up between head and body.
   */
  const columnOrder = useShallowMemo(() => {
    const orderedColumnKeys = columnOrderProp || [];
    const firstRow = table.head[0] || table.body[0] || {};
    return Array.from(new Set([...orderedColumnKeys, ...Object.keys(firstRow)]));
  }, [columnOrderProp, table.head[0], table.body[0]]);

  const onPressRow = React.useCallback((row: Row | Partial<Row>) => () => onPressProp?.(row), [
    onPressProp,
  ]);

  return (
    <TableTag {...boxProps}>
      {table.head.map((row, rowIndex, tableHeadItems) => {
        const isLastChild = rowIndex === tableHeadItems.length - 1;
        return (
          <Tr key={`row.${rowIndex}`} withBorderBottom={isLastChild}>
            {columnOrder.map((key, keyIndex) => (
              // @ts-expect-error TS(2731) FIXME: Implicit conversion of a 'symbol' to a 'string' wi... Remove this comment to see the full error message
              <Th key={`${key}.${keyIndex}`}>{row[key]}</Th>
            ))}
          </Tr>
        );
      })}
      {table.body.map((row, rowIndex, tableBodyItems) => {
        const isLastChild = rowIndex === tableBodyItems.length - 1;
        return (
          <Pressable key={`row.${rowIndex}`} onPress={onPressRow(row)}>
            <Tr withBorderBottom={!isLastChild} backgroundColor={Styles.color.cardBackground}>
              {columnOrder.map((key, keyIndex) => (
                // @ts-expect-error TS(2731) FIXME: Implicit conversion of a 'symbol' to a 'string' wi... Remove this comment to see the full error message
                <Td key={`${key}.${keyIndex}`}>{row[key]}</Td>
              ))}
            </Tr>
          </Pressable>
        );
      })}
    </TableTag>
  );
};

export default addWithConfig(Table);
